import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { cancelRequest } from 'store/ducks/visitServices/requestDetails'
import { Button, CustomRadioGroup, TextBoxOuterLabel } from 'components/common'
import { requestTypeFromId } from 'components/helper/constants/visitServices'

const StyledGrid = styled(Grid)`
  && {
    text-align: center;
  }
`

const CancelRequestConfirmation = ({ closeCancelModal }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { requestId, requestTypeId } = useParams()
  const [cancelConfirmation, setCancelConfirmation] = useState('no')
  const [newRequestConfirmation, setNewRequestConfirmation] = useState('no')
  const [copyCanceledRequestConfirmation, setCopyCanceledRequestConfirmation] = useState('no')
  const [cancelNotes, setCancelNotes] = useState('')

  const onSubmitHandler = () => {
    if (cancelConfirmation === 'no') {
      closeCancelModal()
      return
    }
    if (cancelConfirmation === 'yes') {
      /**
       * If we call navigate after dispatch,
       * it is called immediately without waiting for async post call to be complete
       * So sending the navigate Function to the reducer to call after api call is successful
       */
      if (newRequestConfirmation === 'no') {
        dispatch(cancelRequest(requestId, cancelNotes, () => navigate('../arriveDashboard')))
      } else if (newRequestConfirmation === 'yes') {
        if (copyCanceledRequestConfirmation === 'no') {
          dispatch(cancelRequest(requestId, cancelNotes, () => navigate('../makearequest')))
        } else if (copyCanceledRequestConfirmation === 'yes') {
          dispatch(
            cancelRequest(requestId, cancelNotes, () =>
              navigate(`../makearequest/${requestTypeFromId[requestTypeId]}/${requestId}`)
            )
          )
        }
      }
    }
  }

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomRadioGroup
          formik="false"
          label="Are you sure you want to cancel this request?"
          name="cancelConfirmation"
          id="cancelConfirmation"
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' }
          ]}
          fullWidth={false}
          row={true}
          orientation="column"
          color="primary"
          onChange={e => {
            setCancelConfirmation(e.target.value)
          }}
          value={cancelConfirmation}
          align="center"
        />
      </Grid>
      {cancelConfirmation === 'yes' && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextBoxOuterLabel
              formik="false"
              size="medium"
              id="cancelNotes"
              label=""
              placeholder="Optional: please provide additional information"
              name="cancelNotes"
              type="text"
              rows="3"
              value={cancelNotes}
              onChange={e => {
                setCancelNotes(e.target.value)
              }}
              multiline
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomRadioGroup
              formik="false"
              label="Do you want to start a new request now?"
              name="newRequestConfirmation"
              id="newRequestConfirmation"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ]}
              fullWidth={false}
              row={true}
              orientation="column"
              color="primary"
              onChange={e => {
                setNewRequestConfirmation(e.target.value)
              }}
              value={newRequestConfirmation}
              align="center"
            />
          </Grid>
          {newRequestConfirmation === 'yes' && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomRadioGroup
                formik="false"
                label="Do you want to start a new request based on the information in the request you are canceling?"
                name="copyCanceledRequestConfirmation"
                id="copyCanceledRequestConfirmation"
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' }
                ]}
                fullWidth={false}
                row={true}
                orientation="row"
                color="primary"
                onChange={e => {
                  setCopyCanceledRequestConfirmation(e.target.value)
                }}
                value={copyCanceledRequestConfirmation}
                align="center"
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button size="medium" fullWidth={false} color="primary" onClick={onSubmitHandler}>
          Submit
        </Button>
      </Grid>
    </StyledGrid>
  )
}

CancelRequestConfirmation.propTypes = {
  closeCancelModal: PropTypes.func
}

export default CancelRequestConfirmation
