import React from 'react'
import { CircularProgress, Paper, Typography } from '@mui/material'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const MailWrapper = styled(Paper)`
  display: flex;
  width: 100%;
  max-height: 64vh;
`
const TabsWrapper = styled.div(
  ({ theme }) => `
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 24rem;
    border-right: 1px solid ${theme.palette.gray.main};
    overflow-y: auto;
    }
  `
)

const ContentWrapper = styled.div(
  ({ theme, popup }) => `
    width: 70%;
    max-height: 70vh;
    overflow: auto;
    ${
  popup === 'true'
    ? ` 
        background-color: ${theme.palette.midnight.dark};
        padding: ${theme.spacing(5)};
        `
    : ''
},
    `
)

const ContentChildWrapper = styled.div`
  min-width: 40rem;
`

const Tab = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
`

const ActionWrapper = styled.div(
  ({ theme }) => `
&& {
    width: 100%;
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.minorUiElement};
    border-bottom: 2px solid ${theme.palette.gray.main};
    background: ${theme.palette.white.main};
  }
`
)

const NoResultsFoundContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingWrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
`

const MailNavigation = ({
  tabLabelComp,
  tabContentComp,
  tabChangeActions,
  tabProps,
  action,
  backdropMode,
  selectedItem,
  selectedIdentifierKey,
  isLoading
}) => {
  const changeTab = changedTo => {
    tabChangeActions && tabChangeActions(changedTo)
  }

  const TabLabel = tabLabelComp
  const TabContent = tabContentComp
  return (
    <>
      <MailWrapper elevation={0}>
        <TabsWrapper>
          {action && <ActionWrapper>{action}</ActionWrapper>}
          {tabProps.length > 0 &&
            tabProps.map((b, index) => {
              const selected = selectedItem[selectedIdentifierKey] === b[selectedIdentifierKey]

              return (
                <Tab key={index}>
                  <TabLabel
                    {...b}
                    onTabChange={() => {
                      changeTab(b)
                    }}
                    selected={selected}
                  />
                </Tab>
              )
            })}
        </TabsWrapper>

        {isLoading
          ? (
            <LoadingWrapper>
              <CircularProgress />{' '}
            </LoadingWrapper>
          )
          : tabProps.length > 0
            ? (
              <ContentWrapper popup={backdropMode}>
                <ContentChildWrapper>
                  <TabContent />
                </ContentChildWrapper>
              </ContentWrapper>
            )
            : (
              <NoResultsFoundContainer>
                <Typography variant="subtitle1">No results found.</Typography>
              </NoResultsFoundContainer>
            )}
      </MailWrapper>
    </>
  )
}

MailNavigation.propTypes = {
  /**
   *A custom tab component
   *
   */
  tabLabelComp: PropTypes.any.isRequired,
  /**
   *
   */
  tabContentComp: PropTypes.any.isRequired,
  /**
   *List of tabs
   *
   */
  tabProps: PropTypes.array.isRequired,
  /**
   * action node that will show up on top of the tabs
   */
  action: PropTypes.node,
  /**
   * When true, the content part will show up in pop-up carrying layer
   * @default 'false'
   */
  backdropMode: PropTypes.oneOf(['true', 'false']),
  /**
   * Pass function that will be invoked on tab change
   * @returns the index of clicked tab
   */
  tabChangeActions: PropTypes.func,
  /**
   * pass the selected item
   */
  selectedItem: PropTypes.object,
  /**
   * pass the name of the key to identify the selected item
   */
  selectedIdentifierKey: PropTypes.string,
  /**
   * @default false
   * when true shows a loading indicator
   */
  isLoading: PropTypes.bool
}

MailNavigation.defaultProps = {
  backdropMode: 'false',
  selectedIdentifierKey: 'id',
  isLoading: false
}

export default MailNavigation
