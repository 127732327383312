import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { Button, DataGrid } from 'components/common'
import { palette } from 'styles/baseTheme'

import { fetchSiteNotes, setShowAddNote } from 'store/ducks/siteManagement/siteManagement'
import { displayDate } from 'components/helper/utility'

import AddNoteForm from './AddNoteForm'
import QuickView from 'components/common/QuickView'

const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
`
const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}
const Container = styled.div(({ theme }) => ({
  padding: theme.spacing(2, 0, 3, 0),
  width: '100%',
  overflow: 'hidden'
  // padding: '20px 0px 20px 0px'
}))
const HeadingContainer = styled.div(() => ({
  width: '80%',
  float: 'left'
}))
const ButtonContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))
const EmptyFooter = styled.div`
  padding: 25px;
`

// eslint-disable-next-line max-lines-per-function
const SiteNotes = () => {
  const { siteId } = useParams()
  const dispatch = useDispatch()

  const { siteNotes, showAddNote, siteDetails } = useSelector(store => store.siteManagement)

  let formattedSiteNotes = []
  if (siteNotes && siteNotes.length > 0) {
    formattedSiteNotes = siteNotes.map(note => ({
      ...note,
      createdDate: displayDate(note.createdDate)
    }))
  }

  const closeAddNoteForm = () => {
    setShowAddNote(false)
  }
  const refreshNotes = () => {
    dispatch(fetchSiteNotes(siteId))
  }

  useEffect(() => {
    refreshNotes()
  }, [siteId])

  const headers = [
    {
      id: 'noteType',
      label: 'Notes Type',
      bodyProperties: {
        width: '15%',
        textAlign: 'left'
      },
      headProperties: { ...headerProperties, textAlign: 'left' }
    },
    {
      id: 'notes',
      label: 'Notes',
      bodyProperties: {
        textAlign: 'left',
        width: '50%',
        cellType: 'html'
      },
      headProperties: { ...headerProperties, textAlign: 'left' }
    },
    {
      id: 'createdBy',
      label: 'Created By',
      bodyProperties: {
        width: '15%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'createdByPolicy',
      label: 'Created By Policy',
      bodyProperties: {
        width: '10%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'createdDate',
      label: 'Created Date',
      bodyProperties: {
        width: '10%'
      },
      headProperties: { ...headerProperties }
    }
  ]

  return (
    <React.Fragment>
      {/* <Grid container style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
      </Grid> */}
      <Container>
        <HeadingContainer></HeadingContainer>
        <ButtonContainer>
          <Button
            label="Add"
            size="medium"
            fullWidth={false}
            color="primary"
            onClick={() => dispatch(setShowAddNote(true))}
          >
            Add Site Note
          </Button>
        </ButtonContainer>
      </Container>
      <TableWrapper>
        <DataGrid
          headers={headers}
          tableData={formattedSiteNotes}
          setRequestedSort={() => {}}
          order={'DESC'}
          orderBy={'createdDate'}
          alternateRowColored={true}
        />
      </TableWrapper>
      <QuickView
        title="Add Site Note"
        onClose={closeAddNoteForm}
        dialogContent={
          <AddNoteForm
            siteId={siteId}
            siteProtocolId={siteDetails.siteProtocolId}
          />
        }
        isDialogOpen={showAddNote}
        maxWidth="sm"
        dialogActions={<EmptyFooter />}
        closeConfirmation={false}
      />
    </React.Fragment>
  )
}

export default SiteNotes
