import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div(
  ({ theme, color }) => `
border-radius: ${theme.shape.circle};
border: 8px solid ${theme.palette[color].main};
display: flex;
flex-direction: column;
justify-content: center;
min-width: 9rem;
height: 9rem;
align-items: center;
margin: ${theme.spacing(1)};`,
)

const SubContentWrapper = styled.span`
  align-self: center;
  width: fit-content;
`

const Token = ({ content, subContent, borderColor }) => (
  <Wrapper color={borderColor}>
    <span>{content}</span>
    <SubContentWrapper>{subContent}</SubContentWrapper>
  </Wrapper>
)

Token.propTypes = {
  content: PropTypes.node,
  subContent: PropTypes.node,
  borderColor: PropTypes.string,
}

Token.defaultProps = {
  borderColor: 'text',
}

export default Token
