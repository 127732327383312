import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'components/common/form/Button'
import EditPatientSupportEmailAddress from './EditPatientSupportEmailAddress'
import EditPatientSupport from './EditPatientSupport'
import EditSupplementalInfo from '../common/EditSupplementalInfo'
import { AccordionGroup } from 'components/common'
import {
  fetchPatientSupportPersons,
  resetPatientSupport,
  setShowAddForm
} from 'store/ducks/patientRecord/patientProfile'
import { Restriction } from 'permissions'

const PatientSupport = () => {
  // For Patient View, use the patientID from patientDashboard
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { patientSupports, showAddForm } = useSelector(store => store.patientProfile)

  const dispatch = useDispatch()

  useEffect(() => {
    patientInformation.userId && dispatch(fetchPatientSupportPersons(patientInformation.userId))
    return () => {
      dispatch(resetPatientSupport())
    }
  }, [])

  return (
    <>
      <Restriction ability="read" feature="patientProfileCompanionAddButton">
        <div style={{ width: '100%', overflow: 'hidden', padding: '20px 0px 20px 0px' }}>
          <div style={{ width: '90%', float: 'left' }}>
            This is the patient&apos;s directory of contacts, that could possibly be involved in
            assisting them in the study.
            <br />
            Add as many as may be needed.
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              label="Add"
              size="medium"
              color="primary"
              onClick={() => dispatch(setShowAddForm(true))}
            >
              Add
            </Button>
          </div>
        </div>
      </Restriction>

      <AccordionGroup
        accordions={
          showAddForm
            ? [
              {
                label: '',
                component: <EditPatientSupport />
              }
            ]
            : []
        }
        color="gradientBlue"
        defaultOpenPanel={0}
      />
      {patientSupports.areSupportPersonsAvailable && (
        <AccordionGroup
          accordions={patientSupports.results?.map(supportPerson => ({
            label: `${supportPerson.fullName} (${supportPerson.userSupportPersonType?.displayText})`,
            component: (
              <div key={supportPerson.userSupportPersonId}>
                <>
                  <EditPatientSupportEmailAddress contactDetails={supportPerson} />
                  <br />
                  <hr />
                </>
                <>
                  <EditPatientSupport
                    key={supportPerson.firstName}
                    supportDetails={supportPerson}
                  />
                  <br />
                  <hr />
                </>

                <Restriction ability="read" feature="patientProfileArrive">
                  {supportPerson
                    ? (
                      <EditSupplementalInfo
                        userId={supportPerson.userId}
                        userSupportPersonId={supportPerson.userSupportPersonId}
                      />
                    )
                    : ''}
                </Restriction>
              </div>
            )
          }))}
          color="gradientBlue"
          transitionProps={{ unmountOnExit: true }}
          defaultOpenPanel={-1}
        />
      )}
    </>
  )
}
export default PatientSupport
