import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { Card, PartitionCard } from 'components/common'
import EditParticipationProgress from './EditParticipationProgress'
import ParticipationProgressNotes from './participationProgressNotes/ParticipationProgressNotes'
import PrescreeningQuestionsAnswers from './prescreeningQuestionsAnswers/PrescreeningQuestionsAnswers'
import {
  fetchParticipationProgressNotes,
  fetchParticipationProgressHistory
} from 'store/ducks/patientRecord/participationProgress'
import PropertyValue from 'components/common/dataDisplay/PropertyValue'
import { displayDate } from 'components/helper/utility'
import { useRestriction, Restriction } from 'permissions'
import { updatePatientViewed } from 'store/ducks/patientRecord/patientMainWrapper'

const ParticipationProgress = () => {
  // For Patient View, use the patientID from patientDashboard
  const dispatch = useDispatch()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { participationProgressDetails, participationProgressHistory } = useSelector(
    store => store.participationProgress
  )
  const { loggedInAsSite } = useSelector(store => store.auth)

  const showPrescreeningSection =
    useRestriction('read', 'patientSurveyAnswers') ||
    useRestriction('read', 'secondaryPrescreening') ||
    useRestriction('read', 'additionalPrescreening')

  const showPrescreeningAndNotesSection =
    useRestriction('read', 'patientSurveyAnswers') ||
    useRestriction('read', 'secondaryPrescreening') ||
    useRestriction('read', 'additionalPrescreening') ||
    useRestriction('read', 'participationProgressNotesGrid')

  const fetchCalls = async () => {
    if (patientInformation.userId) {
      if (loggedInAsSite) {
        await dispatch(updatePatientViewed(patientInformation.userId))
      }
      await dispatch(fetchParticipationProgressNotes())
      await dispatch(fetchParticipationProgressHistory())
    }
  }

  useEffect(() => {
    fetchCalls()

    // No need to cleanup as isSubjectIDRequired is needed on Patient Dashboard
  }, [patientInformation.userId])

  return (
    <>
      <PartitionCard
        cardProps={{ cardContentStyles: { padding: '0px', margin: '0px' } }}
        firstContent={
          <>
            <Typography variant="h2">
              Participation Progress: {participationProgressDetails.preconsentStatus?.displayText}
            </Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <PropertyValue
                  labelProps={{ variant: 'body2' }}
                  label="TCN # Created Date:"
                  value={participationProgressDetails.systemIdCreatedDate}
                  align="row"
                  flexWrap="wrap"
                />
                <PropertyValue
                  labelProps={{ variant: 'body2' }}
                  label="Referral Source:"
                  value={participationProgressDetails.referralSource?.displayText}
                  align="row"
                  flexWrap="wrap"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                {patientInformation.firstViewedDate?.trim()
                  ? <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    label="First Viewed by Site:"
                    value={patientInformation.firstViewedDate}
                    align="row"
                    flexWrap="wrap"
                  />
                  : ''}
                {patientInformation.lastViewedDate?.trim()
                  ? <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    label="Last Viewed by Site:"
                    value={patientInformation.lastViewedDate}
                    align="row"
                    flexWrap="wrap"
                  />
                  : ''}
              </Grid>
            </Grid>

            <br />
            {participationProgressHistory.map(
              item =>
                item.status &&
                item.date && (
                  <PropertyValue
                    labelProps={{ variant: 'body2' }}
                    label={`${item.status}:`}
                    value={displayDate(item.date)}
                    align="row"
                    flexWrap="wrap"
                    key={`${item.status}-${item.date}`}
                  />
                )
            )}
          </>
        }
        firstContentWidth="40%"
        secondContent={<EditParticipationProgress />}
        secondContentBackground="gradientBlue"
        secondContentWidth="60%"
      />

      {showPrescreeningAndNotesSection && (
        <Card radius={false} cardContentStyles={{ margin: '10px  35px' }}>
          <>
            {showPrescreeningSection && <PrescreeningQuestionsAnswers />}

            <Restriction ability="read" feature="participationProgressNotesGrid">
              <ParticipationProgressNotes />
            </Restriction>
          </>
        </Card>
      )}
    </>
  )
}
export default ParticipationProgress
