import React from 'react'
import styled from '@emotion/styled'
import PageNotFound from 'assets/images/page-not-found.png'
import { Grid } from '@mui/material'

const NotFoundWrapper = styled.div`
  margin: 20px auto;
  width: 80%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GridContainer = styled(Grid)`
  ${({ theme }) => `
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    ${theme.palette.white.main},
    ${theme.palette.white.main}
  );
  opacity: 0.7;
  border-radius: ${theme.shape.borderRadius};
  `}
`

const NotFound = () => (
  <NotFoundWrapper>
    <GridContainer>
      <img src={PageNotFound} alt="Page Not Found" />
    </GridContainer>
  </NotFoundWrapper>
)

export default NotFound
