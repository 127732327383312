import React from 'react'
import { Alert as MuiAlert } from '@mui/material'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledAlert = styled(MuiAlert)(({ theme, gutter }) => ({
  '&&': {
    marginBottom: gutter === 'true' ? theme.spacing(2) : '0px',
  }
})
)

const Alert = props => {
  const { message, severity, formik, gutterBottom } = props
  let meta

  if (formik === 'true') {
    const fieldProps = useField(props)
    meta = fieldProps[1]
  }

  const helperText = message || (Boolean(meta.error) && meta.error)
  return helperText && <StyledAlert gutter={`${gutterBottom}`} severity={severity}>{helperText}</StyledAlert>
}

Alert.propTypes = {
  /**
   * @when formik is 'true'
   * component will get the message string from meta of formik
   * @when formik is 'false'
   * pass message string as props
   */
  message: PropTypes.string,
  /**
   * severity will decide the color of the Alert
   */
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  /**
   * @default 'true'
   * when 'false' will have to pass the message props
   */
  formik: PropTypes.oneOf(['true', 'false']),
  /**
   * when formik is 'true', pass a name props to let the formik know about alert component
   */
  name: PropTypes.string,
  /**
   * @default false
   * Adds a bottom margin when true
   */
  gutterBottom: PropTypes.bool
}

Alert.defaultProps = {
  formik: 'true',
}

export default Alert
