/* eslint-disable max-params */
export const getPQCardData = async (
  accessToken,
  serverToken,
  userToken,
  destID,
  origin,
  format,
  side
) => {
  const raw = JSON.stringify({
    Token: serverToken
  })

  const headers = {
    'X-MyPayQuicker-Version': '2020.02.24',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Origin: origin,
    Authorization: `Bearer ${accessToken}`
  }

  let url = `${process.env.REACT_APP_PQ_URL}/${userToken}/prepaid-cards/${destID}/pci?format=${format}`

  if (format === 'IMAGE') {
    url = `${process.env.REACT_APP_PQ_URL}/${userToken}/prepaid-cards/${destID}/pci?format=${format}&side=${side}`
  }

  return await fetchData(url, 'POST', headers, raw)
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

export const getPQCardPIN = async (
  accessToken,
  serverToken,
  userToken,
  destID,
  origin
) => {
  const raw = JSON.stringify({
    cardPinToken: serverToken
  })

  const headers = {
    'X-MyPayQuicker-Version': '2020.02.24',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Origin: origin,
    Authorization: `Bearer ${accessToken}`
  }

  const url = `${process.env.REACT_APP_PQ_URL}/${userToken}/prepaid-cards/${destID}/pin`

  return await fetchData(url, 'POST', headers, raw)
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

export const getClientIp = async () => {
  const url = 'https://api64.ipify.org/?format=json'

  return await fetchData(url, 'GET')
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

const fetchData = async (url, method = 'GET', headers = {}, body) => {
  try {
    let options
    if (body) {
      options = {
        method,
        headers: new Headers(headers),
        redirect: 'follow',
        body
      }
    } else {
      options = {
        method,
        headers: new Headers(headers),
        redirect: 'follow'
      }
    }
    const response = await fetch(url, { ...options })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    return null // Return null or handle the error as needed
  }
}
