import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText
} from '@mui/material'
import { Button, Card, PropertyValue } from 'components/common'
import { palette } from 'styles/baseTheme'
import QuickView from 'components/common/QuickView'
import RequestPlasticCardModal from '../manageAccount/RequestPlasticCardModal'
import TransactionHistory from '../manageAccount/TransactionHistory'
import ActivatePlasticCard from '../manageAccount/ActivatePlasticCard'
import { getCardPin, getCardPinToken, suspendCard } from 'services/patientRecord/manageAccount'
import { cardType, cardStatus } from 'components/helper/constants/reimbursement'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAccountHoldersCardDetails } from 'store/ducks/patientRecord/manageAccount'
import {
  AddCardTwoTone,
  HistoryOutlined,
  LockOutlined,
  PowerSettingsNewOutlined,
  ReportProblemOutlined
} from '@mui/icons-material'
import styled from '@emotion/styled'
import { showError } from 'store/ducks/application'
import ComposeMessage from 'components/private/communicationCenter/studyMessages/ComposeMessage'
import { Restriction } from 'permissions'
import { getClientIp, getPQCardPIN } from 'services/patientRecord/payQuicker'

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  '&&': {
    minWidth: '26px',
    marginRight: theme.spacing(1),
    '.MuiSvgIcon-root': {
      width: '1.5em'
    }
  }
}))
const ContentWrapper = styled.div`
  max-width: 212px;
  text-align: initial;
`
const TransferFundsHelpWrapper = styled(Typography)(({ theme }) => ({
  button: {
    padding: '0px',
    color: theme.palette.primary.main,
    fontSize: '0.7rem',
    fontStyle: 'italic'
  }
}))

// const StyledListLink = styled(Link)(({ theme }) => ({
//   '&&': {
//     textDecoration: 'unset',
//     color: theme.palette.secondary.contrastText,
//     width: '100%'
//   }
// }))

const Actions = ({ cardHolderDetails, account }) => {
  const dispatch = useDispatch()
  const { cardDetailsManageAccount } = useSelector(store => store.manageAccount)
  const { patientInformationForReimbursement } = useSelector(store => store.reimbursement)
  const [requestPlasticCardModal, setRequestPlasticCardModal] = useState(false)
  const [activatePlasticCard, setActivatePlasticCard] = useState(false)
  const [reportLostStolen, setReportLostStolen] = useState(false)
  const [viewTransactionHistory, setViewTransactionHistory] = useState(false)
  const [retrievePinModal, setRetrievePinModal] = useState(false)
  const [secretPin, setSecretPin] = useState('')
  const [showComposeMessage, setShowComposeMessage] = useState(false)
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)

  const [lostStolenReportedSuccess, setLostStolenReportedSuccess] = useState(false)

  const { results } = cardDetailsManageAccount

  const onRetrievePin = async () => {
    try {
      let pin
      if (
        instanceConfig.results.CardVendor &&
        instanceConfig.results.CardVendor.toLowerCase() === process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase() &&
        account.id
      ) {
        const origin = process.env.REACT_APP_APPLICATION_ORIGIN
        const { ip } = await getClientIp()
        if (ip) {
          const { data } = await getCardPinToken(account.id, origin, ip)
          const { accessToken, token, userToken, cardToken } = data
          const cardDataResponse = await getPQCardPIN(
            accessToken,
            token,
            userToken,
            cardToken,
            origin
          )
          pin = cardDataResponse.cardPin
        }
      } else {
        const pinDetails = await getCardPin(account.id)
        pin = pinDetails.pin
      }

      setSecretPin(pin)
      setRetrievePinModal(true)
    } catch {
      dispatch(showError('Error while trying to get card pin'))
    }
  }

  const onLostStolenReport = async () => {
    try {
      const requestData = {
        reimbursementCardId: account.id
      }
      await suspendCard(requestData)
      await dispatch(fetchAccountHoldersCardDetails(account.id))
      setLostStolenReportedSuccess(true)
    } catch {
      dispatch(showError('Error when trying to fetch account holder details'))
    }
  }

  // if the card status is inactive and plastic card is NOT activated
  // (IsPlastic = 1 && IsPlasticCardActivated =0 && cardstatus != 'Suspend')
  const isActivatePlasticCardEnabled =
    ![cardStatus.inActive, cardStatus.suspend].includes(results.cardStatus) &&
    account.isPlastic &&
    !account.isPlasticCardActivated

  return (
    <>
      <Card
        radius={false}
        alignContent="center"
        cardContentStyles={{
          padding: '16px',
          borderTop: `16px solid ${palette.primary.main}`,
          justifyContent: 'center'
        }}
      >
        <ContentWrapper>
          <List>
            {patientInformationForReimbursement.isAbleToRequestPlasticCard && (
              <ListItem disablePadding>
                <ListItemButton
                  disableGutters
                  onClick={() => setRequestPlasticCardModal(true)}
                  disabled={
                    !![cardType.convertToPlasticRequested, cardType.plastic].includes(
                      results.cardType
                    ) ||
                    !!account.isPlastic ||
                    !account.isTermsAccepted
                  }
                >
                  <StyledListItemIcon>
                    <AddCardTwoTone color="midnight" />
                  </StyledListItemIcon>
                  <ListItemText
                    primary="Request Plastic Card"
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton
                disableGutters
                onClick={() => setActivatePlasticCard(true)}
                disabled={!!(!isActivatePlasticCardEnabled || !account.isTermsAccepted)}
              >
                <StyledListItemIcon>
                  <PowerSettingsNewOutlined color="midnight" />
                </StyledListItemIcon>
                <ListItemText
                  primary="Activate Plastic Card"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItemButton>
            </ListItem>
            {/* <Restriction ability="read" feature="manageAccountCancelAndReplaceButton"> */}
            <Restriction ability="read" feature="manageAccountReportLostStolenButton">
              <ListItem disablePadding>
                <ListItemButton
                  disableGutters
                  onClick={() => setReportLostStolen(true)}
                  disabled={!account.isTermsAccepted}
                >
                  <StyledListItemIcon>
                    <ReportProblemOutlined color="midnight" />
                  </StyledListItemIcon>
                  <ListItemText
                    primary="Report Lost / Stolen"
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItemButton>
              </ListItem>
            </Restriction>

            <ListItem disablePadding>
              <ListItemButton
                disableGutters
                onClick={() => setViewTransactionHistory(true)}
                disabled={!account.isTermsAccepted}
              >
                <StyledListItemIcon>
                  <HistoryOutlined color="midnight" />
                </StyledListItemIcon>
                <ListItemText
                  primary="View History"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItemButton>
            </ListItem>

            {/* <ListItem disablePadding>
            <StyledListLink to={`${account.id}/transferFunds`}>
              <ListItemButton disableGutters>
                <StyledListItemIcon>
                  <HistoryOutlined color="midnight" />
                </StyledListItemIcon>
                <ListItemText
                  primary="Transfer Funds"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItemButton>
            </StyledListLink>
          </ListItem> */}

            <ListItem disablePadding>
              <ListItemButton
                disableGutters
                onClick={() => onRetrievePin()}
                disabled={!account.isTermsAccepted}
              >
                <StyledListItemIcon>
                  <LockOutlined color="midnight" />
                </StyledListItemIcon>
                <ListItemText primary="Get PIN" primaryTypographyProps={{ variant: 'body2' }} />
              </ListItemButton>
            </ListItem>
          </List>

          <TransferFundsHelpWrapper variant="caption">
            <em>
              To transfer your card funds to a bank account,{' '}
              <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() => setShowComposeMessage(true)}
              >
                contact the Concierge.
              </Button>
            </em>{' '}
          </TransferFundsHelpWrapper>
        </ContentWrapper>
      </Card>
      <QuickView
        title={'Request Plastic Card'}
        titleIcon={<AddCardTwoTone />}
        iconAltText="Request Plastic Card"
        onClose={() => setRequestPlasticCardModal(false)}
        dialogContent={
          <RequestPlasticCardModal
            setRequestPlasticCardModal={setRequestPlasticCardModal}
            cardHolderDetails={cardHolderDetails}
            reimbursementCardId={account.id}
          />
        }
        isDialogOpen={requestPlasticCardModal}
        maxWidth="md"
      />
      <QuickView
        title="View History"
        titleIcon={<HistoryOutlined />}
        onClose={() => setViewTransactionHistory(false)}
        dialogContent={
          <TransactionHistory reimbursementCardId={account.id} />
        }
        isDialogOpen={viewTransactionHistory}
        maxWidth="md"
      />
      <QuickView
        title="Activate Plastic Card"
        titleIcon={<PowerSettingsNewOutlined />}
        iconAltText="Activate Plastic Card"
        onClose={() => setActivatePlasticCard(false)}
        dialogContent={<ActivatePlasticCard setActivatePlasticCard={setActivatePlasticCard} />}
        isDialogOpen={activatePlasticCard}
        maxWidth="sm"
      />
      <QuickView
        title="Get PIN"
        titleIcon={<LockOutlined />}
        iconAltText="Get PIN"
        onClose={() => setRetrievePinModal(false)}
        dialogContent={
          <PropertyValue
            label="PIN:"
            value={secretPin}
            labelProps={{ variant: 'subtitle2' }}
            valueProps={{ variant: 'subtitle1' }}
            align="row"
          />
        }
        isDialogOpen={retrievePinModal}
        maxWidth="sm"
      />
      <QuickView
        title="Report Lost or Stolen Card"
        titleIcon={<ReportProblemOutlined />}
        iconAltText="Report Lost or Stolen Card"
        onClose={() => setReportLostStolen(false)}
        dialogContent={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body2" gutterBottom>
                Are you sure you want to report this card as lost or stolen?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body1">
                Doing so will disable the card and block any future attempted transactions
              </Typography>
            </Grid>
          </Grid>
        }
        alignActions="flex-start"
        dialogActions={
          <Button color="primary" onClick={() => onLostStolenReport()}>
            Report as Lost / Stolen
          </Button>
        }
        isDialogOpen={reportLostStolen}
        maxWidth="sm"
      />
      <QuickView
        title="Report Lost or Stolen Card"
        onClose={() => {
          setReportLostStolen(false)
          setLostStolenReportedSuccess(false)
        }}
        dialogContent={
          <Typography variant="body2" gutterBottom>
            The Concierge has been notified and will reach out to confirm next steps.
          </Typography>
        }
        isDialogOpen={lostStolenReportedSuccess}
        maxWidth="sm"
      />
      <QuickView
        title="Write New Message"
        onClose={() => setShowComposeMessage(false)}
        dialogContent={<ComposeMessage onCancel={() => setShowComposeMessage(false)} />}
        isDialogOpen={showComposeMessage}
        maxWidth="lg"
      />
    </>
  )
}

Actions.propTypes = {
  cardHolderDetails: PropTypes.object,
  account: PropTypes.object
}

export default Actions
