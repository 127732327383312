import React from 'react'
import { Typography } from '@mui/material'
import parse from 'html-react-parser'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  setViewAnnouncementDetails,
  downloadAttachment,
  getDistributionList,
} from 'store/ducks/communicationCenter/announcements'
import { Bar, Button, AttachmentButton } from 'components/common'
import { Restriction } from 'permissions'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'

const MailHeader = styled.div(
  ({ theme }) => `
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.spacing(2)};
      min-height: 15px;
      h6{
        margin-right: ${theme.spacing(2)};
      }
      button{
        text-decoration: none;
      }
`,
)

const MailWrapper = styled.div(
  ({ theme }) => `
    margin: ${theme.spacing(5)};
`,
)

const MailBody = styled.div(
  ({ theme }) => `
    margin: ${theme.spacing(5)};
`,
)

const AttachedFilesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

const AnnouncementContent = () => {
  const dispatch = useDispatch()
  const { announcement } = useSelector(store => store.announcements)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const { subject, attachments, body } = announcement

  const onViewDetails = () => {
    dispatch(setViewAnnouncementDetails(true))
    dispatch(getDistributionList(announcement))
  }

  return (
    <MailWrapper>
      <MailHeader>
        <Typography variant="subtitle1">{`Subject: ${subject}`}</Typography>
        <Restriction ability="read" feature={`${selectedCommunicationTabId}ViewAnnouncementButton`}>
          <Button onClick={() => onViewDetails()} color="inherit" variant="contained">
              View Details
          </Button>
        </Restriction>
      </MailHeader>
      <Bar color="gray" fullWidth />

      <MailBody elevation={0}>{parse(body)}</MailBody>

      {attachments.length > 0 && (
        <AttachedFilesWrapper>
          {attachments.map((eachFile, index) => (
            <div key={index}>
              <AttachmentButton
                onAttachmentClicked={() => dispatch(downloadAttachment(eachFile))}
                title={eachFile.name}
                size={`${eachFile.size / 1000} KB`}
                filetype={eachFile.type}
              />
            </div>
          ))}
        </AttachedFilesWrapper>
      )}
    </MailWrapper>
  )
}
export default AnnouncementContent
