import { backendAxios, fetchWithRetries, getConfig } from './backend'

export const getConciergeInsights = patientId =>
  backendAxios
    .get('/note/getconciergeinsights', getConfig({ patientId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const addConciergeInsight = insightInfo =>
  backendAxios
    .post('/note/addconciergeinsight', insightInfo, getConfig())
    .then(response => response.data)

export const deleteConciergeInsight = noteInfo =>
  backendAxios.post('/note/deletenote', noteInfo, getConfig()).then(response => response.data)

export const getParticipationProgressNotes = patientId => fetchWithRetries('GET', `/note/participationprogress?cache=${Date.now()}`, getConfig({ patientId }))

export const addParticipationProgressNotes = notesInfo =>
  backendAxios
    .post('/note/participationprogress', notesInfo, getConfig())
    .then(response => response.data)
