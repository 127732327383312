import React, { useEffect, useState } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { AccordionActions, Typography } from '@mui/material'
import { ArrowDropDownOutlined } from '@mui/icons-material'

const AccordionWrapper = styled(MuiAccordion)(({ minbottomgutter, theme, styles }) => ({
  '&&': {
    margin: '0px',
    marginBottom: minbottomgutter === 'true' ? theme.spacing(1) : theme.spacing(3),
    backgroundColor: theme.palette.white.main,
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'white !important',
    },
    ...styles,
  },
}))

const AccordionSummary = styled(MuiAccordionSummary)(({
  gradient, color, theme, highlight, styles,
}) => ({
  '&&': {
    minHeight: '64px',
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '35px',
    },
    '.MuiAccordionSummary-expandIconWrapper': {
      color: highlight === 'true' ? theme.palette[color].contrastText : theme.palette.gray.contrastText,
    },
    backgroundColor:
      highlight === 'true' ? (gradient === 'false' ? theme.palette[color].main : 'unset') : theme.palette.gray.main,
    color: highlight === 'true' ? theme.palette[color].contrastText : theme.palette.gray.contrastText,
    backgroundImage:
      highlight === 'true' && gradient === 'true'
        ? `linear-gradient(to right, ${theme.palette[color].main}, ${theme.palette[color].light})`
        : 'unset',
    ...styles,
  },
}))

const ContentWrapper = styled(MuiAccordionDetails)(({
  theme, background, nocontentpadding, styles,
}) => ({
  '&&': {
    backgroundColor: background === 'none' ? theme.palette.background.paper : theme.palette[background].light,
    padding: nocontentpadding === 'true' && '0px',
    ...styles,
  },
}))

const ActionWrapper = styled(AccordionActions)(
  ({ theme }) => `
&&{
  flex-wrap: wrap;
  justify-content: flex-start;
  border-top: 3px solid ${theme.palette.midnight.main};
  padding-top: ${theme.spacing(3)};
  padding-bottom: ${theme.spacing(3)};
}
`,
)

const AccordionGroup = ({
  accordions,
  labelComponent,
  gradient,
  color,
  transitionProps,
  defaultOpenPanel,
  expandAll,
  handleExpandAll,
  contentBackground,
  minBottomGutter,
  noContentPadding,
  controlled,
  highlightOnExpand,
  highlightOnActive,
  summaryStyles,
  contentStyles,
  wrapperStyles,
  onAccordionExpand
}) => {
  // list of all the expanded(list of true and/or false)
  // ex: if four accordions - [true, false, false, false]
  const [expandedPanels, setExpandedPanels] = useState([])

  useEffect(() => {
    let i = 0
    const expandedArray = []
    if (typeof expandAll === 'boolean') {
      while (i <= accordions.length - 1) {
        if (defaultOpenPanel === i) {
          expandedArray.push(true)
        } else if (expandAll) {
          expandedArray.push(expandAll)
        } else {
          expandedArray.push(false)
        }

        i++
      }
      setExpandedPanels(expandedArray)
    }
  }, [expandAll, defaultOpenPanel, accordions.length])

  const handleChange = accordionIndex => {
    const controlExpanding = [...expandedPanels]
    if (controlled) {
      const newArray = controlExpanding.map((item, index) => {
        if (accordionIndex === index) {
          return !expandedPanels[accordionIndex]
        }
        return false
      })
      setExpandedPanels(newArray)
    } else {
      const replacingElement = {}
      replacingElement[accordionIndex] = !expandedPanels[accordionIndex]
      setExpandedPanels(Object.assign([], controlExpanding, replacingElement))
      handleExpandAll && handleExpandAll(null)
    }
    onAccordionExpand && onAccordionExpand(accordionIndex)
  }

  return (
    <>
      {accordions.map((accordion, index) => (
        <AccordionWrapper
          key={`panel${index}`}
          expanded={expandedPanels[index] ? expandedPanels[index] : false}
          onChange={() => handleChange(index)}
          TransitionProps={transitionProps}
          minbottomgutter={`${minBottomGutter}`}
          styles={wrapperStyles}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownOutlined fontSize="large" />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            data-testid={`panel${index}-header`}
            color={color}
            highlight={`${
              highlightOnActive ? accordion.isActive : highlightOnExpand ? !!expandedPanels[index] : true
            }`}
            gradient={`${gradient}`}
            styles={summaryStyles}
          >
            {labelComponent ? <>{accordion.label}</> : <Typography variant="subtitle1">{accordion.label}</Typography>}
          </AccordionSummary>
          <ContentWrapper
            nocontentpadding={`${noContentPadding}`}
            background={contentBackground}
            styles={contentStyles}
          >
            {accordion.component}
          </ContentWrapper>
          {accordion.actions && <ActionWrapper>{accordion.actions}</ActionWrapper>}
        </AccordionWrapper>
      ))}
    </>
  )
}

AccordionGroup.propTypes = {
  /**
   * - Each item passed in this array, will create one accordion.
   * - Label, shows up in the top left corner of the accordion.
   * - Component is a node, you can pass React component/string.
   * - For Actions, you can pass button or any other click events, that will show up at the end.
   * - note: isActive works only when highlightOnActive is true
   */
  accordions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      component: PropTypes.node.isRequired,
      actions: PropTypes.node,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * when true, label will be node instead of string
   * @default false
   */
  labelComponent: PropTypes.bool,
  /**
   * When true uses `main` and `light` to create gradient style color for the accordion wrapper
   */
  gradient: PropTypes.bool,
  /**
   * - when selected any of the available values, the color is applied to the wrapper of the accordion.
   * - Also note, the label and the icon color are taken care by the `contrastText` from the same selected color palette
   * @default `primary`
   */
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
    'gradientBlue',
    'gradientPink',
    'gradientGreen',
    'gradientPurple',
    'gradientOrange',
    'gray',
  ]),
  transitionProps: PropTypes.object,
  /**
   * Pass 'index' of the accordion you want to open by default.
   */
  defaultOpenPanel: PropTypes.number,
  /**
   * When true, expands all the panels
   * @default false
   */
  expandAll: PropTypes.bool,
  /**
   * returns null when called, can be used to reset expandAll flag in the parent component
   */
  handleExpandAll: PropTypes.func,
  /**
   * @default 'secondary'
   */
  contentBackground: PropTypes.oneOf(['secondary', 'gray', 'none']),
  /**
   * Each accordion will have less bottom margin
   * @default false
   */
  minBottomGutter: PropTypes.bool,
  /**
   * Each accordion content will have no padding
   * @default false
   */
  noContentPadding: PropTypes.bool,
  /**
   * When controlled, Opened accordion(if any) is closed, when some other accordion is expanded
   * @default false
   */
  controlled: PropTypes.bool,
  /**
   * When highlightOnExpand, the expanded accordion will have colored summary tab
   * Note: the prop passed to color is used to highlight the accordion and the rest of the accordions will have gray tab/header
   * @default false
   */
  highlightOnExpand: PropTypes.bool,
  /**
   * @default false
   * when true, highlights the one accordion tab in which an item is selected/active
   * active or selection is controlled by passing isActive flag for each item
   * Note, this will override highlightOnExpand prop
   */
  highlightOnActive: PropTypes.bool,
  /**
   * Styles applied to each accordion's summary
   */
  summaryStyles: PropTypes.object,
  /**
   * Styles applied to content
   */
  contentStyles: PropTypes.object,
  /**
   * Styles applied to the wrapper
   */
  wrapperStyles: PropTypes.object,
  /**
   * triggers when accordion is expanded
   */
  onAccordionExpand: PropTypes.func
}

AccordionGroup.defaultProps = {
  color: 'primary',
  gradient: false,
  transitionProps: {},
  defaultOpenPanel: 0,
  labelComponent: false,
  expandAll: false,
  contentBackground: 'secondary',
  minBottomGutter: false,
  noContentPadding: false,
  highlightOnExpand: false,
  highlightOnActive: false,
}

export default AccordionGroup
