import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { Button } from 'components/common'
import { resetSuccessMessages } from 'components/helper/constants/auth'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { performLogout } from 'store/ducks/auth'

const BackToLoginButton = styled(Button)`
  width: fit-content;
`

const MessageSentText = styled(Typography)(
  ({ theme }) => `
        margin-bottom: ${theme.spacing(5)}
    `
)

const SuccessScreen = () => {
  const dispatch = useDispatch()
  const { flow } = useParams()
  const navigate = useNavigate()

  return <>
    <Typography gutterBottom variant="h2" color="primary">
          Success
    </Typography>
    <MessageSentText gutterBottom variant="body1">
      {resetSuccessMessages[flow]}
    </MessageSentText>
    <BackToLoginButton
      color="primary"
      size="large"
      fullWidth={true}
      onClick={() => {
        flow === 'password'
          ? navigate('/login')
          : dispatch(performLogout())
      }}
    >
          Back to Log In
    </BackToLoginButton>
  </>
}

export default SuccessScreen
