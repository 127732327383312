import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment } from '@mui/material'
import { TextBox, TextBoxOuterLabel } from 'components/common'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const Password = props => {
  const { outerLabel, startAdornment, ...rest } = props
  const [hide, setHide] = useState(true)

  const showHideHandler = () => {
    setHide(prevValue => !prevValue)
  }

  return outerLabel
    ? <TextBoxOuterLabel
      {...rest}
      type={hide ? 'password' : 'text'}
      startAdornment={startAdornment}
      endAdornment={<InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={showHideHandler}
          size="large"
        >
          {hide ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>}
    />
    : <TextBox
      {...rest}
      type={hide ? 'password' : 'text'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={showHideHandler}
              size="large"
            >
              {hide ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        startAdornment
      }}
    />
}

Password.propTypes = {
  /**
   * @default false
   * When true uses TextBox component with outer label
   */
  outerLabel: PropTypes.bool,
  /**
   * Adds a icon in the beginning of the input field
   */
  startAdornment: PropTypes.node
}

Password.defaultProps = {
  outerLabel: false
}

export default Password
