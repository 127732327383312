import { backendAxios, fetchWithRetries, getConfig } from '../backend'

export const getParticipationProgressHistory = userId =>
  fetchWithRetries(
    'GET',
    `/patientparticipation/history?cache=${Date.now()}`,
    getConfig({ userId })
  )

export const getParticipationProgressLeadSheet = userId =>
  fetchWithRetries('GET', '/patientparticipation/Leadsheet', getConfig({ userId }))

export const saveParticipationProgressDetails = requestData =>
  backendAxios
    .post('/patientparticipation/details', requestData, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getSitePrescreenerQuestions = userId =>
  backendAxios
    .get('/patientparticipation/siteprescreenerquestions', getConfig({ userId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getConciergePrescreenerQuestions = userId =>
  backendAxios
    .get('/patientparticipation/conciergeprescreenerquestions', getConfig({ userId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const addSitePrescreenerQuestion = requestData =>
  backendAxios
    .post('/patientparticipation/siteprescreenerquestion', requestData, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const addConciergePrescreenerQuestion = requestData =>
  backendAxios
    .post('/patientparticipation/conciergeprescreenerquestion', requestData, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const saveAnswerPrescreenerQuestion = requestData =>
  backendAxios
    .post('/patientparticipation/answerprescreenerquestion', requestData, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getPatientParticipationInformation = (id, invalidateCache = false) => {
  let apiUrl = '/patientparticipation/getdetails'
  if (invalidateCache) {
    apiUrl = `${apiUrl}/?cache=${Date.now()}`
  }

  return fetchWithRetries('GET', apiUrl, getConfig({ userId: id }))
}
