import React from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { TextBoxOuterLabel } from 'components/common'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const LabelWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexDirection: 'column',
    'p:not(:nth-of-type(2))': {
      marginBottom: theme.spacing(2)
    }
  }
}))

const SmallTextTypography = styled(Typography)`
  font-weight: normal;
  font-style: italic;
`

const Accommodations = () => {
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  return <Grid container spacing={2}>
    <Grid sb={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
      <TextBoxOuterLabel
        id="accommodationsDetails"
        data-testid="accommodationsDetails"
        label={
          <LabelWrapper>
            <Typography variant="body2" gutterBottom>
              Please provide details and other relevant considerations below for your patient’s needs. The Concierge will be in direct contact with you to discuss plans to fulfill this request.
            </Typography>

            <SmallTextTypography variant="caption">
              (e.g. accommodations, length of stay, number of visits covered, housekeeping, shopping, childcare, identification of local resources etc.).
            </SmallTextTypography>
          </LabelWrapper>
        }
        name="accommodationsDetails"
        multiline={true}
        rows={3}
        placeholder="Special Assistance Details"
        disabled={readOnly}
      />
    </Grid>
  </Grid>
}

export default Accommodations
