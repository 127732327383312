import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material'
import {
  AccordionGroup,
  AutoCompleteWithLabel,
  Bar,
  Button,
  DatePickerWithLabel,
  NumericTextField,
  PageHeader,
  TextBoxOuterLabel
} from 'components/common'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchMediaPlanDetails, onUpdateMediaPlan } from 'store/ducks/mediaPlans'
import { formElements } from 'components/helper/constants/common'
import { validationMapper } from 'components/helper/validationEngine'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: `${theme.spacing(5)}`,
    marginBottom: `${theme.spacing(5)}`
  }
}))

const ContentWrapper = styled.div(() => ({
  '&&': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  }
}))

const GridContainer = styled(Grid)(({ theme }) => ({
  '&&': {
    width: '48%',
    marginTop: theme.spacing(2)
  }
}))

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
          button {
            margin-top: ${theme.spacing(1)};
            margin-right: ${theme.spacing(2)};
            height: fit-content;
          }
          display: flex;
          align-items: start;
          justify-content: flex-start;
      `
)

const validationSchema = validationMapper([
  { id: 'planName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'sites', isResponseRequired: true, answerType: formElements.multiSelectDropdown },
  { id: 'planStatus', isResponseRequired: true, answerType: formElements.dropdownMenu },
  { id: 'tactics', isResponseRequired: true, answerType: formElements.multiSelectDropdown },
  { id: 'plannedStartDate', isResponseRequired: true, answerType: formElements.date },
  { id: 'actualInquiriesToDate', isResponseRequired: true, answerType: formElements.date },
  { id: 'actualMediaStartDate', isResponseRequired: true, answerType: formElements.date },
  { id: 'dataLockDateForActuals', isResponseRequired: true, answerType: formElements.date },
  { id: 'plannedEndDate', isResponseRequired: true, answerType: formElements.date },
  { id: 'plannedInquiries', isResponseRequired: true, answerType: formElements.number },
  { id: 'plannedReferrals', isResponseRequired: true, answerType: formElements.number },
  { id: 'totalBudget', isResponseRequired: true, answerType: formElements.number },
  { id: 'netPlacementBudget', isResponseRequired: true, answerType: formElements.number },
  { id: 'bbkFees', isResponseRequired: true, answerType: formElements.number },
  { id: 'vendorFees', isResponseRequired: true, answerType: formElements.number },
  { id: 'netPlacementSpendToDate', isResponseRequired: true, answerType: formElements.number },
])

const MediaPlanDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mediaPlanId } = useParams()
  const { mediaPlanDetails } = useSelector(store => store.mediaPlans)
  const { selectedInstance } = useSelector(store => store.auth)
  const { instanceId } = selectedInstance
  const { mediaPlanSitesLookup, tacticsLookup, mediaPlanStatusesLookup } = useSelector(
    store => store.common
  )

  useEffect(() => {
    dispatch(fetchMediaPlanDetails(mediaPlanId))
  }, [mediaPlanId])

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Media Plans</Typography>}
        actionContent={
          <Button
            size="large"
            fullWidth={false}
            color="inherit"
            onClick={() => navigate(`/instance/${instanceId}/media-plans`)}
          >
            Back
          </Button>
        }
      />
      {mediaPlanDetails.isLoading
        ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                <Skeleton height={64} />
                <Skeleton height={64} />
                <Skeleton height={64} />
              </Box>
            </Grid>
          </Grid>
        )
        : mediaPlanDetails.results.mediaPlanId
          ? (
            <Formik
              initialValues={mediaPlanDetails.results}
              enableReinitialize={false}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(onUpdateMediaPlan({ ...values, mediaPlanId }))
                setSubmitting(false)
              }}
            >
              {({ dirty }) => (
                <Form noValidate>
                  <MainWrapper elevation={0}>
                    {/* ---------------- first ---------------- */}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextBoxOuterLabel
                          id="planName"
                          label="Media Plan Name"
                          name="planName"
                          fullWidth={true}
                          size="small"
                          required
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <AutoCompleteWithLabel
                          id="planStatus"
                          label="Media Plan Status"
                          name="planStatus"
                          options={mediaPlanStatusesLookup.results}
                          isLoading={mediaPlanStatusesLookup.isLoading}
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} />
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <AutoCompleteWithLabel
                          id="sites"
                          label="Associated Sites"
                          name="sites"
                          options={mediaPlanSitesLookup.results}
                          isLoading={mediaPlanSitesLookup.isLoading}
                          size="small"
                          required
                          multiple={true}
                          hasSelectAllOption={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <AutoCompleteWithLabel
                          id="tactics"
                          label="Tactics"
                          name="tactics"
                          options={tacticsLookup.results}
                          isLoading={tacticsLookup.isLoading}
                          size="small"
                          required
                          multiple={true}
                          hasSelectAllOption={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <DatePickerWithLabel
                          id="actualMediaEndDate"
                          label="Actual Media End Date"
                          name="actualMediaEndDate"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </MainWrapper>
                  <AccordionGroup
                    accordions={[
                      {
                        label: <Typography variant="subtitle1">Media Dates</Typography>,
                        component: (
                          <ContentWrapper>
                            {/* ---------------- first - left ---------------- */}
                            <GridContainer container spacing={2}>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <DatePickerWithLabel
                                  id="plannedStartDate"
                                  label="Planned Start Date"
                                  required={true}
                                  name="plannedStartDate"
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <DatePickerWithLabel
                                  id="plannedEndDate"
                                  label="Planned End Date"
                                  required={true}
                                  name="plannedEndDate"
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  fullWidth={true}
                                  id="plannedFlightTimeframeDays"
                                  label="Planned Flight Timeframe"
                                  name="plannedFlightTimeframeDays"
                                  size="small"
                                  disabled={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <NumericTextField
                                  id="totalBudget"
                                  label="Total Budget"
                                  name="totalBudget"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  disabled={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  id="netPlacementBudget"
                                  label="Planned Net Placement Budget"
                                  name="netPlacementBudget"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  inputProps={{ min: 0 }}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  id="bbkFees"
                                  label="BBK Fees"
                                  name="bbkFees"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  inputProps={{ min: 0 }}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  id="vendorFees"
                                  label="Vendor Fees"
                                  name="vendorFees"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  inputProps={{ min: 0 }}
                                  required={true}
                                />
                              </Grid>
                            </GridContainer>
                            <Bar vertical color="gray" />
                            {/*  ---------------- first-right ---------------- */}
                            <GridContainer container spacing={2}>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <DatePickerWithLabel
                                  id="actualMediaStartDate"
                                  label="Actual Media Start Date"
                                  name="actualMediaStartDate"
                                  size="small"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <DatePickerWithLabel
                                  id="dataLockDateForActuals"
                                  label="Data Lock Date for Actuals"
                                  required={true}
                                  name="dataLockDateForActuals"
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  fullWidth={true}
                                  id="daysLive"
                                  label="Days Live"
                                  name="daysLive"
                                  size="small"
                                  disabled={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <NumericTextField
                                  id="totalSpendtoDate"
                                  label="Total Spend to Date"
                                  name="totalSpendtoDate"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  disabled={true}
                                  inputProps={{ min: 0 }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  id="netPlacementSpendToDate"
                                  label="Net Placement Spend to Date"
                                  name="netPlacementSpendToDate"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  inputProps={{ min: 0 }}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  id="bbkFeesIncurredToDate"
                                  label="BBK Fees Incurred to Date"
                                  name="bbkFeesIncurredToDate"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  inputProps={{ min: 0 }}
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  id="vendorFeesIncurredToDate"
                                  label="Vendor Fees Incurred to Date"
                                  name="vendorFeesIncurredToDate"
                                  fullWidth={true}
                                  size="small"
                                  startAdornment="$"
                                  inputProps={{ min: 0 }}
                                  disabled
                                />
                              </Grid>
                            </GridContainer>
                          </ContentWrapper>
                        )
                      }
                    ]}
                    color="primary"
                    labelComponent={true}
                  />

                  <AccordionGroup
                    accordions={[
                      {
                        label: <Typography variant="subtitle1">Inquiries / Referrals</Typography>,
                        component: (
                          <ContentWrapper>
                            {/* ---------------- second-left ---------------- */}
                            <GridContainer container spacing={2}>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  id="plannedInquiries"
                                  label="Planned Inquiries"
                                  name="plannedInquiries"
                                  fullWidth={true}
                                  size="small"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  id="plannedReferrals"
                                  label="Planned Referrals"
                                  name="plannedReferrals"
                                  fullWidth={true}
                                  size="small"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4} />
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextBoxOuterLabel
                                  id="plannedInquirytoReferral"
                                  label="Planned Inquiry to Referral"
                                  name="plannedInquirytoReferral"
                                  fullWidth={true}
                                  size="small"
                                  disabled={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  id="plannedCostPerReferral"
                                  label="Planned Cost per Referral"
                                  name="plannedCostPerReferral"
                                  fullWidth={true}
                                  size="small"
                                  disabled={true}
                                  startAdornment="$"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4} />
                            </GridContainer>
                            <Bar vertical color="gray" />
                            {/* ---------------- second - right ---------------- */}
                            <GridContainer container spacing={2}>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  id="actualInquiriesToDate"
                                  label="Actual Inquiries to Date"
                                  name="actualInquiriesToDate"
                                  fullWidth={true}
                                  size="small"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  id="actualReferralsToDate"
                                  label="Actual Referrals to Date"
                                  name="actualReferralsToDate"
                                  fullWidth={true}
                                  size="small"
                                  disabled={false}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4} />
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextBoxOuterLabel
                                  id="actualInquiryToReferral"
                                  label="Actual Inquiry to Referral"
                                  name="actualInquiryToReferral"
                                  fullWidth={true}
                                  size="small"
                                  disabled={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <NumericTextField
                                  inputProps={{ min: 0 }}
                                  id="actualCostPerReferral"
                                  label="Actual Cost per Referral"
                                  name="actualCostPerReferral"
                                  fullWidth={true}
                                  size="small"
                                  disabled={true}
                                  startAdornment="$"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4} />
                            </GridContainer>
                          </ContentWrapper>
                        )
                      }
                    ]}
                    color="primary"
                    labelComponent={true}
                  />
                  <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
                    <Button size="large" fullWidth={false} color="primary" type="submit" disabled={!dirty}>
                  Save
                    </Button>
                    <Button
                      size="large"
                      fullWidth={false}
                      color="inherit"
                      onClick={() => {
                        navigate(`/instance/${instanceId}/media-plans`)
                      }}
                    >
                  Cancel
                    </Button>
                  </ActionWrapper>
                </Form>
              )}
            </Formik>
          )
          : (
            <Typography variant="subtitle1">
          Error while fetching Media Plan details. Please refresh your page or try again later.
            </Typography>
          )}
    </>
  )
}
export default MediaPlanDetails
