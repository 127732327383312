import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import RequestPlasticCardModal from '../manageAccount/RequestPlasticCardModal'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { AddCardTwoTone } from '@mui/icons-material'
import {
  onConfirmPatientReimbursementInfo,
  setSignUpSuccess
} from 'store/ducks/patientRecord/reimbursement'
import { fetchCountriesLookup } from 'store/ducks/common'

import {
  AutoCompleteWithLabel,
  Button,
  Checkbox,
  TextBoxOuterLabel,
  PageHeader,
  DatePickerWithLabel,
  Alert
} from 'components/common'
import StatesDropdown from 'components/common/form/StatesDropdown'
import QuickView from 'components/common/QuickView'
import SignUpSuccess from './SignUpSuccess'
import { Restriction } from 'permissions'
import * as Yup from 'yup'
import { alphaNumber, zip } from 'components/helper/constants/regex'
import { validationSchemaObject } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const validationSchema = validationSchemaObject([
  { id: 'firstName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'lastName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'emailAddress', isResponseRequired: true, answerType: formElements.emailOpenText },
  {
    id: 'emailAddress',
    isResponseRequired: true,
    answerType: formElements.openText,
    dependency: {
      matchWith: true,
      ref: 'emailAddress',
      message: 'Email must match'
    }
  },
  { id: 'address1', isResponseRequired: true, answerType: formElements.openText },
  { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
  { id: 'state', isResponseRequired: true, answerType: formElements.dropdownMenu },
  { id: 'city', isResponseRequired: true, answerType: formElements.openText },
  { id: 'zipCode', isResponseRequired: true, answerType: formElements.openText },
  { id: 'dateOfBirth', isResponseRequired: true, answerType: formElements.openText },
  {
    id: 'isReimbursementAcknowledged',
    isResponseRequired: true,
    answerType: formElements.checkbox
  }
])

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(1)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: start;
      justify-content: flex-start;
  `
)

const Wrapper = styled.div(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
`
)

const FormWrapper = styled.div(
  ({ theme }) => `
  margin-top: ${theme.spacing(4)};
`
)
const LastNameGrid = styled(Grid)(
  ({ theme }) => `
  margin-right: ${theme.spacing(4)};
`
)

const AddressGrid = styled(Grid)(
  ({ theme }) => `
  margin-top: ${theme.spacing(4)};
`
)
const CountryGrid = styled(Grid)(
  ({ theme }) => `
  margin-right: ${theme.spacing(4)};
`
)

const ConfirmPatientSupportInfo = () => {
  const dispatch = useDispatch()
  const [requestPlasticCardModal, setRequestPlasticCardModal] = useState(false)
  const { signUpSuccess, patientInformationForReimbursement } = useSelector(
    store => store.reimbursement
  )
  const { countriesLookup } = useSelector(store => store.common)

  const isDisabled = true

  useEffect(() => {
    dispatch(fetchCountriesLookup())
  }, [])

  return (
    <Wrapper>
      <PageHeader
        title={<Typography variant="subtitle1">Patient</Typography>}
        expandContent={true}
        bar={true}
        content={
          patientInformationForReimbursement.userId && (
            <FormWrapper>
              <Formik
                initialValues={patientInformationForReimbursement}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  ...validationSchema,
                  ...{
                    submitValidation: Yup.bool().when(['firstName', 'middleInitial', 'lastName', 'address1', 'address2', 'city', 'zipCode'], {
                      // eslint-disable-next-line max-params
                      is: (firstName, middleInitial, lastName, address1, address2, city, zipCode) =>
                        !(alphaNumber.test(firstName) && alphaNumber.test(middleInitial) && alphaNumber.test(lastName) && alphaNumber.test(address1) && alphaNumber.test(address2) && alphaNumber.test(city) && zip.test(zipCode)),
                      then: Yup.bool().required('Please navigate to the Patient Profile and remove any special characters from the name or address fields.'),
                      otherwise: Yup.bool(),
                    })
                  }
                })}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(
                    onConfirmPatientReimbursementInfo(patientInformationForReimbursement.userId)
                  )
                  setSubmitting(false)
                }}
              >
                {({ values }) => (
                  <Form noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3} lg={2.5}>
                        <TextBoxOuterLabel
                          id="first-name"
                          disabled={isDisabled}
                          label="First Name"
                          name="firstName"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={2} lg={1.5}>
                        <TextBoxOuterLabel
                          id="middle-initial"
                          disabled={isDisabled}
                          label="Middle Initial"
                          name="middleInitial"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>
                      <LastNameGrid item xs={12} sm={6} md={3} lg={2.5}>
                        <TextBoxOuterLabel
                          id="last-name"
                          disabled={isDisabled}
                          label="Last Name"
                          name="lastName"
                          fullWidth={true}
                          size="small"
                        />
                      </LastNameGrid>
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <DatePickerWithLabel
                          id="date-of-birth"
                          label="Date of Birth"
                          name="dateOfBirth"
                          minDate={new Date()}
                          size="small"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6.5}>
                        <TextBoxOuterLabel
                          id="email-address"
                          disabled={isDisabled}
                          label="Email Address"
                          name="emailAddress"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                    <AddressGrid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3} lg={2.5}>
                        <TextBoxOuterLabel
                          id="address1"
                          disabled={isDisabled}
                          label="Address Line 1"
                          name="address1"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={2} lg={1.5}>
                        <TextBoxOuterLabel
                          fullWidth={true}
                          size="small"
                          id="address2"
                          disabled={isDisabled}
                          label="Address Line 2"
                          name="address2"
                        />
                      </Grid>

                      <CountryGrid item xs={12} sm={6} md={3} lg={2.5}>
                        <AutoCompleteWithLabel
                          multiple={false}
                          size="small"
                          hasSelectAllOption={false}
                          id="country"
                          disabled={isDisabled}
                          label="Country"
                          name="country"
                          options={countriesLookup.results}
                          isLoading={countriesLookup.isLoading}
                        />
                      </CountryGrid>
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <StatesDropdown
                          multiple={false}
                          size="small"
                          hasSelectAllOption={false}
                          id="state"
                          disabled={isDisabled}
                          label="County / State"
                          name="state"
                          selectedCountry={values.country?.id}
                          outerLabel={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <TextBoxOuterLabel
                          fullWidth={true}
                          size="small"
                          id="city"
                          disabled={isDisabled}
                          label="City"
                          name="city"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <TextBoxOuterLabel
                          fullWidth={true}
                          size="small"
                          id="zip"
                          disabled={isDisabled}
                          label="Zip / Postal Code"
                          name="zipCode"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Checkbox
                          name="isReimbursementAcknowledged"
                          id="confirmed"
                          disabled={
                            !!patientInformationForReimbursement.isReimbursementAcknowledged
                          }
                          label="I confirm the information above is complete and correct, and that the person identified with this
                          information is willing to share their data for this service. Furthermore, the information will not be
                          shared with anyone other than study-related personnel or entities."
                          size="small"
                          labelProps={{ variant: 'body2' }}
                        />
                      </Grid>
                      <Restriction ability="read" feature="reimbursementSignUpSubmitButton">
                        {
                          !patientInformationForReimbursement.isReimbursementAcknowledged
                            ? (
                              <>
                                <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                                  <Alert name="submitValidation" severity="error" />
                                </ActionWrapper>
                                <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
                                  <Button
                                    size="large"
                                    fullWidth={false}
                                    color="primary"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </ActionWrapper>
                              </>
                            )
                            : ''
                        }
                      </Restriction>
                    </AddressGrid>
                  </Form>
                )}
              </Formik>
            </FormWrapper>
          )
        }
      />
      <QuickView
        title="Success!"
        onClose={() => {
          dispatch(setSignUpSuccess(false))
        }}
        dialogContent={
          <SignUpSuccess
            isAbleToRequestPlasticCard={
              patientInformationForReimbursement.isAbleToRequestPlasticCard
            }
          />
        }
        isDialogOpen={signUpSuccess}
        disableBackdropClick={true}
        maxWidth="sm"
        dialogContentStyles={{ button: { marginLeft: '20px' } }}
        dialogActions={
          <Button
            size="medium"
            fullWidth={false}
            color="primary"
            onClick={() => dispatch(setSignUpSuccess(false))}
          >
            Ok
          </Button>
          // patientInformationForReimbursement.isAbleToRequestPlasticCard
          // ? (
          //   <>
          //     <Button
          //       size="medium"
          //       fullWidth={false}
          //       color="primary"
          //       onClick={() => {
          //         dispatch(setSignUpSuccess(false))
          //         setRequestPlasticCardModal(true)
          //       }}
          //     >
          //     Request Plastic Card
          //     </Button>
          //     <Button
          //       size="medium"
          //       fullWidth={false}
          //       color="inherit"
          //       onClick={() => dispatch(setSignUpSuccess(false))}
          //     >
          //     Decide Later
          //     </Button>
          //   </>
          // )
        }
      />
      <QuickView
        title="Request Plastic Card"
        titleIcon={<AddCardTwoTone />}
        iconAltText="Request Plastic Card"
        onClose={() => setRequestPlasticCardModal(false)}
        dialogContent={
          <RequestPlasticCardModal
            setRequestPlasticCardModal={setRequestPlasticCardModal}
            cardHolderDetails={patientInformationForReimbursement}
          />
        }
        isDialogOpen={requestPlasticCardModal}
        maxWidth="md"
      />
    </Wrapper>
  )
}

export default ConfirmPatientSupportInfo
