import { useDispatch, useSelector } from 'react-redux'
import { Grid, Tooltip, Typography } from '@mui/material'
import { AutoCompleteWithLabel, DatePickerWithLabel, TextBoxOuterLabel } from 'components/common'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Error } from '@mui/icons-material'
import {
  fetchVisitNames,
  setVisitNames
} from 'store/ducks/visitServices/requestDetails'

const LabelForVisitNames = styled.div(
  ({ theme }) => `
      display: flex;  
      svg{
        top: -1px;
        width: 1.2rem;
        height: 1.2rem;
        right: -17px;
      }
      p {
        margin-right: ${theme.spacing(1 / 2)};
      }`
)

const AutoCompleteGrid = styled(Grid)(() => ({
  '&&': {
    '.MuiGrid-root:last-child': {
      paddingTop: '26px'
    }
  }
}))

const VisitDetailsForm = () => {
  const { visitDetails, visitNames, visitScheduleGroup } = useSelector(
    store => store.requestDetails
  )
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { requestId } = useParams()
  const dispatch = useDispatch()

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <AutoCompleteGrid container spacing={2}>
          {visitScheduleGroup.results.length > 1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AutoCompleteWithLabel
                id="visitScheduleGroup"
                label="Select a Visit Schedule"
                name="visitScheduleGroup"
                onChange={(event, selectedValue, reason) => {
                  if (reason === 'selectOption') {
                    dispatch(fetchVisitNames(patientInformation.userId, selectedValue.id))
                  } else if (reason === 'clear') {
                    dispatch(
                      setVisitNames({
                        isLoading: false,
                        results: []
                      })
                    )
                  }
                }}
                options={visitScheduleGroup.results}
                isLoading={visitScheduleGroup.isLoading}
                size="small"
                disabled={readOnly}
                multiple={false}
                hasSelectAllOption={false}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AutoCompleteWithLabel
              multiple={false}
              hasSelectAllOption={false}
              size="small"
              id="visitNames"
              name="visitNames"
              label={
                <LabelForVisitNames>
                  <Typography variant="body2">Study Visit</Typography>
                  <Tooltip
                    color="primary"
                    size="small"
                    title={
                      <Typography variant="body1">
                    If your request will apply to more than one study visit, please select the first
                    applicable study visit.
                      </Typography>
                    }
                  >
                    <Error size="small" />
                  </Tooltip>
                </LabelForVisitNames>
              }
              isLoading={visitNames.isLoading}
              options={visitNames.results}
              disabled={readOnly}
            />
          </Grid>
        </AutoCompleteGrid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DatePickerWithLabel
              id="visitStartDate"
              label="Visit Start Date"
              name="visitStartDate"
              required={true}
              minDate={new Date()}
              size="small"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DatePickerWithLabel
              id="visitEndDate"
              label="Visit End Date"
              name="visitEndDate"
              required={true}
              minDate={new Date()}
              size="small"
              disabled={readOnly}
            />
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextBoxOuterLabel
              id="arrivalTime"
              label="Arrival Time for This Visit:"
              name="arrivalTime"
              required={true}
              type="time"
              size="small"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextBoxOuterLabel
              id="departureTime"
              label="Approximate Departure Time from This Visit:"
              name="departureTime"
              required={true}
              type="time"
              size="small"
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default VisitDetailsForm
