import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/common'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Bar } from '../utils'

const MainWrapper = styled.span(
  ({ selected, theme }) => `
 width: 100%;
 padding: ${theme.spacing(1, 1, 1, 1)};
 display: flex;
 justify-content: space-between;
 ${
  selected
    ? `
    background-color: ${theme.palette.primary.main};
      `
    : `
    background-color: ${theme.palette.white.main};
    
      `
}
 `
)

const TabButton = styled(Button)`
  width: 100%;
  text-decoration: none;
  display: flex;
  padding: 0px;
  flex-direction: column;
  :hover {
    background-color: unset;
  }
`

const LabelWrapper = styled.div(
  ({ theme }) => `
      display: flex;
      justify-content: flex-start;
      padding: ${theme.spacing(0.5)};
      width: 100%;   
    `
)

const Content = styled.div(
  ({ tab }) => `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: ${tab === 'announcement' ? 'center' : 'start'};
  text-align: ${tab === 'announcement' ? 'center' : 'start'};
  min-height: 60px;
`
)

const Title = styled(Typography)(
  ({ theme, selected, tab }) =>
    `
      width: ${tab === 'announcement' ? '320px' : '250px'};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    ${
  selected
    ? `
        color: ${theme.palette.text.light};
          `
    : `
            color: ${theme.palette.primary.main};
          `
}
   
  `
)

const SubTitle = styled(Typography)(
  ({ theme, selected, tab }) =>
    `
    margin-bottom: ${theme.spacing(1)};
    text-align: ${tab === 'announcement' ? 'center' : 'start'};
    ${
  selected
    ? `
        color: ${theme.palette.other.lightBlue};
          `
    : `
            color: ${theme.palette.primary.light};
          `
}
   
  `
)
const HeaderWrapper = styled.span(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    margin-left: ${theme.spacing(1 / 2)};
    margin-right: ${theme.spacing(1 / 2)};
`
)

const Header = styled(Typography)(
  ({ theme, selected, tab }) =>
    `
      font-style: italic;
      text-align: ${tab === 'announcement' ? 'center' : 'start'};
      width: ${tab === 'announcement' ? '100%' : 'none'};
      ${
  selected
    ? `
          color: ${theme.palette.white.light};
            `
    : `
              color: ${theme.palette.gray.dark};
            `
}
     
    `
)

const Footer = styled(Typography)(
  ({ theme, selected }) =>
    `
      ${
  selected
    ? `
              color: ${theme.palette.white.dark};
            `
    : `
              color: ${theme.palette.text.main};
            `
}
     
    `
)

const ActionWrapper = styled.div(
  ({ theme, selected }) => `
        ${
  selected
    ? `
  
        color: ${theme.palette.white.dark};
        `
    : `
        color: ${theme.palette.primary.main};
        `
}
        `
)
const ContentWrapper = styled.div(({ theme }) => ({
  '&&': {
    width: '100%',
    padding: theme.spacing(1, 1, 1, 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
}))

const UnreadBadge = styled.div(({ theme, selected }) => ({
  '&&': {
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    backgroundColor: selected ? theme.palette.gray.main : theme.palette.primary.main,
    alignSelf: 'center',
    // marginRight: '5px'
  }
}))

const MailNavTab = ({
  onTabChange,
  action,
  selected,
  viewed,
  header,
  title,
  subtitle,
  footer,
  tab
}) => (
  <>
    <MainWrapper selected={selected}>
      {viewed === 'false' && <UnreadBadge selected={selected}/>}
      <ContentWrapper>
        <HeaderWrapper>
          <Header selected={selected} variant="caption" tab={tab}>
            {header}
          </Header>
          {action && <ActionWrapper selected={selected}>{action}</ActionWrapper>}
        </HeaderWrapper>
        <TabButton
          onClick={() => {
            onTabChange()
          }}
          fullWidth
          aria-label="mail-tab"
          size="medium"
          variant="text"
          color="inherit"
        >
          <LabelWrapper>
            <Content tab={tab}>
              <span>
                <Title selected={selected} variant="subtitle1" tab={tab}>
                  {title}
                </Title>
                <SubTitle selected={selected} variant="body1" tab={tab}>
                  {subtitle}
                </SubTitle>
              </span>
              <Footer selected={selected} variant="caption">
                {footer}
              </Footer>
            </Content>
          </LabelWrapper>
        </TabButton>
      </ContentWrapper>
    </MainWrapper>
    {!selected && viewed && <Bar fullWidth={true} color="gray" />}
  </>
)

MailNavTab.propTypes = {
  /**
   * Changes to the clicked tab
   */
  onTabChange: PropTypes.func,
  /**
   * Pass action node to show on the top right corner of tha tab
   */
  action: PropTypes.node,
  /**
   * pass selected value so that tab will show UI effects based on selection
   */
  selected: PropTypes.bool,
  /**
   * show text or node on the top
   */
  header: PropTypes.node,
  /**
   * Title is shown in the middle of the card
   */
  title: PropTypes.node.isRequired,
  /**
   * subtitle is shown just below the title
   */
  subtitle: PropTypes.node,
  /**
   * Node or text shown at the end of the card
   * @default false
   */
  footer: PropTypes.node,
  /**
   * Pass boolean value to show Ui effects based on viewed
   * @default true
   */
  viewed: PropTypes.oneOf(['true', 'false']),
  /**
   * Which tab is it used in
   * @default ""
   */
  tab: PropTypes.string
}

MailNavTab.defaultProps = {
  selected: false,
  viewed: 'false',
  tab: ''
}

export default MailNavTab
