import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { showWarning } from 'store/ducks/application'

import {
  uploadArriveGuidelinesDocument,
  deleteArriveGuidelinesDocument
} from 'store/ducks/configuration/arriveConfiguration'

import { Button, AttachFile, Bar } from 'components/common'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const AlignCenterGrid = styled(Grid)`
  text-align: center;
`
const AlignLeftGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'left',
  marginRight: theme.spacing(2)
}))

const TitleWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const AttachmentsWrapper = styled.div(({ theme }) => ({
  margin: theme.spacing(2)
}))

const AttachmentWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  display: 'flex',
  width: '100%'
}))

const AttachmentNameContainer = styled.div(() => ({
  width: '40%'
}))

const DeleteButtonContainer = styled.div(() => ({
  width: '60%'
}))

const DescriptionWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

const MainContainer = styled.div(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
}))

const BarContainer = styled.div(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}))

const attachFileStyles = {
  justifyContent: 'flex-start',
}

const validationSchema = () =>
  validationMapper([
    {
      id: 'attachments',
      isResponseRequired: true,
      answerType: formElements.attachment
    }
  ])

const UploadArriveGuidelines = () => {
  const dispatch = useDispatch()
  const { arriveHelperAttachments } = useSelector(store => store.requestDetails)

  const onDeleteAttachment = async attachmentId => {
    await dispatch(deleteArriveGuidelinesDocument(attachmentId))
  }

  return (
    <>
      <MainContainer>
        <TitleWrapper>
          <Typography variant="subtitle1">TCN Arrive Guidelines</Typography>
        </TitleWrapper>

        {arriveHelperAttachments.results.length
          ? <AttachmentsWrapper>
            {arriveHelperAttachments.results.map(attachment => (
              <AttachmentWrapper key={attachment.id}>
                <AttachmentNameContainer> {attachment.name}</AttachmentNameContainer>
                <DeleteButtonContainer>
                  <Button color="primary" size="small" onClick={() => onDeleteAttachment(attachment.id)}>
                  Delete
                  </Button>
                </DeleteButtonContainer>
              </AttachmentWrapper>
            ))}
          </AttachmentsWrapper>
          : <>
            <DescriptionWrapper>
              <Typography variant="body1">Upload the TCN Arrive Guidelines document for display in the TCN Arrive module</Typography>
            </DescriptionWrapper>
            <Formik
              initialValues={{ attachments: [] }}
              enableReinitialize={true}
              validationSchema={validationSchema()}
              onSubmit={async values => {
                await dispatch(uploadArriveGuidelinesDocument(values))
              }}
            >
              {({ values }) => (
                <Form>
                  <AlignCenterGrid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <AttachFile
                        label=""
                        name="attachments"
                        onDelete={() => {}}
                        multiple={false}
                        onFileUploadFailure={msg => {
                          dispatch(showWarning(msg))
                        }}
                        attachFileProperties={attachFileStyles}
                      />
                    </Grid>
                    {values.attachments.length > 0 &&
                  <AlignLeftGrid item xs={12} sm={12} md={12} lg={12}>
                    <Button size="medium" fullWidth={false} color="primary" type="submit">
                      Upload
                    </Button>
                  </AlignLeftGrid>
                    }
                  </AlignCenterGrid>
                </Form>
              )}
            </Formik>
          </>
        }
        <BarContainer>
          <Bar fullWidth={true} color="primary" />
        </BarContainer>
      </MainContainer>
    </>
  )
}

UploadArriveGuidelines.propTypes = {

}

export default UploadArriveGuidelines
