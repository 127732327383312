import { backendAxios, getConfig } from '../backend'

export const getMediaReferralsConfigDetails = () =>
  backendAxios.get('/configuration/mediareferralcountries', getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err.message))

export const postMediaReferralsConfigDetails = payload =>
  backendAxios.post('/configuration/mediareferralcountries', payload, getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err.message))
