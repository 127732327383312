import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, FormControl, FormHelperText, OutlinedInput, Typography, InputAdornment } from '@mui/material'
import { useField } from 'formik'
import styled from '@emotion/styled'

const StyledFormControl = styled(FormControl)(({ theme, error }) => ({
  '&&': {
    '.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.white.dark,
      minHeight: '45px',
      fieldset: {
        top: '-5px',
        border: `2px solid ${error ? theme.palette.error.main : theme.palette.background.disabled}`,
      },
    },
    '.Mui-focused': {
      color: '#333333',
    },
    '.Mui-disabled': {
      fieldset: {
        backgroundColor: theme.palette.background.disabled,
        border: 'unset',
      },
      input: {
        zIndex: theme.zIndex.minorUiElement,
      },
      textarea: {
        zIndex: theme.zIndex.minorUiElement,
      },
      '.MuiInputAdornment-root': {
        zIndex: 1,
        svg: {
          color: theme.palette.gray.dark,
        },
      },
    },
  },
}))

const StyledHelperText = styled(FormHelperText)`
  position: relative;
  bottom: 0px;
  left: 5px;
`
const StyledInputLabel = styled(InputLabel)`
  && {
    transform: unset;
    display: flex;
    position: relative;
    width: fit-content;
    white-space: pre-wrap;
    font-weight: 600;
  }
`

const TextBoxOuterLabel = props => {
  const {
    autoComplete,
    autoFocus,
    color,
    formik,
    defaultValue,
    disabled,
    error,
    fullWidth,
    helperText,
    id,
    inputProps,
    label,
    maxRows,
    minRows,
    multiline,
    name,
    onBlur,
    onChange,
    onPostChange,
    onFocus,
    placeholder,
    required,
    rows,
    size,
    type,
    value,
    captionText,
    endAdornment,
    startAdornment
  } = props

  let field, meta, setValue

  const onFieldValueChange = e => {
    setValue(e.target.value)

    // Call onPostChange if provided
    if (onPostChange) {
      onPostChange()
    }
  }

  if (formik === 'true') {
    [field, meta, { setValue }] = useField(props)
    field = { ...field, name: typeof name === 'number' ? `${name}` : name, onChange: onFieldValueChange }
  } else {
    field = {
      onChange,
      onBlur,
      value,
    }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)
  const updatedId = typeof id === 'number' ? `${id}` : id
  return (
    <>
      <StyledFormControl
        variant="standard"
        fullWidth={fullWidth}
        required={required}
        error={isError}
        disabled={disabled}
      >
        <StyledInputLabel shrink htmlFor={updatedId} color={color} error={isError}>
          {label}
        </StyledInputLabel>
        <OutlinedInput
          id={updatedId}
          data-testid={updatedId}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          color={color}
          defaultValue={defaultValue}
          error={isError}
          fullWidth={fullWidth}
          inputProps={inputProps}
          maxRows={maxRows}
          minRows={minRows}
          multiline={multiline}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          required={required}
          rows={rows}
          size={size}
          type={type}
          disabled={disabled}
          endAdornment={<InputAdornment position="end">{endAdornment}</InputAdornment>}
          startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
          {...field}
        />
        <StyledHelperText component="div" error={isError}>
          {errorText}
        </StyledHelperText>
        {captionText && <Typography variant="caption">{captionText}</Typography>}
      </StyledFormControl>
    </>
  )
}

TextBoxOuterLabel.propTypes = {
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element is focused during the first mount.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),

  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),

  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue: PropTypes.any,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * @default false
   */
  error: PropTypes.bool,
  /**
   * If `true`, the input will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Props applied to the Input element.
   * It will be a [`FilledInput`](/api/filled-input/),
   * [`OutlinedInput`](/api/outlined-input/) or [`Input`](/api/input/)
   * component depending on the `variant` prop value.
   */
  inputProps: PropTypes.object,
  /**
   * The label content. Note, this is of type node that means you must pass this as React element ex: Typography
   * The reason this is defined as node is that the label can be a combination of text and some icon.
   */
  label: PropTypes.node.isRequired,
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If `true`, a `textarea` element is rendered instead of an input.
   * @default false
   */
  multiline: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * Optional prop. If provided, call post onChange
   */
  onPostChange: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder: PropTypes.string,
  /**
   * If `true`, the label is displayed as required and the `input` element is required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Render a [`Select`](/api/select/) element while passing the Input element to `Select` as `input` parameter.
   * If this option is set you must pass the options of the select as children.
   * @default false
   */
  select: PropTypes.bool,
  /**
   * Props applied to the [`Select`](/api/select/) element.
   */
  SelectProps: PropTypes.object,
  /**
   * The size of the component.
   */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   */
  type: PropTypes.string,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.any,
  /**
   * Adds adornment to the right of the input field
   */
  endAdornment: PropTypes.node,
  /**
   * Adds adornment to the left of the input field
   */
  startAdornment: PropTypes.node,
  /**
   * Adds caption in the bottom of the field
   */
  captionText: PropTypes.string,
}

TextBoxOuterLabel.defaultProps = {
  color: 'primary',
  size: 'small',
  formik: 'true',
  fullWidth: true,
  autoFocus: false,
  disabled: false,
  error: false,
  multiline: false,
  required: false,
}

export default TextBoxOuterLabel
