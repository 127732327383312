import styled from '@emotion/styled'
import { FormControl, FormHelperText, FormLabel, Grid, Typography } from '@mui/material'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { AddressCard } from '../dataDisplay'
import QuickView from '../QuickView'
import Button from './Button'
import { useState } from 'react'
import CustomRadioGroup from './RadioGroup'

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '&&': {
    marginTop: theme.spacing(2)
  }
}))

const StyledFormLabel = styled(FormLabel)`
  display: flex;
  width: 100%;
`

const AddressField = props => {
  const {
    formik,
    disabled,
    error,
    fullWidth,
    helperText,
    id,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    addressesList,
    addressesListHeader,
    value,
    disableTypographyForLabel,
    changeAddressLabel,
    addAddressLabel,
    addAddressForm,
  } = props
  const [addressSelectionModal, setAddressSelectionModal] = useState(false)
  const [addAddressFormModal, setAddAddressFormModal] = useState(false)

  let field, meta, setValue

  const onFieldValueChange = selectedAddress => {
    if (typeof selectedAddress === 'object') {
      // this means user added new address, so automatically select that address
      setValue(selectedAddress)
    } else {
      // else find the address by addressId from the list of addresses and then make the selection
      const newSelectedAddress = addressesList.find(
        item => item.addressId === parseInt(selectedAddress)
      )
      setValue(newSelectedAddress)
    }
  }

  if (formik === 'true') {
    ;[field, meta, { setValue }] = useField(props)
    field = {
      ...field,
      onChange: address => {
        onChange ? onChange(address) : onFieldValueChange(address)
        setAddAddressFormModal(false)
        setAddressSelectionModal(false)
      }
    }
  } else {
    field = {
      onChange,
      onBlur,
      value,
      onFocus
    }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)
  const AddAddressForm = addAddressForm

  return (
    <>
      <StyledFormControl
        variant="standard"
        fullWidth={fullWidth}
        error={isError}
        disabled={disabled}
        name={name}
      >
        <StyledFormLabel htmlFor={id} component="legend" error={isError}>
          {disableTypographyForLabel ? label : <Typography variant="body2">{label}</Typography>}
        </StyledFormLabel>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <AddressCard
              title={field.value?.siteName}
              address={field.value}
              fullWidth={fullWidth}
              gutterBottom={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button variant="text" onClick={() => setAddressSelectionModal(true)} disabled={disabled}>
              {changeAddressLabel}
            </Button>
          </Grid>
        </Grid>

        <FormHelperText component="div" error={isError}>
          {errorText}
        </FormHelperText>
      </StyledFormControl>
      <QuickView
        title="Select Address"
        onClose={() => setAddressSelectionModal(false)}
        dialogContent={
          <Grid container spacing={2}>
            {addressesListHeader && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='body2'>{addressesListHeader}</Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {addressesList.length > 0
                ? (
                  <CustomRadioGroup
                    formik="false"
                    label=""
                    name="addressList"
                    id="addressList"
                    value={field.value.addressId}
                    disableTypographyForOptionsLabel={true}
                    options={addressesList.map(address => ({
                      value: address.addressId,
                      label: (
                        <AddressCard title={address.siteName} address={address} fullWidth={true} />
                      )
                    }))}
                    fullWidth={false}
                    row={true}
                    orientation="row"
                    color="primary"
                    onChange={e => field.onChange(e.target.value)}
                    align="center"
                    labelPlacement="end"
                    gutterBottomForEachOption={true}
                  />
                )
                : (
                  <Typography variant="h4">No addresses to choose from.</Typography>
                )}
            </Grid>
          </Grid>
        }
        isDialogOpen={addressSelectionModal}
        maxWidth="md"
        dialogActions={
          <Button color="primary" onClick={() => setAddAddressFormModal(true)}>
            {addAddressLabel}
          </Button>
        }
        alignActions="flex-start"
      />
      <QuickView
        title={addAddressLabel}
        isDialogOpen={addAddressFormModal}
        onClose={() => setAddAddressFormModal(false)}
        dialogContent={
          <AddAddressForm
            onSubmitForm={newlyAddedAddress => field.onChange(newlyAddedAddress)}
          />
        }
        maxWidth="md"
      />
    </>
  )
}

export default AddressField

AddressField.defaultProps = {
  formik: 'true',
  disabled: false,
  error: false,
  fullWidth: true,
  disableTypographyForLabel: false,
  changeAddressLabel: 'Change Location'
}

AddressField.propTypes = {
  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),
  /**
   * If `true`, the component will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * The label content. Note, this is of type node that means you must pass this as React element ex: Typography
   * The reason this is defined as node is that the label can be a combination of text and some icon.
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Use this when you want to pass your custom label.
   * when true, whatever is passed for label props, will be shown as is. Meaning, without a Typography/p-tag. Also, there will not be any indication, that this field is required.
   * Whereas in case of `false` and if this component is required, now when user tries to submit the form without selecting this checkbox, the label will turn to red.
   * @default false
   */
  disableTypographyForLabel: PropTypes.bool,
  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,
  /**
   * The id of the `input` element.
   */
  id: PropTypes.string,
  /**
   * Function called on blur
   */
  onBlur: PropTypes.func,
  /**
   * Function called on change of value
   */
  onChange: PropTypes.func,
  /**
   * method called on focus of the element
   */
  onFocus: PropTypes.func,

  /**
   * value of the field
   */
  value: PropTypes.object,
  /**
   * List of addresses
   */
  addressesList: PropTypes.array,
  /**
   * Title/header show on the Addresses list pop-up
   */
  addressesListHeader: PropTypes.string,
  /**
   * Label for change address button
   */
  changeAddressLabel: PropTypes.string,
  /**
   * Label for add new address button
   */
  addAddressLabel: PropTypes.string,
  /**
   * Form component that renders on click of Add Address button
   */
  addAddressForm: PropTypes.func
}
