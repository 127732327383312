import React from 'react'
import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { validationMapper } from 'components/helper/validationEngine'
import { Form, Formik } from 'formik'
import { formElements } from 'components/helper/constants/common'
import { Button, TextBox } from 'components/common'
import styled from '@emotion/styled'
import QuickView from 'components/common/QuickView'
import {
  onActivatePlasticCard,
  setPlasticCardActivatedSuccess
} from 'store/ducks/patientRecord/reimbursement'

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: `${theme.spacing(2)}`
}))

const ActionGrid = styled(Grid)(({ theme }) => ({
  marginTop: `${theme.spacing(3)}`,
  display: 'flex',
  justifyContent: 'center'
}))

const LabelGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const ActivatePlasticCard = ({ setActivatePlasticCard }) => {
  const dispatch = useDispatch()
  const { plasticCardActivatedSuccess } = useSelector(store => store.reimbursement)

  return (
    <>
      <Formik
        initialValues={{ cvv: '' }}
        enableReinitialize={false}
        validationSchema={validationMapper([
          { id: 'cvv', isResponseRequired: true, answerType: formElements.openText }
        ])}
        onSubmit={({ cvv }, { setSubmitting }) => {
          dispatch(onActivatePlasticCard(cvv))
          setSubmitting(false)
        }}
      >
        {() => (
          <Form noValidate>
            <Title variant="body1" gutterBottom>
              To activate the plastic card, enter the card&apos;s CVV below. This is the 3-digit
              code on the back of the card.
            </Title>
            <Grid container spacing={2}>
              <LabelGrid item xs={1} sm={1} md={1} lg={1}>
                <Typography variant="body2">CVV</Typography>
              </LabelGrid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <TextBox id="cvv" name="cvv" placeholder="Enter CVV" type="text" size="small" />
              </Grid>
              <ActionGrid item xs={12} sm={12} md={12} lg={12}>
                <Button color="primary" type="submit" size="large">
                  Activate
                </Button>
              </ActionGrid>
            </Grid>
          </Form>
        )}
      </Formik>
      <QuickView
        title="Success!"
        onClose={() => {
          dispatch(setPlasticCardActivatedSuccess(false))
          setActivatePlasticCard(false)
        }}
        dialogContent={
          <>
            <Typography variant="body1">The plastic card is successfully activated.</Typography>
            <br />
            <Typography variant="body1">This card may now be used to make purchases.</Typography>
          </>
        }
        isDialogOpen={plasticCardActivatedSuccess}
        maxWidth="sm"
      />
    </>
  )
}

ActivatePlasticCard.propTypes = {
  setActivatePlasticCard: PropTypes.func
}

export default ActivatePlasticCard
