export const cohortsLookup = [
  { id: 1, displayText: 'cohort1' },
  { id: 2, displayText: 'cohort2' },
  { id: 3, displayText: 'cohort3' }
]

export const announcementsMessageSendCategory = [
  {
    id: 4,
    displayText: 'All Active Users',
    alternateIdentifier: null
  },
  {
    id: 3,
    displayText: 'Vendors',
    alternateIdentifier: null
  },
  {
    id: 2,
    displayText: 'Sites',
    alternateIdentifier: null
  },
  {
    id: 1,
    displayText: 'Patients',
    alternateIdentifier: null
  }
]

export const announcementByID = {
  openRate: 0,
  subject: 'test font',
  body: '<p><em><ins>test</ins></em> <strong>font</strong></p>\n',
  distributionDate: '2022-07-28T09:50:07',
  sendTo: 1,
  countries: [
    {
      id: 879,
      displayText: 'Afghanistan',
      alternateIdentifier: null
    }
  ],
  vendors: null,
  siteProtocols: null,
  cohorts: null,
  products: [
    {
      id: 2,
      displayText: 'TCN Arrive',
      alternateIdentifier: null
    }
  ],
  recipients: [
    {
      id: 5,
      displayText: 'Patients by Program',
      alternateIdentifier: null
    }
  ],
  attachments: [],
  announcementId: 314,
  userAnnouncementId: null,
  displayAsBanner: false,
  isDistributed: true,
  isViewed: false,
  createdOn: '2022-07-28T09:47:25.217',
  createdBy: 'Rakesh Nalumachu'
}

export const announcementByID313 = {
  openRate: 0,
  subject: 'New Message with ID 313',
  body: '<p><em><ins>New Message body and content with ID 313</ins></em> <strong>font</strong></p>\n',
  distributionDate: '2022-07-28T09:50:07',
  sendTo: 1,
  countries: [
    {
      id: 879,
      displayText: 'Afghanistan',
      alternateIdentifier: null
    }
  ],
  vendors: null,
  siteProtocols: null,
  cohorts: null,
  products: [
    {
      id: 2,
      displayText: 'TCN Arrive',
      alternateIdentifier: null
    }
  ],
  recipients: [
    {
      id: 5,
      displayText: 'Patients by Program',
      alternateIdentifier: null
    }
  ],
  attachments: [],
  announcementId: 313,
  userAnnouncementId: null,
  displayAsBanner: false,
  isDistributed: true,
  isViewed: false,
  createdOn: '2022-07-28T09:47:25.217',
  createdBy: 'Rakesh Nalumachu'
}

export const saveMessageLoadData = {
  sendToCategories: null,
  1: [
    // patients
    {
      id: 1,
      displayText: 'All Patients',
      alternateIdentifier: null
    },
    {
      id: 2,
      displayText: 'Patients by Country',
      alternateIdentifier: null
    },
    {
      id: 3,
      displayText: 'Patients by Site',
      alternateIdentifier: null
    },
    {
      id: 4,
      displayText: 'Patients by Cohort / Arm',
      alternateIdentifier: null
    },
    {
      id: 5,
      displayText: 'Patients by Product',
      alternateIdentifier: null
    }
  ],
  2: [
    // sites
    {
      id: 6,
      displayText: 'All Sites',
      alternateIdentifier: null
    },
    {
      id: 7,
      displayText: 'Sites by Country',
      alternateIdentifier: null
    },
    {
      id: 8,
      displayText: 'Sites by Product',
      alternateIdentifier: null
    }
  ],
  3: [
    // vendors
    {
      id: 9,
      displayText: 'All Vendors',
      alternateIdentifier: null
    },
    {
      id: 10,
      displayText: 'Specific Vendors',
      alternateIdentifier: null
    }
  ],
  4: [
    // allActiveUsers
    {
      id: 11,
      displayText: 'All Active Users',
      alternateIdentifier: null
    }
  ]
}

export const announcementList = [
  {
    subject: 'test font',
    body: '<p><em><ins>test</ins></em> <strong>font</strong></p>\n',
    distributionDate: '2022-07-28T09:50:07',
    sendTo: 1,
    countries: [
      {
        id: 879,
        displayText: 'Afghanistan',
        alternateIdentifier: null
      }
    ],
    vendors: null,
    siteProtocols: null,
    cohorts: null,
    products: [
      {
        id: 2,
        displayText: 'TCN Arrive',
        alternateIdentifier: null
      }
    ],
    recipients: [
      {
        id: 5,
        displayText: 'Patients by Program',
        alternateIdentifier: null
      }
    ],
    attachments: [],
    announcementId: 314,
    userAnnouncementId: null,
    // displayAsBanner: false,
    isDistributed: true,
    isViewed: false,
    createdOn: '2022-07-28T09:47:25.217',
    createdBy: 'Rakesh Nalumachu'
  },
  {
    subject: 'Hellooooooo Afghanistan',
    body: '<p>Welcome to the Payment &amp; Reimbursement Program</p>\n',
    distributionDate: '2022-07-27T15:23:41',
    sendTo: 2,
    countries: [
      {
        id: 879,
        displayText: 'Afghanistan',
        alternateIdentifier: null
      }
    ],
    vendors: null,
    siteProtocols: null,
    cohorts: null,
    products: null,
    recipients: [
      {
        id: 7,
        displayText: 'Sites by Country',
        alternateIdentifier: null
      }
    ],
    attachments: [],
    announcementId: 313,
    userAnnouncementId: null,
    // displayAsBanner: false,
    isDistributed: true,
    isViewed: false,
    createdOn: '2022-07-27T15:24:13.273',
    createdBy: 'Dena Raffa'
  },
  {
    subject: 'Welcome',
    body: '<p><span style="background-color: rgb(247,218,100);">Welcome </span><span style="color: rgb(255,255,255);background-color: rgb(44,130,201);">to</span> <strong>TCN</strong></p>\n',
    distributionDate: '2022-07-27T15:12:51',
    sendTo: 2,
    countries: null,
    vendors: null,
    siteProtocols: null,
    cohorts: null,
    products: null,
    recipients: [
      {
        id: 6,
        displayText: 'All Sites',
        alternateIdentifier: null
      }
    ],
    attachments: [],
    announcementId: 312,
    userAnnouncementId: null,
    // displayAsBanner: false,
    isDistributed: true,
    isViewed: false,
    createdOn: '2022-07-27T15:14:27.277',
    createdBy: 'Dena Raffa'
  },
  {
    subject: 'New file upload logic',
    body: '<p>New file upload logic</p>\n',
    distributionDate: '2022-07-29T00:00:00',
    sendTo: 3,
    countries: null,
    vendors: [],
    siteProtocols: null,
    cohorts: null,
    products: null,
    recipients: [
      {
        id: 10,
        displayText: 'Specific Vendors',
        alternateIdentifier: null
      }
    ],
    attachments: [
      {
        errors: {},
        id: 91,
        name: 'abilityComponent.png',
        luEntityTypeId: 2,
        entityId: 311,
        type: 'image/png',
        data: null,
        size: 13422,
        version: 1,
        isVisible: true,
        isValid: true
      },
      {
        errors: {},
        id: 92,
        name: 'abilityDefination.png',
        luEntityTypeId: 2,
        entityId: 311,
        type: 'image/png',
        data: null,
        size: 51997,
        version: 1,
        isVisible: true,
        isValid: true
      },
      {
        errors: {},
        id: 93,
        name: 'announcement list error.png',
        luEntityTypeId: 2,
        entityId: 311,
        type: 'image/png',
        data: null,
        size: 68577,
        version: 1,
        isVisible: true,
        isValid: true
      },
      {
        errors: {},
        id: 95,
        name: 'authbackground.png',
        luEntityTypeId: 2,
        entityId: 311,
        type: 'image/png',
        data: null,
        size: 65946,
        version: 1,
        isVisible: true,
        isValid: true
      }
    ],
    announcementId: 311,
    userAnnouncementId: null,
    // displayAsBanner: false,
    isDistributed: true,
    isViewed: false,
    createdOn: '2022-07-25T13:59:42.03',
    createdBy: 'John Wick'
  },
  {
    subject: 'Update to attach file component',
    body: '<p><strong>Update to attach file </strong><span style="color: rgb(26,188,156);"><strong>component</strong></span></p>\n',
    distributionDate: '2022-07-28T00:00:00',
    sendTo: 3,
    countries: null,
    vendors: [],
    siteProtocols: null,
    cohorts: null,
    products: null,
    recipients: [
      {
        id: 10,
        displayText: 'Specific Vendors',
        alternateIdentifier: null
      }
    ],
    attachments: [
      {
        errors: {},
        id: 90,
        name: 'abilityComponent.png',
        luEntityTypeId: 2,
        entityId: 310,
        type: 'image/png',
        data: null,
        size: 13422,
        version: 1,
        isVisible: true,
        isValid: true
      }
    ],
    announcementId: 310,
    userAnnouncementId: null,
    // displayAsBanner: false,
    isDistributed: true,
    isViewed: false,
    createdOn: '2022-07-25T12:56:14.32',
    createdBy: 'John Wick'
  }
]

export const announcementDetailedView = {
  openRate: 0,
  subject: 'test font',
  body: '<p><em><ins>test</ins></em> <strong>font</strong></p>\n',
  distributionDate: '28 Jul 2022',
  sendTo: {
    id: 1,
    displayText: 'Patients',
    alternateIdentifier: null
  },
  countries: [
    {
      id: 879,
      displayText: 'Afghanistan',
      alternateIdentifier: null
    }
  ],
  vendors: [],
  siteProtocols: [],
  cohorts: [],
  products: [
    {
      id: 2,
      displayText: 'TCN Arrive',
      alternateIdentifier: null
    }
  ],
  recipients: [
    {
      id: 5,
      displayText: 'Patients by Program',
      alternateIdentifier: null
    }
  ],
  attachments: [],
  announcementId: 314,
  userAnnouncementId: null,
  // displayAsBanner: false,
  isDistributed: false,
  isViewed: false,
  createdOn: '28 Jul 2022',
  createdBy: 'Rakesh Nalumachu',
  recipientDetails: {
    id: 5,
    displayText: 'Patients by Program',
    alternateIdentifier: null
  },
  attachmentsToBeDeleted: [],
  distributionTime: ' 9:50:07 AM',
  createdOnTime: ' 9:47:25 AM'
}

export const distributedList = [
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' },
  { name: 'Jhonny len', readUnread: 'Read', siteID: '123', country: 'Afghanistan' }
]
