import { backendAxios, getConfig } from '../backend'

export const addConfigSitePrescreenerQuestion = questionData =>
  backendAxios
    .post('/configuration/configsiteprescreenerquestion', questionData, getConfig())
    .then(response => response.data)

export const addConfigConciergePrescreenerQuestion = questionData =>
  backendAxios
    .post('/configuration/configconciergeprescreenerquestion', questionData, getConfig())
    .then(response => response.data)

export const updateIVRIntegration = ivrData =>
  backendAxios
    .post('/configuration/ivrintegration', ivrData, getConfig())
    .then(response => response.data)

export const getConfigSitePrescreenerQuestion = () =>
  backendAxios.get('/configuration/configsiteprescreenerquestions', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getConfigConciergePrescreenerQuestion = () =>
  backendAxios.get('/configuration/configconciergeprescreenerquestions', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))
