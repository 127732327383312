export const configurationTabIDs = {
  protocolCampaign: 'protocolCampaign',
  sitePreferences: 'sitePreferences',
  resourceCenter: 'resourceCenter',
  participationProgress: 'participationProgress',
  mediaReferrals: 'mediaReferrals',
  tcnCard: 'tcnCard',
  tcnArrive: 'tcnArrive',
  visitDetails: 'visitDetails',
  permissions: 'permissions'
}

export const cardConfigurationFields = {
  participatingCountries: 'participatingCountries',
  eligibleParticipants: 'eligibleParticipants',
  capType: 'capType',
  approvalRequiredServices: 'approvalRequiredServices',
  isVirtual: 'isVirtual',
  isStandardShipping: 'isStandardShipping',
  isCourierShipping: 'isCourierShipping',
  isVirtualWithPlastic: 'isVirtualWithPlastic'
}

export const capTypesIds = {
  guidelines: 1,
  hardCaps: 2,
  softCaps: 3,
  reviewAndApprove: 4
}

export const fundsManagementLabels = {
  [capTypesIds.guidelines]: 'Guidelines',
  [capTypesIds.hardCaps]: 'Hard Caps',
  [capTypesIds.softCaps]: 'Soft Caps',
  [capTypesIds.reviewAndApprove]: 'Review & Approve'
}
export const fundsManagementGuidelinesForSite = {
  [capTypesIds.guidelines]:
    'If amounts recorded exceed the amounts listed under each category, the system requires an explanatory note.',
  [capTypesIds.hardCaps]:
    'The system will not accept a recorded amount that exceeds the amount listed under each category.',
  [capTypesIds.softCaps]:
    'If amounts recorded exceed the amounts listed under each category, the entry must be reviewed and approved prior to the patient having access to the funds.',
  [capTypesIds.reviewAndApprove]:
    'Amounts recorded are never accessible to the patient without a secondary approval for these amounts, with the exception of the category(ies) listed below. Please note, secondary approval may take between 24-48 hours.'
}

export const fundsManagementGuidelines = {
  [capTypesIds.guidelines]:
    'If amounts recorded exceed the amounts listed under each category, the system requires an explanatory note.',
  [capTypesIds.hardCaps]:
    'The system will not accept a recorded amount that exceeds the amount listed under each category.',
  [capTypesIds.softCaps]:
    'If amounts recorded exceed the amounts listed under each category, the entry must be reviewed and approved prior to the patient having access to the funds.',
  [capTypesIds.reviewAndApprove]:
    'Amounts recorded within the "Categories to be Approved" are never accessible to the patient without a secondary approval for these amounts.'
}

export const capAlertMessages = {
  [capTypesIds.softCaps]:
    'Amount(s) recorded exceed the amount(s) listed under the below category(ies). The entry must be reviewed and approved prior to the patient having access to the funds.',
  [capTypesIds.reviewAndApprove]:
    'The reimbursement will be reviewed before the funds go on the card.'
}

export const visitDetailsUploadType = {
  patientDashboardDisplay: 'patientDashboardDisplay',
  visitDropdownMenus: 'visitDropdownMenus'
}
