import React from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { FileDownload, TextSnippet } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { downloadAttachment } from 'store/ducks/visitServices/requestDetails'
import { palette } from 'styles/baseTheme'

import { DataGrid } from 'components/common'

const CenterAlignDiv = styled.div`
  width: 100%;
  text-align: center;
`
const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  minWidth: '200px'
}

const PatientRequestsGrid = ({ requests }) => {
  const dispatch = useDispatch()

  const downloadAttachmentHandler = attachment => {
    dispatch(downloadAttachment(attachment))
  }

  let formattedRequests = []
  if (requests.length > 0) {
    formattedRequests = requests.map(request => ({
      downloadItinerary: request.attachment
        ? (
          <CenterAlignDiv>
            <IconButton
              aria-label="download itinerary"
              aria-haspopup="true"
              onClick={() => downloadAttachmentHandler(request.attachment)}
              color="primary"
            >
              <FileDownload color="primary" fontSize="small" />{' '}
            </IconButton>
          </CenterAlignDiv>
        )
        : null,
      ...request,
    }))
  }

  return (
    <div>
      <DataGrid
        headers={[
          {
            id: 'downloadItinerary',
            label: <TextSnippet color="white" />,
            bodyProperties: {
              width: '10%',
              cellType: 'node'
            },
            headProperties: { ...headerProperties, cellType: 'node', minWidth: '120px' },
          },
          {
            id: 'services',
            label: 'Services',
            bodyProperties: {
              width: '30%',
            },
            headProperties: { ...headerProperties },
          },
          {
            id: 'visitDate',
            label: 'Visit Date',
            bodyProperties: {
              width: '30%',
            },
            headProperties: { ...headerProperties },
          },

          {
            id: 'visitName',
            label: 'Visit Name',
            bodyProperties: {
              width: '30%',
            },
            headProperties: { ...headerProperties },
          },
        ]}
        tableData={formattedRequests}
        setRequestedSort={() => {}}
        order={'DESC'}
        noRowsFoundMessage="No results found."
      />
    </div>
  )
}

PatientRequestsGrid.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.object),
}

export default PatientRequestsGrid
