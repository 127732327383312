import React, { useEffect } from 'react'
import { Paper, Typography } from '@mui/material'
import { AccordionGroup, PdfViewer } from 'components/common'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import ForPatientsStudyTools from '../resourceCenter/ForPatientsStudyTools'
import { getResourceDocsByID } from 'services/common'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { resetResourceCenter, setPageNumber } from 'store/ducks/resourceCenter'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: `${theme.spacing(5)}`,
    height: '87vh',
  },
}))

const SubWrapper = styled.div(() => ({
  '&&': {
    display: 'flex',
    justifyContent: 'spaceBetween',
    width: '100%',
  },
}))
const SideNavWrapper = styled.div(() => ({
  '&&': {
    width: '25%',
    minWidth: '255px',
  },
}))

const ContentWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    width: '75%',
    marginLeft: `${theme.spacing(2)}`,
    padding: `${theme.spacing(6, 2, 1, 2)}`,
    backgroundColor: `${theme.palette.gray.light}`,
    display: 'flex',
    justifyContent: 'center',
  },
}))

const PatientStudyTools = () => {
  const dispatch = useDispatch()
  const { selectedDocument, pageNumber } = useSelector(store => store.resourceCenter)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)

  useEffect(() =>
    // As the same redux state is reused in resource center
    () => {
      dispatch(resetResourceCenter())
    }
  , [])

  return (
    <MainWrapper elevation={0}>
      <SubWrapper>
        <SideNavWrapper>
          <AccordionGroup
            expandAll={false}
            defaultOpenPanel={0}
            transitionProps={{ unmountOnExit: true }}
            labelComponent={true}
            minBottomGutter={true}
            noContentPadding={true}
            contentBackground="gray"
            controlled={true}
            highlightOnActive={true}
            summaryStyles={{
              minHeight: '40px',
              '.MuiAccordionSummary-content': {
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                margin: '5px',
              },
            }}
            accordions={[
              {
                label: <Typography variant="body2">For Patients</Typography>,
                component: (
                  <ForPatientsStudyTools siteClientId={patientInformation?.siteClientId} fetchInitialDocument={true} />
                ),
                isActive: selectedDocument?.category === resourceCenterCategory.forPatientsStudyToolsDocuments,
              },
            ]}
          />
        </SideNavWrapper>
        <ContentWrapper elevation={0}>
          {selectedDocument
            ? (
              (selectedDocument.fetchId || selectedDocument.resourceURL) && (
                <PdfViewer
                  fetchingPayload={selectedDocument}
                  fetchDocument={getResourceDocsByID}
                  pageNumber={pageNumber}
                  setPageNumber={number => {
                    dispatch(setPageNumber(number))
                  }}
                />
              )
            )
            : (
              <Typography variant="subtitle1">Please select a document.</Typography>
            )}
        </ContentWrapper>
      </SubWrapper>
    </MainWrapper>
  )
}

export default PatientStudyTools
