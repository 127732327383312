import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Typography, Grid, Box, Skeleton } from '@mui/material'
import { Form, Formik } from 'formik'

import {
  fetchArriveRequestCountriesInitialValues,
  updateArriveRequestCountries,
  resetArriveConfiguration
} from 'store/ducks/configuration/arriveConfiguration'
import { fetchCountriesLookup } from 'store/ducks/common'
import { fetchArriveHelperAttachments } from 'store/ducks/visitServices/requestDetails'

import { Button, PageHeader } from 'components/common'
import { AutoCompleteWithLabel } from 'components/common/form'
import { subRequestTypes } from 'components/helper/constants/visitServices'
import UploadArriveGuidelines from './UploadArriveGuidelines'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`
const StyledGrid = styled(Grid)`
  align-self: flex-start;
  margin-top: 20px;
`
const StyledUL = styled.ul`
  list-style-type: '- ';
`
const TitleWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}))

const TCNArrive = () => {
  const dispatch = useDispatch()
  const {
    arriveRequestTypes,
    arriveRequestCountries,
    initialArriveRequestCountriesValues,
    initialValuesUpdateCounter
  } = useSelector(store => store.arriveConfiguration)
  const { arriveHelperAttachments } = useSelector(store => store.requestDetails)
  const { countriesLookup } = useSelector(store => store.common)

  const fetchFormDependencies = async () => {
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchArriveRequestCountriesInitialValues())
    await dispatch(fetchArriveHelperAttachments())
  }

  useEffect(() => {
    fetchFormDependencies()
    return () => {
      dispatch(resetArriveConfiguration())
    }
  }, [])

  const onCountriesChange = (selectedValues, reason, setFieldValue, fieldName) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValues.find(option => option.id === 'select-all')) {
        setFieldValue(fieldName, countriesLookup.results)
      } else {
        setFieldValue(fieldName, selectedValues)
      }
    } else if (reason === 'clear') {
      setFieldValue(fieldName, [])
    }
  }

  return (
    <PageHeader
      title={<Typography variant="h4">TCN Arrive</Typography>}
      expandContent
      content={
        <>
          {countriesLookup.isLoading ||
          arriveRequestTypes.isLoading ||
          arriveRequestCountries.isLoading ||
          arriveHelperAttachments.isLoading
            ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            )
            : (
              <>
                <UploadArriveGuidelines key={arriveHelperAttachments.length}/>
                <>
                  <TitleWrapper>
                    <Typography variant="subtitle1">Request Types by Country</Typography>
                  </TitleWrapper>
                  <Formik
                    initialValues={{ ...initialArriveRequestCountriesValues }}
                    enableReinitialize={true}
                    onSubmit={values => {
                      dispatch(updateArriveRequestCountries(values))
                    }}
                    key={initialValuesUpdateCounter}
                  >
                    {({ setFieldValue, dirty }) => (
                      <Form>
                        <Grid container spacing={2}>
                          {arriveRequestTypes.results.map(requestType => (
                            <React.Fragment key={requestType.id}>
                              <StyledGrid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={3}
                                data-testid={`${requestType.displayText}-label`}
                              >
                                <Typography variant="body2">{requestType.displayText}</Typography>
                                {subRequestTypes[requestType.displayText] && (
                                  <StyledUL>
                                    {subRequestTypes[requestType.displayText].map(subRequestType => (
                                      <li key={subRequestType}>
                                        <Typography variant="caption">{subRequestType}</Typography>
                                      </li>
                                    ))}
                                  </StyledUL>
                                )}
                              </StyledGrid>
                              <Grid item xs={12} sm={12} md={8} lg={9}>
                                <AutoCompleteWithLabel
                                  multiple={true}
                                  hasSelectAllOption={true}
                                  size="small"
                                  id={requestType.id.toString()}
                                  name={requestType.id.toString()}
                                  dataTestId={`${requestType.displayText}-Countries`}
                                  label="Countries"
                                  options={countriesLookup.results}
                                  onChange={(event, selectedValues, reason) =>
                                    onCountriesChange(
                                      selectedValues,
                                      reason,
                                      setFieldValue,
                                      requestType.id.toString()
                                    )
                                  }
                                  isLoading={countriesLookup.isLoading}
                                  limitTags={10}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}

                          <Grid item xs={12} sm={12} md={6} lg={4}>
                            <ActionWrapper>
                              <Button
                                size="large"
                                fullWidth={false}
                                color="primary"
                                type="submit"
                                disabled={!dirty}
                              >
                            Save
                              </Button>
                            </ActionWrapper>
                          </Grid>
                          <Grid item xs={0} sm={0} md={6} lg={8} />
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </>
              </>
            )}
        </>
      }
    />
  )
}

export default TCNArrive
