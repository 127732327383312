import React from 'react'
import styled from '@emotion/styled'
import { Typography, IconButton, Container } from '@mui/material'
import PropertyValue from 'components/common/dataDisplay/PropertyValue'
import { Card, Button, Token } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteAnnouncement,
  onEditAnnouncementClicked,
  setDeleteAnnouncementConfirmation,
  setViewAnnouncementDetails
} from 'store/ducks/communicationCenter/announcements'
import DistributedList from './DistributedList'
import { Cancel } from '@mui/icons-material'
import QuickView from 'components/common/QuickView'
import { Restriction } from 'permissions'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'

const ContentWrapper = styled.div(({ theme }) => ({
  '&&': {
    width: '65%',
    marginBottom: `${theme.spacing(3)}`,
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    }
  }
}))

const ConfirmationActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: space-around;
`

const AnnouncementDetailAction = styled.div(({ theme }) => ({
  '&&': {
    button: {
      color: `${theme.palette.white.main}`
    },
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  }
}))

const AnnouncementDetailedView = () => {
  const dispatch = useDispatch()
  const { announcement, deleteAnnouncementConfirmation } = useSelector(store => store.announcements)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { isLoading } = useSelector(store => store.userInteractions)

  return (
    <>
      {isLoading.length
        ? (
          ''
        )
        : (
          <Card
            title="Announcement Details"
            titleTypographyProps={{ variant: 'h2' }}
            headerColor="secondary"
            headerAction={
              <IconButton
                aria-label="close-announcement-details"
                onClick={() => dispatch(setViewAnnouncementDetails(false))}
                size="large"
                color="primary"
              >
                <Cancel />
              </IconButton>
            }
          >
            <Container maxWidth="lg">
              <Card
                gradientBackground="gradientBlue"
                cardContentStyles={{ justifyContent: 'space-around', padding: '35px' }}
                cardStyles={{ marginBottom: '25px' }}
              >
                <ContentWrapper>
                  <PropertyValue
                    label={'Subject: '}
                    labelProps={{ variant: 'h2' }}
                    value={announcement.subject}
                    valueProps={{ variant: 'h3' }}
                    fullWidth={true}
                    align="row"
                    gutterBottom={3}
                  />

                  <Restriction ability="read" feature={`${selectedCommunicationTabId}SentTo`}>
                    <PropertyValue
                      label="Sent To:"
                      labelProps={{ variant: 'body2' }}
                      valueProps={{ variant: 'body1' }}
                      value={announcement.sendTo.displayText}
                      fullWidth={true}
                      align="row"
                      gutterBottom={1}
                    />
                  </Restriction>

                  {announcement.countries.length > 0 && (
                    <PropertyValue
                      label="Countries:"
                      labelProps={{ variant: 'body2' }}
                      valueProps={{ variant: 'body1' }}
                      value={announcement.countries.map(t => t.displayText).join(', ')}
                      fullWidth={true}
                      align="row"
                      gutterBottom={1}
                    />
                  )}
                  {announcement.recipientDetails?.displayText === 'Specific Vendors' && (
                    <PropertyValue
                      label="Vendor Names:"
                      labelProps={{ variant: 'body2' }}
                      valueProps={{ variant: 'body1' }}
                      value={announcement.vendors.map(t => t.displayText).join(', ')}
                      fullWidth={true}
                      align="row"
                      gutterBottom={1}
                    />
                  )}
                  <PropertyValue
                    label="Created Date:"
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    value={`${announcement.createdOn} ${announcement.createdOnTime}`}
                    fullWidth={true}
                    align="row"
                    gutterBottom={1}
                  />
                  <PropertyValue
                    label={
                      announcement.isDistributed
                        ? 'Distribution Date:'
                        : 'Scheduled Distribution Date:'
                    }
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body1' }}
                    value={`${announcement.distributionDate} ${announcement.distributionTime}`}
                    fullWidth={true}
                    align="row"
                    gutterBottom={1}
                  />
                </ContentWrapper>
                <Token
                  content={<Typography variant="h1">{`${announcement.openRate}%`}</Typography>}
                  subContent={<Typography variant="caption">Open Rate</Typography>}
                  borderColor="primary"
                />

                {!announcement.isDistributed && (
                  <AnnouncementDetailAction>
                    <Restriction ability="read" feature={`${selectedCommunicationTabId}ViewOrEditAnnouncementButton`}>
                      <Button
                        variant="text"
                        onClick={() => dispatch(onEditAnnouncementClicked({ ...announcement }))}
                      >
                        <Typography variant="body1">View/Edit Announcement</Typography>
                      </Button>
                    </Restriction>
                    <Restriction ability="read" feature={`${selectedCommunicationTabId}DeleteAnnouncementButton`}>
                      <Button
                        variant="text"
                        onClick={() => dispatch(setDeleteAnnouncementConfirmation(true))}
                      >
                        <Typography variant="body1">Delete Announcement</Typography>
                      </Button>
                    </Restriction>
                  </AnnouncementDetailAction>
                )}
              </Card>
              {announcement.isDistributed && <DistributedList />}
            </Container>
          </Card>
        )}
      <QuickView
        title="Delete Announcement?"
        onClose={() => dispatch(setDeleteAnnouncementConfirmation(false))}
        contentText="Are you sure you want to delete this announcement?"
        dialogActions={
          <ConfirmationActionsWrapper>
            <Button
              color="primary"
              size="medium"
              onClick={() => {
                dispatch(
                  deleteAnnouncement(
                    announcement,
                    patientInformation.userId,
                    selectedCommunicationTabId
                  )
                )
              }}
            >
              Yes
            </Button>
            <Button label="No" onClick={() => dispatch(setDeleteAnnouncementConfirmation(false))}>
              No
            </Button>
          </ConfirmationActionsWrapper>
        }
        isDialogOpen={deleteAnnouncementConfirmation}
        maxWidth="sm"
      />
    </>
  )
}

export default AnnouncementDetailedView
