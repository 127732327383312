import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AccordionGroup from 'components/common/dataDisplay/AccordionGroup'
import DeliveryServices from './DeliveryServices'
import HomeHealthVisit from './HomeHealthVisit'
import TelehealthVisit from './telehealthSupport/TelehealthVisit'
import OtherServices from './OtherServices'
import { Grid } from '@mui/material'
import { remoteRequestTypes, requestType, requestTypeIds } from 'components/helper/constants/visitServices'

const remoteRequestIdAccordions = {
  [requestTypeIds.deliveryServices]: {
    label: requestType.deliveryServices,
    component: <DeliveryServices />,
  },
  [requestTypeIds.homeHealthVisit]: {
    label: requestType.homeHealthVisit,
    component: <HomeHealthVisit />,
  },
  [requestTypeIds.telehealthVisit]: {
    label: requestType.telehealthVisit,
    component: <TelehealthVisit />,
  },
  [requestTypeIds.otherServices]: {
    label: requestType.otherServices,
    component: <OtherServices />,
  },
}

const RemoteRequestForm = ({ reqType }) => {
  const { availableRequestTypeIds } = useSelector(store => store.requestDetails)

  let accordionOpen = -1
  if (reqType && reqType !== requestType.remoteRequest) {
    accordionOpen = remoteRequestTypes.indexOf(reqType)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AccordionGroup
          accordions={availableRequestTypeIds.remote.map(remoteReqId => remoteRequestIdAccordions[remoteReqId])}
          gradient={true}
          color="gradientBlue"
          transitionProps={{ unmountOnExit: true }}
          defaultOpenPanel={accordionOpen}
          contentStyles={{ padding: '32px 16px' }}
        />
      </Grid>
    </Grid>

  )
}

RemoteRequestForm.propTypes = {
  reqType: PropTypes.string,
}

export default RemoteRequestForm
