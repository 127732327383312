import { backendAxios, getConfig } from './backend'

export const getSites = () =>
  backendAxios.get('/sitemanagement/sites', getConfig()).then(response => response.data)

export const getSiteDetails = siteId =>
  backendAxios
    .get('/sitemanagement/getsitedetails', getConfig({ siteId }))
    .then(response => response.data)

export const saveSiteDetails = requestData =>
  backendAxios
    .post('/sitemanagement/sitedetails', requestData, getConfig())
    .then(response => response.data)

export const addSite = requestData =>
  backendAxios
    .post('/sitemanagement/addsite', requestData, getConfig())
    .then(response => response.data)

export const getSiteUserActivity = siteId =>
  backendAxios
    .get('/sitemanagement/siteuseractivity', getConfig({ siteId }))
    .then(response => response.data)

export const getSiteNotes = siteId =>
  backendAxios
    .get('/sitemanagement/sitenotes', getConfig({ siteId }))
    .then(response => response.data)

export const addSiteNote = notesInfo =>
  backendAxios
    .post('/sitemanagement/addsitenote', notesInfo, getConfig())
    .then(response => response.data)

export const getSiteDataPoints = siteId =>
  backendAxios
    .get('/sitemanagement/sitedatapoints', getConfig({ siteId }))
    .then(response => response.data)

export const addCustomDataPoint = dataPointInfo =>
  backendAxios
    .post('/sitemanagement/addcustomdatapoint', dataPointInfo, getConfig())
    .then(response => response.data)

export const updateDataPointAnswers = answersInfo =>
  backendAxios
    .post('/sitemanagement/sitedatapoint', answersInfo, getConfig())
    .then(response => response.data)
