import React from 'react'
import { DataGrid, Button } from 'components/common'
import { Grid } from '@mui/material'
import { palette } from 'styles/baseTheme'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { setUpdateReceiptsModal } from 'store/ducks/patientRecord/reimbursement'
import { downloadAttachment } from 'store/ducks/common'

const bodyProperties = { textAlign: 'center' }
const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'start',
  padding: '8px',
}
const gridTitleStyles = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'center',
}

const receiptsListHeaders = [
  {
    id: 'name',
    label: 'Document Name',
    headProperties: { ...headProperties, width: '110px' },
    bodyProperties: { ...bodyProperties },
  },
  {
    id: 'uploadedBy',
    label: 'Uploaded By',
    headProperties: { ...headProperties, width: '110px' },
    bodyProperties: { ...bodyProperties },
  },
  {
    id: 'uploadedOn',
    label: 'Uploaded On',
    headProperties: { ...headProperties, width: '110px' },
    bodyProperties: { ...bodyProperties },
  },
]

const ActionWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const Wrapper = styled(Grid)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)}; 
`,
)

const ReceiptsList = () => {
  const dispatch = useDispatch()
  const { fundsReceipts } = useSelector(store => store.reimbursement)

  const finalFundsReceiptsData = fundsReceipts.map((receipt, index) => ({
    ...receipt,
    name: (
      <Button
        key={index}
        variant="text"
        onClick={() => {
          dispatch(downloadAttachment(receipt))
        }}
      >
        {receipt.name}
      </Button>
    ),
  }))

  return (
    <Wrapper container spacing={2}>
      <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
        <Button color="primary" onClick={() => dispatch(setUpdateReceiptsModal(true))}>
          Upload
        </Button>
      </ActionWrapper>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DataGrid
          gridTitleProperties={gridTitleStyles}
          headers={receiptsListHeaders}
          tableData={finalFundsReceiptsData}
          setRequestedSort={() => {}}
          order={'DESC'}
          orderBy={'createdDate'}
          border="allSides"
          radius={'false'}
          alternateRowColored={true}
        />
      </Grid>
    </Wrapper>
  )
}

ReceiptsList.propTypes = {
  updatingReceipts: PropTypes.array,
  setUpdatingReceipts: PropTypes.func,
}
export default ReceiptsList
