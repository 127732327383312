import { backendAxios, fetchWithRetries, getConfig } from './backend'

/**
 * When user logs in for the first time, a pop up shows up
 * @param {*Response object of Site address confirmation}
 *
 */
export const saveSiteAddressConfirmation = siteAddress =>
  backendAxios
    .post('/site/verifysiteaddress', siteAddress, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const addNewPatient = patientInfo =>
  backendAxios
    .post('/users', patientInfo, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getPatientsList = filters => fetchWithRetries('GET', '/users', getConfig(filters))
