import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from './userInteractions'
import { fetchTcnUsersLookup, setSiteClientId, showError } from './application'
import { fetchCountriesLookup } from './common'
import { getSiteAddressesForSite, saveSitePreferenceAnswers } from 'services/settings'
import {
  addNewPatient,
  getPatientsList,
  saveSiteAddressConfirmation
} from 'services/patientExperienceManager'
import { copyObject, extractArrayOfIdsForEachKey, sortList } from 'components/helper/utility'
import { fetchUnansweredSitePref } from './configuration/sitePreferencesConfiguration'
import { addressTypes } from 'components/helper/constants/patientExperienceManager'

export const setShowAddressVerificationPopup = createAction(
  'patientExperienceManager/setShowAddressVerificationPopup'
)
// export const setSiteAddressNPhone = createAction('patientExperienceManager/setSiteAddressNPhone')
export const setUpdatedVisitAddress = createAction(
  'patientExperienceManager/setUpdatedVisitAddress'
)
export const setUpdatedPackageAddress = createAction(
  'patientExperienceManager/setUpdatedPackageAddress'
)
export const setAddressConfirmation = createAction(
  'patientExperienceManager/setAddressConfirmation'
)
export const setPatientsList = createAction('patientExperienceManager/setPatientsList')
export const setPatientTablePageNumber = createAction(
  'patientExperienceManager/setPatientTablePageNumber'
)
export const setRowsPerPage = createAction('patientExperienceManager/setRowsPerPage')
export const setRequestedSort = createAction('patientExperienceManager/setRequestedSort')
export const setIsAddressVerificationRequired = createAction(
  'patientExperienceManager/setIsAddressVerificationRequired'
)
export const setExpandedFilters = createAction('patientExperienceManager/setExpandedFilters')
export const setFilters = createAction('patientExperienceManager/setFilters')
export const setConstantPatientList = createAction(
  'patientExperienceManager/setConstantPatientList'
)
export const resetPatientExperienceManager = createAction(
  'patientExperienceManager/resetPatientExperienceManager'
)
export const setSitePrefVerificationFlow = createAction(
  'patientExperienceManager/setSitePrefVerificationFlow'
)
export const setSitePrefSuccessModal = createAction(
  'patientExperienceManager/setSitePrefSuccessModal'
)
export const setAddPatientFlow = createAction('patientExperienceManager/setAddPatientFlow')
export const setAddedPatientId = createAction('patientExperienceManager/setAddedPatientId')
export const setAddressVerificationSuccessModal = createAction(
  'patientExperienceManager/setAddressVerificationSuccessModal'
)
export const setAlignment = createAction('patientExperienceManager/setAlignment')

const searchList = (searchTerm, list) => {
  const searchFields = [
    'recordCreatedDate',
    'tcnId',
    'userInitials',
    'source',
    'participationProgress',
    'subjectId',
    'sponsorSiteId',
    'siteCountry'
  ]

  const results = list.filter(item => {
    for (const field of searchFields) {
      if (item[field] && item[field].toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }
    }
    return false
  })

  return results
}

/* -------Dashboard - Patients list----- */
export const fetchListOfPatients = fromTeleHealth => async (dispatch, getState) => {
  dispatch(setPatientsList({ isLoading: true }))
  try {
    const { constantPatientList, filters } = getState().patientExperienceManager
    let finalFilters, data
    const { sites, countries, participationProgresses, sources, search } = filters
    const filtersSelected = sites.length > 0 || countries.length > 0 || participationProgresses.length > 0 || sources.length > 0 || search !== ''
    // if this is true that means this function is triggered from filter component
    if (filtersSelected) {
      const { sites, countries, participationProgresses, sources } = filters
      finalFilters =
        (sites.length || countries.length || participationProgresses.length || sources.length) &&
        extractArrayOfIdsForEachKey({
          sites,
          countries,
          participationProgresses,
          sources
        })

      // User might select filters and enter something in search field
      if (finalFilters && filters.search) {
        ;[{ data }] = [await getPatientsList({ filter: finalFilters })]
        data = searchList(filters.search, data.userList)
      } else if (finalFilters) {
        // if this true, that means there are filters selected
        ;[{ data }] = [await getPatientsList({ filter: finalFilters })]
        data = data.userList
      } else if (filters.search) {
        data = searchList(filters.search, constantPatientList)
      } else {
        // if there are is no search term
        data = constantPatientList
      }

      if (data) {
        dispatch(
          setPatientsList({
            results: data,
            totalCount: data.length
          })
        )
      }
    } else {
      ;[{ data }] = [await getPatientsList()]
      if (data) {
        if (data.siteClientId && fromTeleHealth) {
          dispatch(setSiteClientId(data.siteClientId))
          localStorage.setItem('siteClientId', data.siteClientId)
        } else {
          dispatch(
            setPatientsList({
              results: data.userList,
              totalCount: data.userList.length
            })
          )
          dispatch(setConstantPatientList(data.userList))
          if (data.siteClientId) {
            dispatch(setSiteClientId(data.siteClientId))
            localStorage.setItem('siteClientId', data.siteClientId)
          }
          // check if the address verification flag is true
          // If true then show the welcome modal and option to continue with address verification
          // In order to get the updated value, we are getting this loggedInAsSite value here
          const { loggedInAsSite } = getState().auth
          if (loggedInAsSite && data.isAddressVerificationRequired) {
            dispatch(setIsAddressVerificationRequired(true))
          } else if (!finalFilters) {
            // make a call to fetch siteTravel pref questions
            await dispatch(fetchUnansweredSitePref())
          }
        }
      }
    }
  } catch (e) {
    dispatch(setConstantPatientList([]))
    dispatch(setPatientsList({ results: [], totalCount: 0 }))
    dispatch(setSiteClientId(''))
    dispatch(showError('Error while trying to fetching patients list. Please try again.', e))
  }
  dispatch(setPatientsList({ isLoading: false }))
}

export const handleRequestSort = orderByValue => (dispatch, getState) => {
  const { patientsList } = getState().patientExperienceManager
  dispatch(setRequestedSort(orderByValue))
  const { order, orderBy } = getState().patientExperienceManager

  dispatch(
    setPatientsList({
      results: sortList(patientsList.results, order, orderBy),
      totalCount: patientsList.totalCount
    })
  )
}

/* -------Address confirmation----- */
export const fetchSiteAddressForVerification = () => async (dispatch, getState) => {
  try {
    const { siteClientId } = getState().application

    const response = await getSiteAddressesForSite(siteClientId)

    const patientCareAddress = response.find(
      address => address.addressType.id === addressTypes.patientCare
    )

    const shippingAddress = response.find(
      address => address.addressType.id === addressTypes.shipping
    )
    const businessAddress = response.find(
      address => address.addressType.id === addressTypes.business
    )
    if (patientCareAddress && !patientCareAddress.isAddressVerified) {
      dispatch(setUpdatedVisitAddress(patientCareAddress))
    } else if (!patientCareAddress) {
      dispatch(setUpdatedVisitAddress(businessAddress))
    }

    if (shippingAddress && !shippingAddress.isAddressVerified) {
      dispatch(setUpdatedPackageAddress(shippingAddress))
    } else if (!shippingAddress) {
      dispatch(setUpdatedPackageAddress(businessAddress))
    }
  } catch {
    dispatch(showError('There is some issue while trying to fetch site addresses.'))
  }
}

export const startAddressConfirmation = () => async (dispatch, getState) => {
  try {
    const { siteClientId } = getState().application
    dispatch(setLoading(true))
    await dispatch(fetchSiteAddressForVerification())
    await dispatch(fetchTcnUsersLookup(siteClientId))
    await dispatch(fetchCountriesLookup())
    dispatch(setLoading(false))
  } catch {
    dispatch(setLoading(false))
    dispatch(showError('Something went wrong'))
  }
}

export const onAddressConfirmation = values => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { username } = getState().auth
    const { siteClientId } = getState().application
    const { updatedVisitAddress, updatedPackageAddress } = getState().patientExperienceManager

    let payload = {
      userId: username,
      siteClientId
    }

    if (values.isVisitAddress === 'no' && updatedVisitAddress.address1) {
      dispatch(setUpdatedVisitAddress(values.updatedVisitAddress))
      payload = {
        ...payload,
        visitAddress: {
          ...values.updatedVisitAddress,
          luStateId: parseInt(values.updatedVisitAddress.state.id),
          luCountryId: parseInt(values.updatedVisitAddress.country.id),
          isAddressVerified: true
        }
      }
    } else if (values.isVisitAddress === 'yes' && updatedVisitAddress.address1) {
      // Radio option not changed - This means user did not change address and confirmed that the address in the system is correct
      // if updatedVisitAddress.address1 does not exists, that means there is already a verified visit address in the system
      payload = {
        ...payload,
        visitAddress: {
          ...updatedVisitAddress,
          luStateId: parseInt(updatedVisitAddress.state.id),
          luCountryId: parseInt(updatedVisitAddress.country.id),
          isAddressVerified: true
        }
      }
    }

    if (values.isPackageAddress === 'no' && updatedPackageAddress.address1) {
      dispatch(setUpdatedPackageAddress(values.updatedPackageAddress))
      payload = {
        ...payload,
        shippingAddress: {
          ...values.updatedPackageAddress,
          luStateId: parseInt(values.updatedPackageAddress.state.id),
          luCountryId: parseInt(values.updatedPackageAddress.country.id),
          isAddressVerified: true,
          attentionOfContactUserEntityId: values.attentionOf.id,
          attentionOfContactUserName: values.attentionOf.displayText
        }
      }
    } else if (values.isPackageAddress === 'yes' && updatedPackageAddress.address1) {
      // Radio option not changed - This means user did not change address and confirmed that the address in the system is correct
      // if updatedPackageAddress.address1 does not exists, that means there is already a verified shipping address in the system
      payload = {
        ...payload,
        shippingAddress: {
          ...updatedPackageAddress,
          luStateId: parseInt(updatedPackageAddress.state.id),
          luCountryId: parseInt(updatedPackageAddress.country.id),
          isAddressVerified: true,
          attentionOfContactUserEntityId: values.attentionOf.id,
          attentionOfContactUserName: values.attentionOf.displayText
        }
      }
    }
    dispatch(setAddressConfirmation(values))
    await saveSiteAddressConfirmation(payload)
    dispatch(setIsAddressVerificationRequired(false))
    dispatch(setAddressVerificationSuccessModal(true))
  } catch {
    dispatch(showError('There was some issue while trying to save information.'))
  }
  dispatch(setLoading(false))
}

export const onSitePreferencesConfirmation = values => async dispatch => {
  dispatch(setLoading(true))

  try {
    const siteTravelPreferenceResponses = Object.keys(values).map((t, index) => {
      let userResponse = Object.values(values)[index]

      // For Checkboxes send selected options as comma separated string
      userResponse = Array.isArray(userResponse)
        ? userResponse.map(option => option.id).join(',')
        : Object.prototype.toString.call(userResponse) === '[object Date]'
          ? userResponse
          : userResponse && typeof userResponse === 'object'
            ? userResponse.id
            : userResponse

      return { sitePreferenceQuestionId: parseInt(t), response: userResponse }
    })

    await saveSitePreferenceAnswers({ responses: siteTravelPreferenceResponses })
    dispatch(setSitePrefVerificationFlow(false))
    dispatch(setSitePrefSuccessModal(true))
  } catch (e) {
    dispatch(
      showError(
        'There was some error while saving Site Preference Answers. Please try again later.',
        e
      )
    )
  } finally {
    dispatch(setLoading(false))
  }
}

export const onAddPatient = patientInfo => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { siteClientId } = getState().application
    const { instanceConfig } = getState().instanceConfiguration

    const formattedPayload = {
      siteClientId: patientInfo.siteClientId?.alternateIdentifier
        ? patientInfo.siteClientId.alternateIdentifier
        : siteClientId,
      firstName: patientInfo.firstName,
      middleInitial: patientInfo.middleInitial,
      lastName: patientInfo.lastName,
      preferredLanguageId: patientInfo.preferredLanguage.id,
      emailAddress: patientInfo.emailAddress,
      // genderId: patientInfo.gender.id,
      sourceId: patientInfo.source ? patientInfo.source.id : null,
      participationProgressId: patientInfo.participationProgress
        ? patientInfo.participationProgress.id
        : null,
      appointmentDate:
        patientInfo.participationProgress?.id === 22 && patientInfo.appointmentDate
          ? patientInfo.appointmentDate
          : null,
      subjectId: '',
      subjectNumber: '',
      englishSkillLevelId: patientInfo.englishSkill?.id,
      isAcknowledged: patientInfo.isAcknowledged,
      luCountryId: patientInfo.country?.id
    }

    // Subject ID is a dropdown
    if (instanceConfig.results.IVRIntegration === 'true') {
      formattedPayload.subjectId = patientInfo.subjectId ? patientInfo.subjectId.id : null
      formattedPayload.subjectNumber = patientInfo.subjectId
        ? patientInfo.subjectId.displayText
        : null
    } else {
      // Subject ID is a Open text
      formattedPayload.subjectId = null
      formattedPayload.subjectNumber = patientInfo.subjectId
    }

    const newPatient = await addNewPatient(formattedPayload)
    dispatch(setAddPatientFlow(false))
    dispatch(setAddedPatientId(newPatient.userId))
    // After add refresh Patient list
    dispatch(fetchListOfPatients())
  } catch (e) {
    dispatch(
      showError('There was some error while trying to add a patient. Please try again later.', e)
    )
  }
  dispatch(setLoading(false))
}

export const initialState = {
  addressVerificationSuccessModal: false,
  showAddressVerificationPopup: false,
  sitePrefVerificationFlow: false,
  isVisitAddress: 'yes',
  isPackageAddress: 'yes',
  attentionOf: null,
  isAddressVerificationRequired: false,
  sitePrefSuccessModal: false,
  updatedVisitAddress: {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    phone: '',
    isAddressVerified: false,
    addressType: ''
  },
  updatedPackageAddress: {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    phone: '',
    isAddressVerified: false,
    addressType: ''
  },
  filters: {
    sites: [],
    countries: [],
    participationProgresses: [],
    sources: [],
    search: ''
  },
  expandedFilters: false,
  patientsList: { totalCount: 0, results: [], isLoading: false },
  patientTablePageNumber: 0,
  rowsPerPage: 20,
  orderBy: 'recordCreatedDate',
  order: 'DESC',
  constantPatientList: [],
  addPatientFlow: false,
  addedPatientId: '',
  alignment: 'active'
}

// eslint-disable-next-line max-lines-per-function
export default createReducer(initialState, builder => {
  builder
    .addCase(setIsAddressVerificationRequired, (state, action) => {
      state.isAddressVerificationRequired = action.payload
    })
    .addCase(setAddressVerificationSuccessModal, (state, action) => {
      state.addressVerificationSuccessModal = action.payload
    })
    .addCase(setShowAddressVerificationPopup, (state, action) => {
      state.showAddressVerificationPopup = action.payload
    })
    .addCase(setSitePrefVerificationFlow, (state, action) => {
      state.sitePrefVerificationFlow = action.payload
    })
    .addCase(setSitePrefSuccessModal, (state, action) => {
      state.sitePrefSuccessModal = action.payload
    })
    .addCase(setAddressConfirmation, (state, action) => {
      state.isVisitAddress = action.payload.isVisitAddress
      state.isPackageAddress = action.payload.isPackageAddress
      state.attentionOf = action.payload.attentionOf
    })
    .addCase(setUpdatedVisitAddress, (state, action) => {
      const {
        address1,
        address2,
        address3,
        city,
        zipCode,
        phone,
        isAddressVerified,
        addressType,
        locationName
      } = action.payload
      state.updatedVisitAddress = {
        address1,
        address2: address2 || '',
        address3: address3 || '',
        city,
        state: action.payload.state,
        country: action.payload.country,
        zipCode,
        phone,
        isAddressVerified,
        addressType: addressType || { id: '' },
        locationName
      }
    })
    .addCase(setUpdatedPackageAddress, (state, action) => {
      const {
        address1,
        address2,
        address3,
        city,
        zipCode,
        phone,
        isAddressVerified,
        addressType,
        locationName
      } = action.payload
      state.updatedPackageAddress = {
        address1,
        address2: address2 || '',
        address3: address3 || '',
        city,
        state: action.payload.state,
        country: action.payload.country,
        zipCode,
        phone,
        isAddressVerified,
        addressType: addressType || { id: '' },
        locationName
      }
    })
    .addCase(setPatientsList, (state, action) => {
      state.patientsList = { ...state.patientsList, ...action.payload }
    })
    .addCase(setPatientTablePageNumber, (state, action) => {
      state.patientTablePageNumber = action.payload
    })
    .addCase(setRowsPerPage, (state, action) => {
      state.rowsPerPage = parseInt(action.payload, 10)
      state.patientTablePageNumber = 0
    })
    .addCase(setRequestedSort, (state, action) => {
      const isDesc = state.orderBy === action.payload && state.order === 'DESC'
      state.order = isDesc ? 'ASC' : 'DESC'
      state.orderBy = action.payload
    })
    .addCase(setExpandedFilters, (state, action) => {
      state.expandedFilters = action.payload
    })
    .addCase(setFilters, (state, action) => {
      state.filters = action.payload
    })
    .addCase(setConstantPatientList, (state, action) => {
      state.constantPatientList = action.payload
    })
    .addCase(resetPatientExperienceManager, state => {
      copyObject(state, initialState)
    })
    .addCase(setAddPatientFlow, (state, action) => {
      state.addPatientFlow = action.payload
    })
    .addCase(setAddedPatientId, (state, action) => {
      state.addedPatientId = action.payload
    })
    .addCase(setAlignment, (state, action) => {
      state.alignment = action.payload
    })
})
