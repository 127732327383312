import styled from '@emotion/styled'
import { Box, Chip, Grid, Skeleton, Typography } from '@mui/material'
import { Button, DataGrid, PageHeader } from 'components/common'
import QuickView from 'components/common/QuickView'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  downloadPatientDashboardTemplate,
  downloadVisitDropdownMenusTemplate,
  fetchPatientDashboardDisplayGrid,
  fetchSitesByCountries,
  fetchVisitDropdownMenus,
  setSitesLookupByCountry
} from 'store/ducks/configuration/visitDetailsConfiguration'
import { palette } from 'styles/baseTheme'
import UploadPatientDashboardDisplay from './UploadPatientDashboardDisplay'
import EditAssociatedSites from './EditAssociatedSites'
import { visitDetailsUploadType } from 'components/helper/constants/configuration'

const TitleGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
`

const ActionWrapper = styled.div(({ theme }) => ({
  '&&': {
    button: {
      marginLeft: theme.spacing(1)
    }
  }
}))

const MainGrid = styled(Grid)(({ theme }) => ({
  '&&': {
    marginTop: theme.spacing(2)
  }
}))

const SitesListWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    '.MuiChip-root': {
      width: 'fit-content',
      margin: theme.spacing(0.5)
    }
  }
}))

const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  textAlign: 'start',
  minWidth: '140px'
}

const bodyProperties = {
  cellType: 'node',
  textAlign: 'start',
  minWidth: '140px',
  border: '0px',
  borderBottom: `3px inset ${palette.midnight.light}`,
  paddingLeft: '16px'
}

const patientDashboardDisplayHeaders = [
  {
    id: 'displayName',
    label: 'Display Name',
    headProperties: { ...headProperties },
    bodyProperties: { ...bodyProperties }
  },
  {
    id: 'fileName',
    label: 'File Name',
    headProperties: { ...headProperties },
    bodyProperties: { ...bodyProperties }
  },
  {
    id: 'associatedSites',
    label: 'Associated Sites',
    headProperties: { ...headProperties },
    bodyProperties: { ...bodyProperties }
  },
  {
    id: 'edit',
    label: 'Edit',
    headProperties: { ...headProperties },
    bodyProperties: { ...bodyProperties }
  }
  // {
  //   id: 'delete',
  //   label: 'Delete',
  //   headProperties: { ...headProperties },
  //   bodyProperties: { ...bodyProperties }
  // },
]

const editAssociatedSitesInitialValue = {
  filterByCountry: [],
  siteAssociation: []
}

const VisitDetails = () => {
  const dispatch = useDispatch()

  const { patientDashboardDisplayGrid, visitDropdownMenus } = useSelector(
    store => store.visitDetailsConfiguration
  )
  const [uploadPatientDashboardDisplayModal, setUploadPatientDashboardDisplayModal] =
    useState(false)
  const [uploadVisitDropdownMenusModal, setUploadVisitDropdownMenusModal] = useState(false)
  const [editAssociatedSitesModal, setEditAssociatedSitesModal] = useState(false)
  const [editAssociatedSites, setEditAssociatedSites] = useState(editAssociatedSitesInitialValue)
  const fetchAPIData = async () => {
    await dispatch(fetchPatientDashboardDisplayGrid())
    await dispatch(fetchVisitDropdownMenus())
  }
  useEffect(() => {
    fetchAPIData()
  }, [])

  const getGridData = (tableData, uploadType) =>
    tableData.map(item => ({
      ...item,
      associatedSites: (
        <SitesListWrapper>
          {item.associatedSites.map((site, index) => (
            <Chip variant="outlined" size="small" key={index} label={site.displayText} />
          ))}
        </SitesListWrapper>
      ),
      edit: (
        <Button
          variant="text"
          onClick={() => {
            dispatch(fetchSitesByCountries(item.associatedCountries))
            setEditAssociatedSites({
              uploadType,
              groupId:
                uploadType === visitDetailsUploadType.patientDashboardDisplay
                  ? item.visitGuidelineGroupId
                  : item.visitScheduleGroupId,
              displayName: item.displayName || '',
              fileName: item.fileName,
              filterByCountry: item.associatedCountries,
              siteAssociation: item.associatedSites
            })
            setEditAssociatedSitesModal(true)
          }}
        >
          Edit
        </Button>
      )
    }))

  const updatedPatientDashboardData = getGridData(
    patientDashboardDisplayGrid.results,
    visitDetailsUploadType.patientDashboardDisplay
  )

  const updatedVisitDropdown = getGridData(
    visitDropdownMenus.results,
    visitDetailsUploadType.visitDropdownMenus
  )

  return (
    <>
      <PageHeader
        title={
          <Typography variant="h4" gutterBottom>
            Visit Details
          </Typography>
        }
        content={
          <>
            {patientDashboardDisplayGrid.isLoading
              ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                    </Box>
                  </Grid>
                </Grid>
              )
              : (
                <MainGrid container spacing={2}>
                  <TitleGrid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body2">Patient Dashboard display</Typography>
                    <ActionWrapper>
                      <Button onClick={() => dispatch(downloadPatientDashboardTemplate())}>
                      Download Template
                      </Button>
                      <Button onClick={() => setUploadPatientDashboardDisplayModal(true)}>
                      Upload
                      </Button>
                    </ActionWrapper>
                  </TitleGrid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataGrid
                      headers={patientDashboardDisplayHeaders}
                      tableData={updatedPatientDashboardData}
                      setRequestedSort={() => {}}
                      order={'DESC'}
                      orderBy={''}
                      alternateRowColored={true}
                      border="allSides"
                      radius={'false'}
                    />
                  </Grid>
                </MainGrid>
              )}

            {visitDropdownMenus.isLoading
              ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box>
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                      <Skeleton height={64} />
                    </Box>
                  </Grid>
                </Grid>
              )
              : (
                <MainGrid container spacing={2}>
                  <TitleGrid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body2">
                    Study Visit dropdown menus (TCN Card, TCN Arrive)
                    </Typography>
                    <ActionWrapper>
                      <Button onClick={() => dispatch(downloadVisitDropdownMenusTemplate())}>
                      Download Template
                      </Button>
                      <Button onClick={() => setUploadVisitDropdownMenusModal(true)}>Upload</Button>
                    </ActionWrapper>
                  </TitleGrid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataGrid
                      headers={patientDashboardDisplayHeaders}
                      tableData={updatedVisitDropdown}
                      setRequestedSort={() => {}}
                      order={'DESC'}
                      orderBy={''}
                      alternateRowColored={true}
                      border="allSides"
                      radius={'false'}
                    />
                  </Grid>
                </MainGrid>
              )}
          </>
        }
        expandContent={true}
        bar={true}
      />
      <QuickView
        title="Upload Patient Dashboard Display"
        onClose={() => {
          setUploadPatientDashboardDisplayModal(false)
          dispatch(setSitesLookupByCountry({ isLoading: false, results: [] }))
        }}
        dialogContent={
          <UploadPatientDashboardDisplay
            uploadType={visitDetailsUploadType.patientDashboardDisplay}
            onSuccess={() => setUploadPatientDashboardDisplayModal(false)}
          />
        }
        isDialogOpen={uploadPatientDashboardDisplayModal}
        maxWidth="sm"
      />
      <QuickView
        title="Upload Study Visit dropdown menus"
        onClose={() => {
          setUploadVisitDropdownMenusModal(false)
          dispatch(setSitesLookupByCountry({ isLoading: false, results: [] }))
        }}
        dialogContent={
          <UploadPatientDashboardDisplay
            uploadType={visitDetailsUploadType.visitDropdownMenus}
            onSuccess={() => setUploadVisitDropdownMenusModal(false)}
          />
        }
        isDialogOpen={uploadVisitDropdownMenusModal}
        maxWidth="sm"
      />
      <QuickView
        title="Edit Associated Sites"
        onClose={() => {
          setEditAssociatedSitesModal(false)
          setEditAssociatedSites(editAssociatedSitesInitialValue)
          dispatch(setSitesLookupByCountry({ isLoading: false, results: [] }))
        }}
        isDialogOpen={editAssociatedSitesModal}
        maxWidth="sm"
        dialogContent={
          <EditAssociatedSites
            editAssociatedSites={editAssociatedSites}
            onSuccess={() => setEditAssociatedSitesModal(false)}
          />
        }
      />
    </>
  )
}

export default VisitDetails
