import { createAction, createReducer } from '@reduxjs/toolkit'

import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import {
  addRequestMessage,
  addRequestStatus,
  getAdvancedSupportDetails,
  getAllRequests,
  getAuxillarySupportDetails,
  getDeliveryServicesDetails,
  getHomehealthVisitDetails,
  getInPersonVisitDetails,
  getOtherServicesDetails,
  getPatientRequests,
  getRequestAssignmentAndMessageHistory,
  getRequestCurrentItinerary,
  getTelehealthSupportDetails,
  getAvailableRequestTypes
} from 'services/rsgArrive'
import {
  downloadAttachmentByID,
  getRSGArriveRequestAssignments,
  postAttachments,
  getHelperAttachments
} from 'services/common'
import { copyObject, displayDate, displayTime, removeDuplicateItemsFromArray } from 'components/helper/utility'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import {
  assignment,
  multiActionModalTypes,
  requestStatusLabels,
  requestTypeIds,
  remoteRequestIds,
  requestTypeOrder
} from 'components/helper/constants/visitServices'
import { getVisitNames, getVisitScheduleGroup } from 'services/visit'
import { helperAttachmentModuleNames } from 'components/helper/constants/common'

export const setMessageSaved = createAction('requestDetails/setMessageSaved')
export const setAssignmentAndMessageHistory = createAction(
  'requestDetails/setAssignmentAndMessageHistory'
)
export const setVisitDetails = createAction('requestDetails/setVisitDetails')
export const setIsRequestCompletedOrCancelled = createAction(
  'requestDetails/setIsRequestCompletedOrCancelled'
)
export const setCurrentItinerary = createAction('requestDetails/setCurrentItinerary')
export const setMarkAsCompleteModal = createAction('requestDetails/setMarkAsCompleteModal')
export const setRequestMarkedComplete = createAction('requestDetails/setRequestMarkedComplete')
export const setNotApproveModal = createAction('requestDetails/setNotApproveModal')
export const setApproveModal = createAction('requestDetails/setApproveModal')
export const setApproveSuccessModal = createAction('requestDetails/setApproveSuccessModal')
export const setArriveRequestsAssignments = createAction(
  'requestDetails/setArriveRequestsAssignments'
)
export const setMultiActionForm = createAction('requestDetails/setMultiActionForm')
export const setPatientRequests = createAction('requestDetails/setPatientRequests')
export const setOpenSubmitSuccess = createAction('requestDetails/setOpenSubmitSuccess')
export const setAllRequests = createAction('requestDetails/setAllRequests')
export const setVisitNames = createAction('requestDetails/setVisitNames')
export const setAvailableRequestTypeIds = createAction('visitServices/setAvailableRequestTypeIds')
export const setStartNewRequestForVisitFlow = createAction(
  'visitServices/setStartNewRequestForVisitFlow'
)
export const setArriveHelperAttachments = createAction('common/setArriveHelperAttachments')
export const resetRequestDetails = createAction('requestDetails/resetRequestDetails')
export const setVisitScheduleGroup = createAction('requestDetails/setVisitScheduleGroup')

export const saveRequestMessage = (requestId, modalType, values) => async (dispatch, getState) => {
  try {
    const { patientInformation } = getState().patientMainWrapper
    dispatch(setLoading(true))

    const messageData = {
      requestId,
      requestAssignmentId: values.assignment.id,
      isItinerary: modalType === multiActionModalTypes.uploadFile,
      note: values.note
    }

    // If Send to Site
    /**
     * RequestId,
     * RequestAssignmentId,
     * SendToSite (have to send true),
     * note
     */
    if (modalType === multiActionModalTypes.sendToSite) {
      messageData.sendToSite = true
    }

    if (values.attachments.length > 0) {
      //  Create an object of formData
      const formData = new FormData()

      values.attachments.forEach(f => {
        // Update the formData object
        formData.append('files', f)
      })

      const attachmentResponse = await postAttachments(formData)

      if (attachmentResponse && attachmentResponse.data?.length === 1) {
        messageData.attachmentId = attachmentResponse.data[0]
      } else {
        dispatch(
          showError(
            'There was some issue while trying to upload the attachments. Please check your file and try again.'
          )
        )
      }
    }
    await addRequestMessage(messageData)
    if ([2, 3].includes(messageData.requestAssignmentId)) {
      dispatch(fetchBadgesForPatientSection(patientInformation.userId))
    }
    dispatch(setMessageSaved(true))
    dispatch(showSuccess('Added message successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const approveRequest = requestId => async (dispatch, getState) => {
  try {
    const { patientInformation } = getState().patientMainWrapper
    const approveData = {
      requestId,
      requestStatusId: 2,
      isNotApproved: undefined,
      note: 'Itinerary / Services Details Approved'
    }
    dispatch(setLoading(true))
    await addRequestStatus(approveData)
    dispatch(setApproveModal(false))
    dispatch(fetchBadgesForPatientSection(patientInformation.userId))
    dispatch(setApproveSuccessModal(true))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

export const cancelRequest =
  (requestId, cancelNotes, onCancelRequest) => async (dispatch, getState) => {
    try {
      const { patientInformation } = getState().patientMainWrapper
      const cancelData = {
        requestId,
        requestStatusId: 4,
        isNotApproved: undefined,
        note: cancelNotes
      }
      dispatch(setLoading(true))
      await addRequestStatus(cancelData)
      dispatch(showSuccess('Cancelled request successfully!'))
      await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
      // Need to call this after Request Cancellation is successful.
      onCancelRequest && onCancelRequest()
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const downloadAttachment = attachment => async dispatch => {
  try {
    dispatch(setLoading(true))
    await downloadAttachmentByID(attachment)
  } catch (e) {
    dispatch(showError('There was some issue with downloading the attachment.'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const makeAllRequestDetailsCalls = (requestTypeId, requestId) => async dispatch => {
  await dispatch(fetchVisitDetails(requestTypeId, requestId))
  await dispatch(fetchAssignmentAndMessageHistory(requestId))
  await dispatch(fetchItineraryOfRequest(requestId))
}

export const fetchAssignmentAndMessageHistory = requestId => async dispatch => {
  try {
    dispatch(setAssignmentAndMessageHistory({ isLoading: true }))
    const response = await getRequestAssignmentAndMessageHistory(requestId)

    const finalRes = response.map(res => ({
      ...res,
      assignment: res.assignment,
      note: res.note,
      fromPolicy: res.fromPolicy,
      messageSent: `${displayDate(res.messageSentOn)} ${displayTime(res.messageSentOn)} EST`
    }))

    dispatch(setAssignmentAndMessageHistory({ isLoading: false, results: finalRes }))
  } catch (e) {
    dispatch(setAssignmentAndMessageHistory({ isLoading: false }))
    dispatch(showError('Something went wrong while fetching Assignment and Message History', e))
  }
}
export const fetchVisitDetails = (requestTypeId, requestId) => async dispatch => {
  try {
    dispatch(setVisitDetails({ isLoading: true }))
    // Based on RequestType get the correct api call
    let requestDetailsPromise
    switch (requestTypeId) {
      case requestTypeIds.inPersonVisit:
        requestDetailsPromise = getInPersonVisitDetails
        break
      case requestTypeIds.auxillarySupport:
        requestDetailsPromise = getAuxillarySupportDetails
        break
      case requestTypeIds.advancedSupport:
        requestDetailsPromise = getAdvancedSupportDetails
        break
      case requestTypeIds.telehealthVisit:
        requestDetailsPromise = getTelehealthSupportDetails
        break
      case requestTypeIds.deliveryServices:
        requestDetailsPromise = getDeliveryServicesDetails
        break
      case requestTypeIds.homeHealthVisit:
        requestDetailsPromise = getHomehealthVisitDetails
        break
      case requestTypeIds.otherServices:
        requestDetailsPromise = getOtherServicesDetails
        break
      default:
        break
    }

    if (requestDetailsPromise) {
      const response = await requestDetailsPromise(requestId)

      dispatch(
        setIsRequestCompletedOrCancelled(
          [requestStatusLabels.Completed, requestStatusLabels.Cancelled].includes(
            response?.requestStatus
          )
        )
      )

      dispatch(setVisitDetails({ isLoading: false, results: response }))
    }
  } catch (e) {
    dispatch(setVisitDetails({ isLoading: false }))
    dispatch(showError('Something went wrong while fetching Assignment and Message History', e))
  }
}

export const fetchItineraryOfRequest = requestId => async dispatch => {
  try {
    dispatch(setCurrentItinerary({ isLoading: true }))
    const response = await getRequestCurrentItinerary(requestId)
    dispatch(setCurrentItinerary({ isLoading: false, results: response }))
  } catch (e) {
    dispatch(setCurrentItinerary({ isLoading: false }))
    dispatch(showError('Something went wrong while trying to fetch itinerary of the report', e))
  }
}

export const markRequestAsComplete = requestId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const approveData = {
      requestId,
      requestStatusId: 3,
      isNotApproved: undefined,
      note: ''
    }
    await addRequestStatus(approveData)
    dispatch(showSuccess('Request Marked as Completed successfully!'))
    dispatch(setMarkAsCompleteModal(false))
    dispatch(setRequestMarkedComplete(true))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

export const markRequestNotApproved =
  (requestTypeId, requestId, notApproveNotes) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { patientInformation } = getState().patientMainWrapper
      const notApproveData = {
        requestId,
        requestStatusId: null,
        isNotApproved: true,
        note: notApproveNotes
      }
      await addRequestStatus(notApproveData)
      dispatch(setNotApproveModal(false))
      dispatch(fetchBadgesForPatientSection(patientInformation.userId))
      dispatch(makeAllRequestDetailsCalls(requestTypeId, requestId))
      dispatch(showSuccess('Not Approved request successfully!'))
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    }
    dispatch(setLoading(false))
  }

export const fetchArriveRequestsAssignments = modalType => async (dispatch, getState) => {
  try {
    const { arriveRequestsAssignments } = getState().requestDetails
    dispatch(setArriveRequestsAssignments({ isLoading: true }))
    let response
    if (arriveRequestsAssignments.results.length === 0) {
      response = await getRSGArriveRequestAssignments()

      if (response.length) {
        dispatch(setArriveRequestsAssignments({ results: response }))
      }
    } else {
      response = arriveRequestsAssignments.results
    }

    const { multiActionForm } = getState().requestDetails
    if (response.length === 1 && response[0].displayText === assignment.concierge) {
      // If there is only 1 Assignee - Concierge, default to it.
      dispatch(setMultiActionForm({ ...multiActionForm, assignment: response[0] }))
    }

    if (modalType === multiActionModalTypes.sendToSite) {
      // For Send to Site, defaulted to Site
      const assigneeSite = response.filter(option => option.displayText === 'Site')
      if (assigneeSite.length) {
        dispatch(setMultiActionForm({ ...multiActionForm, assignment: assigneeSite[0] }))
      }
    }
  } catch (e) {
    dispatch(showError('There was error while fetching request assignments.', e))
  }
  dispatch(setArriveRequestsAssignments({ isLoading: false }))
}

export const fetchPatientRequests = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    let result = await getPatientRequests()
    // Sort with latest Visit Date on top
    result = result.sort((a, b) => (a.visitDate > b.visitDate ? 1 : -1))
    const formattedRequests = result.map(request => ({
      ...request,
      visitDate: displayDate(request.visitDate)
    }))
    dispatch(setPatientRequests(formattedRequests))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchAllRequests = userId => async dispatch => {
  try {
    dispatch(setLoading(true))
    dispatch(setAllRequests({ isLoading: true }))
    let result = await getAllRequests(userId)
    // Sort with latest Visit Date on top
    result = result.sort((a, b) => (a.visitDate > b.visitDate ? 1 : -1))
    const formattedRequests = result.map(request => ({
      ...request,
      requestDate: displayDate(request.requestDate),
      visitDate: displayDate(request.visitDate),
      statusDate: displayDate(request.statusDate)
    }))
    dispatch(setAllRequests({ results: formattedRequests }))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
    dispatch(setAllRequests({ isLoading: false }))
  }
}

export const fetchVisitNames = (userId, visitScheduleGroupId) => async dispatch => {
  dispatch(setVisitNames({ isLoading: true }))
  try {
    dispatch(setLoading(true))
    const visitNames = await getVisitNames(userId, visitScheduleGroupId)
    if (visitNames) {
      // ! Removes duplicate objects -  Temporary solution on FE, later will be taken care from API -
      const finalRes = removeDuplicateItemsFromArray(visitNames, 'displayText')
      dispatch(setVisitNames({ results: finalRes }))
    }
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
    dispatch(setVisitNames({ isLoading: false }))
  }
}

export const fetchVisitScheduleGroup = userId => async (dispatch, getState) => {
  const { patientInformation } = getState().patientMainWrapper
  dispatch(setVisitScheduleGroup({ isLoading: true }))
  try {
    dispatch(setLoading(true))
    const visitScheduleGroupRes = await getVisitScheduleGroup(userId)
    if (visitScheduleGroupRes && visitScheduleGroupRes.length === 1) {
      await dispatch(fetchVisitNames(patientInformation.userId, visitScheduleGroupRes[0].id))
    }
    dispatch(setVisitScheduleGroup({ results: visitScheduleGroupRes }))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
    dispatch(setVisitScheduleGroup({ isLoading: false }))
  }
}

export const fetchAvailableRequestTypes = userId => async dispatch => {
  dispatch(setAvailableRequestTypeIds({ isLoading: true }))

  try {
    dispatch(setLoading(true))
    const availableRequestTypes = await getAvailableRequestTypes(
      userId
    )
    const availableRequestTypeIds = availableRequestTypes.map(reqType => reqType.id)
    // Order arrive types in the order they are appear in Make a Request screen
    const reorderedAvailableRequestTypeIds = requestTypeOrder
      .map(id => availableRequestTypeIds.find(availableReqId => availableReqId === id))
      // remove false values (undefined, null, false, '')
      .filter(Boolean)

    // Split the available request Ids into remote and nonRemote
    const nonRemote = []
    const remote = []
    reorderedAvailableRequestTypeIds.forEach(id =>
      (remoteRequestIds.includes(id) ? remote : nonRemote).push(id)
    )

    dispatch(
      setAvailableRequestTypeIds({
        nonRemote,
        remote
      })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
    dispatch(setAvailableRequestTypeIds({ isLoading: false }))
  }
}

export const fetchArriveHelperAttachments = () => async (dispatch, getState) => {
  try {
    const { helperAttachmentModules } = getState().common
    dispatch(setArriveHelperAttachments({ isLoading: true }))
    // Get Arrive helper module by filtering by name
    const arriveModule = helperAttachmentModules.results.filter(
      helperAttchmntModule =>
        helperAttchmntModule.displayText === helperAttachmentModuleNames.arriveGuidelines
    )

    // if Module is present, fetch helper attachments with attachmentModuleId
    if (arriveModule.length) {
      const results = await getHelperAttachments(arriveModule[0].id)
      dispatch(setArriveHelperAttachments({ results }))
    }
  } catch (e) {
    dispatch(showError('There was issue fetching Arrive Guidelines and Thresholds.'))
  } finally {
    dispatch(setArriveHelperAttachments({ isLoading: false }))
  }
}

export const multiActionFormInitialState = { assignment: null, attachments: [], note: '' }

const initialState = {
  messageSaved: false,
  assignmentAndMessageHistory: { isLoading: false, results: [] },
  visitDetails: { isLoading: false, results: '' },
  isRequestCompletedOrCancelled: false,
  currentItinerary: { isLoading: false, results: '' },
  markAsCompleteModal: false,
  requestMarkedComplete: false,
  notApproveModal: false,
  approveModal: false,
  approveSuccessModal: false,
  arriveRequestsAssignments: { isLoading: false, results: [] },
  multiActionForm: multiActionFormInitialState,
  openSubmitSuccess: false,
  patientRequests: [],
  allRequests: { results: [], isLoading: false },
  visitNames: { results: [], isLoading: false },
  availableRequestTypeIds: { remote: [], nonRemote: [], isLoading: false },
  startNewRequestForVisitFlow: null,
  arriveHelperAttachments: { isLoading: false, results: [] },
  visitScheduleGroup: { isLoading: false, results: [] }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setMessageSaved, (state, action) => {
      state.messageSaved = action.payload
    })
    .addCase(setAssignmentAndMessageHistory, (state, action) => {
      state.assignmentAndMessageHistory = {
        ...state.assignmentAndMessageHistory,
        ...action.payload
      }
    })
    .addCase(setVisitDetails, (state, action) => {
      state.visitDetails = { ...state.visitDetails, ...action.payload }
    })
    .addCase(setIsRequestCompletedOrCancelled, (state, action) => {
      state.isRequestCompletedOrCancelled = action.payload
    })
    .addCase(setCurrentItinerary, (state, action) => {
      state.currentItinerary = { ...state.currentItinerary, ...action.payload }
    })
    .addCase(setMarkAsCompleteModal, (state, action) => {
      state.markAsCompleteModal = action.payload
    })
    .addCase(setRequestMarkedComplete, (state, action) => {
      state.requestMarkedComplete = action.payload
    })
    .addCase(setNotApproveModal, (state, action) => {
      state.notApproveModal = action.payload
    })
    .addCase(setPatientRequests, (state, action) => {
      state.patientRequests = action.payload
    })
    .addCase(setOpenSubmitSuccess, (state, action) => {
      state.openSubmitSuccess = action.payload
    })
    .addCase(setAllRequests, (state, action) => {
      state.allRequests = { ...state.allRequests, ...action.payload }
    })
    .addCase(setVisitNames, (state, action) => {
      state.visitNames = { ...state.visitNames, ...action.payload }
    })
    .addCase(setApproveModal, (state, action) => {
      state.approveModal = action.payload
    })
    .addCase(setApproveSuccessModal, (state, action) => {
      state.approveSuccessModal = action.payload
    })
    .addCase(setArriveRequestsAssignments, (state, action) => {
      state.arriveRequestsAssignments = { ...state.arriveRequestsAssignments, ...action.payload }
    })
    .addCase(setMultiActionForm, (state, action) => {
      state.multiActionForm = action.payload
    })
    .addCase(setAvailableRequestTypeIds, (state, action) => {
      state.availableRequestTypeIds = { ...state.availableRequestTypeIds, ...action.payload }
    })
    .addCase(setStartNewRequestForVisitFlow, (state, action) => {
      state.startNewRequestForVisitFlow = action.payload
    })
    .addCase(setArriveHelperAttachments, (state, action) => {
      state.arriveHelperAttachments = { ...state.arriveHelperAttachments, ...action.payload }
    })
    .addCase(setVisitScheduleGroup, (state, action) => {
      state.visitScheduleGroup = { ...state.visitScheduleGroup, ...action.payload }
    })
    .addCase(resetRequestDetails, state => {
      copyObject(state, initialState)
    })
})
