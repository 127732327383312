const mapValuesToObjects = (dropdownOptions, values, mapWith = 'id') => {
  if (dropdownOptions && dropdownOptions.length && values) {
    if (typeof values === 'object') {
      return values.map(value => {
        const option = dropdownOptions.find(option => option[mapWith] === value)
        return option || ''
      })
    } else {
      return dropdownOptions.find(option => option[mapWith] === values)
    }
  } else {
    return ''
  }
}

export default mapValuesToObjects
