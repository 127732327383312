import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { CircularProgress, Grid, InputAdornment, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, Password, TextBox } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchEmailByResetToken,
  onSubmitNewPassword,
  performLogout,
  setPasswordUpdateSuccess
} from 'store/ducks/auth'
import { useNavigate, useParams } from 'react-router-dom'
import PasswordConstraintsCheck from 'components/common/utils/PasswordConstraintsCheck'
import { EmailRounded, LockReset, LockRounded } from '@mui/icons-material'

const StyledButton = styled(Button)`
  width: 35%;
`
const Wrapper = styled.div(
  ({ theme }) => `
text-align: center;
button {
  width: fit-content;
}
h2 {
  margin-bottom: ${theme.spacing(2)};
}
`
)

const ActionsGrid = styled(Grid)`
  display: flex;
  justify-content: space-around;
`

const validations = Yup.object().shape({
  password: Yup.string()
    .matches(/([a-z])/, 'Password should have minimum of one lower case letter.')
    .matches(/([A-Z])/, 'Password should have minimum of one uppercase case letter.')
    .matches(
      /(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/,
      'Password should have minimum of one special character.'
    )
    .matches(/([0-9])/, 'Password should have minimum of one numeric.')
    .min(8, 'Password is too short - should be 8 characters minimum.')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required')
})

const ResetPassword = () => {
  const dispatch = useDispatch()
  const { passwordUpdateSuccess, changePasswordForm } = useSelector(store => store.auth)
  const { resetToken } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    // this will clear pre-existing redux and local storage if any
    dispatch(performLogout(false, false))
    dispatch(fetchEmailByResetToken(resetToken))
  }, [])

  return changePasswordForm.isLoading
    ? (
      <CircularProgress />
    )
    : !passwordUpdateSuccess
      ? (
        <>
          <Typography gutterBottom variant="h2" color="primary">
        Reset Password
          </Typography>

          <Formik
            initialValues={changePasswordForm}
            enableReinitialize={true}
            validationSchema={validations}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(onSubmitNewPassword(values))
              setSubmitting(false)
            }}
          >
            {({ values }) => (
              <Form noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextBox
                      required
                      id="email-input"
                      label="Email"
                      name="email"
                      type="text"
                      size="small"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailRounded />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Password
                      size="small"
                      required
                      id="password-input"
                      label="Enter Password"
                      name="password"
                      type="password"
                      startAdornment={
                        <InputAdornment position="start">
                          <LockRounded />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PasswordConstraintsCheck enteringNewPassword={values.password} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Password
                      size="small"
                      required
                      id="confirm-password-input"
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      startAdornment={
                        <InputAdornment position="start">
                          <LockReset />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="caption">
                  By clicking Save, you agree to allow us to store and use your personal information
                  for the sole purpose of providing you with access to this system and services, in
                  accordance with our Privacy Policy.
                    </Typography>
                  </Grid>
                  <ActionsGrid item xs={12} sm={12} md={12} lg={12}>
                    <StyledButton color="primary" type="submit" size="large" fullWidth={true}>
                  Save
                    </StyledButton>
                    <StyledButton
                      color="inherit"
                      size="large"
                      fullWidth={true}
                      onClick={() => navigate('/login')}
                    >
                  Cancel
                    </StyledButton>
                  </ActionsGrid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )
      : (
        <Wrapper>
          <Typography gutterBottom variant="h2" color="primary">
        You have successfully updated your password.
          </Typography>
          <Button
            color="primary"
            size="large"
            fullWidth={true}
            onClick={() => {
              dispatch(setPasswordUpdateSuccess(false))
              dispatch(performLogout(() => navigate('/login')))
            }}
          >
        Go to Log In
          </Button>
        </Wrapper>
      )
}

export default ResetPassword
