import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Download } from '@mui/icons-material'
import { IconButton, List as MuiList, ListItem as MuiListItem, Typography } from '@mui/material'
import { Bar, Button } from 'components/common'

const StyledListItem = styled(MuiListItem)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  padding-top: 0px;
`
const TabContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-wrap: anywhere;
  min-height: 60px;
  button {
    text-decoration: none;
    text-align: start;
  }
`
const StyledList = styled(MuiList)`
  overflow-y: auto;
  max-height: 400px;
`

const List = ({ list, selectedItem, onAdornmentClicked, onItemClicked }) => (
  <StyledList component="nav" disablePadding aria-labelledby="administrative-documents-list">
    {list.length
      ? (
        list.map((listItem, index) => {
          const isSelected = selectedItem.id === listItem.id
          return (
            <StyledListItem key={index}>
              <TabContent>
                <IconButton
                  aria-label="Download"
                  color="primary"
                  onClick={() => onAdornmentClicked(listItem)}
                  size="large"
                >
                  <Download color="primary" />
                </IconButton>
                <Button
                  variant="text"
                  color={isSelected ? 'primary' : 'inherit'}
                  onClick={() => {
                    onItemClicked(listItem)
                  }}
                >
                  <Typography variant="body2">{listItem.name}</Typography>
                </Button>
              </TabContent>
              {list.length !== index + 1 && <Bar fullWidth color="gray" />}
            </StyledListItem>
          )
        })
      )
      : (
        <StyledListItem>
          <Typography variant="body2">No Records Found.</Typography>
        </StyledListItem>
      )}
  </StyledList>
)

List.propTypes = {
  /**
   * List of items
   */
  list: PropTypes.array,
  /**
   * selected item
   */
  selectedItem: PropTypes.object,
  /**
   * on adornment click trigger
   */
  onAdornmentClicked: PropTypes.func,
  /**
   * on Item clicked trigger
   */
  onItemClicked: PropTypes.func,
}

export default List
