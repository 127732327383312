import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/common/form'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

const CardActionGrid = styled(Grid)`
  && {
    margin: 10px 0 0 0;
    display: flex;
    justify-content: flex-end;
  }
`

const DataGridActions = ({ actionItems }) => (
  <CardActionGrid container>
    {actionItems.map((item, index) => {
      if (item.component) {
        const CustomComp = item.action
        return <CustomComp key={index} />
      } else {
        const ButtonIcon = item.startIcon
        return (
          <Button
            label="Yes"
            key={index}
            size="small"
            variant='outlined'
            fullWidth={false}
            color="inherit"
            onClick={item.clickHandler}
            startIcon={<ButtonIcon />}
          >
            {item.label}
          </Button>
        )
      }
    })}
  </CardActionGrid>
)

DataGridActions.propTypes = {
  actionItems: PropTypes.arrayOf(PropTypes.object),
}

export default DataGridActions
