import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { Grid, Typography } from '@mui/material'

import { fetchInPersonRequestTravelers } from 'store/ducks/visitServices/inPersonRequest'
import { Button, CheckboxGroup } from 'components/common/form'
import QuickView from 'components/common/QuickView'
import EditPatientSupport from 'components/private/patientSection/patientProfile/patientSupport/EditPatientSupport'
import { displayDate } from 'components/helper/utility'
import { DataGrid } from 'components/common'
import { palette } from 'styles/baseTheme'

const TitleGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`
const ActionGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const SelectTravelers = () => {
  const { patientId } = useParams()
  const dispatch = useDispatch()
  const { travelers } = useSelector(store => store.inPersonRequest)

  const [openAddTravelerModal, setOpenAddTravelerModal] = useState(false)

  const closeAddTravelerModal = () => {
    setOpenAddTravelerModal(false)
  }

  const refreshTravelersList = () => {
    closeAddTravelerModal()
    dispatch(fetchInPersonRequestTravelers(patientId))
  }

  const headerProperties = {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    borderBottom: `1px solid ${palette.gray.main}`,
    textAlign: 'start',
    minWidth: '120px'
  }
  const selectTravelerHeaders = [
    {
      id: 'address',
      label: 'Home Address',
      isSortable: false,
      headProperties: { ...headerProperties, minWidth: '320px' },
      bodyProperties: { cellType: 'node', textAlign: 'start' }
    },
    {
      id: 'dob',
      label: 'Date of Birth',
      isSortable: false,
      headProperties: { ...headerProperties },
      bodyProperties: { cellType: 'node', textAlign: 'start' }
    },
    {
      id: 'phone',
      label: 'Phone Number',
      isSortable: false,
      headProperties: { ...headerProperties },
      bodyProperties: { cellType: 'node', textAlign: 'start' }
    },
    {
      id: 'email',
      label: 'Email Address',
      isSortable: false,
      headProperties: { ...headerProperties },
      bodyProperties: { cellType: 'node', textAlign: 'start' }
    }
  ]

  const gridTitleStyles = {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    borderBottom: `1px solid ${palette.gray.main}`,
    textAlign: 'start',
    padding: '16px 16px'
  }

  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <>
      <Grid container spacing={2}>
        {travelers.results.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CheckboxGroup
              id="selectTraveler"
              name="selectTraveler"
              matchWith="emailAddress"
              fullWidth={true}
              optionsAlignInRow={false}
              disableTypographyForOptionsLabel={true}
              disableTypographyForLabel={true}
              label={
                <Grid container spacing={2}>
                  <TitleGrid item xs={9} sm={9} md={9} lg={10.5}>
                    <Typography variant="body2">
                      Please make sure the contact information is accurate.
                    </Typography>
                  </TitleGrid>
                  <ActionGrid item xs={3} sm={3} md={3} lg={1.5}>
                    <Button
                      size="medium"
                      onClick={() => setOpenAddTravelerModal(true)}
                      color="primary"
                    >
                      Add Traveler
                    </Button>
                  </ActionGrid>
                </Grid>
              }
              options={travelers.results.map(person => ({
                ...person,
                id: person.userSupportPersonId ? person.userSupportPersonId : person.userId,
                emailAddress: person.emailAddress, // this is required for matching with selected option
                name: person.fullName, // Name is required in case of options being a react node and not a string
                displayText: (
                  <DataGrid
                    gridTitle={
                      <Typography variant="body2">
                        {person.fullName} (
                        {person.userSupportPersonType
                          ? person.userSupportPersonType?.displayText
                          : 'Patient'}
                        )
                      </Typography>
                    }
                    gridTitleProperties={gridTitleStyles}
                    headers={selectTravelerHeaders}
                    tableData={[
                      {
                        address: (
                          <Typography variant="body1">
                            {person.address1}, {person.address2}, {person.city},{' '}
                            {person.state?.displayText} {person.zipCode},{' '}
                            {person.country?.displayText}
                          </Typography>
                        ),
                        dob: displayDate(person.dateOfBirth),
                        phone: person.phoneNumber,
                        email: person.emailAddress
                      }
                    ]}
                    setRequestedSort={() => {}}
                    order={'DESC'}
                    orderBy={'createdDate'}
                    border="allSides"
                    radius={'false'}
                  />
                )
              }))}
              disabled={readOnly}
            />
          </Grid>
        )}
      </Grid>

      <QuickView
        title="Add a New Traveler"
        onClose={closeAddTravelerModal}
        contentText="Please be advised, all travelers must meet at the patient's pick-up / drop-off location."
        contentTextProps={{ variant: 'body2' }}
        dialogContent={

          <EditPatientSupport
            onSubmitPatientSupport={refreshTravelersList}
            isAddTravelerForm={true}
          />

        }
        isDialogOpen={openAddTravelerModal}
        maxWidth="xl"
        closeConfirmation={true}
      />
    </>
  )
}

export default SelectTravelers
