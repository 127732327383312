import styled from '@emotion/styled'
import { DataGrid, TextBoxOuterLabel } from 'components/common'
import { formElements } from 'components/helper/constants/common'
import { setUniqueIdentifier } from 'components/helper/utility'
import { useDispatch, useSelector } from 'react-redux'
import { onFieldChange } from 'store/ducks/configuration/cardConfiguration'
import { palette } from 'styles/baseTheme'
import PropTypes from 'prop-types'

const FieldWrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing(0, 3)}`
}))

const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  textAlign: 'start',
  minWidth: '140px'
}

const bodyProperties = {
  cellType: 'node',
  textAlign: 'start',
  minWidth: '140px',
  border: '0px',
  borderBottom: `3px inset ${palette.midnight.light}`,
  paddingLeft: '16px'
}

const CatAndCapGrid = ({ values, setFieldValue }) => {
  const dispatch = useDispatch()
  const { categoryAndCap, reimbursementCatAndCapHeaders, cardConfigurationForm } = useSelector(
    store => store.cardConfiguration
  )

  const finalHeaders = [
    {
      id: 'siteId',
      label: 'Site ID',
      headProperties: { ...headProperties },
      bodyProperties: { ...bodyProperties }
    },
    {
      id: 'localCurrency',
      label: 'Local Currency',
      headProperties: { ...headProperties },
      bodyProperties: { ...bodyProperties }
    }
  ]

  reimbursementCatAndCapHeaders.results.forEach(header => {
    finalHeaders.push({
      ...header,
      headProperties: { ...headProperties },
      bodyProperties: { ...bodyProperties }
    })
  })

  const onChange = (fieldIdentifier, value) => {
    setFieldValue(fieldIdentifier, parseFloat(value))
    // tracking for service field inside updated fields is required,
    // because the entire formik form re-renders on change of participating countries
    // and formik's dirty property cannot track the changed fields
    let isValueChanged = false
    if (cardConfigurationForm[fieldIdentifier] !== parseFloat(value)) {
      isValueChanged = true
    }

    // setField changed and check if different from original value

    dispatch(
      onFieldChange({
        fieldName: fieldIdentifier,
        fieldType: formElements.number,
        isChanged: isValueChanged
      })
    )
  }

  const updatedTableData = categoryAndCap.results.map(eachCountry => ({
    ...eachCountry,
    subTableData: eachCountry.subTableData.map(servicesObj => {
      const updatedServicesObj = {}
      Object.keys(servicesObj).forEach(serviceKey => {
        if (servicesObj[serviceKey].isService) {
          const { countryId, siteId, uniqueSiteProtocolId } = servicesObj
          const fieldIdentifier = setUniqueIdentifier({
            countryId,
            siteId,
            uniqueSiteProtocolId,
            id: servicesObj[serviceKey].id,
            displayText: serviceKey
          })
          updatedServicesObj[serviceKey] = (
            <FieldWrapper>
              <TextBoxOuterLabel
                type="number"
                id={fieldIdentifier}
                label=""
                name={fieldIdentifier}
                fullWidth={true}
                size="small"
                inputProps={{ min: 0 }}
                formik="false"
                value={values[fieldIdentifier]}
                onChange={e => {
                  onChange(fieldIdentifier, e.target.value)
                }}
              />
            </FieldWrapper>
          )
        } else {
          updatedServicesObj[serviceKey] = servicesObj[serviceKey]
        }
      })
      return updatedServicesObj
    })
  }))

  return (
    <DataGrid
      headers={finalHeaders}
      tableData={updatedTableData}
      setRequestedSort={() => {}}
      order={'DESC'}
      orderBy={'createdDate'}
      border="rowBottom"
      radius={'false'}
      styles={{ border: '2px solid #cccccc' }}
      isLoading={categoryAndCap.isLoading}
      noRowsFoundMessage="No services found for the above configurations."
    />
  )
}

CatAndCapGrid.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func
}

export default CatAndCapGrid
