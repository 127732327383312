import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'

import { fetchAssignmentsPerPolicy, updateAssignmentsPerPolicy } from 'store/ducks/smartAlerts'
import { AutoCompleteWithLabel, Button } from 'components/common'

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(1)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: start;
      justify-content: flex-start;
    `
)

const AssignmentsForm = () => {
  const dispatch = useDispatch()
  const { assignmentsPerPolicyInitialValues, assignmentsPerPolicyInformation } = useSelector(
    store => store.smartAlerts
  )

  const [updatedAssignmentIds, setUpdatedAssignmentIds] = useState([])

  useEffect(() => {
    dispatch(fetchAssignmentsPerPolicy())
  }, [])

  const recordChange = name => {
    setUpdatedAssignmentIds(prevValues => {
      prevValues.push(name)
      return [...new Set(prevValues)]
    })
  }

  const handleAssignmentChange = (selectedValue, reason, setFieldValue, name, options) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValue.find(option => option.id === 'select-all')) {
        setFieldValue(name, options)
      } else {
        setFieldValue(name, selectedValue)
      }
    } else if (reason === 'clear') {
      setFieldValue(name, [])
    }
    recordChange(name)
  }

  return (
    Object.keys(assignmentsPerPolicyInitialValues).length > 0 && (
      <Formik
        initialValues={assignmentsPerPolicyInitialValues}
        enableReinitialize={true}
        onSubmit={values => {
          dispatch(updateAssignmentsPerPolicy(values, updatedAssignmentIds))
        }}
      >
        {({ setFieldValue }) => (
          <Form noValidate>
            <Grid container spacing={2}>

              {Object.keys(assignmentsPerPolicyInformation).map(assignment => {
                const info = assignmentsPerPolicyInformation[assignment]
                const name = info.defaultActionRoleAssignmentId.toString()
                const label = info.actionRole
                const fullUsersList = info.availableUsersList
                return (
                  <Grid item xs={12} sm={6} md={6} lg={6} key={name}>
                    <AutoCompleteWithLabel
                      multiple={true}
                      hasSelectAllOption={true}
                      size="small"
                      id={name}
                      label={label}
                      name={name}
                      options={fullUsersList}
                      key={name}
                      onChange={(event, selectedValue, reason) =>
                        handleAssignmentChange(
                          selectedValue,
                          reason,
                          setFieldValue,
                          name,
                          fullUsersList
                        )
                      }
                      limitTags={10}
                    />
                  </Grid>
                )
              })}
              <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                <Button size="large" fullWidth={false} color="primary" type="submit">
                  Save
                </Button>
              </ActionWrapper>
            </Grid>
          </Form>
        )}
      </Formik>
    )
  )
}

export default AssignmentsForm
