/* eslint-disable indent */
import { createReducer, createAction } from '@reduxjs/toolkit'
import { fetchEnrollmentCodesLookup, showError, showSuccess } from '../application'
import { setLoading } from '../userInteractions'
import {
  displayDate,
  copyObject,
  shallowEqual,
  formInitialValues,
  getSponsorSiteIdFromSiteName
} from 'components/helper/utility'

import { fetchParticipationStatusLookup } from 'store/ducks/application'
import {
  getPatientParticipationInformation,
  getParticipationProgressLeadSheet,
  getSitePrescreenerQuestions,
  getConciergePrescreenerQuestions,
  saveAnswerPrescreenerQuestion,
  addSitePrescreenerQuestion,
  addConciergePrescreenerQuestion,
  saveParticipationProgressDetails,
  getParticipationProgressHistory
} from 'services/patientRecord/patientParticipation'
import { getParticipationProgressNotes, addParticipationProgressNotes } from 'services/note'
import { formElements } from 'components/helper/constants/common'
import {
  participationProgressOptions,
  questionType
} from 'components/helper/constants/participationProgress'
import { fetchBadgesForPatientSection } from './patientMainWrapper'

export const setParticipationProgressDetails = createAction(
  'participationProgress/setParticipationProgressDetails'
)
export const setParticipationProgressHistory = createAction(
  'participationProgress/setParticipationProgressHistory'
)
export const setSitePrescreenerQuestionAnswers = createAction(
  'participationProgress/setSitePrescreenerQuestionAnswers'
)
export const resetParticipationProgress = createAction(
  'participationProgress/resetParticipationProgress'
)
export const setConciergePrescreenerQuestionAnswers = createAction(
  'participationProgress/setConciergePrescreenerQuestionAnswers'
)
export const setParticipationProgressNotes = createAction(
  'participationProgress/setParticipationProgressNotes'
)
export const setNoteSaved = createAction('participationProgress/setNoteSaved')
export const setSubjectIdUpdateModal = createAction('participationProgress/setSubjectIdUpdateModal')
export const setPatientSurveyQnA = createAction('participationProgress/setPatientSurveyQnA')
export const setConciergePreScreenerQnA = createAction(
  'participationProgress/setConciergePreScreenerQnA'
)
export const setSitePreScreenerQnA = createAction('participationProgress/setSitePreScreenerQnA')
export const setConciergeQnADisabled = createAction('participationProgress/setConciergeQnADisabled')
export const setSiteQnADisabled = createAction('participationProgress/setSiteQnADisabled')
export const setShowAddQuestionFormFor = createAction(
  'participationProgress/setShowAddQuestionFormFor'
)

export const fetchParticipationProgressDetails =
  (id, invalidateCache = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      await dispatch(fetchParticipationStatusLookup())
      const { participationStatusLookup } = getState().application

      const { data } = await getPatientParticipationInformation(id, invalidateCache)
      /**
       * The preconsentStatus might not have all properties - isSubjectId, isAdditionalNote, etc
       * So we match the preconsentStatus with participationStatusLookup, to get all properties
       * isSubjectId is needed to save SubjectId in case of SubjectIdisText scenario
       */
      let currentPreconsentStatusLookupValue = null
      if (data.preconsentStatus) {
        currentPreconsentStatusLookupValue = participationStatusLookup.results.find(
          status => status.id === data.preconsentStatus?.id
        )
      }

      // Subject ID should be Open Text when participationProgressDetails.isIVRIntegration is FALSE
      const subjectIDIsOpenText = !data.isIVRIntegration

      const formattedParticipationProgressDetails = {
        ...data,
        systemIdCreatedDate: displayDate(data.systemIdCreatedDate),
        preconsentStatus: currentPreconsentStatusLookupValue,
        postconsentStatus: data.postconsentStatus ? data.postconsentStatus : '',
        statusReason: data.statusReason ? data.statusReason : '',
        referralSource: data.referralSource ? data.referralSource : '',
        systemId: data.systemId || '',
        ...(subjectIDIsOpenText && { subject: data.subject?.displayText || '' })
      }

      /**
       * When subjectIDIsOpenText (Patient Data Integration is false)
       * If, postconsentStatus is present, Show postconsentStatus as the ParticipationProgress Status
       */
      if (subjectIDIsOpenText && !!formattedParticipationProgressDetails.postconsentStatus) {
        const participationProgressStatusArr = participationStatusLookup.results.filter(
          option => option.displayText === formattedParticipationProgressDetails.postconsentStatus
        )
        if (participationProgressStatusArr.length) {
          formattedParticipationProgressDetails.preconsentStatus = participationProgressStatusArr[0]
        }
      }

      dispatch(setParticipationProgressDetails(formattedParticipationProgressDetails))

      // when subjectID is null and preconsentStatus is consented, show modal to update subject ID
      if (!data.subject && data.isSubjectIdRequired) {
        dispatch(setSubjectIdUpdateModal(true))
      }
    } catch {
      dispatch(
        showError(
          'There was some issue while trying to fetch Participation Progress Details. Please try again.'
        )
      )
    }

    dispatch(setLoading(false))
  }

export const fetchParticipationProgressHistory = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { patientInformation } = getState().patientMainWrapper
    const { data } = await getParticipationProgressHistory(patientInformation.userId)
    dispatch(setParticipationProgressHistory(data))
  } catch (e) {
    dispatch(
      showError(
        'There was some issue while trying to fetch Participation Progress History. Please try again.'
      )
    )
  } finally {
    dispatch(setLoading(false))
  }
}

// eslint-disable-next-line complexity
export const saveParticipationProgressInfo =
  (data, id, fetchNotesAndHistory = true, disassociateSubjectId = false) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { participationProgressDetails } = getState().participationProgress
      const previousSite = participationProgressDetails.site
      const previousSubject = participationProgressDetails.subject

      const participationProgressDetailsData = {
        userId: id,
        preconsentStatusId: data.preconsentStatus.id,
        sponsorSiteId: getSponsorSiteIdFromSiteName(participationProgressDetails.site?.displayText),
        uniqueSiteProtocolId: data.site ? data.site.alternateIdentifier : null,
        appointmentDate:
          data.preconsentStatus?.id === participationProgressOptions.appointmentScheduled &&
          data.appointmentDate
            ? data.appointmentDate
            : null
      }

      if (data.isIVRIntegration) {
        // -- Subject ID is a dropdown --
        participationProgressDetailsData.subjectId = data.subject ? data.subject.id : null
        participationProgressDetailsData.subjectNumber = data.subject
          ? data.subject.displayText
          : null
      } else {
        // -- Subject ID is a Open text --
        /**
         * if existing Subject, then use the existing subjectId
         * but send the updated Subject Text to Update
         */
        if (participationProgressDetails.subject) {
          participationProgressDetailsData.subjectId = participationProgressDetails.subject.id
        } else {
          participationProgressDetailsData.subjectId = null
        }
        participationProgressDetailsData.subjectNumber = data.subject
      }

      let subjectIDNote
      if (!shallowEqual(previousSubject, data.subject)) {
        if (data.subject !== null) {
          // if new Subject is not null
          const subject = data.isIVRIntegration ? data.subject.displayText : data.subject
          subjectIDNote = `Subject ID ${subject} associated`
        } else {
          // if new Subject is null
          let previousSubjectValue
          if (typeof previousSubject === 'object') {
            previousSubjectValue = previousSubject?.displayText
          } else if (typeof previousSubject === 'string') {
            previousSubjectValue = previousSubject
          }
          subjectIDNote = `Subject ID ${previousSubjectValue} removed`
        }
      }

      let siteIdNote
      if (!shallowEqual(previousSite, data.site)) {
        siteIdNote = `(Site ID changed from ${previousSite?.displayText} to ${data.site.displayText})`
      }

      const res = await saveParticipationProgressDetails(participationProgressDetailsData)
      /**
       *  Update this state before any other api calls,
       *  because in other api calls isLoading might turn to false before EditParticipation form gets the latest data
       */
      await dispatch(
        setParticipationProgressDetails({
          ...data,
          appointmentDate: displayDate(data.appointmentDate),
          statusReason: ''
        })
      )
      let finalNote

      if (data.preconsentStatus.id === participationProgressOptions.appointmentScheduled) {
        finalNote = `<b>Status: ${data.preconsentStatus.displayText}</b> for ${displayDate(
          data.appointmentDate
        )}${data.statusReason ? `: ${data.statusReason}` : ''}
          ${siteIdNote ? `${siteIdNote}` : ''}${subjectIDNote ? `: ${subjectIDNote}` : ''}`
      } else {
        finalNote = `<b>Status: ${data.preconsentStatus.displayText}</b>${
          data.statusReason ? `: ${data.statusReason}` : ''
        }
          ${siteIdNote ? `${siteIdNote}` : ''}${subjectIDNote ? `: ${subjectIDNote}` : ''}`
      }

      const notesData = {
        patientId: id,
        note: finalNote,
        isSiteAssociated: !!previousSite?.id
      }
      await addParticipationProgressNotes(notesData)
      await dispatch(fetchParticipationProgressNotes(id))
      await dispatch(setSubjectIdUpdateModal(false))
      await dispatch(fetchBadgesForPatientSection(id))
      // This check because there is no need to fetch these items when saving from subjectID pop-up
      if (fetchNotesAndHistory) {
        await dispatch(fetchParticipationProgressHistory(id))
      }
      if (disassociateSubjectId) {
        dispatch(showSuccess('Disassociated Subject ID.'))
      } else {
        dispatch(showSuccess('Participation Progress details saved successfully!'))
      }

      if ((disassociateSubjectId && res) || !data.isIVRIntegration) {
        // Invalidate cache to show updated values
        await dispatch(fetchParticipationProgressDetails(id, true))
        if (participationProgressDetails.site?.alternateIdentifier) {
          await dispatch(
            fetchEnrollmentCodesLookup(participationProgressDetails.site.alternateIdentifier)
          )
        }
      }
    } catch (e) {
      dispatch(
        showError(
          'Unable to save Participation Progress Details at this time. Please try again.',
          e
        )
      )
    }
    dispatch(setLoading(false))
  }

export const saveParticipationProgressNote =
  (note, patientId, isSiteAssociated) => async dispatch => {
    try {
      dispatch(setLoading(true))
      const notesData = {
        patientId,
        note,
        isSiteAssociated
      }
      await addParticipationProgressNotes(notesData)
      await dispatch(fetchParticipationProgressNotes(patientId))
      dispatch(showSuccess('Note saved successfully!'))
    } catch (e) {
      dispatch(
        showError('Unable to save Participation Progress Note at this time. Please try again.')
      )
    } finally {
      dispatch(setLoading(false))
    }
  }

export const fetchAllParticipationInfo = userId => async dispatch => {
  dispatch(setPatientSurveyQnA({ isLoading: true }))
  dispatch(setSitePreScreenerQnA({ isLoading: true }))
  dispatch(setConciergePreScreenerQnA({ isLoading: true }))
  await dispatch(fetchPatientSurveyQnA(userId))
  await dispatch(fetchSitePrescreenerQuestionAnswers(userId))
  await dispatch(fetchConciergePrescreenerQuestionAnswers(userId))
}

export const fetchPatientSurveyQnA = userId => async dispatch => {
  try {
    dispatch(setPatientSurveyQnA({ isLoading: true }))
    const { data } = await getParticipationProgressLeadSheet(userId)

    if (data && data.length > 0) {
      const studyInfoInitialValues = {}
      const surveyInitialValues = {}
      const studyInfoFieldConfig = []
      const surveyFieldConfig = []

      data
        .forEach(r => {
          if (r.QuestionCategoryTypeID === 1 || r.QuestionNo === 'DateofSurvey') {
            if (r.QuestionNo === 'DateofSurvey') {
              studyInfoInitialValues.completedDate = formInitialValues({
                answerType: formElements.openText,
                // this regular expression is looking for a time in the format of HH:MM[AP]M or HH:MM[AP]M (e.g., 9:30AM, 11:45 PM, etc.)
                // at the end of the string and replacing it with an empty string, effectively removing the time from the data string.
                response: displayDate(r.Answer?.trim().replace(/\s{2,}/g, ' ').replace(/\s+\d+:\d+[AP]M?$/, ''), 'dd MMM yyyy', 'MMM dd yyyy')
              })
              studyInfoFieldConfig.push({
                id: 'completedDate',
                answerType: formElements.openText,
                question: 'Completed Date',
                isResponseRequired: false,
                disabled: true
              })
            } else {
              studyInfoInitialValues[`${r.QuestionNo}`] = formInitialValues({
                answerType: formElements.openText,
                response: r.IsOpenText ? r.Answer : displayDate(r.Answer?.trim().replace(/\s{2,}/g, ' ').replace(/\s+\d+:\d+[AP]M?$/, ''), 'dd MMM yyyy', 'MMM dd yyyy')
              })
              studyInfoFieldConfig.push({
                id: r.QuestionNo,
                answerType: formElements.openText,
                question: r.Question,
                isResponseRequired: false,
                disabled: true
              })
            }
          }

          if (r.QuestionCategoryTypeID !== 1 || r.QuestionNo === 'DateofSurvey') {
            surveyInitialValues[`${r.QuestionNo}`] = formInitialValues({
              answerType: formElements.openText,
              response: r.IsOpenText ? r.Answer : displayDate(r.Answer?.trim().replace(/\s{2,}/g, ' ').replace(/\s+\d+:\d+[AP]M?$/, ''), 'dd MMM yyyy', 'MMM dd yyyy')
            })
            surveyFieldConfig.push({
              id: r.QuestionNo,
              answerType: formElements.openText,
              question: r.Question,
              isResponseRequired: false,
              disabled: true
            })
          }
        })

      const finalResponse = {
        studyInfoInitialValues,
        surveyInitialValues,
        studyInfoFieldConfig,
        surveyFieldConfig,
        isLoading: false
      }
      dispatch(setPatientSurveyQnA(finalResponse))
    }
  } catch (e) {
    dispatch(
      showError(
        'There was some issue while trying to fetch Patient Survey QnA. Please try again.',
        e
      )
    )
    dispatch(setPatientSurveyQnA({ isLoading: false }))
  }
}

export const fetchSitePrescreenerQuestionAnswers = userId => async dispatch => {
  try {
    dispatch(setSitePreScreenerQnA({ isLoading: true }))
    const response = await getSitePrescreenerQuestions(userId)

    if (response && response.length > 0) {
      const initialValues = {}

      const fieldsConfig = response.map(r => {
        initialValues[`${r.prescreenerQuestionId}`] = formInitialValues({
          answerType: formElements.openText,
          response: r.answer
        })
        return {
          id: r.prescreenerQuestionId,
          answerType: formElements.openText,
          question: r.question,
          isResponseRequired: false
        }
      })

      const finalResponse = {
        // keeping the actual response, needed while saving answers
        fetchResponse: response,
        initialValues,
        fieldsConfig,
        isLoading: false
      }
      dispatch(setSitePreScreenerQnA(finalResponse))
    } else {
      dispatch(setSitePreScreenerQnA({ isLoading: false }))
    }
  } catch (e) {
    dispatch(setSitePreScreenerQnA({ isLoading: false }))
    dispatch(
      showError(
        'There was some issue while trying to fetch Site Prescreener Questions and Answers. Please try again.',
        e
      )
    )
  }
}

export const fetchConciergePrescreenerQuestionAnswers = userId => async dispatch => {
  try {
    dispatch(setConciergePreScreenerQnA({ isLoading: true }))
    const response = await getConciergePrescreenerQuestions(userId)

    if (response && response.length > 0) {
      const initialValues = {}

      const fieldsConfig = response.map(r => {
        initialValues[`${r.prescreenerQuestionId}`] = formInitialValues({
          answerType: formElements.openText,
          response: r.answer
        })
        return {
          id: r.prescreenerQuestionId,
          answerType: formElements.openText,
          question: r.question,
          isResponseRequired: false
        }
      })

      const finalResponse = {
        // keeping the actual response, needed while saving answers
        fetchResponse: response,
        initialValues,
        fieldsConfig,
        isLoading: false
      }
      dispatch(setConciergePreScreenerQnA(finalResponse))
    } else {
      dispatch(setConciergePreScreenerQnA({ isLoading: false }))
    }
  } catch (e) {
    dispatch(setConciergePreScreenerQnA({ isLoading: false }))
    dispatch(
      showError(
        'There was some issue while trying to fetch Site Prescreener Questions. Please try again.',
        e
      )
    )
  }
}

export const fetchParticipationProgressNotes = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { patientInformation } = getState().patientMainWrapper
    const { data } = await getParticipationProgressNotes(patientInformation.userId)
    const participationProgressNotes = data.map(item => ({
      ...item,
      createdDate: displayDate(item.createdDate)
    }))
    dispatch(setParticipationProgressNotes(participationProgressNotes))
  } catch (e) {
    dispatch(
      showError(
        'There was some issue while trying to fetch Participation Progress Notes. Please try again.',
        e
      )
    )
  } finally {
    dispatch(setLoading(false))
  }
}

export const saveConciergePreScreenerAnswers = values => async (dispatch, getState) => {
  dispatch(setConciergePreScreenerQnA({ isLoading: true }))

  try {
    const { patientInformation } = getState().patientMainWrapper
    const { conciergePreScreenerQnA } = getState().participationProgress

    // conciergePreScreenerQnA.fetchResponse is the fetchQnA Response
    // Its definition matches the payload definition for save answers API.
    // Hence we use the same and update new answers
    const saveAnswersPayload = conciergePreScreenerQnA.fetchResponse.map(question => ({
      ...question,
      answer: values[question.prescreenerQuestionId.toString()]
    }))

    await saveAnswerPrescreenerQuestion({
      userId: patientInformation.userId,
      questions: saveAnswersPayload
    })

    await dispatch(fetchConciergePrescreenerQuestionAnswers(patientInformation.userId))
    dispatch(setConciergeQnADisabled(true))
    dispatch(showSuccess('Answer saved successfully!'))
  } catch (e) {
    dispatch(setConciergePreScreenerQnA({ isLoading: false }))
    dispatch(showError('Not able to save answer(s). Please try again.', e))
  }
  dispatch(setConciergePreScreenerQnA({ isLoading: false }))
}

export const saveSitePreScreenerAnswers = values => async (dispatch, getState) => {
  dispatch(setSitePreScreenerQnA({ isLoading: true }))
  try {
    const { patientInformation } = getState().patientMainWrapper
    const { sitePreScreenerQnA } = getState().participationProgress

    // conciergePreScreenerQnA.fetchResponse is the fetchQnA Response
    // Its definition matches the payload definition for same.
    // Hence we use the same and update new answers
    const saveAnswersPayload = sitePreScreenerQnA.fetchResponse.map(question => ({
      ...question,
      answer: values[question.prescreenerQuestionId.toString()]
    }))

    await saveAnswerPrescreenerQuestion({
      userId: patientInformation.userId,
      questions: saveAnswersPayload
    })

    await dispatch(fetchSitePrescreenerQuestionAnswers(patientInformation.userId))
    dispatch(setSiteQnADisabled(true))
    dispatch(showSuccess('Answer saved successfully!'))
  } catch (e) {
    dispatch(setSitePreScreenerQnA({ isLoading: false }))
    dispatch(showError('Not able to save answer(s). Please try again.', e))
  }
  dispatch(setSitePreScreenerQnA({ isLoading: false }))
}

export const savePrescreenQuestion = (data, type) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { userId } = getState().patientMainWrapper.patientInformation
    const { site } = getState().participationProgress.participationProgressDetails

    if (site) {
      const payload = {
        question: data.question,
        uniqueSiteProtocolId: site.alternateIdentifier
      }

      if (type === questionType.site) {
        await addSitePrescreenerQuestion(payload)
        await dispatch(fetchSitePrescreenerQuestionAnswers(userId))
      } else if (type === questionType.concierge) {
        await addConciergePrescreenerQuestion(payload)
        await dispatch(fetchConciergePrescreenerQuestionAnswers(userId))
      }
      dispatch(setShowAddQuestionFormFor(''))

      dispatch(showSuccess('Question saved successfully!'))
    } else {
      // We are confirming this scenario with Dena
      dispatch(showError('No site assocated with user!'))
    }
  } catch (e) {
    dispatch(showError('Unable to save Prescreening Question at this time. Please try again.', e))
  }
  dispatch(setLoading(false))
}

const initialState = {
  participationProgressDetails: {
    subject: '',
    systemIdCreatedDate: '',
    preConsentedThroughConsented: null,
    referralSource: { displayText: '', id: '' },
    preconsentStatus: null,
    site: null
  },
  participationProgressHistory: [],
  sitePrescreenerQuestionAnswers: [],
  conciergePrescreenerQuestionAnswers: [],
  participationProgressNotes: [],
  noteSaved: false,
  subjectIdUpdateModal: false,
  patientSurveyQnA: {
    initialValues: {},
    fieldsConfig: [],
    isLoading: false
  },
  conciergePreScreenerQnA: {
    fetchResponse: [],
    initialValues: {},
    fieldsConfig: [],
    isLoading: false
  },
  sitePreScreenerQnA: {
    fetchResponse: [],
    initialValues: {},
    fieldsConfig: [],
    isLoading: false
  },
  isConciergeQnADisabled: true,
  isSiteQnADisabled: true,
  showAddQuestionFormFor: ''
}
export default createReducer(initialState, builder => {
  builder
    .addCase(setParticipationProgressDetails, (state, action) => {
      state.participationProgressDetails = action.payload
    })
    .addCase(setParticipationProgressHistory, (state, action) => {
      state.participationProgressHistory = action.payload
    })
    .addCase(setSitePrescreenerQuestionAnswers, (state, action) => {
      state.sitePrescreenerQuestionAnswers = action.payload
    })
    .addCase(setConciergePrescreenerQuestionAnswers, (state, action) => {
      state.conciergePrescreenerQuestionAnswers = action.payload
    })
    .addCase(setParticipationProgressNotes, (state, action) => {
      state.participationProgressNotes = action.payload
    })
    .addCase(setNoteSaved, (state, action) => {
      state.noteSaved = action.payload
    })
    .addCase(setSubjectIdUpdateModal, (state, action) => {
      state.subjectIdUpdateModal = action.payload
    })
    .addCase(setPatientSurveyQnA, (state, action) => {
      state.patientSurveyQnA = { ...state.patientSurveyQnA, ...action.payload }
    })
    .addCase(setConciergePreScreenerQnA, (state, action) => {
      state.conciergePreScreenerQnA = { ...state.conciergePreScreenerQnA, ...action.payload }
    })
    .addCase(setSitePreScreenerQnA, (state, action) => {
      state.sitePreScreenerQnA = { ...state.sitePreScreenerQnA, ...action.payload }
    })
    .addCase(setConciergeQnADisabled, (state, action) => {
      state.isConciergeQnADisabled = action.payload
    })
    .addCase(setSiteQnADisabled, (state, action) => {
      state.isSiteQnADisabled = action.payload
    })
    .addCase(setShowAddQuestionFormFor, (state, action) => {
      state.showAddQuestionFormFor = action.payload
    })
    .addCase(resetParticipationProgress, state => {
      copyObject(state, initialState)
    })
})
