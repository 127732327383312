import React from 'react'
import PropTypes from 'prop-types'

import { TextBoxOuterLabel, DatePickerWithLabel, CustomRadioGroup } from 'components/common'
import { DataPointType } from 'components/helper/constants/siteManagement'

const DataPointAnswerControl = ({ dataPoint }) => {
  const { dataPointType, dataPointId, isManual } = dataPoint

  switch (dataPointType) {
    case DataPointType.Number:
      return (
        <TextBoxOuterLabel
          id={dataPointId.toString()}
          name={dataPointId.toString()}
          label=""
          size="small"
          type="number"
          fullWidth={true}
          disabled={!isManual}
        />
      )

    case DataPointType.Text:
      return (
        <TextBoxOuterLabel
          id={dataPointId.toString()}
          name={dataPointId.toString()}
          label=""
          size="small"
          type="text"
          fullWidth={true}
          disabled={!isManual}
        />
      )

    case DataPointType.Date:
      return (
        <DatePickerWithLabel
          id={dataPointId.toString()}
          name={dataPointId.toString()}
          label=""
          size="small"
          fullWidth={true}
          disabled={!isManual}
        />
      )

    case DataPointType.YesOrNo:
      // return <Switch id={dataPointId.toString()} name={dataPointId.toString()} label="" disabled={!isManual} />
      return (
        <CustomRadioGroup
          id={dataPointId.toString()}
          label=""
          name={dataPointId.toString()}
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
          ]}
          row={true}
          disabled={!isManual}
          orientation="column"
        />
      )

    default:
      return <div>No Component</div>
  }
}

DataPointAnswerControl.propTypes = {
  dataPoint: PropTypes.shape({
    module: PropTypes.string,
    dataPointId: PropTypes.number,
    dataPointType: PropTypes.string,
    dataPoint: PropTypes.string,
    isManual: PropTypes.bool,
    answer: PropTypes.string
  })
}

export default DataPointAnswerControl
