import { createAction, createReducer } from '@reduxjs/toolkit'
import { saveTCNUserDetails, getTCNUserDetails, submitUpdateEmail } from 'services/settings'
import { showError, showSuccess } from './application'
import { setLoading } from './userInteractions'
import { copyObject } from 'components/helper/utility'

export const setIsReadOnly = createAction('myAccount/setIsReadOnly')
export const setRolesLookup = createAction('myAccount/setRolesLookup')
export const setMyInformation = createAction('myAccount/setMyInformation')
export const setEmailUpdateModal = createAction('myAccount/setEmailUpdateModal')
export const resetMyAccount = createAction('myAccount/resetMyAccount')

export const fetchMyInformation = () => async dispatch => {
  dispatch(setLoading(true))

  try {
    const { rolesLookup, ...myInformation } = await getTCNUserDetails()

    dispatch(setRolesLookup(rolesLookup))
    dispatch(
      setMyInformation({
        ...myInformation,
        mobilePhone: myInformation.mobilePhone || '',
        businessPhone: myInformation.businessPhone || ''
      })
    )
  } catch {
    dispatch(showError('There was some error while trying to fetch TCN Users'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateMyInformation = data => async dispatch => {
  dispatch(setLoading(true))
  const updatedInfo = {
    emailAddress: data.emailAddress,
    firstName: data.firstName,
    lastName: data.lastName,
    businessPhone: data.businessPhone,
    mobilePhone: data.mobilePhone,
    luRoleId: +data.role?.id
  }

  try {
    await saveTCNUserDetails(updatedInfo)
    dispatch(fetchMyInformation())
    dispatch(setIsReadOnly(true))
    dispatch(showSuccess('Information was updated successfully!'))
  } catch {
    dispatch(showError('There was some issue while updating information'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateEmail = values => async dispatch => {
  dispatch(setLoading(true))
  try {
    await submitUpdateEmail(values)
    dispatch(setEmailUpdateModal(false))
    dispatch(showSuccess('Email Address change request has been sent.'))
  } catch (e) {
    dispatch(showError('There was issue while trying to send the email update request. Please try again.', e))
  }
  dispatch(setLoading(false))
}
const initialState = {
  myInformation: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    businessPhone: '',
    mobilePhone: '',
    role: null
  },
  rolesLookup: [],
  isReadOnly: true,
  emailUpdateModal: false
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setIsReadOnly, (state, action) => {
      state.isReadOnly = action.payload
    })
    .addCase(setRolesLookup, (state, action) => {
      state.rolesLookup = action.payload
    })
    .addCase(setMyInformation, (state, action) => {
      state.myInformation = action.payload
    })
    .addCase(setEmailUpdateModal, (state, action) => {
      state.emailUpdateModal = action.payload
    }).addCase(resetMyAccount, state => {
      copyObject(state, initialState)
    })
})
