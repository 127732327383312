import { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { palette } from 'styles/baseTheme'
import { Button, AutoCompleteWithLabel, PageHeader, DataGrid, Checkbox } from 'components/common'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import {
  setShowAssignmentForm,
  fetchBBKActionRoles,
  fetchModulesForTriggers,
  fetchPolicies,
  fetchExistingEmailTriggers,
  fetchAssignmentsPerPolicy,
  updateExistingEmailTriggers,
  setUpdatedTriggers,
  setUpdatedUsers,
  setSelectedModule,
  setSelectedPolicies,
  setSelectedBBKActionRoles,
  resetSmartAlert
} from 'store/ducks/smartAlerts'
import QuickView from 'components/common/QuickView'
import AssignmentsForm from './AssignmentsForm'
import * as constants from 'components/helper/constants/smartAlerts'

const StyledGrid = styled(Grid)(
  ({ theme }) => ` 
    margin-bottom: ${theme.spacing(2)};
  `
)
const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(2)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: left;
      justify-content: left;
      padding: ${theme.spacing(4)} 0;
  `
)

const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  textAlign: 'center',
  minWidth: '260px'
}
const bodyProperties = {
  textAlign: 'center'
}

const SmartAlerts = () => {
  const dispatch = useDispatch()

  const {
    showAssignmentForm,
    modulesForTriggers,
    policies,
    bbkActionRoles,
    modulesAssignmentsPerPolicy,
    gridHeaders,
    gridRows,
    initialValues,
    selectedModule,
    selectedPolicies,
    selectedBBKActionRoles,
    initialValuesUpdateCounter,
    updatedTriggers,
    updatedUsers
  } = useSelector(store => store.smartAlerts)

  const [showActionRolesDropdown, setShowActionRolesDropdown] = useState(false)

  const updatedGridHeader = gridHeaders.map(header => {
    if (header.type === 'module') {
      return {
        ...header,
        headProperties: { ...headProperties, textAlign: 'left', width: '500px' },
        bodyProperties: { textAlign: 'left' }
      }
    } else {
      return { ...header, headProperties, bodyProperties: { ...bodyProperties, cellType: 'node' } }
    }
  })
  const formikRef = useRef(null)

  useEffect(() => {
    dispatch(fetchModulesForTriggers())
    dispatch(fetchPolicies())
    dispatch(fetchBBKActionRoles())
    dispatch(fetchAssignmentsPerPolicy())

    return () => {
      dispatch(resetSmartAlert())
    }
  }, [])

  // When dropdown values change, fetch Existing Triggers
  useEffect(() => {
    if (selectedModule || selectedBBKActionRoles.length) {
      dispatch(fetchExistingEmailTriggers())
      // reset the updated values
      dispatch(setUpdatedTriggers({}))
      dispatch(setUpdatedUsers({}))
    }
  }, [selectedModule, selectedPolicies, selectedBBKActionRoles])

  const handleModuleChange = (selectedValue, _reason) => {
    dispatch(setSelectedModule(selectedValue))

    // Reset dropdowns of Policies and Action Roles
    dispatch(setUpdatedTriggers({}))
    dispatch(setUpdatedUsers({}))
  }

  const handlePoliciesChange = (selectedValue, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValue.find(option => option.id === 'select-all')) {
        dispatch(setSelectedPolicies(policies.results))
      } else {
        dispatch(setSelectedPolicies(selectedValue))
      }
    } else if (reason === 'clear') {
      dispatch(setSelectedPolicies([]))
    }

    if (
      selectedValue.map(policy => policy.displayText).includes('BBK') ||
      selectedValue.map(policy => policy.displayText).includes('Select All')
    ) {
      setShowActionRolesDropdown(true)
    } else {
      setShowActionRolesDropdown(false)
      dispatch(setSelectedBBKActionRoles([]))
    }
  }

  const handleBBKActionRolesChange = (selectedValue, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValue.find(option => option.id === 'select-all')) {
        dispatch(setSelectedBBKActionRoles(bbkActionRoles.results))
      } else {
        dispatch(setSelectedBBKActionRoles(selectedValue))
      }
    } else if (reason === 'clear') {
      dispatch(setSelectedBBKActionRoles([]))
    }
  }

  const onCheckboxChange = (event, name) => {
    formikRef.current.setFieldValue(name, event.target.checked)
    const [luCommunicationEmailTriggerId, policyActionId] = name.split('.')
    // Record Ids which are updated

    const newUpdatedTriggers = { ...updatedTriggers }
    if (!newUpdatedTriggers[luCommunicationEmailTriggerId]) {
      newUpdatedTriggers[luCommunicationEmailTriggerId] = {}
    }
    // Make new copy of object
    newUpdatedTriggers[luCommunicationEmailTriggerId] = {
      ...newUpdatedTriggers[luCommunicationEmailTriggerId],
      [policyActionId]: event.target.checked
    }

    dispatch(setUpdatedTriggers(newUpdatedTriggers))

    // when a checkbox is updated, update the Users too for that column.
    // We need to send users too for that column. Required by API
    const [policyId] = policyActionId.split('-')
    if (constants.policiesNeedUsersDropdownIds.includes(parseInt(policyId))) {
      const newUpdatedUsers = {
        ...updatedUsers,
        [policyActionId]: formikRef.current.values[policyActionId]
      }
      dispatch(setUpdatedUsers(newUpdatedUsers))
    }
  }

  const handleUsersChange = (selectedValue, reason, name) => {
    let newSelectedValue = []

    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValue.find(option => option.id === 'select-all')) {
        newSelectedValue = modulesAssignmentsPerPolicy[name]?.availableUsersList
      } else {
        newSelectedValue = selectedValue
      }
    } else if (reason === 'clear') {
      newSelectedValue = []
    }
    formikRef.current.setFieldValue(name, newSelectedValue)
    const newUpdatedUsers = { ...updatedUsers }
    const policyActionId = name
    newUpdatedUsers[policyActionId] = newSelectedValue
    dispatch(setUpdatedUsers(newUpdatedUsers))
  }

  // Hydrate the rows with Components
  const addComponents = gridRows => {
    const newGridRows = gridRows.map(row => {
      const newRow = {}
      Object.keys(row).forEach(elementId => {
        const [componentType, name] = row[elementId].split(':')
        if (componentType === 'AutoComplete') {
          newRow[elementId] = (
            <AutoCompleteWithLabel
              multiple={true}
              hasSelectAllOption={true}
              size="small"
              id={name}
              label=""
              name={name}
              options={modulesAssignmentsPerPolicy[name]?.availableUsersList || []}
              key={`${Object.keys(modulesAssignmentsPerPolicy).length}-${name}`}
              onChange={(event, selectedValue, reason) =>
                handleUsersChange(selectedValue, reason, name)
              }
              dataTestId={name}
            />
          )
        } else if (componentType === 'checkbox') {
          newRow[elementId] = (
            <Checkbox
              key={name}
              name={name}
              id={name}
              onChange={e => onCheckboxChange(e, name)}
              label=""
              size="medium"
              dataTestId={name}
            />
          )
        } else {
          newRow[elementId] = row[elementId]
        }
      })
      return newRow
    })
    return newGridRows
  }

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Smart Alerts</Typography>}
        actionContent={
          <Grid container spacing={2}>
            <Grid item>
              <Button
                size="large"
                fullWidth={false}
                color="primary"
                onClick={() => dispatch(setShowAssignmentForm(true))}
              >
                Assignments per Policy
              </Button>
            </Grid>
          </Grid>
        }
      />
      {modulesForTriggers.isLoading || policies.isLoading || bbkActionRoles.isLoading
        ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <Skeleton height={64} />
                <Skeleton height={64} />
                <Skeleton height={64} />
              </Box>
            </Grid>
          </Grid>
        )
        : (
          <StyledGrid container spacing={2}>
            <Grid item xs={12} sm={9} md={7} lg={5}>
              <AutoCompleteWithLabel
                multiple={false}
                hasSelectAllOption={false}
                size="small"
                id="selectModules"
                label="Select Module"
                name="selectModules"
                options={modulesForTriggers.results}
                formik="false"
                required
                onChange={(event, selectedValue, reason) => handleModuleChange(selectedValue, reason)}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={5} lg={7}></Grid>
            <Grid item xs={12} sm={9} md={7} lg={5}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="small"
                id="selectPolicies"
                label="Select Policies"
                name="selectPolicies"
                formik="false"
                selectedValue={selectedPolicies}
                options={policies.results}
                onChange={(event, selectedValue, reason) =>
                  handlePoliciesChange(selectedValue, reason)
                }
                required
                limitTags={20}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={5} lg={7}></Grid>
            {showActionRolesDropdown && (
              <>
                <Grid item xs={12} sm={9} md={7} lg={5}>
                  <AutoCompleteWithLabel
                    multiple={true}
                    hasSelectAllOption={true}
                    size="small"
                    id="selectRoles"
                    label="Select Roles"
                    name="selectRoles"
                    formik="false"
                    options={bbkActionRoles.results}
                    selectedValue={selectedBBKActionRoles}
                    onChange={(event, selectedValue, reason) =>
                      handleBBKActionRolesChange(selectedValue, reason)
                    }
                    required
                    limitTags={20}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={5} lg={7}></Grid>
              </>
            )}
          </StyledGrid>
        )}

      {selectedModule && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize={false}
          onSubmit={_ => {
            dispatch(updateExistingEmailTriggers())
          }}
          key={initialValuesUpdateCounter}
        >
          {() => (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <DataGrid
                    headers={updatedGridHeader}
                    tableData={addComponents(gridRows)}
                    setRequestedSort={() => {}}
                    order={'DESC'}
                    orderBy={''}
                    alternateRowColored={true}
                    border="allSides"
                    radius={'false'}
                  />
                </Grid>

                <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                </ActionWrapper>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      <QuickView
        title="Add / Edit Assignments per Policy"
        onClose={() => dispatch(setShowAssignmentForm(false))}
        dialogContent={<AssignmentsForm />}
        isDialogOpen={showAssignmentForm}
        maxWidth="md"
      />
    </>
  )
}

export default SmartAlerts
