import { createAction, createReducer } from '@reduxjs/toolkit'

import { getArriveRequestTypes, getArriveRequestCountries, saveArriveRequestCountries } from 'services/configuration/arriveConfiguration'
import { showError, showSuccess } from '../application'
import { setLoading } from '../userInteractions'
import { requestTypeOrder } from 'components/helper/constants/visitServices'
import { copyObject } from 'components/helper/utility'
import { deleteHelperAttachment, uploadHelperAttachment } from 'services/common'
import { setArriveHelperAttachments, fetchArriveHelperAttachments } from 'store/ducks/visitServices/requestDetails'

export const setArriveRequestTypes = createAction('arriveConfiguration/setArriveRequestTypes')
export const setArriveRequestCountries = createAction('arriveConfiguration/setArriveRequestCountries')
export const setInitialArriveRequestCountriesValues = createAction('arriveConfiguration/setInitialArriveRequestCountriesValues')
export const setInitialValuesUpdateCounter = createAction('arriveConfiguration/setInitialValuesUpdateCounter')
export const resetArriveConfiguration = createAction('arriveConfiguration/resetArriveConfiguration')

export const fetchArriveRequestCountriesInitialValues = () => async (dispatch, getState) => {
  const { initialValuesUpdateCounter } = getState().arriveConfiguration
  dispatch(setArriveRequestTypes({ isLoading: true }))
  dispatch(setArriveRequestCountries({ isLoading: true }))
  try {
    const arriveRequestTypes = await getArriveRequestTypes()
    // Order arrive types in the order they are appear in Make a Request screen
    const reorderedArriveRequestTypes = requestTypeOrder
      .map(id => arriveRequestTypes.find(reqType => reqType.id === id))
      // remove false values (undefined, null, false, '')
      .filter(Boolean)
    const arriveRequestCountries = await getArriveRequestCountries()

    dispatch(setArriveRequestTypes({ results: reorderedArriveRequestTypes, isLoading: false }))
    dispatch(setArriveRequestCountries({ results: arriveRequestCountries, isLoading: false }))

    const initialValues = Object.fromEntries(arriveRequestTypes.map(reqType => [reqType.id.toString(), []]))
    // populate existing countries mappings
    arriveRequestCountries.forEach(reqCountries => {
      initialValues[reqCountries.rsgArriveRequestTypeId.toString()] = reqCountries.countries
    })

    dispatch(setInitialArriveRequestCountriesValues(initialValues))
    dispatch(setInitialValuesUpdateCounter(initialValuesUpdateCounter + 1))
  } catch (e) {
    dispatch(showError('There was some error while fetching information. Please try again later.', e))
  } finally {
    dispatch(setArriveRequestTypes({ isLoading: false }))
    dispatch(setArriveRequestCountries({ isLoading: false }))
  }
}

export const updateArriveRequestCountries = values => async dispatch => {
  dispatch(setLoading(true))
  try {
    /* Payload for update
    {
        "rsgArriveRequestCountryList": [
          {
            "lursgArriveRequestTypeId": 0,
            "countryIds": [
              0
            ]
          }
        ]
      }
    */
    const payloadArray = Object.keys(values).map(requestTypeId => (
      {
        lursgArriveRequestTypeId: parseInt(requestTypeId),
        countryIds: values[requestTypeId].map(country => country.id)
      }
    ))
    const payload = { rsgArriveRequestCountryList: payloadArray }

    await saveArriveRequestCountries(payload)
    await dispatch(fetchArriveRequestCountriesInitialValues())
  } catch {
    dispatch(showError('There was some issue while updating Arrive Request Countries'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const uploadArriveGuidelinesDocument = values => async dispatch => {
  dispatch(setLoading(true))
  try {
    if (values.attachments.length > 0) {
      //  Create an object of formData
      const formData = new FormData()

      formData.set('AttachmentModuleId', 1)
      formData.set('Name', values.attachments[0].name)
      formData.set('File', values.attachments[0])

      await uploadHelperAttachment(formData)
      dispatch(showSuccess('Arrive Guidelines document uploaded successfully!'))
    }
  } catch {
    dispatch(showError('There was some issue while trying to upload the attachments. Please check your file and try again.'))
  } finally {
    dispatch(setLoading(false))
    await dispatch(fetchArriveHelperAttachments())
  }
}

export const deleteArriveGuidelinesDocument = attachmentId => async dispatch => {
  dispatch(setLoading(true))
  try {
    await deleteHelperAttachment({ id: attachmentId })
    await dispatch(setArriveHelperAttachments({ results: [] }))
    dispatch(showSuccess('Arrive Guidelines document deleted successfully!'))
  } catch {
    dispatch(showError('There was some issue while trying to delete the attachment. Please try again later.'))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  arriveRequestTypes: { results: [], isLoading: false },
  arriveRequestCountries: { results: [], isLoading: false },
  initialArriveRequestCountriesValues: {},
  initialValuesUpdateCounter: 0,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setArriveRequestTypes, (state, action) => {
      state.arriveRequestTypes = { ...state.arriveRequestTypes, ...action.payload }
    })
    .addCase(setArriveRequestCountries, (state, action) => {
      state.arriveRequestCountries = { ...state.arriveRequestCountries, ...action.payload }
    })
    .addCase(setInitialArriveRequestCountriesValues, (state, action) => {
      state.initialArriveRequestCountriesValues = { ...action.payload }
    })
    .addCase(setInitialValuesUpdateCounter, (state, action) => {
      state.initialValuesUpdateCounter = action.payload
    })
    .addCase(resetArriveConfiguration, state => {
      copyObject(state, initialState)
    })
})
