import React from 'react'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled('div')(({ align, theme, wrap, styles }) =>
  align === 'column'
    ? {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      ...styles
    }
    : {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: wrap,
      alignItems: 'baseline',
      '*:first-of-type': {
        marginRight: theme.spacing(1)
      },
      ...styles
    }
)

const MainWrapper = styled.div(
  ({ theme, fullwidth, gutterbottom }) => `
    width: ${fullwidth ? '100%' : 'fit-content'};
    margin-bottom: ${theme.spacing(gutterbottom)} 
  `
)

const StyledValue = styled.div`
  overflow-wrap: anywhere;
  ul {
    margin: 6px 0px;
  }
`
const StyledLabel = styled(Typography)`
  white-space: nowrap;
`

const PropertyValue = ({
  label,
  labelProps,
  value,
  valueProps,
  fullWidth,
  align,
  concatMultiple,
  gutterBottom,
  flexWrap,
  styles
}) => {
  const finalValue =
    concatMultiple && value.filter(Boolean).length > 0
      ? value.filter(Boolean).join(', ')
      : value || ''

  const mainContent = (
    <Wrapper align={align} wrap={flexWrap} styles={styles}>
      <StyledLabel {...labelProps}>{label}</StyledLabel>

      <StyledValue>
        {Array.isArray(value)
          ? (
            <ul>
              {value.map(val => (
                <li key={val}><Typography {...valueProps}>{val}</Typography></li>
              ))}
            </ul>
          )
          : <Typography {...valueProps}>{finalValue}</Typography>
        }
      </StyledValue>
    </Wrapper>
  )

  return (
    <MainWrapper gutterbottom={gutterBottom} fullwidth={fullWidth}>
      {mainContent}
    </MainWrapper>
  )
}

PropertyValue.propTypes = {
  /**
   * Label
   */
  label: PropTypes.string.isRequired,
  /**
   * This takes same props as the Typography component and applies them to Label
   */
  labelProps: PropTypes.object,
  /**
   * This takes same props as the Typography component and applies them to Value
   */
  valueProps: PropTypes.object,
  /**
   * Value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.node]),
  /**
   * When true, Takes up the entire horizontal space
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * Aligns the label and value
   */
  align: PropTypes.oneOf(['column', 'row']),
  /**
   * @default false
   * When true and value is an array, renders all the values with a comma separated format
   */
  concatMultiple: PropTypes.bool,
  /**
   * @default 0
   * takes numeric value and calculated margin bottom using the base theme
   */
  gutterBottom: PropTypes.number,
  /**
   * @default wrap
   * when wrap content will wrap to next line in low space scenario
   */
  flexWrap: PropTypes.oneOf(['wrap', 'noWrap']),
  /**
   * Styles applied to wrapper
   */
  styles: PropTypes.object
}

PropertyValue.defaultProps = {
  align: 'column',
  concatMultiple: false,
  labelProps: {
    variant: 'subtitle1'
  },
  valueProps: {
    variant: 'body1'
  },
  gutterBottom: 0,
  fullWidth: false,
  flexWrap: 'wrap'
}

export default PropertyValue
