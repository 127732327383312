import { createAction, createReducer } from '@reduxjs/toolkit'
import { getSitesByCountries } from 'services/common'
import { showError } from '../application'
import { setLoading } from '../userInteractions'
import {
  addVisitScheduleInfo,
  addVisitGuideLineInfo,
  getConfigurationVisitScheduleGroup,
  getVisitGuideLineGroup,
  updateVisitGuideLneAssociatedSites,
  updateVisitScheduleAssociatedSites
} from 'services/configuration/visitDetails'
import { copyObject, extractArrayOfIdsForEachKey } from 'components/helper/utility'
import { visitDetailsUploadType } from 'components/helper/constants/configuration'
import patientDashboardTemplate from 'assets/business/v8_Visit Schedule and Assessments_Patient_Dashboard.xlsx'
import visitDropdownMenus from 'assets/business/v8_Visit Names_Card-Arrive_TEMPLATE.xlsx'
import fileDownload from 'js-file-download'

export const setSitesLookupByCountry = createAction(
  'visitDetailsConfiguration/setSitesLookupByCountry'
)
export const setPatientDashboardDisplayGrid = createAction(
  'visitDetailsConfiguration/setPatientDashboardDisplayGrid'
)
export const setVisitDropdownMenus = createAction('visitDetailsConfiguration/setVisitDropdownMenus')
export const resetVisitDetailsConfiguration = createAction(
  'visitDetailsConfiguration/resetVisitDetailsConfiguration'
)

export const fetchPatientDashboardDisplayGrid = () => async dispatch => {
  dispatch(setPatientDashboardDisplayGrid({ isLoading: true }))
  try {
    const res = await getVisitGuideLineGroup()

    dispatch(setPatientDashboardDisplayGrid({ results: res, isLoading: false }))
  } catch (e) {
    dispatch(setPatientDashboardDisplayGrid({ isLoading: false }))
    dispatch(
      showError(
        'There was some issue while trying to fetch Patient Dashboard Display. Trying refreshing your page.',
        e
      )
    )
  }
}

export const fetchVisitDropdownMenus = () => async dispatch => {
  dispatch(setVisitDropdownMenus({ isLoading: true }))
  try {
    const res = await getConfigurationVisitScheduleGroup()

    dispatch(setVisitDropdownMenus({ results: res, isLoading: false }))
  } catch (e) {
    dispatch(setVisitDropdownMenus({ isLoading: false }))
    dispatch(
      showError(
        'There was some issue while trying to fetch Visit Dropdown Menus. Trying refreshing your page.',
        e
      )
    )
  }
}

export const fetchSitesByCountries = countriesLookup => async dispatch => {
  dispatch(setSitesLookupByCountry({ isLoading: true, results: [] }))
  try {
    const res = await getSitesByCountries(countriesLookup.map(country => country.id).toString())
    dispatch(setSitesLookupByCountry({ results: res, isLoading: false }))
  } catch (e) {
    dispatch(setSitesLookupByCountry({ isLoading: false }))
    dispatch(
      showError('There was some issue while trying to fetch sites. Trying refreshing your page.', e)
    )
  }
}

export const submitPatientDashboardDisplay = (values, onSuccess) => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { displayName, sheetData, fileName, siteAssociation } = values
    const payload = {
      displayName,
      fileName,
      assessmentNames: sheetData.assessmentNames,
      visitDetails: sheetData.visitAssessments,
      ...extractArrayOfIdsForEachKey({
        uniqueSiteProtocolIds: {
          array: siteAssociation,
          propertyToExtract: 'alternateIdentifier'
        }
      })
    }
    await addVisitGuideLineInfo(payload)
    await dispatch(fetchPatientDashboardDisplayGrid())
    onSuccess()
  } catch (e) {
    dispatch(showError('There was error while trying to submit patient dashboard display.', e))
  }
  dispatch(setLoading(false))
}

export const submitVisitDropdownMenus = (values, onSuccess) => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { displayName, sheetData, fileName, siteAssociation } = values
    const payload = {
      displayName,
      fileName,
      visitSchedules: sheetData.visitSchedules,
      ...extractArrayOfIdsForEachKey({
        uniqueSiteProtocolIds: {
          array: siteAssociation,
          propertyToExtract: 'alternateIdentifier'
        }
      })
    }

    await addVisitScheduleInfo(payload)
    await dispatch(fetchVisitDropdownMenus())
    onSuccess()
  } catch (e) {
    dispatch(showError('There was error while trying to submit Study Visit Dropdown Menus.', e))
  }
  dispatch(setLoading(false))
}

export const onUpdateVisitDetailsAssociatedSites = (values, onSuccess) => async dispatch => {
  dispatch(setLoading(true))
  try {
    let payload
    if (values.uploadType === visitDetailsUploadType.patientDashboardDisplay) {
      payload = {
        displayName: values.displayName,
        visitGuidelineGroupId: values.groupId,
        ...extractArrayOfIdsForEachKey({
          uniqueSiteProtocolIds: {
            array: values.siteAssociation,
            propertyToExtract: 'alternateIdentifier'
          }
        })
      }
      await updateVisitGuideLneAssociatedSites(payload)
      await dispatch(fetchPatientDashboardDisplayGrid())
    } else {
      payload = {
        displayName: values.displayName,
        visitScheduleGroupId: values.groupId,
        ...extractArrayOfIdsForEachKey({
          uniqueSiteProtocolIds: {
            array: values.siteAssociation,
            propertyToExtract: 'alternateIdentifier'
          }
        })
      }
      await updateVisitScheduleAssociatedSites(payload)
      await dispatch(fetchVisitDropdownMenus())
    }
    onSuccess()
  } catch (e) {
    dispatch(showError('There was error while trying to edit associated sites.', e))
  }
  dispatch(setLoading(false))
}

export const downloadPatientDashboardTemplate = () => async dispatch => {
  try {
    const response = await fetch(patientDashboardTemplate)
    const blob = await response.blob()
    fileDownload(blob, 'v8_Visit Schedule and Assessments_Patient Dashboard_TEMPLATE.xlsx')
  } catch {
    dispatch(
      showError('There was some error while trying to fetch document. Please try again later.')
    )
  }
}

export const downloadVisitDropdownMenusTemplate = () => async dispatch => {
  try {
    const response = await fetch(visitDropdownMenus)
    const blob = await response.blob()
    fileDownload(blob, 'v8_Visit Names_Card-Arrive_TEMPLATE.xlsx')
  } catch {
    dispatch(
      showError('There was some error while trying to fetch document. Please try again later.')
    )
  }
}

const initialState = {
  sitesLookupByCountry: { results: [], isLoading: false },
  patientDashboardDisplayGrid: { results: [], isLoading: false },
  visitDropdownMenus: { results: [], isLoading: false }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setSitesLookupByCountry, (state, { payload }) => {
      state.sitesLookupByCountry = { ...state.sitesLookupByCountry, ...payload }
    })
    .addCase(setPatientDashboardDisplayGrid, (state, { payload }) => {
      state.patientDashboardDisplayGrid = { ...state.patientDashboardDisplayGrid, ...payload }
    })
    .addCase(setVisitDropdownMenus, (state, { payload }) => {
      state.visitDropdownMenus = { ...state.visitDropdownMenus, ...payload }
    })
    .addCase(resetVisitDetailsConfiguration, state => {
      copyObject(state, initialState)
    })
})
