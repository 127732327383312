import React from 'react'

import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const DisplayAddress = ({ item, bold, containerStyle }) => {
  const Container = styled.div(() => ({ ...containerStyle }))

  return item
    ? (
      <Container data-testid="address-container">
        <Typography key={item.addressId} variant={bold ? 'body2' : 'body1'} component="div" >
          {item.siteName
            ? (
              <p>
                <b>{item.siteName}</b>
                <br />
              </p>
            )
            : null}
          {item.locationName
            ? (
              <p>
                {item.locationName}
                <br />
              </p>
            )
            : null}
          {item.address1
            ? (
              <>
                {item.address1}
                <br />
              </>
            )
            : null}
          {item.address2
            ? (
              <>
                {item.address2}
                <br />
              </>
            )
            : null}
          {item.address3
            ? (
              <>
                {item.address3}
                <br />
              </>
            )
            : null}
          {item.city}, {typeof item.state === 'object' ? item.state?.displayText : item.state}
          <br />
          {typeof item.country === 'object' ? item.country?.displayText : item.country}
          <p>{item.zipCode}</p>
          <p>(t): {item.phone}</p>
        </Typography>
      </Container>
    )
    : (
      <div data-testid="address-container"></div>
    )
}

DisplayAddress.propTypes = {
  item: PropTypes.object,
  bold: PropTypes.bool,
  containerStyle: PropTypes.object
}

DisplayAddress.defaultProps = {
  bold: false
}

export default DisplayAddress
