import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { Bar, Button, Checkbox, PartitionCard } from 'components/common'
import CardHolderDetails from './CardHolderDetails'
import Actions from './Actions'
import {
  fetchAccountHoldersCardDetails,
  fetchCardTranslationDocuments,
  onAcceptingTermsAndConditions,
  setSelectedPersonDetailsManageAccount
} from 'store/ducks/patientRecord/manageAccount'
import PatientCardView from './PatientCardView'
import { Link } from 'react-router-dom'
import { onDownloadPPPdf } from 'store/ducks/auth'
import { downloadHelperAttachment } from 'store/ducks/common'
import PQPatientCardView from './PQPatientCardView'

const RightWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    marginTop: theme.spacing(4)
  }
}))

const TermsAndConditionsWrapper = styled(Grid)(() => ({
  '&&': {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const TermsAndConditionsLabel = styled(Typography)(() => ({
  marginTop: '2px',
  button: {
    padding: '0px'
  }
}))

const StyledStack = styled(Stack)(({ theme }) => ({
  '&&': {
    marginTop: theme.spacing(3),
    button: {
      margin: theme.spacing(0),
      padding: theme.spacing(0, 1)
    }
  }
}))

const AccountDetails = ({ account }) => {
  const dispatch = useDispatch()
  const {
    cardDetailsManageAccount,
    selectedPersonDetailsManageAccount,
    cardTranslationDocuments,
    termsAndConditionsAttachments
  } = useSelector(store => store.manageAccount)
  const { patientInformationForReimbursement, patientSupportsForReimbursement } = useSelector(
    store => store.reimbursement
  )
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)

  const userTermsReadAndAccepted = instanceConfig.results.CardVendor
    ? instanceConfig.results.CardVendor.toLowerCase() ===
      process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase()
      ? account.isTermsAccepted
        ? true
        : termsAndConditionsAttachments.results.length
          ? termsAndConditionsAttachments.results.filter(agreement => !agreement.read).length > 0
          : true
      : !!account.isTermsAccepted
    : true

  const getAccountHolderCardDetails = async () => {
    if (userTermsReadAndAccepted) {
      await dispatch(fetchAccountHoldersCardDetails(account.id))
      await dispatch(fetchCardTranslationDocuments())
    }

    if (!account.supportPersonType) {
      dispatch(setSelectedPersonDetailsManageAccount(patientInformationForReimbursement))
    } else {
      const cardHolderDetailsTempList = patientSupportsForReimbursement.results.filter(
        supportPerson => supportPerson.emailAddress === account.email
      )
      if (cardHolderDetailsTempList.length > 0) {
        dispatch(setSelectedPersonDetailsManageAccount(cardHolderDetailsTempList[0]))
      }
    }
  }

  useEffect(() => {
    getAccountHolderCardDetails()
  }, [])

  return (
    <>
      {cardDetailsManageAccount.isLoading
        ? (
          <div style={{ textAlign: 'center', width: '100%', height: '300px', padding: '200px 0' }}>
            <CircularProgress />
          </div>
        )
        : (
          <Grid container spacing={1}>
            {cardTranslationDocuments.results.length
              ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <StyledStack direction="row" spacing={2} divider={<Bar vertical={true} />}>
                    {cardTranslationDocuments.results.map((translation, index) => (
                      <Button
                        key={index}
                        variant="text"
                        onClick={() => dispatch(downloadHelperAttachment(translation))}
                      >
                        {translation.name}
                      </Button>
                    ))}
                  </StyledStack>
                </Grid>
              )
              : (
                ''
              )}
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <PartitionCard
                cardProps={{
                  cardContentStyles: { padding: '0px', margin: '0px' },
                  cardStyles: { marginTop: '30px' }
                }}
                firstContent={
                  <CardHolderDetails cardHolderDetails={selectedPersonDetailsManageAccount} />
                }
                firstContentWidth="30%"
                firstContentBackground="customBlue"
                firstContentBackgroundSubColor="main"
                firstContentPadding={4}
                secondContent={
                  instanceConfig.results.CardVendor
                    ? (
                      instanceConfig.results.CardVendor.toLowerCase() ===
                    process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase()
                        ? (
                          <PQPatientCardView account={account} />
                        )
                        : (
                          <PatientCardView
                            cardDetails={cardDetailsManageAccount.results}
                            account={account}
                          />
                        )
                    )
                    : (
                      ''
                    )
                }
                secondContentWidth="70%"
                secondContentPadding={0}
              />
            </Grid>
            <RightWrapper item xs={12} sm={12} md={5} lg={3}>
              <Actions cardHolderDetails={selectedPersonDetailsManageAccount} account={account} />
            </RightWrapper>
            <TermsAndConditionsWrapper item xs={12} sm={12} md={12} lg={12}>
              <Checkbox
                name="agreeTermsAndConditions"
                id="agreeTermsAndConditions"
                label={
                  <TermsAndConditionsLabel variant="body2" component="div">
                  I would like to begin using my TCN<sup>TM</sup> Card and I accept the card
                  issuer&apos;s <Link to={`${account.id}/termsconditions`}>Terms & Conditions</Link>{' '}
                  and the subcontracted program manager&apos;s{' '}
                    <Button variant="text" onClick={() => dispatch(onDownloadPPPdf())}>
                    Privacy Policy
                    </Button>
                  </TermsAndConditionsLabel>
                }
                size="small"
                formik="false"
                disableTypographyForLabel={true}
                onChange={() => dispatch(onAcceptingTermsAndConditions(account.id))}
                disabled={!!userTermsReadAndAccepted}
                value={!!account.isTermsAccepted}
              />
            </TermsAndConditionsWrapper>
          </Grid>
        )}
    </>
  )
}

AccountDetails.propTypes = {
  account: PropTypes.object
}

export default AccountDetails
