import { createContext } from 'react'

// Default behavior for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider.

// The permission will not be granted unless a provider says otherwise
const defaultBehavior = {
  isAllowedTo: () => false
}

// Define Permission Context
const permissionContext = createContext(defaultBehavior)

export default permissionContext
