import { backendAxios, getConfig } from 'services/backend'

export const getVisitGuideLineGroup = () =>
  backendAxios
    .get('/configuration/visitguidelinegroups', getConfig())
    .then(res => res.data)
    .catch(err => Promise.reject(err.message))

export const getConfigurationVisitScheduleGroup = () =>
  backendAxios
    .get('/configuration/visitschedulegroups', getConfig())
    .then(res => res.data)
    .catch(err => Promise.reject(err.message))

export const updateVisitGuideLneAssociatedSites = payload =>
  backendAxios
    .post('/configuration/visitguidelinesite', { ...payload }, getConfig())
    .then(res => res.data)
    .catch(err => Promise.reject(err.message))

export const updateVisitScheduleAssociatedSites = payload =>
  backendAxios
    .post('/configuration/visitschedulesite', { ...payload }, getConfig())
    .then(res => res.data)
    .catch(err => Promise.reject(err.message))

export const addVisitGuideLineInfo = payload =>
  backendAxios
    .post('/configuration/visitguidelineinfo', { ...payload }, getConfig())
    .then(res => res.data)
    .catch(err => Promise.reject(err.message))

export const addVisitScheduleInfo = payload =>
  backendAxios
    .post('/configuration/visitscheduleinfo', { ...payload }, getConfig())
    .then(res => res.data)
    .catch(err => Promise.reject(err.message))
