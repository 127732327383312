import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Collapse, Paper } from '@mui/material'
import { Bar } from 'components/common'

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  hr {
    margin-bottom: 0px;
  }
`
const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`
const ActionWrapper = styled.div`
  float: right;
  display: flex;
  align-items: flex-end;
`
const Title = styled.div`
  align-self: flex-end;
`
const SpaceDiv = styled.div`
  margin: ${({ theme }) => theme.spacing(1)};
`
const StyledPaper = styled(Paper)(
  ({ theme, background }) => `
  background-color: ${background && theme.palette[background].light};
`,
)

const PageHeader = ({ title, actionContent, expandContent, content, bar, background }) => (
  <Wrapper>
    <PageHeaderWrapper>
      <Title>{title}</Title>
      {actionContent && <ActionWrapper>{actionContent}</ActionWrapper>}
    </PageHeaderWrapper>
    {bar && <Bar fullWidth={true} color="midnight" />}
    {!bar && <SpaceDiv />}
    {content && <Collapse in={expandContent} unmountOnExit>
      <StyledPaper background={background} elevation={0}>
        {content}
      </StyledPaper>
    </Collapse>}
  </Wrapper>
)

PageHeader.propTypes = {
  /**
   * Title for the page header component
   */
  title: PropTypes.node,
  /**
   * This will show up on the right side of the component
   */
  actionContent: PropTypes.node,
  /**
   * Flag to toggle the content
   */
  expandContent: PropTypes.bool,
  /**
   * content that will show up just below the header
   */
  content: PropTypes.node,
  /**
   * shows a horizontal bar below the header
   */
  bar: PropTypes.bool,
  /**
   * Decides the color of the background
   */
  background: PropTypes.oneOf(['primary', 'secondary']),
}

PageHeader.defaultProps = {
  bar: true,
}

export default PageHeader
