import styled from '@emotion/styled'
import React, { useEffect } from 'react'

const Wrapper = styled.div(() => ({
  '&&': {
    '.otnotice-sections': {
      margin: '50px auto'
    }
  }
}))

const PrivacyNoticeDocument = () => {
  useEffect(() => {
    // Load the OneTrust script after component mounts
    const script = document.createElement('script')
    script.src = 'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js'
    script.type = 'text/javascript'
    script.charset = 'UTF-8'
    script.id = 'otprivacy-notice-script'
    script.setAttribute(
      'settings',
      'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9'
    )

    document.body.appendChild(script)

    // To ensure external settings are loaded, use the Initialized promise:
    script.onload = () => {
      if (window.OneTrust && window.OneTrust.NoticeApi) {
        window.OneTrust.NoticeApi.Initialized.then(() => {
          window.OneTrust.NoticeApi.LoadNotices([
            'https://privacyportal-cdn.onetrust.com/73dca12b-5ba4-4937-9072-b5ffa15d1ba7/privacy-notices/879ed2a1-c9f4-49ac-a534-a488492705b1.json'
          ])
        })
      }
    }
  }, [])

  const htmlContent = `
    <!-- OneTrust Privacy Notice start -->
    <div id="otnotice-879ed2a1-c9f4-49ac-a534-a488492705b1" class="otnotice"></div>
    <!-- OneTrust Privacy Notice end -->
  `

  return (
    <Wrapper>
      <div role="PrivacyNoticeDocument" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Wrapper>
  )
}

export default PrivacyNoticeDocument
