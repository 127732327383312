import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Grid, Typography, IconButton } from '@mui/material'

import HelpMessage from 'assets/images/Card-Help-Message-Icon.png'
// import HelpPhone from 'assets/images/Card-Help-Call-Icon-New.png'
import { Button } from 'components/common'
import QuickView from 'components/common/QuickView'
import ComposeMessage from 'components/private/communicationCenter/studyMessages/ComposeMessage'

const StyledImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
`
// const StyledPhoneNumber = styled(Typography)(
//   ({ theme }) => `
//     margin: ${theme.spacing(1)};
//     color: ${theme.palette.primary.main};
// `,
// )

const PatientHelp = () => {
  const [showComposeMessage, setShowComposeMessage] = useState(false)

  const closeComposeMessage = () => {
    setShowComposeMessage(false)
  }

  const openComposeMessage = () => {
    setShowComposeMessage(true)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ display: 'block', textAlign: 'center' }}>
              <IconButton aria-label="Message Us" aria-haspopup="true" onClick={openComposeMessage} color="primary">
                <StyledImage src={HelpMessage} alt="Message Us" />
              </IconButton>
              <br />
              <Button size="large" fullWidth={false} color="primary" variant="text" onClick={openComposeMessage}>
                <Typography variant="subtitle1">Message Us</Typography>
              </Button>
            </div>
            {/* <div style={{ display: 'block' }}>
              <IconButton aria-label="Message Us" aria-haspopup="true" onClick={() => {}} color="primary">
                <StyledImage src={HelpPhone} alt="Phone" />
              </IconButton>
              <br />
              <StyledPhoneNumber variant="subtitle1">1-231-9317</StyledPhoneNumber>
            </div> */}
          </div>
        </Grid>
      </Grid>
      <QuickView
        title="Write New Message"
        onClose={closeComposeMessage}
        dialogContent={<ComposeMessage onCancel={closeComposeMessage} />}
        isDialogOpen={showComposeMessage}
        maxWidth="lg"
      />
    </>
  )
}

export default PatientHelp
