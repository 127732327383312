import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button, Bar, Badge } from 'components/common'
import { Typography } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'

const MainWrapper = styled.div`
  overflow-x: auto;
`
const StyledButton = styled(Button)(({ theme, active }) => ({
  '&&': {
    borderRadius: '0px',
    textDecoration: 'unset',
    width: 'max-content',
    height: '60px',
    padding: theme.spacing(0, 3.4),
    display: 'flex',
    svg: {
      color: active === 'true' ? theme.palette.secondary.main : theme.palette.customBlue.main,
    },
    p: {
      marginTop: '5px',
    },
  },
}))

const TabsWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    padding: ${theme.spacing(0)};
    min-width: fit-content;
    justify-content: flex-start;
`,
)

const StyledNavLink = styled(NavLink)(() => ({
  '&&': {
    textDecoration: 'unset',
    color: 'unset',
  },
}))

const TopNavigationBar = ({ buttons, bar, changeTab }) => {
  const { pathname } = useLocation()
  return (
    <MainWrapper>
      <TabsWrapper>
        {buttons.map((b, index) => (
          <StyledNavLink to={b.children ? `${b.to}/${b.children[0].to}` : b.to} key={index} data-testid={b.to}>
            {() => {
              const isActive = pathname.split('/').includes(b.to)
              return (
                <StyledButton
                  aria-label={b.label}
                  onClick={() => changeTab && changeTab(b.to)}
                  size="large"
                  color={isActive ? 'customBlue' : 'inherit'}
                  startIcon={<Badge invisible={!b.badgeCount} badgeCount={b.badgeCount}>{b.icon}</Badge>}
                  active={`${isActive}`}
                  variant={isActive ? 'contained' : 'text'}
                >
                  <Typography variant="body2">{b.label}</Typography>
                </StyledButton>
              )
            }}
          </StyledNavLink>
        ))}
      </TabsWrapper>
      {bar && <Bar fullWidth={true} color="midnight" />}
    </MainWrapper>
  )
}

TopNavigationBar.propTypes = {
  /**
   * Function to change tabs
   *
   */
  changeTab: PropTypes.func,
  /**
   *List of tabs
   *
   */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, icon: PropTypes.node, id: PropTypes.string }),
  ).isRequired,
  /**
   * shows a horizontal bar below the header
   */
  bar: PropTypes.bool,
}

TopNavigationBar.defaultProps = {
  bar: false,
}

export default TopNavigationBar
