import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
  Avatar,
  Badge as MuiBadge,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.error.dark,
    border: `2px solid ${theme.palette.background.paper}`,
    width: 'auto',
    height: 'auto',
    minWidth: '23px',
    minHeight: '21px',
    padding: '5px 2px',
    span: {
      padding: '0px',
      margin: '0px',
      lineHeight: '0.7rem'
    }
  }
}))

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '&&': {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.light}`,
      marginBottom: '6px !important',
      padding: '2px 8px'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.primary.light
    },
    p: {
      fontSize: '0.7rem',
      lineHeight: '1rem',
      fontStyle: 'italic'
    }
  }
}))

const StyledBadge = styled(MuiBadge)(() => ({
  '&&': {
    '.MuiBadge-badge': {
      top: '12%',
      left: '2%'
    }
  }
}))

const Badge = ({ invisible, tooltipItems, badgeCount, children }) => {
  const badgeContent = (
    <StyledAvatar>
      <Typography variant="caption">{badgeCount > 99 ? `${99}+` : badgeCount}</Typography>
    </StyledAvatar>
  )
  return children
    ? (
      <StyledBadge
        invisible={invisible}
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        badgeContent={
          tooltipItems
            ? (
              <StyledTooltip
                arrow
                placement="top-start"
                size="small"
                title={
                  <List disablePadding>
                    {tooltipItems.map((badge, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemText
                          primary={badge.displayText}
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                }
              >
                {badgeContent}
              </StyledTooltip>
            )
            : (
              badgeContent
            )
        }
      >
        {children}
      </StyledBadge>
    )
    : (
      !invisible && badgeContent
    )
}

Badge.propTypes = {
  invisible: PropTypes.bool,
  tooltipItems: PropTypes.array,
  badgeCount: PropTypes.number,
  children: PropTypes.node
}

export default Badge
