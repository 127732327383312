import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { AddressField, Bar, CheckboxGroup, TextBoxOuterLabel } from 'components/common'
import AddressForm from 'components/private/siteManagement/siteAddresses/AddressForm'
import { fetchAddressLookup } from 'store/ducks/siteManagement/siteManagement'
import { fetchCountriesLookup } from 'store/ducks/common'
import { fetchTcnUsersLookup } from 'store/ducks/application'
import { useEffect } from 'react'
import PatientLocationForm from '../../../PatientLocationForm'
import { setPatientAddresses } from 'store/ducks/visitServices/inPersonRequest'
import {
  selectPatientAddressCopy,
  inPersonServices
} from 'components/helper/constants/visitServices'
import { useParams } from 'react-router-dom'
import SelectServiceOption from './SelectServiceOption'

const TextContainer = styled.div`
  padding: 2% 10%;
  width: 100%;
  text-align: center;
`

const services = inPersonServices.map(service => ({
  id: service.id,
  displayText: service.displayText,
  control: <SelectServiceOption />
}))

const SelectServices = ({ setFieldValue }) => {
  const dispatch = useDispatch()
  const { siteAddresses, siteAddress } = useSelector(store => store.siteManagement)
  const { patientAddresses } = useSelector(store => store.inPersonRequest)
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  const fetchAllData = async () => {
    await dispatch(fetchAddressLookup())
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchTcnUsersLookup())
  }

  useEffect(() => {
    if (!readOnly) {
      fetchAllData()
    }
  }, [])

  // If Pickup patient Location is selected for Visit Start
  // Drop-off patient Location for Visit End should be defaulted to it.
  const changePickUpPatientLocationVisitStart = selectedAddress => {
    let newSelectedAddress
    if (typeof selectedAddress === 'object') {
      // this means user added new address, so automatically select that address
      newSelectedAddress = selectedAddress
    } else {
      // else find the address by addressId from the list of addresses and then make the selection
      newSelectedAddress = patientAddresses.find(
        item => item.addressId === parseInt(selectedAddress)
      )
    }
    setFieldValue('pickUpPatientLocationVisitStart', newSelectedAddress)
    setFieldValue('dropOffPatientLocationVisitEnd', newSelectedAddress)
  }

  // If Drop-pff site Location is selected for Visit Start
  // Pickup site Location for Visit End should be defaulted to it.
  const changeDropOffSiteLocationVisitStart = selectedAddress => {
    let newSelectedAddress
    if (typeof selectedAddress === 'object') {
      // this means user added new address, so automatically select that address
      newSelectedAddress = selectedAddress
    } else {
      // else find the address by addressId from the list of addresses and then make the selection
      newSelectedAddress = siteAddresses.find(item => item.addressId === parseInt(selectedAddress))
    }
    setFieldValue('dropOffSiteLocationVisitStart', newSelectedAddress)
    setFieldValue('pickUpSiteLocationVisitEnd', newSelectedAddress)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CheckboxGroup
          id="selectedNeeds"
          name="selectedNeeds"
          options={services}
          fullWidth={true}
          optionsAlignInRow={true}
          disableTypographyForLabel={true}
          label={
            <TextContainer>
              <Typography variant="body2">
                Please check the box for each needed service below.
              </Typography>
              <Typography variant="body1">
                Your Concierge will create a comprehensive itinerary that includes all ticketed
                travel, hotel accommodations and ground transportation for all pick-up and departure
                locations of the trip unless you specify otherwise.
              </Typography>
            </TextContainer>
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Bar fullWidth={true} color="midnight" />
      </Grid>
      <Grid item xs={12} md={12} lg={6} xl={6}>
        <Typography variant="h4" gutterBottom>
          Visit Arrival
        </Typography>
        <Bar fullWidth={true} color="midnight" />
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <AddressField
              id="pickUpPatientLocationVisitStart"
              label="Pick-up location for start of this visit"
              name="pickUpPatientLocationVisitStart"
              changeAddressLabel="Change Location"
              addressesList={patientAddresses}
              addressesListHeader={selectPatientAddressCopy}
              addAddressLabel="Add Location"
              addAddressForm={props => (
                <PatientLocationForm
                  onNewAddressAdded={newAddress =>
                    dispatch(setPatientAddresses([...patientAddresses, newAddress]))
                  }
                  {...props}
                />
              )}
              onChange={changePickUpPatientLocationVisitStart}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <AddressField
              id="dropOffSiteLocationVisitStart"
              label="Drop-off site location for start of this visit"
              name="dropOffSiteLocationVisitStart"
              changeAddressLabel="Change / Add Site Location"
              addressesList={siteAddresses}
              addAddressLabel="Add Site Location"
              addAddressForm={props => <AddressForm addressToEdit={siteAddress} {...props} />}
              onChange={changeDropOffSiteLocationVisitStart}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={6} xl={6}>
        <Typography variant="h4" gutterBottom>
          Visit Departure
        </Typography>
        <Bar fullWidth={true} color="midnight" />
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <AddressField
              id="pickUpSiteLocationVisitEnd"
              label="Pick-up site location for end of this visit"
              name="pickUpSiteLocationVisitEnd"
              changeAddressLabel="Change / Add Site Location"
              addressesList={siteAddresses}
              addAddressLabel="Add Site Location"
              addAddressForm={props => <AddressForm addressToEdit={siteAddress} {...props} />}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <AddressField
              id="dropOffPatientLocationVisitEnd"
              label="Drop-off location for end of this visit"
              name="dropOffPatientLocationVisitEnd"
              changeAddressLabel="Change Location"
              addressesList={patientAddresses}
              addressesListHeader={selectPatientAddressCopy}
              addAddressLabel="Add Location"
              addAddressForm={props => (
                <PatientLocationForm
                  onNewAddressAdded={newAddress =>
                    dispatch(setPatientAddresses([...patientAddresses, newAddress]))
                  }
                  {...props}

                />
              )}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h4" gutterBottom>
          Between Visit Arrival and Visit Departure
        </Typography>
        <Bar fullWidth={true} color="midnight" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextBoxOuterLabel
          size="medium"
          id="otherConsiderations"
          label="If there are any other locations associated with this visit (e.g., different buildings for procedures such
              as: imaging, labs) where patient transportation will be necessary, please enter the following details:
              relevant pick-up times, locations and addresses, and any other key considerations."
          placeholder="Other locations details"
          name="otherConsiderations"
          type="text"
          rows="5"
          multiline
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  )
}

SelectServices.propTypes = {
  setFieldValue: PropTypes.func
}

export default SelectServices
