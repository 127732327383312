import React from 'react'
import { Typography, IconButton } from '@mui/material'
import {
  AttachFileOutlined,
  DescriptionOutlined,
  HighlightOffRounded,
  Image,
  OndemandVideo,
  VolumeUp,
} from '@mui/icons-material'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  width: 215px;
  justify-content: space-between;
  border-radius: 10px;
  margin-top: 15px;
  margin-right: 15px;
  position: relative;

  ${({ filetype, theme }) => `${
    filetype.includes('doc')
      ? `background-color: #e9eff7;
          .MuiButtonBase-root:first-of-type {
            svg{
            color: #50b5ff;
            background-color: #50b5ff30;
          }}
          `
      : filetype.includes('video')
        ? `background-color: #f5f0e4;
          .MuiButtonBase-root:first-of-type {
          svg{
            color: #FFC542;
            background-color: #ffc54238;
          }}
          `
        : filetype.includes('music')
          ? `background-color: #eef3e9;
          .MuiButtonBase-root:first-of-type {
          svg{
            color: #82C43C;
            background-color: #85c7404f;
          }}
          `
          : filetype.includes('image')
            ? `background-color: #eef3e9;
          .MuiButtonBase-root:first-of-type {
          svg{
            color: #82C43C;
            background-color: #85c7404f;
          }}
          `
            : `background-color: ${theme.palette.gray.main};
          .MuiButtonBase-root:first-of-type {
          svg{
            color: ${theme.palette.midnight.dark};
            background-color: #a7a7a78c;
          }}`
  }`}
`

const ButtonWrapper = styled(IconButton)`
  .MuiIconButton-label {
    display: flex;
    justify-content: space-between;
  }
  padding: 0px;
  svg {
    padding: 5px;
    font-size: 55px;
    border-radius: 10px;
    margin-right: 5px;
  }
`
const DeleteIcon = styled(IconButton)`
  position: absolute;
  top: -15px;
  right: -15px;
  svg {
    border-radius: 6px;
  }
`

const Title = styled(Typography)`
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ButtonLabelWrapper = styled.div`
  text-align: initial;
`

const AttachmentButton = ({ filetype, title, size, onAttachmentClicked, hasDeleteButton, onDeleteAttachment }) => (
  <Wrapper filetype={filetype}>
    <ButtonWrapper aria-label="document-attached" onClick={() => onAttachmentClicked()}>
      {filetype.includes('doc')
        ? (
          <DescriptionOutlined />
        )
        : filetype.includes('video')
          ? (
            <OndemandVideo />
          )
          : filetype.includes('music')
            ? (
              <VolumeUp />
            )
            : filetype.includes('image')
              ? (
                <Image />
              )
              : (
                <AttachFileOutlined />
              )}
      <ButtonLabelWrapper>
        <Title variant="body2">{title}</Title>
        <Typography>{size}</Typography>
      </ButtonLabelWrapper>
    </ButtonWrapper>
    {hasDeleteButton && (
      <DeleteIcon aria-label="delete-attachment" color="default" onClick={() => onDeleteAttachment()}>
        <HighlightOffRounded />
      </DeleteIcon>
    )}
  </Wrapper>
)

AttachmentButton.propTypes = {
  filetype: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  onAttachmentClicked: PropTypes.func,
  onDeleteAttachment: PropTypes.func,
  hasDeleteButton: PropTypes.bool,
}

AttachmentButton.defaultProps = {
  filetype: 'unknown',
}

export default AttachmentButton
