import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Button } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { setAddedPatientId, setSitePrefSuccessModal } from 'store/ducks/patientExperienceManager'

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(1)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: start;
      justify-content: flex-start;
  `
)

const AddPatientSuccess = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { addedPatientId } = useSelector(store => store.patientExperienceManager)
  const { selectedInstance } = useSelector(store => store.auth)
  const { instanceId } = selectedInstance

  const onPatientProfileVisit = () => {
    nav(`/instance/${instanceId}/patient-dashboard/${addedPatientId}/patientProfile`)
    dispatch(setAddedPatientId(''))
  }

  const onReturnToPatientList = () => {
    dispatch(setAddedPatientId(''))
    dispatch(setSitePrefSuccessModal(false))
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          To take an action regarding support services for this patient, click on{' '}
          <b>Patient Profile</b> and complete the required information. Otherwise, you can{' '}
          <b>Return to Patient List</b> to add another patient.
        </Grid>
        <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
          <Button size="large" fullWidth={false} color="primary" onClick={onPatientProfileVisit}>
            Patient Profile
          </Button>

          <Button size="large" fullWidth={false} color="primary" onClick={onReturnToPatientList}>
            Return to Patient List
          </Button>
        </ActionWrapper>
      </Grid>
    </>
  )
}

AddPatientSuccess.propTypes = {
  closeAddPatientSuccess: PropTypes.func
}

export default AddPatientSuccess
