import { backendAxios, getConfig } from 'services/backend'

// API URL DOES NOT EXIST
// export const requestPlasticCard = () => backendAxios
//   .post('/reimbursement/requestplasticcard', getConfig())
//   .then(res => Promise.resolve(res))
//   .catch(err => Promise.reject(err))

export const getTransactionHistory = (reimbursementCardId, month, year) =>
  backendAxios
    .get('/reimbursement/card/transhistory', getConfig({ reimbursementCardId, month, year }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const postCardHistoryAudit = reimbursementCardId =>
  backendAxios
    .post('/reimbursement/cardhistory/audit', { reimbursementCardId }, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const submitActivatePlasticCard = requestData =>
  backendAxios
    .post('/reimbursement/card/activate', requestData, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getCardPin = reimbursementCardId =>
  backendAxios
    .get('/reimbursement/card/pin', getConfig({ reimbursementCardId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

// NO API for LOST STOLEN
export const submitLostStolen = reimbursementCardId =>
  backendAxios
    .get('/reimbursement/card/transhistory', getConfig({ reimbursementCardId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

/**
 *
 * @param {
  "reimbursementCardId": "string"
  } payload
 * @returns
 */
export const suspendCard = payload =>
  backendAxios
    .post('/reimbursement/cardsuspend', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

/**
 *
 * @param {
  "reimbursementCardId": "string"
  } payload
 * @returns
 */
export const convertToPhysical = payload =>
  backendAxios
    .post('/reimbursement/convertophysical', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const acceptTermsAndConditions = reimbursementCardId =>
  backendAxios
    .patch('/reimbursement/card/termsaccepted', { reimbursementCardId }, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const postCancelAndReplace = (reimbursementCardId, luCardReplaceReasonId) =>
  backendAxios
    .post('/reimbursement/reissue', { reimbursementCardId, luCardReplaceReasonId }, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getReplaceOrReissueReasonsLookup = () =>
  backendAxios
    .get('/common/card/replacementreasons', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getCardDataToken = (reimbursementCardId, format, side, origin, clientIP) =>
  backendAxios
    .get('/reimbursement/card/datatoken', getConfig({ reimbursementCardId, format, side, origin, clientIP }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getCardPinToken = (reimbursementCardId, origin, clientIP) => backendAxios
  .get('/reimbursement/card/pintoken', getConfig({ reimbursementCardId, origin, clientIP }))
  .then(res => Promise.resolve(res))
  .catch(err => Promise.reject(err))
