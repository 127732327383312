import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import { saveParticipationProgressNote } from 'store/ducks/patientRecord/participationProgress'

import Button from 'components/common/form/Button'
import TextBoxOuterLabel from 'components/common/form/TextBoxOuterLabel'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const StyledDiv = styled.div`
  width: '100%';
`

const validationSchema = Yup.object({
  note: Yup.string().required('Note is required')
})

const AddNoteForm = ({ closeAddNoteForm }) => {
  const { patientId } = useParams()
  const dispatch = useDispatch()
  const { participationProgressDetails } = useSelector(store => store.participationProgress)

  const submitHandler = data => {
    const isSiteAssociated = participationProgressDetails.site !== null
    const noteWithPrefix = `<b>Status: ${participationProgressDetails.preconsentStatus?.displayText}</b>: ${data.note}`
    dispatch(saveParticipationProgressNote(noteWithPrefix, patientId, isSiteAssociated))
    closeAddNoteForm()
  }

  return (
    <Formik
      initialValues={{ note: '' }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {() => (
        <StyledDiv>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  size="medium"
                  id="note"
                  label=""
                  placeholder="Note"
                  name="note"
                  type="text"
                  rows="5"
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" color="inherit" fullWidth={false} onClick={closeAddNoteForm}>
                    Cancel
                  </Button>
                  &ensp;&ensp;
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                </ActionWrapper>
              </Grid>
            </Grid>
          </Form>
        </StyledDiv>
      )}
    </Formik>
  )
}

AddNoteForm.propTypes = {
  closeAddNoteForm: PropTypes.func
}

export default AddNoteForm
