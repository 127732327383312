import React from 'react'
import { Grid, Typography } from '@mui/material'
import { TextBoxOuterLabel } from 'components/common'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const LabelWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexDirection: 'column',
    'p:not(:nth-of-type(3))': {
      marginBottom: theme.spacing(2)
    }
  }
}))

const Companion = () => {
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  return <Grid container spacing={2}>
    <Grid sb={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
      <TextBoxOuterLabel
        id="companionDetails"
        data-testid="companionDetails"
        label={
          <LabelWrapper>
            <Typography variant="body2" gutterBottom>
          We can provide medical and non-medical assistance where a professional can accompany
          and support the participant / family members throughout a portion or all of their
          clinical trial journey.
            </Typography>
            <Typography variant="body2" gutterBottom>
          Please provide details and other relevant considerations below for your patient’s
          specific assistance needs.
            </Typography>
            <Typography variant="body2">Other Relevant Considerations</Typography>
          </LabelWrapper>
        }
        name="companionDetails"
        multiline={true}
        rows={3}
        placeholder="Professional Companion Details"
        disabled={readOnly}
      />
    </Grid>
  </Grid>
}

export default Companion
