import React from 'react'
import { PropTypes } from 'prop-types'
import styled from '@emotion/styled'
import { AttachFile } from '@mui/icons-material'
import { MailNavTab } from 'components/common'
import { useRestriction } from 'permissions'
import { Typography } from '@mui/material'

const AttachedLogo = styled(AttachFile)`
  transform: rotate(45deg);
`
const ScheduledMessageText = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  font-style: italic;
`

const AnnouncementTab = ({
  subject,
  isViewed,
  attachments,
  selected,
  distributionDate,
  distributionTime,
  createdDate,
  createdTime,
  sendTo,
  onTabChange
}) => {
  // this permission is true for only bbk and concierge
  const loggedInAsConcierge = useRestriction('read', 'conciergeMsgsWithSitesSitesDropdown')
  const canReadSendTo = useRestriction('read', 'announcementTabSentTo')

  return (
    <MailNavTab
      selected={selected}
      viewed={`${isViewed}`}
      action={attachments.length > 0 && <AttachedLogo />}
      title={subject}
      header={canReadSendTo ? `Sent To: ${sendTo.displayText}` : ''}
      footer={
        <>
          {loggedInAsConcierge
            ? (
              <span>{`${createdDate} | ${createdTime}`}</span>
            )
            : (
              <span>{`${distributionDate} | ${distributionTime}`}</span>
            )}
          {loggedInAsConcierge && distributionDate !== createdDate && (
            <ScheduledMessageText variant="caption" color={`${selected ? 'secondary' : 'primary'}`}>
              Scheduled Announcement
            </ScheduledMessageText>
          )}
        </>
      }
      onTabChange={onTabChange}
      tab="announcement"
    />
  )
}

AnnouncementTab.propTypes = {
  distributionDate: PropTypes.string,
  distributionTime: PropTypes.string,
  createdDate: PropTypes.string,
  subject: PropTypes.string,
  isViewed: PropTypes.bool,
  onTabChange: PropTypes.func,
  sendTo: PropTypes.any,
  attachments: PropTypes.array,
  selected: PropTypes.bool,
  createdTime: PropTypes.string
}

AnnouncementTab.defaultProps = {
  isViewed: false
}

export default AnnouncementTab
