import React from 'react'
import styled from '@emotion/styled'

import { Grid, Typography, Tooltip } from '@mui/material'

import { CustomRadioGroup, DatePickerWithLabel, TextBoxOuterLabel } from 'components/common'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const SmallTextTypography = styled(Typography)`
  font-weight: normal;
  font-style: italic;
`
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const HotelAccommodations = () => {
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" gutterBottom>
          The Concierge will determine hotel dates; however, if you have recommendations, please
          supply below:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePickerWithLabel
          id="checkInDate"
          label="Recommended check-in date"
          name="checkInDate"
          minDate={new Date()}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePickerWithLabel
          id="checkOutDate"
          label="Recommended check-out date"
          name="checkOutDate"
          minDate={new Date()}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CustomRadioGroup
          id="adaRoomRequired"
          data-testid="adaRoomRequired"
          label={
            <Tooltip
              color="primary"
              size="small"
              title={<Typography variant="body1">Americans with Disabilities Act</Typography>}
            >
              <span>Is an ADA room needed? &#9432;</span>
            </Tooltip>
          }
          name="adaRoomRequired"
          options={[
            { value: '1', label: 'yes' },
            { value: '0', label: 'no' }
          ]}
          size="medium"
          row={true}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextBoxOuterLabel
          id="hotelAccommodationOtherPreferences"
          data-testid="hotelAccommodationOtherPreferences"
          label={
            <LabelWrapper>
              <SmallTextTypography variant="caption" gutterBottom>
                If your patient requires special accommodations beyond an ADA room, requires
                kitchenette, has a preferred hotel, hoyer lift, and/or requires meal arrangements,
                please enter details below.
              </SmallTextTypography>
              <Typography variant="body2">Other Relevant Considerations</Typography>
            </LabelWrapper>
          }
          name="hotelAccommodationOtherPreferences"
          multiline={true}
          rows={3}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  )
}

export default HotelAccommodations
