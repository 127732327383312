import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import { Form, Formik } from 'formik'

import {
  onPatientSupportEmailAddress,
  setIsPatientSupportEmaildAddressReadOnly,
} from 'store/ducks/patientRecord/patientProfile'

import TextBoxOuterLabel from 'components/common/form/TextBoxOuterLabel'
import Button from 'components/common/form/Button'
import { Restriction } from 'permissions'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`
const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
const MarginDiv = styled.div(
  ({ theme }) => `  
  margin-top: ${theme.spacing(3.3)};
`,
)
const validationSchema = Yup.object({
  emailAddress: Yup.string().required('Email address is required'),
})

const EditPatientSupportEmailAddress = ({ contactDetails }) => {
  const dispatch = useDispatch()
  const { isPatientSupportEmaildAddressReadOnly } = useSelector(store => store.patientProfile)

  const submitHandler = values => {
    dispatch(onPatientSupportEmailAddress(values, contactDetails))
  }

  const resetFormData = setFieldValue => {
    setFieldValue('emailAddress', contactDetails?.emailAddress)
  }

  return (
    <>
      <Formik
        initialValues={{ userId: contactDetails.userId, emailAddress: contactDetails.emailAddress }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={values => submitHandler(values)}
      >
        {({ setFieldValue }) => (
          <div style={{ width: '100%' }}>
            <Form>
              <Restriction ability="read" feature="patientProfileEditEmailCompanion">
                {isPatientSupportEmaildAddressReadOnly && (
                  <IconButtonWrapper>
                    <Button
                      color="secondary"
                      aria-label="Edit"
                      onClick={() => dispatch(setIsPatientSupportEmaildAddressReadOnly(false))}
                      startIcon={<Edit />}
                      size="small"
                    >
                      Edit
                    </Button>
                    &nbsp;
                  </IconButtonWrapper>
                )}
              </Restriction>
              {!isPatientSupportEmaildAddressReadOnly && <MarginDiv />}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextBoxOuterLabel
                    size="medium"
                    id="emailAddress"
                    label="Email Address / Username"
                    name="emailAddress"
                    type="text"
                    disabled={isPatientSupportEmaildAddressReadOnly}
                  />
                </Grid>
                {!isPatientSupportEmaildAddressReadOnly && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ActionWrapper>
                      <Button size="large" fullWidth={false} color="primary" type="submit">
                        Save
                      </Button>
                      &ensp;&ensp;
                      <Button
                        size="large"
                        color="inherit"
                        fullWidth={false}
                        onClick={() => {
                          resetFormData(setFieldValue)
                          dispatch(setIsPatientSupportEmaildAddressReadOnly(true))
                        }}
                      >
                        Cancel
                      </Button>
                    </ActionWrapper>
                  </Grid>
                )}
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
    </>
  )
}

EditPatientSupportEmailAddress.propTypes = {
  contactDetails: PropTypes.object,
  closeForm: PropTypes.func,
  refreshPatientSupport: PropTypes.func,
}

export default EditPatientSupportEmailAddress
