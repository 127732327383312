import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { Box, Grid, Skeleton } from '@mui/material'
import { Bar, FormMapper } from 'components/common'

const IndividualPrescreener = () => {
  const { patientSurveyQnA } = useSelector(store => store.participationProgress)

  return patientSurveyQnA.isLoading
    ? (
      <Box>
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
      </Box>
    )
    : (
      <Formik
        initialValues={{
          ...patientSurveyQnA.studyInfoInitialValues,
          ...patientSurveyQnA.surveyInitialValues
        }}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
        }}
      >
        {() => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormMapper
                  questions={patientSurveyQnA.studyInfoFieldConfig}
                  wrapperStyles={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap'
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Bar fullWidth color="gray" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormMapper questions={patientSurveyQnA.surveyFieldConfig} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    )
}

IndividualPrescreener.propTypes = {
  showAddPatientSuccess: PropTypes.func
}

export default IndividualPrescreener
