export const formElements = {
  dropdownMenu: 'Dropdown Menu',
  multiSelectDropdown: 'Multi Select Dropdown',
  radioButtons: 'Radio Buttons',
  checkbox: 'Check Box',
  checkboxGroup: 'Check Boxes',
  openText: 'Open Text',
  number: 'Number Text',
  emailOpenText: 'Email Open Text',
  richTextEditor: 'Rich Text Editor',
  date: 'Date',
  attachment: 'Attachment'
}

export const TCNConnectSiteUrl = 'https://www.tcnconnect.com/'

export const patientTabIDs = {
  dashboard: 'dashboard',
  patientProfile: 'patientProfile',
  participationProgress: 'participationProgress',
  patientMailbox: 'patientMailbox',
  studyTools: 'studyTools',
  reimbursement: 'reimbursement',
  visitSupportServices: 'arrive'
}

export const helperAttachmentModuleNames = {
  arriveGuidelines: 'Arrive Thresholds & Guidelines',
  cardTermsAndConditions: 'Card Terms & Conditions',
  cardReferenceDocuments: 'Card Reference Documents'
}
