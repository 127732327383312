import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import { saveIVRConfiguration } from 'store/ducks/configuration/participationProgressConfiguration'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import { Button, Card, CustomRadioGroup } from 'components/common'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`
const ContentWrapper = styled.div`
  width: 100%;
`

const validationSchema = validationMapper([
  { id: 'isIVRIntegration', isResponseRequired: false, answerType: formElements.checkbox }
])

const IVRIntegrationForm = () => {
  const dispatch = useDispatch()
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)

  return (
    <Card title="Patient Data Integration"
      cardHeaderStyles={{
        '.MuiCardHeader-title': {
          fontSize: '16px',
        },
        padding: '14px 14px 7px 14px',
        marginTop: '14px'
      }}
    >
      <ContentWrapper>
        <Formik
          // Since RadioGroup only works with string value, we convert true/false to '1'/'0'
          initialValues={{ isIVRIntegration: instanceConfig.results.IVRIntegration === 'true' ? '1' : '0' }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={values => {
            // transform value back from '1'/'0' to true/false
            const isIVRIntegration = values.isIVRIntegration === '1'
            dispatch(saveIVRConfiguration({ isIVRIntegration }))
          }}
        >
          {({ dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Checkbox
                    name="isIVRIntegration"
                    id="isIVRIntegration"
                    label="IVR Integration"
                    size="medium"
                  /> */}
                  <CustomRadioGroup
                    name="isIVRIntegration"
                    id="isIVRIntegration"
                    data-testid="isIVRIntegration"
                    label="Does this instance have patient data integration?"
                    options={[
                      { value: '1', label: 'Yes' },
                      { value: '0', label: 'No' }
                    ]}
                    size="medium"
                    row={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ActionWrapper>
                    <Button
                      size="medium"
                      fullWidth={false}
                      color="primary"
                      type="submit"
                      disabled={!dirty}
                    >
                      Save
                    </Button>
                  </ActionWrapper>
                </Grid>
                <Grid item xs={0} sm={0} md={6} lg={8} />
              </Grid>
            </Form>
          )}
        </Formik>
      </ContentWrapper>
    </Card>
  )
}
export default IVRIntegrationForm
