import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

import Approved from 'assets/images/patient-arrive-approved.PNG'
import Completed from 'assets/images/patient-arrive-completed.PNG'

import { requestStatusId } from 'components/helper/constants/visitServices'
import { filterByRequestStatusId } from 'components/helper/utility'
import { fetchPatientRequests } from 'store/ducks/visitServices/requestDetails'

import { Card, PartitionCard } from 'components/common'
import PatientRequestsGrid from './PatientRequestsGrid'

const StyledImage = styled.img(
  ({ theme }) => `
  && {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${theme.spacing(1)}
  }
`
)

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

const ArrivePatientView = () => {
  const dispatch = useDispatch()
  const { patientRequests } = useSelector(store => store.requestDetails)

  useEffect(() => {
    dispatch(fetchPatientRequests())
  }, [])

  return (
    <>
      <Card radius={false} cardStyles={{ padding: '16px' }}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PartitionCard
              cardProps={{
                cardContentStyles: { padding: '0px', margin: '0px' },
                cardStyles: { boxShadow: '1px 1px 7px 3px #0909091a' }
              }}
              firstContent={
                <ImageWrapper>
                  <img alt="After authentication" src={Approved} width="100px" />
                  <Typography variant="body2">APPROVED</Typography>
                </ImageWrapper>
              }
              firstContentWidth="20%"
              secondContent={
                <PatientRequestsGrid
                  requests={filterByRequestStatusId(patientRequests, [
                    requestStatusId.InProcessApproved
                  ])}
                />
              }
              firstContentBackground="gradientBlue"
              isFirstContentBackgroundGradient={true}
              secondContentWidth="80%"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PartitionCard
              cardProps={{ cardContentStyles: { padding: '0px', margin: '0px' } }}
              firstContent={
                <ImageWrapper>
                  <StyledImage alt="After authentication" src={Completed} width="100px" />
                  <Typography variant="body2">COMPLETED</Typography>
                </ImageWrapper>
              }
              firstContentWidth="20%"
              secondContent={
                <PatientRequestsGrid
                  requests={filterByRequestStatusId(patientRequests, [requestStatusId.Completed])}
                />
              }
              firstContentBackground="gradientBlue"
              isFirstContentBackgroundGradient={true}
              secondContentWidth="80%"
            />
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default ArrivePatientView
