import React, { useEffect } from 'react'
import { PermissionProvider, useRestriction } from 'permissions'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingIndicator, AppBar, Button, Footer } from 'components/common'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from 'components/common/Sidebar'
import styled from '@emotion/styled'
import { Box, Typography, Toolbar } from '@mui/material'
import { performLogout, setLoggedInAsSite } from 'store/ducks/auth'
import ErrorBoundary from 'components/common/ErrorBoundary'
import FallBackComponent from 'components/common/FallbackComponent'

const AppWrapper = styled(Box)`
  display: flex;
`
const ToolbarAndContent = styled(Box)`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
`
const StyledToolbar = styled(Toolbar)`
  height: 100px;
`

const MainContentWrapper = styled(Box)(
  ({ theme }) => `
  margin: auto;
  margin-bottom: 10rem;
  padding-left: ${theme.spacing(5)};
  padding-right: ${theme.spacing(5)};
`
)
const StyledLogo = styled.img(() => ({
  '&&': {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  }
}))

const LogoutButton = styled(Button)`
  width: fit-content;
  text-decoration: none;
`

const Content = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isSidebarOpen } = useSelector(store => store.userInteractions)
  const showSideNav = useRestriction('read', 'sideNavigation')
  const { selectedInstance, isPatient } = useSelector(store => store.auth)
  const HeaderLogo = selectedInstance && selectedInstance.logo

  // this permission is true for only bbk
  const canSelectSite = useRestriction('read', 'conciergeMsgsWithSitesSitesDropdown')
  const loggedInAsSite = !canSelectSite && !isPatient

  useEffect(() => {
    // determine and set the permission and then use it in the entire application
    dispatch(setLoggedInAsSite(loggedInAsSite))
  }, [loggedInAsSite])

  return (
    <AppWrapper>
      <AppBar
        withSideNav={!!showSideNav}
        isSidebarOpen={isSidebarOpen}
        logo={<StyledLogo src={HeaderLogo} alt={selectedInstance.instanceName} />}
        action={
          <LogoutButton
            variant="text"
            color="inherit"
            size="small"
            fullWidth={true}
            onClick={() => {
              dispatch(performLogout(() => navigate('/login')))
            }}
          >
            <Typography variant="body2">Logout</Typography>
          </LogoutButton>
        }
      />

      {isPatient ? '' : <Sidebar role="navigation" />}

      <ToolbarAndContent component="main">
        <StyledToolbar />
        <MainContentWrapper>
          <ErrorBoundary fallback={<FallBackComponent />}>
            <Outlet />
          </ErrorBoundary>
        </MainContentWrapper>
        <Footer />
      </ToolbarAndContent>

    </AppWrapper>
  )
}

const MainAppLayout = () => {
  const { policyPermissions } = useSelector(store => store.application)

  return (
    <>
      {policyPermissions.length > 0
        ? (
          <PermissionProvider permissions={policyPermissions}>
            <Content />
          </PermissionProvider>
        )
        : (
          <LoadingIndicator />
        )}
    </>
  )
}

export default MainAppLayout
