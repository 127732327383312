import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import {
  fetchPatientInformationForReimbursement,
  fetchPatientSupportPersonsForReimbursement,
  getAccounts,
  resetReimbursement
} from 'store/ducks/patientRecord/reimbursement'
import { resetManageAccount } from 'store/ducks/patientRecord/manageAccount'
import { Card, PageHeader } from 'components/common'
import { Typography } from '@mui/material'

const ReimbursementWrapper = () => {
  const dispatch = useDispatch()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { isPatient } = useSelector(store => store.auth)
  const { patientInformationForReimbursement } = useSelector(store => store.reimbursement)

  const getPatientReimbursementInformation = async () => {
    await dispatch(
      fetchPatientSupportPersonsForReimbursement(patientInformationForReimbursement.userId)
    )
    await dispatch(getAccounts(patientInformationForReimbursement.userId))
  }

  useEffect(() => {
    if (patientInformation.userId) {
      if (isPatient) {
        dispatch(fetchPatientInformationForReimbursement())
      } else {
        dispatch(fetchPatientInformationForReimbursement(patientInformation.userId))
      }
    }
  }, [patientInformation.userId])

  useEffect(() => {
    isPatient && patientInformationForReimbursement.userId && getPatientReimbursementInformation()
  }, [patientInformationForReimbursement.userId])

  useEffect(
    () => () => {
      dispatch(resetReimbursement())
      dispatch(resetManageAccount())
    },
    []
  )
  return (
    <Card background="secondary" radius={false} cardContentStyles={{ flexDirection: 'column' }}>
      <PageHeader
        title={!isPatient && <Typography variant="h4">TCN Card</Typography>}
        bar={false}
        expandContent={true}
        content={<Outlet />}
      />
    </Card>
  )
}
export default ReimbursementWrapper
