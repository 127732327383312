import { Home } from '@mui/icons-material'
import PatientMainWrapper from 'components/private/patientSection/PatientMainWrapper'
import { patientTabs } from './navigationRoutes'

const patientRoutes = [
  {
    id: 'patientDashboard',
    label: 'Home',
    component: PatientMainWrapper,
    icon: (
      <>
        <Home fontSize="small" />
      </>
    ),
    to: '',
    children: patientTabs(true),
  },
]

export default patientRoutes
