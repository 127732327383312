/* eslint-disable max-lines */
import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  cardType,
  reimbursementAccordions,
  getCardStatus,
  bankAccountTypes,
  serviceIds
} from 'components/helper/constants/reimbursement'
import {
  formatCurrency,
  copyObject,
  displayDate,
  sortArrayByProperty
} from 'components/helper/utility'
import { postAttachments } from 'services/common'
import { convertToPhysical, submitActivatePlasticCard } from 'services/patientRecord/manageAccount'
import {
  fetchAccounts,
  confirmPatientReimbursementInfo,
  confirmSupportPersonReimbursementInfo,
  // fetchCardFrontDetails,
  fetchCardStatus,
  fetchCardBackDetails,
  fetchServiceCap,
  checkLocalCurrency,
  convertCurrency,
  submitReimbursements,
  getFundsHistory,
  getFundsReceipts,
  updateReimbursement,
  getPatientInformationForReimbursement,
  getAllSupportPersonsForReimbursement,
  fetchServiceCapForRestrictedCountry,
  getFundsHistoryForRestrictedCountry,
  submitReimbursementsForRestrictedCountry,
  getBankAccounts,
  submitBankAccount,
  deleteBankAccount,
  updateBankAccount,
  reviewFunds
} from 'services/patientRecord/reimbursement'
import { showError, showSuccess } from '../application'
import { setLoading } from '../userInteractions'
import {
  setSelectedAccountManageAccount,
  fetchAccountHoldersCardDetails,
  setSelectedPersonDetailsManageAccount,
  setCardDetailsManageAccount
} from './manageAccount'
import { fetchCountriesLookupForTransferFunds } from '../common'
import { fetchReimbursementConfiguration, fetchReimbursementServices } from '../configuration/cardConfiguration'
import { capTypesIds } from 'components/helper/constants/configuration'

export const setPatientInformationForReimbursement = createAction(
  'reimbursement/setPatientInformationForReimbursement'
)
export const setPatientSupportsForReimbursement = createAction(
  'reimbursement/setPatientSupportsForReimbursement'
)
export const setAccounts = createAction('reimbursement/setAccounts')
export const setSelectedAccountAddFunds = createAction('reimbursement/setSelectedAccountAddFunds')
export const setCardDetails = createAction('reimbursement/setCardDetails')
export const setSelectedVisitName = createAction('reimbursement/setSelectedVisitName')
export const setSelectedVisitDate = createAction('reimbursement/setSelectedVisitDate')
export const setAddFundsGridHeaders = createAction('reimbursement/setAddFundsGridHeaders')
export const setServicesEnteredValue = createAction('reimbursement/setServicesEnteredValue')
export const setServiceCaps = createAction('reimbursement/setServiceCaps')
export const setOthersCatModal = createAction('reimbursement/setOthersCatModal')
export const setAmountsExceededModal = createAction('reimbursement/setAmountsExceededModal')
export const setAmountsExceededList = createAction('reimbursement/setAmountsExceededList')
export const setNoteRequiredModal = createAction('reimbursement/setNoteRequiredModal')
export const setAmountsExceedNote = createAction('reimbursement/setAmountsExceedNote')
export const setConfirmReimbursementModal = createAction(
  'reimbursement/setConfirmReimbursementModal'
)
export const setFundsAddedSuccessModal = createAction('reimbursement/setFundsAddedSuccessModal')
export const setFundsHistory = createAction('reimbursement/setFundsHistory')
export const setFundsReceipts = createAction('reimbursement/setFundsReceipts')
export const setSelectedReimbursement = createAction('reimbursement/setSelectedReimbursement')
export const setReceiptsListModal = createAction('reimbursement/setReceiptsListModal')
export const setUpdateReceiptsModal = createAction('reimbursement/setUpdateReceiptsModal')
export const setIsLocalCurrency = createAction('reimbursement/setIsLocalCurrency')
export const setSignUpSuccess = createAction('reimbursement/setSignUpSuccess')
export const setSupportPersonSignUpSuccess = createAction(
  'reimbursement/setSupportPersonSignUpSuccess'
)
export const setServiceIdDisplayTextMapping = createAction(
  'reimbursement/setServiceIdDisplayTextMapping'
)
export const setLocalCurrency = createAction('reimbursement/setLocalCurrency')
export const setLocalCurrencySymbol = createAction('reimbursement/setLocalCurrencySymbol')
export const setRequestPlasticCardSuccessModal = createAction(
  'reimbursement/setRequestPlasticCardSuccessModal'
)
export const setPlasticCardActivatedSuccess = createAction(
  'reimbursement/setPlasticCardActivatedSuccess'
)
export const setBankAccounts = createAction('reimbursement/setBankAccounts')
export const setAddBankAccountModal = createAction('reimbursement/setAddBankAccountModal')
export const setBankAccountDetails = createAction('reimbursement/setBankAccountDetails')
export const setDeleteBankAccountConfirmation = createAction(
  'reimbursement/setDeleteBankAccountConfirmation'
)
export const setSelectedBankAccountId = createAction('reimbursement/setSelectedBankAccountId')
export const setTransferFundsModal = createAction('reimbursement/setTransferFundsModal')
export const setCapTypeRestriction = createAction('reimbursement/setCapTypeRestriction')
export const setRejectionReasonModal = createAction('reimbursement/setRejectionReasonModal')
export const resetReimbursement = createAction('reimbursement/resetReimbursement')

export const fetchPatientInformationForReimbursement = id => async dispatch => {
  dispatch(setLoading(true))
  await getPatientInformationForReimbursement(id)
    // eslint-disable-next-line complexity
    .then(({ data }) => {
      const {
        zipCode,
        middleName,
        gender,
        dateOfBirth,
        emailAddress,
        phoneNumber,
        preferredLanguage,
        patientCommunicationPreferences,
        address1,
        address2,
        address3,
        city,
        country,
        state
      } = data
      const formattedResponse = {
        ...data,
        middleName: middleName || '',
        gender: gender && gender,
        dateOfBirth,
        emailAddress: emailAddress || '',
        phoneNumber: phoneNumber || '',
        preferredLanguage: preferredLanguage || null,
        patientCommunicationPreferences: patientCommunicationPreferences || [],
        address1: address1 || '',
        address2: address2 || '',
        address3: address3 || '',
        city: city || '',
        country: country && country,
        state: state && state,
        zipCode: zipCode || '',
        isReimbursementAcknowledged: !!data.isReimbursementAcknowledged,
        isRestrictedCountry: !!data.isRestrictedCountry,
        cardId: '123',
        isAbleToRequestPlasticCard: !data.isRequestPlasticCard
      }
      dispatch(setPatientInformationForReimbursement(formattedResponse))
    })
    .catch(err => {
      if (err.response && err.response.status === 404) {
        dispatch(
          showError('Record not found. Please click on back button to go back to the application.')
        )
      } else {
        dispatch(
          showError(
            "There was some issue while trying to fetch Patient's contact details. please try again later."
          )
        )
      }
    })
  dispatch(setLoading(false))
}

export const fetchPatientSupportPersonsForReimbursement = userId => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getAllSupportPersonsForReimbursement(userId)
    if (data?.length) {
      const formattedResponse = data.map(support => {
        const {
          middleName,
          gender,
          dateOfBirth,
          emailAddress,
          phoneNumber,
          preferredLanguage,
          secondaryLanguage,
          supportPersonRelationship,
          address1,
          address2,
          address3,
          city,
          country,
          state,
          zipCode
        } = support

        return {
          ...support,
          middleName: middleName || '',
          gender: gender || {
            id: '',
            displayText: ''
          },
          dateOfBirth,
          emailAddress: emailAddress || '',
          phoneNumber: phoneNumber || '',
          preferredLanguage: preferredLanguage || {
            id: '',
            displayText: ''
          },
          secondaryLanguage: secondaryLanguage || {
            id: '',
            displayText: ''
          },
          supportPersonRelationship: supportPersonRelationship || { id: '', displayText: '' },
          address1: address1 || '',
          address2: address2 || '',
          address3: address3 || '',
          city: city || '',
          country: country || { id: '', displayText: '' },
          state: state || { id: '', displayText: '' },
          zipCode: zipCode || '',
          isReimbursementAcknowledged: !!support.isReimbursementAcknowledged,
          cardId: '123'
        }
      })
      dispatch(
        setPatientSupportsForReimbursement({
          results: formattedResponse,
          areSupportPersonsAvailable: true
        })
      )
    } else {
      dispatch(setPatientSupportsForReimbursement(initialValue.patientSupportsForReimbursement))
    }
  } catch (e) {
    dispatch(
      showError(
        'There was an issue while trying to fetch Patient support information. Please try again later.',
        e
      )
    )
  }
  dispatch(setLoading(false))
}

export const onConfirmPatientReimbursementInfo = userId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { patientInformationForReimbursement } = getState().reimbursement
  try {
    await confirmPatientReimbursementInfo({
      userId,
      isAcknowledged: true,
      userSupportPersonId: 0
    })
    dispatch(
      setPatientInformationForReimbursement({
        ...patientInformationForReimbursement,
        isReimbursementAcknowledged: true
      })
    )
    dispatch(setSignUpSuccess(true))
  } catch {
    dispatch(showError('There was some issue while trying to confirm patient information.'))
  }

  dispatch(setLoading(false))
}

export const onConfirmPatientSupportReimbursementInfo = values => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { patientSupportsForReimbursement } = getState().reimbursement
  try {
    // 12/15/22 : Is this correct?
    await confirmSupportPersonReimbursementInfo({ ...values, isAcknowledged: true })
    // Find and replace the confirmed support person's object
    const acknowledgedPersonIndex = patientSupportsForReimbursement.results.findIndex(
      support => support.userSupportPersonId === values.userSupportPersonId
    )

    const newSupportPersons = [...patientSupportsForReimbursement.results]
    newSupportPersons[acknowledgedPersonIndex] = {
      ...newSupportPersons[acknowledgedPersonIndex],
      isReimbursementAcknowledged: true
    }
    dispatch(
      setPatientSupportsForReimbursement({
        results: newSupportPersons,
        areSupportPersonsAvailable: true
      })
    )
    dispatch(setSupportPersonSignUpSuccess(true))
  } catch {
    dispatch(showError('There was some issue while trying to confirm support person information.'))
  }

  dispatch(setLoading(false))
}

export const getAccountDisplayText = account =>
  `${account.name} (${account.supportPersonType || 'Patient'})`

export const getAccounts = (userId, fromAccordion) => async (dispatch, getState) => {
  try {
    dispatch(setSelectedAccountManageAccount(null))
    dispatch(setAccounts({ isLoading: true }))
    dispatch(setLoading(true))
    const { patientInformationForReimbursement, patientSupportsForReimbursement } =
      getState().reimbursement
    const { data } = await fetchAccounts(userId)
    if (data?.length) {
      dispatch(
        setAccounts({
          results: data.map(account =>
          // Keep all Accounts properties for use, instead of just id, displayText
            ({ ...account, displayText: getAccountDisplayText(account) })
          )
        })
      )
      // preselect the card/account when there is only one account associated to this patient
      if (data.length === 1) {
        if (fromAccordion === reimbursementAccordions.addFunds) {
          dispatch(
            setSelectedAccountAddFunds({
              id: data[0].id,
              displayText: getAccountDisplayText(data[0])
            })
          )

          await dispatch(fetchAccountInformation(data[0].id))
        }
        if (fromAccordion === reimbursementAccordions.manageAccount) {
          dispatch(
            setSelectedAccountManageAccount({
              ...data[0],
              id: data[0].id,
              displayText: getAccountDisplayText(data[0])
            })
          )

          await dispatch(fetchAccountHoldersCardDetails(data[0].id))

          // extract the card holder details
          // Set the CardHolderDetails of the selected Account
          if (!data[0].supportPersonType) {
            // If supportPersonType is null, it means its a Patient
            dispatch(setSelectedPersonDetailsManageAccount(patientInformationForReimbursement))
          } else {
            // Find the Support Person by email
            const cardHolderDetailsTempList = patientSupportsForReimbursement.results.filter(
              supportPerson => supportPerson.emailAddress === data[0].email
            )
            if (cardHolderDetailsTempList.length > 0) {
              dispatch(setSelectedPersonDetailsManageAccount(cardHolderDetailsTempList[0]))
            }
          }
        }
      }
    }
  } catch (e) {
    dispatch(showError('There was error while trying to fetch accounts.', e))
  }
  dispatch(setAccounts({ isLoading: false }))
  dispatch(setLoading(false))
}

export const fetchAccountInformation = reimbursementCardId => async dispatch => {
  await dispatch(fetchReimbursementServices())
  await dispatch(fetchReimbursementConfiguration(false))
  await dispatch(getCardDetails(reimbursementCardId))
  await dispatch(getServiceCap(reimbursementCardId))
  await dispatch(fetchFundsHistory(reimbursementCardId))
}

export const setupReimbursementForCountryRestricted = () => async dispatch => {
  await dispatch(setupServiceCapForRestrictedCountry())
  await dispatch(fetchFundsHistoryForRestrictedCountry())
}

export const getCardDetails = reimbursementCardId => async dispatch => {
  dispatch(setLoading(true))
  await dispatch(setCardDetails(emptyPatientCardDetails))
  let payload = {
    ...emptyPatientCardDetails,
    reimbursementCardId
  }

  let backResponse, statusResponse
  try {
    // frontResponse = await fetchCardFrontDetails(reimbursementCardId)
  } catch (e) {
    dispatch(showError('There was error while trying to fetch card details.', e))
  }
  try {
    backResponse = await fetchCardBackDetails(reimbursementCardId)
  } catch (e) {
    dispatch(showError('There was error while trying to fetch card details.', e))
  }
  try {
    statusResponse = await fetchCardStatus(reimbursementCardId)
    statusResponse = {
      ...statusResponse,
      cardStatus: getCardStatus(statusResponse.cardStatus)
    }
  } catch (e) {
    dispatch(showError('There was error while trying to fetch card details.', e))
  }

  ;[backResponse, statusResponse].forEach(response => {
    payload = {
      ...payload,
      ...response,
      availableBalance: formatCurrency(response.availableBalance)
    }
  })

  await dispatch(setCardDetails(payload))

  dispatch(setLoading(false))
}

export const getServiceCap = reimbursementCardId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { cardDetails } = getState().reimbursement

  try {
    const isLocalCurrency = await checkLocalCurrency(reimbursementCardId)
    dispatch(setIsLocalCurrency(isLocalCurrency))
    const res = await fetchServiceCap(reimbursementCardId)
    if (res.length) {
      // Sort the response array by sortIndex from backend
      const sortedRes = sortArrayByProperty(res, 'sortIndex')

      // get currency and currency symbol
      dispatch(setLocalCurrency(res[0].currency))
      dispatch(setLocalCurrencySymbol(res[0].currencySymbol))

      const addFundsHeaders = []
      const servicesEnteredValue = {}
      const serviceIdDisplayTextMapping = {}
      const serviceCaps = {}

      let cardCurrencySymbol = ''

      for (const item of sortedRes) {
        if (item.serviceId === serviceIds.totalOrTotalInLocalCurrency) {
          if (isLocalCurrency) {
            addFundsHeaders.push({
              ...item,
              id: item.service,
              label: item.displayText,
              disabled: true
            })

            servicesEnteredValue[item.serviceId] = ''
            serviceCaps[item.serviceId] = item.maxAmount

            // Add Display Texts for Total
            serviceIdDisplayTextMapping.Total = {
              displayText: 'Total',
              currencySymbol: item.currencySymbol
            }
          } else {
            // eslint-disable-next-line no-await-in-loop
            const convertedAmountResponse = await convertCurrency({
              reimbursementCardId,
              amount: item.maxAmount
            })
            cardCurrencySymbol = convertedAmountResponse.toCurrencySymbol

            // if the site's local currency do not match with card currency,
            // then there are two columns

            // column1: one for local currency
            addFundsHeaders.push({
              ...item,
              id: 'totalInLocalCurrency',
              label: 'Total in Local Currency',
              disabled: true
            })

            servicesEnteredValue[serviceIds.totalOrTotalInLocalCurrency] = ''
            serviceCaps[serviceIds.totalOrTotalInLocalCurrency] = item.maxAmount

            // and column2: second for card currency
            addFundsHeaders.push({
              ...item,
              id: 'totalInCardCurrency',
              label: 'Total in Card Currency',
              currency: convertedAmountResponse.toCurrency,
              maxAmount: convertedAmountResponse.convertedAmount,
              currencySymbol: '',
              disabled: true,
              serviceId: serviceIds.totalInCardCurrency
            })

            servicesEnteredValue[serviceIds.totalInCardCurrency] = ''
            serviceCaps[serviceIds.totalInCardCurrency] = convertedAmountResponse.convertedAmount

            // Add Display Texts for Totals
            serviceIdDisplayTextMapping[serviceIds.totalOrTotalInLocalCurrency] = {
              displayText: 'Total in Local Currency',
              currencySymbol: item.currencySymbol
            }

            serviceIdDisplayTextMapping[serviceIds.totalInCardCurrency] = {
              displayText: 'Total in Card Currency',
              currencySymbol: convertedAmountResponse.toCurrencySymbol
            }
          }
        } else {
          addFundsHeaders.push({
            ...item,
            id: item.service,
            label: item.displayText
          })

          servicesEnteredValue[item.serviceId] = ''
          serviceCaps[item.serviceId] = item.maxAmount
        }
        serviceIdDisplayTextMapping[item.serviceId] = {
          displayText: item.displayText,
          currencySymbol: item.currencySymbol
        }
      }

      addFundsHeaders.push({
        id: 'receipts',
        label: 'Receipts',
        serviceId: 'receipts'
      })

      dispatch(setServicesEnteredValue(servicesEnteredValue))
      dispatch(setServiceCaps(serviceCaps))
      dispatch(setAddFundsGridHeaders(addFundsHeaders))
      dispatch(setServiceIdDisplayTextMapping(serviceIdDisplayTextMapping))
      dispatch(setCardDetails({ ...cardDetails, cardCurrencySymbol }))
    }
  } catch {
    dispatch(showError('There was some error while trying to fetch reimbursement category limits.'))
  }
  dispatch(setLoading(false))
}

export const setupServiceCapForRestrictedCountry = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { patientInformationForReimbursement } = getState().reimbursement

  try {
    const res = await fetchServiceCapForRestrictedCountry(patientInformationForReimbursement.userId)
    if (res.length) {
      // Sort the response array by sortIndex from backend
      const sortedRes = sortArrayByProperty(res, 'sortIndex')

      // get currency and currency symbol
      dispatch(setLocalCurrency(res[0].currency))
      dispatch(setLocalCurrencySymbol(res[0].currencySymbol))

      const addFundsHeaders = []
      const servicesEnteredValue = {}
      const serviceIdDisplayTextMapping = {}
      const serviceCaps = {}

      for (const item of sortedRes) {
        if (item.serviceId === serviceIds.totalOrTotalInLocalCurrency) {
          addFundsHeaders.push({
            ...item,
            id: item.service,
            label: item.displayText,
            disabled: true
          })

          servicesEnteredValue[item.serviceId] = ''
          serviceCaps[item.serviceId] = item.maxAmount

          // Add Display Texts for Totals
          serviceIdDisplayTextMapping.Total = {
            displayText: 'Total',
            currencySymbol: item.currencySymbol
          }
        } else {
          addFundsHeaders.push({
            ...item,
            id: item.service,
            label: item.displayText
          })

          servicesEnteredValue[item.serviceId] = ''
          serviceCaps[item.serviceId] = item.maxAmount
        }
        serviceIdDisplayTextMapping[item.serviceId] = {
          displayText: item.displayText,
          currencySymbol: item.currencySymbol
        }
      }

      addFundsHeaders.push({
        id: 'receipts',
        label: 'Receipts',
        serviceId: 'receipts'
      })

      dispatch(setServicesEnteredValue(servicesEnteredValue))
      dispatch(setServiceCaps(serviceCaps))
      dispatch(setAddFundsGridHeaders(addFundsHeaders))
      dispatch(setServiceIdDisplayTextMapping(serviceIdDisplayTextMapping))
    }
  } catch {
    dispatch(showError('There was some error while trying to fetch reimbursement category limits.'))
  }
  dispatch(setLoading(false))
}

export const onSubmittingNewFunds = values => async (dispatch, getState) => {
  try {
    dispatch(setCapTypeRestriction(''))
    await dispatch(fetchReimbursementConfiguration(false))
    const { selectedAccountAddFunds, isLocalCurrency, patientInformationForReimbursement } =
      getState().reimbursement

    if (!isLocalCurrency && !patientInformationForReimbursement.isRestrictedCountry) {
      const convertCurrencyResponse = await convertCurrency({
        reimbursementCardId: selectedAccountAddFunds.id,
        amount: parseFloat(values[serviceIds.totalOrTotalInLocalCurrency])
      })

      const finalConvertedAmount = formatCurrency(convertCurrencyResponse.convertedAmount)

      dispatch(
        setServicesEnteredValue({
          ...values,
          [serviceIds.totalInCardCurrency]: finalConvertedAmount
        })
      )
    } else if (patientInformationForReimbursement.isRestrictedCountry) {
      dispatch(setServicesEnteredValue(values))
    } else {
      dispatch(
        setServicesEnteredValue({
          ...values
        })
      )
    }

    // check each category if entered value exceeds the maxAmount limit
    const exceededCategories = {}
    const { serviceCaps, servicesEnteredValue } = getState().reimbursement
    Object.keys(servicesEnteredValue).forEach(category => {
      if (
        serviceIds.totalInCardCurrency !== parseInt(category) && servicesEnteredValue[category] > serviceCaps[category]
      ) {
        exceededCategories[category] = servicesEnteredValue[category]
      }
    })

    const isAnyAmountExceeded = Object.keys(exceededCategories).length
    const { reimbursementConfiguration } = getState().cardConfiguration

    // Open correct note modal based on conditions

    if (
      reimbursementConfiguration.results.capType.id === capTypesIds.softCaps &&
      isAnyAmountExceeded
    ) {
      dispatch(setCapTypeRestriction(capTypesIds.softCaps))
    }

    if (reimbursementConfiguration.results.capType.id === capTypesIds.reviewAndApprove) {
      if (reimbursementConfiguration.results.approvalRequiredServices.length) {
        // if user entered amount in at-least one of the `review required services`
        if (
          reimbursementConfiguration.results.approvalRequiredServices.some(
            service => !!servicesEnteredValue[service.id]
          )
        ) {
          dispatch(setCapTypeRestriction(capTypesIds.reviewAndApprove))
        }
      }
    }

    if (
      reimbursementConfiguration.results.capType.id === capTypesIds.hardCaps &&
      isAnyAmountExceeded
    ) {
      dispatch(setCapTypeRestriction(capTypesIds.hardCaps))
    } else if (values[serviceIds.other] && isAnyAmountExceeded) {
      dispatch(setNoteRequiredModal(true))
      dispatch(setAmountsExceededList({ ...exceededCategories, [serviceIds.other]: values[serviceIds.other] }))
    } else if (values[serviceIds.other]) {
      dispatch(setOthersCatModal(true))
      dispatch(setAmountsExceededList({ [serviceIds.other]: values[serviceIds.other] }))
    } else if (isAnyAmountExceeded) {
      dispatch(setAmountsExceededModal(true))
      dispatch(setAmountsExceededList(exceededCategories))
    } else {
      dispatch(setConfirmReimbursementModal(true))
    }
  } catch (e) {
    dispatch(showError('Something went wrong', e))
  }
}

export const onAmountsExceededModalSubmit =
  (noteRequired, note = '') =>
    async dispatch => {
      if (noteRequired && !note.replace(/ /g, '')) {
        return
      }
      dispatch(setOthersCatModal(false))
      dispatch(setAmountsExceededModal(false))
      dispatch(setNoteRequiredModal(false))
      dispatch(setConfirmReimbursementModal(true))
    }

// eslint-disable-next-line complexity
export const onSubmittingReimbursement = receipts => async (dispatch, getState) => {
  dispatch(
    setLoading({
      showLoading: true,
      loadingCriteria: {
        module: 'reimbursement',
        methodName: 'onSubmittingReimbursement',
        loadingText:
          'Funds are being processed. This may take up to one minute. Thank you for your patience.'
      }
    })
  )
  const {
    servicesEnteredValue,
    selectedAccountAddFunds,
    selectedVisitName,
    selectedVisitDate,
    amountsExceedNote,
    patientInformationForReimbursement,
    fundsHistory
  } = getState().reimbursement

  try {
    const reimbursementFunds = []

    for (const key of Object.keys(servicesEnteredValue)) {
      if (servicesEnteredValue[key]) {
        reimbursementFunds.push({
          luReimbursementServiceId: parseInt(key),
          amount: servicesEnteredValue[key]
        })
      }
    }

    let payload
    let isThisTheFirstReimbursement = true

    if (patientInformationForReimbursement.isRestrictedCountry) {
      payload = {
        userId: patientInformationForReimbursement.userId,
        visitScheduleId: selectedVisitName.id,
        visitDate: selectedVisitDate,
        reimbursementFunds
      }

      if (!fundsHistory.length) {
        isThisTheFirstReimbursement = 'firstReimbursement'
      }
    } else {
      // currencyConversionRate is required by the API, to generate reports(Exchange rate at the time of reimbursement)
      const { conversionRate } = await convertCurrency({
        reimbursementCardId: selectedAccountAddFunds.id,
        amount: 1
      })
      payload = {
        reimbursementCardId: selectedAccountAddFunds.id,
        visitScheduleId: selectedVisitName.id,
        visitDate: selectedVisitDate,
        reimbursementFunds,
        currencyConversionRate: conversionRate
      }
    }

    if (amountsExceedNote) {
      payload = {
        ...payload,
        notes: amountsExceedNote
      }
    }

    if (receipts.length) {
      //  Create an object of formData
      const formData = new FormData()

      receipts.forEach(f => {
        // Update the formData object
        formData.append('files', f)
      })

      const { data } = await postAttachments(formData)
      if (data.length > 0) {
        const existingFileIds = receipts.filter(f => f.id).map(f => f.id)
        payload = {
          ...payload,
          attachmentIds: [...existingFileIds, ...data]
        }
      }
    }

    const response = patientInformationForReimbursement.isRestrictedCountry
      ? await submitReimbursementsForRestrictedCountry(payload)
      : await submitReimbursements(payload)
    if (response) {
      dispatch(setConfirmReimbursementModal(false))
      dispatch(setSelectedVisitDate(null))
      dispatch(setSelectedVisitName(null))
      dispatch(setAmountsExceedNote(''))
      dispatch(setFundsAddedSuccessModal(isThisTheFirstReimbursement))
      if (patientInformationForReimbursement.isRestrictedCountry) {
        dispatch(fetchFundsHistoryForRestrictedCountry())
      } else {
        dispatch(fetchFundsHistory(selectedAccountAddFunds.id))
      }
      // Revert back the categories to initial value
      const categoriesInitialValue = {}
      for (const service of Object.keys(servicesEnteredValue)) {
        categoriesInitialValue[service] = ''
      }
      dispatch(setServicesEnteredValue(categoriesInitialValue))
    }
  } catch (e) {
    dispatch(
      showError('There was some issue while trying to submit the reimbursements. Please try again.', e)
    )
  }

  dispatch(
    setLoading({
      showLoading: false,
      loadingCriteria: {
        module: 'reimbursement',
        methodName: 'onSubmittingReimbursement',
      }
    })
  )
}

export const fetchFundsHistory = selectedReimbursementCardId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setFundsHistory([]))
  const { selectedAccountAddFunds, isLocalCurrency, cardDetails, localCurrencySymbol } =
    getState().reimbursement
  try {
    const reimbursementCardId = selectedReimbursementCardId || selectedAccountAddFunds.id
    let totalInLocalCurrency = 0
    let totalInCardCurrency = 0
    const nonNumericArray = [
      'ReimbursementRequestId',
      'VisitName',
      'VisitDate',
      'Notes',
      'CreatedBy',
      'CreatedOn',
      'AnyAttachments',
      'IsApprovalRequired',
      'IsApproved',
      'ReviewNotes',
      'ReviewedBy',
      'ReviewedOn'
    ]

    const res = await getFundsHistory(reimbursementCardId)
    if (res.length > 0) {
      const finalRes = []
      res.forEach(reimbursement => {
        // loop through each obj and append currencySymbol and convert the numeric values
        const finalObj = {}
        Object.keys(reimbursement).forEach(property => {
          if (nonNumericArray.includes(property)) {
            finalObj[property] = reimbursement[property]
          } else {
            if (!isLocalCurrency && property === 'TotalInCardCurrency') {
              finalObj[property] = `${cardDetails.cardCurrencySymbol}${formatCurrency(
                reimbursement[property]
              )}`
            } else {
              finalObj[property] = `${localCurrencySymbol}${formatCurrency(
                reimbursement[property]
              )}`
            }
          }
        })

        // calculate total and total in card currency for footer
        if (isLocalCurrency) {
          totalInLocalCurrency = totalInLocalCurrency + reimbursement.Total
        } else {
          totalInLocalCurrency = totalInLocalCurrency + reimbursement.Total
          totalInCardCurrency = totalInCardCurrency + reimbursement.TotalInCardCurrency
        }

        if (finalObj.Total && isLocalCurrency) {
          finalRes.push({ ...finalObj })
        } else {
          finalRes.push({
            ...finalObj,
            totalInLocalCurrency: finalObj.Total,
            totalInCardCurrency: finalObj.TotalInCardCurrency
          })
        }
      })

      // push footer properties at the last index
      let footerObj = {}
      Object.keys(finalRes[0]).forEach(eachItem => {
        footerObj[eachItem] = ''
      })

      if (isLocalCurrency) {
        footerObj = {
          ...footerObj,
          rowId: 'footer',
          viewNotes: '',
          Other: 'Total',
          Total: `${localCurrencySymbol}${formatCurrency(totalInLocalCurrency)}`
        }
      } else {
        footerObj = {
          ...footerObj,
          rowId: 'footer',
          viewNotes: '',
          Other: 'Total',
          totalInCardCurrency: `${cardDetails.cardCurrencySymbol}${formatCurrency(
            totalInCardCurrency
          )}`,
          totalInLocalCurrency: `${localCurrencySymbol}${formatCurrency(totalInLocalCurrency)}`
        }
      }

      finalRes.push(footerObj)

      dispatch(setFundsHistory(finalRes))
    }
  } catch (e) {
    dispatch(showError('There was some error while trying to fetch funds history.'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const submitFundsReview = (fundsDetails, onFundsReviewSuccess) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { selectedReimbursement, selectedAccountAddFunds } = getState().reimbursement
    const { isApproved, reviewNotes } = fundsDetails
    const payload = {
      reimbursementCardId: selectedAccountAddFunds.id,
      reimbursementRequestId: selectedReimbursement,
      isApproved
    }
    if (isApproved) {
      await reviewFunds(payload)
      dispatch(showSuccess('Funds Approved Successfully.'))
    } else {
      await reviewFunds({ ...payload, reviewNotes })
      dispatch(showSuccess('Funds Rejected.'))
    }
    dispatch(setRejectionReasonModal(false))
    await dispatch(fetchFundsHistory())
    onFundsReviewSuccess && onFundsReviewSuccess()
  } catch (e) {
    dispatch(showError('Something went wrong', e))
  }
  dispatch(setLoading(false))
}

export const fetchFundsHistoryForRestrictedCountry = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setFundsHistory([]))
  const { localCurrencySymbol, patientInformationForReimbursement } = getState().reimbursement
  try {
    let totalInLocalCurrency = 0

    const res = await getFundsHistoryForRestrictedCountry(patientInformationForReimbursement.userId)
    if (res.length > 0) {
      const finalRes = []
      res.forEach(reimbursement => {
        const nonNumericArray = [
          'ReimbursementRequestId',
          'VisitName',
          'VisitDate',
          'Notes',
          'CreatedBy',
          'CreatedOn',
          'AnyAttachments'
        ]

        // loop through each obj and append currencySymbol and convert the numeric values
        const finalObj = {}
        Object.keys(reimbursement).forEach(property => {
          if (nonNumericArray.includes(property)) {
            finalObj[property] = reimbursement[property]
          } else {
            finalObj[property] = `${localCurrencySymbol}${formatCurrency(reimbursement[property])}`
          }
        })

        // calculate total
        totalInLocalCurrency = totalInLocalCurrency + reimbursement.Total

        finalRes.push({ ...finalObj, totalInLocalCurrency: finalObj.Total })
      })

      // push footer properties at the last index
      let footerObj = {}
      Object.keys(finalRes[0]).forEach(eachItem => {
        footerObj[eachItem] = ' '
      })

      footerObj = {
        ...footerObj,
        rowId: 'footer',
        viewNotes: ' ',
        Other: 'Total',
        totalInLocalCurrency: `${localCurrencySymbol}${formatCurrency(totalInLocalCurrency)}`
      }

      finalRes.push(footerObj)

      dispatch(setFundsHistory(finalRes))
    }
  } catch (e) {
    dispatch(showError('There was some error while trying to fetch funds history.'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchReimbursementReceipts = reimbursementRequestId => async dispatch => {
  dispatch(setLoading(true))
  try {
    const res = await getFundsReceipts(7, reimbursementRequestId)
    const finalRes = res.map(receipt => ({
      ...receipt,
      uploadedOn: displayDate(receipt.uploadedOn)
    }))
    dispatch(setFundsReceipts(finalRes))

    if (res.length) {
      dispatch(setReceiptsListModal(true))
      // This is to store the reimbursementId, just in if user wants to upload more receipts
      dispatch(setSelectedReimbursement(reimbursementRequestId))
    }
  } catch {
    dispatch(showError('There was some error while trying to fetch funds receipts.'))
  }
  dispatch(setLoading(false))
}

export const updateReimbursementReceipts = receipts => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { selectedReimbursement } = getState().reimbursement

  try {
    //  Create an object of formData
    const formData = new FormData()

    receipts.forEach(f => {
      // Update the formData object
      formData.append('files', f)
    })
    const { data } = await postAttachments(formData)
    if (data.length > 0) {
      const existingFileIds = receipts.filter(f => f.id).map(f => f.id)

      const payload = {
        reimbursementRequestId: selectedReimbursement,
        attachmentIds: [...existingFileIds, ...data]
      }

      const res = await updateReimbursement(payload)
      if (res) {
        await dispatch(setUpdateReceiptsModal(false))
        await dispatch(fetchReimbursementReceipts(selectedReimbursement))
        await dispatch(fetchFundsHistory())
      } else {
        dispatch(showError('There was error while trying to upload new receipts.'))
      }
    }
  } catch {
    dispatch(showError('There was some error while trying to upload receipts.'))
  }

  dispatch(setLoading(false))
}

export const onSubmitPlasticCardRequest =
  (cardHolderDetails, reimbursementCardId) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { patientInformationForReimbursement } = getState().reimbursement

    try {
      let payload = {}
      if (!reimbursementCardId) {
        // when reimbursementCardId is null, that means user is trying to request plastic card from SignUp accordion, soon after the isAcknowledged submit
        // In this accordion Get Cards call is not made in advance

        await dispatch(getAccounts(patientInformationForReimbursement.userId))
        const { accounts } = getState().reimbursement

        if (accounts.results.length) {
          // Now extract the card Id from the list of accounts
          let extractedReimbursementCardId
          if (!cardHolderDetails.supportPersonRelationship) {
            // then it is a patient
            extractedReimbursementCardId = accounts.results.filter(
              card => !card.supportPersonRelationShip
            )[0].id
          } else {
            // it is one of the support person
            extractedReimbursementCardId = accounts.results.filter(
              card => card.email === cardHolderDetails.emailAddress
            )[0].id
          }

          payload = {
            reimbursementCardId: extractedReimbursementCardId
          }
          await convertToPhysical(payload)
        } else {
          dispatch(
            showError('There is no account associated yet to this user. Please try again later.')
          )
        }
      } else {
        payload = {
          reimbursementCardId
        }
        await convertToPhysical(payload)

        const { cardDetailsManageAccount } = getState().manageAccount

        // change cardType in cardDetails
        const tempCardDetails = { ...cardDetailsManageAccount.results, cardType: cardType.plastic }
        dispatch(setCardDetailsManageAccount({ results: tempCardDetails }))

        // set isPlastic to true in the selected account
        const { selectedAccountManageAccount } = getState().manageAccount
        dispatch(
          setSelectedAccountManageAccount({ ...selectedAccountManageAccount, isPlastic: true })
        )
      }
      dispatch(setRequestPlasticCardSuccessModal(true))
    } catch (e) {
      dispatch(
        showError('There was some issue while trying to request a plastic card. Please try again.', e)
      )
    }
    dispatch(setLoading(false))
  }

export const onActivatePlasticCard = cvv => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { cardDetailsManageAccount } = getState().manageAccount
  try {
    const payload = {
      reimbursementCardId: cardDetailsManageAccount.results.reimbursementCardId,
      cvv
    }
    await submitActivatePlasticCard(payload)

    // change isPlasticCardActivated to true in the correct account object
    const { selectedAccountManageAccount } = getState().manageAccount
    dispatch(
      setSelectedAccountManageAccount({
        ...selectedAccountManageAccount,
        isPlasticCardActivated: true
      })
    )

    dispatch(setPlasticCardActivatedSuccess(true))
  } catch (e) {
    dispatch(showError('There was issue while activating card!', e))
  }
  dispatch(setLoading(false))
}

export const fetchBankAccounts =
  (reimbursementCardId, disableCache = false) =>
    async dispatch => {
      dispatch(setBankAccounts({ isLoading: true }))
      try {
        const { data } = await getBankAccounts(
          reimbursementCardId,
          disableCache
        )
        if (data) {
          dispatch(
            setBankAccounts({
              results: data.beneficiaries.map(beneficiary => ({
                ...beneficiary.bankDetails,
                country: beneficiary.countryCode,
                beneficiaryId: beneficiary.beneficiaryId
              }))
            })
          )
        }
      } catch (e) {
        dispatch(showError('There was some error while trying to fetch bank accounts', e))
      }
      dispatch(setBankAccounts({ isLoading: false }))
    }

export const onSubmitBankAccount = (reimbursementCardId, values) => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { fullName, routingNo, accountNo, bankName, accountType, country } = values
    const payload = {
      reimbursementCardId,
      beneficiaryFullName: fullName,
      bankName,
      routingNo,
      accountNo,
      accountType: accountType.id,
      countryCode: country.id,
      currency: country.alternateIdentifier
    }

    if (values.beneficiaryId) {
      await updateBankAccount({
        ...payload,
        beneficiaryId: values.beneficiaryId
      })
      dispatch(showSuccess('Successfully updated bank account information.'))
    } else {
      await submitBankAccount(payload)
      dispatch(showSuccess('Successfully added a new bank account.'))
    }
    dispatch(setAddBankAccountModal(false))
    dispatch(fetchBankAccounts(reimbursementCardId))
  } catch (e) {
    dispatch(showError('There was some error while trying to submit bank account details', e))
  }
  dispatch(setLoading(false))
}

export const fetchBankAccountDetails = accountId => async (dispatch, getState) => {
  try {
    await dispatch(fetchCountriesLookupForTransferFunds())
    const { bankAccounts } = getState().reimbursement
    const { countriesLookupForTransferFunds } = getState().common

    const data = bankAccounts.results.find(account => account.beneficiaryId === accountId)
    dispatch(
      setBankAccountDetails({
        ...data,
        country: countriesLookupForTransferFunds.results.find(
          countryObj => countryObj.id === parseInt(data.country)
        ),
        routingNo: data.bankCode,
        accountNo: data.bankAccount,
        accountType: bankAccountTypes.find(type => type.id === parseInt(data.accountType)),
        fullName: data.beneficiaryFullName
      })
    )
    dispatch(setAddBankAccountModal(true))
  } catch {
    dispatch(showError('There is some error, while trying to fetch countries dropdown options'))
  }
}

export const onDeleteBankAccount =
  (reimbursementCardId, accountId) => async dispatch => {
    dispatch(setLoading(true))
    try {
      const payload = { beneficiaryId: accountId, reimbursementCardId }
      await deleteBankAccount(payload)
      dispatch(setDeleteBankAccountConfirmation(false))
      dispatch(fetchBankAccounts(reimbursementCardId, true))
      dispatch(showSuccess('Successfully deleted bank account.'))
    } catch (e) {
      dispatch(showError('There was error while trying to delete bank account.', e))
    }
    dispatch(setLoading(false))
  }

const emptyPatientCardDetails = {
  reimbursementCardId: '',
  expiry: '',
  cardStatus: '',
  cardCurrency: '',
  trackingNumber: '',
  availableBalance: 0,
  totalReimbursement: 0,
  cvv: '',
  cardholderEmail: '',
  cardType: '',
  name: '',
  cardImageLink: '',
  san: '',
  status: 0,
  description: ''
}

const initialValue = {
  patientInformationForReimbursement: {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: { id: '', displayText: '' },
    dateOfBirth: '',
    emailAddress: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    country: { id: '', displayText: '' },
    state: { id: '', displayText: '' },
    preferredLanguage: null,
    patientCommunicationPreferences: [],
    isReimbursementAcknowledged: false,
    isRestrictedCountry: false,
    isAbleToRequestPlasticCard: false
  },
  patientSupportsForReimbursement: {
    results: [
      {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: null,
        dateOfBirth: '',
        emailAddress: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        zipCode: '',
        phoneNumber: '',
        country: null,
        state: null,
        preferredLanguage: null,
        patientCommunicationPreferences: [],
        isReimbursementAcknowledged: false
      }
    ],
    areSupportPersonsAvailable: false
  },
  accounts: { results: [], isLoading: false },
  selectedAccountAddFunds: null,
  selectedVisitName: null,
  selectedVisitDate: null,
  cardDetails: emptyPatientCardDetails,
  amountsExceedNote: '',
  noteRequiredModal: false,
  addFundsGridHeaders: [],
  servicesEnteredValue: {},
  serviceCaps: {},
  othersCatModal: false,
  amountsExceededModal: false,
  amountsExceededList: {},
  fundsHistory: [],
  confirmReimbursementModal: false,
  fundsAddedSuccessModal: false,
  selectedReimbursement: '',
  receiptsListModal: false,
  updateReceiptsModal: false,
  isLocalCurrency: false,
  signUpSuccess: false,
  serviceIdDisplayTextMapping: {},
  localCurrency: '',
  localCurrencySymbol: '',
  supportPersonSignUpSuccess: false,
  requestPlasticCardSuccessModal: false,
  plasticCardActivatedSuccess: false,
  bankAccounts: { isLoading: false, results: [] },
  addBankAccountModal: false,
  bankAccountDetails: {
    country: null,
    fullName: '',
    bankName: '',
    routingNo: '',
    accountNo: '',
    accountType: null
  },
  deleteBankAccountConfirmation: false,
  selectedBankAccountId: '',
  transferFundsModal: false,
  capTypeRestriction: '',
  rejectionReasonModal: false
}

export default createReducer(initialValue, builder => {
  builder
    .addCase(setPatientInformationForReimbursement, (state, action) => {
      state.patientInformationForReimbursement = action.payload
    })
    .addCase(setPatientSupportsForReimbursement, (state, action) => {
      state.patientSupportsForReimbursement = {
        ...state.patientSupportsForReimbursement,
        ...action.payload
      }
    })
    .addCase(setAccounts, (state, action) => {
      state.accounts = { ...state.accounts, ...action.payload }
    })
    .addCase(setSelectedAccountAddFunds, (state, action) => {
      state.selectedAccountAddFunds = action.payload
    })
    .addCase(setSelectedVisitName, (state, action) => {
      state.selectedVisitName = action.payload
    })
    .addCase(setSelectedVisitDate, (state, action) => {
      state.selectedVisitDate = action.payload
    })
    .addCase(setCardDetails, (state, action) => {
      state.cardDetails = action.payload
    })
    .addCase(setServiceCaps, (state, action) => {
      state.serviceCaps = action.payload
    })
    .addCase(setAddFundsGridHeaders, (state, action) => {
      state.addFundsGridHeaders = action.payload
    })
    .addCase(setServicesEnteredValue, (state, action) => {
      state.servicesEnteredValue = action.payload
    })
    .addCase(setAmountsExceedNote, (state, action) => {
      state.amountsExceedNote = action.payload
    })
    .addCase(setNoteRequiredModal, (state, action) => {
      state.noteRequiredModal = action.payload
    })
    .addCase(setOthersCatModal, (state, action) => {
      state.othersCatModal = action.payload
    })
    .addCase(setAmountsExceededModal, (state, action) => {
      state.amountsExceededModal = action.payload
    })
    .addCase(setAmountsExceededList, (state, action) => {
      state.amountsExceededList = action.payload
    })
    .addCase(setConfirmReimbursementModal, (state, action) => {
      state.confirmReimbursementModal = action.payload
    })
    .addCase(setFundsAddedSuccessModal, (state, action) => {
      state.fundsAddedSuccessModal = action.payload
    })
    .addCase(setFundsHistory, (state, action) => {
      state.fundsHistory = action.payload
    })
    .addCase(setFundsReceipts, (state, action) => {
      state.fundsReceipts = action.payload
    })
    .addCase(setSelectedReimbursement, (state, action) => {
      state.selectedReimbursement = action.payload
    })
    .addCase(setReceiptsListModal, (state, action) => {
      state.receiptsListModal = action.payload
    })
    .addCase(setUpdateReceiptsModal, (state, action) => {
      state.updateReceiptsModal = action.payload
    })
    .addCase(setIsLocalCurrency, (state, action) => {
      state.isLocalCurrency = action.payload
    })
    .addCase(setSignUpSuccess, (state, action) => {
      state.signUpSuccess = action.payload
    })
    .addCase(setSupportPersonSignUpSuccess, (state, action) => {
      state.supportPersonSignUpSuccess = action.payload
    })
    .addCase(setServiceIdDisplayTextMapping, (state, action) => {
      state.serviceIdDisplayTextMapping = action.payload
    })
    .addCase(setLocalCurrency, (state, action) => {
      state.localCurrency = action.payload
    })
    .addCase(setLocalCurrencySymbol, (state, action) => {
      state.localCurrencySymbol = action.payload
    })
    .addCase(setRequestPlasticCardSuccessModal, (state, action) => {
      state.requestPlasticCardSuccessModal = action.payload
    })
    .addCase(setPlasticCardActivatedSuccess, (state, action) => {
      state.plasticCardActivatedSuccess = action.payload
    })
    .addCase(setBankAccounts, (state, action) => {
      state.bankAccounts = { ...state.bankAccounts, ...action.payload }
    })
    .addCase(setAddBankAccountModal, (state, action) => {
      state.addBankAccountModal = action.payload
    })
    .addCase(setBankAccountDetails, (state, action) => {
      state.bankAccountDetails = action.payload
    })
    .addCase(setDeleteBankAccountConfirmation, (state, action) => {
      state.deleteBankAccountConfirmation = action.payload
    })
    .addCase(setSelectedBankAccountId, (state, action) => {
      state.selectedBankAccountId = action.payload
    })
    .addCase(setTransferFundsModal, (state, action) => {
      state.transferFundsModal = action.payload
    })
    .addCase(setCapTypeRestriction, (state, action) => {
      state.capTypeRestriction = action.payload
    })
    .addCase(setRejectionReasonModal, (state, action) => {
      state.rejectionReasonModal = action.payload
    })
    .addCase(resetReimbursement, state => {
      copyObject(state, initialValue)
    })
})
