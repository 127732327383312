import { patientTabIDs } from 'components/helper/constants/common'
import { communicationTabIDs } from 'components/helper/constants/mailbox'
export const navigationTabs = [
  {
    label: 'PROTOCOL / CAMPAIGN',
    icon: <>PROTOCOL_CAMPAIGN ICON</>,
    to: 'PROTOCOL_CAMPAIGN',
    component: <div>PROTOCOL / CAMPAIGN</div>
  },
  {
    label: 'QUICK QUERY',
    icon: <>QUICK_QUERY ICON</>,
    to: 'QUICK_QUERY',
    component: <div>QUICK QUERY</div>
  },
  {
    label: 'RESOURCE CENTER',
    icon: <>RESOURCE_CENTER ICON</>,
    to: 'RESOURCE_CENTER',
    component: <div>RESOURCE CENTER</div>
  },
  {
    label: 'PARTICIPATION PROGRESS',
    icon: <>PARTICIPATION_PROGRESS ICON</>,
    to: 'PARTICIPATION_PROGRESS',
    component: <div>PARTICIPATION PROGRESS</div>
  }
]

export const mockIsPatientTabs = [
  {
    label: 'HOME',
    to: patientTabIDs.dashboard,
    navigateUrl: patientTabIDs.dashboard,

  },
  {
    label: 'PROFILE',
    to: patientTabIDs.patientProfile,
    navigateUrl: patientTabIDs.patientProfile,
  },
  {
    label: 'STUDY TOOLS',
    to: patientTabIDs.studyTools,
    navigateUrl: patientTabIDs.studyTools,
  },
  {
    label: 'TCN CARD',
    to: patientTabIDs.reimbursement,
    navigateUrl: `${patientTabIDs.reimbursement}/`,
  },
  {
    label: 'TCN ARRIVE',
    to: patientTabIDs.visitSupportServices,
    navigateUrl: `${patientTabIDs.visitSupportServices}/arriveDashboard`,
  }
]

export const mockPatientMailBoxTabs = [
  {
    label: 'CONCIERGE MESSAGES',

    to: communicationTabIDs.conciergeMsgsForPatients,

  },
  {
    label: 'GENERAL ANNOUNCEMENTS',

    to: communicationTabIDs.announcementsForPatients,

  }
]

export const mockPatientTabs = [
  {
    label: 'HOME',
    to: patientTabIDs.dashboard,
    navigateUrl: patientTabIDs.dashboard,

  },
  {
    label: 'PROFILE',
    to: patientTabIDs.patientProfile,
    navigateUrl: patientTabIDs.patientProfile,
  },
  // {
  //   label: 'PARTICIPATION PROGRESS',
  //   to: patientTabIDs.participationProgress,
  //   navigateUrl: patientTabIDs.participationProgress,
  // },
  // {
  //   label: 'MAILBOX',
  //   to: patientTabIDs.patientMailbox,

  //   navigateUrl: patientTabIDs.patientMailbox,
  // },
  {
    label: 'STUDY TOOLS',
    to: patientTabIDs.studyTools,
    navigateUrl: patientTabIDs.studyTools,
  },
  {
    label: 'TCN CARD',
    to: patientTabIDs.reimbursement,
    navigateUrl: `${patientTabIDs.reimbursement}/`,
  },
  {
    label: 'TCN ARRIVE',
    to: patientTabIDs.visitSupportServices,
    navigateUrl: `${patientTabIDs.visitSupportServices}/arriveDashboard`,
  }
]

export const mockAllTabs = [
  {
    label: 'DASHBOARD',
    to: patientTabIDs.dashboard,
    navigateUrl: patientTabIDs.dashboard,

  },
  {
    label: 'PATIENT PROFILE',
    to: patientTabIDs.patientProfile,
    navigateUrl: patientTabIDs.patientProfile,
  },
  {
    label: 'PARTICIPATION PROGRESS',
    to: patientTabIDs.participationProgress,
    navigateUrl: patientTabIDs.participationProgress,
  },
  {
    label: 'STUDY TOOLS',
    to: patientTabIDs.studyTools,
    navigateUrl: patientTabIDs.studyTools,
  },
  {
    label: 'TCN CARD',
    to: patientTabIDs.reimbursement,
    navigateUrl: `${patientTabIDs.reimbursement}/`,
  },
  {
    label: 'TCN ARRIVE',
    to: patientTabIDs.visitSupportServices,
    navigateUrl: `${patientTabIDs.visitSupportServices}/arriveDashboard`,
  }
]
