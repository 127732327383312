import React from 'react'
import { Typography } from '@mui/material'
import { DataGrid, Pagination } from 'components/common'
import { useSelector } from 'react-redux'
import { palette } from 'styles/baseTheme'

const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'center',
  width: '120px',
}

const messageSendCategory = {
  1: 'Patients:',
  2: 'Site users:',
  3: 'Vendors:',
  4: 'Active users:',
}

const DistributedList = () => {
  const { distributedList, announcement } = useSelector(store => store.announcements)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  return (
    <>
      <Typography variant="body2" gutterBottom={true}>{`This announcement was sent to the following ${
        messageSendCategory[announcement.sendTo.id]
      }`}</Typography>
      <DataGrid
        headers={[
          { id: 'name', label: 'Name', isSortable: false, headProperties: { ...headerProperties } },
          { id: 'readUnread', label: 'Read / Unread', isSortable: false, headProperties: { ...headerProperties } },
          { id: 'siteID', label: 'Site ID', isSortable: false, headProperties: { ...headerProperties } },
          { id: 'country', label: 'Country', isSortable: false, headProperties: { ...headerProperties } },
        ]}
        tableData={distributedList.results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        setRequestedSort={() => { }}
        order={'DESC'}
        orderBy={'createdDate'}
        border="allSides"
        radius={'false'}
      />
      <Pagination
        canChangeRowsPerPage={[5, 10, 20, 50, 100]}
        totalCount={distributedList.totalCount}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        setPageNumber={pageNumber => setPage(pageNumber)}
        setRowsPerPage={numberOfRows => {
          setRowsPerPage(parseInt(numberOfRows, 10))
          setPage(0)
        }}
      />
    </>
  )
}

export default DistributedList
