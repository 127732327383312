/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import {
  fetchSitesByCountries,
  setSitesLookupByCountry,
  saveDataPoint,
  resetSitesLookupByCountry,
} from 'store/ducks/siteManagement/siteManagement'
import { fetchSiteDataPointsType, fetchCountriesLookup } from 'store/ducks/common'

import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import Button from 'components/common/form/Button'
import { TextBoxOuterLabel, AutoCompleteWithLabel } from 'components/common/form'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const StyledDiv = styled.div`
  width: '100%';
`

const validationSchema = () => validationMapper([
  { id: 'countries', isResponseRequired: true, answerType: formElements.multiSelectDropdown },
  { id: 'sites', isResponseRequired: false, answerType: formElements.multiSelectDropdown },
  { id: 'dataPoint', isResponseRequired: true, answerType: formElements.openText },
  { id: 'answerType', isResponseRequired: true, answerType: formElements.dropdownMenu },
])

const AddDataPointForm = ({ closeAddDataPointForm, siteId }) => {
  const dispatch = useDispatch()
  const { sitesLookupByCountry } = useSelector(store => store.siteManagement)
  const { countriesLookup, siteDataPointTypes } = useSelector(store => store.common)

  const submitHandler = values => {
    dispatch(saveDataPoint(values, siteId))
    closeAddDataPointForm()
  }

  useEffect(() => () => {
    dispatch(resetSitesLookupByCountry())
  }, [])

  const onFilterByCountriesChange = (selectedValues, reason, setFieldValue) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      // Dynamic dropdown options for Sites based on country selection
      if (selectedValues.find(option => option.id === 'select-all')) {
        setFieldValue('countries', countriesLookup.results)
        dispatch(fetchSitesByCountries(countriesLookup.results))
      } else {
        setFieldValue('countries', selectedValues)
        if (selectedValues.length > 0) {
          dispatch(fetchSitesByCountries(selectedValues))
        }
        if (reason === 'removeOption') {
          // Empty the selected values if any
          setFieldValue('siteProtocols', [])
        }
      }
    } else if (reason === 'clear') {
      setFieldValue('sites', [])
      setFieldValue('countries', [])
      dispatch(setSitesLookupByCountry({ results: [], isLoading: false }))
    }
  }

  return (
    <Formik
      initialValues={{ countries: [], sites: [], dataPoint: '', answerType: null }}
      enableReinitialize={false}
      validationSchema={validationSchema()}
      onSubmit={submitHandler}
    >
      {({ setFieldValue }) => (
        <StyledDiv>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteWithLabel
                  multiple={true}
                  hasSelectAllOption={true}
                  size="medium"
                  id="countries"
                  name="countries"
                  label="Countries"
                  options={countriesLookup.results}
                  isLoading={countriesLookup.isLoading}
                  onChange={(event, selectedValues, reason) => {
                    onFilterByCountriesChange(selectedValues, reason, setFieldValue)
                  }}
                  fetchOptions={() => dispatch(fetchCountriesLookup())}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteWithLabel
                  multiple={true}
                  hasSelectAllOption={true}
                  size="small"
                  id="sites"
                  name="sites"
                  label="Sites"
                  options={sitesLookupByCountry.results}
                  isLoading={sitesLookupByCountry.isLoading}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel size="medium" id="dataPoint" label="Data Point" name="dataPoint" type="text" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteWithLabel
                  id="answerType"
                  label="Answer Type"
                  name="answerType"
                  options={siteDataPointTypes.results}
                  isLoading={siteDataPointTypes.isLoading}
                  fetchOptions={() => dispatch(fetchSiteDataPointsType())}
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                  &ensp;&ensp;
                  <Button size="large" color="inherit" fullWidth={false} onClick={closeAddDataPointForm}>
                    Cancel
                  </Button>
                </ActionWrapper>
              </Grid>
            </Grid>
          </Form>
        </StyledDiv>
      )}
    </Formik>
  )
}

AddDataPointForm.propTypes = {
  closeAddDataPointForm: PropTypes.func,
  refreshDataPoints: PropTypes.func,
  siteId: PropTypes.string,
  siteProtocolId: PropTypes.number,
}

export default AddDataPointForm
