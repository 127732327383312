import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { Box, Grid, Skeleton } from '@mui/material'
import {
  saveSitePreScreenerAnswers,
  setSiteQnADisabled,
  setShowAddQuestionFormFor
} from 'store/ducks/patientRecord/participationProgress'
import { Button, FormMapper } from 'components/common'
import { Edit } from '@mui/icons-material'
import { questionType } from 'components/helper/constants/participationProgress'
import { Restriction } from 'permissions'

const ActionGrid = styled(Grid)(({ theme }) => ({
  '&&': {
    button: {
      marginRight: theme.spacing(2)
    }
  }
}))

const ButtonGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const AddQuestionGrid = styled(Grid)`
  text-align: end;
`

const SitePrescreener = () => {
  const dispatch = useDispatch()
  const { sitePreScreenerQnA, isSiteQnADisabled } = useSelector(
    store => store.participationProgress
  )

  return sitePreScreenerQnA.isLoading
    ? (
      <Box>
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
      </Box>
    )
    : (
      <>
        <Formik
          initialValues={sitePreScreenerQnA.initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(saveSitePreScreenerAnswers(values))
            setSubmitting(false)
          }}
        >
          {({ dirty, resetForm }) => (
            <Form>
              <Grid container spacing={2}>
                <Restriction ability="read" feature="additionalPrescreeningEditButton">
                  {
                    isSiteQnADisabled
                      ? (
                        <ButtonGrid item xs={12} sm={12} md={12} lg={12}>
                          <Button
                            color="secondary"
                            onClick={() => dispatch(setSiteQnADisabled(false))}
                            aria-label="Edit"
                            startIcon={<Edit />}
                            size="small"
                          >
                        Edit
                          </Button>
                        </ButtonGrid>
                      )
                      : ''
                  }
                </Restriction>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormMapper
                    questions={sitePreScreenerQnA.fieldsConfig.map(config => ({
                      ...config,
                      disabled: isSiteQnADisabled
                    }))}
                  />
                </Grid>
                {!isSiteQnADisabled && (
                  <ActionGrid item xs={12} sm={12} md={8} lg={8}>
                    <Button disabled={!dirty} color="primary" type="submit">
                    Save
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        dispatch(setSiteQnADisabled(true))
                        resetForm()
                      }}
                    >
                    Cancel
                    </Button>
                  </ActionGrid>
                )}
                {!isSiteQnADisabled && (
                  <AddQuestionGrid item xs={12} sm={12} md={4} lg={4}>
                    <Button
                      color="primary"
                      onClick={() => dispatch(setShowAddQuestionFormFor(questionType.site))}
                    >
                    Add Question
                    </Button>
                  </AddQuestionGrid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    )
}

export default SitePrescreener
