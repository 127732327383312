import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material'
import { PageHeader } from 'components/common'
import { TopNavigationBar } from 'components/common/navigation'
import { Outlet } from 'react-router-dom'
import { configurationTabs } from 'routes/navigationRoutes'

import { fetchHelperAttachmentModules } from 'store/ducks/common'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(5)
  }
}))

const Configuration = () => {
  const dispatch = useDispatch()
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)
  const { badgesPerModule } = useSelector(store => store.application)

  const fetchData = async () => {
    await dispatch(fetchHelperAttachmentModules())
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Configuration</Typography>}
        actionContent={<></>}
        expandContent={true}
        content={<TopNavigationBar buttons={configurationTabs} bar={true} />}
        bar={false}
      />
      <MainWrapper elevation={0}>
        {instanceConfig.isLoading || badgesPerModule.isLoading
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          )
          : (
            <Outlet />
          )}
      </MainWrapper>
    </>
  )
}

export default Configuration
