import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { AccordionGroup } from 'components/common'
import VisitPhaseDetails from './VisitPhaseDetails'

const VisitDetails = () => {
  const { visitScheduleDetails } = useSelector(store => store.patientMainWrapper)

  return (
    <>
      {Object.keys(visitScheduleDetails.results).map((phase, index) => (
        <AccordionGroup
          color="primary"
          labelComponent={true}
          contentBackground={'gray'}
          key={index}
          accordions={[
            {
              label: (
                <Typography variant="h5">
                  <b>{phase}</b>
                </Typography>
              ),
              component: <VisitPhaseDetails phaseDetails={visitScheduleDetails.results[phase]} />
            }
          ]}
        />
      ))}
    </>
  )
}

export default VisitDetails
