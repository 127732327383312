/* eslint-disable max-lines */
import { createAction, createReducer } from '@reduxjs/toolkit'
import { showError } from './application'
import { assignAlphabetKeys, displayDate, displayTime, exportToCSV } from 'components/helper/utility'
import { setLoading } from './userInteractions'
import { getConciergeScrum, getCrossInstancePermission, getRequestSummary } from 'services/crossInstanceReports'
import { conciergeScrumHeaders, requestSummaryHeaders } from 'components/helper/constants/crossInstanceReports'

export const setCrossInstancePermissions = createAction('crossInstanceReports/setCrossInstancePermissions')

export const fetchTCNArriveRequestSummaryAndDetails = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const requsetSummaryReport = await fetchRequestSummary()
    const conciergeScrumReport = await fetchConciergeScrum()
    const finalSheets = [
      {
        sheet: requsetSummaryReport.sheet,
        styles: requsetSummaryReport.styles,
        sheetName: requsetSummaryReport.title
      },
      {
        sheet: conciergeScrumReport.sheet,
        styles: conciergeScrumReport.styles,
        sheetName: conciergeScrumReport.title
      }
    ]

    exportToCSV(finalSheets, requsetSummaryReport.reportName)
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was issue while trying to fetch Cross Instance TCN Arrive Request Summary & Details Report', e))
  }
}

const fetchRequestSummary = async () => {
  const { data } = await getRequestSummary()
  const { crossInstanceReport } = data || {}
  const { reportHeaders, reportData } = crossInstanceReport || {}
  const { type, report, title, reportDate } = reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}
  const reportName = `${type}_${report}`

  const dateColumns = [requestSummaryHeaders.TCNCreatedDate, requestSummaryHeaders.RequestDate, requestSummaryHeaders.VisitDate, requestSummaryHeaders.ReqNotApprovedDate, requestSummaryHeaders.ReqApprovedDate, requestSummaryHeaders.ReqCompletedDate, requestSummaryHeaders.ReqCancelledDate]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const finalHeaders = {
    ...requestSummaryHeaders
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A3'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A5:${Object.keys(headers).pop()}5`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(headers)

  const mainTable = reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 6}:${Object.keys(rowItem).pop()}${index + 6}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    reportName,
    title
  }
  return finalReport
}

const fetchConciergeScrum = async () => {
  const { data } = await getConciergeScrum()
  const { crossInstanceReport } = data || {}
  const { reportHeaders, reportData } = crossInstanceReport || {}
  const { title, reportDate } = reportHeaders
  const rangeStylesObject = {}
  const columnStylesObject = {}

  const dateColumns = [conciergeScrumHeaders.RequestDate, conciergeScrumHeaders.VisitDate]

  // Each object will act as an row in Excel
  let finaleTables = [
    { A: 'TCN® Engage' },
    { A: title },
    {
      A: `${displayDate(reportDate)} ${displayTime(reportDate)}`
    }
  ]

  finaleTables.push('')

  const finalHeaders = {
    ...conciergeScrumHeaders
  }

  const headers = assignAlphabetKeys(finalHeaders)

  // Adding styles to each sheet
  rangeStylesObject['A1:A3'] = {
    bold: true,
    horizontalAlignment: 'left',
    fontFamily: 'Arial',
    fontSize: 10
  }

  // loop through the header keys of the main table and find the column that has `date` in the key
  Object.keys(headers).forEach(excelColumnAlphabet => {
    if (dateColumns.includes(headers[excelColumnAlphabet])) { // comparing with column label not column key
      columnStylesObject[excelColumnAlphabet] = { numberFormat: 'MM/dd/yyyy' }
    }
  })

  rangeStylesObject[`A5:${Object.keys(headers).pop()}5`] = {
    bold: true,
    horizontalAlignment: 'left',
    wrapText: true,
    fontFamily: 'Arial',
    fontSize: 10
  }
  finaleTables.push(headers)

  const mainTable = reportData.map((tableRow, index) => {
    const finalTableRow = {}

    Object.keys(finalHeaders).forEach(columnKey => {
      if (tableRow[columnKey] && dateColumns.includes(finalHeaders[columnKey])) { // comparing with column label not column key
        finalTableRow[columnKey] = new Date(tableRow[columnKey])
      } else {
        finalTableRow[columnKey] = tableRow[columnKey]
      }
    })
    const rowItem = assignAlphabetKeys(assignAlphabetKeys(finalTableRow))

    rangeStylesObject[
      `${Object.keys(rowItem)[0]}${index + 6}:${Object.keys(rowItem).pop()}${index + 6}`
    ] = {
      wrapText: true,
      fontFamily: 'Arial',
      fontSize: 10
    }
    return rowItem
  })
  finaleTables = finaleTables.concat(mainTable)

  const finalReport = {
    sheet: finaleTables,
    styles: { rangeStyles: rangeStylesObject, columnStyles: columnStylesObject },
    title
  }

  return finalReport
}

export const getCrossInstancePermissions = () => async dispatch => {
  try {
    const { data } = await getCrossInstancePermission()
    await dispatch(setCrossInstancePermissions(data))
  } catch (e) {
    dispatch(
      showError(
        'There was some error while trying to fetch cross instance reports permissions.',
        e
      )
    )
    dispatch(setCrossInstancePermissions({}))
  }
}

const initialState = {
  crossInstancePermissions: {}
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setCrossInstancePermissions, (state, action) => {
      state.crossInstancePermissions = action.payload
    })
})
