import { createAction, createReducer } from '@reduxjs/toolkit'

import { getInstanceConfigDetails } from 'services/configuration/instanceConfiguration'
import { showError } from '../application'
import { setLoading } from '../userInteractions'
import { copyObject } from 'components/helper/utility'

export const setInstanceConfig = createAction('instanceConfiguration/setInstanceConfig')
export const resetInstanceConfiguration = createAction('instanceConfiguration/resetInstanceConfiguration')

export const fetchInstanceConfigDetails = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { selectedInstance } = getState().auth
    const { instanceConfig } = getState().instanceConfiguration
    dispatch(setInstanceConfig({ isLoading: true }))
    if (selectedInstance) {
      const response = await getInstanceConfigDetails()
      const newResults = { ...instanceConfig.results }
      response.forEach(configuration => {
        newResults[configuration.configKey] = configuration.configValue
      })

      dispatch(setInstanceConfig({ results: { ...newResults }, isLoading: false }))
    }
  } catch (e) {
    dispatch(setInstanceConfig({ isLoading: false }))
    dispatch(showError('There was some error while fetching campaign details. Please try again later.', e))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  instanceConfig: {
    isLoading: true,
    results: {
      IVRIntegration: 'false',
      IsCohortAssociation: 'false',
      CardVendor: ''
    }
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setInstanceConfig, (state, action) => {
      state.instanceConfig = { ...state.instanceConfig, ...action.payload }
    })
    .addCase(resetInstanceConfiguration, state => {
      copyObject(state, initialState)
    })
})
