import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { FormControlLabel, Switch, FormHelperText, FormControl } from '@mui/material'
import SwitchRightIcon from '@mui/icons-material/SwitchRightRounded'
import SwitchLeftIcon from '@mui/icons-material/SwitchLeftRounded'
import styled from '@emotion/styled'

const FormControlWrapper = styled(FormControl)`
  label {
    height: 80%;
    align-items: flex-end;
    display: flex;
    margin-left: 0px;
  }
`
const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    align-self: center;
  }
`

const StyledSwitch = styled(props => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }),
)

const CustomSwitch = props => {
  const {
    id,
    label,
    formik,
    onChange,
    name,
    checked,
    icon,
    checkedIcon,
    color,
    disabled,
    disableRipple,
    inputProps,
    inputRef,
    required,
    size,
    value,
    onBlur,
    helperText,
    error,
    disableTypographyForLabel,
    fullWidth,
    defaultChecked,
  } = props

  let field, meta, setValue

  const onFieldValueChange = event => {
    setValue(event.target.checked)
  }

  if (formik === 'true') {
    [field, meta, { setValue }] = useField(props)
    field = { ...field, onChange: onChange || onFieldValueChange }
  } else {
    field = {
      onChange,
      onBlur,
      value,
    }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <FormControlWrapper fullWidth={fullWidth} required={required}>
      <StyledFormControlLabel
        control={
          <StyledSwitch
            checked={checked || field.value}
            name={name}
            id={id}
            color={color}
            icon={icon}
            checkedIcon={checkedIcon}
            defaultChecked={defaultChecked}
            disabled={disabled}
            disableRipple={disableRipple}
            inputProps={inputProps}
            inputRef={inputRef}
            required={required}
            size={size}
            {...field}
          />
        }
        label={required && !disableTypographyForLabel ? `${label} *` : label}
        disableTypography={disableTypographyForLabel}
      />
      <FormHelperText component="div" error={isError}>
        {errorText}
      </FormHelperText>
    </FormControlWrapper>
  )
}

CustomSwitch.propTypes = {
  /**
   * The label content. Note, this is of type node that means you must pass this as React element ex: Typography
   * The reason this is defined as node is that the label can be a combination of text and some icon.
   */
  label: PropTypes.node.isRequired,
  /**
   * Use this when you want to pass your custom label.
   * when true, whatever is passed for label props, will be shown as is. Meaning, without a Typography/p-tag. Also, there will not be any indication, that this field is required.
   * Whereas in case of `false` and if this component is required, now when user tries to submit the form without selecting this checkbox, the label will turn to red.
   * @default false
   */
  disableTypographyForLabel: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * If `true`, the component will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * Implement onBlur using this props.
   */
  onBlur: PropTypes.func,

  /**
   * If `true`, the component is checked.
   */
  checked: PropTypes.bool,
  /**
   * The default checked state. Use when the component is not controlled.
   */
  defaultChecked: PropTypes.bool,
  /**
   * The icon to display when the component is true/Yes.
   * @default <SwitchRightIcon />
   */
  checkedIcon: PropTypes.node,

  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning', 'default']),

  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the ripple effect is disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * The icon to display when the component is false/No.
   * @default <SwitchLeftIcon />
   */
  icon: PropTypes.node,
  /**
   * The id of the `input` element.
   */
  id: PropTypes.string,
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps: PropTypes.object,
  /**
   * Pass a ref to the `input` element.
   */
  inputRef: PropTypes.string,
  /**
   * onChange is an optional props, can be used to perform other tasks beside just value change of this particular element
   * Callback fired when the state is changed.
   * @param {React.ChangeEvent<HTMLInputElement>} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the `component` is required.
   */
  required: PropTypes.bool,
  /**
   * The size of the component.
   * `small` is equivalent to the dense checkbox styling.
   * @default 'medium'
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * The value of the component. The DOM API casts this to a string.
   * The browser uses "on" as the default value.
   */
  value: PropTypes.any,
}

CustomSwitch.defaultProps = {
  formik: 'true',
  color: 'primary',
  size: 'medium',
  error: false,
  fullWidth: false,
  required: false,
  disableTypographyForLabel: false,
  icon: <SwitchLeftIcon />,
  checkedIcon: <SwitchRightIcon />,
}

export default CustomSwitch
