export const communicationTabIDs = {
  conciergeMsgsWithSites: 'conciergeMsgsWithSites',
  conciergeMsgsWithPatients: 'conciergeMsgsWithPatients',
  conciergeMsgsForPatients: 'patientConciergeMsgs',
  announcementTab: 'announcementTab',
  announcementsForPatients: 'announcementsForPatients',
}

export const communicationListType = {
  conciergeMsgsWithSites: [1, 2, 4],
  conciergeMsgsWithPatients: [3, 4],
  patientConciergeMsgs: [3, 4],
  announcementTab: [5],
  announcementsForPatients: [5],
}

export const messageSendCategoryIDs = {
  allActiveUsers: 1,
  individualUsers: 2,
  patient: 3,
  bbk: 4,
}

export const announcementSendCategoryIDs = {
  allActiveUsers: 4,
  vendors: 3,
  sites: 2,
  patients: 1,
}

export const sendToOptionsFor = {
  announcementTab: 2,
  announcementsForPatients: 2,
  conciergeMsgsWithSites: 3,
  conciergeMsgsWithPatients: 3,
  patientConciergeMsgs: 3,
}

export const recipientsDetailsCodes = {
  patientsByCountry: 2,
  patientsBySite: 3,
  patientsByCohortArm: 4,
  patientsByProgram: 5,
  sitesByCountry: 7,
  sitesByProgram: 8,
  specificVendors: 10,
}
