import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { fetchTcnUsersLookup, showError, showSuccess } from '../application'
import { copyObject, removeTime } from 'components/helper/utility'

import {
  addRemoteSupportDeliveryServiceRequest,
  getDeliveryServicesDetails
} from 'services/rsgArrive'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import { setOpenSubmitSuccess, setStartNewRequestForVisitFlow } from './requestDetails'
import { fetchAddressLookup, fetchSiteAddresses } from '../siteManagement/siteManagement'
import { fetchPatientAddresses } from './inPersonRequest'
import { fetchCountriesLookup } from '../common'

export const setDeliveryServicesDetailsForm = createAction(
  'deliveryServices/setDeliveryServicesDetailsForm'
)
export const resetDeliveryServices = createAction('deliveryServices/resetDeliveryServices')

export const setupDeliveryServicesForm = () => async (dispatch, getState) => {
  try {
    const { patientInformation } = getState().patientMainWrapper
    await dispatch(fetchAddressLookup())
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchTcnUsersLookup())
    if (patientInformation.siteClientId) {
      await dispatch(fetchSiteAddresses(patientInformation.siteClientId))
    }
    await dispatch(fetchPatientAddresses(patientInformation.userId))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
}

export const fetchDeliveryServicesDetails = requestId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const res = await getDeliveryServicesDetails(requestId)
    const { deliveryServicesDetailsForm } = getState().deliveryServices
    let formattedDeliveryServicesDetails = { ...deliveryServicesDetailsForm }
    formattedDeliveryServicesDetails = {
      ...formattedDeliveryServicesDetails,
      visitNames: res.visitName,
      visitDate: res.visitDate,
      pickupAddressType: res.pickupAddressType,
      pickupAddress: {
        ...res.pickupLocation,
        addressId: res.pickupLocation.addressId ? res.pickupLocation.addressId : 0
      },
      contactName: res.pickupLocation?.attentionOfContactUserName,
      contactPhone: res.pickupLocation?.phone,
      deliveryItems: res.pickupItems,
      pickupDate: res.pickupDate,
      pickupTime: res.pickupTime,
      dropOffAddress: {
        ...res.dropoffLocation,
        addressId: res.dropoffLocation.addressId ? res.dropoffLocation.addressId : 0
      },
      dropOffAddressType: res.dropoffAddressType,
      dropOffContactName: res.dropoffLocation?.attentionOfContactUserName,
      dropOffContactPhone: res.dropoffLocation?.phone,
      dropOffInstructions: res.dropoffInstructions,
      dropOffDate: res.dropoffDate,
      dropOffTime: res.dropoffTime,
      reachDateTime: res.bestTimeToReach || '',
      reachPhoneNumber: res.bestPhoneNumber || ''
    }

    dispatch(setDeliveryServicesDetailsForm(formattedDeliveryServicesDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

const populateAddressFormat = (address, contactName, contactPhone) => ({
  id: address.addressId,
  luAddressTypeId: address.addressType?.id,
  contactName,
  address1: address.address1,
  address2: address.address2,
  address3: '',
  city: address.city,
  luStateId: address.state.id,
  zipPostalCode: address.zipCode,
  luCountryId: address.country.id,
  phone: contactPhone,
  fax: '',
  emailAddress: address.email,
  isVisible: true
})

export const submitDeliveryServicesRequest =
  (data, parentRequestId) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { patientInformation } = getState().patientMainWrapper
      const requestData = {
        patientId: patientInformation.userId,
        visitNameId: data.visitNames.id,
        visitDate: removeTime(data.visitDate),
        deliveryItems: data.deliveryItems,
        pickupDate: removeTime(data.pickupDate),
        pickupTime: data.pickupTime,
        pickupAddressType: data.pickupAddressType,
        pickupAddress: populateAddressFormat(
          data.pickupAddress,
          data.contactName,
          data.contactPhone
        ),
        dropOffDate: removeTime(data.dropOffDate),
        dropOffTime: data.dropOffTime,
        dropOffAddressType: data.dropOffAddressType,
        dropOffAddress: populateAddressFormat(
          data.dropOffAddress,
          data.dropOffContactName,
          data.dropOffContactPhone
        ),
        dropOffInstructions: data.dropOffInstructions,
        bestTimeToReach: data.reachDateTime,
        bestPhoneNumber: data.reachPhoneNumber,
        ...(!!parentRequestId && {
          parentRequestId
        })
      }

      await addRemoteSupportDeliveryServiceRequest(requestData)
      await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
      dispatch(setOpenSubmitSuccess(true))
      // Save data for StartNewRequestForVisitFlow,
      // in case user selects Yes in SubmitSuccess Confirmation box
      // This will be cleared if user selects No.
      dispatch(
        setStartNewRequestForVisitFlow({
          visitDate: removeTime(data.visitDate),
          visitNames: data.visitNames
        })
      )
      dispatch(showSuccess('Request submitted successfully!'))
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    }
    dispatch(setLoading(false))
  }

const initialState = {
  deliveryServicesDetailsForm: {
    visitNames: null,
    visitDate: '',
    pickupAddressType: '',
    pickupAddress: '',
    contactName: '',
    contactPhone: '',
    deliveryItems: '',
    pickupDate: '',
    pickupTime: '',
    dropOffAddress: '',
    dropOffAddressType: '',
    dropOffContactName: '',
    dropOffContactPhone: '',
    dropOffInstructions: '',
    dropOffDate: '',
    dropOffTime: '',
    reachDateTime: '',
    reachPhoneNumber: ''
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setDeliveryServicesDetailsForm, (state, action) => {
      state.deliveryServicesDetailsForm = action.payload
    })
    .addCase(resetDeliveryServices, state => {
      copyObject(state, initialState)
    })
})
