import { createAction, createReducer } from '@reduxjs/toolkit'

import { addStudyLink } from 'services/configuration/resourceCenter'
import { showError, showSuccess } from '../application'
import { setLoading } from '../userInteractions'
import { copyObject } from 'components/helper/utility'

export const setStudyLinkInitialValues = createAction('resourceCenter/setStudyLinkInitialValues')
export const resetResourceCenterConfiguration = createAction('protocolCampaignConfiguration/resetResourceCenterConfiguration')

export const saveStudyLink = data => async dispatch => {
  dispatch(setLoading(true))
  try {
    await addStudyLink(data)
    dispatch(showSuccess('Study Link saved successfully!'))
  } catch {
    dispatch(showError('Unable to add new Study Link at this time. Please try again.'))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  studyLinkInitialValues: {
    studyLink: '',
    displayName: '',
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setStudyLinkInitialValues, (state, action) => {
      state.studyLinkInitialValues = { ...action.payload }
    })
    .addCase(resetResourceCenterConfiguration, state => {
      copyObject(state, initialState)
    })
})
