import pdfIcon from 'assets/images/pdfIcon.png'
import videoIcon from 'assets/images/videoIcon.png'
import defaultIcon from 'assets/images/defaultIcon.png'

export const resourceTypes = {
  zipCompressed: 'application/x-zip-compressed',
  jpeg: 'image/jpeg',
  presentation: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  pdf: 'application/pdf',
  zip: 'application/zip',
  octetStream: 'application/octet-stream',
  spreadSheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  wordDocument: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png: 'image/png',
  mp4: 'video/mp4',
}

/**
 * Function to return Resource Icon based on File Type
 * Note: We do not have all icons, so handling only few.
 * And returning default blank icon for others.
 */
export const fetchResourceIcon = resourceType => {
  let resourceIcon
  switch (resourceType) {
    case resourceTypes.pdf:
      resourceIcon = pdfIcon
      break
    case resourceTypes.mp4:
      resourceIcon = videoIcon
      break
    default:
      resourceIcon = defaultIcon
      break
  }
  return resourceIcon
}
