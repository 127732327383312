import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Box, Grid, Skeleton } from '@mui/material'
import VisitDetails from './VisitDetails'
import Information from './Information'
import HelloBanner from './HelloBanner'
import { Restriction } from 'permissions'
import { AutoCompleteWithLabel } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchVisitScheduleDetails,
  setVisitScheduleDetails
} from 'store/ducks/patientRecord/patientMainWrapper'

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.gray.light};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  padding-top: ${({ theme }) => theme.spacing(2)};
`

const PatientDashboard = () => {
  const { patientInformation, visitGuidelineGroup, visitScheduleDetails } = useSelector(
    store => store.patientMainWrapper
  )
  const dispatch = useDispatch()
  const [selectedVisitSchedule, setSelectedVisitSchedule] = useState(null)

  useEffect(() => {
    if (patientInformation.userId && visitGuidelineGroup.results.length > 1) {
      onVisitScheduleChange(visitGuidelineGroup.results[0], 'selectOption')
    }
  }, [patientInformation.userId, visitGuidelineGroup])

  const onVisitScheduleChange = (selectedValue, reason) => {
    if (reason === 'selectOption') {
      dispatch(fetchVisitScheduleDetails(patientInformation.userId, selectedValue.id))
      setSelectedVisitSchedule(selectedValue)
    } else if (reason === 'clear') {
      dispatch(setVisitScheduleDetails({ results: {} }))
      setSelectedVisitSchedule(null)
    }
  }

  return (
    <>
      <Grid container spacing={2}></Grid>
      <MainWrapper>
        <Grid container spacing={2} style={{ maxWidth: '1486px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MainWrapper>
              <HelloBanner />
            </MainWrapper>
          </Grid>
          {visitGuidelineGroup.results.length > 1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AutoCompleteWithLabel
                id="visitGuidelineGroup"
                label="Please Select"
                name="visitGuidelineGroup"
                onChange={(event, selectedValue, reason) => {
                  onVisitScheduleChange(selectedValue, reason)
                }}
                options={visitGuidelineGroup.results}
                isLoading={visitGuidelineGroup.isLoading}
                size="small"
                multiple={false}
                formik="false"
                hasSelectAllOption={false}
                selectedValue={selectedVisitSchedule}
              />
            </Grid>
          )}

          {visitScheduleDetails.isLoading
            ? (
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            )
            : (
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Restriction ability="read" feature="dashboardVisitScheduleAndAssessments">
                  {Object.keys(visitScheduleDetails.results).length > 0 ? <VisitDetails /> : ''}
                </Restriction>
              </Grid>
            )}
          <Restriction ability="read" feature="dashboardVisitInformation">
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Information />
            </Grid>
          </Restriction>
        </Grid>
      </MainWrapper>
    </>
  )
}

export default PatientDashboard
