import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Typography, Paper, Grid } from '@mui/material'
import AccordionGroup from 'components/common/dataDisplay/AccordionGroup'
import { requestStatusId as requestStatusIdConstant } from 'components/helper/constants/visitServices'

import RequestGrid from './RequestGrid'
import { Restriction } from 'permissions'
import { Button, PageHeader } from 'components/common'
import { Download } from '@mui/icons-material'
import {
  fetchAllRequests,
  fetchArriveHelperAttachments
} from 'store/ducks/visitServices/requestDetails'
import { downloadHelperAttachment } from 'store/ducks/common'
import QuickView from 'components/common/QuickView'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(5)
  }
}))

const InProcessGridContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`
const StyledLink = styled(Link)`
  text-decoration: unset;
`

const ConfirmationActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const VisitServicesDashboard = () => {
  const { patientId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { arriveHelperAttachments } = useSelector(store => store.requestDetails)
  const { helperAttachmentModules } = useSelector(store => store.common)
  const { selectedInstance } = useSelector(store => store.auth)
  const { instanceId } = selectedInstance
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const [completePatientProfileModal, setCompletePatientProfileModal] = useState(false)

  useEffect(() => {
    dispatch(fetchAllRequests(patientId))
  }, [])

  useEffect(() => {
    if (helperAttachmentModules.results.length) {
      dispatch(fetchArriveHelperAttachments())
    }
  }, [helperAttachmentModules.results])

  const onMakeARequest = () => {
    const requiredFields = [
      'dateOfBirth',
      'address1',
      'city',
      'zipCode',
      'phoneNumber',
      'country',
      'state'
    ]

    const isEmpty = requiredFields.some(field => {
      if (field === 'country' || field === 'state') {
        return !patientInformation[field]?.id
      } else {
        return !patientInformation[field]
      }
    })

    if (isEmpty) {
      setCompletePatientProfileModal(true)
    } else {
      navigate('../makearequest')
    }
  }

  return (
    <>
      <MainWrapper>
        <PageHeader
          title={<Typography variant="h4">TCN Arrive</Typography>}
          expandContent
          actionContent={
            <Grid container spacing={2}>
              <Restriction ability="read" feature="arriveGuidelinesButton">
                <>
                  {arriveHelperAttachments.results.length
                    ? (
                      <Grid item>
                        <Button
                          startIcon={<Download />}
                          color="inherit"
                          onClick={() =>
                            dispatch(downloadHelperAttachment(arriveHelperAttachments.results[0]))
                          }
                        >
                          <Typography variant="body1"> Arrive Guidelines</Typography>
                        </Button>
                      </Grid>
                    )
                    : null}
                </>
              </Restriction>
              <Restriction ability="read" feature="tcnArriveUserGuide">
                <Grid item>
                  <StyledLink
                    to={`/instance/${instanceId}/resource-center`}
                    state={{ expandAccordion: 'userGuides' }}
                  >
                    <Button size="large" color="primary">
                      TCN Arrive User Guide
                    </Button>
                  </StyledLink>
                </Grid>
              </Restriction>
              <Restriction ability="read" feature="arriveMakeRequestButton">
                <Grid item>
                  <Button size="large" color="primary" onClick={onMakeARequest}>
                    Make A Request
                  </Button>
                </Grid>
              </Restriction>
            </Grid>
          }
          content={
            <>
              <AccordionGroup
                accordions={[
                  {
                    label: 'In Process',
                    component: (
                      <>
                        <InProcessGridContainer>
                          <RequestGrid
                            /**
                             * After we receive specs for InProcessNotApproved Grid,
                             * this prop will revert to being a number instead of array.
                             * As there is logic to show hide some columns for requestId.
                             */
                            requestStatusIds={[
                              requestStatusIdConstant.InProcess,
                              requestStatusIdConstant.InProcessNotApproved
                            ]}
                          />
                        </InProcessGridContainer>
                        <RequestGrid
                          requestStatusIds={[requestStatusIdConstant.InProcessApproved]}
                          gridTitle="In Process - Approved"
                        />
                      </>
                    )
                  }
                ]}
                gradient={true}
                color="gradientBlue"
                transitionProps={{ unmountOnExit: true }}
                wrapperStyles={{ marginTop: '16px !important' }}
              />
              <AccordionGroup
                accordions={[
                  {
                    label: 'Completed',
                    component: (
                      <RequestGrid requestStatusIds={[requestStatusIdConstant.Completed]} />
                    )
                  }
                ]}
                gradient={true}
                color="gradientBlue"
                transitionProps={{ unmountOnExit: true }}
                defaultOpenPanel={-1}
              />
              <AccordionGroup
                accordions={[
                  {
                    label: 'Cancelled',
                    component: (
                      <RequestGrid requestStatusIds={[requestStatusIdConstant.Cancelled]} />
                    )
                  }
                ]}
                gradient={true}
                color="gradientBlue"
                transitionProps={{ unmountOnExit: true }}
                defaultOpenPanel={-1}
              />
            </>
          }
          bar={false}
        />
      </MainWrapper>
      <QuickView
        title="Action Required"
        onClose={() => setCompletePatientProfileModal(false)}
        contentText="Before making a request, please complete the patient&apos;s profile."
        isDialogOpen={!!completePatientProfileModal}
        maxWidth="sm"
        dialogActions={
          <ConfirmationActionsWrapper>
            <Button
              color="primary"
              size="medium"
              onClick={() => {
                navigate(`/instance/${instanceId}/patient-dashboard/${patientId}/patientProfile`)
              }}
            >
              Patient Profile
            </Button>
          </ConfirmationActionsWrapper>
        }
      />
    </>
  )
}

export default VisitServicesDashboard
