import { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Typography, Grid } from '@mui/material'
import { Button, Bar } from 'components/common'

const StyledTypography = styled(Typography)(
  ({ theme }) => `
    padding-bottom: ${theme.spacing(2)};
`
)
const CenterAlignGrid = styled(Grid)(
  () => `
  width: 100%;
  text-align: center;
`
)
const StyledButton = styled(Button)(
  ({ theme }) => `
    margin: ${theme.spacing(1)};
`
)

const SubmitConfirmationSuccess = ({
  onClose,
  startNewRequestForSameVisit,
  startNewRequestForDifferentVisit
}) => {
  const [newRequestConfirmation, setNewRequestConfirmation] = useState(false)

  return (
    <Grid container spacing={2} style={{ paddingLeft: '20px' }}>
      <CenterAlignGrid item xs={12} sm={12} md={12} lg={12}>
        <StyledTypography variant="body2" gutterBottom>
          Thank you. We will begin working on your request.
        </StyledTypography>
        <Typography variant="body1" gutterBottom>
          If you need to review your request, you can find it under In Process. If we have any
          questions, we will contact you.
        </Typography>
      </CenterAlignGrid>
      <CenterAlignGrid item xs={12} sm={12} md={12} lg={12}>
        <Bar color="gray" fullWidth={true} />
      </CenterAlignGrid>
      <CenterAlignGrid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="body2">
          Do you want to make another request for this patient?
        </Typography>
      </CenterAlignGrid>

      <CenterAlignGrid item xs={12} sm={12} md={12} lg={12}>
        <StyledButton
          label="Yes"
          size="medium"
          fullWidth={false}
          color="primary"
          onClick={() => setNewRequestConfirmation(true)}
        >
          Yes
        </StyledButton>
        <StyledButton size="medium" fullWidth={false} color="inherit" onClick={onClose}>
          No
        </StyledButton>
      </CenterAlignGrid>

      {newRequestConfirmation && (
        <>
          <CenterAlignGrid item xs={12} sm={12} md={12} lg={12}>
            <Bar color="gray" fullWidth={true} />
          </CenterAlignGrid>
          <CenterAlignGrid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body2">
              Is this new request for the same visit or a different visit?
            </Typography>
          </CenterAlignGrid>
          <CenterAlignGrid item xs={12} sm={12} md={12} lg={12}>
            <StyledButton
              label="Yes"
              size="medium"
              fullWidth={false}
              color="primary"
              onClick={startNewRequestForSameVisit}
            >
              Same Visit
            </StyledButton>
            <StyledButton
              size="medium"
              fullWidth={false}
              color="inherit"
              onClick={startNewRequestForDifferentVisit}
            >
              Different Visit
            </StyledButton>
          </CenterAlignGrid>
        </>
      )}
    </Grid>
  )
}

SubmitConfirmationSuccess.propTypes = {
  onClose: PropTypes.func,
  startNewRequestForSameVisit: PropTypes.func,
  startNewRequestForDifferentVisit: PropTypes.func
}

export default SubmitConfirmationSuccess
