import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import {
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material'

import { setVisitComplete } from 'store/ducks/patientRecord/patientMainWrapper'
import { Circle } from '@mui/icons-material'

const ListWrapper = styled(List)`
  display: flex;
`

const ListItemWrapper = styled.div`
  flex: 1;
`

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  '&&': {
    minWidth: '20px',
    alignSelf: 'flex-start',
    marginTop: '9px',
    svg: {
      fontSize: '10px'
    }
  }
}))
const CustomCard = styled(Card)`
  && {
    maxwidth: 200;
    box-shadow: none;
    margin: 16px;
    .MuiCardHeader-root {
      padding: 16px 16px 16px 16px;
      background-image: linear-gradient(
        90deg,
        ${({ theme }) => theme.palette.customBlue.main} 70px,
        #dddddd 0%
      );
      min-height: 66px;
      align-items: start;
    }
    .MuiCardHeader-content {
      align-items: start;
    }
    .MuiCardContent-root {
      margin: 0px 0px 0px ${({ theme }) => theme.spacing(3)};
    }
    .MuiCardHeader-title {
      color: ${({ theme }) => theme.palette.white.shade};
      font-weight: 100;
      flex: 1;
      display: flex;
      justify-content: left;
      transform: translateX(10px);
      align-self: center;
      .MuiCheckbox-root {
        color: ${({ theme }) => theme.palette.white.shade};
        align-self: start;
      }
    }
  }
`
const CustomFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0px;
      padding-left: ${({ theme }) => theme.spacing(3.8)};
      color: ${({ theme }) => theme.palette.secondary.contrastText};
    }
  }
`

const VisitScheduleDetail = ({ visitSchedule }) => {
  const { patientId } = useParams()
  const dispatch = useDispatch()

  const handleChange = event => {
    dispatch(setVisitComplete(visitSchedule, event.target.checked, patientId))
  }

  const visitAssessment = visitSchedule.visitAssesment || []
  const middleIndex = Math.ceil(visitAssessment.length / 2)
  const leftColumn = visitAssessment.slice(0, middleIndex)
  const rightColumn = visitAssessment.slice(middleIndex)

  return (
    <>
      <CustomCard>
        <CardHeader
          title={
            <CustomFormControlLabel
              control={
                <Checkbox
                  checked={visitSchedule.isVisitComplete}
                  name={visitSchedule.visitTitle}
                  aria-label={visitSchedule.visitTitle}
                  data-testid={visitSchedule.visitTitle}
                  onChange={event => handleChange(event)}
                  color="secondary"
                />
              }
              label={<b>{visitSchedule.visitTitle}</b>}
            />
          }
        />
        <CardContent>
          <ListWrapper dense={false} style={{ display: 'flex' }}>
            <ListItemWrapper>
              {leftColumn.map((assessment, idx) => (
                <ListItem key={idx}>
                  <StyledListItemIcon>
                    <Circle size="small" />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={assessment}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
              ))}
            </ListItemWrapper>
            <ListItemWrapper>
              {rightColumn.map((assessment, idx) => (
                <ListItem key={idx + middleIndex}>
                  <StyledListItemIcon>
                    <Circle size="small" />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={assessment}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
              ))}
            </ListItemWrapper>
          </ListWrapper>
        </CardContent>
      </CustomCard>
    </>
  )
}

VisitScheduleDetail.propTypes = {
  visitSchedule: PropTypes.object
}

export default VisitScheduleDetail
