import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/storeConfig'
import axios from './axios-instance'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles'
import { muiTheme } from './styles/baseTheme'
import { CssBaseline } from '@mui/material'
import '@fontsource/roboto'

// set authorization headers, to log responses or log errors globally
// axios.defaults.baseURL="https://jsonplaceholder.typicode.com";
axios.defaults.headers.common.Authorization = 'AUTH TOKEN'
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(
  request => request,
  error => Promise.reject(error),
)

axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
)

const app = (
  <BrowserRouter>
    <ThemeProvider theme={responsiveFontSizes(muiTheme)}>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
)

const root = createRoot(document.getElementById('root'))
root.render(app)

registerServiceWorker()
