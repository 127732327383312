import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import AccordionGroup from 'components/common/dataDisplay/AccordionGroup'
import PatientSupport from './patientSupport/PatientSupport'
import InsightsList from './conciergeInsights/InsightsList'
import { Button, Card } from 'components/common'
import QuickView from 'components/common/QuickView'
import { useDispatch, useSelector } from 'react-redux'
import {
  setUserAccountUpdatedModal,
  updatePatientViewed
} from 'store/ducks/patientRecord/patientMainWrapper'
import EditPatientEmailAddress from './editPatientInfo/EditPatientEmailAddress'
import EditPatientContactInfo from './editPatientInfo/EditPatientContactInfo'
// import EditSupplementalInfo from './common/EditSupplementalInfo'
import { useRestriction } from 'permissions'

const PaddedTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1)
}))

const Container = styled.div(() => ({
  padding: '2% 0',
  textAlign: 'center',
  width: '100%'
}))

const ContentWrapper = styled.div`
  width: 100%;
`

const patientProfileAccordions = () => [
  {
    id: 'personalInformation',
    label: 'Personal Information',
    component: (
      <>
        <EditPatientEmailAddress />
        <EditPatientContactInfo />
        {/* <EditSupplementalInfo userId={userId} userSupportPersonId={null} /> */}
      </>
    )
  },
  {
    id: 'supportPersonInformation',
    label: 'LAR / Caregiver / Companion Information',
    component: <PatientSupport />
  },
  {
    id: 'conciergeProfileNotes',
    label: 'Concierge Notes',
    component: <InsightsList />
  }
]

const PatientProfile = () => {
  const dispatch = useDispatch()
  const { userAccountUpdatedModal, patientInformation } = useSelector(
    store => store.patientMainWrapper
  )
  const { loggedInAsSite } = useSelector(store => store.auth)

  useEffect(() => {
    if (loggedInAsSite && patientInformation.userId) {
      dispatch(updatePatientViewed(patientInformation.userId))
    }
  }, [patientInformation.userId])

  /**
   * Add patient accordions only if Read access
   */
  const updatedPatientProfileAccordions = patientProfileAccordions(
    patientInformation.userId
  ).filter(tab => {
    if (tab.id === 'conciergeProfileNotes') {
      return useRestriction('read', 'conciergeProfileNotes')
    } else if (tab.id === 'supportPersonInformation') {
      return useRestriction('read', 'patientProfileCompanionInformation')
    } else if (tab.id === 'personalInformation') {
      return useRestriction('read', 'patientProfileGeneralInformation')
    } else {
      return true
    }
  })

  return (
    <>
      <Card background="secondary" radius={false}>
        <Container>
          {updatedPatientProfileAccordions.length > 0
            ? (
              <PaddedTypography variant="body2" gutterBottom>
              It is important that the information provided below matches government issued
              identification (e.g., passports, drivers license).
              </PaddedTypography>
            )
            : (
              <PaddedTypography variant="body2">
              You do not have access to any of the patient information. Please contact Admin.
              </PaddedTypography>
            )}
        </Container>

        <ContentWrapper>
          <AccordionGroup
            accordions={updatedPatientProfileAccordions}
            gradient={true}
            color="gradientBlue"
            transitionProps={{ unmountOnExit: true }}
            defaultOpenPanel={updatedPatientProfileAccordions.length > 1 ? 0 : -1}
          />
        </ContentWrapper>
      </Card>
      <QuickView
        title="Details Edited Successfully"
        onClose={() => dispatch(setUserAccountUpdatedModal(false))}
        dialogContent={
          <Typography variant="body1">
            The participant&apos;s TCN Card account has also been updated to reflect these changes.
          </Typography>
        }
        isDialogOpen={userAccountUpdatedModal}
        dialogActions={
          <Button
            color="primary"
            onClick={() => dispatch(setUserAccountUpdatedModal(false))}
            size="large"
          >
            Ok
          </Button>
        }
        alignActions="flex-start"
        maxWidth="sm"
      />
    </>
  )
}

export default PatientProfile
