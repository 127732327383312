import { backendAxios, fetchWithRetries, getConfig, getConfigForFileUpload } from 'services/backend'

export const createStudyMessage = payload =>
  backendAxios
    .post('/studymessage', payload, getConfigForFileUpload())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const updateStudyMessage = payload =>
  backendAxios
    .put('/studymessage', payload, getConfigForFileUpload())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const replyToStudyMessage = payload =>
  backendAxios
    .post('/studymessage/userstudymessage', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getStudyMessages = filters => fetchWithRetries('POST', '/studyMessage/list', getConfig(), { ...filters })

export const getDistributionList = studyMessageId =>
  backendAxios
    .get(`/studymessage/${studyMessageId}/distribution`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getStudyMessageByID = id =>
  backendAxios
    .get(`/studyMessage/${id}`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getUserStudyMessageByID = id =>
  backendAxios
    .get(`/studyMessage/userstudymessage/${id}`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getUsersBySite = siteProtocolIds =>
  backendAxios
    .get('/user/list/sites/', getConfig({ siteProtocolIds }))
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const updateUserStudyMessageProperty = (userStudyMessageId, property, newValue) => {
  const payload = {
    userStudyMessageId,
    document: [
      {
        op: 'replace', // operation
        path: property,
        value: newValue
      }
    ]
  }

  return backendAxios
    .patch('/studymessage/userstudymessage', payload, getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))
}

export const updateStudyMessageProperty = (studyMessageId, property, newValue) => {
  const payload = {
    studyMessageId,
    document: [
      {
        op: 'replace',
        path: property,
        value: newValue
      }
    ]
  }

  return backendAxios
    .patch('/studymessage', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

export const flagUserStudyMessage = payload =>
  backendAxios
    .post('/studymessage/userstudymessage/flag', payload, getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getSystemIDsByCommunicationTab = (isWithSites, isWithPatients) =>
  backendAxios
    .get(`/user/systemids/${isWithSites}/${isWithPatients}`, getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))
