import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import { saveConfigPrescreenerQuestion, initialValues, fetchSitesByCountries } from 'store/ducks/configuration/participationProgressConfiguration'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import { questionType as questionTypeConstant } from 'components/helper/constants/participationProgress'

import { Button } from 'components/common'
import { TextBoxOuterLabel, AutoCompleteWithLabel } from 'components/common/form'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`

const validationSchema = validationMapper([
  { id: 'question', isResponseRequired: true, answerType: formElements.openText },
  {
    id: 'countries',
    isResponseRequired: false,
    answerType: formElements.multiSelectDropdown,
  },
  {
    id: 'siteAssociations',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
  },
])

const AddConfigQuestionForm = ({ questionType }) => {
  const dispatch = useDispatch()
  const { countriesLookup } = useSelector(store => store.common)
  const { conciergePrescreenerSitesByCountries, sitePrescreenerSitesByCountries } = useSelector(store => store.participationProgressConfiguration)

  const sitesByCountries = questionType === questionTypeConstant.site ? sitePrescreenerSitesByCountries : conciergePrescreenerSitesByCountries

  const onCountriesChange = (selectedValues, reason, setFieldValue) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      // Dynamic dropdown options for Sites based on country selection
      if (selectedValues.find(option => option.id === 'select-all')) {
        dispatch(fetchSitesByCountries(countriesLookup.results, questionType))
        setFieldValue('countries', countriesLookup.results)
      } else {
        if (selectedValues.length > 0) {
          dispatch(fetchSitesByCountries(selectedValues, questionType))
          setFieldValue('countries', selectedValues)
        } else {
          dispatch(fetchSitesByCountries([], questionType))
          setFieldValue('countries', [])
        }
      }
    } else if (reason === 'clear') {
      dispatch(fetchSitesByCountries([], questionType))
      setFieldValue('countries', [])
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        dispatch(saveConfigPrescreenerQuestion(values, questionType, resetForm))
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <TextBoxOuterLabel size="small" id="question" label="Question" name="question" type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="small"
                id="selectCountries"
                name="countries"
                label="Countries:"
                options={countriesLookup.results}
                onChange={(event, selectedValues, reason) =>
                  onCountriesChange(selectedValues, reason, setFieldValue)
                }
                isLoading={countriesLookup.isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="medium"
                id="siteAssociations"
                label="Site Associations:"
                name="siteAssociations"
                options={sitesByCountries.results}
                isLoading={sitesByCountries.isLoading}
              />
            </Grid>
          </Grid>
          <ActionWrapper>
            <Button size="medium" fullWidth={false} color="primary" type="submit">
                  Save
            </Button>
          </ActionWrapper>
        </Form>
      )}
    </Formik>

  )
}

AddConfigQuestionForm.propTypes = {
  questionType: PropTypes.oneOf([questionTypeConstant.site, questionTypeConstant.concierge])
}

export default AddConfigQuestionForm
