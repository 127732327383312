import React, { useEffect } from 'react'
import { Launch } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStudyLinks } from 'store/ducks/resourceCenter'
import styled from '@emotion/styled'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { Bar } from 'components/common'

const StyledListItemText = styled(ListItemText)`
  overflow-wrap: anywhere;
`
const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
`

const ButtonContent = styled.a(({ theme }) => ({
  '&&': {
    display: 'flex',
    alignItems: 'center',
    color: `${theme.palette.text.main}`,
    svg: {
      fontSize: '1rem',
      marginLeft: '4px'
    },
    '.MuiOutlinedInput-root': {
      marginTop: theme.spacing(3)
    }
  }
}))

const StyledList = styled(List)`
  overflow-y: auto;
  max-height: 400px;
`

const StudyLinks = () => {
  const dispatch = useDispatch()
  const { studyLinks, selectedDocument } = useSelector(store => store.resourceCenter)

  useEffect(() => {
    dispatch(fetchStudyLinks())
  }, [])

  return (
    <StyledList component="nav" disablePadding aria-labelledby="study-links-list">
      {studyLinks?.length
        ? (
          studyLinks.map((file, index) => {
            const isSelected =
            selectedDocument?.category === resourceCenterCategory.studyLinks &&
            selectedDocument.id === file.id
            // check if the url starts with http:// or https://
            const url = /^https?:\/\//i.test(file.url) ? file.url : `http://${file.url}`
            return (
              <StyledListItem key={index}>
                <ButtonContent href={url} target="_blank" rel="noreferrer">
                  <StyledListItemText
                    primary={file.displayText}
                    primaryTypographyProps={{ color: `${isSelected && 'primary'}`, variant: 'body2' }}
                  />
                  <ListItemIcon>
                    <Launch color="primary" size="small" />
                  </ListItemIcon>
                </ButtonContent>
                <Bar fullWidth color="gray" />
              </StyledListItem>
            )
          })
        )
        : (
          <StyledListItem>
            <Typography variant="body2">No Records Found.</Typography>
          </StyledListItem>
        )}
    </StyledList>
  )
}
export default StudyLinks
