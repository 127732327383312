import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { ModeEditRounded, FileDownload } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { requestStatusId as requestStatusIdConstant } from 'components/helper/constants/visitServices'
import { filterByRequestStatusId, sortList } from 'components/helper/utility'
import { patientTabIDs } from 'components/helper/constants/common'
import { downloadAttachment } from 'store/ducks/visitServices/requestDetails'
import { useRestriction } from 'permissions'
import { DataGrid } from 'components/common'
import { palette } from 'styles/baseTheme'

const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`
const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 8px;
  padding: 1px 3px;
`
)
const CenterAlignDiv = styled.div`
  width: 100%;
  text-align: center;
`

const statusLabel = {
  2: 'Approval Date',
  3: 'Completed Date',
  4: 'Cancelled Date'
}

const gridTitleStyles = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 600,
  padding: '15px 0'
}

const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}

const RequestGrid = ({ requestStatusIds, gridTitle }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [order, setOrder] = useState('DESC')
  const [orderBy, setOrderBy] = useState('requestDate')
  const [requests, setRequests] = useState([])
  const { allRequests } = useSelector(store => store.requestDetails)
  const { instanceID, patientId } = useParams()

  useEffect(() => {
    const gotRequests = filterByRequestStatusId(allRequests.results, requestStatusIds)
    setRequests(gotRequests)
  }, [requestStatusIds])

  const downloadAttachmentHandler = attachment => {
    dispatch(downloadAttachment(attachment))
  }

  let formattedRequests = []
  if (requests.length > 0) {
    formattedRequests = requests.map(request => ({
      ...request,
      action: (
        <CenterAlignDiv>
          <IconButtonWrapper
            aria-label="actions"
            aria-haspopup="true"
            onClick={() => {
              navigate(
                `/instance/${instanceID}/patient-dashboard/${patientId}/${patientTabIDs.visitSupportServices}/${request.requestId}/${request.requestTypeId}`
              )
            }}
            color="primary"
          >
            <ModeEditRounded color="primary" fontSize="small" />{' '}
          </IconButtonWrapper>
        </CenterAlignDiv>
      ),
      downloadItinerary: request.attachment
        ? (
          <CenterAlignDiv>
            <IconButton
              aria-label="download itinerary"
              aria-haspopup="true"
              onClick={() => downloadAttachmentHandler(request.attachment)}
              color="primary"
            >
              <FileDownload color="primary" fontSize="small" />{' '}
            </IconButton>
          </CenterAlignDiv>
        )
        : null
    }))
  }

  const manageRequestColumn = useRestriction('read', 'arriveManageRequestButton')
    ? [
      {
        id: 'action',
        label: ' ',
        headProperties: { ...headerProperties },
        bodyProperties: { cellType: 'node' }
      }
    ]
    : []

  const otherColumns = [
    {
      id: 'requestNumber',
      label: 'Request Number',
      isSortable: true,
      headProperties: { ...headerProperties }
    },
    {
      id: 'requestDate',
      label: 'Request Date',
      isSortable: true,
      headProperties: { ...headerProperties }
    },
    {
      id: 'requestType',
      label: 'Request Type',
      isSortable: true,
      headProperties: { ...headerProperties }
    },
    {
      id: 'visitDate',
      label: 'Visit Date',
      isSortable: true,
      headProperties: { ...headerProperties }
    },
    {
      id: 'visitName',
      label: 'Visit Name',
      isSortable: true,
      headProperties: { ...headerProperties }
    }
  ]

  /**
   * After we receive specs for InProcessNotApproved Grid,
   * this prop requestStatusIds will revert to being a number instead of array.
   * As we have no specs for InProcessNotApproved, we are checking only for first requestStatusId
   * So all existing logic will work as is.
   */
  if (
    [
      requestStatusIdConstant.InProcessApproved,
      requestStatusIdConstant.Completed,
      requestStatusIdConstant.Cancelled
    ].includes(requestStatusIds[0])
  ) {
    otherColumns.push({
      id: 'statusDate',
      label: statusLabel[requestStatusIds[0]],
      isSortable: true,
      headProperties: { ...headerProperties }
    })
  }
  if ([requestStatusIdConstant.Cancelled].includes(requestStatusIds[0])) {
    otherColumns.push({
      id: 'statusWhenCancelled',
      label: 'Status When Cancelled',
      isSortable: true,
      headProperties: { ...headerProperties }
    })
  }
  if (
    [requestStatusIdConstant.InProcessApproved, requestStatusIdConstant.InProcess].includes(
      requestStatusIds[0]
    )
  ) {
    otherColumns.push({
      id: 'assignment',
      label: 'Assignment',
      isSortable: true,
      headProperties: { ...headerProperties }
    })
  }
  if (
    [requestStatusIdConstant.InProcessApproved, requestStatusIdConstant.Completed].includes(
      requestStatusIds[0]
    )
  ) {
    otherColumns.push({
      id: 'downloadItinerary',
      label: 'Itinerary',
      headProperties: { ...headerProperties },
      bodyProperties: { cellType: 'node' }
    })
  }

  const headers = [...manageRequestColumn, ...otherColumns]

  const handleRequestSort = orderByValue => {
    const isDesc = orderBy === orderByValue && order === 'DESC'
    const newOrder = isDesc ? 'ASC' : 'DESC'
    setOrder(newOrder)
    setOrderBy(orderByValue)
    setRequests(sortList(requests, newOrder, orderByValue))
  }

  return (
    <>
      <TableWrapper>
        <DataGrid
          headers={headers}
          tableData={formattedRequests}
          setRequestedSort={orderByValue => handleRequestSort(orderByValue)}
          order={order}
          orderBy={orderBy}
          alternateRowColored={true}
          gridTitle={gridTitle}
          gridTitleProperties={gridTitleStyles}
          isLoading={allRequests.isLoading}
        />
      </TableWrapper>
    </>
  )
}

RequestGrid.propTypes = {
  requestStatusIds: PropTypes.arrayOf(PropTypes.number),
  gridTitle: PropTypes.string
}

RequestGrid.defaultProps = {
  gridTitle: undefined
}

export default RequestGrid
