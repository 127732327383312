import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import {
  AutoCompleteWithLabel,
  CustomRadioGroup,
  CheckboxGroup,
  TextBoxOuterLabel,
  DatePickerWithLabel
} from 'components/common'
import { stripEnclosingPTag } from 'components/helper/utility'
import RenderHTML from '../dataDisplay/RenderHTML'
import { Typography } from '@mui/material'

const DefaultCompWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 16px;
`
const DefaultCompTitleWrapper = styled.div`
  display: flex;
  p {
    margin-right: 4px;
  }
`

const getComponent = componentDetails => {
  const radio = () => (
    <CustomRadioGroup
      id={`${componentDetails.id}`}
      row={true}
      name={`${componentDetails.id}`}
      label={<RenderHTML htmlContent={stripEnclosingPTag(componentDetails.question)} />}
      disableTypographyForLabel={true}
      options={componentDetails.options?.map(q => ({ value: q, label: q }))}
      size="medium"
      disabled={componentDetails.disabled}
      onPostChange={componentDetails.markAsChanged
        ? () => componentDetails.markAsChanged(componentDetails.id)
        : undefined}
    />
  )
  const dropdown = () => (
    <AutoCompleteWithLabel
      hasSelectAllOption={false}
      multiple={false}
      id={`${componentDetails.id}`}
      name={`${componentDetails.id}`}
      label={<RenderHTML htmlContent={stripEnclosingPTag(componentDetails.question)} />}
      options={componentDetails.options?.map(q => ({ id: q, displayText: q }))}
      size="small"
      required={componentDetails.isResponseRequired}
      fullWidth={true}
      disabled={componentDetails.disabled}
      onPostChange={componentDetails.markAsChanged
        ? () => componentDetails.markAsChanged(componentDetails.id)
        : undefined}
    />
  )

  const checkboxGroup = () => (
    <CheckboxGroup
      id={`${componentDetails.id}`}
      name={`${componentDetails.id}`}
      label={<RenderHTML htmlContent={stripEnclosingPTag(componentDetails.question)} />}
      disableTypographyForLabel={true}
      options={componentDetails.options?.map(q => ({ id: q, displayText: q }))}
      size="medium"
      required={componentDetails.isResponseRequired}
      disabled={componentDetails.disabled}
      onPostChange={componentDetails.markAsChanged
        ? () => componentDetails.markAsChanged(componentDetails.id)
        : undefined}
    />
  )

  const openText = () => (
    <TextBoxOuterLabel
      id={`${componentDetails.id}`}
      name={`${componentDetails.id}`}
      label={<RenderHTML htmlContent={stripEnclosingPTag(componentDetails.question)} />}
      size="small"
      required={componentDetails.isResponseRequired}
      fullWidth={true}
      disabled={componentDetails.disabled}
      onPostChange={componentDetails.markAsChanged
        ? () => componentDetails.markAsChanged(componentDetails.id)
        : undefined}
    />
  )

  const date = () => (
    <DatePickerWithLabel
      id={`${componentDetails.id}`}
      name={`${componentDetails.id}`}
      label={<RenderHTML htmlContent={componentDetails.question} />}
      size="small"
      required={componentDetails.isResponseRequired}
      fullWidth={true}
      disabled={componentDetails.disabled}
      onPostChange={componentDetails.markAsChanged
        ? () => componentDetails.markAsChanged(componentDetails.id)
        : undefined}
    />
  )

  const defaultComponent = () => (
    <DefaultCompWrapper>
      <Typography variant="body2" gutterBottom>
        The following form element is corrupted or have missing options:
      </Typography>
      {componentDetails.answerType && (
        <Typography variant="body1">Type - {componentDetails.answerType}</Typography>
      )}
      {componentDetails.question && (
        <DefaultCompTitleWrapper>
          <Typography variant="body1">Title - </Typography>
          <RenderHTML htmlContent={componentDetails.question} />
        </DefaultCompTitleWrapper>
      )}
    </DefaultCompWrapper>
  )

  const componentsList = {
    'Dropdown Menu': dropdown,
    'Radio Buttons': radio,
    'Check Boxes': checkboxGroup,
    'Open Text': openText,
    Date: date,
    default: defaultComponent
  }
  return !componentsList[componentDetails.answerType] ||
    (['Dropdown Menu', 'Radio Buttons', 'Check Boxes'].includes(componentDetails.answerType) &&
      !componentDetails.options)
    ? componentsList.default(componentDetails)
    : componentsList[componentDetails.answerType]()
}

const FormWrapper = styled.div(({ styles }) => ({
  '&&': {
    ...styles,
  },
}))

const ElementWrapper = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing(1)} 0;
  `
)

const FormMapper = ({ questions, wrapperStyles }) => (
  <FormWrapper styles={wrapperStyles}>
    {questions?.map((item, questionIndex) => {
      const myComp = getComponent(item)
      return <ElementWrapper key={questionIndex}>{myComp}</ElementWrapper>
    })}
  </FormWrapper>
)

FormMapper.propTypes = {
  questions: PropTypes.array,
  wrapperStyles: PropTypes.object
}

FormMapper.defaultProps = {
  questions: [],
  styles: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  }
}

export default FormMapper
