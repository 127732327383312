import { createReducer, createAction } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { convertUTCDateToLocalDate, copyObject, deepEqual, displayDate } from 'components/helper/utility'
import {
  getAllSupportPersons,
  updatePatientInformation,
  savePatientSupportPersonInformation,
  updateSupportPersonEmail,
  forgotPasswordForPatient,
  updatePatientEmail,
  getSupplementalInformation,
  postPatientReactivation
} from 'services/patientRecord/patientProfile'
import { fetchPatientInformation, setUserAccountUpdatedModal } from './patientMainWrapper'
import { fetchPatientAddresses } from 'store/ducks/visitServices/inPersonRequest'
import { deleteConciergeInsight, getConciergeInsights, addConciergeInsight } from 'services/note'

export const setPatientSupports = createAction('patientProfile/setPatientSupports')
export const setPatientContactInfoIsReadOnly = createAction(
  'patientProfile/setPatientContactInfoIsReadOnly'
)
export const setEditPatientSupport = createAction('patientProfile/setEditPatientSupport')
export const resetPatientProfile = createAction('patientProfile/resetPatientProfile')
export const setShowAddForm = createAction('patientProfile/setShowAddForm')
export const setShowAddInsightForm = createAction('patientProfile/setShowAddInsightForm')
export const setConciergeInsights = createAction('patientProfile/setConciergeInsights')
export const setDeleteInsightConfirmation = createAction(
  'patientProfile/setDeleteInsightConfirmation'
)
export const resetDeleteInsightConfirmation = createAction(
  'patientProfile/resetDeleteInsightConfirmation'
)
export const setForgotPasswordConfirmation = createAction(
  'patientProfile/setForgotPasswordConfirmation'
)
export const setForgotPasswordSuccess = createAction('patientProdfile/setForgotPasswordSuccess')
export const setIsReadOnly = createAction('patientProfile/setIsReadOnly')
export const setIsPatientSupportEmaildAddressReadOnly = createAction(
  'patientProfile/setIsPatientSupportEmaildAddressReadOnly'
)
export const setIsPatientEmaildAddressReadOnly = createAction(
  'patientProfile/setIsPatientEmaildAddressReadOnly'
)
export const setSupplementalInfo = createAction('patientProfile/setSupplementalInfo')
export const setPatientReactivationConfirmation = createAction('patientProfile/setPatientReactivationConfirmation')
export const resetPatientSupport = createAction('patientProfile/resetPatientSupport')

export const fetchPatientSupportPersons = userId => async dispatch => {
  dispatch(setLoading(true))
  await getAllSupportPersons(userId)
    .then(({ data }) => {
      if (data?.length) {
        // eslint-disable-next-line complexity
        const formattedResponse = data.map(support => {
          const {
            firstName,
            lastName,
            middleName,
            gender,
            dateOfBirth,
            emailAddress,
            phoneNumber,
            preferredLanguage,
            secondaryLanguage,
            supportPersonRelationship,
            address1,
            address2,
            address3,
            city,
            country,
            state,
            zipCode,
            isAcknowledged
          } = support
          return {
            ...support,
            firstName: firstName || '',
            lastName: lastName || '',
            middleName: middleName || '',
            gender: gender || {
              id: '',
              displayText: ''
            },
            dateOfBirth,
            emailAddress: emailAddress || '',
            phoneNumber: phoneNumber || '',
            preferredLanguage: preferredLanguage || {
              id: '',
              displayText: ''
            },
            secondaryLanguage: secondaryLanguage || {
              id: '',
              displayText: ''
            },
            supportPersonRelationship: supportPersonRelationship || { id: '', displayText: '' },
            address1: address1 || '',
            address2: address2 || '',
            address3: address3 || '',
            city: city || '',
            country: country || { id: '', displayText: '' },
            state: state || { id: '', displayText: '' },
            zipCode: zipCode || '',
            isAcknowledged
          }
        })
        dispatch(
          setPatientSupports({ results: formattedResponse, areSupportPersonsAvailable: true })
        )
      }
    })
    .catch(() => {
      dispatch(
        showError(
          'There was an issue while trying to fetch Patient support information. Please try again later.'
        )
      )
    })
  dispatch(setLoading(false))
}

export const fetchSupplementalInformation = (userId, userSupportPersonId) => async dispatch => {
  dispatch(setSupplementalInfo({ isLoading: true }))

  try {
    const result = await getSupplementalInformation({
      userId,
      userSupportPersonId
    })
    if (result) {
      const newSupplementalInfo = {
        passportNumber: result.passportNumber || '',
        passportExpirationDate: result.passportExpirationDate || '',
        passportIssuedCountry: result.passportIssuedCountry
          ? {
            id: result.passportIssuedCountry?.id,
            displayText: result.passportIssuedCountry?.displayText
          }
          : null,
        hasNoPassport: result.hasNoPassport || false,
        supplementalInformationId: result.supplementalInformationId
      }
      dispatch(setSupplementalInfo({ isLoading: false, results: newSupplementalInfo }))
    } else {
      dispatch(setSupplementalInfo(initialState.supplementalInfo))
    }
  } catch (e) {
    dispatch(setSupplementalInfo(initialState.supplementalInfo))
    dispatch(showError('Something went wrong while fetching information!'))
  }
}
export const onPatientContactInfoChange = (values, previousValues, patientId) => async dispatch => {
  dispatch(setLoading(true))
  const {
    userId,
    firstName,
    middleName,
    lastName,
    // gender,
    dateOfBirth,
    emailAddress,
    addressId,
    address1,
    address2,
    address3,
    city,
    zipCode,
    phoneNumber,
    country,
    state,
    preferredLanguage,
    patientCommunicationPreferences,
    englishSkill
  } = values

  const payload = {
    userId,
    firstName,
    middleName,
    lastName,
    dateOfBirth: convertUTCDateToLocalDate(dateOfBirth),
    emailAddress,
    addressId,
    address1,
    address2,
    address3,
    city,
    zipCode,
    phoneNumber,
    // genderId: gender?.id,
    countryId: country?.id,
    stateId: state?.id,
    preferredLanguageId: preferredLanguage?.id,
    patientCommunicationPreferences: patientCommunicationPreferences?.map(c => c.id),
    englishSkillLevelId: englishSkill?.id
  }

  const comparingKeys = [
    'firstName',
    'lastName',
    'dateOfBirth',
    'address1',
    'address2',
    'city',
    'zipCode',
    'phoneNumber',
    // 'gender',
    'country',
    'state'
  ]

  try {
    await updatePatientInformation(payload)
    dispatch(setPatientContactInfoIsReadOnly(true))
    dispatch(showSuccess("Patient's contact information updated successfully."))

    // Refresh Patient Information
    dispatch(fetchPatientInformation(patientId))

    const updatedValue = {}
    const previousValueInSystem = {}
    comparingKeys.forEach(propertyName => {
      updatedValue[propertyName] = values[propertyName]
      previousValueInSystem[propertyName] = previousValues[propertyName]
    })

    const areBothObjectsSame = deepEqual(updatedValue, previousValueInSystem)

    // if Address is updated, refresh patient address in Redux
    if (!areBothObjectsSame) {
      dispatch(fetchPatientAddresses(patientId))
    }

    if (!areBothObjectsSame && previousValues.isReimbursementAcknowledged) {
      // When above mentioned fields(comparingKeys) are not same, show a pop as a note to user
      dispatch(setUserAccountUpdatedModal(true))
    }
  } catch {
    dispatch(
      showError(
        "There was some issue while trying to update patient's contact information. Please try updating it again later."
      )
    )
  } finally {
    dispatch(setLoading(false))
  }
}

export const onPatientEmailAddressChange =
  values => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { patientInformation } = getState().patientMainWrapper
      const requestData = {
        userId: patientInformation.userId,
        emailAddress: values.emailAddress
      }
      await updatePatientEmail(requestData)
      await dispatch(fetchPatientInformation(patientInformation.userId))
      dispatch(setIsPatientEmaildAddressReadOnly(true))
      if (
        values.emailAddress !== patientInformation.emailAddress &&
        patientInformation.isReimbursementAcknowledged
      ) {
        dispatch(setUserAccountUpdatedModal(true))
      }
      dispatch(showSuccess('Email Address / Username updated.'))
    } catch (e) {
      dispatch(showError('Email address not updated. Please try again.', e))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const submitPatientForgotPassword = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { patientInformation } = getState().patientMainWrapper
    await forgotPasswordForPatient(patientInformation.emailAddress)
    dispatch(setForgotPasswordConfirmation(false))
    dispatch(setForgotPasswordSuccess(true))
  } catch (e) {
    dispatch(showError('There was some issue requesting for reset password. Please try again.', e))
  }
  dispatch(setLoading(false))
}

export const fetchConciergeInsights = userId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const result = await getConciergeInsights(userId)
    const conciergeInsights = result.map(item => ({
      ...item,
      createdDate: displayDate(item.createdDate)
    }))
    dispatch(setConciergeInsights(conciergeInsights))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const onDeleteConciergeInsight = deleteInsightId => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { conciergeInsights } = getState().patientProfile
    const deleteNoteInfo = {
      noteId: deleteInsightId
    }
    await deleteConciergeInsight(deleteNoteInfo)
    const newConciergeInsights = conciergeInsights.filter(
      insight => insight.noteId !== deleteInsightId
    )
    dispatch(setConciergeInsights(newConciergeInsights))

    dispatch(resetDeleteInsightConfirmation())
    dispatch(showSuccess('Note deleted!'))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const onAddConciergeInsight = values => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { patientInformation } = getState().patientMainWrapper
    const insightData = {
      patientId: patientInformation.userId,
      note: values.note
    }
    await addConciergeInsight(insightData)
    dispatch(setShowAddInsightForm(false))
    dispatch(fetchConciergeInsights(patientInformation.userId))
    dispatch(showSuccess('Note added successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const onPatientSupportChange =
  (values, oldValues, patientId, onSubmitPatientSupport) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { patientInformation } = getState().patientMainWrapper
      const {
        userSupportPersonId,
        userSupportPersonType,
        firstName,
        middleName,
        lastName,
        // gender,
        dateOfBirth,
        emailAddress,
        addressId,
        address1,
        address2,
        address3,
        city,
        zipCode,
        phoneNumber,
        country,
        state,
        preferredLanguage,
        secondaryLanguage,
        supportPersonRelationship,
        communicationPreferences,
        isAcknowledged
      } = values
      const payload = {
        userId: patientId,
        userSupportPersonId: userSupportPersonId || null,
        userSupportPersonTypeId: userSupportPersonType?.id,
        firstName,
        middleName,
        lastName,
        dateOfBirth: convertUTCDateToLocalDate(dateOfBirth),
        emailAddress,
        addressId,
        address1,
        address2,
        address3,
        city,
        zipCode,
        phoneNumber,
        // genderId: gender?.id,
        countryId: country?.id,
        stateId: state?.id,
        preferredLanguageId: preferredLanguage?.id,
        secondaryLanguageId: secondaryLanguage?.id,
        supportPersonRelationshipId: supportPersonRelationship?.id,
        communicationPreferences: communicationPreferences?.map(c => c.id),
        isAcknowledged
      }

      let areBothObjectsSame = true

      if (values.userId && oldValues.isReimbursementAcknowledged) {
        const comparingKeys = [
          'firstName',
          'lastName',
          'dateOfBirth',
          'address1',
          'address2',
          'city',
          'zipCode',
          'phoneNumber',
          // 'gender',
          'country',
          'state'
        ]

        const updatedValue = {}
        const previousValueInSystem = {}
        comparingKeys.forEach(propertyName => {
          updatedValue[propertyName] = values[propertyName]
          previousValueInSystem[propertyName] = oldValues[propertyName]
        })

        areBothObjectsSame = deepEqual(updatedValue, previousValueInSystem)
      }

      await savePatientSupportPersonInformation(payload)

      dispatch(showSuccess('Success!'))
      onSubmitPatientSupport && onSubmitPatientSupport()
      if (patientInformation.userId) {
        dispatch(fetchPatientSupportPersons(patientInformation.userId))
      }
      if (values.userId && !areBothObjectsSame && oldValues.isReimbursementAcknowledged) {
        // When above mentioned fields(comparingKeys) are not same, show a pop as a note to user
        dispatch(setUserAccountUpdatedModal(true))
      }
      dispatch(setShowAddForm(false))
      dispatch(setIsReadOnly(true))
    } catch (e) {
      dispatch(
        showError(
          "There was some issue while trying to update Patient Support's information. Please try again.", e
        )
      )
    }

    dispatch(setLoading(false))
  }

export const onPatientSupportEmailAddress =
  (values, contactDetails) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { patientInformation } = getState().patientMainWrapper
    const updatedEmailInfo = {
      userSupportPersonId: contactDetails.userSupportPersonId,
      emailAddress: values.emailAddress
    }
    updateSupportPersonEmail(updatedEmailInfo)
      .then(() => {
        dispatch(fetchPatientSupportPersons(patientInformation.userId))
        dispatch(setIsPatientSupportEmaildAddressReadOnly(true))
        if (
          values.emailAddress !== contactDetails.emailAddress &&
          values.userId &&
          contactDetails.isReimbursementAcknowledged
        ) {
          dispatch(setUserAccountUpdatedModal(true))
        }
      })
      .catch(e => {
        dispatch(
          showError(
            "There was some issue while trying to update Patient Support's email information. Please try again.", e
          )
        )
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

export const submitPatientReactivation = (userId, currentInstanceId) => async dispatch => {
  try {
    dispatch(setLoading(true))
    await postPatientReactivation(userId, currentInstanceId)
    dispatch(setPatientReactivationConfirmation(false))
    dispatch(setLoading(false))
  } catch {
    dispatch(setLoading(false))
  }
}

export const patientSupportInitialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  emailAddress: '',
  userSupportPersonType: null,
  supportPersonRelationship: null,
  gender: null,
  dateOfBirth: '',
  preferredLanguage: null,
  communicationPreferences: [],
  secondaryLanguage: null,
  phoneNumber: '',
  address1: '',
  address2: '',
  country: null,
  state: null,
  city: '',
  zipCode: '',
  // Every time user edits information, he needs to acknowledge
  isAcknowledged: false,
  forgotPasswordConfirmation: false
}

export const deleteInsightConfirmationInitialValues = {
  showConfirmation: false,
  deleteInsightId: null
}

const initialState = {
  patientContactInfoIsReadOnly: true,
  editPatientSupport: false,
  patientSupports: {
    results: [
      {
        ...patientSupportInitialValues
      }
    ],
    areSupportPersonsAvailable: false
  },
  supplementalInfo: {
    isLoading: false,
    results: {
      passportNumber: '',
      passportExpirationDate: '',
      passportIssuedCountry: null,
      hasNoPassport: false,
      supplementalInformationId: null
    }
  },
  showAddForm: false,
  isReadOnly: false,
  showAddInsightForm: false,
  conciergeInsights: [],
  deleteInsightConfirmation: { ...deleteInsightConfirmationInitialValues },
  isPatientSupportEmaildAddressReadOnly: true,
  isPatientEmaildAddressReadOnly: true,
  forgotPasswordConfirmation: false,
  forgotPasswordSuccess: false,
  patientReactivationConfirmation: false
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setPatientContactInfoIsReadOnly, (state, action) => {
      state.patientContactInfoIsReadOnly = action.payload
    })
    .addCase(setSupplementalInfo, (state, action) => {
      state.supplementalInfo = { ...state.supplementalInfo, ...action.payload }
    })
    .addCase(setPatientSupports, (state, action) => {
      state.patientSupports = { ...state.patientSupports, ...action.payload }
    })
    .addCase(setEditPatientSupport, (state, action) => {
      state.editPatientSupport = action.payload
    })
    .addCase(setShowAddForm, (state, action) => {
      state.showAddForm = action.payload
    })
    .addCase(setIsReadOnly, (state, action) => {
      state.isReadOnly = action.payload
    })
    .addCase(setShowAddInsightForm, (state, action) => {
      state.showAddInsightForm = action.payload
    })
    .addCase(setConciergeInsights, (state, action) => {
      state.conciergeInsights = action.payload
    })
    .addCase(setDeleteInsightConfirmation, (state, action) => {
      state.deleteInsightConfirmation = { ...action.payload }
    })
    .addCase(resetDeleteInsightConfirmation, (state, _action) => {
      state.deleteInsightConfirmation = { ...deleteInsightConfirmationInitialValues }
    })
    .addCase(setIsPatientSupportEmaildAddressReadOnly, (state, action) => {
      state.isPatientSupportEmaildAddressReadOnly = action.payload
    })
    .addCase(setIsPatientEmaildAddressReadOnly, (state, action) => {
      state.isPatientEmaildAddressReadOnly = action.payload
    })
    .addCase(resetPatientProfile, state => {
      copyObject(state, initialState)
    })
    .addCase(resetPatientSupport, state => {
      state.showAddForm = initialState.showAddForm
      state.isReadOnly = initialState.isReadOnly
      state.patientContactInfoIsReadOnly = initialState.patientContactInfoIsReadOnly
    })
    .addCase(setForgotPasswordConfirmation, (state, action) => {
      state.forgotPasswordConfirmation = action.payload
    })
    .addCase(setForgotPasswordSuccess, (state, action) => {
      state.forgotPasswordSuccess = action.payload
    })
    .addCase(setPatientReactivationConfirmation, (state, action) => {
      state.patientReactivationConfirmation = action.payload
    })
})
