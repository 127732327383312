import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Grid, Typography, Paper, ListItemIcon, List, ListItem, ListItemText } from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material'

import { Button } from 'components/common/form'
import { Card, PageHeader } from 'components/common'

import { setStartNewRequestForVisitFlow } from 'store/ducks/visitServices/requestDetails'
import { Restriction } from 'permissions'
import {
  requestLabel,
  requestType,
  requestTypeIds
} from 'components/helper/constants/visitServices'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(5)
  }
}))

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledCircle = styled(FiberManualRecord)`
  font-size: 12px;
`
const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 22px;
`
const StyledLink = styled(Link)`
  text-decoration: unset;
`

const remoteRequestTypeListItems = {
  [requestTypeIds.deliveryServices]: (
    <ListItem disablePadding alignItems="flex-start" key={requestTypeIds.deliveryServices}>
      <StyledListItemIcon>
        <StyledCircle fontSize="small" />
      </StyledListItemIcon>
      <ListItemText primary="Delivery Services" />
    </ListItem>
  ),
  [requestTypeIds.homeHealthVisit]: (
    <ListItem disablePadding alignItems="flex-start" key={requestTypeIds.homeHealthVisit}>
      <StyledListItemIcon>
        <StyledCircle fontSize="small" />
      </StyledListItemIcon>
      <ListItemText primary="Home Health Visit" />
    </ListItem>
  ),
  [requestTypeIds.telehealthVisit]: (
    <ListItem disablePadding alignItems="flex-start" key={requestTypeIds.telehealthVisit}>
      <StyledListItemIcon>
        <StyledCircle fontSize="small" />
      </StyledListItemIcon>
      <ListItemText primary="Telehealth Support" />
    </ListItem>
  ),
  [requestTypeIds.otherServices]: (
    <ListItem disablePadding alignItems="flex-start" key={requestTypeIds.otherServices}>
      <StyledListItemIcon>
        <StyledCircle fontSize="small" />
      </StyledListItemIcon>
      <ListItemText primary="Other Services" />
    </ListItem>
  )
}

const remoteRequestBox = availableRemoteRequestIds => (
  <Grid item xs={12} sm={12} md={6} lg={3}>
    <StyledLink to={`./${requestType.remoteRequest}`}>
      <Card
        radius={false}
        title={requestLabel.remoteRequest}
        titleTypographyProps={{ variant: 'subtitle1' }}
        headerColor="primary"
        cardBottom={
          <ActionWrapper>
            <Button size="large" fullWidth={false} color="primary">
              START A NEW REQUEST
            </Button>
          </ActionWrapper>
        }
        cardStyles={{ paddingBottom: '10px' }}
        cardContentStyles={{
          minHeight: '320px',
          alignContent: 'flex-start'
        }}
        background="gray"
        backgroundType="light"
      >
        <List aria-labelledby={requestLabel.remoteRequest} disablePadding>
          <ListItem disablePadding>
            <ListItemText
              primary="Services include:"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
          {availableRemoteRequestIds.map(remoteReqId => remoteRequestTypeListItems[remoteReqId])}
        </List>
      </Card>
    </StyledLink>
  </Grid>
)

const requestTypeBoxes = {
  [requestTypeIds.inPersonVisit]: (
    <Grid item xs={12} sm={12} md={6} lg={3} key={requestTypeIds.inPersonVisit}>
      <StyledLink to={`./${requestType.inPersonVisit}`}>
        <Card
          radius={false}
          title={requestLabel.inPersonVisit}
          titleTypographyProps={{ variant: 'subtitle1' }}
          headerColor="primary"
          cardBottom={
            <ActionWrapper>
              <Button size="large" fullWidth={false} color="primary">
                START A NEW REQUEST
              </Button>
            </ActionWrapper>
          }
          cardStyles={{ paddingBottom: '10px' }}
          cardContentStyles={{
            minHeight: '320px',
            alignContent: 'flex-start'
          }}
          background="gray"
          backgroundType="light"
        >
          <List aria-labelledby={requestLabel.inPersonVisit} disablePadding>
            <ListItem disablePadding>
              <ListItemText
                primary="Services include:"
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Ground Transportation Service (sedan, van, WAV)" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Hotel / Accommodations (single, double, ADA, meal availability, etc.)" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Ticketed Travel (air, train, bus)" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Mobility Assistance" />
            </ListItem>
          </List>
        </Card>
      </StyledLink>
    </Grid>
  ),
  [requestTypeIds.auxillarySupport]: (
    <Grid item xs={12} sm={12} md={6} lg={3} key={requestTypeIds.auxillarySupport}>
      <StyledLink to={`./${requestType.auxillarySupport}`}>
        <Card
          radius={false}
          title={requestLabel.auxillarySupport}
          titleTypographyProps={{ variant: 'subtitle1' }}
          headerColor="primary"
          cardBottom={
            <ActionWrapper>
              <Button size="large" fullWidth={false} color="primary">
                START A NEW REQUEST
              </Button>
            </ActionWrapper>
          }
          cardStyles={{ paddingBottom: '10px' }}
          cardContentStyles={{
            minHeight: '320px',
            alignContent: 'flex-start'
          }}
          background="gray"
          backgroundType="light"
        >
          <List aria-labelledby={requestLabel.auxillarySupport} disablePadding>
            <ListItem disablePadding>
              <ListItemText
                primary="Services include:"
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Interpreter Support" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Longer-term Stay Support" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Professional Companion" />
            </ListItem>
          </List>
        </Card>
      </StyledLink>
    </Grid>
  ),
  [requestTypeIds.advancedSupport]: (
    <Grid item xs={12} sm={12} md={6} lg={3} key={requestTypeIds.advancedSupport}>
      <StyledLink to={`./${requestType.advancedSupport}`}>
        <Card
          radius={false}
          title={requestLabel.advancedSupport}
          titleTypographyProps={{ variant: 'subtitle1' }}
          headerColor="primary"
          cardBottom={
            <ActionWrapper>
              <Button size="large" fullWidth={false} color="primary">
                START A NEW REQUEST
              </Button>
            </ActionWrapper>
          }
          cardStyles={{ paddingBottom: '10px' }}
          cardContentStyles={{
            minHeight: '320px',
            alignContent: 'flex-start'
          }}
          background="gray"
          backgroundType="light"
        >
          <List aria-labelledby={requestLabel.advancedSupport} disablePadding>
            <ListItem disablePadding>
              <ListItemText
                primary="Services include:"
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Legal Support for International Travel Needs" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Medical Record / Official Document Request and Translation" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Medical / Specialty Transportation" />
            </ListItem>
            <ListItem disablePadding alignItems="flex-start">
              <StyledListItemIcon>
                <StyledCircle fontSize="small" />
              </StyledListItemIcon>
              <ListItemText primary="Nutrition Support / Meal Delivery" />
            </ListItem>
          </List>
        </Card>
      </StyledLink>
    </Grid>
  )
}

const SelectRequestType = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { availableRequestTypeIds } = useSelector(store => store.requestDetails)

  const backHandler = () => {
    /** Reset setStartNewRequestForVisitFlow if,
      user navigates away from VisitServices  */
    dispatch(setStartNewRequestForVisitFlow(null))
    navigate('../arriveDashboard')
  }

  return (
    <MainWrapper
      key={availableRequestTypeIds.nonRemote.length + availableRequestTypeIds.remote.length}
    >
      <PageHeader
        title={<Typography variant="h4">Make a Request</Typography>}
        expandContent
        actionContent={
          <Grid container spacing={2}>
            <Restriction ability="read" feature="arriveMakeRequestButton">
              <Grid item>
                {/* <StyledLink to={'../arriveDashboard'}> */}
                <Button size="large" color="primary" onClick={backHandler}>
                  Back
                </Button>
                {/* </StyledLink> */}
              </Grid>
            </Restriction>
          </Grid>
        }
        content={
          <Grid container spacing={2}>
            {availableRequestTypeIds.nonRemote.map(
              nonRemoteRequestId => requestTypeBoxes[nonRemoteRequestId]
            )}
            {!!availableRequestTypeIds.remote.length &&
              remoteRequestBox(availableRequestTypeIds.remote)}
          </Grid>
        }
        bar={false}
      />
    </MainWrapper>
  )
}

export default SelectRequestType
