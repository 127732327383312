import { backendAxios, getConfig } from './backend'

// Settings - Address
export const getAddressDropdownValues = siteClientId =>
  backendAxios
    .get('/settings/getaddaddresslookup', getConfig({ siteClientId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const saveSiteAddress = newAddress =>
  backendAxios
    .post('/settings/savesiteaddress', newAddress, getConfig())
    .then(response => response.data)

export const getSiteAddresses = () =>
  backendAxios
    .get('/settings/getsiteaddressesloaddata', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getSiteAddressesForSite = siteClientId =>
  backendAxios
    .get('/settings/getalladdressesforsite', getConfig({ siteClientId }))
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err.message))

// Settings - User Information / Password
export const saveTCNUserDetails = updatedUserInformation =>
  backendAxios
    .post('/settings/savetcnuserprofile', updatedUserInformation, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const submitUpdateEmail = updateEmailInfo =>
  backendAxios
    .post('/settings/submitemailchange', updateEmailInfo, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getTCNUserDetails = () =>
  backendAxios
    .get('/settings/gettcnuserdetails', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const changePassword = passwordInfo =>
  backendAxios
    .post('/settings/changepassword', passwordInfo, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const changeSiteName = siteNameInfo =>
  backendAxios
    .post('/settings/savesitename', siteNameInfo, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

/**
Site Preference APIs
 */

// Settings - Preferences
// Get unanswered questions
export const getUnansweredSitePreferences = () =>
  backendAxios
    .get('/settings/unansweredsitepreferences', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

// Get all questions
export const getSitePreferenceConfiguration = () =>
  backendAxios
    .get('/settings/sitepreferenceconfiguration', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err.message))

export const getSitePrefQuestionAndResponse = userId =>
  backendAxios
    .get('/settings/getsitepreferencesquestionandresponsedata', getConfig({ userId }))
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

// return Promise.resolve(getSitePreferenceQuestionData)

export const getSitePreferenceQuestionData = id =>
  backendAxios
    .get('/settings/getsitepreferencequestiondata', getConfig({ questionId: id }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

// return Promise.resolve(getSitePreferenceQuestionData)

/**
 *
 * Called to update site travel responses
 */

export const saveSitePreferenceAnswers = answersInfo =>
  backendAxios
    .post('/settings/savesitepreferenceresponses', answersInfo, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

// Settings - Address
export const getAddSitePreferenceLoadData = () =>
// return Promise.resolve(addAddressLookup)

  backendAxios
    .get('/settings/getaddsitepreferenceloaddata', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const saveSitePreferenceQuestion = questionData =>
  backendAxios
    .post('/settings/savesitepreferencequestion', questionData, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))
