export const reimbursementAccordions = {
  signUp: 'signUp',
  addFunds: 'addFunds',
  manageAccount: 'manageAccount'
}

export const cardType = {
  convertToPlasticRequested: 'ConvertToPlasticRequested',
  plastic: 'Plastic'
}

export const cardStatus = {
  active: 'Active',
  inActive: 'Inactive',
  normal: 'Active',
  suspend: 'Suspended'
}

export const getCardStatus = givenStatus => {
  const cardStatus = {
    Normal: 'Active',
    Suspend: 'Suspended'
  }

  return cardStatus[givenStatus] ? cardStatus[givenStatus] : givenStatus
}

export const getUserSupportPersonType = type => {
  const types = { LAR: 'Legally Authorized Representative' }
  return types[type] ? types[type] : type
}
export const bankAccountTypes = [
  { id: 0, displayText: 'Checking' },
  { id: 1, displayText: 'Savings' }
]

export const currencyExchangeSiteUrl = 'https://www.xe.com/'

export const serviceIds = {
  other: 7,
  totalOrTotalInLocalCurrency: 8,
  totalInCardCurrency: 15
}

export const termsAndConditionsAttachmentText = {
  'North America Program_PACE_Terms and Conditions_MasterCard':
    'If the number on the front of your card begins with a 5 (Mastercard), please reference the',
  'European Program_CashFlows_Terms and Conditions_VISA':
    'If the number on the front of your card begins with a 4 (Visa), please reference the'
}

export const termsAndConditionsAttachmentLabel = label => {
  const agreementLabels = {
    'North America Program_PACE_Terms and Conditions_MasterCard':
    'PACE Savings & Credit Union Terms and Conditions',
    'European Program_CashFlows_Terms and Conditions_VISA':
  'CashFlow Terms and Conditions'
  }

  return agreementLabels[label] ? agreementLabels[label] : label
}
