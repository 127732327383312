import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Card, CardHeader, CardContent, Grid } from '@mui/material'

import { fetchAddressLookup, fetchSiteAddresses } from 'store/ducks/siteManagement/siteManagement'

import AddressForm from './AddressForm'

import { Button } from 'components/common/form'
import QuickView from 'components/common/QuickView'
import { fetchCountriesLookup } from 'store/ducks/common'
import { fetchTcnUsersLookup } from 'store/ducks/application'

const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
`

const ActionWrapper = styled.div`
  padding-top: 30px;
  float: right;
  display: flex;
  align-items: flex-start;
`
const CustomCard = styled(Card)`
  && {
    maxwidth: 200;
    margin: 10px;
    .MuiCardHeader-root {
      padding: ${({ theme }) => theme.spacing(2)};
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    .MuiCardContent-root {
      padding: ${({ theme }) => theme.spacing(4)};
    }
    .MuiCardHeader-title {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      font-weight: bold;
      flex: 1;
      display: flex;
      justify-content: center;
      transform: translateX(10px);
      align-self: center;
    }
  }
`

const SiteAddress = () => {
  const dispatch = useDispatch()
  const { siteAddresses, siteAddress, siteDetails } = useSelector(store => store.siteManagement)
  const [isQuickViewShown, setQuickView] = useState(false)

  const fetchAllData = async () => {
    await dispatch(fetchSiteAddresses(siteDetails.siteClientId))
    await dispatch(fetchAddressLookup())
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchTcnUsersLookup(siteDetails.siteClientId))
  }
  useEffect(() => {
    if (siteDetails.siteClientId) {
      fetchAllData()
    }
  }, [siteDetails])

  return (
    <Grid container style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container style={{ display: 'flex', flexWrap: 'wrap' }}>
          {siteAddresses.map((address, index) => (
            <Grid item key={index} xl={6} lg={6} md={6} sm={12} xs={12}>
              <CustomCard>
                <CardHeader
                  // If SiteLocation present show SiteLocation as title, else Site Name
                  title={address.addressType.displayText}
                />

                <CardContent>
                  <AddressForm addressToEdit={address} />
                </CardContent>
              </CustomCard>
            </Grid>
          ))}
        </Grid>

        <SubHeaderWrapper>
          <ActionWrapper>
            <Button size="medium" onClick={() => setQuickView(true)} color="primary">
                Add Site Address
            </Button>
          </ActionWrapper>
        </SubHeaderWrapper>
        <QuickView
          title="Add Site Address"
          onClose={() => setQuickView(false)}
          dialogContent={
            <AddressForm
              addressToEdit={siteAddress}
              onSubmitForm={() => setQuickView(false)}
            />
          }
          isDialogOpen={isQuickViewShown}
          size="medium"
          closeConfirmation={true}
        />
      </Grid>
    </Grid>
  )
}

export default SiteAddress
