import React, { useEffect } from 'react'
import {
  RichTextEditor,
  AutoCompleteWithLabel,
  CustomRadioGroup,
  TextBoxOuterLabel,
  DatePickerWithLabel,
  Button,
  AttachFile
} from 'components/common'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Paper, Typography, Tooltip } from '@mui/material'
import {
  convertDraftToHTML,
  convertHtmlToDraft,
  displayDate,
  getSelectedId
} from 'components/helper/utility'
import styled from '@emotion/styled'
import {
  publishMessage,
  fetchSystemIDsBySite,
  setSystemIDsBySite,
  fetchSitesByCountries,
  fetchUsersBySite,
  setUsersBySite,
  onClearOfCountriesDropdown
} from 'store/ducks/communicationCenter/studyMessages'
import {
  fetchCategoriesLookup,
  // fetchProgramsLookup,
  fetchCountriesLookup,
  fetchSystemIDs,
  fetchMessagesSendCategory,
  fetchSystemIDsByLoggedInSite
} from 'store/ducks/common'

import Error from '@mui/icons-material/Error'
import {
  communicationTabIDs,
  messageSendCategoryIDs,
  sendToOptionsFor
} from 'components/helper/constants/mailbox'
import { showWarning } from 'store/ducks/application'
import { Restriction, useRestriction } from 'permissions'
import { validationMapper } from 'components/helper/validationEngine'
import { useLocation } from 'react-router-dom'
import { formElements } from 'components/helper/constants/common'

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(2)};
        margin-right: ${theme.spacing(2)};
      }
      flex-wrap: wrap;
      display: flex;
      justify-content: flex-start;
  `
)

const StyledForm = styled(Form)`
  width: 100%;
`

const GridContainer = styled(Grid)(
  ({ theme, background }) => `
    margin-bottom: ${theme.spacing(3)};
    background-color: ${background === 'true' && theme.palette.gray.light};
    border-radius: ${theme.shape.minRadius};
    padding: 0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .MuiFormLabel-root {
      font-weight: 900;
      color: ${theme.palette.text.dark};
    }
`
)

const SendToGrid = styled(Grid)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    display: flex;
    justify-content: flex-start;
    .MuiFormLabel-root {
      font-weight: 900;
      color: ${theme.palette.text.dark};
    }
`
)

const SystemIDGrid = styled(Grid)(
  ({ theme }) => `
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .MuiFormLabel-root {
      font-weight: 900;
      color: ${theme.palette.text.dark};
    }
`
)

const SystemIDDropDown = styled.div(
  ({ theme }) => `
 &&{
   width: 100%;
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiOutlinedInput-root{
    background-color: ${theme.palette.gray.light};
  }
  }
 }
`
)

const SystemIDLabel = styled.div(
  ({ theme }) => `
      display: flex;  
      p {
        margin-right: ${theme.spacing(1 / 2)};
      }`
)

const AutoCompleteWrapper = styled(Grid)(
  ({ theme }) => `
 &&{
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiOutlinedInput-root{
    background-color: ${theme.palette.gray.light};
  }
  }
 }
`
)
const StyledPaper = styled(Paper)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
`
)
const SystemIdNote = styled(Typography)`
  font-weight: 900;
  color: ${({ theme }) => theme.palette.midnight.dark};
  font-style: italic;
`

const AttachWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};
    justify-content: flex-end;
    display: flex;
`
)

const validationSchema = ({
  isCountriesPermission,
  isSitesPermission,
  isSystemPermission,
  isDistributionDatePermission
}) =>
  validationMapper([
    { id: 'subject', isResponseRequired: true, answerType: formElements.openText },
    { id: 'body', isResponseRequired: true, answerType: formElements.richTextEditor },
    {
      id: 'countries',
      isResponseRequired: true,
      answerType: formElements.multiSelectDropdown,
      dependency: {
        elements: [],
        callBack: () => isCountriesPermission
      }
    },
    {
      id: 'siteProtocols',
      isResponseRequired: true,
      answerType: formElements.multiSelectDropdown,
      dependency: {
        elements: ['countries'],
        callBack: countries => countries?.length && isSitesPermission
      }
    },
    {
      id: 'userEntities',
      isResponseRequired: true,
      answerType: formElements.multiSelectDropdown,
      dependency: {
        elements: ['sendTo', 'siteProtocols'],
        callBack: (sendTo, siteProtocols) =>
          // when individual user option is selected
          siteProtocols?.length && sendTo === messageSendCategoryIDs.individualUsers
      }
    },
    {
      id: 'systemId',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
      dependency: {
        elements: [],
        callBack: () => isSystemPermission
      }
    },
    { id: 'category', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'distributionDate',
      isResponseRequired: isDistributionDatePermission,
      answerType: formElements.date,
      condition: {
        min: new Date(),
        minError: 'Distribution date must be in the future'
      },
      error: 'Distribution date is required and must be a valid date'
    }
  ])

const ComposeMessage = ({ onCancel }) => {
  const dispatch = useDispatch()
  const { isPatient, loggedInAsSite } = useSelector(store => store.auth)
  const { composeMessage, systemIDsBySite, sitesByCountries, usersBySite } = useSelector(
    store => store.studyMessages
  )
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const {
    countriesLookup,
    categoriesLookup,
    // programsLookup,
    systemIDsByLoggedInSite,
    messagesSendCategory,
    systemIDs
  } = useSelector(store => store.common)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)

  // this permission is true for only bbk and concierge
  const canSelectSite = useRestriction('read', 'conciergeMsgsWithSitesSitesDropdown')

  const canSelectDistributionDate = useRestriction('read', 'conciergeMsgsWithSitesDistributionDate')

  const countriesSitesUsersWrapper =
    useRestriction('read', `${selectedCommunicationTabId}CountriesDropdown`) ||
    useRestriction('read', `${selectedCommunicationTabId}SitesDropdown`) ||
    useRestriction('read', `${selectedCommunicationTabId}SelectUsers`)

  useEffect(() => {
    if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients) {
      dispatch(fetchSystemIDs())
    }
    if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites) {
      dispatch(fetchMessagesSendCategory(sendToOptionsFor[selectedCommunicationTabId]))
    }
    if (loggedInAsSite) {
      dispatch(fetchSystemIDsByLoggedInSite())
    }
  }, [selectedCommunicationTabId])

  let systemIdOptionsForAdmin = {
    ...systemIDsBySite
  }

  if (selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients) {
    systemIdOptionsForAdmin = {
      ...systemIDs
    }
  }

  const updatedInitialValues = {
    ...composeMessage,
    body: convertHtmlToDraft(composeMessage.body)
  }

  const sendToOptions = []

  // send to radio option to have only options,
  // because messages to patients is a separate tab and messages to bbk are only possible when logged in as site user

  messagesSendCategory.results.forEach(category => {
    if (
      category.id === messageSendCategoryIDs.allActiveUsers ||
      category.id === messageSendCategoryIDs.individualUsers
    ) {
      sendToOptions.push({
        value: category.id,
        label: category.displayText
      })
    }
  })

  const onSendToChange = (value, setFieldValue, formValues) => {
    const sendToValue = parseInt(value)
    setFieldValue('sendTo', sendToValue)
    // user might switch to individual users radio option, after selecting countries and sites
    // therefore fetch users per sites
    if (
      sendToValue === messageSendCategoryIDs.individualUsers &&
      formValues.siteProtocols.length > 0
    ) {
      dispatch(fetchUsersBySite(formValues.siteProtocols))
    }
  }

  const onCountriesChange = (selectedValues, reason, setFieldValue) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      // Dynamic dropdown options for Sites based on country selection
      if (selectedValues.find(option => option.id === 'select-all')) {
        setFieldValue('countries', countriesLookup.results)
        dispatch(fetchSitesByCountries(countriesLookup.results))
      } else {
        setFieldValue('countries', selectedValues)
        if (selectedValues.length > 0) {
          dispatch(fetchSitesByCountries(selectedValues))
        }

        if (reason === 'removeOption') {
          // Empty the selected sites, users, systemIds if any
          // and as no sites are selected, clear both dropdown options and selected values  of users and system IDs dropdown
          setFieldValue('siteProtocols', [])
          setFieldValue('systemId', null)
          setFieldValue('users', [])
          dispatch(setSystemIDsBySite({ results: [] }))
          dispatch(setUsersBySite({ results: [] }))
        }
      }
    } else if (reason === 'clear') {
      setFieldValue('countries', [])
      setFieldValue('siteProtocols', [])
      setFieldValue('systemId', null)
      setFieldValue('users', [])
      dispatch(onClearOfCountriesDropdown())
    }
  }

  const fetchCountries = formValues => {
    dispatch(fetchCountriesLookup())
    // following lookup api calls are triggered only when there are pre-selected values in the form elements
    if (formValues.countries.length > 0) {
      dispatch(fetchSitesByCountries(formValues.countries))
    }
    // when sending to individual user and when user already selected a site and now changing the country
    if (
      formValues.sendTo === messageSendCategoryIDs.individualUsers &&
      formValues.siteProtocols.length > 0
    ) {
      dispatch(fetchUsersBySite(formValues.siteProtocols))
    }
    if (formValues.siteProtocols.length === 1) {
      dispatch(fetchSystemIDsBySite(formValues.siteProtocols[0].id))
    }
  }

  const onSiteProtocolChange = (selectedValues, reason, setFieldValue, formValues) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValues.find(option => option.id === 'select-all')) {
        setFieldValue('siteProtocols', sitesByCountries.results)
        // when sending to individual user
        if (formValues.sendTo === messageSendCategoryIDs.individualUsers) {
          dispatch(fetchUsersBySite(sitesByCountries.results))
        }
      } else {
        setFieldValue('siteProtocols', selectedValues)

        // Dynamic dropdown options for users dropdown and System ID based on site selection
        // when sending to individual user
        if (formValues.sendTo === messageSendCategoryIDs.individualUsers) {
          if (selectedValues.length > 0) {
            dispatch(fetchUsersBySite(selectedValues))
          } else {
            setFieldValue('users', [])
            dispatch(setUsersBySite({ results: [] }))
          }
        }

        if (selectedValues.length === 1) {
          dispatch(fetchSystemIDsBySite(selectedValues[0].id))
        } else {
          setFieldValue('systemId', null)
          dispatch(setSystemIDsBySite({ results: [] }))
        }
      }
    } else if (reason === 'clear') {
      setFieldValue('siteProtocols', [])
      setFieldValue('systemId', null)
      setFieldValue('users', [])
      dispatch(setSystemIDsBySite({ results: [] }))
      dispatch(setUsersBySite({ results: [] }))
    }
  }

  const canSelectSystemId = isPatient
    ? useRestriction('read', 'patientConciergeMsgsSystemId')
    : selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithPatients

  const validationConditions = {
    isCountriesPermission: useRestriction('read', `${selectedCommunicationTabId}CountriesDropdown`),
    isSitesPermission: canSelectSite,
    isSystemPermission: canSelectSystemId,
    isDistributionDatePermission: canSelectDistributionDate
  }

  return (
    <Formik
      initialValues={updatedInitialValues}
      enableReinitialize={false}
      validationSchema={validationSchema(validationConditions)}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          publishMessage(
            {
              ...values,
              body: convertDraftToHTML(values.body)
            },
            patientInformation.userId,
            selectedCommunicationTabId,
            onCancel // this will close the form after success
          )
        )
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue }) => (
        <StyledForm noValidate>
          <>
            <>
              <Restriction
                ability="read"
                feature={`${selectedCommunicationTabId}SendToRadioButtons`}
              >
                <SendToGrid container spacing={2}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <CustomRadioGroup
                      label="Send To:"
                      name="sendTo"
                      id="sendTo"
                      options={sendToOptions}
                      onChange={e => {
                        onSendToChange(e.target.value, setFieldValue, values)
                      }}
                      fullWidth={true}
                      row={true}
                      orientation="row"
                      color="primary"
                      formik="false"
                      size="small"
                      value={values.sendTo}
                    />
                  </Grid>
                </SendToGrid>
              </Restriction>

              <GridContainer background={`${countriesSitesUsersWrapper}`} container spacing={2}>
                <Restriction
                  ability="read"
                  feature={`${selectedCommunicationTabId}CountriesDropdown`}
                >
                  <AutoCompleteWrapper item xs={12} sm={5} md={3} lg={3}>
                    <AutoCompleteWithLabel
                      multiple={true}
                      hasSelectAllOption={true}
                      size="small"
                      id="selectCountries"
                      name="countries"
                      label="Countries:"
                      options={countriesLookup.results}
                      onChange={(event, selectedValues, reason) =>
                        onCountriesChange(selectedValues, reason, setFieldValue)
                      }
                      isLoading={countriesLookup.isLoading}
                      fetchOptions={() => fetchCountries(values)}
                      required
                    />
                  </AutoCompleteWrapper>
                </Restriction>

                <Restriction ability="read" feature={`${selectedCommunicationTabId}SitesDropdown`}>
                  <AutoCompleteWrapper item xs={12} sm={5} md={3} lg={3}>
                    <AutoCompleteWithLabel
                      multiple={true}
                      hasSelectAllOption={true}
                      size="small"
                      id="selectSites"
                      name="siteProtocols"
                      onChange={(event, selectedValues, reason) =>
                        onSiteProtocolChange(selectedValues, reason, setFieldValue, values)
                      }
                      label="Sites:"
                      isLoading={sitesByCountries.isLoading}
                      options={sitesByCountries.results}
                      matchWith="alternateIdentifier"
                      required
                    />
                  </AutoCompleteWrapper>
                </Restriction>
                <Restriction
                  ability="read"
                  feature={`${selectedCommunicationTabId}SendToRadioButtons`}
                >
                  {values.sendTo && values.sendTo === messageSendCategoryIDs.individualUsers
                    ? (
                      <AutoCompleteWrapper item xs={12} sm={5} md={3} lg={3}>
                        <AutoCompleteWithLabel
                          multiple={true}
                          hasSelectAllOption={true}
                          size="small"
                          id="selectUsers"
                          name="userEntities"
                          label="Users:"
                          isLoading={usersBySite.isLoading}
                          options={usersBySite.results}
                          matchWith="alternateIdentifier"
                        />
                      </AutoCompleteWrapper>
                    )
                    : (
                      ''
                    )}
                </Restriction>
              </GridContainer>
            </>

            <GridContainer background={'true'} container spacing={2}>
              <AutoCompleteWrapper item xs={12} sm={5} md={3} lg={3}>
                <AutoCompleteWithLabel
                  multiple={false}
                  hasSelectAllOption={false}
                  size="small"
                  id="selectCategories"
                  name="category"
                  label="Category:"
                  required
                  options={categoriesLookup.results}
                  isLoading={categoriesLookup.isLoading}
                  fetchOptions={() => dispatch(fetchCategoriesLookup(selectedCommunicationTabId))}
                />
              </AutoCompleteWrapper>

              {/* <AutoCompleteWrapper item xs={12} sm={5} md={3} lg={3}>
                  <AutoCompleteWithLabel
                    multiple={false}
                    hasSelectAllOption={false}
                    size="small"
                    id="selectProgram"
                    name="program"
                    label="Program:"
                    required
                    options={programsLookup.results}
                    isLoading={programsLookup.isLoading}
                    fetchOptions={() => dispatch(fetchProgramsLookup())}
                  />
                </AutoCompleteWrapper> */}

              {/* Multiple systemIDs can be selected only when site logs in */}
              {loggedInAsSite
                ? (
                  <>
                    <SystemIDGrid item xs={12} sm={5} md={3} lg={3}>
                      <SystemIDDropDown>
                        <AutoCompleteWithLabel
                          multiple={false}
                          hasSelectAllOption={false}
                          size="small"
                          id="selectSystemID"
                          name="systemId"
                          label={
                            <SystemIDLabel>
                              <Typography variant="body2">TCN #:</Typography>
                              <Tooltip
                                color="primary"
                                size="small"
                                title={
                                  <Typography variant="body1">
                                  In order to select a TCN #, only one site can be selected in the
                                  Sites dropdown
                                  </Typography>
                                }
                              >
                                <Error />
                              </Tooltip>
                            </SystemIDLabel>
                          }
                          options={systemIDsByLoggedInSite.results}
                          matchWith="displayText"
                          isLoading={systemIDsByLoggedInSite.isLoading}
                        />
                      </SystemIDDropDown>
                    </SystemIDGrid>
                    <Grid item xs={12} sm={5} md={3} lg={3}>
                      <SystemIdNote variant="caption">
                      If this message pertains to a specific patient, select their TCN #
                      </SystemIdNote>
                    </Grid>
                  </>
                )
                : (
                  <Restriction ability="read" feature="patientConciergeMsgsSystemId">
                    <SystemIDGrid item xs={12} sm={5} md={3} lg={3}>
                      <SystemIDDropDown>
                        <AutoCompleteWithLabel
                          hasSelectAllOption={false}
                          multiple={false}
                          size="small"
                          id="selectSystemID"
                          name="systemId"
                          label="TCN #:"
                          options={systemIdOptionsForAdmin.results}
                          isLoading={systemIdOptionsForAdmin.isLoading}
                          matchWith="displayText"
                        />
                      </SystemIDDropDown>
                    </SystemIDGrid>
                  </Restriction>
                )}
            </GridContainer>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  required
                  size="medium"
                  id="subject"
                  label="Subject:"
                  name="subject"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledPaper elevation={1}>
                  <RichTextEditor id="body" name="body" />
                </StyledPaper>
              </Grid>
              {isPatient && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" gutterBottom>
                    IMPORTANT: Never send questions or comments regarding your health status and /
                    or the way you are feeling through TCN. You must contact your healthcare
                    provider and / or your study staff directly regarding such matters.
                  </Typography>
                </Grid>
              )}
              <AttachWrapper item xs={12} sm={12} md={12} lg={12}>
                <AttachFile
                  label="Attachment:"
                  name="attachments"
                  onDelete={deletedFile => {
                    setFieldValue('attachmentsToBeDeleted', [
                      ...values.attachmentsToBeDeleted,
                      deletedFile.id
                    ])
                  }}
                  onFileUploadFailure={msg => {
                    dispatch(showWarning(msg))
                  }}
                />
              </AttachWrapper>
              <Restriction ability="read" feature="conciergeMsgsWithSitesDistributionDate">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <DatePickerWithLabel
                    id="distribution-date"
                    label="Distribution Date:"
                    required={true}
                    name="distributionDate"
                    minDate={new Date()}
                    size="small"
                  />
                </Grid>
              </Restriction>

              <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                <Button color="primary" type="submit" size="medium">
                  {isPatient || loggedInAsSite || !values.distributionDate
                    ? 'Send'
                    : displayDate(values.distributionDate) === displayDate(new Date())
                      ? 'Send Now'
                      : 'Send Later'}
                </Button>
                <Button
                  size="medium"
                  color="inherit"
                  onClick={() => {
                    onCancel()
                  }}
                >
                  Cancel
                </Button>
              </ActionWrapper>
            </Grid>
          </>
        </StyledForm>
      )}
    </Formik>
  )
}

ComposeMessage.propTypes = {
  onCancel: PropTypes.func
}

export default ComposeMessage
