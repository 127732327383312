import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Grid, Typography, Paper } from '@mui/material'
import * as constants from 'components/helper/constants/visitServices'
import { patientTabIDs } from 'components/helper/constants/common'
import QuickView from 'components/common/QuickView'
import CloseConfirmation from 'components/common/CloseConfirmation'
import SubmitConfirmationSuccess from '../SubmitConfirmationSuccess'
import AdvancedSupportForm from './advancedSupportForm/AdvancedSupportForm'
import AuxillarySupportForm from './auxillarySupportForm/AuxillarySupportForm'
import InPersonRequestForm from './inPersonRequestForm/InPersonRequestForm'
import RemoteRequestForm from './remoteRequestForm/RemoteRequestForm'
import { Button, PageHeader } from 'components/common'
import {
  setOpenSubmitSuccess,
  setStartNewRequestForVisitFlow
} from 'store/ducks/visitServices/requestDetails'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(5)
  }
}))

const EmptyFooter = styled.div`
  padding: 0px;
`

const MakeARequest = () => {
  const dispatch = useDispatch()
  const { instanceID, patientId, requestType } = useParams()
  const { openSubmitSuccess } = useSelector(store => store.requestDetails)
  const navigate = useNavigate()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const backHandler = () => {
    navigate('../makearequest')
  }

  const closeConfirmationHandler = () => {
    setShowConfirmation(false)
    // If User leaves the form clear the StartNewRequestForVisitFlow data
    // reset the startNewRequestForVisitFlow data
    dispatch(setStartNewRequestForVisitFlow(null))
  }

  const closeSubmitSuccessHandler = () => {
    // Close Popup
    dispatch(setOpenSubmitSuccess(false))
    // reset the startNewRequestForVisitFlow data
    dispatch(setStartNewRequestForVisitFlow(null))
    // Show Dashboard
    navigate(
      `/instance/${instanceID}/patient-dashboard/${patientId}/${patientTabIDs.visitSupportServices}/arriveDashboard`
    )
  }

  // Called in Success Confirmation Popup to start NewRequest For Same Request
  // Navigate to MakeARequest page, populate Visit Date and Visit Name
  const startNewRequestForSameVisit = () => {
    // Close Popup
    dispatch(setOpenSubmitSuccess(false))
    // start the startNewRequestForVisitFlow (data already in submit request)
    // Show Select Request Type page
    navigate(
      `/instance/${instanceID}/patient-dashboard/${patientId}/${patientTabIDs.visitSupportServices}/makearequest`
    )
  }

  // Called in Success Confirmation Popup to start NewRequest for Different Visit
  // Navigate to MakeARequest page, DO NOT populate Visit Date and Visit Name
  const startNewRequestForDifferentVisit = () => {
    // Close Popup
    dispatch(setOpenSubmitSuccess(false))
    // Resent startNewRequestForVisitFlow data
    dispatch(setStartNewRequestForVisitFlow(null))

    navigate(
      `/instance/${instanceID}/patient-dashboard/${patientId}/${patientTabIDs.visitSupportServices}/makearequest`
    )
  }

  return (
    <MainWrapper>
      <PageHeader
        title={
          <Typography variant="h4">
            {requestType === constants.requestType.inPersonVisit &&
              constants.requestLabel.inPersonVisit}
            {requestType === constants.requestType.auxillarySupport &&
              constants.requestLabel.auxillarySupport}
            {requestType === constants.requestType.advancedSupport &&
              constants.requestLabel.advancedSupport}
            {(requestType === constants.requestType.remoteRequest ||
              constants.remoteRequestTypes.includes(requestType)) &&
              constants.requestLabel.remoteRequest}
          </Typography>
        }
        expandContent
        actionContent={
          <Grid container spacing={2}>
            <Grid item>
              <Button onClick={() => setShowConfirmation(true)} size="large" color="primary">
                Back
              </Button>
            </Grid>
          </Grid>
        }
        content={
          <Grid container spacing={0}>
            {requestType === constants.requestType.inPersonVisit && <InPersonRequestForm />}
            {requestType === constants.requestType.auxillarySupport && <AuxillarySupportForm />}
            {requestType === constants.requestType.advancedSupport && <AdvancedSupportForm />}
            {(requestType === constants.requestType.remoteRequest ||
              constants.remoteRequestTypes.includes(requestType)) && (
              <RemoteRequestForm requestType={requestType} />
            )}
          </Grid>
        }
        bar={false}
      />
      <QuickView
        title="Success"
        onClose={closeSubmitSuccessHandler}
        dialogContent={
          <SubmitConfirmationSuccess
            onClose={closeSubmitSuccessHandler}
            startNewRequestForSameVisit={startNewRequestForSameVisit}
            startNewRequestForDifferentVisit={startNewRequestForDifferentVisit}
          />
        }
        isDialogOpen={openSubmitSuccess}
        maxWidth="sm"
        dialogActions={<EmptyFooter />}
      />
      <QuickView
        title="Confirmation"
        onClose={closeConfirmationHandler}
        dialogContent={
          <CloseConfirmation
            closeConfirmation={closeConfirmationHandler}
            onConfirmation={backHandler}
          />
        }
        isDialogOpen={showConfirmation}
        maxWidth="sm"
        dialogActions={<EmptyFooter />}
      />
    </MainWrapper>
  )
}

MakeARequest.propTypes = {}

export default MakeARequest
