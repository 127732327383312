import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'

import { Alert, PropertyValue } from 'components/common'
import { formatCurrency } from 'components/helper/utility'
import { capTypesIds } from 'components/helper/constants/configuration'
import { serviceIds } from 'components/helper/constants/reimbursement'

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: `${theme.spacing(1.5)}`
}))

const ConfirmFundsForReimbursement = () => {
  const {
    selectedVisitName,
    selectedVisitDate,
    servicesEnteredValue,
    serviceIdDisplayTextMapping,
    isLocalCurrency,
    localCurrency,
    cardDetails,
    capTypeRestriction
  } = useSelector(store => store.reimbursement)

  const returnPropertyContent = (category, index, currency) =>
    servicesEnteredValue[category]
      ? (
        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
          <PropertyValue
            label={`${serviceIdDisplayTextMapping[category].displayText}:`}
            value={`${serviceIdDisplayTextMapping[category].currencySymbol}${formatCurrency(
              servicesEnteredValue[category]
            )} ${currency || ''}`}
            align="row"
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
            fullWidth
          />
        </Grid>
      )
      : (
        ''
      )

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {[capTypesIds.softCaps, capTypesIds.reviewAndApprove].includes(capTypeRestriction) && (
            <Alert
              severity="info"
              message="The entry must be reviewed and approved prior to the patient having access to the funds."
              formik="false"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <PropertyValue
            label="Visit Name:"
            value={selectedVisitName?.displayText}
            align="row"
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
            fullWidth
            gutterBottom={2}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <PropertyValue
            label="Visit Date:"
            value={selectedVisitDate}
            align="row"
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
            fullWidth
            gutterBottom={2}
          />
        </Grid>

        {/* Show Service Fields at first and exclude any categories with string-"total" in the field name */}
        {Object.keys(servicesEnteredValue)
          .filter(
            key =>
              ![serviceIds.totalOrTotalInLocalCurrency, serviceIds.totalInCardCurrency].includes(
                parseInt(key)
              )
          )
          .map((category, index) => returnPropertyContent(category, index))}
        <StyledGrid item xs={12} sm={12} md={12} lg={12} />
      </Grid>

      {/* Show Total Fields at the end */}

      {isLocalCurrency
        ? (
          <Grid container spacing={2}>
            {returnPropertyContent(
              serviceIds.totalOrTotalInLocalCurrency,
              serviceIds.totalOrTotalInLocalCurrency,
              localCurrency
            )}
          </Grid>
        )
        : (
          <Grid container spacing={1}>
            {returnPropertyContent(
              serviceIds.totalOrTotalInLocalCurrency,
              serviceIds.totalOrTotalInLocalCurrency,
              localCurrency
            )}
            {returnPropertyContent(
              serviceIds.totalInCardCurrency,
              serviceIds.totalInCardCurrency,
              cardDetails.cardCurrency
            )}
          </Grid>
        )}
    </>
  )
}

export default ConfirmFundsForReimbursement
