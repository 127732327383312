import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Autocomplete, FormHelperText, TextField, Skeleton } from '@mui/material'
import { useField } from 'formik'

const StyledHelperText = styled(FormHelperText)`
  position: relative;
  bottom: 0px;
  left: 5px;
`
const StyledAutoComplete = styled(Autocomplete)(
  ({ theme }) => `
&& {
  .MuiOutlinedInput-root {
    max-height: 80px;
    overflow: hidden;
  }
  .Mui-disabled{
    fieldset {
      background-color: ${theme.palette.gray.light};
    }
    input {
      z-index: ${theme.zIndex.minorUiElement};
    }
    .MuiAutocomplete-endAdornment{
      svg{
        color: ${theme.palette.gray.dark};
      }
    }
    .MuiButtonBase-root{
      z-index: ${theme.zIndex.minorUiElement};
    }
  }
}
`,
)

const LoadingSkeleton = styled(Skeleton)`
    position: relative;
    top: 10px;
}
`

const AutoComplete = props => {
  const {
    label,
    id,
    name,
    options,
    multiple,
    fetchOptions,
    hasSelectAllOption,
    disabled,
    selectedValue,
    onChange,
    onBlur,
    error,
    helperText,
    fullWidth,
    size,
    limitTags,
    required,
    formik,
    isLoading,
  } = props

  let field, meta, setValue

  const onFieldValueChange = (event, selectedValues, reason) => {
    if (multiple) {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (hasSelectAllOption && selectedValues.find(option => option.id === 'select-all')) {
          setValue(options)
        } else {
          setValue(selectedValues)
        }
      } else if (reason === 'clear') {
        setValue([])
      }
    } else {
      setValue(selectedValues)
    }
  }

  if (formik === 'true') {
    [field, meta, { setValue }] = useField(props)
    field = { ...field, onChange: onFieldValueChange }
  } else {
    field = {
      onChange,
      onBlur,
    }
  }

  useEffect(() => {
    if (fetchOptions) {
      fetchOptions()
    }
  }, [])

  let formattedOptions = options
  if (multiple && formik && hasSelectAllOption) {
    formattedOptions = [{ id: 'select-all', displayText: 'Select All' }, ...options]
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <>
      {isLoading
        ? (
          <LoadingSkeleton height={50} animation="wave" variant="rectangular" />
        )
        : (
          <StyledAutoComplete
            limitTags={limitTags}
            getOptionLabel={option => option.displayText}
            options={formattedOptions}
            id={id}
            renderInput={params => (
              <TextField
                {...params}
                error={isError}
                label={label}
                fullWidth={fullWidth}
                required={required}
                size={size}
              />
            )}
            name={name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple={multiple}
            disabled={disabled}
            ChipProps={{ size: 'small', color: 'secondary' }}
            value={selectedValue}
            size={size}
            {...field}
          />
        )}
      {isError && errorText && (
        <StyledHelperText component="div" error={isError}>
          {errorText}
        </StyledHelperText>
      )}
    </>
  )
}

AutoComplete.propTypes = {
  /**
   * Options must be in array of objects format with each field as id and display text.
   */
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, displayText: PropTypes.string })).isRequired,
  /**
   * Label of the field.
   */
  label: PropTypes.string.isRequired,
  /**
   * ID for the field.
   */
  id: PropTypes.string.isRequired,
  /**
   * Name for the field. When formik is true, this will also used for validations.
   */
  name: PropTypes.any.isRequired,
  /**
   * When true, user can selected multiple values.
   * @default false
   */
  multiple: PropTypes.bool,
  /**
   * Pass a service call that returns array of options.
   */
  fetchOptions: PropTypes.func,
  /**
   * When true, there will be a select all option in the dropdown.
   * This functionality is available when multiple props is true and when formik is true.
   * @default false
   */

  hasSelectAllOption: PropTypes.bool,
  /**
   * When true, field is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle  onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),
  /**
   * Use this only for non-formik forms, because when formik, the initial value given in the formik wrapper will be passed to this field
   * inside a `field` object and field.value will override this props.
   * - For single select dropdown i.e when `multiple` is false, the value must be an object with id and display text.
   * - Whereas for `multiple` is true, the value must be an array of objects with id and display text.
   */
  selectedValue: PropTypes.oneOfType([
    PropTypes.shape({ id: PropTypes.any, displayText: PropTypes.string }),
    PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, displayText: PropTypes.any })),
  ]),
  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own onChange
   * inside a `field` object and field.onChange will override this props.
   * * Here is onChange example, which will take care all the scenarios - multi-select, select all, single select
   * `const onFieldValueChange = (event, selectedValues, reason) => {`
    `if (multiple) {`
    `if (reason === 'selectOption' || reason === 'removeOption') {`
        `if (hasSelectAllOption && selectedValues.find((option) => option.id === 'select-all')) {`
          `setValue(options)`
        `} else {`
          `setValue(selectedValues)`
        `}`
     ` } else if (reason === 'clear') {`
       ` setValue([])`
      `}`
    `} else {`
      `setValue(selectedValues)`
   ` }`
 ` }`
   */
  onChange: PropTypes.func,
  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own onBlur
   * inside a `field` object and field.onBlur will override this props.
   * - Information on onChange https://mui.com/api/autocomplete/
   */
  onBlur: PropTypes.func,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * When true, The width of this field is spread covering its parent wrapper or container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * Decides the size of the component.
   * @default 'small'
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Limits the number of selected options in input field. Works only when multiple is true.
   */
  limitTags: PropTypes.number,
  /**
   * Makes this field required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * Control the loading state of the element
   *
   * when `true` a skeleton loading animation is shown.
   * @default false
   */
  isLoading: PropTypes.bool,
}

AutoComplete.defaultProps = {
  multiple: false,
  disabled: false,
  formik: 'true',
  fullWidth: true,
  size: 'small',
  hasSelectAllOption: false,
  limitTags: 2,
  required: false,
  isLoading: false,
}

export default AutoComplete
