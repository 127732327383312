import { createAction, createReducer } from '@reduxjs/toolkit'

import { getCampaignDetails, saveCampaignDetails } from 'services/configuration/protocolCampaign'
import { showError } from '../application'
import { setLoading } from '../userInteractions'
import { copyObject } from 'components/helper/utility'

export const setProtocolCampaignDetails = createAction(
  'protocolCampaignConfiguration/setProtocolCampaignDetails'
)
export const resetProtocolCampaignConfiguration = createAction(
  'protocolCampaignConfiguration/resetProtocolCampaignConfiguration'
)

export const fetchCampaignDetails = () => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setProtocolCampaignDetails({ isLoading: true }))
  try {
    const { data } = await getCampaignDetails()
    // Campaign Name is InstanceName
    // Protocol Number is ProtocolCode
    // Instance ID is InstanceCode
    const results = {
      campaignName: data.instanceName ? data.instanceName : '',
      protocolNumber: data.protocolCode ? data.protocolCode : '',
      instanceId: data.instanceCode ? data.instanceCode : ''
    }

    dispatch(setProtocolCampaignDetails({ results, isLoading: false }))
  } catch (e) {
    dispatch(
      setProtocolCampaignDetails({
        isLoading: false,
        results: initialState.protocolCampaignDetails.results
      })
    )
    dispatch(
      showError('There was some error while fetching campaign details. Please try again later.', e)
    )
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateCampaignDetails = data => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setProtocolCampaignDetails({ isLoading: true }))
  try {
    await saveCampaignDetails(data)
    await dispatch(fetchCampaignDetails())
  } catch {
    dispatch(setProtocolCampaignDetails({ isLoading: false }))
    dispatch(showError('There was some issue while updating campaign details'))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  protocolCampaignDetails: {
    isLoading: false,
    results: {
      campaignName: '',
      protocolNumber: '',
      instanceId: ''
    }
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setProtocolCampaignDetails, (state, action) => {
      state.protocolCampaignDetails = { ...state.protocolCampaignDetails, ...action.payload }
    })
    .addCase(resetProtocolCampaignConfiguration, state => {
      copyObject(state, initialState)
    })
})
