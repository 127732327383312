import fileDownload from 'js-file-download'
import { cohortsLookup } from 'mockData/announcements'
import {
  backendAxios,
  fetchWithRetries,
  getConfig,
  getConfigForDownload,
  getConfigForFileUpload
} from './backend'

/** Common API Calls */

export const getCountries = () =>
  backendAxios
    .get('/common/getcountries', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getCountriesForTransferFunds = () =>
  backendAxios
    .get('/common/reimbursementcountries', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getStateDropDownValues = countryId =>
  backendAxios
    .get('/common/getstatesbycountry', getConfig({ countryId }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getGender = () =>
  backendAxios
    .get('/common/getgender', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getEnglishSkillLevels = () =>
  backendAxios
    .get('/common/englishskilllevels', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSupportPersonRelationships = () =>
  backendAxios
    .get('/common/getsupportpersonrelationships', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getLanguages = () =>
  backendAxios
    .get('/common/getlanguages', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSupportPersonTypes = () =>
  backendAxios
    .get('/common/getsupportpersontypes', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getMeansOfCommunication = () =>
  backendAxios
    .get('/common/getcommunicationtypes', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getMobilityTypes = () =>
  backendAxios.get('/common/getmobilitytypes', getConfig()).then(response => response.data)

export const getTeleHealthParticipantTypes = () =>
  backendAxios
    .get('/common/telehealthparticipanttypes', getConfig())
    .then(response => response.data)

export const getAdvancedSupportTypes = () =>
  backendAxios.get('/common/advancedsupporttypes', getConfig()).then(response => response.data)

export const getEnrollmentCodes = uniqueSiteProtocolId =>
  fetchWithRetries(
    'GET',
    `/common/enrollmentcodes?cache=${Date.now()}`,
    getConfig({ uniqueSiteProtocolId })
  )

export const getSubjectSources = () =>
  backendAxios
    .get('/common/subjectsources', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getParticipationStatus = () =>
  backendAxios
    .get('/common/participationstatus', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getAuxillarySupportTypes = () =>
  backendAxios
    .get('/common/auxillarysupporttypes', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const postAttachments = files =>
  backendAxios
    .post('/attachment', files, getConfigForFileUpload())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getOtherServiceTypes = () =>
  backendAxios
    .get('/common/otherservicetypes', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const downloadAttachmentByID = attachment =>
  backendAxios
    .get(`/attachment/download/${attachment.id}`, getConfigForDownload())
    .then(response => {
      fileDownload(response.data, `${attachment.name}`)
    })
    .catch(err => Promise.reject(err.message))

export const downloadResourceDocsByID = attachment => {
  const url = attachment.documentType
    ? `/v7attachment/download/${attachment.id}/${attachment.documentType}/${attachment.isViewed}`
    : `/v7attachment/download/${attachment.id}/${null}/${attachment.isViewed}`
  return backendAxios
    .get(url, getConfigForDownload())
    .then(response => {
      fileDownload(response.data, `${attachment.name}`)
      return Promise.resolve('message')
    })
    .catch(err => Promise.reject(err.message))
}

export const getResourceDocsByID = attachment => {
  const url = attachment.documentType
    ? `/v7attachment/download/${attachment.id}/${attachment.documentType}/${attachment.isViewed}`
    : `/v7attachment/download/${attachment.id}/${null}/${attachment.isViewed}`
  return backendAxios
    .get(url, getConfigForDownload())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err.message))
}

// export const getResourceDocsByURL = url =>
//   backendAxios
//     .get(`${url}`, getConfigForDownload())
//     .then(response => Promise.resolve(response))
//     .catch(err => Promise.reject(err.message))

export const getCategories = () =>
  backendAxios
    .get('/common/category', getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getCategoriesForWithPatients = () =>
  backendAxios
    .get('/common/message/patientcategories', getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getPrograms = () =>
  backendAxios
    .get('/common/program', getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getRSGArriveRequestAssignments = () =>
  backendAxios
    .get('/common/RSGArriveRequestAssignments', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

export const getSitesLookup = () =>
  backendAxios
    .get('/site/list/user', getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getSystemIDs = siteProtocolID =>
  backendAxios
    .get('/user/systemids', getConfig({ siteProtocolID }))
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getSystemIDsByLoggedInSite = () =>
  backendAxios
    .get('/user/systemids/loggeduser', getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getMessagesSendCategory = entityTypeID =>
  /**
   * @entityTypeID is the module(studyMessages or announcements) for which
   * sendTo items(example: all active users, site, patients etc) are needed
   */
  backendAxios
    .get(`/common/messagesendcategory/${entityTypeID}`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSitesByCountries = countryIds =>
  backendAxios
    .get('/site/list/countries', getConfig({ countryIds }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getProductsLookup = () =>
  backendAxios
    .get('/common/getproducts', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

// Todo! connect to API, when API is ready with below end-point
export const getCohortsLookup = () =>
  new Promise(resolve => {
    resolve({ data: cohortsLookup })
  })

export const getVendorsLookup = () =>
  backendAxios
    .get('/common/vendors', getConfig())
    .then(res => res)
    .catch(err => Promise.reject(err))

export const getSiteTypesLookup = () =>
  backendAxios
    .get('/common/V7sitetypes', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getSiteStatusesLookup = () =>
  backendAxios
    .get('/common/V7sitestatuses', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getSiteNoteTypesLookup = () =>
  backendAxios
    .get('/common/V7sitenotetypes', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getSiteDataPointTypes = () =>
  backendAxios
    .get('/common/sitedatapointtypes', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getAddressTypes = () =>
  backendAxios
    .get('/common/addresstypes', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getHelperAttachmentModules = () =>
  fetchWithRetries('GET', '/common/helperattachmentmodules', getConfig())

export const getHelperAttachments = attachmentModuleId =>
  backendAxios
    .get('/common/helperattachments', getConfig({ attachmentModuleId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const downloadHelperAttachmentById = attachment =>
  backendAxios
    .get('/common/download/helperattachment', getConfigForDownload({ attachmentId: attachment.id }))
    .then(response => {
      fileDownload(response.data, `${attachment.name}`)
    })
    .catch(err => Promise.reject(err))

export const uploadHelperAttachment = formData =>
  backendAxios
    .post('/common/uploadhelperattachment', formData, getConfigForFileUpload())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const deleteHelperAttachment = payload =>
  backendAxios
    .patch('/common/deletehelperattachment', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getTacticsLookup = () =>
  backendAxios
    .get('/common/mediaplantactics', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getMediaPlanSitesLookup = () =>
  backendAxios
    .get('/common/mediaplansites', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getMediaPlanStatusesLookup = () =>
  backendAxios
    .get('/common/mediaplanstatuses', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
