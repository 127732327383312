import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { performLogout } from 'store/ducks/auth'

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(performLogout())
  })

  return <Navigate to="/login" replace />
}

export default Logout
