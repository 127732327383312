import { backendAxios, fetchWithRetries, getConfig } from 'services/backend'

export const getPatientInformation = id =>
  backendAxios
    .get('/patientprofile/getpatientinformation', getConfig({ userId: id }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getAllSupportPersons = patientId =>
  backendAxios
    .get('/patientprofile/getallsupportpersons', getConfig({ userId: patientId }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const updatePatientInformation = payload =>
  backendAxios
    .post('/patientprofile/updatepatientinformation', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const savePatientSupportPersonInformation = payload =>
  backendAxios
    .post('/patientprofile/savepatientsupportpersoninformation', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const saveSupplementalInformation = payload =>
  backendAxios
    .post('/patientprofile/supplementalinformation', payload, getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

export const getSupplementalInformation = ({ userId, userSupportPersonId }) =>
  backendAxios
    .get('/patientprofile/getsupplementalinformation', getConfig({ userId, userSupportPersonId }))
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

export const getPatientAddress = userId =>
  backendAxios
    .get('/patientprofile/getpatientaddress', getConfig({ userId }))
    .then(response => response.data)

export const updatePatientEmail = requestData =>
  backendAxios
    .post('/patientprofile/updatepatientemail', requestData, getConfig())
    .then(response => response.data)

export const updateSupportPersonEmail = requestData =>
  backendAxios
    .post('/patientprofile/updatesupportpersonemail', requestData, getConfig())
    .then(response => response.data)

export const forgotPasswordForPatient = email =>
  backendAxios.post('/sendpassword', { email }, getConfig())

export const setPatientViewed = userId =>
  fetchWithRetries('POST', '/patientprofile/viewed', getConfig(), { userId })

export const postPatientReactivation = (userId, currentInstanceId) =>
  backendAxios
    .post('/patientprofile/reactivate', { userId, currentInstanceId }, getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))
