import React, { useState } from 'react'
import { Alert, AttachFile, Button, DataGrid, TextBoxOuterLabel } from 'components/common'
import { Grid, IconButton, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Close, Publish } from '@mui/icons-material'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import ExceededAmount from './ExceededAmount'
import { palette } from 'styles/baseTheme'
import QuickView from 'components/common/QuickView'
import {
  onSubmittingNewFunds,
  onAmountsExceededModalSubmit,
  setOthersCatModal,
  setAmountsExceededModal,
  setNoteRequiredModal,
  setConfirmReimbursementModal,
  onSubmittingReimbursement,
  setFundsAddedSuccessModal
} from 'store/ducks/patientRecord/reimbursement'
import { showWarning } from 'store/ducks/application'
import ConfirmFundsForReimbursement from './ConfirmFundsForReimbursement'
import { formatCurrency } from 'components/helper/utility'
import { Restriction, useRestriction } from 'permissions'
import { capTypesIds } from 'components/helper/constants/configuration'
import { serviceIds } from 'components/helper/constants/reimbursement'

const FieldWrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing(1, 2)}`,
  svg: {
    fontSize: '1em'
  }
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  backgroundColor: `${theme.palette.gray.light}`,
  height: `${theme.spacing(4)}`,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
}))

const ReceiptWrapper = styled.div(() => ({
  height: '100%',
  p: {
    position: 'relative',
    bottom: '12px'
  }
}))

const ActionWrapper = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

const ModalActionsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  button: {
    marginLeft: `${theme.spacing(2)}`
  }
}))

const UploadReceiptsModalActions = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  button: {
    marginLeft: `${theme.spacing(2)}`,
    marginTop: `${theme.spacing(2)}`
  }
}))
const FileNameWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(1)}`,
  marginBottom: `${theme.spacing(2)}`,
  backgroundColor: `${theme.palette.background.disabled}`,
  width: '90%'
}))

const SelectedFile = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'center',
  minWidth: '140px'
}
const bodyProperties = { cellType: 'node', padding: '0px', textAlign: 'center' }

const AddFundsGrid = () => {
  const dispatch = useDispatch()
  const {
    addFundsGridHeaders,
    servicesEnteredValue,
    othersCatModal,
    amountsExceededModal,
    amountsExceedNote,
    selectedVisitName,
    selectedVisitDate,
    noteRequiredModal,
    confirmReimbursementModal,
    fundsAddedSuccessModal,
    patientInformationForReimbursement,
    capTypeRestriction
  } = useSelector(store => store.reimbursement)
  const [uploadReceiptsModal, setUploadReceiptsModal] = useState(false)
  const [receipts, setReceipts] = useState([])
  const canReadAddFundsReceiptsColumn = useRestriction('read', 'addFundsReceiptsColumn')

  const rowData = {}
  const finalHeaders = []

  addFundsGridHeaders.forEach(item => {
    if (item.id === 'receipts') {
      if (canReadAddFundsReceiptsColumn) {
        finalHeaders.push({
          id: 'receipts',
          label: 'Receipts',
          headProperties: { ...headProperties, minWidth: '50px' },
          bodyProperties: { ...bodyProperties }
        })
      }
    } else {
      finalHeaders.push({
        ...item,
        headProperties: { ...headProperties },
        bodyProperties: { ...bodyProperties }
      })
    }
    rowData[item.id] = (
      <>
        <StyledLabel variant="body1">
          {item.serviceId === serviceIds.totalInCardCurrency
            ? item.currency
            : `${formatCurrency(item.maxAmount)} ${item.currency}`}
        </StyledLabel>
        <FieldWrapper>
          <TextBoxOuterLabel
            type="number"
            id={`${item.service}-${item.serviceId}`}
            label=""
            name={item.serviceId}
            fullWidth={true}
            size="small"
            startAdornment={item.currencySymbol}
            disabled={item.disabled}
            inputProps={{ min: 0 }}
          />
        </FieldWrapper>
      </>
    )
  })

  const addFundsGridData = []
  if (canReadAddFundsReceiptsColumn) {
    addFundsGridData.push({
      ...rowData,
      receipts: (
        <ReceiptWrapper>
          <StyledLabel variant="body1"> </StyledLabel>
          <IconButton aria-label="upload-receipt" onClick={() => setUploadReceiptsModal(true)}>
            <Publish />
          </IconButton>
        </ReceiptWrapper>
      )
    })
  } else {
    addFundsGridData.push({ ...rowData })
  }

  return (
    <>
      {addFundsGridHeaders.length && addFundsGridData.length && finalHeaders.length
        ? (
          <Formik
            initialValues={servicesEnteredValue}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(onSubmittingNewFunds(values))
              setSubmitting(false)
            }}
          >
            {({ values, setFieldValue }) => (
              <Form
                noValidate
                onChange={event => {
                // Keep adding funds together as user enters values in each category and then set it to the total column
                  let totalFunds = 0
                  const fieldName = parseInt(event.target.name)

                  if (event.target.value) {
                    for (const category of Object.keys(values)) {
                      // all the keys are coming as string from the TextField,
                      // so convert them to integer here
                      const serviceId = parseInt(category)
                      if (
                        serviceId !== serviceIds.totalOrTotalInLocalCurrency &&
                      serviceId !== serviceIds.totalInCardCurrency &&
                      serviceId !== fieldName &&
                      values[serviceId]
                      ) {
                        totalFunds += parseFloat(values[serviceId])
                      }
                    }
                    totalFunds += parseFloat(event.target.value)
                  } else {
                  // This means user is removing values from one of the fields
                  // find the total category in the object(values)

                    if (values[serviceIds.totalOrTotalInLocalCurrency]) {
                      totalFunds = parseFloat(values[serviceIds.totalOrTotalInLocalCurrency])
                      totalFunds -= values[fieldName]
                    }
                  }

                  // convert the number to have always two decimals
                  const finalTotalFund = formatCurrency(totalFunds)

                  setFieldValue(serviceIds.totalOrTotalInLocalCurrency, finalTotalFund)
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataGrid
                      headers={finalHeaders}
                      tableData={addFundsGridData}
                      order={'DESC'}
                      orderBy={'createdDate'}
                      border="allSides"
                      radius={'false'}
                    />
                  </Grid>
                  {capTypeRestriction === capTypesIds.hardCaps && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Alert
                        formik='false'
                        severity="error"
                        message="A reimbursement or payment is above the category guideline. Edit the entry to be below or equal to the guideline."
                      />
                    </Grid>
                  )}
                  <Restriction ability="read" feature="addFundsApproveButton">
                    <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        disabled={
                          !(
                            Object.values(values).filter(Boolean).length &&
                            selectedVisitName &&
                            selectedVisitDate
                          )
                        }
                        size="large"
                        fullWidth={false}
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </ActionWrapper>
                  </Restriction>
                </Grid>
              </Form>
            )}
          </Formik>
        )
        : (
          ''
        )}
      <QuickView
        title="Other Category"
        onClose={() => dispatch(setOthersCatModal(false))}
        isDialogOpen={othersCatModal}
        maxWidth="md"
        dialogContent={<ExceededAmount exceededType="other" />}
        dialogActions={
          <Button
            color="primary"
            size="large"
            onClick={() => dispatch(onAmountsExceededModalSubmit(true, amountsExceedNote))}
            disabled={!amountsExceedNote}
          >
            Submit
          </Button>
        }
      />
      <QuickView
        title="Reimbursement Exceeds Category Guideline"
        onClose={() => dispatch(setAmountsExceededModal(false))}
        isDialogOpen={amountsExceededModal}
        maxWidth="md"
        dialogContent={<ExceededAmount exceededType="exceededCap" />}
        dialogActions={
          <Button
            color="primary"
            size="large"
            onClick={() => dispatch(onAmountsExceededModalSubmit(true, amountsExceedNote))}
            disabled={!amountsExceedNote}
          >
            Submit
          </Button>
        }
      />

      <QuickView
        title="Add a Reimbursement Note"
        onClose={() => dispatch(setNoteRequiredModal(false))}
        isDialogOpen={noteRequiredModal}
        maxWidth="md"
        dialogContent={<ExceededAmount exceededType="both" />}
        dialogActions={
          <Button
            color="primary"
            size="large"
            disabled={!amountsExceedNote}
            onClick={() => dispatch(onAmountsExceededModalSubmit(true, amountsExceedNote))}
          >
            Submit
          </Button>
        }
      />
      <QuickView
        title="Confirm"
        onClose={() => dispatch(setConfirmReimbursementModal(false))}
        isDialogOpen={confirmReimbursementModal}
        maxWidth="sm"
        dialogContent={<ConfirmFundsForReimbursement />}
        dialogActions={
          <ModalActionsWrapper>
            <Button
              color="primary"
              size="large"
              onClick={() => dispatch(onSubmittingReimbursement(receipts))}
            >
              Confirm
            </Button>
            <Button
              color="inherit"
              size="large"
              onClick={() => dispatch(setConfirmReimbursementModal(false))}
            >
              Edit
            </Button>
          </ModalActionsWrapper>
        }
      />
      <QuickView
        title="Success!"
        onClose={() => dispatch(setFundsAddedSuccessModal(false))}
        isDialogOpen={!!fundsAddedSuccessModal}
        maxWidth="xs"
        dialogContent={
          patientInformationForReimbursement.isRestrictedCountry
            ? (
              fundsAddedSuccessModal === 'firstReimbursement'
                ? (
                  <Typography variant="body2">
                Thank you. The Concierge team has been notified and will be in touch with you
                shortly. You may call the Concierge directly if you prefer (click &quot;Concierge
                Help&quot; button).
                  </Typography>
                )
                : (
                  <Typography variant="body2">
                Thank you. The Concierge team has been notified and will begin processing the
                reimbursement / payment.
                  </Typography>
                )
            )
            : (
              <Typography variant="body2">
              Please allow up to one minute before checking the Available Balance of the card to
              allow the funds to process.
              </Typography>
            )
        }
      />
      <QuickView
        title="Upload Receipts"
        onClose={() => {
          setUploadReceiptsModal(false)
          setReceipts([])
        }}
        isDialogOpen={uploadReceiptsModal}
        maxWidth="sm"
        closeConfirmation={true}
        dialogContent={
          <SelectedFile>
            {receipts.map((file, index) => (
              <FileNameWrapper key={index}>
                <Typography variant="body1">{file.name}</Typography>
                <IconButton
                  aria-label="cancel-file-upload"
                  onClick={() => {
                    const myReceipts = [...receipts]
                    const fileIndex = receipts.findIndex(receipt => receipt.name === file.name)
                    myReceipts.splice(fileIndex, 1)
                    setReceipts(myReceipts)
                  }}
                  size="small"
                  color="default"
                >
                  <Close />
                </IconButton>
              </FileNameWrapper>
            ))}
          </SelectedFile>
        }
        dialogActions={
          <UploadReceiptsModalActions>
            <AttachFile
              formik="false"
              files={receipts}
              onFileChange={selectedFile => setReceipts([...selectedFile])}
              name="attachments"
              onFileUploadFailure={msg => {
                dispatch(showWarning(msg))
              }}
              showInQuickView={false}
            />
            <div>
              <Button
                fullWidth={false}
                disabled={!receipts.length}
                color="primary"
                onClick={() => setUploadReceiptsModal(false)}
              >
                Upload
              </Button>
              <Button
                fullWidth={false}
                color="inherit"
                onClick={() => {
                  setUploadReceiptsModal(false)
                  setReceipts([])
                }}
              >
                Cancel
              </Button>
            </div>
          </UploadReceiptsModalActions>
        }
      />
    </>
  )
}

export default AddFundsGrid
