import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Typography } from '@mui/material'
import { Button, DataGrid, PageHeader } from 'components/common'
import { fetchMediaPlansData, setAddMediaPlan, setMediaPlanDetails } from 'store/ducks/mediaPlans'
import { Add, Edit } from '@mui/icons-material'
import { palette, combinedBaseTheme } from 'styles/baseTheme'
import styled from '@emotion/styled'
import QuickView from 'components/common/QuickView'
import AddMediaPlan from './AddMediaPlan'
import { useNavigate } from 'react-router-dom'

const initialHeaders = [
  { id: 'manage', label: '' },
  {
    id: 'planName',
    label: 'Media Plan Name',
    isSortable: false
  },
  {
    id: 'numberofParticipatingSites',
    label: 'Number of Participating Sites',
    isSortable: false
  },
  {
    id: 'mediaPlanStatus',
    label: 'Media Plan Status',
    isSortable: false
  },
  {
    id: 'plannedStartDate',
    label: 'Planned Start Date',
    isSortable: false
  },
  {
    id: 'plannedEndDate',
    label: 'Planned End Date',
    isSortable: false
  },
  {
    id: 'actualMediaStartDate',
    label: 'Actual Media Start Date',
    isSortable: false
  },
  {
    id: 'actualMediaEndDate',
    label: 'Actual Media End Date',
    isSortable: false
  },
  {
    id: 'totalBudget',
    label: 'Total Budget',
    isSortable: false
  },
  {
    id: 'totalSpendtoDate',
    label: 'Total Spend to Date',
    isSortable: false
  }
]

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&&': {
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: '30%',
    padding: '4px 5px'
  }
}))

const MediaPlans = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mediaPlansData, addMediaPlan } = useSelector(store => store.mediaPlans)
  const { selectedInstance } = useSelector(store => store.auth)
  const { instanceId } = selectedInstance

  useEffect(() => {
    dispatch(fetchMediaPlansData())
  }, [])

  /* -----DataGrid related actions------ */
  const updatedHeaders = initialHeaders.map(header => {
    const headerProperties = {
      backgroundColor: palette.secondary.main,
      color: palette.primary.main,
      borderBottom: `1px solid ${palette.gray.main}`,
      borderTop: `1px solid ${palette.gray.main}`,
      borderLeft: '0px',
      borderRight: `2px solid ${palette.gray.main}`,
      cellType: 'node',
      padding: '24px 8px',
      textAlign: 'center'
    }
    const bodyProperties = {
      backgroundColor: palette.secondary.main,
      borderLeft: '0px',
      borderBottom: `1px solid ${palette.gray.main}`,
      borderRight: `1px solid ${palette.gray.main}`,
      padding: '14px 0px'
    }

    if (header.id === 'manage') {
      return {
        ...header,
        headProperties: {
          ...headerProperties,
          borderRight: `4px solid ${palette.gray.main}`,
          borderLeft: `1px solid ${palette.gray.main}`,
          borderTopLeftRadius: `${combinedBaseTheme.shape.mediumRadius}`,
          minWidth: '70px'
        },
        bodyProperties: {
          ...bodyProperties,
          cellType: 'node',
          borderRight: `4px solid ${palette.gray.main}`,
          borderLeft: `1px solid ${palette.gray.main}`
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else if (header.id === 'totalSpendtoDate') {
      return {
        ...header,
        headProperties: {
          ...headerProperties,
          borderRight: `1px solid ${palette.gray.main}`,
          borderTopRightRadius: `${combinedBaseTheme.shape.mediumRadius}`
        },
        bodyProperties: {
          ...bodyProperties,
          borderRight: `1px solid ${palette.gray.main}`
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else {
      return {
        ...header,
        headProperties: {
          ...headerProperties
        },
        bodyProperties: {
          ...bodyProperties
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    }
  })

  let updatedMediaListData = []

  updatedMediaListData = mediaPlansData.results.map(item => ({
    ...item,
    manage: (
      <StyledIconButton
        aria-label="actions"
        aria-haspopup="true"
        color="primary"
        onClick={() => {
          dispatch(setMediaPlanDetails({ isLoading: true }))
          navigate(
            `/instance/${instanceId}/media-plans/${item.id}`
          )
        }
        }
      >
        <Edit />
      </StyledIconButton>
    )
  }))

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Media Plans</Typography>}
        actionContent={
          <Button startIcon={<Add />} size="large" fullWidth={false} color="primary" onClick={() => {
            dispatch(setAddMediaPlan(true))
          }}>
            Add Media Plan
          </Button>
        }
      />
      <DataGrid
        headers={updatedHeaders}
        tableData={updatedMediaListData}
        border="none"
        radius="false"
        isLoading={mediaPlansData.isLoading}
        setRequestedSort={() => {}}
        order={'DESC'}
        orderBy={'createdDate'}
      />
      <QuickView
        title="Add Media Plan"
        onClose={() => dispatch(setAddMediaPlan(false))}
        dialogContent={<AddMediaPlan />}
        isDialogOpen={!!addMediaPlan}
        maxWidth="md"
        dialogActions={<div />}
        closeConfirmation={true}
      />
    </>
  )
}

export default MediaPlans
