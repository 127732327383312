import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { FormHelperText, Grid, InputAdornment, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, TextBox } from 'components/common'
import { useDispatch } from 'react-redux'
import { onSubmitForgotPassword } from 'store/ducks/auth'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { showError } from 'store/ducks/application'
import { EmailRounded } from '@mui/icons-material'

const StyledButton = styled(Button)`
  width: 35%;
`

const ActionsGrid = styled(Grid)`
  display: flex;
  justify-content: space-around;
`

const CaptchaWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: {
      alignSelf: 'flex-start',
      marginLeft: theme.spacing(2)
    }
  }
}))

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const captchaRef = useRef(null)
  const [captchaRequired, setCaptchaRequired] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const onChallengeComplete = async token => {
    try {
      setCaptchaRequired(false)
      setCaptchaToken(token)
    } catch (e) {
      dispatch(showError('Not able to verify. Please try again.'))
      captchaRef.current.reset()
      setCaptchaRequired(true)
      setCaptchaToken('')
    }
  }

  return (
    <>
      <Typography gutterBottom variant="h2" color="primary">
        Reset Password
      </Typography>

      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Please enter a valid email').required('Required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (captchaToken) {
            dispatch(onSubmitForgotPassword({ ...values, captchaToken }))
          } else {
            setCaptchaRequired(true)
          }
          setSubmitting(false)
        }}
      >
        <Form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextBox
                required
                id="email-input"
                label="Email"
                name="email"
                type="text"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailRounded />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <CaptchaWrapper item xs={12} sm={12} md={12} lg={12}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
                onChange={onChallengeComplete}
                ref={captchaRef}
              />
              {captchaRequired && <FormHelperText error={captchaRequired}>Required</FormHelperText>}
            </CaptchaWrapper>
            <ActionsGrid item xs={12} sm={12} md={12} lg={12}>
              <StyledButton color="primary" type="submit" size="large" fullWidth={true}>
                Submit
              </StyledButton>
              <StyledButton
                color="inherit"
                size="large"
                fullWidth={true}
                onClick={() => navigate('/login')}
              >
                Cancel
              </StyledButton>
            </ActionsGrid>
          </Grid>
        </Form>
      </Formik>
    </>
  )
}

export default ForgotPassword
