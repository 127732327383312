/* eslint-disable multiline-ternary */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import {
  fetchInPersonRequestDetails,
  resetInPersonVisit,
  setupInPersonRequestForm,
  submitInPersonVisitRequest
} from 'store/ducks/visitServices/inPersonRequest'

import { AccordionGroup, Button, TextBoxOuterLabel } from 'components/common'
import VisitDetailsForm from './VisitDetailsForm'
import SelectServices from './services/SelectServices'
import SelectTravelers from './SelectTravelers'
import { showError } from 'store/ducks/application'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import { servicesIdForVisit } from 'components/helper/constants/visitServices'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { fetchVisitScheduleGroup, setVisitNames } from 'store/ducks/visitServices/requestDetails'

const StyledLabel = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
`

const validationSchema = validationMapper([
  { id: 'visitNames', isResponseRequired: true, answerType: formElements.dropdownMenu },
  {
    id: 'visitStartDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Visit start date must be in the future'
    },
    error: 'Visit start date is required and must be a valid date'
  },
  {
    id: 'visitEndDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Visit end date must be in the future'
    },
    error: 'Visit end date is required and must be a valid date'
  },
  { id: 'arrivalTime', isResponseRequired: true, answerType: formElements.openText },
  { id: 'departureTime', isResponseRequired: true, answerType: formElements.openText },
  {
    id: 'selectTraveler',
    isResponseRequired: true,
    answerType: formElements.checkboxGroup,
    errorMessage: 'Atleast 1 Participant needs to be selected'
  },
  {
    id: 'selectedNeeds',
    isResponseRequired: true,
    answerType: formElements.checkboxGroup,
    errorMessage: 'Atleast 1 Service needs to be selected'
  },
  {
    id: 'ticketedTravelSpecialNeeds',
    isResponseRequired: true,
    answerType: formElements.openText,
    dependency: {
      elements: ['selectedNeeds'],
      callBack: selectedNeeds =>
        selectedNeeds?.length &&
        selectedNeeds.map(need => need.id).includes(servicesIdForVisit.ticketedTravel)
    }
  },
  {
    id: 'checkInDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Check-in date must be in the future'
    },
    dependency: {
      elements: ['selectedNeeds'],
      callBack: selectedNeeds =>
        selectedNeeds?.length &&
        selectedNeeds.map(need => need.id).includes(servicesIdForVisit.hotelAccommodations)
    },
    error: 'Check-in date is required and must be a valid date'
  },
  {
    id: 'checkOutDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Check-out date must be in the future'
    },
    dependency: {
      elements: ['selectedNeeds'],
      callBack: selectedNeeds =>
        selectedNeeds?.length &&
        selectedNeeds.map(need => need.id).includes(servicesIdForVisit.hotelAccommodations)
    },
    error: 'Check-out date is required and must be a valid date'
  },
  {
    id: 'adaRoomRequired',
    isResponseRequired: true,
    answerType: formElements.radioButtons,
    dependency: {
      elements: ['selectedNeeds'],
      callBack: selectedNeeds =>
        selectedNeeds?.length &&
        selectedNeeds.map(need => need.id).includes(servicesIdForVisit.hotelAccommodations)
    }
  },

  {
    id: 'groundTransportationException',
    isResponseRequired: true,
    answerType: formElements.openText,
    dependency: {
      elements: ['selectedNeeds'],
      callBack: selectedNeeds =>
        selectedNeeds?.length &&
        selectedNeeds.map(need => need.id).includes(servicesIdForVisit.groundTransportation)
    }
  },
  {
    id: 'mobilityAssistance',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
    dependency: {
      elements: ['selectedNeeds'],
      callBack: selectedNeeds =>
        selectedNeeds?.length &&
        selectedNeeds.map(need => need.id).includes(servicesIdForVisit.groundTransportation)
    }
  }
])

const InPersonRequestForm = () => {
  const { requestTypeId, requestId, copyRequestId } = useParams()
  const dispatch = useDispatch()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { visitDetails, startNewRequestForVisitFlow, visitScheduleGroup } = useSelector(
    store => store.requestDetails
  )

  const { mobilityTypes, travelers, inPersonRequestForm } = useSelector(
    store => store.inPersonRequest
  )

  const setupForm = async () => {
    if (patientInformation.userId) {
      await dispatch(fetchVisitScheduleGroup(patientInformation.userId))
      if (!requestTypeId && !requestId && !copyRequestId) {
        dispatch(setupInPersonRequestForm(patientInformation))
      }
      if (requestTypeId && requestId) {
        dispatch(fetchInPersonRequestDetails(requestId, patientInformation))
      }
      if (copyRequestId) {
        dispatch(fetchInPersonRequestDetails(copyRequestId, patientInformation))
      }
    }
  }

  useEffect(() => {
    setupForm()
    return () => {
      dispatch(resetInPersonVisit())
      dispatch(
        setVisitNames({
          isLoading: false,
          results: []
        })
      )
    }
  }, [patientInformation.userId])

  const readOnly = !!visitDetails.results && !!requestId

  const initialFormValue = {
    ...inPersonRequestForm,
    ...(startNewRequestForVisitFlow &&
    !readOnly && {
      ...startNewRequestForVisitFlow,
      // only in InPersonRequestForm, visitDate is visitStartDate
      visitStartDate: startNewRequestForVisitFlow.visitDate,
      // remove the visitDate field
      visitDate: undefined
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {visitScheduleGroup.isLoading ||
        travelers.isLoading ||
        mobilityTypes.isLoading ||
        travelers.results.length === 0 ||
        mobilityTypes.results.length === 0 ||
        visitDetails.isLoading ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={{ ...initialFormValue }}
              validationSchema={validationSchema}
              onSubmit={values => dispatch(submitInPersonVisitRequest(values, copyRequestId))}
            >
              {({ handleSubmit, isValid, setFieldValue }) => (
                <Form
                  onSubmit={e => {
                    handleSubmit(e)
                    if (!isValid) {
                      dispatch(showError('Please add required information and try again'))
                    }
                    e.preventDefault()
                  }}
                >
                  <>
                    <AccordionGroup
                      accordions={[
                        {
                          label: 'Visit Details',
                          component: <VisitDetailsForm />
                        }
                      ]}
                      gradient={true}
                      color="gradientBlue"
                      transitionProps={{ unmountOnExit: true }}
                      contentStyles={{ padding: '32px 16px' }}
                    />
                    <AccordionGroup
                      accordions={[
                        {
                          label: 'Select Travelers',
                          component: <SelectTravelers />
                        }
                      ]}
                      gradient={true}
                      color="gradientBlue"
                      transitionProps={{ unmountOnExit: true }}
                      contentStyles={{ padding: '32px 16px' }}
                    />
                    <AccordionGroup
                      accordions={[
                        {
                          label: 'Select Services',
                          component: <SelectServices setFieldValue={setFieldValue} />
                        }
                      ]}
                      gradient={true}
                      color="gradientBlue"
                      transitionProps={{ unmountOnExit: true }}
                      contentStyles={{ padding: '32px 16px' }}
                    />
                    <AccordionGroup
                      accordions={[
                        {
                          label: 'In Case We Need To Speak with You',
                          component: (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextBoxOuterLabel
                                  id="reachDateTime"
                                  label="What is the best time of the day to reach you?"
                                  name="reachDateTime"
                                  type="time"
                                  size="small"
                                  disabled={readOnly}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextBoxOuterLabel
                                  id="reachPhoneNumber"
                                  label={
                                    <StyledLabel variant="body2">
                                    Best phone number to reach you <i>(include country code)</i>
                                    </StyledLabel>
                                  }
                                  name="reachPhoneNumber"
                                  size="small"
                                  type="number"
                                  disabled={readOnly}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      ]}
                      gradient={true}
                      color="gradientBlue"
                      transitionProps={{ unmountOnExit: true }}
                      contentStyles={{ padding: '32px 16px' }}
                    />
                  </>
                  {!readOnly && (
                    <div style={{ textAlign: 'center', paddingTop: '2%' }}>
                      <Button size="medium" fullWidth={false} color="primary" type="submit">
                      Submit Request
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          )}
      </Grid>
    </Grid>
  )
}

export default InPersonRequestForm
