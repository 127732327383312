import React from 'react'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { Button, Password } from 'components/common'
import { Grid } from '@mui/material'
import { changePassword } from 'services/settings'
import { showError } from 'store/ducks/application'
import { useDispatch } from 'react-redux'

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(3)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: start;
      justify-content: flex-start;
  `
)
const StyledButton = styled(Button)(
  ({ theme }) => `
    margin: ${theme.spacing(1)};
`
)

const validationSchema = yup.object({
  currentPassword: yup.string().required('Required'),
  newPassword: yup.string().required('Required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
    .required('Required')
})

const ChangePassword = ({ setShowChangePassword, setShowChangePasswordSuccess }) => {
  const dispatch = useDispatch()

  const submitHandler = data => {
    changePassword({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword
    })
      .then(() => {
        setShowChangePassword(false)
        setShowChangePasswordSuccess(true)
      })
      .catch(() => {
        dispatch(showError('Error - change password'))
      })
  }

  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <React.Fragment>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Password
                  id="currentPassword"
                  data-testid="currentPassword"
                  label="Current Password"
                  name="currentPassword"
                  size="small"
                />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Password
                  id="newPassword"
                  data-testid="newPassword"
                  label="New Password"
                  name="newPassword"
                  size="small"
                />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Password
                  id="confirmPassword"
                  data-testid="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  size="small"
                />
              </Grid>
              <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                <StyledButton size="large" fullWidth={false} color="primary" type="submit">
                  Change Password
                </StyledButton>
              </ActionWrapper>
            </Grid>
          </Form>
        </React.Fragment>
      )}
    </Formik>
  )
}

ChangePassword.propTypes = {
  setShowChangePassword: PropTypes.func,
  setShowChangePasswordSuccess: PropTypes.func
}

export default ChangePassword
