import React, { useState } from 'react'
import { DataGrid, AttachFile, Button, PropertyValue, TextBoxOuterLabel } from 'components/common'
import { palette } from 'styles/baseTheme'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { FindInPage, Publish, Close, ListAlt } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateReimbursementReceipts,
  setSelectedReimbursement,
  fetchReimbursementReceipts,
  setReceiptsListModal,
  setUpdateReceiptsModal,
  submitFundsReview,
  setRejectionReasonModal
} from 'store/ducks/patientRecord/reimbursement'
import { showWarning } from 'store/ducks/application'
import QuickView from 'components/common/QuickView'
import ReceiptsList from './ReceiptsList'
import { displayDate } from 'components/helper/utility'
import { useRestriction } from 'permissions'

const Wrapper = styled(Grid)(({ theme }) => ({
  marginTop: `${theme.spacing(3)}`
}))

const ReceiptWrapper = styled.div(() => ({
  height: '100%'
}))

const SelectedFile = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))
const FileNameWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(1)}`,
  marginBottom: `${theme.spacing(2)}`,
  backgroundColor: `${theme.palette.background.disabled}`,
  width: '90%'
}))
const ModalActionsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  button: {
    marginLeft: `${theme.spacing(2)}`,
    marginTop: `${theme.spacing(2)}`
  }
}))

const bodyProperties = { padding: '0px', textAlign: 'center', cellType: 'node' }
const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'center',
  padding: '8px',
  minWidth: '120px'
}
const gridTitleStyles = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'center'
}

const footerStyles = {
  backgroundColor: palette.gray.light,
  color: palette.gray.contrastText,
  borderBottom: `2px solid ${palette.gray.drk}`,
  textAlign: 'unset'
}

const NotesWrapper = styled(Grid)(({ theme }) => ({
  border: `2px solid ${theme.palette.gray.dark}`,
  padding: `${theme.spacing(1, 4, 2, 4)}`,
  backgroundColor: `${theme.palette.gray.light}`
}))

const StyledFooterCell = styled.div(({ theme }) => ({
  '&&': {
    borderLeft: `2px solid ${theme.palette.midnight.light}`,
    borderRight: `2px solid ${theme.palette.midnight.light}`,
    position: 'relative',
    left: '-2px',
    minHeight: '43px',
    backgroundColor: theme.palette.midnight.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const StatusActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const FundsHistory = () => {
  const dispatch = useDispatch()

  const [receipts, setReceipts] = useState([])
  const [notesToShow, setNotesToShow] = useState([])
  const {
    fundsHistory,
    receiptsListModal,
    updateReceiptsModal,
    addFundsGridHeaders,
    rejectionReasonModal
  } = useSelector(store => store.reimbursement)
  const { loggedInAsSite, isPatient } = useSelector(store => store.auth)
  const [approvalConfirmationModal, setApprovalConfirmationModal] = useState(false)
  const [rejectionNotes, setRejectionNotes] = useState('')
  const canReadAddFundsReceiptsColumn = useRestriction('read', 'addFundsReceiptsColumn')

  const finalFundsHistoryHeaders = [
    {
      id: 'VisitName',
      label: 'Visit Name',
      headProperties: { ...headProperties },
      bodyProperties: { ...bodyProperties, cellType: 'node' }
    },
    {
      id: 'VisitDate',
      label: 'Visit Date',
      headProperties: { ...headProperties },
      bodyProperties: { ...bodyProperties, cellType: 'node' }
    }
  ]

  addFundsGridHeaders.forEach(item => {
    if (item.id === 'receipts') {
      fundsHistory.some(history => history.IsApprovalRequired) && finalFundsHistoryHeaders.push({
        id: 'submissionStatus',
        label: 'Submission Status',
        headProperties: { ...headProperties, minWidth: '160px' },
        bodyProperties: { ...bodyProperties, cellType: 'node' }
      })
      finalFundsHistoryHeaders.push({
        id: 'viewNotes',
        label: 'View Notes',
        headProperties: { ...headProperties, minWidth: '80px' },
        bodyProperties: { ...bodyProperties, cellType: 'node' }
      })
      if (canReadAddFundsReceiptsColumn) {
        finalFundsHistoryHeaders.push({
          ...item,
          headProperties: { ...headProperties, minWidth: '50px' },
          bodyProperties: { ...bodyProperties, cellType: 'node' }
        })
      }
    } else {
      finalFundsHistoryHeaders.push({
        ...item,
        headProperties: { ...headProperties },
        bodyProperties: { ...bodyProperties }
      })
    }
  })

  const notesCreatedByLabels = {
    addFunds: 'Note added by:',
    approved: 'Approved by:',
    rejected: 'Rejected By:'
  }

  const notesCreatedOnLabels = {
    addFunds: 'Date:',
    approved: 'Approved On:',
    rejected: 'Rejected On:'
  }

  const onNotesClicked = funds => {
    const notesList = [
      {
        noteType: 'addFunds',
        note: funds.Notes,
        noteCreatedBy: funds.CreatedBy,
        noteCreatedDate: displayDate(funds.CreatedOn)
      }
    ]
    if (funds.IsApprovalRequired && typeof funds.IsApproved === 'boolean') {
      funds.IsApproved
        ? notesList.push({
          noteType: 'approved',
          note: 'Approved',
          noteCreatedBy: funds.ReviewedBy,
          noteCreatedDate: displayDate(funds.ReviewedOn)
        })
        : notesList.push({
          noteType: 'rejected',
          note: funds.ReviewNotes,
          noteCreatedBy: funds.ReviewedBy,
          noteCreatedDate: displayDate(funds.ReviewedOn)
        })
    }

    setNotesToShow(notesList)
  }

  const fundsHistoryData = fundsHistory.map(funds => {
    const rowObj = {}
    if (funds.rowId !== 'footer') {
      return {
        ...funds,
        VisitDate: (
          <Tooltip
            color="primary"
            size="small"
            title={<Typography variant="body1">{displayDate(funds.CreatedOn)}</Typography>}
          >
            <div>{displayDate(funds.VisitDate)}</div>
          </Tooltip>
        ),
        submissionStatus: funds.IsApprovalRequired
          ? (
            typeof funds.IsApproved === 'boolean'
              ? (
                funds.IsApproved
                  ? (
                    <Typography variant="body2">Approved</Typography>
                  )
                  : (
                    <Typography variant="body2">Rejected</Typography>
                  )
              )
              : loggedInAsSite || isPatient
                ? (
                  <Typography variant="body2">Pending Approval</Typography>
                )
                : (
                  <StatusActionWrapper>
                    <Button
                      variant="text"
                      onClick={() => {
                        dispatch(setSelectedReimbursement(funds.ReimbursementRequestId))
                        setApprovalConfirmationModal(true)
                      }}
                    >
                Approve
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        dispatch(setSelectedReimbursement(funds.ReimbursementRequestId))
                        dispatch(setRejectionReasonModal(true))
                      }}
                    >
                Reject
                    </Button>
                  </StatusActionWrapper>
                )
          )
          : null,
        viewNotes: funds.Notes && (
          <ReceiptWrapper>
            <IconButton aria-label="view-notes" onClick={() => onNotesClicked(funds)}>
              <FindInPage />
            </IconButton>
          </ReceiptWrapper>
        ),
        receipts: (
          <ReceiptWrapper>
            {funds.AnyAttachments
              ? (
                <IconButton
                  aria-label="view-receipts"
                  onClick={() => {
                    setReceipts([])
                    dispatch(fetchReimbursementReceipts(funds.ReimbursementRequestId))
                  }}
                >
                  <ListAlt />
                </IconButton>
              )
              : (
                <IconButton
                  aria-label="upload-receipt"
                  onClick={() => {
                    setReceipts([])
                    dispatch(setSelectedReimbursement(funds.ReimbursementRequestId))
                    dispatch(setUpdateReceiptsModal(true))
                  }}
                >
                  <Publish />
                </IconButton>
              )}
          </ReceiptWrapper>
        )
      }
    } else {
      Object.keys(funds).forEach(cellItem => {
        rowObj[cellItem] = <StyledFooterCell>{funds[cellItem]}</StyledFooterCell>
      })
      return rowObj
    }
  })

  return (
    <>
      <Wrapper container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {fundsHistoryData.length > 0 && addFundsGridHeaders.length > 0 && (
            <DataGrid
              gridTitle={<Typography variant="subtitle1">Funds History</Typography>}
              gridTitleProperties={gridTitleStyles}
              headers={finalFundsHistoryHeaders}
              tableData={fundsHistoryData}
              setRequestedSort={() => {}}
              order={'DESC'}
              orderBy={'createdDate'}
              border="allSides"
              radius={'false'}
              alternateRowColored={true}
              gridFooterProperties={footerStyles}
              styles={{
                '.MuiTableRow-root:last-child': { backgroundColor: palette.midnight.light }
              }}
            />
          )}
        </Grid>
      </Wrapper>
      <QuickView
        title="Upload Receipts"
        onClose={() => {
          dispatch(setUpdateReceiptsModal(false))
          setReceipts([])
        }}
        isDialogOpen={updateReceiptsModal}
        maxWidth="sm"
        closeConfirmation={true}
        dialogContent={
          <SelectedFile>
            {receipts.map((file, index) => (
              <FileNameWrapper key={index}>
                <Typography variant="body1">{file.name}</Typography>
                <IconButton
                  aria-label="cancel-file-upload"
                  onClick={() => {
                    const selectedReceipts = receipts
                    const removingIndex = selectedReceipts.findIndex(r => r.name === file.name)
                    setReceipts(selectedReceipts.splice(1, removingIndex))
                  }}
                  size="small"
                  color="default"
                >
                  <Close />
                </IconButton>
              </FileNameWrapper>
            ))}
          </SelectedFile>
        }
        dialogActions={
          <ModalActionsWrapper>
            <AttachFile
              formik="false"
              files={receipts}
              onFileChange={selectedFile => setReceipts([...selectedFile])}
              name="attachments"
              onFileUploadFailure={msg => {
                dispatch(showWarning(msg))
              }}
              showInQuickView={false}
            />
            <div>
              <Button
                disabled={!receipts.length}
                color="primary"
                onClick={() => {
                  dispatch(updateReimbursementReceipts(receipts))
                }}
              >
                Upload
              </Button>
              <Button
                fullWidth={false}
                color="inherit"
                onClick={() => {
                  setUpdateReceiptsModal(false)
                  setReceipts([])
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalActionsWrapper>
        }
      />
      <QuickView
        title="View / Upload Receipts"
        onClose={() => {
          dispatch(setSelectedReimbursement(''))
          dispatch(setReceiptsListModal(false))
          setReceipts([])
        }}
        isDialogOpen={receiptsListModal}
        maxWidth="md"
        dialogContent={<ReceiptsList />}
      />
      <QuickView
        title="Reimbursement Notes"
        onClose={() => {
          setNotesToShow([])
        }}
        isDialogOpen={!!notesToShow.length}
        maxWidth="md"
        dialogContentStyles={{ padding: '60px' }}
        dialogContent={
          notesToShow.map((noteDetails, index) => <NotesWrapper container spacing={1} key={index}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body1" gutterBottom>
                {noteDetails.note}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PropertyValue
                label={notesCreatedByLabels[noteDetails.noteType]}
                value={noteDetails.noteCreatedBy}
                valueProps={{ variant: 'body1' }}
                labelProps={{ variant: 'body1' }}
                align="row"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PropertyValue
                label={notesCreatedOnLabels[noteDetails.noteType]}
                value={noteDetails.noteCreatedDate}
                valueProps={{ variant: 'body1' }}
                labelProps={{ variant: 'body1' }}
                align="row"
                fullWidth
              />
            </Grid>
          </NotesWrapper>)
        }
      />
      <QuickView
        title="Funds Rejection"
        onClose={() => {
          setRejectionNotes('')
          dispatch(setRejectionReasonModal(false))
        }}
        isDialogOpen={rejectionReasonModal}
        maxWidth="sm"
        dialogContent={
          <>
            <Typography variant="body2" gutterBottom>
            Please provide additional information on why this reimbursement is not approved
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  size="medium"
                  id="note"
                  label=""
                  name="note"
                  type="text"
                  rows="5"
                  multiline
                  formik="false"
                  onChange={e => setRejectionNotes(e.target.value)}
                />
              </Grid>
            </Grid>
          </>
        }
        dialogActions={
          <>
            <Button
              color="primary"
              onClick={() =>
                dispatch(submitFundsReview({ isApproved: false, reviewNotes: rejectionNotes }))
              }
              disabled={!rejectionNotes}
            >
              Submit
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                setRejectionNotes('')
                dispatch(setRejectionReasonModal(false))
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
      <QuickView
        title="Confirmation"
        onClose={() => {
          dispatch(setSelectedReimbursement(''))
          setApprovalConfirmationModal(false)
        }}
        isDialogOpen={approvalConfirmationModal}
        maxWidth="sm"
        contentText='Please confirm your approval'
        dialogActions={
          <>
            <Button
              color="primary"
              onClick={() =>
                dispatch(submitFundsReview({ isApproved: true }, () => setApprovalConfirmationModal(false)))
              }
            >
              Confirm
            </Button>
          </>
        }
      />

    </>
  )
}

export default FundsHistory
