import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { useField } from 'formik'
import styled from '@emotion/styled'

const StyledTextBox = styled(TextField)(
  ({ theme, rounded }) =>
    `
  &&{
    ${
  rounded === 'true'
    ? `
      .MuiOutlinedInput-root {
        display: flex;
        flex-direction: row-reverse;
        border-radius: ${theme.shape.containerRadius}; 
      }`
    : `.MuiOutlinedInput-root {
        background-color: ${theme.palette.white.dark};
        min-height: 45px;
        input {
          padding: 14px 12px;
        }
      }`
} 
   .Mui-disabled{
        fieldset {
          background-color: ${theme.palette.gray.light};
        }
        input {
          z-index: ${theme.zIndex.minorUiElement};
        }
        .MuiInputAdornment-root{
          z-index: ${theme.zIndex.minorUiElement};
          svg{
            color: ${theme.palette.gray.dark};
          }
        }
      }
    }
  
  `
)

const TextBox = props => {
  const {
    autoComplete,
    autoFocus,
    color,
    formik,
    defaultValue,
    disabled,
    error,
    FormHelperTextProps,
    fullWidth,
    helperText,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    label,
    margin,
    maxRows,
    minRows,
    multiline,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    rows,
    size,
    type,
    value,
    variant,
    rounded
  } = props

  let field, meta

  if (formik === 'true') {
    [field, meta] = useField(props)
  } else {
    field = {
      onChange,
      onBlur,
      value
    }
  }

  if (onChange) {
    field = { ...field, onChange, value }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <StyledTextBox
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      color={color}
      formik={formik}
      defaultValue={defaultValue}
      disabled={disabled}
      FormHelperTextProps={{ component: 'div', ...FormHelperTextProps }}
      fullWidth={fullWidth}
      id={id}
      InputLabelProps={InputLabelProps}
      inputProps={inputProps}
      InputProps={InputProps}
      label={label}
      margin={margin}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={name}
      onFocus={onFocus}
      placeholder={placeholder}
      required={required}
      rows={rows}
      size={size}
      type={type}
      variant={variant}
      {...field}
      error={isError}
      helperText={errorText}
      rounded={rounded}
    />
  )
}

TextBox.propTypes = {
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element is focused during the first mount.
   * @default false
   */
  autoFocus: PropTypes.bool,

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),

  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),

  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue: PropTypes.any,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * Props applied to the [`FormHelperText`](/api/form-helper-text/) element.
   */
  FormHelperTextProps: PropTypes.object,
  /**
   * If `true`, the input will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.string,
  /**
   * Props applied to the [`InputLabel`](/api/input-label/) element.
   */
  InputLabelProps: PropTypes.object,
  /**
   * Props applied to the Input element.
   * It will be a [`FilledInput`](/api/filled-input/),
   * [`OutlinedInput`](/api/outlined-input/) or [`Input`](/api/input/)
   * component depending on the `variant` prop value.
   */
  inputProps: PropTypes.object,
  /**
   * provide props to the input element
   */
  InputProps: PropTypes.object,
  /**
   * when 'true', outlined input field will have rounded corners
   * @default 'false'
   */
  rounded: PropTypes.oneOf(['true', 'false']),
  /**
   * The label content.
   */
  label: PropTypes.node,
  /**
   * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
   * @default 'none'
   */
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If `true`, a `textarea` element is rendered instead of an input.
   * @default false
   */
  multiline: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * onBlur function
   */
  onBlur: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder: PropTypes.string,
  /**
   * If `true`, the label is displayed as required and the `input` element is required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * Number of rows to display when multiline option is set to true.
   */
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The size of the component.
   * @default small
   */
  size: PropTypes.oneOf(['medium', 'small']),

  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   */
  type: PropTypes.string,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.any,
  /**
   * The variant to use.
   * @default 'outlined'
   */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard'])
}

TextBox.defaultProps = {
  variant: 'outlined',
  color: 'primary',
  size: 'small',
  formik: 'true',
  fullWidth: true,
  autoFocus: false,
  disabled: false,
  error: false,
  margin: 'none',
  multiline: false,
  required: false,
  rounded: 'false'
}

export default TextBox
