import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { performAuthentication } from '../../store/ducks/auth'
import { Fade, Grid, InputAdornment, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, Password, TextBox } from 'components/common'
import TCNEngageLogo from 'assets/images/TCN-Engage_Logo-Full-Color.png'
import { useNavigate } from 'react-router-dom'
import { EmailRounded, LockRounded } from '@mui/icons-material'

const loginText = [
  "It's All About Study Experience!",
  'É tudo sobre a experiência de estudo!',
  'Es geht um Studienerfahrung!',
  '¡Todo se trata de la experiencia de estudio!',
  'C\'est une question d\'expérience d\'étude !',
  'Si tratta di esperienza di studio!',
  'Chodzi o doświadczenie w nauce!',
]

const StyledForm = styled(Form)(() => ({
  width: '90%',
  textAlign: 'center'
}))

const StyledLogo = styled.img`
  margin-bottom: 5px;
`

const StyledTitleWrapper = styled(Fade)(
  ({ theme }) => `
    height: 56px;
    text-align: center;
    margin-bottom: ${theme.spacing(3)};
`
)

const LoginButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '50%'
  },
  [theme.breakpoints.up('md')]: {
    width: '50%'
  }
}))

const ForgotPasswordButton = styled(Button)`
  width: fit-content;
  color: ${({ theme }) => theme.palette.primary.light};
`

const Auth = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [count, setCount] = useState(0)
  const [showText, setShowText] = useState(true)

  const countRef = useRef(count)
  const intervalIdRef = useRef(-1)

  countRef.current = count
  let timeoutID

  const changeText = () => {
    setShowText(false)
    // this time out will take half second to switch the flag
    timeoutID = setTimeout(() => {
      const myCount = countRef.current
      if (myCount === 6) {
        setCount(0)
      } else {
        setCount(myCount + 1)
      }
      setShowText(true)
    }, 500)
  }

  const startLoop = () => {
    intervalIdRef.current = setInterval(changeText, 3000)
  }

  // When component unmounts, clean up the interval:
  const stopLoop = () => {
    clearTimeout(timeoutID)
    clearInterval(intervalIdRef.current)
  }

  useEffect(() => {
    startLoop()
    return stopLoop
  }, [])

  return (
    <>
      <StyledLogo src={TCNEngageLogo} alt="TCN Engage Logo" width="120px" />

      <StyledTitleWrapper in={showText}>
        <Typography gutterBottom variant={'h4'} color="gradientBlue.light">
          {loginText[count]}
        </Typography>
      </StyledTitleWrapper>

      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Please enter a valid email').required('Required'),
          password: Yup.string().min(6, 'Must be 6 characters of more').required('Required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          clearInterval(intervalIdRef.current)
          dispatch(performAuthentication(values.email, values.password))
          setSubmitting(false)
        }}
      >
        <StyledForm noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextBox
                required
                id="email-input"
                label="Email"
                name="email"
                type="text"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailRounded />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Password
                size="small"
                required
                id="password-input"
                label="Password"
                name="password"
                startAdornment={
                  <InputAdornment position="start">
                    <LockRounded />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LoginButton color="primary" type="submit" size="large" fullWidth={true}>
                Log In
              </LoginButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ForgotPasswordButton
                variant="text"
                color="inherit"
                size="small"
                fullWidth={true}
                onClick={() => navigate('/forgot-password')}
              >
                <Typography variant="caption">
                  Reset Password
                </Typography>
              </ForgotPasswordButton>
            </Grid>
          </Grid>
        </StyledForm>
      </Formik>
    </>
  )
}

export default Auth
