import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import {
  fetchHomeHealthVisitDetails,
  resetHomeHealthVisit,
  setupHomeHealthVisitDetailsForm,
  submitHomeHealthVisitRequest
} from 'store/ducks/visitServices/homeHealthVisit'

import {
  TextBoxOuterLabel,
  DatePickerWithLabel,
  AutoCompleteWithLabel,
  Button,
  Bar,
  AddressField
} from 'components/common'
import PatientLocationForm from '../../PatientLocationForm'
import { setPatientAddresses } from 'store/ducks/visitServices/inPersonRequest'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import { Error } from '@mui/icons-material'
import {
  fetchVisitNames,
  fetchVisitScheduleGroup,
  setVisitNames
} from 'store/ducks/visitServices/requestDetails'

const LabelForVisitNames = styled.div(
  ({ theme }) => `
      display: flex;  
      svg{
        top: -1px;
        width: 1.2rem;
        height: 1.2rem;
        right: -17px;
      }
      p {
        margin-right: ${theme.spacing(1 / 2)};
      }`
)

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`
const DownloadFormWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    marginTop: theme.spacing(2),
    button: {
      margin: theme.spacing(1)
    }
  }
}))

const StyledLabel = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
`

const validationSchema = validationMapper([
  { id: 'visitNames', isResponseRequired: true, answerType: formElements.dropdownMenu },
  {
    id: 'visitDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Visit start date must be in the future'
    },
    error: 'Visit start date is required and must be a valid date'
  },
  { id: 'visitTime', isResponseRequired: true, answerType: formElements.openText },
  { id: 'otherRelevantConsiderations', isResponseRequired: true, answerType: formElements.openText }
])

const HomeHealthVisit = () => {
  const { requestTypeId, requestId, copyRequestId } = useParams()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const dispatch = useDispatch()

  const { patientAddresses } = useSelector(store => store.inPersonRequest)
  const { visitDetails, visitNames, visitScheduleGroup, startNewRequestForVisitFlow } = useSelector(
    store => store.requestDetails
  )
  const { homeHealthVisitDetailsForm } = useSelector(store => store.homeHealthVisit)

  const setupForCopyRequest = async () => {
    await dispatch(setupHomeHealthVisitDetailsForm())
    await dispatch(fetchHomeHealthVisitDetails(copyRequestId))
  }

  const setupForm = async () => {
    await dispatch(fetchVisitScheduleGroup(patientInformation.userId))
    if (patientInformation.userId) {
      if (!requestTypeId && !requestId && !copyRequestId) {
        dispatch(setupHomeHealthVisitDetailsForm())
      }
      if (requestTypeId && requestId) {
        dispatch(fetchHomeHealthVisitDetails(requestId))
      }
      // Copy Existing request View
      if (copyRequestId) {
        setupForCopyRequest()
      }
    }
  }

  useEffect(() => {
    setupForm()
    return () => {
      dispatch(resetHomeHealthVisit())
      dispatch(setVisitNames({ isLoading: false, results: [] }))
    }
  }, [patientInformation.userId])

  const readOnly = !!visitDetails.results && !!requestId

  const changeAddressSelection = (selectedAddress, setFieldValue) => {
    let newSelectedAddress
    if (typeof selectedAddress === 'object') {
      // this means user added new address, so automatically select that address
      newSelectedAddress = selectedAddress
    } else {
      // else find the address by addressId from the list of addresses and then make the selection
      // if the selected address type is patient residence than, use patient addresses list for selection
      newSelectedAddress = patientAddresses.find(
        item => item.addressId === parseInt(selectedAddress)
      )
    }
    setFieldValue('location', newSelectedAddress)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {visitScheduleGroup.isLoading ||
        visitDetails.isLoading ||
        !homeHealthVisitDetailsForm.location
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          )
          : (
            <Formik
              initialValues={{
                ...homeHealthVisitDetailsForm,
                ...(startNewRequestForVisitFlow &&
                !readOnly && {
                  ...startNewRequestForVisitFlow
                })
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={values => {
                dispatch(submitHomeHealthVisitRequest(values, copyRequestId))
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <AddressField
                        id="location"
                        label="Location of Home Health Appointment"
                        name="location"
                        changeAddressLabel="Select Alternate Address"
                        addressesList={patientAddresses}
                        addressesListHeader="Location must be where the patient resides at the time of pick-up / delivery. If the home address does not
          meet this criteria, please add a new residence address (e.g., hotel)"
                        addAddressLabel="Add Location"
                        addAddressForm={props => (
                          <PatientLocationForm
                            onNewAddressAdded={newAddress =>
                              dispatch(setPatientAddresses([...patientAddresses, newAddress]))
                            }
                            {...props}
                          />
                        )}
                        onChange={selectedAddress =>
                          changeAddressSelection(selectedAddress, setFieldValue)
                        }
                        disabled={readOnly}
                      />
                    </Grid>
                    <DownloadFormWrapper item xs={12} sm={12} md={4} lg={4}>
                      <Typography variant="body2" gutterBottom>
                      Download Physician Authorization Form
                      </Typography>
                      <Button size="large" color="inherit" fullWidth={false} onClick={() => {}}>
                      Download Form
                      </Button>
                      <Typography variant="caption" gutterBottom>
                      Upon completion of the physician authorization form, please upload the
                      executed form within this specific request which appears on the main TCN
                      Arrive page within the In Process section.
                      </Typography>
                    </DownloadFormWrapper>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>
                    {visitScheduleGroup.results.length > 1 && (
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <AutoCompleteWithLabel
                          id="visitScheduleGroup"
                          label="Select a Visit Schedule"
                          name="visitScheduleGroup"
                          onChange={(event, selectedValue, reason) => {
                            if (reason === 'selectOption') {
                              dispatch(fetchVisitNames(patientInformation.userId, selectedValue.id))
                            } else if (reason === 'clear') {
                              dispatch(
                                setVisitNames({
                                  isLoading: false,
                                  results: []
                                })
                              )
                            }
                          }}
                          options={visitScheduleGroup.results}
                          isLoading={visitScheduleGroup.isLoading}
                          size="small"
                          disabled={readOnly}
                          multiple={false}
                          hasSelectAllOption={false}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <AutoCompleteWithLabel
                        id="visitNames"
                        label={
                          <LabelForVisitNames>
                            <Typography variant="body2">Study Visit</Typography>
                            <Tooltip
                              color="primary"
                              size="small"
                              title={
                                <Typography variant="body1">
                                If your request will apply to more than one study visit, please
                                select the first applicable study visit.
                                </Typography>
                              }
                            >
                              <Error size="small" />
                            </Tooltip>
                          </LabelForVisitNames>
                        }
                        name="visitNames"
                        options={visitNames.results}
                        isLoading={visitNames.isLoading}
                        size="small"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <DatePickerWithLabel
                        id="visitDate"
                        label={'Visit Date'}
                        name="visitDate"
                        size="small"
                        minDate={new Date()}
                        fullWidth={false}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextBoxOuterLabel
                        size="small"
                        id="visitTime"
                        label={'Visit Time'}
                        name="visitTime"
                        type="time"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <TextBoxOuterLabel
                        size="small"
                        id="otherRelevantConsiderations"
                        label={'Other Relevant Considerations'}
                        name="otherRelevantConsiderations"
                        type="text"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="body2">In Case We Need To Speak with You</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachDateTime"
                        label="What is the best time of the day to reach you?"
                        name="reachDateTime"
                        type="time"
                        size="small"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachPhoneNumber"
                        label={
                          <StyledLabel variant="body2">
                          Best phone number to reach you <i>(include country code)</i>
                          </StyledLabel>
                        }
                        name="reachPhoneNumber"
                        size="small"
                        type="number"
                        disabled={readOnly}
                      />
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ActionWrapper>
                          <Button size="large" fullWidth={false} color="primary" type="submit">
                          Submit Request
                          </Button>
                        </ActionWrapper>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
      </Grid>
    </Grid>
  )
}

export default HomeHealthVisit
