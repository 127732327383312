import React, { useState } from 'react'
import { Typography, IconButton, Grid } from '@mui/material'
import parse from 'html-react-parser'
import styled from '@emotion/styled'
import { AccordionGroup, Bar, Button, AttachmentButton } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDistributionList,
  toggleIsCompletedFlag,
  resetMessages,
  setComposeMessage,
  setComposeMessageModal,
  setIsResponding,
  downloadAttachment,
  deleteStudyMessage,
  setDeleteStudyMessageConfirmation
} from 'store/ducks/communicationCenter/studyMessages'
import StudyMessageResponse from './StudyMessageResponse'
import { AttachFile, Close } from '@mui/icons-material'
import QuickView from 'components/common/QuickView'
import PropertyValue from 'components/common/dataDisplay/PropertyValue'
import { communicationTabIDs } from 'components/helper/constants/mailbox'
import { Restriction, useRestriction } from 'permissions'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'

const RespondingHeader = styled(Grid)(
  () => `
     display: flex;
     justify-content: space-between; 
`
)

const SubjectHeader = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  margin-top: ${({ theme }) => theme.spacing(3)};
`

const MailWrapper = styled.div(
  ({ theme }) => `
    margin: ${theme.spacing(5)};
`
)

const MailBody = styled.div(
  ({ theme }) => `
    margin: ${theme.spacing(5, 3, 3, 3)};
`
)

const TitleWrapper = styled.span(
  ({ theme }) => `
  display: flex;
  h6{
    margin-right: ${theme.spacing(1)};
  }
`
)

const AttachedFilesWrapper = styled(Grid)(
  ({ theme }) => `
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: ${theme.spacing(2)};
    `
)
const AttachedLogo = styled(AttachFile)(
  () => `
    transform: rotate(45deg);
    `
)
const DateTimeWrapper = styled(Typography)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    svg{
      margin-left: ${theme.spacing(1)};
    } 
    `
)
const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
const ActionsWrapper = styled(Grid)(
  ({ theme }) => `
flex-wrap: wrap;
button {
  margin-right: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
  text-decoration: none;
}

`
)

const ViewUsersLink = styled(Button)(
  ({ theme }) => `
    text-decoration: underlined;
    margin-bottom: ${theme.spacing(5)};
    `
)

const ConfirmationActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  justify-content: space-between;
`

const MessageDetails = styled(Grid)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`
)

const communicationTabStrings = {
  conciergeMsgsWithSites: 'with Sites',
  conciergeMsgsWithPatients: 'with Patients'
}

const StudyMessageContent = () => {
  const dispatch = useDispatch()
  const [toggleIsCompletedFlagModal, setToggleIsCompletedFlagModal] = useState(false)
  const { isResponding, studyMessage, isCompleted, messagesList, deleteStudyMessageConfirmation } =
    useSelector(store => store.studyMessages)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const [expandAll, setExpandAll] = useState(false)
  const [defaultOpenPanel, setDefaultOpenPanel] = useState(0)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)

  // A flag from backend will determine what header to show
  const contentHeader = `${
    useRestriction('read', 'conciergeMsgsHeader')
      ? `${isCompleted ? 'Completed' : 'Active'} Messages ${
        communicationTabStrings[selectedCommunicationTabId]
      }`
      : 'Concierge Messages'
  }`

  const canReadReplyButton = useRestriction('read', `${selectedCommunicationTabId}ReplyButton`)

  const onEditOfStudyMessage = () => {
    dispatch(setComposeMessageModal(true))
    // systemID dropdown: single select in messageWithSites tab and multi-select in withPatients tab
    // now both the dropdowns are single select, but systemID dropdown is still mapped differently on different tabs
    selectedCommunicationTabId === communicationTabIDs.conciergeMsgsWithSites
      ? dispatch(
        setComposeMessage({
          ...studyMessage[0],
          sendTo: studyMessage[0].sendTo.id,
          systemId: studyMessage[0].userSite
        })
      )
      : dispatch(
        setComposeMessage({
          ...studyMessage[0],
          sendTo: studyMessage[0].sendTo.id,
          systemId: studyMessage[0].userSites[0]
        })
      )
  }
  return (
    <MailWrapper>
      {messagesList.results.length && studyMessage[0].id
        ? (
          <>
            {/* When replying to a message */}
            {isResponding && (
              <>
                <Grid container spacing={2}>
                  <RespondingHeader item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h2">Reply</Typography>
                    <IconButton
                      aria-label="close reply"
                      color="primary"
                      onClick={() => dispatch(setIsResponding(false))}
                      size="large"
                    >
                      <Close />
                    </IconButton>
                  </RespondingHeader>
                </Grid>
                <Bar fullWidth color="gray" />
                <SubjectHeader variant="subtitle1">{studyMessage[0].subject}</SubjectHeader>
                <StudyMessageResponse />
              </>
            )}
            <>
              {!isResponding && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Restriction ability="read" feature="conciergeMsgsHeader">
                      <Typography gutterBottom variant="subtitle1">
                        {/* Different label for thread vs single study message  */}
                        {contentHeader}
                      </Typography>
                    </Restriction>
                  </Grid>

                  <ActionsWrapper item xs={12} sm={12} md={12} lg={12}>
                    {isCompleted
                      ? (
                        <Restriction ability="read" feature={'conciergeMsgsWithSitesMoveToActiveButton'}>

                          <Button
                            disableElevation
                            color="inherit"
                            variant="contained"
                            onClick={() => {
                              setToggleIsCompletedFlagModal(true)
                            }}
                          >
                          Move to Active Messages
                          </Button>

                        </Restriction>
                      )
                      : (
                        <>
                          {((studyMessage[0].isStudyMessage && studyMessage[0].isDistributed) ||
                        !studyMessage[0].isStudyMessage) && (
                            <Restriction ability="read" feature={`${selectedCommunicationTabId}MoveToCompletedButton`}>

                              <Button
                                color="inherit"
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                  setToggleIsCompletedFlagModal(true)
                                }}
                              >
                              Move to Completed
                              </Button>

                            </Restriction>
                          )}

                          {studyMessage[0].isStudyMessage && !studyMessage[0].isDistributed && (
                            <Restriction ability="read" feature={'conciergeMsgsWithSitesEditButton'}>

                              <Button
                                disableElevation
                                color="inherit"
                                variant="contained"
                                onClick={() => {
                                  onEditOfStudyMessage()
                                }}
                              >
                              Edit
                              </Button>

                            </Restriction>
                          )}
                          {studyMessage[0].isStudyMessage && !studyMessage[0].isDistributed && (
                            <Restriction ability="read" feature={'conciergeMsgsWithSitesEditButton'}>

                              <Button
                                disableElevation
                                color="inherit"
                                variant="contained"
                                onClick={() => {
                                  dispatch(setDeleteStudyMessageConfirmation(true))
                                }}
                              >
                              Delete
                              </Button>

                            </Restriction>
                          )}
                        </>
                      )}

                    {/* This rule is only applicable for patient mailBox, on actual mailbox we dont need CAN */}
                    {selectedCommunicationTabId === communicationTabIDs.conciergeMsgsForPatients
                      ? canReadReplyButton &&
                      !isCompleted &&
                      !studyMessage[0].isStudyMessage && (
                        <Button
                          disableElevation
                          color="inherit"
                          variant="contained"
                          onClick={() => {
                            dispatch(resetMessages(selectedCommunicationTabId))
                            dispatch(setIsResponding(true))
                          }}
                        >
                          Reply
                        </Button>
                      )
                      : !isCompleted &&
                      !studyMessage[0].isStudyMessage && (
                        <Button
                          disableElevation
                          color="inherit"
                          variant="contained"
                          onClick={() => {
                            dispatch(resetMessages(selectedCommunicationTabId))
                            dispatch(setIsResponding(true))
                          }}
                        >
                          Reply
                        </Button>
                      )}

                    <Button
                      disableElevation
                      color="inherit"
                      variant="contained"
                      onClick={() => {
                        setExpandAll(true)
                        setDefaultOpenPanel(null)
                      }}
                    >
                    Expand All
                    </Button>
                    <Button
                      disableElevation
                      color="inherit"
                      variant="contained"
                      onClick={() => {
                        setExpandAll(false)
                        setDefaultOpenPanel(null)
                      }}
                    >
                    Collapse All
                    </Button>
                  </ActionsWrapper>
                </Grid>
              )}
              <Bar color="gray" fullWidth />

              <MailBody elevation={0}>
                <AccordionGroup
                  expandAll={expandAll}
                  defaultOpenPanel={defaultOpenPanel}
                  handleExpandAll={setExpandAll}
                  contentBackground="gray"
                  accordions={studyMessage.map(mt => {
                    const hasAttachment = mt.attachments.length > 0
                    return {
                      label: (
                        <LabelWrapper>
                          <TitleWrapper>
                            <Typography variant="subtitle1">{mt.subject}</Typography>
                          </TitleWrapper>

                          <DateTimeWrapper variant="caption">
                            {mt.isStudyMessage
                              ? `${mt.distributionDate} | ${mt.distributionTime}`
                              : `${mt.createdDate} | ${mt.createdTime}`}

                            {hasAttachment && <AttachedLogo />}
                          </DateTimeWrapper>
                        </LabelWrapper>
                      ),
                      component: (
                        <>
                          <MessageDetails container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <PropertyValue
                                label="Sent To:"
                                value={mt.sendTo.displayText}
                                labelProps={{ variant: 'body2' }}
                                fullWidth={false}
                                align="row"
                              />
                            </Grid>

                            {mt.sponsorSiteId && (
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <PropertyValue
                                  label="Site ID:"
                                  labelProps={{ variant: 'body2' }}
                                  value={mt.sponsorSiteId}
                                  fullWidth={false}
                                  align="row"
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <PropertyValue
                                label="From:"
                                value={mt.fromPolicy ? `${mt.from}, ${mt.fromPolicy}` : mt.from}
                                labelProps={{ variant: 'body2' }}
                                fullWidth={false}
                                align="row"
                              />
                            </Grid>

                            {mt.siteCountry && (
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <PropertyValue
                                  label="Site Country:"
                                  labelProps={{ variant: 'body2' }}
                                  value={mt.siteCountry}
                                  fullWidth={false}
                                  align="row"
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} sm={12} md={6} lg={6} />

                            {mt.systemId && (
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <PropertyValue
                                  label="TCN #:"
                                  labelProps={{ variant: 'body2' }}
                                  value={mt.systemId}
                                  fullWidth={false}
                                  align="row"
                                />
                              </Grid>
                            )}
                          </MessageDetails>

                          <Grid container spacing={1}>
                            <Restriction ability="read" feature={`${selectedCommunicationTabId}ViewMessageRecipients`}>
                              {studyMessage[0].isDistributed
                                ? (
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <ViewUsersLink
                                      color="primary"
                                      variant="text"
                                      onClick={() => dispatch(fetchDistributionList(mt.id))}
                                    >
                                    View Message Recipients
                                    </ViewUsersLink>
                                  </Grid>)
                                : ''}
                            </Restriction>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Bar color="midnight" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              {parse(mt.body)}
                            </Grid>
                            {hasAttachment && (
                              <AttachedFilesWrapper item xs={12} sm={12} md={12} lg={12}>
                                {mt.attachments.map((eachFile, index) => (
                                  <div key={index}>
                                    <AttachmentButton
                                      onAttachmentClicked={() =>
                                        dispatch(downloadAttachment(eachFile))
                                      }
                                      title={eachFile.name}
                                      size={`${eachFile.size / 1000} KB`}
                                      filetype={eachFile.type}
                                    />
                                  </div>
                                ))}
                              </AttachedFilesWrapper>
                            )}
                          </Grid>
                        </>
                      )
                    }
                  })}
                  color="primary"
                  labelComponent={true}
                />
              </MailBody>
            </>
            <QuickView
              title="Confirmation"
              onClose={() => dispatch(setDeleteStudyMessageConfirmation(false))}
              contentText="Are you sure you want to delete this message?"
              dialogActions={
                <ConfirmationActionsWrapper>
                  <Button
                    color="primary"
                    size="medium"
                    onClick={() => {
                      dispatch(
                        deleteStudyMessage(studyMessage[0], patientInformation.userId, selectedCommunicationTabId)
                      )
                    }}
                  >
                  Yes
                  </Button>
                  <Button
                    label="No"
                    onClick={() => dispatch(setDeleteStudyMessageConfirmation(false))}
                  >
                  No
                  </Button>
                </ConfirmationActionsWrapper>
              }
              isDialogOpen={deleteStudyMessageConfirmation}
              maxWidth="sm"
            />
            <QuickView
              title="Confirmation"
              onClose={() => setToggleIsCompletedFlagModal(false)}
              contentText={`${
                isCompleted
                  ? 'Are you sure you want to mark this message as active?'
                  : 'Are you sure you want to mark this message as complete?'
              }`}
              alignActions="flex-start"
              dialogActions={
                <ConfirmationActionsWrapper>
                  <Button
                    color="primary"
                    size="medium"
                    onClick={() => {
                      dispatch(
                        toggleIsCompletedFlag(patientInformation.userId, selectedCommunicationTabId)
                      )
                      setToggleIsCompletedFlagModal(false)
                    }}
                  >
                  Yes
                  </Button>
                  <Button label="No" onClick={() => setToggleIsCompletedFlagModal(false)}>
                  No
                  </Button>
                </ConfirmationActionsWrapper>
              }
              isDialogOpen={toggleIsCompletedFlagModal}
              maxWidth="sm"
            />
          </>
        )
        : (
          <Typography variant="subtitle1">No message found.</Typography>
        )}
    </MailWrapper>
  )
}

export default StudyMessageContent
