import React from 'react'
import { Drawer, IconButton, ListItemButton } from '@mui/material'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { bbkRoutes } from 'routes'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { setIsSidebarOpen } from 'store/ducks/userInteractions'
import { Restriction } from 'permissions'
import { Badge } from './utils'
import { fetchBadgesPerModule, setBadgesPerModule } from 'store/ducks/application'
import { clearInstanceInterceptor } from 'store/ducks/auth'

const DrawerWidth = 175

const openedMixin = theme => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(8)
  }
})

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: `${theme.palette.primary.main}`,
    border: 'unset',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      ...openedMixin(theme)
    })
  },
  '.MuiListItemText-root': {
    marginLeft: '10px',
    marginBottom: '0px',
    whiteSpace: 'break-spaces',
    maxWidth: '85px',
    color: `${theme.palette.white.main}`,
    ...(!open && { display: 'none' })
  },
  '.Mui-selected': {
    backgroundImage: 'linear-gradient( to right, #4e4e4e59, #42424287 )',
    borderLeft: `5px solid ${theme.palette.secondary.main}`
  }
}))

const ListWrapper = styled(List)(({ theme }) => ({
  '&&': {
    paddingTop: '0px',
    '.MuiListItemIcon-root': {
      minWidth: '20px',
      color: `${theme.palette.white.main}`
    }
  }
}))

const StyledListItemButton = styled(ListItemButton)(({ theme, bar }) => ({
  '&&': {
    padding: '20px 15px',
    borderBottomColor: theme.palette.primary.light,
    borderBottomWidth: `${bar === 'true' ? '10px' : '1.33px'}`,
    height: '50px'
  }
}))

const StyledBar = styled(Divider)(({ theme, bold }) => ({
  '&&': {
    borderColor: theme.palette.primary.light,
    borderBottomWidth: bold === 'true' ? '10px' : '1.33px'
  }
}))

const SideBarHeader = styled.div`
  display: flex;
`

const SideBarToggle = styled(IconButton)(({ theme, open }) => ({
  position: 'fixed',
  top: '7px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.standard
  }),
  ...(open
    ? {
      left: '11rem'
    }
    : {
      left: '4rem'
    })
}))

const Sidebar = () => {
  const { isSidebarOpen } = useSelector(store => store.userInteractions)
  const { associatedInstances } = useSelector(store => store.auth)
  const { badgesPerModule } = useSelector(store => store.application)
  const location = useLocation()
  const { instanceID } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const onSideBarOptionClicked = async route => {
    if (route.id === 'studyList') {
      clearInstanceInterceptor()
      navigate(`/instance/${instanceID}/${route.to}`)
    } else {
      dispatch(setBadgesPerModule({ isLoading: true }))
      await dispatch(fetchBadgesPerModule())
      route.children
        ? navigate(`/instance/${instanceID}/${route.to}/${route.children[0].to}`)
        : navigate(`/instance/${instanceID}/${route.to}`)
    }
  }

  return (
    <CustomDrawer open={isSidebarOpen} variant="permanent">
      <ListWrapper>
        <SideBarHeader>
          <SideBarToggle
            aria-label="sidebar toggle"
            color="primary"
            onClick={() => dispatch(setIsSidebarOpen(!isSidebarOpen))}
            size="large"
            open={isSidebarOpen}
            data-testid="sidebar-toggle-button"
          >
            {isSidebarOpen
              ? (
                <ChevronLeft size="large" data-testid="sidebar-chevron-left" />
              )
              : (
                <ChevronRight data-testid="sidebar-chevron-right" />
              )}
          </SideBarToggle>
        </SideBarHeader>
        <StyledBar light />
        {bbkRoutes.map((route, index) => {
          const sideNavButton = (
            <StyledListItemButton
              divider
              onClick={() => {
                onSideBarOptionClicked(route)
              }}
              bar={`${route.type === 'bar'}`}
              selected={location.pathname.includes(route.to)}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'overline',
                  gutterBottom: false,
                  component: 'p'
                }}
              >
                {route.label}
              </ListItemText>
              {badgesPerModule.results[route.id] && (
                <Badge
                  invisible={!badgesPerModule.results[route.id].count}
                  badgeCount={badgesPerModule.results[route.id].count}
                />
              )}
            </StyledListItemButton>
          )
          return route.icon
            ? (
              ['myAccount', 'logout'].includes(route.id)
                ? ( // permissions not needed for these two items
                  <span key={index}>{sideNavButton}</span>
                )
                : (
                  <Restriction ability="read" feature={route.id} key={index}>
                    {
                      route.id === 'studyList'
                        ? associatedInstances.length > 1 && sideNavButton
                        : sideNavButton
                    }
                  </Restriction>
                )
            )
            : (
              ''
            )
        })}
      </ListWrapper>
    </CustomDrawer>
  )
}

export default Sidebar
