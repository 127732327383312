import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  // Form,
  Formik
} from 'formik'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
// import Edit from '@mui/icons-material/Edit'
// import { fetchCountriesLookup } from 'store/ducks/common'
import { showError, showSuccess } from 'store/ducks/application'
import {
  saveSupplementalInformation
} from 'services/patientRecord/patientProfile'

import {
// Button,
// TextBoxOuterLabel,
// AutoCompleteWithLabel,
// DatePickerWithLabel,
// Checkbox
} from 'components/common'
// import { Restriction } from 'permissions'
// import { fetchSupplementalInformation } from 'store/ducks/patientRecord/patientProfile'

// const ActionWrapper = styled.div`
//   display: flex;
//   margin-top: 0px;
// `
// const IconButtonWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
// `
// const PaddedGridItem = styled(Grid)(({ theme }) => ({
//   '&&': {
//     paddingTop: theme.spacing(3)
//   }
// }))
// const CenterAlignGrid = styled(Grid)(
//   ({ theme }) => `
//   align-self: center;
//   margin-top: ${theme.spacing(1)};
// `
// )
const MarginDiv = styled.div(
  ({ theme }) => `  
  margin-top: ${theme.spacing(4.1)};
`
)

const EditSupplementalInfo = ({ userId, userSupportPersonId }) => {
  const dispatch = useDispatch()
  const { supplementalInfo } = useSelector(store => store.patientProfile)
  const [isReadOnly, setIsReadOnly] = useState(true)
  // const { countriesLookup } = useSelector(store => store.common)

  const validationSchema = Yup.object({
    passportNumber: Yup.string().when('hasNoPassport', {
      is: false,
      then: Yup.string().trim().required('Required')
    }),
    passportExpirationDate: Yup.string()
      .nullable({ isNullable: true })
      .when('hasNoPassport', {
        is: false,
        then: Yup.string().nullable({ isNullable: true }).required('Required')
      }),
    passportIssuedCountry: Yup.object()
      .nullable({ isNullable: true })
      .when('hasNoPassport', {
        is: false,
        then: Yup.object().nullable({ isNullable: true }).required('Required')
      }),
    hasNoPassport: Yup.bool()
  })

  // useEffect(() => {
  //   if (userId) {
  //     dispatch(fetchSupplementalInformation(userId, userSupportPersonId))
  //   }
  // }, [userId])

  const submitHandler = async data => {
    const requestData = {
      userId,
      userSupportPersonId: userSupportPersonId || null,
      passportNumber: data.passportNumber.trim(),
      passportExpirationDate: data.passportExpirationDate || '',
      passportIssuedCountryId: data.passportIssuedCountry?.id || null,
      hasNoPassport: data.hasNoPassport,
      supplementalInformationId: data.supplementalInformationId
    }
    try {
      await saveSupplementalInformation(requestData)
      dispatch(showSuccess('Information saved successfully!'))
      setIsReadOnly(true)
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    }
  }

  /**
   * On NoPassport true, clear other fields and make them ReadOnly.
   */
  // const onHasNoPassportChange = (event, setFieldValue) => {
  //   setFieldValue('hasNoPassport', event.target.checked)
  //   if (event.target.checked === true) {
  //     setFieldValue('passportNumber', '')
  //     setFieldValue('passportExpirationDate', null)
  //     setFieldValue('passportIssuedCountry', null)
  //   }
  // }

  return supplementalInfo.isLoading
    ? (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box >
            <Skeleton height={64} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box >
            <Skeleton height={64} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box >
            <Skeleton height={64} />
          </Box>
        </Grid>
      </Grid>
    )
    : (
      <Formik
        initialValues={supplementalInfo.results}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {() => (
          <div style={{ width: '100%' }}>
            {/* <Restriction ability="read" feature="patientProfileArrive">
              {isReadOnly && (
                <IconButtonWrapper>
                  <Button
                    color="secondary"
                    aria-label="Edit"
                    onClick={() => setIsReadOnly(false)}
                    startIcon={<Edit />}
                    size="small"
                  >
                Edit
                  </Button>
              &nbsp;
                </IconButtonWrapper>
              )}
            </Restriction> */}
            {!isReadOnly && <MarginDiv />}
            <Typography variant="h4">
              <b>TCN Arrive</b>
            </Typography>
            <br />
            {/* <Typography variant="body1">
              <b>Passport Information:</b> Required for international travel. The Concierge will alert
            you when required.
            </Typography> */}
            <br />
            {/* <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
                  <TextBoxOuterLabel
                    size="small"
                    id="passportNumber"
                    label="Passport Number"
                    name="passportNumber"
                    type="text"
                    disabled={isReadOnly || values.hasNoPassport}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <DatePickerWithLabel
                    id="passportExpirationDate"
                    label="Passport Expiration Date"
                    name="passportExpirationDate"
                    size="small"
                    minDate={new Date()}
                    disabled={isReadOnly || values.hasNoPassport}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
                  <AutoCompleteWithLabel
                    id="passportIssuedCountry"
                    label="Passport Issuing Country"
                    name="passportIssuedCountry"
                    options={countriesLookup.results}
                    isLoading={countriesLookup.isLoading}
                    fetchOptions={() => {
                      if (countriesLookup.results?.length === 0) {
                        dispatch(fetchCountriesLookup())
                      }
                    }}
                    disabled={isReadOnly || values.hasNoPassport}
                    size="small"
                  />
                </Grid>
                <Grid item xs={0} sm={0} md={0.5} lg={0.5}>
                  <div></div>
                </Grid>
                <CenterAlignGrid item xs={12} sm={12} md={3} lg={3}>
                  <Checkbox
                    id="hasNoPassport"
                    label="No Passport"
                    name="hasNoPassport"
                    size="medium"
                    disabled={isReadOnly}
                    onChange={event => onHasNoPassportChange(event, setFieldValue, values)}
                  />
                </CenterAlignGrid>
                {!isReadOnly && (
                  <PaddedGridItem item xs={12} sm={12} md={12} lg={12}>
                    <ActionWrapper>
                      <Button size="large" fullWidth={false} color="primary" type="submit">
                      Save
                      </Button>
                    &ensp;&ensp;
                      <Button
                        size="large"
                        color="inherit"
                        fullWidth={false}
                        onClick={() => {
                          setIsReadOnly(true)
                          resetForm()
                        }}
                      >
                      Cancel
                      </Button>
                    </ActionWrapper>
                  </PaddedGridItem>
                )}
              </Grid>

              <br />
              <hr />
            </Form> */}
          </div>
        )}
      </Formik>
    )
}

EditSupplementalInfo.propTypes = {
  userId: PropTypes.string,
  userSupportPersonId: PropTypes.string || null
}

export default EditSupplementalInfo
