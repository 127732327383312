import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { saveParticipationProgressInfo } from 'store/ducks/patientRecord/participationProgress'
import { Grid, Tooltip, Typography } from '@mui/material'
import {
  AutoCompleteWithLabel,
  Button,
  DatePickerWithLabel,
  TextBoxOuterLabel
} from 'components/common'
import styled from '@emotion/styled'
import { validationMapper } from 'components/helper/validationEngine'
import { useDispatch, useSelector } from 'react-redux'
import { formElements } from 'components/helper/constants/common'
import { Error } from '@mui/icons-material'
import { fetchEnrollmentCodesLookup, fetchParticipationStatusLookup } from 'store/ducks/application'
import { fetchSitesLookup } from 'store/ducks/common'
import { useRestriction, Restriction } from 'permissions'
import { participationProgressOptions } from 'components/helper/constants/participationProgress'

const StyledLabel = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.white.main};
    padding-bottom: ${theme.spacing(0.5)};
`
)

const StyledDateLabel = styled(Typography)(
  ({ theme }) => `
color: ${theme.palette.white.main};
`
)

const AppointmentDateGrid = styled(Grid)(({ theme }) => ({
  '&&': {
    '.MuiInputBase-root': {
      marginTop: `${theme.spacing(3)} !important`
    }
  }
}))

const StatusReasonLabel = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spacing(0.5)};
`
)
const StyledDiv = styled.div(
  ({ theme }) => `
  padding-top: ${theme.spacing(0.5)};
`
)
const StatusReasonWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(3)};
`
)
const SubjectIDLabel = styled.div(
  ({ theme }) => `
    display: flex;
    color: ${theme.palette.white.main};  
    svg{
        font-size: 20px;
    }
    p {
      margin-right: ${theme.spacing(1 / 2)};
    };
    padding-bottom: ${theme.spacing(0.5)};
  `
)

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
    button {
      margin-top: ${theme.spacing(1)};
      margin-right: ${theme.spacing(2)};
      height: fit-content;
    }
    display: flex;
    align-items: start;
    justify-content: flex-start;
`
)

const SelectedSubjectIDWrapper = styled.div(({ theme }) => ({
  border: `2px solid ${theme.palette.background.disabled}`,
  height: '45px',
  backgroundColor: `${theme.palette.background.disabled}`,
  padding: `${theme.spacing(1.3, 1.3, 1.3, 1)}`,
  marginTop: `${theme.spacing(2.5)}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  color: `${theme.palette.white.contrastText}`
}))

const validationSchema = (
  isNotesRequired,
  subjectIdAlreadyExists,
  subjectIDIsOpenText,
  isAppointmentDateRequired
) =>
  validationMapper([
    {
      id: 'preconsentStatus',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
    },
    {
      ...(subjectIDIsOpenText && subjectIdAlreadyExists && {
        dependency: {
          type: 'test',
          condition: value => (value ? value.isSubjectId : false),
          message: 'Please select a post-consent status'
        }
      })
    },
    {
      id: 'appointmentDate',
      isResponseRequired: isAppointmentDateRequired,
      answerType: formElements.date,
      condition: {
        min: new Date(),
        minError: 'Appointment Date must be in the future'
      },
      error: 'Appointment Date is required and must be a valid date'
    },
    { id: 'site', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'subject',
      isResponseRequired: true,
      answerType: subjectIDIsOpenText ? formElements.openText : formElements.dropdownMenu,
      dependency: {
        elements: [],
        callBack: () => subjectIdAlreadyExists
      },
      ...(subjectIDIsOpenText && {
        dependency: {
          elements: ['preconsentStatus'],
          callBack: preconsentStatus =>
            /**
             * required for Post Consent Status
             */
            preconsentStatus?.isSubjectId === true,
          error: 'Please enter a Subject ID when selecting a post-consent status.'
        }
      })
    },
    {
      id: 'statusReason',
      isResponseRequired: true,
      answerType: formElements.openText,
      dependency: {
        elements: [],
        callBack: () => isNotesRequired
      }
    }
  ])

const EditParticipationProgress = () => {
  const dispatch = useDispatch()
  const { participationProgressDetails } = useSelector(store => store.participationProgress)
  const { enrollmentCodesLookup, participationStatusLookup } = useSelector(
    store => store.application
  )
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { isLoading } = useSelector(store => store.userInteractions)
  const { sitesLookup } = useSelector(store => store.common)
  const [isNotesRequired, setIsNotesRequired] = useState(false)
  const [isAppointmentDateRequired, setAppointmentDateRequired] = useState(false)

  // Subject ID should be Open Text when participationProgressDetails.isIVRIntegration is FALSE
  const subjectIDIsOpenText = !participationProgressDetails.isIVRIntegration

  // participation progress dropdown should *NOT* be disabled when isIVRIntegration is FALSE
  // this will get the value from configuration module
  const isDisabledParticipationProgress =
    !useRestriction('edit', 'participationProgressPreconsentStatusDD') ||
    (participationProgressDetails.isIVRIntegration && !!participationProgressDetails.subject)

  useEffect(() => {
    dispatch(fetchParticipationStatusLookup())
    dispatch(fetchSitesLookup())
  }, [])

  useEffect(() => {
    if (participationProgressDetails.site?.alternateIdentifier) {
      dispatch(fetchEnrollmentCodesLookup(participationProgressDetails.site.alternateIdentifier))
    }
  }, [participationProgressDetails.site?.alternateIdentifier])

  useEffect(() => {
    if (
      participationProgressDetails.preconsentStatus?.id ===
      participationProgressOptions.appointmentScheduled
    ) {
      setAppointmentDateRequired(true)
    }
  }, [participationProgressDetails.preconsentStatus])

  // Check if selectedValue of preconsentedStatus dropdown has isAdditionalNote === true
  // or site dropdown is changed
  const onPreConsentStatusChange = (selectedValues, reason, setFieldValue, formikValues) => {
    if (reason === 'selectOption') {
      setFieldValue('preconsentStatus', selectedValues)
      if (
        selectedValues.isAdditionalNote ||
        formikValues.site?.id !== participationProgressDetails.site.id
      ) {
        setIsNotesRequired(true)
      } else {
        setIsNotesRequired(false)
      }

      // when "Appointment Scheduled" option is selected
      if (selectedValues.id === 22) {
        setAppointmentDateRequired(true)
      } else {
        setAppointmentDateRequired(false)
      }
    } else {
      setFieldValue('preconsentStatus', null)
      setIsNotesRequired(false)
      setAppointmentDateRequired(false)
    }
  }

  // Check if selectedValue of preconsentedStatus dropdown has isAdditionalNote === true
  // or site dropdown is changed
  const onSiteChange = (selectedValues, reason, setFieldValue, formikValues) => {
    if (reason === 'selectOption') {
      setFieldValue('site', selectedValues)
      // fetch lookup for subject ID only when user selects a site
      if (!subjectIDIsOpenText) {
        dispatch(fetchEnrollmentCodesLookup(selectedValues.alternateIdentifier))
      }
      if (
        selectedValues.id !== participationProgressDetails.site.id ||
        formikValues.preconsentStatus?.isAdditionalNote
      ) {
        setIsNotesRequired(true)
      } else {
        setIsNotesRequired(false)
      }
    } else {
      setFieldValue('site', null)
      setIsNotesRequired(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={{ ...participationProgressDetails }}
        enableReinitialize={true}
        validationSchema={validationSchema(
          isNotesRequired,
          !!participationProgressDetails.subject,
          subjectIDIsOpenText,
          isAppointmentDateRequired
        )}
        onSubmit={(values, { setSubmitting }) => {
          setIsNotesRequired(false)
          dispatch(saveParticipationProgressInfo(values, patientInformation.userId, true))
          setSubmitting(false)
        }}
      >
        {({ values, dirty, setFieldValue }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Restriction ability="read" feature="participationProgressPreconsentStatusDD">
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <AutoCompleteWithLabel
                    id="preconsentStatus"
                    label={<StyledLabel variant="body2">Participation Progress</StyledLabel>}
                    name="preconsentStatus"
                    multiple={false}
                    hasSelectAllOption={false}
                    size="small"
                    options={participationStatusLookup.results}
                    isLoading={participationStatusLookup.isLoading}
                    disabled={isDisabledParticipationProgress}
                    onChange={(event, selectedValues, reason) =>
                      onPreConsentStatusChange(selectedValues, reason, setFieldValue, values)
                    }
                  />
                </Grid>
              </Restriction>
              {isAppointmentDateRequired
                ? (
                  <AppointmentDateGrid item xs={12} sm={12} md={6} lg={3}>
                    <DatePickerWithLabel
                      id="appointment-date"
                      label={<StyledDateLabel variant="body2">Appointment Date:</StyledDateLabel>}
                      required={true}
                      name="appointmentDate"
                      minDate={new Date()}
                      size="small"
                      disabled={isDisabledParticipationProgress}
                    />
                  </AppointmentDateGrid>
                )
                : (
                  ''
                )}
              <Restriction ability="read" feature="participationProgressSiteId">
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <AutoCompleteWithLabel
                    id="site"
                    label={<StyledLabel variant="body2">Site ID Assignment</StyledLabel>}
                    name="site"
                    options={sitesLookup.results}
                    size="small"
                    // Disable logic only for SubjectID Dropdown (IVRIntegration=true)
                    disabled={!subjectIDIsOpenText && !!participationProgressDetails.subject}
                    onChange={(event, selectedValues, reason) =>
                      onSiteChange(selectedValues, reason, setFieldValue, values)
                    }
                  />
                </Grid>
              </Restriction>
              <Restriction ability="read" feature="participationProgressSubjectId">
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  {subjectIDIsOpenText
                    ? (
                      <TextBoxOuterLabel
                        size="small"
                        id="subject"
                        name="subject"
                        label={
                          <SubjectIDLabel>
                            <Typography variant="body2">Subject ID:</Typography>
                          </SubjectIDLabel>
                        }
                        fullWidth={true}
                      />
                    )
                    : participationProgressDetails.subject
                      ? (
                        /**
                         * This is to show an disabled input field style wrapper
                         * and a button to disassociate existing subject ID
                         */
                        <StyledDiv>
                          <SelectedSubjectIDWrapper>
                            {participationProgressDetails.subject.displayText}
                          </SelectedSubjectIDWrapper>
                          <Button
                            variant="text"
                            onClick={() =>
                              dispatch(
                                saveParticipationProgressInfo(
                                  { ...values, subject: null },
                                  patientInformation.userId,
                                  true,
                                  true
                                )
                              )
                            }
                          >
                            <SubjectIDLabel>
                              <Typography variant="body1">Edit Subject ID</Typography>
                            </SubjectIDLabel>
                          </Button>
                        </StyledDiv>
                      )
                      : (
                        <AutoCompleteWithLabel
                          multiple={false}
                          hasSelectAllOption={false}
                          size="small"
                          id="subject"
                          name="subject"
                          label={
                            <SubjectIDLabel>
                              <Typography variant="body2">Subject ID:</Typography>
                              <Tooltip
                                color="error"
                                size="small"
                                title={
                                  <Typography variant="body2">
                                    Not seeing your patient&apos;s Subject ID? Try again later.
                                  </Typography>
                                }
                              >
                                <Error />
                              </Tooltip>
                            </SubjectIDLabel>
                          }
                          options={enrollmentCodesLookup.results}
                          isLoading={!!(enrollmentCodesLookup.isLoading || isLoading.length)}
                        />
                      )}
                </Grid>
              </Restriction>
              {isNotesRequired && (
                <StatusReasonWrapper item xs={12} sm={12} md={11} lg={11}>
                  <TextBoxOuterLabel
                    size="small"
                    id="statusReason"
                    label={
                      <StatusReasonLabel>
                        <StyledLabel variant="body2">
                          {
                            'Please provide additional information. If updating the "Participation Progress" status, explain why you\'ve indicated the patient is not interested or unable to contact. (e.g. distance of study site, duration of study, placebo potential).'
                          }
                        </StyledLabel>
                      </StatusReasonLabel>
                    }
                    name="statusReason"
                    type="text"
                  />
                </StatusReasonWrapper>
              )}
              {dirty && (
                <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                  <Button size="large" fullWidth={false} color="secondary" type="submit">
                    Save
                  </Button>
                </ActionWrapper>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EditParticipationProgress
