import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Card, CardContent, useMediaQuery } from '@mui/material'

import { Bar } from 'components/common'

const VisitCard = styled(Card)`
  margin-bottom: 20px;
  border-radius: 24px;
`

const CardTypeWrapper = styled.div(({ theme }) => ({
  '&&': {
    width: '25%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '15%',
      flexDirection: 'column',
    },
  },
}))

const CardTypeContainer = styled.div`
  ${({ theme, isGradient }) =>
    `background-image: linear-gradient(to right, ${theme.palette.gradientBlue.main}, ${
      isGradient ? theme.palette.gradientBlue.light : theme.palette.gradientBlue.main
    });
  color: ${theme.palette.white.main};`}
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 15px;
  width: -webkit-fill-available;
  min-width: -moz-available;
`

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&&': {
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '145px',
    hr: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      hr: {
        width: '85%',
      },
    },
  },
}))

const Arrow = styled.div(({ theme }) => ({
  '&&': {
    height: '0px',
    content: '""',
    width: 0,
    borderTop: 'solid 15px transparent',
    borderLeft: `solid 15px ${theme.palette.gradientBlue.light}`,
    borderRight: 'solid 15px transparent',
    borderBottom: 'solid 15px transparent',
    zIndex: 32,
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      borderTop: `solid 15px ${theme.palette.gradientBlue.light}`,
      borderLeft: 'solid 15px transparent',
    },
  },
}))

const MainContent = styled.div(({ align, theme, wrap, fullWidth }) => ({
  flexWrap: wrap,
  width: `${fullWidth ? '100%' : '50%'}`,
  display: 'flex',
  flexDirection: align,
  margin: '15px',
  justifyContent: 'start',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '85%',
  },
}))

const SubContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '5px 5px 15px 5px',
  width: '15%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const SpecificCard = ({
  titleContent,
  mainContent,
  flexDirection,
  flexWrap,
  subContent,
  showArrow,
  isTitleGradient,
}) => {
  const screenBelowMedium = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <VisitCard>
      <StyledCardContent>
        <CardTypeWrapper>
          <CardTypeContainer isGradient={isTitleGradient}>{titleContent}</CardTypeContainer>
          {showArrow && <Arrow />}
        </CardTypeWrapper>
        <MainContent align={flexDirection} wrap={flexWrap} fullWidth={!subContent}>
          {mainContent}
        </MainContent>

        {subContent
          ? (
            <>
              {screenBelowMedium
                ? (
                  <Bar color="midnight" vertical={false} fullWidth={true} />
                )
                : (
                  <Bar color="midnight" vertical={true} />
                )}
              <SubContentWrapper>{subContent}</SubContentWrapper>
            </>
          )
          : null}
      </StyledCardContent>
    </VisitCard>
  )
}

SpecificCard.propTypes = {
  titleContent: PropTypes.node,
  mainContent: PropTypes.node,
  subContent: PropTypes.node,
  flexDirection: PropTypes.oneOf(['row', 'column']),
  flexWrap: PropTypes.oneOf(['wrap', 'noWrap']),
  showArrow: PropTypes.bool,
  isTitleGradient: PropTypes.bool,
}

SpecificCard.defaultProps = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  showArrow: true,
  isTitleGradient: false,
}

export default SpecificCard
