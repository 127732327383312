import React from 'react'
import { Grid, IconButton, Paper } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { AutoComplete, Alert, Button } from 'components/common'
import { clearFilters, getAnnouncements } from 'store/ducks/communicationCenter/announcements'
import PropTypes from 'prop-types'
import { sendToOptionsFor } from 'components/helper/constants/mailbox'
import {
  fetchSitesLookup,
  fetchCountriesLookup,
  fetchProductsLookup,
  // fetchCohortsLookup,
  fetchVendorsLookup,
  fetchMessagesSendCategory,
} from 'store/ducks/common'
import * as Yup from 'yup'
import { ArrowDropUp } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'

const Wrapper = styled(Paper)`
  padding: 25px 25px 0px 25px;
`
const FormElementsWrapper = styled(Grid)`
  max-height: 22vh;
  overflow-y: auto;
  margin-bottom: 25px;
  padding-right: 16px;
`
const CollapseFilter = styled(Grid)`
  display: flex;
  justify-content: center;
  button {
    padding-bottom: 0px;
  }
`

const AnnouncementFilters = ({ handleClose }) => {
  const dispatch = useDispatch()
  const {
    sitesLookup,
    countriesLookup,
    productsLookup,
    // cohortsLookup,
    vendorsLookup,
    messagesSendCategory,
  } = useSelector(store => store.common)
  const { filters } = useSelector(store => store.announcements)
  const { pathname } = useLocation()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const selectedCommunicationTabId = getSelectedId(pathname)
  const handleClearFilters = formikProps => {
    formikProps.resetForm()
    handleClose()
    dispatch(clearFilters())
    dispatch(getAnnouncements(true, false, patientInformation.userId, selectedCommunicationTabId))
  }

  const validations = Yup.object().shape({
    filterFormValidation: Yup.bool().when(['sentTo', 'countries', 'cohorts', 'sites', 'products', 'vendors'], {
      // eslint-disable-next-line max-params
      is: (sentTo, countries, cohorts, sites, products, vendors) =>
        ![sentTo, countries, cohorts, sites, products, vendors].filter(t => t.filter(Boolean).length > 0).length > 0,
      then: Yup.bool().required('Minimum one selection is required to filter the list.'),
      otherwise: Yup.bool(),
    }),
  })

  return (
    <Wrapper>
      <Formik
        initialValues={filters}
        enableReinitialize={true}
        validationSchema={validations}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(getAnnouncements(true, values, patientInformation.userId, selectedCommunicationTabId))
          setSubmitting(false)
        }}
      >
        {formikProps => (
          <Form>
            <FormElementsWrapper container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  id="sentTo"
                  label="Sent To"
                  name="sentTo"
                  options={messagesSendCategory.results}
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                  isLoading={messagesSendCategory.isLoading}
                  fetchOptions={() => dispatch(fetchMessagesSendCategory(sendToOptionsFor[selectedCommunicationTabId]))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  id="country"
                  label="Countries"
                  name="countries"
                  options={countriesLookup.results}
                  fetchOptions={() => dispatch(fetchCountriesLookup())}
                  isLoading={countriesLookup.isLoading}
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                />
              </Grid>
              {/* ! not needed for now */}
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  id="cohorts"
                  label="Cohorts"
                  name="cohorts"
                  options={cohortsLookup.results}
                  isLoading={cohortsLookup.isLoading}
                  fetchOptions={() => dispatch(fetchCohortsLookup())}
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  id="sites"
                  label="Sites"
                  name="sites"
                  options={sitesLookup.results}
                  isLoading={sitesLookup.isLoading}
                  fetchOptions={() => dispatch(fetchSitesLookup())}
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  id="products"
                  label="Products"
                  name="products"
                  options={productsLookup.results}
                  isLoading={productsLookup.isLoading}
                  fetchOptions={() => dispatch(fetchProductsLookup())}
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoComplete
                  id="selectVendor"
                  name="vendors"
                  label="Vendor(s)"
                  options={vendorsLookup.results}
                  isLoading={vendorsLookup.isLoading}
                  fetchOptions={() => dispatch(fetchVendorsLookup())}
                  multiple={true}
                  hasSelectAllOption={false}
                  size="small"
                />
              </Grid>
            </FormElementsWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Alert name="filterFormValidation" severity="error" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button type="submit" size="medium" fullWidth color="primary">
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button
                  aria-label="clear-and-close-filters"
                  size="medium"
                  fullWidth
                  color="inherit"
                  onClick={() => handleClearFilters(formikProps)}
                >
                  Clear
                </Button>
              </Grid>
              <CollapseFilter item xs={12} sm={12} md={12} lg={12}>
                <IconButton aria-label="Collapse Filters" color="primary" onClick={() => handleClose()} size="large">
                  <ArrowDropUp fontSize="large" />
                </IconButton>
              </CollapseFilter>
            </Grid>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

AnnouncementFilters.propTypes = {
  handleClose: PropTypes.func,
}

export default AnnouncementFilters
