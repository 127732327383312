import { backendAxios, getConfig } from 'services/backend'

export const getReimbursementConfiguration = () =>
  backendAxios
    .get('/configuration/reimbursement', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

export const getReimbursementCatAndCapHeaders = countryIds =>
  backendAxios
    .get('/configuration/reimbursementcaps', getConfig({ countryIds }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getReimbursementCountries = () =>
  backendAxios
    .get('/common/configuration/reimbursementcountries', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

export const getEligibleParticipants = () =>
  backendAxios
    .get('/common/getsupportpersontypes', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

export const getReimbursementCapTypes = () =>
  backendAxios
    .get('/common/reimbursementCapTypes', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))

export const getReimbursementServices = () =>
  backendAxios
    .get('/common/reimbursementServices', getConfig())
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err))
export const submitCardConfigurations = cardConfigurations =>
  backendAxios
    .post('/configuration/reimbursement', cardConfigurations, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(error => Promise.reject(error))
export const submitCatAndCap = catAndCapPayload =>
  backendAxios
    .post('/configuration/reimbursementcaps', { siteCaps: catAndCapPayload }, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(error => Promise.reject(error))
