import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import {
  AutoCompleteWithLabel,
  Button,
  Checkbox,
  TextBoxOuterLabel,
  PageHeader,
  DatePickerWithLabel
} from 'components/common'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import styled from '@emotion/styled'
import {
  onConfirmPatientSupportReimbursementInfo,
  setSupportPersonSignUpSuccess
} from 'store/ducks/patientRecord/reimbursement'
import { fetchCountriesLookup } from 'store/ducks/common'
import StatesDropdown from 'components/common/form/StatesDropdown'
import QuickView from 'components/common/QuickView'
import SignUpSuccess from './SignUpSuccess'
import RequestPlasticCardModal from '../manageAccount/RequestPlasticCardModal'
import { AddCardTwoTone } from '@mui/icons-material'
import { getUserSupportPersonType } from 'components/helper/constants/reimbursement'

const validationSchema = validationMapper([
  { id: 'firstName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'lastName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'emailAddress', isResponseRequired: true, answerType: formElements.emailOpenText },
  {
    id: 'emailAddress',
    isResponseRequired: true,
    answerType: formElements.openText,
    dependency: {
      matchWith: true,
      ref: 'emailAddress',
      message: 'Email must match'
    }
  },
  { id: 'address1', isResponseRequired: true, answerType: formElements.openText },
  { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
  { id: 'state', isResponseRequired: true, answerType: formElements.dropdownMenu },
  { id: 'city', isResponseRequired: true, answerType: formElements.openText },
  { id: 'zipCode', isResponseRequired: true, answerType: formElements.openText },
  { id: 'dateOfBirth', isResponseRequired: true, answerType: formElements.openText },
  {
    id: 'isReimbursementAcknowledged',
    isResponseRequired: true,
    answerType: formElements.checkbox
  }
])

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(1)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: start;
      justify-content: flex-start;
  `
)

const Wrapper = styled.div(
  ({ theme }) => `
  margin-top: ${theme.spacing(5)};
`
)

const FormWrapper = styled.div(
  ({ theme }) => `
  margin-top: ${theme.spacing(4)};
`
)
const LastNameGrid = styled(Grid)(
  ({ theme }) => `
  margin-right: ${theme.spacing(4)};
`
)

const AddressGrid = styled(Grid)(
  ({ theme }) => `
  margin-top: ${theme.spacing(4)};
`
)
const CountryGrid = styled(Grid)(
  ({ theme }) => `
  margin-right: ${theme.spacing(4)};
`
)

const PatientSupportInfo = () => {
  const dispatch = useDispatch()
  const {
    patientSupportsForReimbursement,
    patientInformationForReimbursement,
    supportPersonSignUpSuccess
  } = useSelector(store => store.reimbursement)
  const { countriesLookup } = useSelector(store => store.common)
  const [requestPlasticCardModal, setRequestPlasticCardModal] = useState(false)

  const isDisabled = true

  useEffect(() => {
    dispatch(fetchCountriesLookup())
  }, [])

  return (
    patientSupportsForReimbursement.areSupportPersonsAvailable &&
    patientSupportsForReimbursement.results.map((support, index) => (
      <Wrapper key={index}>
        <PageHeader
          title={
            <Typography variant="subtitle1">
              {getUserSupportPersonType(support.userSupportPersonType.displayText)}
            </Typography>
          }
          expandContent={true}
          bar={true}
          content={
            <FormWrapper>
              <Formik
                initialValues={support}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(
                    onConfirmPatientSupportReimbursementInfo({
                      userId: patientInformationForReimbursement.userId,
                      userSupportPersonId: support.userSupportPersonId
                    })
                  )
                  setSubmitting(false)
                }}
              >
                {({ values }) => (
                  <Form noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3} lg={2.5}>
                        <TextBoxOuterLabel
                          id="first-name"
                          disabled={isDisabled}
                          label="First Name"
                          name="firstName"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={2} lg={1.5}>
                        <TextBoxOuterLabel
                          id="middle-initial"
                          disabled={isDisabled}
                          label="Middle Initial"
                          name="middleInitial"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>
                      <LastNameGrid item xs={12} sm={6} md={3} lg={2.5}>
                        <TextBoxOuterLabel
                          id="last-name"
                          disabled={isDisabled}
                          label="Last Name"
                          name="lastName"
                          fullWidth={true}
                          size="small"
                        />
                      </LastNameGrid>
                      {/* <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <AutoCompleteWithLabel
                          multiple={false}
                          hasSelectAllOption={false}
                          size="small"
                          id="gender"
                          name="gender"
                          disabled={isDisabled}
                          label="Gender"
                          options={genderLookup.results}
                          isLoading={genderLookup.isLoading}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <DatePickerWithLabel
                          id="date-of-birth"
                          label="Date of Birth"
                          name="dateOfBirth"
                          minDate={new Date()}
                          size="small"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6.5}>
                        <TextBoxOuterLabel
                          id="email-address"
                          disabled={isDisabled}
                          label="Email Address"
                          name="emailAddress"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                    <AddressGrid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3} lg={2.5}>
                        <TextBoxOuterLabel
                          id="address1"
                          disabled={isDisabled}
                          label="Address Line 1"
                          name="address1"
                          fullWidth={true}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={2} lg={1.5}>
                        <TextBoxOuterLabel
                          fullWidth={true}
                          size="small"
                          id="address2"
                          disabled={isDisabled}
                          label="Address Line 2"
                          name="address2"
                        />
                      </Grid>

                      <CountryGrid item xs={12} sm={6} md={3} lg={2.5}>
                        <AutoCompleteWithLabel
                          multiple={false}
                          size="small"
                          hasSelectAllOption={false}
                          id="country"
                          disabled={isDisabled}
                          label="Country"
                          name="country"
                          options={countriesLookup.results}
                          isLoading={countriesLookup.isLoading}
                        />
                      </CountryGrid>
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <StatesDropdown
                          multiple={false}
                          size="small"
                          hasSelectAllOption={false}
                          id="state"
                          disabled={isDisabled}
                          label="County / State"
                          name="state"
                          selectedCountry={values.country?.id}
                          outerLabel={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <TextBoxOuterLabel
                          fullWidth={true}
                          size="small"
                          id="city"
                          disabled={isDisabled}
                          label="City"
                          name="city"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={1.5}>
                        <TextBoxOuterLabel
                          fullWidth={true}
                          size="small"
                          id="zip"
                          disabled={isDisabled}
                          label="Zip / Postal Code"
                          name="zipCode"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Checkbox
                          name="isReimbursementAcknowledged"
                          id="confirmed"
                          disabled={!!support.isReimbursementAcknowledged}
                          label="I confirm the information above is complete and correct, and that the
                              person identified with this information is willing to share their data
                              for this service. Furthermore, the information will not be shared with
                              anyone other than study-related personnel or entities."
                          size="small"
                          labelProps={{ variant: 'body2' }}
                        />
                      </Grid>

                      {!support.isReimbursementAcknowledged && (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                          <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
                            <Button size="large" fullWidth={false} color="primary" type="submit">
                              Submit
                            </Button>
                          </ActionWrapper>
                        </>
                      )}
                    </AddressGrid>
                  </Form>
                )}
              </Formik>
            </FormWrapper>
          }
        />
        <QuickView
          title="Success!"
          onClose={() => {
            dispatch(setSupportPersonSignUpSuccess(false))
          }}
          dialogContent={
            <SignUpSuccess
              isAbleToRequestPlasticCard={
                patientInformationForReimbursement.isAbleToRequestPlasticCard
              }
            />
          }
          isDialogOpen={supportPersonSignUpSuccess}
          maxWidth="sm"
          dialogContentStyles={{ button: { marginLeft: '20px' } }}
          dialogActions={
            // patientInformationForReimbursement.isAbleToRequestPlasticCard
            //   ? (
            <Button
              size="medium"
              fullWidth={false}
              color="primary"
              onClick={() => {
                dispatch(setSupportPersonSignUpSuccess(false))
              }}
            >
                Ok
            </Button>
            // )
            // : (
            //   <>
            //     <Button
            //       size="medium"
            //       fullWidth={false}
            //       color="primary"
            //       onClick={() => {
            //         dispatch(setSupportPersonSignUpSuccess(false))
            //         setRequestPlasticCardModal(true)
            //       }}
            //     >
            //     Request Plastic Card
            //     </Button>
            //     <Button
            //       size="medium"
            //       fullWidth={false}
            //       color="inherit"
            //       onClick={() => dispatch(setSupportPersonSignUpSuccess(false))}
            //     >
            //     Decide Later
            //     </Button>
            //   </>
            // )
          }
        />
        <QuickView
          title="Request Plastic Card"
          titleIcon={<AddCardTwoTone />}
          iconAltText="Request Plastic Card"
          onClose={() => setRequestPlasticCardModal(false)}
          dialogContent={
            <RequestPlasticCardModal
              setRequestPlasticCardModal={setRequestPlasticCardModal}
              cardHolderDetails={support}
            />
          }
          isDialogOpen={requestPlasticCardModal}
          maxWidth="md"
        />
      </Wrapper>
    ))
  )
}

export default PatientSupportInfo
