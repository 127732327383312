import axios from 'axios'

export const backendAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

// const isServerError = response =>
//   // Define your logic to check if the response indicates a server error
//   response && response.status >= 500 && response.status < 600

let requestQueue = Promise.resolve()

export const fetchWithRetries = async (method, url, options, payload) => {
  const makeRequest = async () => {
    try {
      if (payload) {
        return await backendAxios[method.toLowerCase()](url, payload, options)
      } else {
        return await backendAxios[method.toLowerCase()](url, options)
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  // Add the new request to the end of the queue
  const queuedRequest = async () => {
    try {
      return await makeRequest()
    } catch (error) {
      throw new Error(error)
    }
  }

  // Chain the new request to the existing queue
  requestQueue = requestQueue.then(queuedRequest)

  return requestQueue
}

export const getConfig = params => {
  const token = localStorage.getItem('accessToken')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  if (params) {
    config.params = params
  }

  return config
}

export const getConfigForDownload = params => {
  const token = localStorage.getItem('accessToken')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    Accept: 'application/json',
    'Content-Type': 'application/octet-stream',
    responseType: 'blob'
  }

  if (params) {
    config.params = params
  }

  return config
}

export const getConfigForFileUpload = params => {
  const token = localStorage.getItem('accessToken')
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    'Content-Type': undefined
  }

  if (params) {
    config.params = params
  }

  return config
}
