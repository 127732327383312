export const addressTypes = {
  patientCare: 27,
  shipping: 4,
  business: 3,
  other: 33
}

export const addressTitles = {
  [addressTypes.business]: 'Site address provided by the sponsor / CRO',
  [addressTypes.patientCare]: 'Patient Visit Location',
  [addressTypes.shipping]: 'Shipping Address'
}
