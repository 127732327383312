import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { TextBoxOuterLabel, AutoCompleteWithLabel, Button } from 'components/common'
import StatesDropdown from 'components/common/form/StatesDropdown'
import { formElements } from 'components/helper/constants/common'
import { validationMapper } from 'components/helper/validationEngine'

const validationSchema = isNoStatesForCountry =>
  validationMapper([

    { id: 'address1', isResponseRequired: true, answerType: formElements.openText },
    { id: 'city', isResponseRequired: true, answerType: formElements.openText },
    { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'state',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
      dependency: {
        elements: [],
        callBack: () => !isNoStatesForCountry
      }
    },
    { id: 'zipCode', isResponseRequired: true, answerType: formElements.openText },
    { id: 'phone', isResponseRequired: true, answerType: formElements.openText },
  ])

const PatientLocationForm = ({ onNewAddressAdded, onSubmitForm }) => {
  const [isNoStatesForCountry, setIsNoStatesForCountry] = useState(false)
  const { countriesLookup } = useSelector(store => store.common)

  const noStatesFoundForSelectedCountry = noStatesForCountry => {
    setIsNoStatesForCountry(noStatesForCountry)
  }

  const onSubmit = values => {
    onNewAddressAdded(values)
    onSubmitForm({ ...defaultAddressField, ...values })
  }

  const defaultAddressField = {
    isAddressVerified: null,
    createdBy: null,
    modifiedBy: null,
    verifiedBy: null,
    createdOn: null,
    modifiedOn: null,
    verifiedOn: null,
    locationName: null,
    address3: null,
    email: '',
    attentionOfContactUserName: '',
    attentionOfContact: null,
    addressId: 2,
    address1: '',
    address2: '',
    country: null,
    state: null,
    city: '',
    zipCode: '',
    phone: '',
  }

  return (
    <Formik
      initialValues={defaultAddressField}
      enableReinitialize={true}
      validationSchema={validationSchema(isNoStatesForCountry)}
      onSubmit={onSubmit}
    >
      {({ values }) => (

        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextBoxOuterLabel required id="address1" label="Address 1" name="address1" type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <TextBoxOuterLabel id="address2" label="Address 2" name="address2" type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <AutoCompleteWithLabel
                id="country"
                required
                label="Country"
                name="country"
                options={countriesLookup.results}
                isLoading={countriesLookup.isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <StatesDropdown
                id="state"
                required
                label="State / Province"
                name="state"
                selectedCountry={values.country?.id}
                noStatesFoundForSelectedCountry={noStatesFoundForSelectedCountry}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <TextBoxOuterLabel required id="city" label="City / Town" name="city" type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <TextBoxOuterLabel required id="zipCode" label="Zip / Postal Code" name="zipCode" type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={7.5} lg={7.5} xl={7.5}>
              <TextBoxOuterLabel required id="phone" label="Phone Number" name="phone" type="text" />
            </Grid>
            <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button size="large" fullWidth={false} color="primary" type="submit" data-testid="submitButton">
                    Save
              </Button>
            </Grid>
          </Grid>
        </Form>

      )}
    </Formik>
  )
}

PatientLocationForm.propTypes = {
  /**
     * To close out any forms/pop-ups
     */
  onSubmitForm: PropTypes.func,
  /**
   * Operation on submitting this form.
   */
  onNewAddressAdded: PropTypes.func
}

export default PatientLocationForm
