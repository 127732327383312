import React from 'react'
import { Card, DataGrid, Pagination } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { setShowDistributionList } from 'store/ducks/communicationCenter/studyMessages'
import { palette } from 'styles/baseTheme'

const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'center',
}

const SentToList = () => {
  const dispatch = useDispatch()
  const { distributionList } = useSelector(store => store.studyMessages)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  return (
    <Card
      title="Message Recipients"
      titleTypographyProps={{ variant: 'subtitle1' }}
      headerColor="secondary"
      cardContentStyles={{ flexDirection: 'column', alignItems: 'flex-end' }}
      headerAction={
        <IconButton
          aria-label="close-announcement-details"
          onClick={() => dispatch(setShowDistributionList(false))}
          size="large"
          color="inherit"
        >
          <Cancel />
        </IconButton>
      }
    >
      <DataGrid
        headers={[
          { id: 'fullName', label: 'Name', isSortable: false, headProperties: { ...headerProperties } },
          { id: 'readUnread', label: 'Read / Unread', isSortable: false, headProperties: { ...headerProperties } },
          { id: 'sponsorSiteID', label: 'Site ID', isSortable: false, headProperties: { ...headerProperties } },
          { id: 'country', label: 'Country', isSortable: false, headProperties: { ...headerProperties } },
        ]}
        tableData={distributionList.results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        alternateRowColored={true}
        border="allSides"
        radius={'false'}
      />
      <Pagination
        canChangeRowsPerPage={[5, 10, 20, 50, 100]}
        totalCount={distributionList.totalCount}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        setPageNumber={pageNumber => setPage(pageNumber)}
        setRowsPerPage={numberOfRows => {
          setRowsPerPage(parseInt(numberOfRows, 10))
          setPage(0)
        }}
      />
    </Card>
  )
}

export default SentToList
