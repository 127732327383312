import React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import { Divider } from '@mui/material'

const HorizontalBar = styled.hr`
  border-width: 3px;
  border-color: ${({ theme, color }) => theme.palette[color]};
  background-color: ${({ theme, color }) => theme.palette[color]};
  width: 48px;
`

const FullWidthBar = styled.hr`
  && {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    height: 1px;
    border-color: ${({ theme, color }) => theme.palette[color].main};
    border-width: 0.1px;
    width: 100%;
    margin: 0px;
  }
`

const VerticalDivider = styled(Divider)`
  && {
    background-color: ${({ theme, color }) => theme.palette[color].main};
    margin-left: 3px;
    border-color: ${({ theme, color }) => theme.palette[color]};
    margin-right: 3px;
    height: inherit;
    width: 2px;
  }
`

const Bar = ({ color, fullWidth, vertical }) => vertical
  ? (
    <VerticalDivider orientation="vertical" color={color} variant="middle" />
  )
  : fullWidth
    ? (
      <FullWidthBar color={color} />
    )
    : (
      <HorizontalBar color={color} />
    )

Bar.propTypes = {
  /**
   * Bar color
   * @default secondary
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'gray', 'white', 'rose', 'midnight']),
  /**
   * When true, bar takes up the full width of it's parent
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * When true, Bar will position vertically
   * @default false
   */
  vertical: PropTypes.bool,
}

Bar.defaultProps = {
  color: 'secondary',
  vertical: false,
  fullWidth: false,
}

export default Bar
