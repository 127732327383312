import { backendAxios, fetchWithRetries, getConfig } from 'services/backend'

export const login = authData =>
  backendAxios
    .post('/auth/login', authData)
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getListOfInstances = () =>
  backendAxios
    .get('/instance/instances', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const postRefreshToken = refreshData =>
  backendAxios
    .post('/auth/refresh-token', refreshData, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const forgotPassword = (email, captchaToken) =>
  backendAxios
    .post('/forgotpassword', { email, token: captchaToken }, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getEmailByResetToken = resetToken =>
  backendAxios
    .get('/validatepasswordtoken', getConfig({ token: resetToken }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const submitNewPassword = payload =>
  backendAxios
    .post('/passwordsetup', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSecurityQuestionsLookup = () =>
  backendAxios
    .get('/securityquestion/questions', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSecurityQuestionsStatus = () =>
  backendAxios
    .get('/securityquestion/usersecurityquestion', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const submitNewAnswerToQues = payload =>
  backendAxios
    .post('/securityquestion/usersecurityquestion', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const validateStoredAnswer = payload =>
  backendAxios
    .get('/securityquestion/validatesecurityquestion', getConfig(payload))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const resetSecurityQnA = () =>
  backendAxios
    .post('/securityquestion/resetusersecurityquestion', {}, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const lastLogin = () => fetchWithRetries('POST', '/user/lastlogindate', getConfig(), {})

export const postLogout = () => backendAxios
  .post('/user/logout', {}, getConfig())
  .then(res => Promise.resolve(res))
  .catch(err => Promise.reject(err))
