import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import {
  AutoCompleteWithLabel,
  Bar,
  Button,
  DatePickerWithLabel,
  NumericTextField,
  TextBoxOuterLabel
} from 'components/common'
import { formElements } from 'components/helper/constants/common'
import { formatCurrency } from 'components/helper/utility'
import { validationMapper } from 'components/helper/validationEngine'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMediaPlanSitesLookup, fetchTacticsLookup } from 'store/ducks/common'
import { onAddMediaPlan, setAddMediaPlan } from 'store/ducks/mediaPlans'

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
        button {
          margin-top: ${theme.spacing(1)};
          margin-right: ${theme.spacing(2)};
          height: fit-content;
        }
        display: flex;
        align-items: start;
        justify-content: flex-start;
    `
)

const MediaNameWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    '.MuiFormControl-root': {
      [theme.breakpoints.up('sm')]: {
        width: '40%'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  }
}))

const validationSchema = validationMapper([
  { id: 'planName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'sites', isResponseRequired: true, answerType: formElements.multiSelectDropdown },
  { id: 'tactics', isResponseRequired: true, answerType: formElements.multiSelectDropdown },
  { id: 'plannedStartDate', isResponseRequired: true, answerType: formElements.date },
  { id: 'plannedEndDate', isResponseRequired: true, answerType: formElements.date },
  { id: 'plannedInquiries', isResponseRequired: true, answerType: formElements.number },
  { id: 'plannedReferrals', isResponseRequired: true, answerType: formElements.number },
  { id: 'totalBudget', isResponseRequired: true, answerType: formElements.number },
  { id: 'netPlacementBudget', isResponseRequired: true, answerType: formElements.number },
  { id: 'bbkFees', isResponseRequired: true, answerType: formElements.number },
  { id: 'vendorFees', isResponseRequired: true, answerType: formElements.number }
])

const AddMediaPlan = () => {
  const dispatch = useDispatch()
  const { mediaPlan } = useSelector(store => store.mediaPlans)
  const { mediaPlanSitesLookup, tacticsLookup } = useSelector(store => store.common)

  const onNumericValueChange = (event, values, setFieldValue) => {
    // Keep adding funds together as user enters values in each category and then set it to the total column
    let totalBudget = 0
    const fieldName = event.target.name

    const budgetFields = ['netPlacementBudget', 'bbkFees', 'vendorFees']

    if (budgetFields.includes(fieldName)) {
      if (event.target.value) {
        for (const category of Object.keys(values)) {
          if (budgetFields.includes(category) && category !== fieldName && values[category]) {
            totalBudget += parseFloat(values[category])
          }
        }

        totalBudget += parseFloat(event.target.value)
      } else {
        // This means user is removing values from one of the fields
        if (values.totalBudget) {
          totalBudget = parseFloat(values.totalBudget)
          totalBudget -= parseFloat(values[fieldName])
        }
      }
    }

    // convert the number to have always two decimals
    const finalTotalFund = formatCurrency(totalBudget)

    setFieldValue('totalBudget', finalTotalFund)
    setFieldValue(fieldName, event.target.value)
  }
  return (
    <Formik
      initialValues={mediaPlan}
      enableReinitialize={false}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(onAddMediaPlan(values))
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form noValidate>
          <Grid container spacing={3}>
            <MediaNameWrapper item xs={12} sm={12} md={12} lg={12}>
              <TextBoxOuterLabel
                id="planName"
                label="Media Plan Name"
                name="planName"
                fullWidth={true}
                size="small"
                required
              />
            </MediaNameWrapper>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AutoCompleteWithLabel
                id="sites"
                label="Associated Sites"
                name="sites"
                options={mediaPlanSitesLookup.results}
                isLoading={mediaPlanSitesLookup.isLoading}
                fetchOptions={() => dispatch(fetchMediaPlanSitesLookup())}
                size="small"
                required
                multiple={true}
                hasSelectAllOption={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AutoCompleteWithLabel
                id="tactics"
                label="Tactics"
                name="tactics"
                options={tacticsLookup.results}
                isLoading={tacticsLookup.isLoading}
                fetchOptions={() => dispatch(fetchTacticsLookup())}
                size="small"
                required
                multiple={true}
                hasSelectAllOption={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <DatePickerWithLabel
                id="plannedStartDate"
                label="Planned Start Date"
                required={true}
                name="plannedStartDate"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <DatePickerWithLabel
                id="plannedEndDate"
                label="Planned End Date"
                required={true}
                name="plannedEndDate"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <NumericTextField
                inputProps={{ min: 0 }}
                id="plannedInquiries"
                label="Planned Inquiries"
                name="plannedInquiries"
                fullWidth={true}
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <NumericTextField
                inputProps={{ min: 0 }}
                id="plannedReferrals"
                label="Planned Referrals"
                name="plannedReferrals"
                fullWidth={true}
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Bar color="gray" fullWidth={true} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} />
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <NumericTextField
                id="totalBudget"
                label="Total Budget"
                name="totalBudget"
                fullWidth={true}
                size="small"
                startAdornment="$"
                disabled={true}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <NumericTextField
                id="netPlacementBudget"
                label="Planned Net Placement Budget"
                name="netPlacementBudget"
                fullWidth={true}
                size="small"
                startAdornment="$"
                inputProps={{ min: 0 }}
                onChange={event => onNumericValueChange(event, values, setFieldValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <NumericTextField
                id="bbkFees"
                label="BBK Fees"
                name="bbkFees"
                fullWidth={true}
                size="small"
                startAdornment="$"
                inputProps={{ min: 0 }}
                onChange={event => onNumericValueChange(event, values, setFieldValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <NumericTextField
                id="vendorFees"
                label="Vendor Fees"
                name="vendorFees"
                fullWidth={true}
                size="small"
                startAdornment="$"
                inputProps={{ min: 0 }}
                onChange={event => onNumericValueChange(event, values, setFieldValue)}
              />
            </Grid>
            <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
              <Button size="large" fullWidth={false} color="primary" type="submit">
                Save
              </Button>
              <Button
                size="large"
                fullWidth={false}
                color="inherit"
                onClick={() => {
                  resetForm()
                  dispatch(setAddMediaPlan(false))
                }}
              >
                Cancel
              </Button>
            </ActionWrapper>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default AddMediaPlan
