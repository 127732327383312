import React, { useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { AccordionGroup, AutoCompleteWithLabel, PageHeader, PdfViewer } from 'components/common'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import StudyLinks from './StudyLinks'
import AdministrativeDocuments from './AdministrativeDocuments'
import UserGuides from './UserGuides'
import ForStudySiteStaffDocuments from './ForStudySiteStaffDocuments'
import ForPatientsStudyTools from './ForPatientsStudyTools'
import { getResourceDocsByID } from 'services/common'
import { fetchSitesLookup } from 'store/ducks/common'
import { Restriction, useRestriction } from 'permissions'
import { resetResourceCenter, setPageNumber } from 'store/ducks/resourceCenter'
import { getUserGuidesDocsByID } from 'services/resourceCenter'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { useLocation } from 'react-router-dom'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: `${theme.spacing(5)}`,
    height: '87vh'
  }
}))

const SubWrapper = styled.div(() => ({
  '&&': {
    display: 'flex',
    justifyContent: 'spaceBetween',
    width: '100%'
  }
}))
const SideNavWrapper = styled.div(() => ({
  '&&': {
    width: '25%',
    minWidth: '255px'
  }
}))

const ContentWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    width: '75%',
    marginLeft: `${theme.spacing(2)}`,
    padding: `${theme.spacing(6, 2, 1, 2)}`,
    backgroundColor: `${theme.palette.gray.light}`,
    display: 'flex',
    justifyContent: 'center'
  }
}))
const SelectSiteWrapper = styled(Grid)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
`
)
const accordionsIndex = {
  administration: 0,
  userGuides: 4,
}
const ResourceCenter = () => {
  const dispatch = useDispatch()
  const { selectedDocument, pageNumber } = useSelector(store => store.resourceCenter)
  const { sitesLookup } = useSelector(store => store.common)
  const { siteClientId } = useSelector(store => store.application)
  const [selectedSite, setSelectedSite] = useState(null)
  const location = useLocation()
  const { state } = location
  const [accordionExpanded, setAccordionExpanded] = useState(null)
  const expandAccordion = state ? state.expandAccordion : 'administration'

  const onSiteChange = (selectedValues, reason) => {
    if (reason === 'clear') {
      setSelectedSite(null)
    } else {
      setSelectedSite(selectedValues)
    }
  }
  useEffect(
    () =>
      // As the same redux state is reused in patient study tools
      () => {
        dispatch(resetResourceCenter())
      },
    []
  )

  // when the logged in user is bbk/concierge, will have this permission and can select siteClientID from the dropdown
  // when no permission that means the user is site and siteClientID from the patientList response is used instead
  const finalSite = useRestriction('read', 'resourcesSelectSite')
    ? selectedSite?.alternateIdentifier
    : siteClientId

  return (
    <MainWrapper elevation={0}>
      <PageHeader
        title={<Typography variant="subtitle1">Resource Center</Typography>}
        bar={false}
      />
      <Restriction ability="read" feature="resourcesSelectSite">
        <SelectSiteWrapper container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <AutoCompleteWithLabel
              multiple={false}
              hasSelectAllOption={false}
              size="small"
              formik="false"
              id="site"
              name="site"
              label="Select Site"
              fetchOptions={() => dispatch(fetchSitesLookup())}
              onChange={(event, selectedValue, reason) => onSiteChange(selectedValue, reason)}
              options={sitesLookup.results}
              isLoading={sitesLookup.isLoading}
              selectedValue={selectedSite}
            />
          </Grid>
        </SelectSiteWrapper>
      </Restriction>

      {finalSite && (
        <SubWrapper>
          <SideNavWrapper>
            <AccordionGroup
              expandAll={false}
              defaultOpenPanel={accordionsIndex[expandAccordion]}
              transitionProps={{ unmountOnExit: true }}
              labelComponent={true}
              minBottomGutter={true}
              noContentPadding={true}
              contentBackground="gray"
              controlled={true}
              summaryStyles={{
                minHeight: '40px',
                '.MuiAccordionSummary-content': {
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  margin: '5px'
                }
              }}
              onAccordionExpand={accordionIndex => {
                setAccordionExpanded(accordionIndex)
              }}
              accordions={[
                {
                  label: <Typography variant="body2">Administration</Typography>,
                  component: <AdministrativeDocuments />
                },
                {
                  label: <Typography variant="body2">For Study Site Staff</Typography>,
                  component: <ForStudySiteStaffDocuments siteClientId={finalSite} />
                },
                {
                  label: <Typography variant="body2">For Patients</Typography>,
                  component: <ForPatientsStudyTools siteClientId={finalSite} />
                },
                {
                  label: <Typography variant="body2">Study Links</Typography>,
                  component: <StudyLinks />
                },

                {
                  label: <Typography variant="body2">User Guides</Typography>,
                  component: <UserGuides />
                }
              ]}
            />
          </SideNavWrapper>
          <ContentWrapper elevation={0}>
            {selectedDocument && (selectedDocument.fetchId || selectedDocument.resourceURL)
              ? (
                <PdfViewer
                  fetchingPayload={selectedDocument}
                  fetchDocument={
                    selectedDocument.category === resourceCenterCategory.userGuides
                      ? getUserGuidesDocsByID
                      : getResourceDocsByID
                  }
                  pageNumber={pageNumber}
                  setPageNumber={number => {
                    dispatch(setPageNumber(number))
                  }}
                />
              )
              : (
                <Typography variant="subtitle1">
                  {accordionExpanded === 3 ? 'Select a Study Portal from the list' : 'To see a document, select an item from the list.'}
                </Typography>
              )}
          </ContentWrapper>
        </SubWrapper>
      )}
    </MainWrapper>
  )
}

export default ResourceCenter
