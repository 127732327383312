import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { palette } from 'styles/baseTheme'
import { Button, AutoCompleteWithLabel, PageHeader, DataGrid, Checkbox, AccordionGroup } from 'components/common'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import {
  fetchExistingPermissions,
  fetchExistingPermissionsPolicy,
  fetchModules,
  fetchPolicies,
  resetPermissionsConfiguration,
  setSelectedModules,
  setSelectedPolicies,
  setUpdatedPermissions,
  updatePermissions
} from 'store/ducks/configuration/permissionsConfiguration'
import RenderHTML from 'components/common/dataDisplay/RenderHTML'
import { getPolicyPermissions } from 'store/ducks/application'

const StyledGrid = styled(Grid)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
  `
)
const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(2)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: left;
      justify-content: left;
      padding: ${theme.spacing(4)} 0;
  `
)

const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  textAlign: 'center',
  minWidth: '260px'
}
const bodyProperties = {
  textAlign: 'center'
}

const PermissionsConfiguration = () => {
  const dispatch = useDispatch()

  const {
    modules,
    policies,
    selectedModules,
    selectedPolicies,
    gridHeaders,
    gridRows,
    initialPermissionValues,
    initialValuesUpdateCounter,
    updatedPermissions
  } = useSelector(
    store => store.permissionsConfiguration
  )

  const updatedGridHeaders = {}
  Object.keys(gridHeaders).forEach(key => {
    updatedGridHeaders[key] = gridHeaders[key].map(header => {
      if (header.type === 'module') {
        return {
          ...header,
          headProperties: {
            ...headProperties,
            textAlign: 'left',
            width: '500px'
          },
          bodyProperties: {
            textAlign: 'left',
            cellType: 'node'
          }
        }
      } else {
        return {
          ...header,
          headProperties: {
            ...headProperties,
            minWidth: '80px',
            width: '100px'
          },
          bodyProperties: {
            ...bodyProperties,
            cellType: 'node'
          }
        }
      }
    })
  })

  useEffect(() => {
    dispatch(fetchModules())
    dispatch(fetchPolicies())
    return () => {
      dispatch(resetPermissionsConfiguration())
    }
  }, [])

  const fetchPermissions = async () => {
    if (selectedModules.length || selectedPolicies.length) {
      await dispatch(fetchExistingPermissions())
      if (selectedPolicies.length) {
        await dispatch(fetchExistingPermissionsPolicy())
      }
      // reset the updated values
      dispatch(setUpdatedPermissions({}))
    }
  }

  // When dropdown values change, fetch Existing Permisions
  useEffect(() => {
    fetchPermissions()
  }, [selectedModules, selectedPolicies])

  const handleModuleChange = (selectedValue, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValue.find(option => option.id === 'select-all')) {
        dispatch(setSelectedModules(modules.results))
      } else {
        dispatch(setSelectedModules(selectedValue))
      }
    } else if (reason === 'clear') {
      dispatch(setSelectedModules([]))
    }
  }

  const handlePoliciesChange = (selectedValue, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedValue.find(option => option.id === 'select-all')) {
        dispatch(setSelectedPolicies(policies))
      } else {
        dispatch(setSelectedPolicies(selectedValue))
      }
    } else if (reason === 'clear') {
      dispatch(setSelectedPolicies([]))
    }
  }

  const onCheckboxChange = (event, name, setFieldValue) => {
    setFieldValue(name, event.target.checked)
    const [luPermissionId, policyId] = name.split('-')

    const newUpdatedPermissions = { ...updatedPermissions }
    if (!newUpdatedPermissions[luPermissionId]) {
      newUpdatedPermissions[luPermissionId] = {}
    }
    // Make new copy of object
    newUpdatedPermissions[luPermissionId] = {
      ...newUpdatedPermissions[luPermissionId],
      [policyId]: event.target.checked
    }

    dispatch(setUpdatedPermissions(newUpdatedPermissions))
  }

  return (
    (modules.isLoading || policies.isLoading)
      ? <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box>
            <Skeleton height={64} />
            <Skeleton height={64} />
            <Skeleton height={64} />
          </Box>
        </Grid>
      </Grid>
      : <>
        <PageHeader title={<Typography variant="h4">Permissions</Typography>} />
        <StyledGrid container spacing={2}>
          <Grid item xs={12} sm={9} md={7} lg={5}>
            <AutoCompleteWithLabel
              multiple={true}
              hasSelectAllOption={true}
              size="small"
              id="selectModules"
              label="Select Module"
              name="selectModules"
              options={modules.results}
              formik="false"
              required
              selectedValue={selectedModules}
              limitTags={20}
              onChange={(event, selectedValue, reason) => handleModuleChange(selectedValue, reason)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={5} lg={7}></Grid>
          <Grid item xs={12} sm={9} md={7} lg={5}>
            <AutoCompleteWithLabel
              multiple={true}
              hasSelectAllOption={true}
              size="small"
              id="selectPolicies"
              label="Select Policies"
              name="selectPolicies"
              formik="false"
              selectedValue={selectedPolicies}
              options={policies.results}
              onChange={(event, selectedValue, reason) => handlePoliciesChange(selectedValue, reason)}
              required
              limitTags={20}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={5} lg={7}></Grid>
        </StyledGrid>
        {selectedModules.length > 0 && (
          <Formik
            initialValues={initialPermissionValues}
            enableReinitialize={false}
            onSubmit={async () => {
            // Capture updated values
              await dispatch(updatePermissions())
              await fetchPermissions()
              await dispatch(getPolicyPermissions())
            }}
            key={initialValuesUpdateCounter}
          >
            {({ setFieldValue, dirty }) => (
              <Form noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AccordionGroup
                      accordions={
                        selectedModules.map(selectedModule => ({
                          label: selectedModule.displayText,
                          component: <DataGrid
                            headers={ updatedGridHeaders && updatedGridHeaders[selectedModule.id] ? updatedGridHeaders[selectedModule.id] : []}
                            tableData= {
                              gridRows[selectedModule.id]
                                ? gridRows[selectedModule.id].map(row => {
                                  const newRow = {}
                                  Object.keys(row).forEach(elementId => {
                                    const [componentType, name, readOnly] = row[elementId].split(':')
                                    const readOnlyBoolean = !!parseInt(readOnly)
                                    if (componentType === 'html') {
                                      newRow[elementId] = <RenderHTML key={name} htmlContent={name} />
                                    } else if (componentType === 'checkbox') {
                                      newRow[elementId] = (
                                        <Checkbox
                                          key={name}
                                          name={name}
                                          id={name}
                                          onChange={e => onCheckboxChange(e, name, setFieldValue)}
                                          label=""
                                          size="medium"
                                          dataTestId={name}
                                          disabled={readOnlyBoolean}
                                        />
                                      )
                                    } else {
                                      newRow[elementId] = row[elementId]
                                    }
                                  })
                                  return newRow
                                })
                                : []

                            }
                            setRequestedSort={() => {}}
                            order={'DESC'}
                            orderBy={''}
                            alternateRowColored={true}
                            border="allSides"
                            radius={'false'}
                            key={selectedModule.id}
                          />
                        }))}
                      expandAll={true}
                    />
                  </Grid>
                  <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                    <Button size="large" fullWidth={false} color="primary" type="submit" disabled={!dirty}>
                    Save
                    </Button>
                  </ActionWrapper>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </>
  )
}

export default PermissionsConfiguration
