import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { copyObject, removeTime } from 'components/helper/utility'

import { getAdvancedSupportTypes } from 'services/common'
import { addAdvancedSupportRequest, getAdvancedSupportDetails } from 'services/rsgArrive'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import { setOpenSubmitSuccess, setStartNewRequestForVisitFlow } from './requestDetails'

export const setAdvancedSupportTypes = createAction('advancedRequest/setAdvancedSupportTypes')
export const setAdvancedSupportRequestForm = createAction(
  'advancedRequest/setAdvancedSupportRequestForm'
)
export const resetAdvancedSupport = createAction('advancedRequest/resetAdvancedSupport')

export const fetchAdvancedSupportTypes = () => async (dispatch, getState) => {
  try {
    const { advancedSupportTypes } = getState().advancedSupportRequest
    if (advancedSupportTypes.results.length === 0) {
      dispatch(setAdvancedSupportTypes({ isLoading: true }))
      const res = await getAdvancedSupportTypes()
      dispatch(setAdvancedSupportTypes({ results: res, isLoading: false }))
    }
  } catch (e) {
    dispatch(setAdvancedSupportTypes({ isLoading: false }))
    dispatch(showError('Something went wrong!'))
  }
}

export const setupAdvancedSupportRequest = () => async (dispatch, getState) => {
  try {
    await dispatch(fetchAdvancedSupportTypes())
    const { advancedSupportTypes, advancedSupportRequestForm } = getState().advancedSupportRequest

    // To setup initial state, loop through the advancedSupportTypes and push details'/note's key
    // for each type of support service
    const serviceTypeDetails = {}
    advancedSupportTypes.results.length &&
      advancedSupportTypes.results.forEach(supportType => {
        serviceTypeDetails[`serviceTypeDetails-${supportType.id}`] = ''
      })

    dispatch(
      setAdvancedSupportRequestForm({ ...advancedSupportRequestForm, ...serviceTypeDetails })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
}

export const fetchAdvancedSupportDetails = requestId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    await dispatch(setupAdvancedSupportRequest())
    const res = await getAdvancedSupportDetails(requestId)
    const { advancedSupportRequestForm } = getState().advancedSupportRequest
    let formattedAdvancedSupportDetails = { ...advancedSupportRequestForm }

    formattedAdvancedSupportDetails = {
      ...formattedAdvancedSupportDetails,
      ...res,
      visitNames: res.visitName,
      visitDate: res.visitDate,
      supportServices: res.selectedServices.map(service => ({
        id: `${service.supportTypeId}`,
        displayText: service.supportTypeName
      })),
      reachDateTime: res.bestTimeToReach || '',
      reachPhoneNumber: res.bestPhoneNumber || ''
    }
    res.selectedServices.length &&
      res.selectedServices.forEach(service => {
        formattedAdvancedSupportDetails[`serviceTypeDetails-${service.supportTypeId}`] =
          service.note
      })

    dispatch(setAdvancedSupportRequestForm(formattedAdvancedSupportDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

export const submitAdvancedSupportRequest =
  (data, parentRequestId) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { patientInformation } = getState().patientMainWrapper
      const advanceSupportTypes = []

      Object.keys(data).forEach(key => {
        if (data[key] && key.includes('serviceTypeDetails')) {
          const supportTypeId = parseInt(key.split('-')[1])
          advanceSupportTypes.push({
            advancedSupportType: supportTypeId,
            note: data[key]
          })
        }
      })

      const requestData = {
        patientId: patientInformation.userId,
        visitNameId: data.visitNames?.id,
        visitDate: removeTime(data.visitDate),
        advanceSupportTypes,
        bestTimeToReach: data.reachDateTime,
        bestPhoneNumber: data.reachPhoneNumber,
        ...(!!parentRequestId && {
          parentRequestId
        })
      }

      await addAdvancedSupportRequest(requestData)
      await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
      dispatch(setOpenSubmitSuccess(true))
      // Save data for StartNewRequestForVisitFlow,
      // in case user selects Yes in SubmitSuccess Confirmation box
      // This will be cleared if user selects No.
      dispatch(
        setStartNewRequestForVisitFlow({
          visitDate: removeTime(data.visitDate),
          visitNames: data.visitNames
        })
      )
      dispatch(showSuccess('Request submitted successfully!'))
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    }
    dispatch(setLoading(false))
  }

const initialState = {
  advancedSupportTypes: { isLoading: false, results: [] },
  advancedSupportRequestForm: {
    visitNames: null,
    visitDate: '',
    supportServices: [],
    reachDateTime: '',
    reachPhoneNumber: ''
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setAdvancedSupportTypes, (state, action) => {
      state.advancedSupportTypes = { ...state.advancedSupportTypes, ...action.payload }
    })
    .addCase(setAdvancedSupportRequestForm, (state, action) => {
      state.advancedSupportRequestForm = action.payload
    })
    .addCase(resetAdvancedSupport, state => {
      copyObject(state, initialState)
    })
})
