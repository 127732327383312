import React from 'react'
import styled from '@emotion/styled'
import { Outlet } from 'react-router-dom'

const AuthWrapper = styled.div(() => ({
  '&&': {
    height: '100%'
  }
}))

const DocumentLayout = () => (
  <AuthWrapper maxWidth="md">
    <Outlet />
  </AuthWrapper>
)

export default DocumentLayout
