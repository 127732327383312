import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { Button } from 'components/common'

import { onDeleteConciergeInsight } from 'store/ducks/patientRecord/patientProfile'

const DeleteInsightConfirmation = () => {
  const dispatch = useDispatch()
  const { deleteInsightConfirmation } = useSelector(store => store.patientProfile)

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="body1">
          <b>Are you sure you want to delete this note?</b>
        </Typography>
        <br />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button
          size="small"
          fullWidth={false}
          color="primary"
          onClick={() =>
            dispatch(onDeleteConciergeInsight(deleteInsightConfirmation.deleteInsightId))
          }
        >
          Yes
        </Button>
      </Grid>
    </Grid>
  )
}

export default DeleteInsightConfirmation
