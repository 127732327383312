import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { FormControlLabel, Checkbox, FormHelperText, FormControl } from '@mui/material'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import styled from '@emotion/styled'

const FormControlWrapper = styled(FormControl)`
  label {
    height: 80%;
    align-items: flex-end;
    display: flex;
    margin-left: 0px;

    .MuiCheckbox-root {
      padding: 0px;
      padding-right: ${({ theme }) => theme.spacing(1)};
      align-self: baseline;
    }
  }
`
const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    align-self: center;
  }
`

const CustomCheckbox = props => {
  const {
    id,
    label,
    formik,
    onChange,
    name,
    checked,
    checkedIcon,
    color,
    disabled,
    disableRipple,
    icon,
    indeterminate,
    indeterminateIcon,
    inputProps,
    inputRef,
    required,
    size,
    value,
    onBlur,
    helperText,
    error,
    disableTypographyForLabel,
    labelProps,
    fullWidth,
    dataTestId
  } = props

  let field, meta, setValue

  const onFieldValueChange = event => {
    setValue(event.target.checked)
  }

  if (formik === 'true') {
    [field, meta, { setValue }] = useField(props)
    field = { ...field, onChange: onChange || onFieldValueChange }
  } else {
    field = {
      onChange,
      onBlur,
      value,
    }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <FormControlWrapper fullWidth={fullWidth} required={required}>
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={checked || field.value}
            name={name}
            id={id}
            color={color}
            checkedIcon={checkedIcon}
            disabled={disabled}
            disableRipple={disableRipple}
            icon={icon}
            indeterminate={indeterminate}
            indeterminateIcon={indeterminateIcon}
            inputProps={inputProps}
            inputRef={inputRef}
            required={required}
            size={size}
            data-testid={dataTestId}
            {...field}
          />
        }
        label={required && !disableTypographyForLabel ? `${label} *` : label}
        componentsProps={{ typography: { color: isError ? 'error' : '', ...labelProps } }}
        disableTypography={disableTypographyForLabel}
      />
      <FormHelperText component="div" error={isError}>
        {errorText}
      </FormHelperText>
    </FormControlWrapper>
  )
}

CustomCheckbox.propTypes = {
  /**
   * The label content. Note, this is of type node that means you must pass this as React element ex: Typography
   * The reason this is defined as node is that the label can be a combination of text and some icon.
   */
  label: PropTypes.node.isRequired,
  /**
   * Props applied to label's Typography
   */
  labelProps: PropTypes.object,
  /**
   * Use this when you want to pass your custom label.
   * when true, whatever is passed for label props, will be shown as is. Meaning, without a Typography/p-tag. Also, there will not be any indication, that this field is required.
   * Whereas in case of `false` and if this component is required, now when user tries to submit the form without selecting this checkbox, the label will turn to red.
   * @default false
   */
  disableTypographyForLabel: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * If `true`, the component will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * Implement onBlur using this props.
   */
  onBlur: PropTypes.func,

  /**
   * If `true`, the component is checked.
   */
  checked: PropTypes.bool,
  /**
   * The icon to display when the component is checked.
   * @default <CheckBoxIcon />
   */
  checkedIcon: PropTypes.node,

  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning', 'default']),

  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the ripple effect is disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * The icon to display when the component is unchecked.
   * @default <CheckBoxOutlineBlankIcon />
   */
  icon: PropTypes.node,
  /**
   * The id of the `input` element.
   */
  id: PropTypes.string,
  /**
   * If `true`, the component appears indeterminate.
   * This does not set the native input element to indeterminate due
   * to inconsistent behavior across browsers.
   * However, we set a `data-indeterminate` attribute on the `input`.
   * @default false
   */
  indeterminate: PropTypes.bool,
  /**
   * The icon to display when the component is indeterminate.
   * @default <IndeterminateCheckBoxIcon />
   */
  indeterminateIcon: PropTypes.node,
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps: PropTypes.object,
  /**
   * Pass a ref to the `input` element.
   */
  inputRef: PropTypes.string,
  /**
   * onChange is an optional props, can be used to perform other tasks beside just value change of this particular element
   * Callback fired when the state is changed.
   * @param {React.ChangeEvent<HTMLInputElement>} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the `component` is required.
   */
  required: PropTypes.bool,
  /**
   * The size of the component.
   * `small` is equivalent to the dense checkbox styling.
   * @default 'medium'
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * The value of the component. The DOM API casts this to a string.
   * The browser uses "on" as the default value.
   */
  value: PropTypes.any,
  /**
   * Pass data-testid to the control
   * data-testid is an attribute used to identify a DOM node for testing purposes
   */
  dataTestId: PropTypes.string
}

CustomCheckbox.defaultProps = {
  formik: 'true',
  color: 'primary',
  size: 'medium',
  indeterminateIcon: <IndeterminateCheckBoxIcon />,
  checkedIcon: <CheckBoxIcon />,
  icon: <CheckBoxOutlineBlankIcon />,
  error: false,
  fullWidth: false,
  required: false,
  disableTypographyForLabel: false,
}

export default CustomCheckbox
