import styled from '@emotion/styled'
import { FormControlLabel } from '@mui/material'
import { Card } from 'components/common'
import PropTypes from 'prop-types'
import TicketedTravel from './TicketedTravel'
import HotelAccommodations from './HotelAccommodations'
import GroundTransportation from './GroundTransportation'

const servicesComponents = {
  0: <TicketedTravel />,
  1: <HotelAccommodations />,
  2: <GroundTransportation />
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&&': {
    margin: theme.spacing(2),
    width: 'auto',
    '.MuiCheckbox-root': {
      color: theme.palette.primary.contrastText
    },
    '.MuiTypography-body1': {
      color: theme.palette.primary.contrastText
    }
  }
}))

const SelectServiceOption = props => (
  <Card
    radius={false}
    title={
      <StyledFormControlLabel
        componentsProps={{ typography: { variant: 'subtitle1' } }}
        {...props}
      />
    }
    disableTypographyForTitle={true}
    headerColor="primary"
    cardHeaderStyles={{ padding: '0px' }}
    cardStyles={{
      flex: '1 0 355px',
      minWidth: '355px',
      margin: '5px',
      boxSizing: 'border-box',
      height: 'fit-content'
    }}
  >
    {props.checked && servicesComponents[props.id]}
  </Card>
)

SelectServiceOption.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string
}
export default SelectServiceOption
