import { backendAxios, getConfig } from './backend'

export const getMediaPlans = () =>
  backendAxios
    .get('/mediaplan/getlist', getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const postMediaPlan = payload =>
  backendAxios
    .post('/mediaplan', payload, getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const getMediaPlanDetails = mediaPlanId =>
  backendAxios
    .get('/mediaplan/get', getConfig({ id: mediaPlanId }))
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))

export const postMediaPlanUpdate = payload =>
  backendAxios
    .post('/mediaplan/update', payload, getConfig())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err))
