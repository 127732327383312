import { configureStore } from '@reduxjs/toolkit'
import auth from 'store/ducks/auth'
import application from 'store/ducks/application'
import patientExperienceManager from 'store/ducks/patientExperienceManager'
import userInteractions from 'store/ducks/userInteractions'
import userMessageBar from 'store/ducks/userMessageBar'
import patientProfile from 'store/ducks/patientRecord/patientProfile'
import announcements from 'store/ducks/communicationCenter/announcements'
import studyMessages from 'store/ducks/communicationCenter/studyMessages'
import participationProgress from './ducks/patientRecord/participationProgress'
import instanceConfiguration from 'store/ducks/configuration/instanceConfiguration'
import protocolCampaignConfiguration from 'store/ducks/configuration/protocolCampaignConfiguration'
import resourceCenterConfiguration from 'store/ducks/configuration/resourceCenterConfiguration'
import arriveConfiguration from 'store/ducks/configuration/arriveConfiguration'
import participationProgressConfiguration from 'store/ducks/configuration/participationProgressConfiguration'
import sitePreferencesConfiguration from 'store/ducks/configuration/sitePreferencesConfiguration'
import permissionsConfiguration from 'store/ducks/configuration/permissionsConfiguration'
import patientMainWrapper from 'store/ducks/patientRecord/patientMainWrapper'
import advancedSupportRequest from 'store/ducks/visitServices/advancedSupportRequest'
import auxillarySupportRequest from 'store/ducks/visitServices/auxillarySupportRequest'
import otherServiceRequest from 'store/ducks/visitServices/otherServiceRequest'
import inPersonRequest from 'store/ducks/visitServices/inPersonRequest'
import telehealthVisit from 'store/ducks/visitServices/telehealthVisit'
import homeHealthVisit from 'store/ducks/visitServices/homeHealthVisit'
import deliveryServices from 'store/ducks/visitServices/deliveryServices'
import siteManagement from 'store/ducks/siteManagement/siteManagement'
import userActivity from 'store/ducks/siteManagement/userActivity'
import requestDetails from 'store/ducks/visitServices/requestDetails'
import common from 'store/ducks/common'
import resourceCenter from './ducks/resourceCenter'
import reimbursement from './ducks/patientRecord/reimbursement'
import manageAccount from './ducks/patientRecord/manageAccount'
import smartAlerts from './ducks/smartAlerts'
import myAccount from './ducks/myAccount'
import cardConfiguration from './ducks/configuration/cardConfiguration'
import visitDetailsConfiguration from './ducks/configuration/visitDetailsConfiguration'
import mediaReferralsConfiguration from './ducks/configuration/mediaReferralsConfiguration'
import mediaPlans from './ducks/mediaPlans'
import crossInstanceReports from './ducks/crossInstanceReports'

export const storeConfigObject = {
  reducer: {
    auth,
    application,
    patientExperienceManager,
    userInteractions,
    userMessageBar,
    patientProfile,
    announcements,
    studyMessages,
    participationProgress,
    patientMainWrapper,
    instanceConfiguration,
    protocolCampaignConfiguration,
    resourceCenterConfiguration,
    participationProgressConfiguration,
    arriveConfiguration,
    sitePreferencesConfiguration,
    permissionsConfiguration,
    advancedSupportRequest,
    auxillarySupportRequest,
    otherServiceRequest,
    inPersonRequest,
    telehealthVisit,
    homeHealthVisit,
    deliveryServices,
    siteManagement,
    userActivity,
    requestDetails,
    common,
    resourceCenter,
    reimbursement,
    manageAccount,
    myAccount,
    smartAlerts,
    cardConfiguration,
    visitDetailsConfiguration,
    mediaReferralsConfiguration,
    mediaPlans,
    crossInstanceReports,
  },
  devtools: window.location.port
}

const store = configureStore(storeConfigObject)

export default store
