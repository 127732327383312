import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Typography, Grid, Box, Skeleton } from '@mui/material'
import { Form, Formik } from 'formik'
import { Button, PageHeader } from 'components/common'
import { AutoCompleteWithLabel } from 'components/common/form'
import {
  fetchMediaReferralsConfiguration,
  resetMediaReferralsConfiguration,
  setEditMediaReferralConfig,
  submitMediaReferralsConfiguration
} from 'store/ducks/configuration/mediaReferralsConfiguration'
import { Edit } from '@mui/icons-material'
import { fetchCountriesLookup } from 'store/ducks/common'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const ActionWrapper = styled.div(
  ({ theme }) => `
      display: flex;
      margin-top: 0px;  
      button{
        margin-right: ${theme.spacing(1)};
       
      }
     `
)

const validationSchema = validationMapper([
  {
    id: 'participatingCountries',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown
  }
])

const MediaReferrals = () => {
  const dispatch = useDispatch()
  const { mediaReferralsConfiguration, editMediaReferralConfig } = useSelector(
    store => store.mediaReferralsConfiguration
  )
  const { countriesLookup } = useSelector(store => store.common)

  useEffect(() => {
    dispatch(fetchCountriesLookup())
    dispatch(fetchMediaReferralsConfiguration())
    return () => {
      dispatch(resetMediaReferralsConfiguration())
    }
  }, [])

  return (
    <PageHeader
      title={<Typography variant="h4">Media / Referrals Configuration</Typography>}
      actionContent={
        <Button
          startIcon={<Edit />}
          size="large"
          fullWidth={false}
          color="primary"
          onClick={() => {
            dispatch(setEditMediaReferralConfig(true))
          }}
        >
          Edit
        </Button>
      }
      expandContent
      content={
        <>
          {countriesLookup.isLoading || mediaReferralsConfiguration.isLoading
            ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <Skeleton height={64} />
                  </Box>
                </Grid>
              </Grid>
            )
            : (
              <Formik
                initialValues={{ ...mediaReferralsConfiguration.results }}
                enableReinitialize={true}
                onSubmit={values => {
                  dispatch(submitMediaReferralsConfiguration(values))
                }}
                validationSchema={validationSchema}
              >
                {({ dirty, resetForm }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AutoCompleteWithLabel
                          multiple={true}
                          hasSelectAllOption={true}
                          size="small"
                          id="participatingCountries"
                          name="participatingCountries"
                          dataTestId="participating-countries"
                          label="Participating Countries"
                          options={countriesLookup.results}
                          isLoading={countriesLookup.isLoading}
                          limitTags={10}
                          disabled={!editMediaReferralConfig}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <ActionWrapper>
                          <Button
                            size="large"
                            fullWidth={false}
                            color="primary"
                            type="submit"
                            disabled={editMediaReferralConfig ? !dirty : true}
                          >
                          Save
                          </Button>
                          <Button
                            size="large"
                            fullWidth={false}
                            color="inherit"
                            disabled={!editMediaReferralConfig}
                            onClick={() => {
                              resetForm()
                              dispatch(setEditMediaReferralConfig(false))
                            }}
                          >
                          Cancel
                          </Button>
                        </ActionWrapper>
                      </Grid>
                      <Grid item xs={0} sm={0} md={6} lg={8} />
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
        </>
      }
    />
  )
}

export default MediaReferrals
