import styled from '@emotion/styled'
import { FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import InterpreterSupport from './InterpreterSupport'
import Accommodations from './Accommodations'
import Companion from './Companion'
import { Card } from 'components/common'
import { Children, cloneElement } from 'react'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&&': {
    margin: theme.spacing(2),
    width: 'auto',
    '.MuiCheckbox-root': {
      color: theme.palette.primary.contrastText
    },
    '.MuiTypography-body1': {
      color: theme.palette.primary.contrastText
    }
  }
}))

const auxillarySupportTypesComponents = {
  1: <InterpreterSupport />,
  2: <Accommodations />,
  3: <Companion />
}

const SelectServiceOption = props => (
  <Card
    radius={false}
    title={
      <StyledFormControlLabel
        componentsProps={{ typography: { variant: 'subtitle1' } }}
        {...props}
      />
    }
    disableTypographyForTitle={true}
    headerColor="primary"
    cardHeaderStyles={{ padding: '0px' }}
    cardStyles={{
      flex: '1 0 355px',
      minWidth: '355px',
      margin: '5px',
      boxSizing: 'border-box',
      height: 'fit-content'
    }}
  >
    {props.checked && cloneElement(Children.only(auxillarySupportTypesComponents[props.id]))}
  </Card>
)

SelectServiceOption.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.object
}

export default SelectServiceOption
