import React from 'react'
import { AppBar as MuiAppBar } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const StyledToolbar = styled(Toolbar)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const LogoWrapper = styled.div(
  ({ nav }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding: 5px;
    margin-left: ${nav === 'true' ? '150px' : '0px'};
`,
)

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 150,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const AppBar = ({ logo, action, withSideNav, isSidebarOpen }) => (
  <StyledAppBar color="inherit" position="absolute" open={isSidebarOpen}>
    <StyledToolbar>
      <div />
      <LogoWrapper nav={`${withSideNav}`}>{logo}</LogoWrapper>
      <div>{action}</div>
    </StyledToolbar>
  </StyledAppBar>
)

AppBar.propTypes = {
  /**
   * When passed a node element, it will be shown in the center of the App Bar
   */
  logo: PropTypes.node,
  /**
   * When passed a node element, it will be shown in the right most corner of the App Bar.
   */
  action: PropTypes.node,
  /**
   * indicates if the sideNav exists
   */
  withSideNav: PropTypes.bool,
  /**
   * indicates if the sideNav is open
   */
  isSidebarOpen: PropTypes.bool
}

export default AppBar
