import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import {
  AutoCompleteWithLabel,
  Button,
  CheckboxGroup,
  DatePickerWithLabel,
  TextBoxOuterLabel
} from 'components/common'
import {
  fetchAdvancedSupportDetails,
  resetAdvancedSupport,
  setupAdvancedSupportRequest,
  submitAdvancedSupportRequest
} from 'store/ducks/visitServices/advancedSupportRequest'
import AccordionGroup from 'components/common/dataDisplay/AccordionGroup'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import styled from '@emotion/styled'
import { showError } from 'store/ducks/application'
import SelectServiceOption from './SelectServiceOption'
import { Error } from '@mui/icons-material'
import {
  fetchVisitNames,
  fetchVisitScheduleGroup,
  setVisitNames
} from 'store/ducks/visitServices/requestDetails'

const LabelForVisitNames = styled.div(
  ({ theme }) => `
      display: flex;  
      svg{
        top: -1px;
        width: 1.2rem;
        height: 1.2rem;
        right: -17px;
      }
      p {
        margin-right: ${theme.spacing(1 / 2)};
      }`
)

const validationSchema = advancedSupportTypes => {
  const finalValidationArray = [
    { id: 'visitNames', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'visitDate',
      isResponseRequired: true,
      answerType: formElements.date,
      condition: {
        min: new Date(),
        minError: 'Visit start date must be in the future'
      },
      error: 'Visit start date is required and must be a valid date'
    },
    {
      id: 'supportServices',
      isResponseRequired: true,
      answerType: formElements.checkboxGroup,
      errorMessage: 'Atleast 1 Service needs to be selected'
    }
  ]
  advancedSupportTypes.forEach(supportType => {
    finalValidationArray.push({
      id: `serviceTypeDetails-${supportType.id}`,
      isResponseRequired: true,
      answerType: formElements.openText,
      dependency: {
        elements: ['supportServices'],
        callBack: supportServices =>
          supportServices?.length &&
          supportServices.map(service => service.id).includes(`${supportType.id}`)
      }
    })
  })
  return validationMapper(finalValidationArray)
}

const TextContainer = styled.div`
  padding: 2% 10%;
  width: 100%;
  text-align: center;
`

const StyledLabel = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
`

const AdvancedSupportForm = () => {
  const { requestTypeId, requestId, copyRequestId } = useParams()
  const dispatch = useDispatch()
  const { visitDetails, visitNames, visitScheduleGroup, startNewRequestForVisitFlow } = useSelector(
    store => store.requestDetails
  )
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { advancedSupportRequestForm, advancedSupportTypes } = useSelector(
    store => store.advancedSupportRequest
  )

  const setupAdvanceSupportForm = async () => {
    if (patientInformation.userId) {
      await dispatch(fetchVisitScheduleGroup(patientInformation.userId))
      if (!requestTypeId && !requestId && !copyRequestId) {
        dispatch(setupAdvancedSupportRequest())
      }
      if (requestTypeId && requestId) {
        dispatch(fetchAdvancedSupportDetails(requestId))
      }
      // Copy Existing request View
      if (copyRequestId) {
        dispatch(fetchAdvancedSupportDetails(copyRequestId))
      }
    }
  }

  useEffect(() => {
    setupAdvanceSupportForm()
    return () => {
      dispatch(resetAdvancedSupport())
      dispatch(
        setVisitNames({
          isLoading: false,
          results: []
        })
      )
    }
  }, [patientInformation.userId])

  const readOnly = !!visitDetails.results && !!requestId

  const services = advancedSupportTypes.results.map(supportType => ({
    id: `${supportType.id}`,
    displayText: supportType.displayText,
    control: <SelectServiceOption />
  }))

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {visitScheduleGroup.isLoading ||
        visitDetails.isLoading ||
        advancedSupportTypes.isLoading
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          )
          : (
            <Formik
              enableReinitialize={true}
              initialValues={{
                ...advancedSupportRequestForm,
                ...(startNewRequestForVisitFlow &&
                !readOnly && {
                  ...startNewRequestForVisitFlow
                })
              }}
              validationSchema={validationSchema(advancedSupportTypes.results)}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(submitAdvancedSupportRequest(values, copyRequestId))
                setSubmitting(false)
              }}
            >
              {({ handleSubmit, isValid }) => (
                <Form
                  onSubmit={e => {
                    handleSubmit(e)
                    if (!isValid) {
                      dispatch(showError('Please add required information and try again'))
                    }
                    e.preventDefault()
                  }}
                >
                  <>
                    <AccordionGroup
                      accordions={[
                        {
                          label: 'Visit Details',
                          component: (
                            <Grid container spacing={2}>
                              {visitScheduleGroup.results.length > 1 && (
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                  <AutoCompleteWithLabel
                                    id="visitScheduleGroup"
                                    label="Select a Visit Schedule"
                                    name="visitScheduleGroup"
                                    onChange={(event, selectedValue, reason) => {
                                      if (reason === 'selectOption') {
                                        dispatch(
                                          fetchVisitNames(patientInformation.userId, selectedValue.id)
                                        )
                                      } else if (reason === 'clear') {
                                        dispatch(
                                          setVisitNames({
                                            isLoading: false,
                                            results: []
                                          })
                                        )
                                      }
                                    }}
                                    options={visitScheduleGroup.results}
                                    isLoading={visitScheduleGroup.isLoading}
                                    size="small"
                                    disabled={readOnly}
                                    multiple={false}
                                    hasSelectAllOption={false}
                                  />
                                </Grid>
                              )}
                              <Grid item xs={12} sm={12} md={5} lg={5}>
                                <AutoCompleteWithLabel
                                  multiple={false}
                                  hasSelectAllOption={false}
                                  size="small"
                                  id="visitNames"
                                  name="visitNames"
                                  label={
                                    <LabelForVisitNames>
                                      <Typography variant="body2">Study Visit</Typography>
                                      <Tooltip
                                        color="primary"
                                        size="small"
                                        title={
                                          <Typography variant="body1">
                                          If your request will apply to more than one study visit,
                                          please select the first applicable study visit.
                                          </Typography>
                                        }
                                      >
                                        <Error size="small" />
                                      </Tooltip>
                                    </LabelForVisitNames>
                                  }
                                  isLoading={visitNames.isLoading}
                                  options={visitNames.results}
                                  disabled={readOnly}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <DatePickerWithLabel
                                  id="visitDate"
                                  label="Visit Date"
                                  name="visitDate"
                                  minDate={new Date()}
                                  size="small"
                                  disabled={readOnly}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      ]}
                      gradient={true}
                      color="gradientBlue"
                      transitionProps={{ unmountOnExit: true }}
                      contentStyles={{ padding: '32px 16px' }}
                    />
                    <AccordionGroup
                      accordions={[
                        {
                          label: 'Advanced Support Services',
                          component: (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CheckboxGroup
                                  id="supportServices"
                                  name="supportServices"
                                  label={
                                    <TextContainer>
                                      <Typography variant="body2">
                                      Please check the box for each needed service below, and
                                      provide all necessary information.
                                      </Typography>
                                    </TextContainer>
                                  }
                                  options={services}
                                  fullWidth={true}
                                  optionsAlignInRow={true}
                                  disableTypographyForLabel={true}
                                  disabled={readOnly}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      ]}
                      gradient={true}
                      color="gradientBlue"
                      transitionProps={{ unmountOnExit: true }}
                      contentStyles={{ padding: '32px 16px' }}
                    />
                    <AccordionGroup
                      accordions={[
                        {
                          label: 'In Case We Need To Speak with You',
                          component: (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextBoxOuterLabel
                                  id="reachDateTime"
                                  label="What is the best time of the day to reach you?"
                                  name="reachDateTime"
                                  type="time"
                                  size="small"
                                  disabled={readOnly}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <TextBoxOuterLabel
                                  id="reachPhoneNumber"
                                  label={
                                    <StyledLabel variant="body2">
                                    Best phone number to reach you <i>(include country code)</i>
                                    </StyledLabel>
                                  }
                                  name="reachPhoneNumber"
                                  size="small"
                                  type="number"
                                  disabled={readOnly}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      ]}
                      gradient={true}
                      color="gradientBlue"
                      transitionProps={{ unmountOnExit: true }}
                      contentStyles={{ padding: '32px 16px' }}
                    />
                  </>
                  {!readOnly && (
                    <div style={{ textAlign: 'center', paddingTop: '2%' }}>
                      <Button size="medium" fullWidth={false} color="primary" type="submit">
                      Submit Request
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          )}
      </Grid>
    </Grid>
  )
}

export default AdvancedSupportForm
