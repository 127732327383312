import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Box, IconButton, Skeleton, Typography } from '@mui/material'
import { palette } from 'styles/baseTheme'
import CardFlip from 'assets/images/reimbursement/Card-Flip-Arrow-Icon.png'
import { CreditCard } from '@mui/icons-material'
import { Card } from 'components/common'
import Background from 'assets/images/reimbursement/Card-Gradient-Logo-BG.png'
import { cardStatus } from 'components/helper/constants/reimbursement'
import { useDispatch, useSelector } from 'react-redux'
import { getCardDataToken } from 'services/patientRecord/manageAccount'
import { getClientIp, getPQCardData } from 'services/patientRecord/payQuicker'
import { showError } from 'store/ducks/application'

const Wrapper = styled.div(() => ({
  backgroundImage: `url(${Background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  verticalAlign: 'middle',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

const commonHeight = '320px'

const CardWrapper = styled.span(() => ({
  '&&': {
    height: commonHeight,
    width: '500px'
  }
}))

const CardContentWrapper = styled.div(() => ({
  '&&': {
    height: commonHeight,
    position: 'absolute',
    width: '100%'
  }
}))

const FrontWrapper = styled.div`
  padding: 8px 15px;
  width: 100%;
  height: 100%;
`

const Header = styled.div`
  width: 100%;
  text-align: end;
`
const ExpiryDate = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.customBlue.main};
    position: relative;
    top: -35px;
    right: -22px;
`
)

const MagneticStrip = styled.div(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.primary.main,
    height: '40px',
    width: '100%',
    marginTop: '30px'
  }
}))

const CVVBox = styled.div(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.gray.light,
    height: '40px',
    width: '90%',
    marginTop: '30px',
    marginBottom: '30px',
    marginLeft: '17px',
    padding: '5px 15px',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'end',
    alignSelf: 'center'
  }
}))

const CardBalanceWrapper = styled.div`
  && {
    ${() => `
        display: flex;
        align-items: center;
        width: 100%;
        z-index: 9;
        position: relative;
        left: 24px;
        svg{
          margin-top: 5px;
          align-self: flex-start;
        },
        p {
          width: 4rem;
          margin-left: 10px;
        },
        h2 {
          font-size: 2.5rem;
          margin-left: 20px;
          color: ${palette.customBlue.main};
        },
        h2:last-child {
          font-size: 1.5rem;
          margin-left: 15px;
          align-self: flex-end;
          margin-bottom: 5px;
        },
      `}
  }
`

const NoCardBalanceWrapper = styled.div(() => ({
  '&&': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: '9',
    position: 'relative',
    left: '24px',
    'p:first-of-type': {
      width: '4rem',
      marginLeft: '10px'
    },
    'p:nth-of-type(2)': {
      fontStyle: 'italic'
    }
  }
}))
const StyledFlipButton = styled(IconButton)`
  position: relative;
  top: -9rem;
  right: -28.5rem;
`

const StyledVirtualCard = styled.img`
  width: 380px;
  position: relative;
  left: 42px;
`

const SkeletonWrapper = styled(Box)`
  width: 500px;
  height: 360px;
`

const CardView = ({ cardDetails }) => {
  const dispatch = useDispatch()
  const [showBackDetails, setShowBackDetails] = useState(false)
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)
  const [pqVirtualCard, setPQVirtualCard] = useState({ result: null, isLoading: false })
  const [cardSide, setCardSide] = useState('')

  const flipCard = () => {
    setShowBackDetails(state => !state)
  }

  const fetchVirtualCard = async (reimbursementCardId, side = 'FRONT') => {
    try {
      setPQVirtualCard({ result: null, isLoading: true })
      const origin = process.env.REACT_APP_APPLICATION_ORIGIN
      const { ip } = await getClientIp()
      if (ip) {
        const { data } = await getCardDataToken(reimbursementCardId, 'IMAGE', side, origin, ip)
        const { accessToken, token, userToken, cardToken } = data
        const cardDataResponse = await getPQCardData(
          accessToken,
          token,
          userToken,
          cardToken,
          origin,
          'IMAGE',
          side
        )

        setCardSide(side)
        setPQVirtualCard({ result: cardDataResponse.cardImage, isLoading: false })
      }
    } catch (e) {
      setPQVirtualCard({ result: null, isLoading: false })
      dispatch(showError('Error when trying to fetch view card', e))
    }
  }

  useEffect(() => {
    if (
      instanceConfig.results.CardVendor &&
      instanceConfig.results.CardVendor.toLowerCase() === process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase() &&
      cardDetails.reimbursementCardId
    ) {
      fetchVirtualCard(cardDetails.reimbursementCardId)
    }
  }, [instanceConfig.results.CardVendor, cardDetails.reimbursementCardId])

  const interCashView = (
    <>
      <Card
        cardContentStyles={{
          padding: '0px',
          margin: '0px',
          flexDirection: 'column',
          height: '100%'
        }}
        cardStyles={{
          margin: '0px',
          width: '100%',
          height: commonHeight,
          borderRadius: '10px',
          border: `2px solid ${palette.primary.main}`,
          position: 'relative'
        }}
        background="secondary"
      >
        <CardContentWrapper>
          {showBackDetails
            ? (
              <>
                <MagneticStrip />
                <CVVBox>CVV: {cardDetails.cvv}</CVVBox>
              </>
            )
            : (
              <FrontWrapper>
                <Header>
                  <Typography variant="caption">{cardDetails.cardStatus.toUpperCase()}</Typography>
                </Header>

                {cardDetails.cardStatus === cardStatus.suspend
                  ? (
                    <NoCardBalanceWrapper>
                      <CreditCard fontSize="large" />
                      <Typography variant="body1">Available Balance</Typography>
                      <Typography variant="body2">Not Available due to card Suspension</Typography>
                    </NoCardBalanceWrapper>
                  )
                  : (
                    <CardBalanceWrapper>
                      <CreditCard fontSize="large" />
                      <Typography variant="body1">Available Balance</Typography>
                      <Typography variant="h2">{`${cardDetails.cardCurrencySymbol}${cardDetails.availableBalance}`}</Typography>
                      <Typography variant="h2">{cardDetails.cardCurrency}</Typography>
                    </CardBalanceWrapper>
                  )}

                <StyledVirtualCard src={cardDetails.cardImageLink} alt="Virtual Card" />

                <ExpiryDate variant="body2">Exp Date: {cardDetails.expiry}</ExpiryDate>
              </FrontWrapper>
            )}
        </CardContentWrapper>
      </Card>
      <StyledFlipButton aria-label="flip-card" onClick={flipCard}>
        <img src={CardFlip} alt="Card Flip" />
      </StyledFlipButton>
    </>
  )

  const payQuickerView = (
    <>
      {pqVirtualCard.isLoading
        ? (
          <SkeletonWrapper>
            <Skeleton height={360}/>
          </SkeletonWrapper>
        )
        : pqVirtualCard.result
          ? (
            <img src={pqVirtualCard.result} width={'100%'} />
          )
          : (
            ''
          )}
      <StyledFlipButton aria-label="flip-card" onClick={() => {
        fetchVirtualCard(cardDetails.reimbursementCardId, cardSide === 'FRONT' ? 'BACK' : 'FRONT')
      }}>
        <img src={CardFlip} alt="Card Flip" />
      </StyledFlipButton>
    </>
  )

  return (
    <Wrapper>
      <CardWrapper>
        {instanceConfig.results.CardVendor
          ? instanceConfig.results.CardVendor.toLowerCase() === process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase()
            ? payQuickerView
            : interCashView
          : ''}
      </CardWrapper>
    </Wrapper>
  )
}

CardView.propTypes = {
  cardDetails: PropTypes.object
}

export default CardView
