import React, { useEffect, useState } from 'react'
import { ArrowDropDown, ArrowDropUp, Edit, Refresh } from '@mui/icons-material'
import styled from '@emotion/styled'
import StudyMessageContent from './StudyMessageContent'
import StudyMessageTab from './StudyMessageTab'
import QuickView from 'components/common/QuickView'
import ComposeMessage from './ComposeMessage'
import { Button, MailNavigation } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchStudyMessageByID,
  fetchStudyMessages,
  onUserViewMessage,
  resetMessages,
  setComposeMessageModal,
  setIsCompleted,
  setIsResponding,
  setSelectedMessage,
  setShowDistributionList
} from 'store/ducks/communicationCenter/studyMessages'
import { Paper, Collapse, IconButton } from '@mui/material'
import DistributionList from './DistributionList'
import StudyMessagesFilters from './StudyMessagesFilters'
import { Restriction } from 'permissions'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'
import { communicationTabIDs } from 'components/helper/constants/mailbox'

const EditIcon = styled(Edit)(
  ({ theme }) => `
    border: 2px solid ${theme.palette.white.main};
    border-radius: 5px;
    margin-right: ${theme.spacing(1 / 2)};
`
)

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const StyledIconButton = styled(IconButton)`
  padding: 6px;
`
const FilterCompletedWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button{
      margin-top: ${theme.spacing(2)};
      margin-left: ${theme.spacing(2)};
    }
`
)
const WriteMessageAndRefreshWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const StudyMessages = () => {
  const dispatch = useDispatch()
  const {
    messagesList,
    filters,
    composeMessageModal,
    composeMessage,
    showDistributionList,
    isCompleted,
    studyMessage,
    selectedMessage
  } = useSelector(store => store.studyMessages)
  const { isPatient } = useSelector(store => store.auth)
  const [isFilterPanelOpen, setFilterPanelOpen] = useState(false)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)

  // check if one of the multi-select is selected
  let isFiltered =
    Object.values(filters).filter(t => Array.isArray(t) && t.filter(Boolean).length > 0).length > 0
  // check if the isScheduledMessage flag is selected
  isFiltered = !!filters.isScheduledMessage
  // check if the isFlagged flag is selected
  isFiltered = !!filters.isFlagged

  useEffect(() => {
    dispatch(fetchStudyMessages(true, false, patientInformation.userId, selectedCommunicationTabId))
    setFilterPanelOpen(false)
  }, [isCompleted, selectedCommunicationTabId])

  const onCreateNewMessage = () => {
    dispatch(resetMessages(selectedCommunicationTabId))
    dispatch(setComposeMessageModal(true))
  }
  const onModalClose = () => {
    dispatch(setComposeMessageModal(false))
    dispatch(resetMessages(selectedCommunicationTabId))
  }

  const onNewStudyMessageClicked = async selectedItem => {
    dispatch(setIsResponding(false))
    await dispatch(fetchStudyMessageByID(selectedItem, selectedCommunicationTabId))
    dispatch(setShowDistributionList(false))
    // if logged in as bbk or site and trying to fetch study messages for patients
    // We should'nt execute this, when user is concierge and on an individual patient mailbox section
    if ((!isPatient && selectedCommunicationTabId !== communicationTabIDs.conciergeMsgsForPatients) || isPatient) {
      await dispatch(onUserViewMessage(selectedItem))
    }

    dispatch(setSelectedMessage(selectedItem))
  }

  return (
    <>
      <MailNavigation
        isLoading={messagesList.isLoading}
        tabLabelComp={StudyMessageTab}
        tabContentComp={showDistributionList ? DistributionList : StudyMessageContent}
        tabProps={messagesList.results}
        backdropMode={showDistributionList ? 'true' : 'false'}
        tabChangeActions={selectedItem => {
          // do not make api call if user clicks again on the same item
          if (studyMessage[0].id !== selectedItem.id) {
            onNewStudyMessageClicked(selectedItem)
          }
        }}
        selectedItem={selectedMessage || { ...messagesList.results[0] }}
        action={
          <ActionWrapper>
            <WriteMessageAndRefreshWrapper>
              <Restriction ability="read" feature={`${selectedCommunicationTabId}WriteMessage`}>
                <Button
                  size="medium"
                  startIcon={<EditIcon />}
                  onClick={() => onCreateNewMessage(true)}
                  fullWidth={false}
                >
                      Write New Message
                </Button>
              </Restriction>

              <StyledIconButton
                aria-label="refresh"
                color="primary"
                onClick={() =>
                  dispatch(
                    fetchStudyMessages(
                      true,
                      false,
                      patientInformation.userId,
                      selectedCommunicationTabId
                    )
                  )
                }
                size="large"
              >
                <Refresh aria-label="refresh-list" />
              </StyledIconButton>
            </WriteMessageAndRefreshWrapper>
            <FilterCompletedWrapper>
              <Restriction ability="read" feature="conciergeMsgsFilterSearchButton">

                <Button
                  disableElevation
                  size="small"
                  color={`${isFiltered ? 'primary' : 'inherit'}`}
                  onClick={() => {
                    setFilterPanelOpen(!isFilterPanelOpen)
                  }}
                  endIcon={isFilterPanelOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                >
                    Filter / Search
                </Button>
              </Restriction>
              <Restriction ability="read" feature="conciergeMsgsWithSitesCompletedMsgbutton">
                <Button
                  disableElevation
                  size="small"
                  color="inherit"
                  onClick={() => dispatch(setIsCompleted(!isCompleted))}
                >
                  {isCompleted ? 'Active Messages' : 'Completed Messages'}
                </Button>
              </Restriction>
            </FilterCompletedWrapper>

            <Collapse in={isFilterPanelOpen} unmountOnExit>
              <Paper elevation={0}>
                <StudyMessagesFilters
                  handleClose={() => {
                    setFilterPanelOpen(!isFilterPanelOpen)
                  }}
                />
              </Paper>
            </Collapse>
          </ActionWrapper>
        }
      />
      <QuickView
        title={`${composeMessage.id ? 'View / Edit Message' : 'Write New Message'}`}
        onClose={() => onModalClose()}
        dialogContent={<ComposeMessage onCancel={() => onModalClose()} />}
        isDialogOpen={composeMessageModal}
        maxWidth="lg"
        closeConfirmation={true}
      />
    </>
  )
}

export default StudyMessages
