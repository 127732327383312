import { createAction, createReducer } from '@reduxjs/toolkit'
import { copyObject } from 'components/helper/utility'

export const setLoading = createAction('userInteractions/setLoading')
export const setActiveStep = createAction('userInteractions/setActiveStep')
export const setTotalSteps = createAction('userInteractions/setTotalSteps')
export const setIsSidebarOpen = createAction('userInteractions/setIsSidebarOpen')
export const resetUserInteractions = createAction('userInteractions/resetUserInteractions')

/**
 * Custom Stepper methods
 */
export const handleNextStep = () => (dispatch, getState) => {
  dispatch(setActiveStep(getState().userInteractions.activeStep + 1))
}

export const handleBackStep = () => (dispatch, getState) => {
  dispatch(setActiveStep(getState().userInteractions.activeStep - 1))
}

export const handleResetStep = () => dispatch => {
  dispatch(setActiveStep(0))
}

const initialState = {
  isLoading: [],
  loadingCriteria: [],
  activeStep: 0,
  totalSteps: 0,
  isEditMode: false,
  isSidebarOpen: true,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setLoading, (state, { payload }) => {
      if (typeof payload === 'boolean') {
        if (payload) {
          state.isLoading = [...state.isLoading, true]
        } else {
          const myLoadingArray = [...state.isLoading]
          myLoadingArray.pop()
          state.isLoading = myLoadingArray
        }
      } else {
        if (payload.showLoading) {
          state.isLoading = [...state.isLoading, true]
          state.loadingCriteria = [...state.loadingCriteria, payload.loadingCriteria]
        } else {
          const myLoadingArray = [...state.isLoading]
          myLoadingArray.pop()
          state.isLoading = myLoadingArray

          const moduleToRemove = payload.moduleName
          const methodNameToRemove = payload.methodName
          state.loadingCriteria = state.loadingCriteria.filter(criteria =>
            !(criteria.module === moduleToRemove && criteria.methodName === methodNameToRemove)
          )
        }
      }
    })
    .addCase(setActiveStep, (state, action) => {
      state.activeStep = action.payload
    })
    .addCase(setTotalSteps, (state, action) => {
      state.totalSteps = action.payload
    })
    .addCase(setIsSidebarOpen, (state, action) => {
      state.isSidebarOpen = action.payload
    })
    .addCase(resetUserInteractions, state => {
      copyObject(state, initialState)
    })
})
