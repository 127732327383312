import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Typography, Grid } from '@mui/material'

import { Button } from 'components/common'
import QuickView from 'components/common/QuickView'
import ComposeMessage from 'components/private/communicationCenter/studyMessages/ComposeMessage'

const PaddedTypography = styled(Typography)(
  ({ theme }) => `
    &&{
      padding-bottom: ${theme.spacing(1)}
    }`,
)

const ConciergeHelp = () => {
  const [showComposeMessage, setShowComposeMessage] = useState(false)

  const closeComposeMessage = () => {
    setShowComposeMessage(false)
  }

  const openComposeMessage = () => {
    setShowComposeMessage(true)
  }

  return (
    <>
      <Grid container spacing={2} style={{ paddingLeft: '20px' }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PaddedTypography variant="body2" gutterBottom>
            Send Us a Study Message:
          </PaddedTypography>
          <Button size="large" fullWidth={false} color="primary" onClick={openComposeMessage}>
            New Message
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="body2">Call Us:</Typography>
          <br />
          <Typography variant="body1" data-testid="Call Us Information">
            +1 (617) 714-2300 (collect calls accepted)
            <br />
            Monday through Friday 7:30 AM – 6:30 PM
            <br />
            American Eastern Time.
          </Typography>
          <br />
          <Typography variant="body1">
            For assistance outside of these hours, please leave a voicemail message for our after-hours team.
          </Typography>
          <br />
        </Grid>
      </Grid>
      <QuickView
        title="Write New Message"
        onClose={closeComposeMessage}
        dialogContent={<ComposeMessage onCancel={closeComposeMessage} />}
        isDialogOpen={showComposeMessage}
        maxWidth="lg"
      />
    </>
  )
}

export default ConciergeHelp
