import React, { useEffect } from 'react'
import { List } from 'components/common'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdministrativeDocuments, setPageNumber, setSelectedDocument } from 'store/ducks/resourceCenter'
import { downloadResourceDocsByID } from 'services/common'
import { showError } from 'store/ducks/application'
import { setLoading } from 'store/ducks/userInteractions'

const AdministrativeDocuments = () => {
  const dispatch = useDispatch()
  const { administrativeDocuments, selectedDocument } = useSelector(store => store.resourceCenter)

  useEffect(() => {
    dispatch(fetchAdministrativeDocuments(true))
    return () => {
      dispatch(setSelectedDocument({}))
    }
  }, [])

  return (
    <List
      list={administrativeDocuments}
      selectedItem={selectedDocument}
      onAdornmentClicked={async file => {
        dispatch(setLoading(true))
        await downloadResourceDocsByID({ ...file, isViewed: false, documentType: null })
          .then(() => {
            dispatch(setLoading(false))
          })
          .catch(() => {
            dispatch(setLoading(false))
            dispatch(showError('There was some error while trying to download the document. Please try again.'))
          })
      }}
      onItemClicked={file => {
        dispatch(setPageNumber(1))
        dispatch(
          setSelectedDocument({
            ...file,
            category: resourceCenterCategory.administrativeDocuments,
            documentType: null,
            isViewed: true
          }),
        )
      }}
    />
  )
}

export default AdministrativeDocuments
