import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import Edit from '@mui/icons-material/Edit'
import {
  fetchCountriesLookup,
  // fetchGenderLookup,
  fetchLanguagesLookup,
  fetchMeansOfCommunications,
  fetchEnglishSkillsLookup
} from 'store/ducks/common'
import {
  onPatientContactInfoChange,
  setPatientContactInfoIsReadOnly
} from 'store/ducks/patientRecord/patientProfile'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import {
  TextBoxOuterLabel,
  DatePickerWithLabel,
  AutoCompleteWithLabel,
  Button,
  Checkbox,
  PhoneNumberField
} from 'components/common'
import StatesDropdown from 'components/common/form/StatesDropdown'
import { Restriction } from 'permissions'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`
const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
const MarginDiv = styled.div(
  ({ theme }) => `  
  margin-top: ${theme.spacing(4)};
`
)
const PaddedGridItem = styled(Grid)(({ theme }) => ({
  '&&': {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  }
}))

const validationSchema = isNoStatesForCountry =>
  validationMapper([
    { id: 'firstName', isResponseRequired: true, answerType: formElements.openText },
    { id: 'lastName', isResponseRequired: true, answerType: formElements.openText },
    { id: 'zipCode', isResponseRequired: true, answerType: formElements.openText },
    // { id: 'gender', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'dateOfBirth', isResponseRequired: true, answerType: formElements.date },
    { id: 'emailAddress', isResponseRequired: true, answerType: formElements.emailOpenText },
    { id: 'phoneNumber', isResponseRequired: true, answerType: formElements.openText },
    { id: 'preferredLanguage', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'patientCommunicationPreferences',
      isResponseRequired: true,
      answerType: formElements.multiSelectDropdown
    },
    { id: 'address1', isResponseRequired: true, answerType: formElements.openText },
    { id: 'city', isResponseRequired: true, answerType: formElements.openText },
    { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'state',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
      dependency: {
        elements: [],
        callBack: () => !isNoStatesForCountry
      }
    },
    { id: 'isAcknowledged', isResponseRequired: true, answerType: formElements.checkbox }
  ])

const EditPatientContactInfo = () => {
  const dispatch = useDispatch()
  const {
    countriesLookup,
    // genderLookup,
    meansOfCommunicationsLookup,
    languagesLookup,
    englishSkillsLookup
  } = useSelector(store => store.common)
  const { patientContactInfoIsReadOnly } = useSelector(store => store.patientProfile)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  // const [isReadOnly, setIsReadOnly] = useState(true)
  const [isNoStatesForCountry, setIsNoStatesForCountry] = useState(false)

  const noStatesFoundForSelectedCountry = noStatesForCountry => {
    setIsNoStatesForCountry(noStatesForCountry)
  }

  return (
    <Formik
      initialValues={patientInformation}
      enableReinitialize={true}
      validationSchema={validationSchema(isNoStatesForCountry)}
      onSubmit={values => {
        dispatch(onPatientContactInfoChange(values, patientInformation, patientInformation.userId))
      }}
    >
      {({ values, handleReset, setFieldValue }) => (
        <Form>
          <Restriction ability="read" feature="patientProfileEditContactInfo">
            {patientContactInfoIsReadOnly && (
              <IconButtonWrapper>
                <Button
                  color="secondary"
                  aria-label="Edit"
                  onClick={() => {
                    dispatch(setPatientContactInfoIsReadOnly(false))
                    // Every time user edits information, he needs to acknowledge
                    setFieldValue('isAcknowledged', false)
                  }}
                  startIcon={<Edit />}
                  size="small"
                >
                Edit
                </Button>
                {/* <IconButton aria-label="Edit" onClick={() => setIsReadOnly(false)}>
                  <Edit />
                </IconButton> */}
              &nbsp;
              </IconButtonWrapper>
            )}
          </Restriction>
          {!patientContactInfoIsReadOnly && <MarginDiv />}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextBoxOuterLabel
                size="small"
                id="firstName"
                label="First Name"
                name="firstName"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
              <TextBoxOuterLabel
                size="small"
                id="middleName"
                label="Middle Initial"
                name="middleName"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextBoxOuterLabel
                size="small"
                id="lastName"
                label="Last Name"
                name="lastName"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            {/* <Grid item xs={12} sm={12} md={1.75} lg={1.75}>
              <AutoCompleteWithLabel
                id="gender"
                label="Gender"
                name="gender"
                options={genderLookup.results}
                fetchOptions={() => dispatch(fetchGenderLookup())}
                disabled={patientContactInfoIsReadOnly}
                size="small"
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <DatePickerWithLabel
                id="dateOfBirth"
                label="Date of Birth"
                name="dateOfBirth"
                size="small"
                maxDate={new Date()}
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>

            {/* -------------------------------------------------------------------------------------------------------- */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <AutoCompleteWithLabel
                id="preferred-language"
                label="Preferred Language"
                name="preferredLanguage"
                options={languagesLookup.results}
                fetchOptions={() => dispatch(fetchLanguagesLookup())}
                disabled={patientContactInfoIsReadOnly}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AutoCompleteWithLabel
                multiple={false}
                hasSelectAllOption={false}
                size="medium"
                id="english-skill"
                name="englishSkill"
                label="How well does the patient understand English?"
                fetchOptions={() => dispatch(fetchEnglishSkillsLookup())}
                options={englishSkillsLookup.results}
                isLoading={englishSkillsLookup.isLoading}
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
              <PhoneNumberField
                size="small"
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5} />
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <AutoCompleteWithLabel
                multiple={true}
                id="preferred-means-of-communication"
                label="Preferred Communication"
                name="patientCommunicationPreferences"
                options={meansOfCommunicationsLookup}
                fetchOptions={() => dispatch(fetchMeansOfCommunications())}
                disabled={patientContactInfoIsReadOnly}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} />

            {/* -------------------------------------------------------------------------------------------------------- */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextBoxOuterLabel
                size="small"
                id="visit-address1"
                label="Home Address Line 1"
                name="address1"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextBoxOuterLabel
                size="small"
                id="visit-address2"
                label="Home Address Line 2"
                name="address2"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
              <AutoCompleteWithLabel
                id="visit-country"
                label="Country"
                name="country"
                options={countriesLookup.results}
                isLoading={countriesLookup.isLoading}
                fetchOptions={() => dispatch(fetchCountriesLookup())}
                disabled={patientContactInfoIsReadOnly}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            <Grid item xs={12} sm={12} md={1.75} lg={1.75}>
              <StatesDropdown
                id="visit-state"
                label="State / Province"
                name="state"
                selectedCountry={values.country?.id}
                disabled={patientContactInfoIsReadOnly}
                size="small"
                noStatesFoundForSelectedCountry={noStatesFoundForSelectedCountry}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.75} lg={1.75}>
              <TextBoxOuterLabel
                size="small"
                id="visit-city"
                label="City / Town"
                name="city"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
              <TextBoxOuterLabel
                size="small"
                id="visit-zip"
                label="Zip / Postal Code"
                name="zipCode"
                type="text"
                disabled={patientContactInfoIsReadOnly}
              />
            </Grid>

            <PaddedGridItem item xs={12} sm={12} md={12} lg={12}>
              <Checkbox
                id="isAcknowledged"
                label={
                  <>
                    <Typography variant="body1">
                      I confirm the information above is complete and correct, and that the person
                      identified with this information is willing to share their data. Furthermore,
                      the information will not be shared with anyone other than study-related
                      personnel or entities.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      <small>
                        <b>Please Note:</b> In accordance with the Terms and Conditions and Privacy
                        Policy of TrialCentralNet ®, this information also may be relevant for
                        program-related administrative communication regarding other patient support
                        services as available for this study.
                      </small>
                    </Typography>
                  </>
                }
                name="isAcknowledged"
                size="medium"
                disabled={patientContactInfoIsReadOnly}
              />
            </PaddedGridItem>
            {!patientContactInfoIsReadOnly && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                  &ensp;&ensp;
                  <Button
                    size="large"
                    color="inherit"
                    fullWidth={false}
                    onClick={() => {
                      // setIsReadOnly(true)
                      dispatch(setPatientContactInfoIsReadOnly(true))
                      handleReset()
                    }}
                  >
                    Cancel
                  </Button>
                </ActionWrapper>
              </Grid>
            )}
          </Grid>
          <br />
          <hr />
        </Form>
      )}
    </Formik>
  )
}

export default EditPatientContactInfo
