export const bbkRoles = {
  2: 'Account Executive',
  3: 'Success Manager',
  11: 'Concierge',
  5: 'Creative Director',
  8: 'Studio Manager',
  4: 'Copywriter',
  7: 'Designer',
  9: 'Media/Research',
  10: 'Website Developer',
  14: 'Finance'
}

export const policies = {
  1: 'Admin',
  2: 'BBK',
  3: 'Sponsor',
  4: 'CRA',
  5: 'Site',
  6: 'CRO',
  7: 'Travel Agent',
  8: 'Langland',
  9: 'Research Vendors',
  10: 'Vendor View',
  11: 'Prescreening Concierge Vendor',
  12: 'Concierge',
  13: 'Patient'
}

export const policiesNeedUsersDropdown = {
  12: 'Concierge',
  7: 'Travel Agent',
  11: 'Prescreening Concierge Vendor',
  3: 'Sponsor',
  6: 'CRO',
}

export const policyIdByName = {
  Concierge: 12,
  'Travel Agent': 7,
  'Prescreening Concierge Vendor': 11,
  'Prescreening Concierge': 11,
  Sponsor: 3,
  CRO: 6,
}

export const policiesNeedUsersDropdownIds = [12, 7, 11, 3, 6, 2, 1]
