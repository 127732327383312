import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Grid, Typography, Paper, Box, Skeleton } from '@mui/material'
import { patientTabIDs } from 'components/helper/constants/common'
import {
  approveRequest,
  downloadAttachment,
  makeAllRequestDetailsCalls,
  markRequestAsComplete,
  markRequestNotApproved,
  setApproveModal,
  setApproveSuccessModal,
  setMarkAsCompleteModal,
  setNotApproveModal,
  setRequestMarkedComplete
} from 'store/ducks/visitServices/requestDetails'
import {
  AutoCompleteWithLabel,
  Button,
  Card,
  DataGrid,
  PageHeader,
  PartitionCard,
  TextBoxOuterLabel
} from 'components/common'
import RequestFields from './RequestFields'
import RequestReadOnlyView from './RequestReadOnlyView'
import QuickView from 'components/common/QuickView'
import CancelRequestConfirmation from './actionModals/CancelRequestConfirmation'
import WriteMessage from './actionModals/WriteMessage'
import { Restriction } from 'permissions'
import {
  modalActionModalTitles,
  multiActionModalTypes
} from 'components/helper/constants/visitServices'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(4)
  }
}))

const RequestFieldsGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(4)};
  height: 100%;
`
const ActionGrid = styled(Grid)`
  align-self: end;
`
const ItineraryGrid = styled(Grid)(() => ({
  '&&': {
    button: {
      paddingLeft: '0px'
    }
  }
}))

const SecondContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const CenterAlignContainer = styled.div(
  ({ theme }) => `
    padding-top : ${theme.spacing(2)};
    padding-bottom : ${theme.spacing(6)};
    text-align: center;
  `
)
const StyledLink = styled(Link)`
  text-decoration: unset;
`

const RequestDetails = () => {
  const { instanceID, requestId, requestTypeId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const {
    assignmentAndMessageHistory,
    visitDetails,
    isRequestCompletedOrCancelled,
    currentItinerary,
    markAsCompleteModal,
    requestMarkedComplete,
    notApproveModal,
    approveModal,
    approveSuccessModal
  } = useSelector(store => store.requestDetails)
  const [showDetailView, setShowDetailView] = useState(false)
  const [multiActionModalType, setMultiActionModalType] = useState('')
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [notApproveNotes, setNotApproveNotes] = useState('')
  const [viewMessagesToFrom, setViewMessagesToFrom] = useState(null)

  useEffect(() => {
    dispatch(makeAllRequestDetailsCalls(+requestTypeId, requestId))
  }, [requestId])

  useEffect(() => {
    if (requestMarkedComplete) {
      navigate(
        `/instance/${instanceID}/patient-dashboard/${patientInformation.userId}/${patientTabIDs.visitSupportServices}/arriveDashboard`
      )
    }
    return () => {
      dispatch(setRequestMarkedComplete(false))
    }
  }, [requestMarkedComplete])

  const headers = [
    {
      id: 'assignment',
      label: 'Assignment',
      headProperties: { textAlign: 'center', minWidth: '120px' },
      bodyProperties: {
        textAlign: 'center',
        verticalAlign: 'top'
      }
    },
    {
      id: 'note',
      label: 'Message',
      headProperties: { textAlign: 'center', minWidth: '500px' },
      bodyProperties: {
        textAlign: 'left',
        // width: '50%',
        cellType: 'html',
        verticalAlign: 'top'
      }
    },
    {
      id: 'createdBy',
      label: 'From (Name)',
      headProperties: { textAlign: 'center', minWidth: '180px' },
      bodyProperties: {
        textAlign: 'center',
        verticalAlign: 'top'
      }
    },
    {
      id: 'fromPolicy',
      label: 'From (Policy)',
      headProperties: { textAlign: 'center', minWidth: '120px' },
      bodyProperties: {
        textAlign: 'center',
        verticalAlign: 'top'
      }
    },
    // TODO: this field is not sent.
    {
      id: 'messageSent',
      label: 'Message Sent',
      headProperties: { textAlign: 'center', minWidth: '180px' },
      bodyProperties: {
        textAlign: 'center',
        verticalAlign: 'top'
      }
    },
    {
      id: 'attachment',
      label: 'Attachment',
      headProperties: { textAlign: 'center', minWidth: '180px' },
      bodyProperties: {
        textAlign: 'center',
        verticalAlign: 'top'
      }
    }
  ]

  const onViewMessagesToFromChange = (selectedValue, reason) => {
    if (reason === 'selectOption') {
      setViewMessagesToFrom(selectedValue)
    } else if (reason === 'clear') {
      setViewMessagesToFrom(null)
    }
  }

  let updatedTableData = assignmentAndMessageHistory.results.map(data => ({
    ...data,
    attachment: data.attachment && (
      <Button variant="text" onClick={() => dispatch(downloadAttachment(data.attachment))}>
        {data.attachment.name}
      </Button>
    )
  }))

  if (viewMessagesToFrom) {
    updatedTableData = updatedTableData.filter(data => [data.fromPolicy, data.assignment].includes(viewMessagesToFrom.id))
  }

  return showDetailView
    ? (
      <RequestReadOnlyView setShowDetailView={setShowDetailView} />
    )
    : (
      <>
        <MainWrapper>
          <PageHeader
            title={<Typography variant="h4">Manage Request</Typography>}
            actionContent={
              <Grid container spacing={2}>
                <Grid item>
                  <StyledLink to={'../arriveDashboard'}>
                    <Button size="large" color="primary">
                      Back
                    </Button>
                  </StyledLink>
                </Grid>
              </Grid>
            }
            bar={false}
          />
        </MainWrapper>
        <PartitionCard
          cardProps={{
            cardStyles: { marginTop: '16px', marginBottom: '32px' },
            cardContentStyles: { padding: '0px', margin: '0px' }
          }}
          firstContent={
            <>
              <Typography variant="h2">Request Overview</Typography>
              <RequestFieldsGrid container spacing={2}>
                <RequestFields setShowDetailView={setShowDetailView} />
                {!isRequestCompletedOrCancelled && (
                  <Restriction ability="read" feature="arriveCancelRequestButton">

                    <ActionGrid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        size="medium"
                        onClick={() => setOpenCancelModal(true)}
                        color="inherit"
                      >
                          Cancel Request
                      </Button>
                    </ActionGrid>

                  </Restriction>
                )}
              </RequestFieldsGrid>
            </>
          }
          firstContentWidth="60%"
          secondContentHeight={'375px'}
          secondContent={
            <SecondContentWrapper>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h2">Itinerary / Service Details</Typography>
                </Grid>
                <ItineraryGrid item xs={12} sm={12} md={12} lg={12}>
                  {currentItinerary.isLoading
                    ? (
                      <Box>
                        <Skeleton height={62} />
                      </Box>
                    )
                    : currentItinerary.results?.attachment
                      ? (
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={() =>
                            dispatch(downloadAttachment(currentItinerary.results.attachment))
                          }
                        >
                          {currentItinerary.results?.attachment?.name}
                        </Button>
                      )
                      : (
                        <Typography variant="body">
                          <i>Itinerary / Services Details document is pending</i>
                        </Typography>
                      )}
                </ItineraryGrid>
              </Grid>

              <Grid container spacing={2}>
                {/* Show Approved and Not Approved Buttons only when document is uploaded */}
                {currentItinerary.isLoading
                  ? (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box>
                        <Skeleton height={60} />{' '}
                      </Box>
                    </Grid>
                  )
                  : (
                    currentItinerary.results?.attachment &&
                  !isRequestCompletedOrCancelled && (
                      <>
                        <Restriction ability="read" feature="arriveApproveButton">
                          <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                              size="large"
                              color="secondary"
                              fullWidth={true}
                              onClick={() => dispatch(setApproveModal(true))}
                            >
                              Approved
                            </Button>
                          </Grid>
                        </Restriction>

                        <Restriction ability="read" feature="arriveNotApproveButton">
                          <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Button
                              size="large"
                              color="secondary"
                              fullWidth={true}
                              onClick={() => dispatch(setNotApproveModal(true))}
                            >
                              Not Approved
                            </Button>
                          </Grid>
                        </Restriction>
                      </>
                    )
                  )}

                {visitDetails.isLoading
                  ? (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box>
                        <Skeleton height={60} />
                      </Box>
                    </Grid>
                  )
                  : (
                    !isRequestCompletedOrCancelled && (
                      <Restriction ability="read" feature="arriveUploadButton">
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            size="large"
                            color="secondary"
                            fullWidth={true}
                            onClick={() => setMultiActionModalType(multiActionModalTypes.uploadFile)}
                          >
                            Upload
                          </Button>
                        </Grid>
                      </Restriction>
                    )
                  )}

                {currentItinerary.isLoading
                  ? (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box>
                        <Skeleton height={60} />
                      </Box>
                    </Grid>
                  )
                  : (
                    currentItinerary.results &&
                  !currentItinerary.results?.isSendtoSite &&
                  !isRequestCompletedOrCancelled && (
                      <Restriction ability="read" feature="arriveSendToSite">
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Button
                            size="large"
                            color="secondary"
                            fullWidth={true}
                            onClick={() => setMultiActionModalType(multiActionModalTypes.sendToSite)}
                          >
                            Send To Site
                          </Button>
                        </Grid>
                      </Restriction>
                    )
                  )}
              </Grid>
            </SecondContentWrapper>
          }
          secondContentBackground="gradientBlue"
          secondContentWidth="40%"
        />

        <Card
          title="Assignment and Message History"
          titleTypographyProps={{ variant: 'h2' }}
          radius={false}
          headerColor="secondary"
          headerAction={
            <Restriction ability="read" feature="arriveWriteMsgButton">
              <Button
                size="medium"
                onClick={() => setMultiActionModalType(multiActionModalTypes.writeMessage)}
                color="primary"
              >
                  Write Message
              </Button>
            </Restriction>
          }
        >

          <Grid container spacing={2}>
            <Restriction ability="read" feature="arriveViewMessagesFilter">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteWithLabel
                  label="View Messages To / From"
                  id="viewMessagesToFrom"
                  name="viewMessagesToFrom"
                  options={[
                    { id: 'Concierge', displayText: 'Concierge' },
                    { id: 'Site', displayText: 'Site' },
                    { id: 'Travel Agent', displayText: 'Travel Agent' }
                  ]}
                  hasSelectAllOption={false}
                  size="small"
                  formik='false'
                  multiple={false}
                  selectedValue={viewMessagesToFrom}
                  onChange={(event, selectedValue, reason) => {
                    onViewMessagesToFromChange(selectedValue, reason)
                  }}
                />
              </Grid>
            </Restriction>
            <Restriction ability="read" feature="arriveHistoryGrid">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataGrid
                  headers={headers}
                  tableData={updatedTableData}
                  setRequestedSort={() => { }}
                  order={'DESC'}
                  orderBy={'createdDate'}
                  alternateRowColored={false}
                />
              </Grid>
            </Restriction>
          </Grid>

        </Card>

        <CenterAlignContainer>
          {!isRequestCompletedOrCancelled && (
            <Restriction ability="read" feature="arriveMarkAsCompletedButton">
              <Button
                size="medium"
                fullWidth={false}
                color="primary"
                onClick={() => dispatch(setMarkAsCompleteModal(true))}
              >
                Mark as Completed
              </Button>
            </Restriction>
          )}
        </CenterAlignContainer>
        <QuickView
          title="Approval Confirmation"
          onClose={() => {
            dispatch(setApproveModal(false))
          }}
          contentText="Upon approving this Itinerary / Service Details document, an automatic email with the document attached will
            be sent to all parties involved with the service. Please confirm your approval."
          contentTextProps={{ variant: 'body2' }}
          dialogActions={
            <Button
              size="medium"
              fullWidth={false}
              color="primary"
              onClick={() => dispatch(approveRequest(requestId))}
            >
              Confirm
            </Button>
          }
          isDialogOpen={approveModal}
          isSmallDialog={true}
        />
        <QuickView
          title="Success"
          onClose={() => {
            dispatch(setApproveSuccessModal(false))
            dispatch(makeAllRequestDetailsCalls(+requestTypeId, requestId))
          }}
          contentText="The Itinerary / Service Details document is now approved, and an email with the document attached has been
            sent to all parties involved with the service."
          contentTextProps={{ variant: 'body2' }}
          dialogActions={
            <Button
              size="medium"
              fullWidth={false}
              color="primary"
              onClick={() => {
                dispatch(setApproveSuccessModal(false))
                dispatch(makeAllRequestDetailsCalls(+requestTypeId, requestId))
              }}
            >
              Close
            </Button>
          }
          isDialogOpen={approveSuccessModal}
          isSmallDialog={true}
        />
        <QuickView
          title="Not Approved"
          onClose={() => {
            dispatch(setNotApproveModal(false))
          }}
          dialogContent={
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2">
                  Please explain why the itinerary / services details are not approved.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  formik="false"
                  size="small"
                  id="notApprovedNotes"
                  label=""
                  placeholder="Notes"
                  name="notApprovedNotes"
                  type="text"
                  rows="3"
                  value={notApproveNotes}
                  onChange={e => setNotApproveNotes(e.target.value)}
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  size="medium"
                  fullWidth={false}
                  color="primary"
                  onClick={() =>
                    dispatch(markRequestNotApproved(+requestTypeId, requestId, notApproveNotes))
                  }
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          }
          isDialogOpen={notApproveModal}
          isSmallDialog={true}
        />
        <QuickView
          title={modalActionModalTitles[multiActionModalType]}
          onClose={() => {
            setMultiActionModalType('')
          }}
          dialogContent={
            <WriteMessage
              closeWriteMessageModal={() => {
                setMultiActionModalType('')
                dispatch(makeAllRequestDetailsCalls(+requestTypeId, requestId))
              }}
              modalType={multiActionModalType}
            />
          }
          isDialogOpen={!!multiActionModalType}
          isSmallDialog={false}
        />
        <QuickView
          title="Cancel Request"
          onClose={() => setOpenCancelModal(false)}
          dialogContent={
            <CancelRequestConfirmation closeCancelModal={() => setOpenCancelModal(false)} />
          }
          isDialogOpen={openCancelModal}
          isSmallDialog={true}
        />
        <QuickView
          title="Mark as Completed"
          onClose={() => {
            dispatch(setMarkAsCompleteModal(false))
          }}
          contentText="Are you sure you want to mark this request as completed?"
          contentTextProps={{ variant: 'body2' }}
          dialogActions={
            <Button
              size="medium"
              fullWidth={false}
              color="primary"
              onClick={() => dispatch(markRequestAsComplete(requestId))}
            >
              Mark as Completed
            </Button>
          }
          isDialogOpen={markAsCompleteModal}
          isSmallDialog={true}
        />
      </>
    )
}

export default RequestDetails
