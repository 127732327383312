import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Grid, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material'
import { LocationOn } from '@mui/icons-material'
import styled from '@emotion/styled'
import { performLogout } from 'store/ducks/auth'
import TCNLogo from 'assets/images/tcn-logo.png'
import { AppBar, Button, PageHeader } from 'components/common'
import ExportLink from 'components/private/reports/ExportLink'
import { fetchTCNArriveRequestSummaryAndDetails } from 'store/ducks/crossInstanceReports'

const LogoutButton = styled(Button)`
  width: fit-content;
  text-decoration: none;
`
const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(5),
    height: '100vh',
    paddingTop: '80px'
  }
}))

const GridContainer = styled(Grid)(({ theme }) => ({
  '&&': {
    margin: theme.spacing(5, 5, 5, 0)
  }
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  '&&': {
    svg: {
      marginBottom: theme.spacing(0.5)
    }
  }
}))

const CrossInstanceReports = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isSidebarOpen } = useSelector(store => store.userInteractions)
  return (
    <>
      <AppBar
        isSidebarOpen={isSidebarOpen}
        logo={<img src={TCNLogo} width="40px" />}
        action={
          <LogoutButton
            variant="text"
            color="inherit"
            size="small"
            fullWidth={true}
            onClick={() => {
              dispatch(performLogout(() => navigate('/login'), false))
            }}
          >
            <Typography variant="body2">Logout</Typography>
          </LogoutButton>
        }
      />
      <MainWrapper>
        <PageHeader
          title={<Typography variant="h4">Cross-Instance Reports</Typography>}
          expandContent
          actionContent={
            <Button
              size="large"
              fullWidth={true}
              color="inherit"
              onClick={() => {
                navigate('/portal')
              }}
            >
              Back
            </Button>
          }
          content={
            <GridContainer container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ListItem disablePadding>
                  <StyledListItemIcon>
                    <LocationOn color="primary" fontSize="large" />
                  </StyledListItemIcon>
                  <ListItemText primary={<Typography variant="subtitle1">TCN Arrive</Typography>} />
                </ListItem>
                <ExportLink
                  label="Request Summary and Details"
                  exportTrigger={() => dispatch(fetchTCNArriveRequestSummaryAndDetails())}
                />
              </Grid>
            </GridContainer>
          }
          bar={true}
        />
      </MainWrapper>
    </>
  )
}

export default CrossInstanceReports
