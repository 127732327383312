import styled from '@emotion/styled'
import TCNLogo from 'assets/images/tcn-logo.png'
import { Button } from '../form'
import { Typography } from '@mui/material'

const FooterWrapper = styled.footer(({ theme }) => ({
  '&&': {
    position: 'fixed',
    bottom: 0,
    width: '-webkit-fill-available',
    minWidth: '-moz-available',
    padding: theme.spacing(1),
    textAlign: 'center',
    boxShadow: '0px -5px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.background.paper,
  }
}))

const AppFooterItems = styled.div(() => ({
  '&&': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}))

const FooterLogo = styled.img(
  ({ theme }) => `
      margin-bottom: ${theme.spacing(1)};
  `
)

const PpAndTermsConditionsWrapper = styled.div`
    p {
      text-decoration: underline;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  `

const Footer = () => (
  <FooterWrapper>
    <AppFooterItems>
      <Typography variant="minor" gutterBottom component="p">Copyright &copy; 2024 BBK</Typography>
      <FooterLogo src={TCNLogo} width="40px" />
      <PpAndTermsConditionsWrapper>
        <Button
          variant="text"
          onClick={() => window.open('/doc/privacy-notice', '_blank', 'rel=noopener noreferrer')}
        >
          <Typography variant="minor" component="p">
          Privacy Policy
          </Typography>
        </Button>
        <Typography variant="minor" component="div">|</Typography>
        <Button
          variant="text"
          onClick={() => window.open('/doc/tnc', '_blank', 'rel=noopener noreferrer')}
        >
          <Typography variant="minor" component="p">
          Terms and Conditions
          </Typography>
        </Button>
      </PpAndTermsConditionsWrapper>
    </AppFooterItems>
  </FooterWrapper>
)

export default Footer
