import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { DataGrid, AccordionGroup, Button } from 'components/common'
import { palette } from 'styles/baseTheme'

import { fetchDataPoints, saveDataPointAnswers } from 'store/ducks/siteManagement/siteManagement'
import { fetchSiteDataPointsType } from 'store/ducks/common'

import AddDataPointForm from './AddDataPointForm'
import DataPointAnswerControl from './DataPointAnswerControl'
import QuickView from 'components/common/QuickView'

const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
`

const Container = styled.div(({ theme }) => ({
  padding: theme.spacing(1, 5, 1, 5)
}))

const ButtonContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))

const EmptyFooter = styled.div`
  padding: 25px;
`
const CenterAlignContainer = styled.div(
  ({ theme }) => `
    padding-top : ${theme.spacing(2)};
    padding-bottom : ${theme.spacing(6)};
    text-align: center;
  `
)
const LabelComponent = styled.div(() => ({
  display: 'flex',
  justifyContent: 'start',
  width: '100%',
  fontWeight: 'bold'
}))

const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}

const DataPoints = () => {
  const { siteId } = useParams()
  const dispatch = useDispatch()
  const { dataPointsInitialValues, dataPointsInformation } = useSelector(store => store.siteManagement)
  const [showAddDataPoint, setShowAddDataPoint] = useState(false)

  useEffect(() => {
    dispatch(fetchDataPoints(siteId))
    dispatch(fetchSiteDataPointsType())
  }, [siteId])

  const addDataPointHandler = () => {
    setShowAddDataPoint(true)
  }
  const closeAddDataPointForm = () => {
    setShowAddDataPoint(false)
  }

  const headers = [
    {
      id: 'dataPoint',
      label: 'Data Point',
      bodyProperties: {
        textAlign: 'left',
        width: '50%',
        cellType: 'html'
      },
      headProperties: { ...headerProperties, textAlign: 'left' }
    },
    {
      id: 'answer',
      label: 'Data Point Answers',
      bodyProperties: {
        width: '20%',
        cellType: 'node'
      },
      headProperties: { ...headerProperties, textAlign: 'left' }
    },
    {
      id: 'updatedBy',
      label: 'Updated By',
      bodyProperties: {
        width: '20%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'updatedOn',
      label: 'Updated On',
      bodyProperties: {
        width: '10%'
      },
      headProperties: { ...headerProperties }
    }
  ]

  const submitHandler = values => {
    dispatch(saveDataPointAnswers(values, siteId))
  }

  return (
    <React.Fragment>
      <Container>
        <ButtonContainer>
          <Button
            label="Add"
            size="medium"
            fullWidth={false}
            color="primary"
            onClick={addDataPointHandler}
          >
            Add Data Point
          </Button>
        </ButtonContainer>
      </Container>
      <Container>
        <Formik
          initialValues={dataPointsInitialValues}
          enableReinitialize={true}
          onSubmit={submitHandler}
        >
          {({ handleReset }) => (
            <Form>
              <AccordionGroup
                accordions={Object.keys(dataPointsInformation).map(module => ({
                  label: <LabelComponent>{module}</LabelComponent>,
                  component: (
                    <TableWrapper>
                      <DataGrid
                        headers={headers}
                        tableData={dataPointsInformation[module].map(dataPointInfo => ({
                          ...dataPointInfo,
                          /**
                           * Cannot do this in redux
                           * As it gives serialization error for Component.
                           */
                          answer: <DataPointAnswerControl dataPoint={dataPointInfo} />
                        }))}
                        setRequestedSort={() => {}}
                        order={'DESC'}
                        orderBy={'updatedOn'}
                        alternateRowColored={true}
                      />
                    </TableWrapper>
                  )
                }))}
                gradient={false}
                color="gray"
                transitionProps={{ unmountOnExit: true }}
                defaultOpenPanel={-1}
                minBottomGutter={true}
                labelComponent={true}
              />
              <CenterAlignContainer>
                <Button
                  size="large"
                  fullWidth={false}
                  color="primary"
                  type="submit"
                  data-testid="saveButton"
                >
                  Save
                </Button> &nbsp; &nbsp;
                <Button
                  size="large"
                  fullWidth={false}
                  color="inherit"
                  data-testid="cancelButton"
                  onClick={() => { handleReset() }}
                >
                  Cancel
                </Button>
              </CenterAlignContainer>
            </Form>
          )}
        </Formik>
      </Container>
      <QuickView
        title="Add Data Point"
        onClose={closeAddDataPointForm}
        dialogContent={
          <AddDataPointForm closeAddDataPointForm={closeAddDataPointForm} siteId={siteId} />
        }
        isDialogOpen={showAddDataPoint}
        maxWidth="sm"
        dialogActions={<EmptyFooter />}
        closeConfirmation={false}
      />
    </React.Fragment>
  )
}

export default DataPoints
