import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Block, FileUploadTwoTone } from '@mui/icons-material'
import QuickView from '../QuickView'
import CheckCircle from '@mui/icons-material/CheckCircle'
import FormHelperText from '@mui/material/FormHelperText'
import Button from './Button'
import AttachmentButton from './AttachmentButton'
import { Typography } from '@mui/material'
import { useField } from 'formik'
import fileDownload from 'js-file-download'

const FileGridWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      margin-left: ${theme.spacing(2)};
    }
  `,
)

const AttachedFilesWrapper = styled.div(({ properties }) => ({
  '&&': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    flexWrap: 'wrap',
    ...properties
  }
}))

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 2em;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

const AttachFile = props => {
  const {
    onDelete,
    downloadAttachment,
    label,
    acceptFileFormats,
    multiple,
    formik,
    files,
    onFileUploadFailure,
    onFileChange,
    buttonProps,
    buttonLabel,
    openSelectFileModal,
    showInQuickView,
    attachFileProperties
  } = props
  let field, meta, setValue

  if (formik === 'true') {
    [field, meta, { setValue }] = useField(props)
  } else {
    field = {
      value: files,
    }
  }

  const fieldValue = field.value

  const [selectFileModal, setSelectFileModal] = useState(openSelectFileModal)
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    // acceptedFiles
  } = useDropzone({
    accept: acceptFileFormats,
    noDragEventsBubbling: false,
    onDrop: files => onFileUpload(files),
    multiple,
  })

  // On file upload (click the upload button)
  const onFileUpload = files => {
    const selectedFiles = files
    let finalFilesToUpload = fieldValue
    // finalFilesToUpload length is > 0 only when there are already selected files
    if (finalFilesToUpload.length > 0) {
      const listOfExistingFileNames = finalFilesToUpload.map(v => v.name)
      let skipFileUpload = false
      selectedFiles.forEach(selectedFile => {
        // when user tries to select a file with same name that exists in the already selected files,
        // then skip the selected file
        if (!listOfExistingFileNames.includes(selectedFile.name)) {
          finalFilesToUpload = [...finalFilesToUpload, selectedFile]
        } else {
          skipFileUpload = true
        }
      })
      if (skipFileUpload) {
        onFileUploadFailure('Not able to upload one of more files as files with the same name(s) already selected.')
      }
    } else {
      finalFilesToUpload = selectedFiles
    }

    if (formik === 'true') {
      setValue(finalFilesToUpload)
    } else {
      onFileChange(finalFilesToUpload)
    }

    setSelectFileModal(false)
  }

  const onClickRemove = file => {
    const selectedFiles = [...fieldValue]

    selectedFiles.splice(
      fieldValue.findIndex(selectedFile => selectedFile.name === file.name),
      1,
    )
    // this means this file already exists in the DB
    if (file.id) {
      onDelete(file)
    }
    if (formik === 'true') {
      setValue(selectedFiles)
    } else {
      onFileChange(selectedFiles)
    }
  }

  const isError = meta?.touched && Boolean(meta.error)
  const errorText = meta?.touched && meta.error

  return (
    <>
      <div>
        <FileGridWrapper>
          {label && <Typography variant="body2">{label}</Typography>}
          {showInQuickView && (
            <Button onClick={() => setSelectFileModal(true)} {...buttonProps}>
              {buttonLabel || 'Select'}
            </Button>
          )}
        </FileGridWrapper>
        {formik === 'true' && fieldValue.length > 0 && (
          <AttachedFilesWrapper properties={attachFileProperties}>
            {fieldValue.map((eachFile, index) => (
              <div key={index}>
                <AttachmentButton
                  title={eachFile.name}
                  size={`${eachFile.size / 1000} KB`}
                  filetype={eachFile.type}
                  onAttachmentClicked={() => {
                    /**
                       * Check ID
                       * If ID does not exists, that means download the file from the local memory
                       * if ID exists, fetch from the API using ID
                       */
                    if (eachFile.id) {
                      downloadAttachment && downloadAttachment(eachFile)
                    } else {
                      fileDownload(eachFile, eachFile.name)
                    }
                  }}
                  onDeleteAttachment={() => {
                    onClickRemove(eachFile)
                  }}
                  hasDeleteButton
                />
              </div>
            ))}
          </AttachedFilesWrapper>
        )}
        {errorText && (
          <FormHelperText component="div" error={isError}>
            {errorText}
          </FormHelperText>
        )}
      </div>

      {showInQuickView
        ? (
          <QuickView
            title="Select File"
            onClose={() => setSelectFileModal(false)}
            dialogContent={
              <div className="container">
                <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                  <input {...getInputProps()} multiple={multiple} data-testid="DropZone"/>
                  {isDragReject
                    ? (
                      <>
                        <Block fontSize="large" />
                        <p>
                          <b>
                        Please check if you are selecting the right file format.
                            {acceptFileFormats &&
                          `Allowed file format(s) are/is:
          ${acceptFileFormats}`}
                          </b>
                        </p>
                      </>
                    )
                    : isDragAccept
                      ? (
                        <>
                          <CheckCircle fontSize="large" />
                          <p>
                            <b>All files will be accepted.</b>
                          </p>
                        </>
                      )
                      : (
                        <>
                          <FileUploadTwoTone fontSize="large" />
                          <p>
                            <b>Drag &apos;n&apos; drop some files here, or click to select files.</b>
                          </p>
                        </>
                      )}
                </Container>
              </div>
            }
            isDialogOpen={selectFileModal}
            maxWidth="sm"
          />
        )
        : (
          <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} multiple={multiple} data-testid="DropZone"/>
            {isDragReject
              ? (
                <>
                  <Block fontSize="large" />
                  <p>
                    <b>
                  Please check if you are selecting the right file format.
                      {acceptFileFormats &&
                    `Allowed file format(s) are/is:
          ${acceptFileFormats}`}
                    </b>
                  </p>
                </>
              )
              : isDragAccept
                ? (
                  <>
                    <CheckCircle fontSize="large" />
                    <p>
                      <b>All files will be accepted.</b>
                    </p>
                  </>
                )
                : (
                  <>
                    <FileUploadTwoTone fontSize="large" />
                    <p>
                      <b>Drag &apos;n&apos; drop some files here, or click to select files.</b>
                    </p>
                  </>
                )}
          </Container>
        )}
    </>
  )
}

AttachFile.propTypes = {
  acceptFileFormats: PropTypes.string,
  onDelete: PropTypes.func,
  downloadAttachment: PropTypes.func,
  label: PropTypes.node,
  multiple: PropTypes.bool,
  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),
  /**
   * Selected Files
   */
  files: PropTypes.array,
  /**
   * Pass a method, the return fieldValue will be a error message
   */
  onFileUploadFailure: PropTypes.func,
  /**
   * Pass a method, which will be called on success of file upload or file deletion. Returns an array of files
   */
  onFileChange: PropTypes.func,
  /**
   * pass property to button
   */
  buttonProps: PropTypes.object,
  /**
   * Pass label to the button
   */
  buttonLabel: PropTypes.string,
  /**
   * Open Select file Modal by default
   */
  openSelectFileModal: PropTypes.bool,
  /**
   * when false, directly opens up the drag and drop UI instead of a button for triggering
   */
  showInQuickView: PropTypes.bool,
  /**
   * Styles applied to attach file wrapper
   */
  attachFileProperties: PropTypes.object,
}

AttachFile.defaultProps = {
  multiple: true,
  formik: 'true',
  buttonProps: { variant: 'contained', color: 'inherit' },
  openSelectFileModal: false,
  showInQuickView: true,
}

export default AttachFile
