import React, { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { IconButton, Typography } from '@mui/material'
import { palette } from 'styles/baseTheme'
import CardFlip from 'assets/images/reimbursement/Card-Flip-Arrow-Icon.png'
import { CreditCard } from '@mui/icons-material'
import { Button, Card } from 'components/common'
import Background from 'assets/images/reimbursement/Card-Gradient-Logo-BG.png'
import { cardStatus, currencyExchangeSiteUrl } from 'components/helper/constants/reimbursement'

const Wrapper = styled.div(({ theme }) => ({
  backgroundImage: `url(${Background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  verticalAlign: 'middle',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
}))

const AcceptTermsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  height: '100%',
  alignItems: 'center'
}))

const commonHeight = '260px'

const CardWrapper = styled.div(({ theme }) => ({
  '&&': {
    height: '100%',
    width: '75%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: theme.spacing(4),
    h6: {
      width: '420px',
      textAlign: 'center',
      marginBottom: theme.spacing(2)
    }
  }
}))

const CardContentWrapper = styled.div(() => ({
  '&&': {
    height: commonHeight,
    position: 'absolute',
    width: '100%'
  }
}))

const FrontWrapper = styled.div`
  padding: 8px 15px;
  width: 100%;
  height: 100%;
`

const Header = styled.div`
  width: 100%;
  text-align: end;
`
const ExpiryDate = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.customBlue.main};
    position: relative;
    bottom: 27px;
    left: 22px;
`
)

const MagneticStrip = styled.div(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.primary.main,
    height: '40px',
    width: '100%',
    marginTop: '30px'
  }
}))

const CVVBox = styled.div(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.gray.light,
    height: '40px',
    width: '90%',
    marginTop: '30px',
    marginBottom: '30px',
    marginLeft: '17px',
    padding: '5px 15px',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'end',
    alignSelf: 'center'
  }
}))

const CardBalanceWrapper = styled.div`
  && {
    ${() => `
        display: flex;
        align-items: center;
        width: 100%;
        z-index: 9;
        position: relative;
        left: 24px;
        svg{
          margin-top: 5px;
          align-self: flex-start;
        },
        p {
          width: 4rem;
          margin-left: 10px;
        },
        h2 {
          font-size: 2.2rem;
          margin-left: 20px;
          color: ${palette.customBlue.main};
        },
        h2:last-child {
          font-size: 1.5rem;
          margin-left: 15px;
          align-self: flex-end;
          margin-bottom: 5px;
        },
      `}
  }
`

const NoCardBalanceWrapper = styled.div(() => ({
  '&&': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: '9',
    position: 'relative',
    left: '24px',
    'p:first-of-type': {
      width: '4rem',
      marginLeft: '10px'
    },
    'p:nth-of-type(2)': {
      fontStyle: 'italic'
    }
  }
}))
const FlipCardWrapper = styled.div(() => ({
  '&&': {
    position: 'relative',
    top: '12rem',
    right: '3rem',
    width: 'max-content',
    button: {
      padding: '0px'
    }
  }
}))

const CurrencyConversionNFlipButtons = styled.div(({ theme }) => ({
  width: '25%',
  marginLeft: theme.spacing(1),
  height: '100%',
}))

const CurrencyConversionButton = styled(Button)`
  && {
    height: fit-content;
    position: relative;
    top: 7rem;
    left: -0.5rem;
    width: max-content;
  }
  
`

const StyledVirtualCard = styled.img`
  width: 274px;
  position: relative;
  left: 5rem;
  height: 183px;
`

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const PatientCardView = ({ cardDetails, account }) => {
  const [showBackDetails, setShowBackDetails] = useState(false)

  return account.isTermsAccepted
    ? (
      <Wrapper>
        <CardWrapper>
          <Typography variant="subtitle1" gutterBottom>
          Your Virtual Card
          </Typography>
          <Card
            cardContentStyles={{
              padding: '0px',
              margin: '0px',
              flexDirection: 'column',
              height: '100%'
            }}
            cardStyles={{
              margin: '0px',
              width: '420px',
              height: commonHeight,
              borderRadius: '10px',
              border: `2px solid ${palette.primary.main}`,
              position: 'relative'
            }}
            background="secondary"
          >
            <CardContentWrapper>
              {showBackDetails
                ? (
                  <>
                    <MagneticStrip />
                    <CVVBox>CVV: {cardDetails.cvv}</CVVBox>
                  </>
                )
                : (
                  <FrontWrapper>
                    <Header>
                      <Typography variant="caption">
                        {cardDetails.cardStatus.toUpperCase()}
                      </Typography>
                    </Header>

                    {cardDetails.cardStatus === cardStatus.suspend
                      ? (
                        <NoCardBalanceWrapper>
                          <CreditCard fontSize="large" />
                          <Typography variant="body1">Available Balance</Typography>
                          <Typography variant="body2">
                          Not Available due to card Suspension
                          </Typography>
                        </NoCardBalanceWrapper>
                      )
                      : (
                        <CardBalanceWrapper>
                          <CreditCard fontSize="large" />
                          <Typography variant="body1">Available Balance</Typography>
                          <Typography variant="h2">{`${cardDetails.cardCurrencySymbol}${cardDetails.availableBalance}`}</Typography>
                          <Typography variant="h2">{cardDetails.cardCurrency}</Typography>
                        </CardBalanceWrapper>
                      )}

                    <StyledVirtualCard src={cardDetails.cardImageLink} alt="Virtual Card" />

                    <ExpiryDate variant="body2">Exp Date: {cardDetails.expiry}</ExpiryDate>
                  </FrontWrapper>
                )}
            </CardContentWrapper>
          </Card>
        </CardWrapper>
        {account.isTermsAccepted
          ? <CurrencyConversionNFlipButtons>
            <CurrencyConversionButton
              variant="text"
              color="inherit"
              onClick={() => openInNewTab(currencyExchangeSiteUrl)}
              size="small"
            >
          Currency Conversion
            </CurrencyConversionButton>
            <FlipCardWrapper>
              <IconButton
                aria-label="edit-address"
                onClick={() => {
                  setShowBackDetails(state => !state)
                }}
              >
                <img src={CardFlip} alt="Card Flip" />
              </IconButton>
              <Button
                onClick={() => {
                  setShowBackDetails(state => !state)
                }}
                variant="text"
                color="inherit"
                size="small"
              >
                View CVV
              </Button>
            </FlipCardWrapper>
          </CurrencyConversionNFlipButtons>
          : ''}
      </Wrapper>
    )
    : (
      <AcceptTermsWrapper>
        <Typography variant="subtitle1">
        To access your virtual TCN Card, review the Terms & Conditions and Privacy Policy, and then
        accept by clicking the checkbox below. You may read the Terms & Conditions and Privacy
        Policy by clicking the links below.
        </Typography>
      </AcceptTermsWrapper>
    )
}

PatientCardView.propTypes = {
  cardDetails: PropTypes.object,
  account: PropTypes.object
}

export default PatientCardView
