import React from 'react'
import { useDispatch } from 'react-redux'

import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import { onAddConciergeInsight } from 'store/ducks/patientRecord/patientProfile'

import Button from 'components/common/form/Button'
import TextBoxOuterLabel from 'components/common/form/TextBoxOuterLabel'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const StyledDiv = styled.div`
  width: '100%';
`

const validationSchema = () =>
  validationMapper([{ id: 'note', isResponseRequired: true, answerType: formElements.openText }])

const AddInsightForm = () => {
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{ note: '' }}
      validationSchema={validationSchema()}
      onSubmit={values => {
        dispatch(onAddConciergeInsight(values))
      }}
    >
      {() => (
        <StyledDiv>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  size="medium"
                  id="note"
                  label="Note"
                  name="note"
                  type="text"
                  rows="5"
                  multiline
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                </ActionWrapper>
              </Grid>
            </Grid>
          </Form>
        </StyledDiv>
      )}
    </Formik>
  )
}

export default AddInsightForm
