import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@mui/material'
import { Button } from 'components/common'

const CloseConfirmation = ({ closeConfirmation, onConfirmation }) => {
  const onConfirmHandler = () => {
    closeConfirmation()
    onConfirmation()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="body1">
          <b>Are you sure you want to leave this form?</b>
          <br />
          Please note that any unsaved data will be lost.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button label="Yes" size="medium" fullWidth={false} color="primary" onClick={onConfirmHandler}>
          Yes
        </Button>
        &nbsp; &nbsp;
        <Button size="medium" fullWidth={false} color="inherit" onClick={closeConfirmation}>
          No
        </Button>
      </Grid>
    </Grid>
  )
}

CloseConfirmation.propTypes = {
  // Function to call on Confirmation
  onConfirmation: PropTypes.func,
  // Function to close Confirmation Modal
  closeConfirmation: PropTypes.func,
}

export default CloseConfirmation
