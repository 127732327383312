/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import {
  fetchOtherServiceDetails,
  submitOtherServiceRequest,
  resetOtherService,
  fetchOtherServiceTypes
} from 'store/ducks/visitServices/otherServiceRequest'

import {
  TextBoxOuterLabel,
  Button,
  Bar,
  CheckboxGroup,
  DatePickerWithLabel
} from 'components/common'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`

const StyledLabel = styled(Typography)`
display: flex;
flex-wrap: wrap;
`

const validationSchema = validationMapper([
  {
    id: 'visitDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Visit start date must be in the future'
    },
    error: 'Visit start date is required and must be a valid date'
  },

  {
    id: 'selectedServices',
    isResponseRequired: true,
    answerType: formElements.checkboxGroup,
    errorMessage: 'Atleast 1 Service needs to be selected'
  }
])

const OtherServices = () => {
  const { visitDetails, startNewRequestForVisitFlow } = useSelector(store => store.requestDetails)
  const { requestTypeId, requestId, copyRequestId } = useParams()
  const dispatch = useDispatch()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)

  const { otherServiceDetailsForm, otherServiceTypes } = useSelector(
    store => store.otherServiceRequest
  )

  useEffect(() => {
    if (patientInformation.userId) {
      if (!requestTypeId && !requestId && !copyRequestId) {
        dispatch(fetchOtherServiceTypes())
      }
      if (requestTypeId && requestId) {
        dispatch(fetchOtherServiceDetails(requestId))
      }
      // Copy Existing request View
      if (copyRequestId) {
        dispatch(fetchOtherServiceDetails(copyRequestId))
      }
    }
    return () => {
      dispatch(resetOtherService())
    }
  }, [patientInformation.userId])

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {visitDetails.isLoading ||
        otherServiceTypes.isLoading ||
        otherServiceTypes.results.length === 0
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          )
          : (
            <Formik
              initialValues={{
                ...otherServiceDetailsForm,
                ...(startNewRequestForVisitFlow &&
                !readOnly && {
                  ...startNewRequestForVisitFlow
                })
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={values => {
                dispatch(submitOtherServiceRequest(values, copyRequestId))
              }}
            >
              {() => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="body2">
                      Please have the Concierge contact me about other remote services as indicated
                      below.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <DatePickerWithLabel
                        id="visitDate"
                        label="Visit Date"
                        name="visitDate"
                        size="small"
                        minDate={new Date()}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} />
                    <Grid item xs={12} sm={12} md={12} lg={12} pb={4}>
                      <CheckboxGroup
                        label="Please check the box for each needed service below, and provide all necessary information."
                        id="selectedServices"
                        name="selectedServices"
                        optionsAlignInRow={true}
                        options={otherServiceTypes.results}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="body2">In Case We Need To Speak with You</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachDateTime"
                        label="What is the best time of the day to reach you?"
                        name="reachDateTime"
                        type="time"
                        size="small"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachPhoneNumber"
                        label={
                          <StyledLabel variant='body2'>
                          Best phone number to reach you <i>(include country code)</i>
                          </StyledLabel>
                        }
                        name="reachPhoneNumber"
                        size="small"
                        type="number"
                        disabled={readOnly}
                      />
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ActionWrapper>
                          <Button size="large" fullWidth={false} color="primary" type="submit">
                          Submit Request
                          </Button>
                        </ActionWrapper>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
      </Grid>
    </Grid>
  )
}

export default OtherServices
