import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Box, IconButton, Skeleton, Typography } from '@mui/material'
import CardFlip from 'assets/images/reimbursement/Card-Flip-Arrow-Icon.png'
import { Button } from 'components/common'
import Background from 'assets/images/reimbursement/Card-Gradient-Logo-BG.png'
import { currencyExchangeSiteUrl } from 'components/helper/constants/reimbursement'
import { getCardDataToken } from 'services/patientRecord/manageAccount'
import { getClientIp, getPQCardData } from 'services/patientRecord/payQuicker'
import { useDispatch } from 'react-redux'
import { showError } from 'store/ducks/application'

const Wrapper = styled.div(({ theme }) => ({
  backgroundImage: `url(${Background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  verticalAlign: 'middle',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2)
}))

const AcceptTermsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  height: '100%',
  alignItems: 'center'
}))

const CardWrapper = styled.div(({ theme }) => ({
  '&&': {
    height: '100%',
    width: '75%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: theme.spacing(4),
    h6: {
      width: '420px',
      textAlign: 'center',
      marginBottom: theme.spacing(2)
    }
  }
}))

const FlipCardWrapper = styled.div(() => ({
  '&&': {
    position: 'relative',
    top: '12rem',
    right: '3rem',
    width: 'max-content',
    button: {
      padding: '0px'
    }
  }
}))

const CurrencyConversionNFlipButtons = styled.div(({ theme }) => ({
  width: '25%',
  marginLeft: theme.spacing(1),
  height: '100%'
}))

const CurrencyConversionButton = styled(Button)`
  && {
    height: fit-content;
    position: relative;
    top: 7rem;
    left: -0.5rem;
    width: max-content;
  }
`

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const PayQuickerVirtualCard = styled.img`
  width: 420px;
  height: 260px;
  position: relative;
`

const SkeletonWrapper = styled(Box)`
  width: 420px;
  height: 260px;
`

const PQPatientCardView = ({ account }) => {
  const dispatch = useDispatch()
  const [pqVirtualCard, setPQVirtualCard] = useState({ result: null, isLoading: false })
  const [cardSide, setCardSide] = useState('')

  const fetchVirtualCard = async (reimbursementCardId, side = 'FRONT') => {
    try {
      setPQVirtualCard({ result: null, isLoading: true })
      const origin = process.env.REACT_APP_APPLICATION_ORIGIN
      const { ip } = await getClientIp()
      if (ip) {
        const { data } = await getCardDataToken(reimbursementCardId, 'IMAGE', side, origin, ip)
        const { accessToken, token, userToken, cardToken } = data
        const cardDataResponse = await getPQCardData(
          accessToken,
          token,
          userToken,
          cardToken,
          origin,
          'IMAGE',
          side
        )
        setCardSide(side)
        setPQVirtualCard({ result: cardDataResponse.cardImage, isLoading: false })
      }
    } catch (e) {
      setPQVirtualCard({ result: null, isLoading: false })
      dispatch(showError('Error when trying to fetch view card', e))
    }
  }

  useEffect(() => {
    account.isTermsAccepted && fetchVirtualCard(account.id)
  }, [account.isTermsAccepted])

  return account.isTermsAccepted
    ? (
      <Wrapper>
        <CardWrapper>
          <Typography variant="subtitle1" gutterBottom>
          Your Virtual Card
          </Typography>
          {pqVirtualCard.isLoading
            ? (
              <SkeletonWrapper>
                <Skeleton height={260} />
              </SkeletonWrapper>
            )
            : pqVirtualCard.result
              ? (
                <PayQuickerVirtualCard src={pqVirtualCard.result} width={'100%'} />
              )
              : (
                ''
              )}
        </CardWrapper>

        <CurrencyConversionNFlipButtons>
          <CurrencyConversionButton
            variant="text"
            color="inherit"
            onClick={() => openInNewTab(currencyExchangeSiteUrl)}
            size="small"
          >
          Currency Conversion
          </CurrencyConversionButton>
          <FlipCardWrapper>
            <IconButton
              aria-label="edit-address"
              onClick={() => fetchVirtualCard(account.id, cardSide === 'FRONT' ? 'BACK' : 'FRONT')}
            >
              <img src={CardFlip} alt="Card Flip" />
            </IconButton>
            <Button
              onClick={() => fetchVirtualCard(account.id, cardSide === 'FRONT' ? 'BACK' : 'FRONT')}
              variant="text"
              color="inherit"
              size="small"
            >
            View CVV
            </Button>
          </FlipCardWrapper>
        </CurrencyConversionNFlipButtons>
      </Wrapper>
    )
    : (
      <AcceptTermsWrapper>
        <Typography variant="subtitle1">
        To access your virtual TCN Card, review the Terms & Conditions and Privacy Policy, and then
        accept by clicking the checkbox below. You may read the Terms & Conditions and Privacy
        Policy by clicking the links below.
        </Typography>
      </AcceptTermsWrapper>
    )
}

PQPatientCardView.propTypes = {
  cardDetails: PropTypes.object,
  account: PropTypes.object
}

export default PQPatientCardView
