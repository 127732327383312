import styled from '@emotion/styled'
import { Edit } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Button, DataGrid, TextBoxOuterLabel } from 'components/common'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { palette } from 'styles/baseTheme'

const ParticipantTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'start',
  minWidth: '100px'
}

const selectParticipantsHeaders = [
  {
    id: 'phone',
    isSortable: false,
    headProperties: { ...headerProperties },
    bodyProperties: { cellType: 'node', textAlign: 'start' }
  },
  {
    id: 'email',
    isSortable: false,
    headProperties: { ...headerProperties },
    bodyProperties: { cellType: 'node', textAlign: 'start' }
  }
]

const gridTitleStyles = {
  backgroundColor: palette.primary.main,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'start',
  padding: '16px 16px',
  p: {
    color: palette.primary.contrastText,
  }
}
const ParticipantWrapper = styled.div`
  width: 500px;
`

const ParticipantForm = ({ person }) => {
  const [isReadOnly, setIsReadOnly] = useState(true)
  return (
    <ParticipantWrapper>
      <DataGrid
        gridTitle={
          <ParticipantTitleWrapper>
            <Typography variant="body2">
              {person.role ? `${person.fullName} (${person.role})` : person.fullName}
            </Typography>
            <Button variant='outlined' color='secondary' startIcon={<Edit size="small" />} onClick={() => setIsReadOnly(!isReadOnly)}>
              {isReadOnly ? 'Edit' : 'Save'}
            </Button>
          </ParticipantTitleWrapper>
        }
        gridTitleProperties={gridTitleStyles}
        headers={selectParticipantsHeaders}
        tableData={[
          {
            phone: (
              <TextBoxOuterLabel
                size="small"
                id={`${person.id}-selectedTelehealthParticipants-phoneNumber`}
                label="Phone Number:"
                name={`${person.id}-selectedTelehealthParticipants-phoneNumber`}
                disabled={isReadOnly}
              />
            ),
            email: (
              <TextBoxOuterLabel
                size="small"
                id={`${person.id}-selectedTelehealthParticipants-emailAddress`}
                label="Email Address:"
                name={`${person.id}-selectedTelehealthParticipants-emailAddress`}
                disabled={isReadOnly}
              />
            )
          }
        ]}
        setRequestedSort={() => {}}
        order={'DESC'}
        orderBy={'createdDate'}
        border="allSides"
        radius={'false'}
      />
    </ParticipantWrapper>
  )
}

ParticipantForm.propTypes = {
  person: PropTypes.object
}
export default ParticipantForm
