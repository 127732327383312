import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Typography } from '@mui/material'

import { Card, TextBoxOuterLabel } from 'components/common'
import styled from '@emotion/styled'

const supportTypeContent = {
  1: "Please provide details and other relevant considerations below for your patient's legal support for international travel needs (e.g. visa procurement, customs assistance, passport procurement).",
  2: 'Please provide details and other relevant considerations below for your patient’s medical record / official document request and translation needs.',
  3: 'Please provide details and other relevant considerations below for your patient’s medical / specialty transport needs (e.g. med flight, charter flight, ambulance).',
  4: (
    <>
      <p>
        We offer medially tailored nutritious meals to support patients / caregivers through the
        rigors of the study visits (e.g. infusion visits, therapies, long visits, fatigue).
      </p>
      <p>
        Please provide details and other relevant considerations below for your patient’s nutrition
        support / meal delivery needs
      </p>
    </>
  )
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&&': {
    margin: theme.spacing(2),
    width: 'auto',
    '.MuiCheckbox-root': {
      color: theme.palette.primary.contrastText
    },
    '.MuiTypography-body1': {
      color: theme.palette.primary.contrastText
    }
  }
}))
const SelectServiceOption = props => {
  const { requestId } = useParams()
  const { visitDetails } = useSelector(store => store.requestDetails)
  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Card
      radius={false}
      title={
        <StyledFormControlLabel
          componentsProps={{ typography: { variant: 'subtitle1' } }}
          {...props}
        />
      }
      disableTypographyForTitle={true}
      headerColor="primary"
      cardHeaderStyles={{ padding: '0px' }}
      cardStyles={{
        flex: '1 0 450px',
        minWidth: '450px',
        margin: '5px',
        boxSizing: 'border-box',
        height: 'fit-content'
      }}
    >
      {props.checked && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextBoxOuterLabel
              id={`serviceTypeDetails-${props.id}`}
              data-testid={`serviceTypeDetails-${props.id}`}
              label={
                <Typography component="div" variant="body2" gutterBottom={true}>
                  <>{supportTypeContent[props.id]}</>
                </Typography>
              }
              name={`serviceTypeDetails-${props.id}`}
              multiline={true}
              rows={3}
              placeholder="Details"
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

SelectServiceOption.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string
}

export default SelectServiceOption
