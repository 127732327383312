import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { PropertyValue, Bar } from 'components/common'
import { displayDate } from 'components/helper/utility'
import { CottageRounded, EmailRounded, PersonRounded } from '@mui/icons-material'

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  '&&': {
    minWidth: '12px',
    svg: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
      padding: '2px',
      marginRight: theme.spacing(1),
      fontSize: '1.5rem',
    },
  },
}))

const StyledList = styled(List)(({ theme }) => ({
  '&&': {
    marginTop: theme.spacing(2),
    '.MuiListItemText-root': {
      marginBottom: '0px',
    },
    '.MuiListItem-root': {
      alignItems: 'flex-start',
    },
  },
}))
const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&&': {
    marginBottom: theme.spacing(1.5),
  },
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '&&': {
    marginLeft: theme.spacing(3.7),
  },
}))
const CardHolderDetails = ({ cardHolderDetails }) => (
  <>
    {cardHolderDetails && (
      <>
        <Typography variant="h4" gutterBottom>
            Cardholder Details
        </Typography>
        <Bar fullWidth={true} color="white" />
        <StyledList>
          <ListItem disablePadding>
            <StyledListItemIcon>
              <PersonRounded color="secondary" />
            </StyledListItemIcon>
            <ListItemText>
              <PropertyValue
                label="First Name:"
                value={cardHolderDetails.firstName}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="Last Name:"
                value={cardHolderDetails.lastName}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </ListItem>
          {/* <ListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="Gender:"
                value={cardHolderDetails.gender.displayText}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </ListItem> */}
          <StyledListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="Date of Birth:"
                value={displayDate(cardHolderDetails.dateOfBirth)}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </StyledListItem>
          <StyledListItem disablePadding dense>
            <StyledListItemIcon>
              <EmailRounded color="secondary" />
            </StyledListItemIcon>
            <ListItemText>
              <PropertyValue
                label="Email Address:"
                value={cardHolderDetails.emailAddress}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </ListItemText>
          </StyledListItem>

          {/* <StyledListItem disablePadding dense>
            <StyledListItemIcon>
              <LocalPhoneRounded color="secondary" />
            </StyledListItemIcon>
            <ListItemText>
              <PropertyValue
                label="Phone Number:"
                value={cardHolderDetails.phoneNumber}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </ListItemText>
          </StyledListItem> */}
          <ListItem disablePadding dense>
            <StyledListItemIcon>
              <CottageRounded color="secondary" />
            </StyledListItemIcon>
            <ListItemText>
              <PropertyValue
                label="Country:"
                value={cardHolderDetails.country.displayText}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </ListItemText>
          </ListItem>

          <ListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="Address 1:"
                value={cardHolderDetails.address1}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="Address 2:"
                value={cardHolderDetails.address2}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="City / Town:"
                value={cardHolderDetails.city}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="State / Province:"
                value={cardHolderDetails.state.displayText}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemText>
              <PropertyValue
                label="Zip / Postal Code:"
                value={cardHolderDetails.zipCode}
                valueProps={{ variant: 'body2' }}
                align="row"
                labelProps={{ variant: 'body1' }}
              />
            </StyledListItemText>
          </ListItem>
        </StyledList>
      </>
    )}
  </>
)

CardHolderDetails.propTypes = {
  cardHolderDetails: PropTypes.object,
}

export default CardHolderDetails
