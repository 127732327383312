import React from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, Password } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { forgotSecurityQnA, performLogout, validateExistingAnswer } from 'store/ducks/auth'
import { useNavigate } from 'react-router-dom'

const StyledButton = styled(Button)`
  width: 35%;
`

const StyledTitle = styled(Typography)`
  margin-bottom: 30px;
`

const validations = Yup.object().shape({
  answer: Yup.string().required('Required')
})

const GridContainer = styled(Grid)`
  text-align: center;
`
const ActionGrid = styled(Grid)`
  display: flex;
  justify-content: space-around;
`

const AnswerSecurityQuestion = () => {
  const dispatch = useDispatch()
  const { securityStatus } = useSelector(store => store.auth)
  const navigate = useNavigate()

  return (
    <>
      <StyledTitle gutterBottom variant="h2" color="primary">
        Verify Your Answer
      </StyledTitle>

      <Formik
        initialValues={{
          answer: ''
        }}
        validationSchema={validations}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(validateExistingAnswer(values))
          setSubmitting(false)
        }}
      >
        <Form noValidate>
          <GridContainer container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Password
                size="small"
                id="security-answer"
                label={securityStatus.securityQuestion}
                name="answer"
                type="password"
                outerLabel={true}
              />
            </Grid>
            <ActionGrid item xs={12} sm={12} md={12} lg={12}>
              <StyledButton color="primary" type="submit" size="large" fullWidth={true}>
                Submit
              </StyledButton>
              <StyledButton
                color="inherit"
                size="large"
                fullWidth={true}
                onClick={() => {
                  dispatch(performLogout(() => navigate('/login'), false))
                }}
              >
                Cancel
              </StyledButton>
            </ActionGrid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                variant="text"
                size="small"
                fullWidth={false}
                onClick={() => {
                  dispatch(forgotSecurityQnA())
                }}
              >
                <Typography variant="caption">I forgot my security answer</Typography>
              </Button>
            </Grid>
          </GridContainer>
        </Form>
      </Formik>
    </>
  )
}

export default AnswerSecurityQuestion
