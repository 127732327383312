import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import ConfirmPatientInfo from './ConfirmPatientInfo'
import ConfirmPatientSupportInfo from './ConfirmPatientSupportInfo'
import { fetchPatientSupportPersonsForReimbursement } from 'store/ducks/patientRecord/reimbursement'

const InstructionText = styled.div(
  ({ theme }) => `
      text-align: center;
      margin: ${theme.spacing(4, 6, 0, 6)};
      h6{
        margin-bottom: ${theme.spacing(2)}
      }
      button{
        font-size: 0.6rem;
        padding: 1px;
      }
  `,
)

const SignUp = () => {
  const dispatch = useDispatch()
  const { patientInformationForReimbursement } = useSelector(store => store.reimbursement)

  const getPatientSupportPerson = async () => {
    !patientInformationForReimbursement.isRestrictedCountry &&
      patientInformationForReimbursement.userId &&
      (await dispatch(fetchPatientSupportPersonsForReimbursement(patientInformationForReimbursement.userId)))
  }

  useEffect(() => {
    getPatientSupportPerson()
  }, [patientInformationForReimbursement.userId])

  return patientInformationForReimbursement.isRestrictedCountry
    ? (
      <InstructionText>
        <Typography variant="subtitle1">
        The country of residence for this participant is a restricted country based on international banking
        regulations. Therefore, we will establish with you an alternate method of reimbursement / payment for this
        participant.
        </Typography>

        <div>
          <Typography variant="body1">
          Please proceed with entering reimbursements / payments in the Add Funds section below. The Concierge team will
          be notified and will follow up with your site to determine the best way to deliver the funds to the
          participant.
          </Typography>
        </div>
      </InstructionText>
    )
    : (
      <>
        <InstructionText>
          <Typography variant="subtitle1">
          If data is missing, please click the Patient Profile and add the relevant information.
          </Typography>
          <div>
            <Typography variant="body1">
            Once added to the profile, the information will appear here and then you can confirm and proceed.
            </Typography>
          </div>
        </InstructionText>
        <ConfirmPatientInfo />
        <ConfirmPatientSupportInfo />
      </>
    )
}

SignUp.propTypes = {
  setConciergeHelpPopup: PropTypes.func,
}

export default SignUp
