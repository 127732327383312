import { AutoCompleteWithLabel, CustomRadioGroup, PropertyValue } from 'components/common'
import {
  fetchReimbursementServices,
  onFieldChange,
  setCardConfigurationForm
} from 'store/ducks/configuration/cardConfiguration'
import PropTypes from 'prop-types'
import { capTypesIds, cardConfigurationFields, fundsManagementGuidelines } from 'components/helper/constants/configuration'
import { formElements } from 'components/helper/constants/common'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

const DropdownGrid = styled(Grid)(() => ({
  '&&': {
    '.MuiAutocomplete-root': {
      width: '50%'
    },
    '.MuiTypography-caption': {
      width: '50%'
    }
  }
}))

const NoteText = styled(Typography)(({ theme }) => ({
  '&&': {
    color: theme.palette.other.grayMediumTwo
  }
}))

const CardConfigurationForm = ({ values, setFieldValue }) => {
  const dispatch = useDispatch()
  const {
    eligibleParticipants,
    reimbursementServices,
    reimbursementCapTypes,
    cardConfigurationForm,
    reimbursementConfiguration
  } = useSelector(store => store.cardConfiguration)
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)

  const onChange = (field, fieldType, selectedValues, reason, options) => {
    let newSelectedValue
    let isValueChanged = false
    const existingValue = reimbursementConfiguration.results[field]

    if (fieldType === formElements.multiSelectDropdown) {
      if (reason === 'selectOption' || reason === 'removeOption') {
        newSelectedValue = selectedValues.find(option => option.id === 'select-all')
          ? options
          : selectedValues
      } else if (reason === 'clear') {
        newSelectedValue = []
      }

      const arrayOfSelectedIds = newSelectedValue.map(value => value.id)
      // because participating countries is coming array of IDs and not array of object, we are doing this check
      const areIdsEqual =
        existingValue &&
        existingValue.length === arrayOfSelectedIds.length &&
        existingValue.every(value =>
          typeof value === 'object'
            ? arrayOfSelectedIds.includes(value.id)
            : arrayOfSelectedIds.includes(value)
        )

      isValueChanged = !areIdsEqual
    } else {
      newSelectedValue = selectedValues
      if (newSelectedValue && existingValue && fieldType === formElements.dropdownMenu) {
        isValueChanged = newSelectedValue.id !== existingValue.id
      } else {
        isValueChanged = newSelectedValue !== existingValue
      }
    }

    setFieldValue(field, newSelectedValue)
    dispatch(setCardConfigurationForm({ ...cardConfigurationForm, [field]: newSelectedValue }))

    dispatch(
      onFieldChange({
        fieldName: field,
        fieldType,
        isChanged: isValueChanged
      })
    )
  }

  const isPayQuicker = !!(instanceConfig.results.CardVendor && instanceConfig.results.CardVendor.toLowerCase() ===
  process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase())

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container spacing={2}>
          <DropdownGrid item xs={12} sm={12} md={12} lg={12}>
            <AutoCompleteWithLabel
              multiple={true}
              hasSelectAllOption={true}
              size="small"
              id={cardConfigurationFields.eligibleParticipants}
              name={cardConfigurationFields.eligibleParticipants}
              label="Eligible to Receive Funds"
              options={eligibleParticipants.results}
              isLoading={eligibleParticipants.isLoading}
              onChange={(event, selectedValues, reason) =>
                onChange(
                  cardConfigurationFields.eligibleParticipants,
                  formElements.multiSelectDropdown,
                  selectedValues,
                  reason,
                  eligibleParticipants.results
                )
              }
            />
          </DropdownGrid>
          <DropdownGrid item xs={12} sm={12} md={12} lg={12}>
            <AutoCompleteWithLabel
              multiple={false}
              size="small"
              id={cardConfigurationFields.capType}
              name={cardConfigurationFields.capType}
              label="Funds Management"
              options={reimbursementCapTypes.results}
              isLoading={reimbursementCapTypes.isLoading}
              onChange={(event, selectedValue) =>
                onChange(cardConfigurationFields.capType, formElements.dropdownMenu, selectedValue)
              }
            />
            {values.capType && <NoteText variant='caption'><i>{fundsManagementGuidelines[values.capType.id]}</i></NoteText>}
          </DropdownGrid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PropertyValue
              label='Vendor: '
              labelProps={{ variant: 'body2' }}
              value={isPayQuicker ? 'PayQuicker' : 'Intercash'}
              valueProps={{ variant: 'body1' }}
              fullWidth={true}
              align="row"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isPayQuicker
              ? <CustomRadioGroup
                label="Virtual vs Plastic workflow"
                name={cardConfigurationFields.isVirtual}
                id={cardConfigurationFields.isVirtual}
                options={
                  [
                    { value: true, label: 'Virtual Only' },
                  ]}
                fullWidth={true}
                color="primary"
                row={true}
                formik="false"
                onChange={event =>
                  onChange(
                    cardConfigurationFields.isVirtual,
                    formElements.radioButtons,
                    event.target.value === 'true'
                  )
                }
                value={true}
                disabled={true}
              />
              : <CustomRadioGroup
                label="Virtual vs Plastic workflow"
                name={cardConfigurationFields.isVirtual}
                id={cardConfigurationFields.isVirtual}
                options={[
                  { value: true, label: 'Virtual with Plastic option' },
                  {
                    value: false,
                    label: 'Virtual with Automatic Plastic Shipment'
                  }
                ]}
                fullWidth={true}
                color="primary"
                row={true}
                formik="false"
                onChange={event =>
                  onChange(
                    cardConfigurationFields.isVirtual,
                    formElements.radioButtons,
                    event.target.value === 'true'
                  )
                }
                value={values.isVirtual}
              />}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomRadioGroup
              label='Shipment Method'
              name={cardConfigurationFields.isStandardShipping}
              id={cardConfigurationFields.isStandardShipping}
              options={[
                { value: true, label: 'Standard shipping' },
                {
                  value: false,
                  label: 'Courier shipping'
                }
              ]}
              fullWidth={true}
              color="primary"
              row={true}
              formik="false"
              onChange={event =>
                onChange(
                  cardConfigurationFields.isStandardShipping,
                  formElements.radioButtons,
                  event.target.value === 'true'
                )
              }
              value={values.isStandardShipping}
            />
            <NoteText variant='caption'><i>Changes to the shipment method require a TCN ticket.</i></NoteText>
          </Grid>

        </Grid>
      </Grid>

      {values.capType?.id === capTypesIds.reviewAndApprove && (
        <DropdownGrid item xs={12} sm={12} md={6} lg={6}>
          <AutoCompleteWithLabel
            multiple={true}
            hasSelectAllOption={true}
            size="small"
            id={cardConfigurationFields.approvalRequiredServices}
            name={cardConfigurationFields.approvalRequiredServices}
            label="Categories to be Approved"
            fetchOptions={() => dispatch(fetchReimbursementServices())}
            isLoading={reimbursementServices.isLoading}
            options={reimbursementServices.results}
            onChange={(event, selectedValues, reason) =>
              onChange(
                cardConfigurationFields.approvalRequiredServices,
                formElements.multiSelectDropdown,
                selectedValues,
                reason,
                reimbursementServices.results
              )
            }
          />
        </DropdownGrid>
      )}
    </>
  )
}

CardConfigurationForm.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func
}

export default CardConfigurationForm
