import React from 'react'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Button } from 'components/common'
import { useDispatch } from 'react-redux'
import { setAddPatientFlow, setIsAddressVerificationRequired } from 'store/ducks/patientExperienceManager'

const ActionWrapper = styled.div(
  ({ theme }) => `
      width: 100%;
      display: flex;
      justify-content: left;
      margin-top: ${theme.spacing(3)};
`,
)

const PatientExperienceInfoSuccess = () => {
  const dispatch = useDispatch()
  return (
    <>
      <Typography variant="body1">
        Thank you for providing your information. You may now begin using this portal, and if you have an immediate need
        for a patient, click the &ldquo;Add a Patient&rdquo; button.
      </Typography>
      <ActionWrapper>
        <Button
          size="large"
          fullWidth={false}
          color="primary"
          onClick={() => {
            dispatch(setIsAddressVerificationRequired(false))
            dispatch(setAddPatientFlow(true))
          }}
        >
          Add a Patient
        </Button>
      </ActionWrapper>
    </>
  )
}

export default PatientExperienceInfoSuccess
