import * as React from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-number-input'
import { FormControl, FormHelperText, InputLabel, TextField, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useField } from 'formik'

const StyledFormControl = styled(FormControl)(({ theme, error }) => ({
  '&&': {
    '.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.white.dark,
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      minHeight: '45px',
      fieldset: {
        top: '-5px',
        border: `2px solid ${error ? theme.palette.error.main : theme.palette.background.disabled}`
      }
    },
    '.Mui-focused': {
      color: '#333333'
    },
    '.Mui-disabled': {
      fieldset: {
        backgroundColor: theme.palette.background.disabled,
        border: 'unset'
      },
      input: {
        zIndex: theme.zIndex.minorUiElement
      },
      textarea: {
        zIndex: theme.zIndex.minorUiElement
      },
      '.MuiInputAdornment-root': {
        zIndex: 1,
        svg: {
          color: theme.palette.gray.dark
        }
      }
    }
  }
}))

const StyledWrapper = styled.div(({ theme }) => ({
  '&&': {
    '.PhoneInput': {
      display: 'flex'
    },
    '.PhoneInputCountry': {
      width: '90px',
      height: '44.5px',
      marginTop: '20px',
      select: {
        width: '80px',
        height: '44.5px',
        backgroundColor: theme.palette.white.dark,
        border: `2px solid ${theme.palette.background.disabled}`,
        borderRightWidth: '0px',
        ':disabled': {
          backgroundColor: theme.palette.background.disabled
        }
      }
    },
    '.PhoneInputCountryIcon': {
      width: '55px',
      position: 'relative',
      left: '6px',
      top: '-42px',
      backgroundColor: theme.palette.background.disabled
    },
    '.PhoneInputCountrySelectArrow': {
      display: 'none'
    }
  }
}))

const StyledHelperText = styled(FormHelperText)`
  position: relative;
  bottom: 0px;
  left: 5px;
`
const StyledInputLabel = styled(InputLabel)`
  && {
    transform: unset;
    display: flex;
    position: relative;
    width: fit-content;
    white-space: pre-wrap;
    font-weight: 600;
  }
`

// eslint-disable-next-line react/display-name
const Input = React.forwardRef((props, ref) => {
  const { fullWidth, required, disabled, captionText, error, helperText, meta } = props

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)
  return (
    <StyledFormControl
      fullWidth={fullWidth}
      required={required}
      error={isError}
      disabled={disabled}
    >
      <StyledInputLabel shrink>{props.label}</StyledInputLabel>
      <TextField {...props} label="" inputRef={ref} />
      <StyledHelperText component="div" error={isError}>
        {errorText}
      </StyledHelperText>
      {captionText && <Typography variant="caption">{captionText}</Typography>}
    </StyledFormControl>
  )
})

Input.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  captionText: PropTypes.string,
  meta: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.node
}

const PhoneNumberField = props => {
  const { value, onChange, onPostChange, formik, onBlur } = props

  let field, meta, setValue

  const onFieldValueChange = value => {
    setValue(value)

    // Call onPostChange if provided
    if (onPostChange) {
      onPostChange()
    }
  }

  if (formik === 'true') {
    ;[field, meta, { setValue }] = useField(props)
    field = {
      ...field,
      name: typeof name === 'number' ? `${name}` : name,
      onChange: onChange || onFieldValueChange
    }
  } else {
    field = {
      onChange,
      onBlur,
      value
    }
  }

  return (
    <>
      <StyledWrapper>
        <PhoneInput
          international
          defaultCountry="US"
          inputComponent={Input}
          label="Phone"
          meta={meta}
          countryCallingCodeEditable={false}
          {...props}
          {...field}
        />
      </StyledWrapper>
    </>
  )
}

PhoneNumberField.propTypes = {

  /**
     * The color of the component. It supports those theme colors that make sense for this component.
     * @default 'primary'
     */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),

  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),

  /**
     * If `true`, the component is disabled.
     * @default false
     */
  disabled: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * @default false
   */
  error: PropTypes.bool,
  /**
     * If `true`, the input will take up the full width of its container.
     * @default true
     */
  fullWidth: PropTypes.bool,
  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
     * The id of the `input` element.
     * Use this prop to make `label` and `helperText` accessible for screen readers.
     */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
     * Props applied to the Input element.
     * It will be a [`FilledInput`](/api/filled-input/),
     * [`OutlinedInput`](/api/outlined-input/) or [`Input`](/api/input/)
     * component depending on the `variant` prop value.
     */
  inputProps: PropTypes.object,
  /**
     * The label content. Note, this is of type node that means you must pass this as React element ex: Typography
     * The reason this is defined as node is that the label can be a combination of text and some icon.
     */
  label: PropTypes.node.isRequired,
  /**
     * Name attribute of the `input` element.
     */
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * Optional prop. If provided, call post onChange
   */
  onPostChange: PropTypes.func,
  /**
     * If `true`, the label is displayed as required and the `input` element is required.
     * @default false
     */
  required: PropTypes.bool,
  /**
     * The size of the component.
     */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.any,
  /**
   * Adds caption in the bottom of the field
   */
  captionText: PropTypes.string
}

PhoneNumberField.defaultProps = {
  color: 'primary',
  size: 'small',
  formik: 'true',
  fullWidth: true,
  autoFocus: false,
  disabled: false,
  error: false,
  multiline: false,
  required: false
}

export default PhoneNumberField
