import React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import WelcomeTextBackground from 'assets/images/blue-with-rings.png'
import ConciergeLogo from 'assets/images/concierge.png'
import { Button } from 'components/common'
import { Outlet } from 'react-router-dom'

const AuthWrapper = styled(Container)`
  margin: auto;
  height: 100%;
  display: flex;
`

const FormWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '95%',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '80%'
  },
  [theme.breakpoints.up('md')]: {
    width: '66%'
  }
}))

const GridContainerWrapper = styled(Grid)`
  justify-content: center;
  align-items: end;
  margin: auto;
`

const GridFormWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '600px',
  backgroundColor: `${theme.palette.white.main}`,
  [theme.breakpoints.down('md')]: {
    borderTopLeftRadius: `${theme.shape.containerRadius}`,
    borderTopRightRadius: `${theme.shape.containerRadius}`
  },
  [theme.breakpoints.up('md')]: {
    borderTopLeftRadius: `${theme.shape.containerRadius}`,
    borderBottomLeftRadius: `${theme.shape.containerRadius}`
  }
}))

const TermsWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing(3)};
`
)

const PrivacyPolicyButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
  text-decoration: unset;
  `
)

const GridLoginText = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${WelcomeTextBackground})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '600px',
  color: theme.palette.white.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    borderBottomRightRadius: `${theme.shape.containerRadius}`,
    borderBottomLeftRadius: `${theme.shape.containerRadius}`
  },
  [theme.breakpoints.up('md')]: {
    borderTopRightRadius: `${theme.shape.containerRadius}`,
    borderBottomRightRadius: `${theme.shape.containerRadius}`
  },
  'p:first-of-type': {
    marginBottom: theme.spacing(3)
  },
  'p:nth-of-type(2)': {
    width: '60%'
  },
  'p:nth-of-type(3)': {
    width: '80%'
  }
}))

const SecondWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledContactUsLogo = styled.img(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(2)};
  display: unset;
`
)
const StyledFooter = styled.footer(({ theme }) => ({
  '&&': {
    height: '20%',
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}))

const PublicLayout = () => (
  <AuthWrapper maxWidth="md">
    <GridContainerWrapper container>
      <GridFormWrapper item xs={12} sm={12} md={7} lg={7}>
        <FormWrapper>
          <Outlet />
        </FormWrapper>
        <TermsWrapper>
          <PrivacyPolicyButton
            variant="text"
            size="small"
            fullWidth={false}
            onClick={() => window.open('/doc/privacy-notice', '_blank', 'rel=noopener noreferrer')}
          >
            <Typography variant="caption">Privacy Policy</Typography>
          </PrivacyPolicyButton>
          <Typography variant="caption">|</Typography>
          <PrivacyPolicyButton
            variant="text"
            size="small"
            fullWidth={false}
            onClick={() => window.open('/doc/tnc', '_blank', 'rel=noopener noreferrer')}
          >
            <Typography variant="caption">Terms & Conditions</Typography>
          </PrivacyPolicyButton>
        </TermsWrapper>
      </GridFormWrapper>

      <GridLoginText item xs={12} sm={12} md={5} lg={5}>
        <SecondWrapper>
          <StyledContactUsLogo src={ConciergeLogo} width="30px" />

          <Typography gutterBottom variant="body2">
              Concierge Support Phone Line:
          </Typography>

          <Typography gutterBottom variant="body1">
              +1 (617) 714-2300
          </Typography>

          <Typography gutterBottom variant="body1">
              Monday through Friday
            <br /> 7:30 AM - 6:30 PM
            <br /> American Eastern Time.
          </Typography>
        </SecondWrapper>
      </GridLoginText>

      <StyledFooter>
        <Typography variant="caption" gutterBottom>
            TCN Engage only uses Strictly Necessary cookies for the sole purpose of providing basic
            function of the website.
        </Typography>

        <Typography variant="caption">
            TrialCentralNet (TCN) is comprised of TCN Engage, for Patients and Sites, and TCN
            Connect, for Sponsors / CROs. TrialCentralNet (TCN) is a trademark of BBK Worldwide LLC.
            © 2023 BBK Worldwide. All Rights Reserved.
        </Typography>
      </StyledFooter>
    </GridContainerWrapper>
  </AuthWrapper>
)

export default PublicLayout
