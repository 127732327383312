import React from 'react'
import { Outlet } from 'react-router-dom'
import { Typography } from '@mui/material'
import { PageHeader, TopNavigationBar } from 'components/common'
import { myAccountTabs } from 'routes/navigationRoutes'

const Settings = () => (
  <>
    <PageHeader
      title={<Typography variant="h4">My Account</Typography>}
      expandContent
      content={<TopNavigationBar buttons={myAccountTabs} bar={true} />}
      bar={false}
    />

    <Outlet />
  </>
)

export default Settings
