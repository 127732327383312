import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import Edit from '@mui/icons-material/Edit'
import { Grid, Typography } from '@mui/material'
import {
  fetchCountriesLookup,
  fetchSupportPersonTypes,
  fetchSupportPersonRelationships,
  // fetchGenderLookup,
  fetchLanguagesLookup,
  fetchMeansOfCommunications
} from 'store/ducks/common'
import {
  onPatientSupportChange,
  setShowAddForm,
  setIsReadOnly,
  patientSupportInitialValues
} from 'store/ducks/patientRecord/patientProfile'
import {
  TextBoxOuterLabel,
  AutoCompleteWithLabel,
  Button,
  DatePickerWithLabel,
  Checkbox
} from 'components/common'
import StatesDropdown from 'components/common/form/StatesDropdown'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import { Restriction } from 'permissions'

const AcknowledgeTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`
const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
const MarginDiv = styled.div(
  ({ theme }) => `  
  margin-top: ${theme.spacing(4.1)};
`
)

const validationSchema = isNoStatesForCountry =>
  validationMapper([
    { id: 'firstName', isResponseRequired: true, answerType: formElements.openText },
    {
      id: 'userSupportPersonType',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu
    },
    { id: 'lastName', isResponseRequired: true, answerType: formElements.openText },
    // { id: 'gender', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'dateOfBirth', isResponseRequired: true, answerType: formElements.date },
    { id: 'emailAddress', isResponseRequired: true, answerType: formElements.emailOpenText },
    { id: 'address1', isResponseRequired: true, answerType: formElements.openText },
    { id: 'city', isResponseRequired: true, answerType: formElements.openText },
    { id: 'zipCode', isResponseRequired: true, answerType: formElements.openText },
    { id: 'phoneNumber', isResponseRequired: true, answerType: formElements.openText },
    { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'state',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
      dependency: {
        elements: [],
        callBack: () => !isNoStatesForCountry
      }
    },
    { id: 'preferredLanguage', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'supportPersonRelationship',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu
    },
    {
      id: 'communicationPreferences',
      isResponseRequired: true,
      answerType: formElements.multiSelectDropdown
    },
    { id: 'isAcknowledged', isResponseRequired: true, answerType: formElements.checkbox }
  ])

const EditPatientSupport = ({ supportDetails, isAddTravelerForm, onSubmitPatientSupport }) => {
  const { instanceID } = useParams()
  const dispatch = useDispatch()
  const [isNoStatesForCountry, setIsNoStatesForCountry] = useState(false)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const {
    countriesLookup,
    supportPersonRelationships,
    supportPersonTypes,
    // genderLookup,
    languagesLookup,
    meansOfCommunicationsLookup
  } = useSelector(store => store.common)
  const { isReadOnly } = useSelector(store => store.patientProfile)
  let formattedSupportDetails

  const isNewPatientForm = supportDetails === undefined

  if (supportDetails) {
    formattedSupportDetails = {
      ...supportDetails,
      communicationPreferences: supportDetails.communicationPreferences || []
    }
  }

  useEffect(() => {
    // if it is Edit form, should be ReadOnly.
    dispatch(setIsReadOnly(!isNewPatientForm))
  }, [])

  const submitHandler = values => {
    dispatch(onPatientSupportChange(values, supportDetails, patientInformation.userId, onSubmitPatientSupport))
  }

  const noStatesFoundForSelectedCountry = noStatesForCountry => {
    setIsNoStatesForCountry(noStatesForCountry)
  }

  return (
    <Formik
      initialValues={formattedSupportDetails || patientSupportInitialValues}
      enableReinitialize={true}
      validationSchema={validationSchema(isNoStatesForCountry)}
      onSubmit={values => {
        submitHandler(values)
      }}
    >
      {({ values, handleReset, setFieldValue }) => (
        <Form>
          <Restriction ability="read" feature="patientProfileEditContactInfoCompanion">
            {isReadOnly && (
              <IconButtonWrapper>
                <Button
                  color="secondary"
                  aria-label="Edit"
                  onClick={() => {
                    dispatch(setIsReadOnly(false))
                    // Every time user edits information, he needs to acknowledge
                    setFieldValue('isAcknowledged', false)
                  }}
                  startIcon={<Edit />}
                  size="small"
                >
                Edit
                </Button>
              &nbsp;
              </IconButtonWrapper>
            )}
          </Restriction>
          {!isReadOnly && <MarginDiv />}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextBoxOuterLabel
                size="small"
                id="firstName"
                label={'First Name'}
                name="firstName"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
              <TextBoxOuterLabel
                size="small"
                id="middleName"
                label="Middle Initial"
                name="middleName"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextBoxOuterLabel
                size="small"
                id="lastName"
                label="Last Name"
                name="lastName"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

            {/* For new form show Email Address */}
            {isNewPatientForm && (
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextBoxOuterLabel
                  size="small"
                  id="emailAddress"
                  label="Email Address"
                  name="emailAddress"
                  type="text"
                  disabled={isReadOnly}
                />
              </Grid>
            )}

            {/* <Grid item xs={12} sm={12} md={1.75} lg={1.75}>
                  <AutoCompleteWithLabel
                    id="gender"
                    label="Gender"
                    name="gender"
                    options={genderLookup.results}
                    fetchOptions={() => {
                      dispatch(fetchGenderLookup())
                    }}
                    disabled={isReadOnly}
                    size="small"
                  />
                </Grid> */}

            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            {/* -------------------------------------------------------------------------------------------------------- */}

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <AutoCompleteWithLabel
                id="support-person-type"
                label="LAR / Caregiver / Companion"
                name="userSupportPersonType"
                options={supportPersonTypes}
                fetchOptions={() => {
                  dispatch(fetchSupportPersonTypes(instanceID))
                }}
                disabled={isReadOnly}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <AutoCompleteWithLabel
                id="support-person-relation"
                label="Relationship to Patient"
                name="supportPersonRelationship"
                options={supportPersonRelationships}
                fetchOptions={() => {
                  dispatch(fetchSupportPersonRelationships(instanceID))
                }}
                disabled={isReadOnly}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            <Grid item xs={12} sm={12} md={1.75} lg={1.75}>
              <DatePickerWithLabel
                id="dateOfBirth"
                label="Date of Birth"
                name="dateOfBirth"
                size="small"
                maxDate={new Date()}
                disabled={isReadOnly}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={1.75} lg={1.75}> */}
            {/* For new form show gender control here */}
            {/* {isNewPatientForm && (
                <AutoCompleteWithLabel
                  id="gender"
                  label="Gender"
                  name="gender"
                  options={genderLookup.results}
                  fetchOptions={() => {
                    dispatch(fetchGenderLookup())
                  }}
                  disabled={isReadOnly}
                  size="small"
                />
              )} */}
            {/* </Grid> */}
            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

            {/* -------------------------------------------------------------------------------------------------------- */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <AutoCompleteWithLabel
                id="preferred-language"
                label="Primary Language"
                name="preferredLanguage"
                options={languagesLookup.results}
                fetchOptions={() => {
                  dispatch(fetchLanguagesLookup())
                }}
                disabled={isReadOnly}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <TextBoxOuterLabel
                size="small"
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <AutoCompleteWithLabel
                multiple={true}
                id="communicationPreferences"
                label="Preferred Communication"
                name="communicationPreferences"
                options={meansOfCommunicationsLookup}
                fetchOptions={() => {
                  dispatch(fetchMeansOfCommunications())
                }}
                disabled={isReadOnly}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            {/* -------------------------------------------------------------------------------------------------------- */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextBoxOuterLabel
                size="small"
                id="visit-address1"
                label="Address Line 1"
                name="address1"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
              <TextBoxOuterLabel
                size="small"
                id="visit-address2"
                label="Address Line 2"
                name="address2"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <AutoCompleteWithLabel
                id="country"
                label="Country"
                name="country"
                options={countriesLookup.results}
                isLoading={countriesLookup.isLoading}
                fetchOptions={() => {
                  dispatch(fetchCountriesLookup())
                }}
                disabled={isReadOnly}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
            <Grid item xs={12} sm={12} md={1.75} lg={1.75}>
              <StatesDropdown
                id="visit-state"
                label="State / Province"
                name="state"
                selectedCountry={values.country?.id}
                disabled={isReadOnly}
                size="small"
                noStatesFoundForSelectedCountry={noStatesFoundForSelectedCountry}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.5} lg={1.5}>
              <TextBoxOuterLabel
                size="small"
                id="visit-city"
                label="City / Town"
                name="city"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1.75} lg={1.75}>
              <TextBoxOuterLabel
                size="small"
                id="visit-zip"
                label="Zip / Postal Code"
                name="zipCode"
                type="text"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Checkbox
                name="isAcknowledged"
                id="acknowledgement"
                label={
                  <AcknowledgeTextWrapper>
                    <Typography variant="body1" gutterBottom>
                      I confirm the information above is complete and correct, and that the person
                      identified with this information is willing to share their data. Furthermore,
                      the information will not be shared with anyone other than study-related
                      personnel or entities.
                    </Typography>
                  </AcknowledgeTextWrapper>
                }
                size="medium"
                disableTypographyForLabel={true}
                disabled={isReadOnly}
              />
            </Grid>

            {!isReadOnly && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                  &ensp;&ensp;
                  {/* For AddTravelerForm used in TCN Arrive/InPersonRequestForm Cancel button not needed */}
                  {!isAddTravelerForm && (
                    <Button
                      size="large"
                      color="inherit"
                      fullWidth={false}
                      onClick={() => {
                        dispatch(setIsReadOnly(true))
                        dispatch(setShowAddForm(false))
                        handleReset()
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </ActionWrapper>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

EditPatientSupport.propTypes = {
  /**
   * Data for the support form fields
   */
  supportDetails: PropTypes.object,
  /**
   * Adding traveler from In-person Request form
   */
  isAddTravelerForm: PropTypes.bool,
  /**
   * call back on submit of patient support form
   */
  onSubmitPatientSupport: PropTypes.func
}

EditPatientSupport.defaultProps = {
  supportDetails: undefined,
  isAddTravelerForm: false
}
export default EditPatientSupport
