import { Chip, Grid } from '@mui/material'
import { AttachFile, AutoCompleteWithLabel, Button, TextBoxOuterLabel } from 'components/common'
import { formElements } from 'components/helper/constants/common'
import { validationMapper } from 'components/helper/validationEngine'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { showError, showWarning } from 'store/ducks/application'
import { fetchCountriesLookup } from 'store/ducks/common'
import {
  fetchSitesByCountries,
  submitPatientDashboardDisplay,
  submitVisitDropdownMenus
} from 'store/ducks/configuration/visitDetailsConfiguration'
import * as XLSX from 'xlsx'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { visitDetailsUploadType } from 'components/helper/constants/configuration'

const ActionGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`

const validationSchema = validationMapper([
  {
    id: 'displayName',
    isResponseRequired: true,
    answerType: formElements.openText
  },
  {
    id: 'processingFile',
    isResponseRequired: true,
    answerType: formElements.attachment
  },
  { id: 'filterByCountry', isResponseRequired: true, answerType: formElements.multiSelectDropdown },
  { id: 'siteAssociation', isResponseRequired: true, answerType: formElements.multiSelectDropdown }
])

const removeNumberAddedByExcel = key => {
  const headerSplit = key.split('_')
  const isExcelAppendedNum = headerSplit[1] && typeof parseInt(headerSplit[1]) === 'number'
  return isExcelAppendedNum ? headerSplit[0] : key
}

const UploadPatientDashboardDisplay = ({ onSuccess, uploadType }) => {
  const dispatch = useDispatch()
  const { countriesLookup } = useSelector(store => store.common)
  const { sitesLookupByCountry } = useSelector(store => store.visitDetailsConfiguration)
  const readExcelFile = (selectedFile, setFieldValue) => {
    try {
      if (selectedFile) {
        const reader = new FileReader()

        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          const workbookSheets = {}
          const sheetNames = Object.keys(workbook.Sheets)
          sheetNames.forEach(name => {
            if (workbook.Sheets[name]) {
              workbookSheets[name.trim()] = workbook.Sheets[name]
            }
          })

          const result = {}

          if (uploadType === visitDetailsUploadType.patientDashboardDisplay) {
            // Process the sheet (Visit Assessments) and build the first JSON(AssessmentList)
            const visitAssessmentSheet = workbookSheets['Visit Assessments']
            const visitAssessmentSheetRows = XLSX.utils.sheet_to_json(visitAssessmentSheet, {
              header: 1
            })
            if (visitAssessmentSheetRows.length && visitAssessmentSheetRows[0].length) {
              // Extract column A data, excluding the top two header rows
              const assessmentNames = visitAssessmentSheetRows
                .slice(2)
                .filter(row => row.length)
                .map(row => row[0])

              // Create the first JSON(AssessmentList)
              result.assessmentNames = assessmentNames

              // Process the sheet (Visit Assessments) and build the second JSON(visitAssessments)
              const visitAssessmentSheetData = XLSX.utils.sheet_to_json(visitAssessmentSheet)

              const headerRow = visitAssessmentSheetData[0]
              const keys = Object.keys(headerRow).filter(key => key !== '__EMPTY')

              const visitAssessments = keys.map(key => {
                const visitPhase = removeNumberAddedByExcel(key)
                const visitName = headerRow[key]
                const visitAssessment = []

                visitAssessmentSheetData.slice(1).forEach(item => {
                  if (item.__EMPTY && item[key] && item[key].toLocaleLowerCase() === 'x') {
                    visitAssessment.push(item.__EMPTY)
                  }
                })

                return {
                  visitPhase,
                  visitName,
                  visitAssessment
                }
              })

              result.visitAssessments = visitAssessments
            } else {
              // As the Visit Assessment sheet is empty process the visit schedule data
              const visitScheduleSheet = workbookSheets['Visit Schedule']

              const visitScheduleSheetRows = XLSX.utils.sheet_to_json(visitScheduleSheet, {
                header: 1
              })
              if (visitScheduleSheetRows.length && visitScheduleSheetRows[0].length) {
                // Process the sheet (Visit schedule) and build the second JSON(visitAssessments)
                const visitScheduleSheetData = XLSX.utils.sheet_to_json(visitScheduleSheet)
                const visitAssessmentKeys = {
                  'Visit Phase': 'visitPhase',
                  'Visit Name': 'visitName'
                }

                const visitAssessments = visitScheduleSheetData.map(data => {
                  const visitAssignmentObject = {}
                  visitScheduleSheetRows[0].forEach(rowItem => {
                    visitAssignmentObject[visitAssessmentKeys[rowItem.trim()]] =
                      data[rowItem.trim()]
                  })
                  visitAssignmentObject.visitAssessment = []
                  return visitAssignmentObject
                })

                result.visitAssessments = visitAssessments
              } else {
                dispatch(
                  showError(
                    'There was some issue processing the file. Please make sure you are using the template file for filling the information.'
                  )
                )
              }
            }
          } else {
            // Process the sheet (Visit schedule) and build the JSON(visitSchedules)
            const visitScheduleSheet = workbookSheets['Visit Schedule']

            const visitScheduleSheetRows = XLSX.utils.sheet_to_json(visitScheduleSheet, {
              header: 1
            })
            if (visitScheduleSheetRows.length && visitScheduleSheetRows[0].length) {
              const visitScheduleSheetData = XLSX.utils.sheet_to_json(visitScheduleSheet)
              const visitScheduleKeys = {
                'Visit Phase': 'visitPhase',
                'Visit Name': 'visitName'
              }
              const requiredFields = ['Visit Phase', 'Visit Name']

              const visitSchedules = visitScheduleSheetData.map(data => {
                const visitScheduleObject = {}
                requiredFields.forEach(rowItem => {
                  visitScheduleObject[visitScheduleKeys[rowItem]] = data[rowItem]
                    ? data[rowItem]
                    : ''
                })
                return visitScheduleObject
              })

              result.visitSchedules = visitSchedules
            } else {
              dispatch(
                showError(
                  'There was some issue processing the file. Please make sure you are using the template file for filling the information.'
                )
              )
            }
          }

          setFieldValue('sheetData', result)
          setFieldValue('processingFile', [selectedFile[0]])
          setFieldValue('fileName', selectedFile[0].name)
        }

        reader.readAsArrayBuffer(selectedFile[0])
      } else {
        dispatch(
          showError(
            'There was some issue processing the file. Please make sure you are using the template file for filling the information.'
          )
        )
      }
    } catch {
      dispatch(
        showError(
          'There was some issue processing the file. Please make sure you are using the template file for filling the information.'
        )
      )
    }
  }

  const onFilterByCountriesChange = (selectedValues, reason, setFieldValue) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      // Dynamic dropdown options for Sites based on country selection
      if (selectedValues.find(option => option.id === 'select-all')) {
        setFieldValue('filterByCountry', countriesLookup.results)
        dispatch(fetchSitesByCountries(countriesLookup.results))
      } else {
        setFieldValue('filterByCountry', selectedValues)
        if (selectedValues.length > 0) {
          dispatch(fetchSitesByCountries(selectedValues))
        }
        if (reason === 'removeOption') {
          // Empty the selected values if any
          setFieldValue('siteAssociation', [])
        }
      }
    } else if (reason === 'clear') {
      setFieldValue('siteAssociation', [])
      setFieldValue('filterByCountry', [])
    }
  }
  return (
    <Formik
      initialValues={{
        processingFile: [],
        filterByCountry: [],
        siteAssociation: [],
        sheetData: {},
        fileName: '',
        displayName: ''
      }}
      enableReinitialize={false}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (uploadType === visitDetailsUploadType.patientDashboardDisplay) {
          dispatch(submitPatientDashboardDisplay(values, onSuccess))
        } else {
          dispatch(submitVisitDropdownMenus(values, onSuccess))
        }

        setSubmitting(false)
      }}
    >
      {({ setFieldValue, values, dirty }) => (
        <Form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {values.fileName
                ? (
                  <Chip
                    label={values.fileName}
                    color="primary"
                    onDelete={() => {
                      setFieldValue('sheetData', {})
                      setFieldValue('processingFile', [])
                      setFieldValue('fileName', '')
                    }}
                  />
                )
                : (
                  <AttachFile
                    formik="false"
                    buttonLabel="Upload"
                    name="processingFile"
                    buttonProps={{ color: 'primary' }}
                    showInQuickView={false}
                    onFileUploadFailure={msg => dispatch(showWarning(msg))}
                    onFileChange={selectedFile => {
                      readExcelFile(selectedFile, setFieldValue)
                    }}
                    files={values.processingFile}
                  />
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextBoxOuterLabel
                id="displayName"
                label="Display Name"
                name="displayName"
                fullWidth={true}
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="small"
                id="filterByCountry"
                name="filterByCountry"
                label="Select Country / Countries"
                options={countriesLookup.results}
                isLoading={countriesLookup.isLoading}
                fetchOptions={() => dispatch(fetchCountriesLookup())}
                onChange={(event, selectedValues, reason) => {
                  onFilterByCountriesChange(selectedValues, reason, setFieldValue)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="small"
                id="siteAssociation"
                name="siteAssociation"
                label="Site Association"
                options={sitesLookupByCountry.results}
                isLoading={sitesLookupByCountry.isLoading}
              />
            </Grid>
            <ActionGrid item xs={12} sm={12} md={12} lg={12}>
              <Button
                disabled={!dirty}
                color="primary"
                type="submit"
                size="medium"
                fullWidth={false}
              >
                Save
              </Button>
            </ActionGrid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

UploadPatientDashboardDisplay.propTypes = {
  onSuccess: PropTypes.func,
  uploadType: PropTypes.string
}

export default UploadPatientDashboardDisplay
