import styled from '@emotion/styled'
import { Grid, Skeleton, Typography } from '@mui/material'
import { AutoCompleteWithLabel, Button, PageHeader } from 'components/common'
import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchEligibleParticipants,
  fetchReimbursementCardTypes,
  fetchReimbursementCatAndCap,
  fetchReimbursementConfiguration,
  onFieldChange,
  onSubmitCardConfigurations,
  resetCardConfiguration,
  setCardConfigurationForm,
  setUpdatedFields
} from 'store/ducks/configuration/cardConfiguration'

import { Box } from '@mui/system'

import { validationMapper } from 'components/helper/validationEngine'
import { capTypesIds, cardConfigurationFields } from 'components/helper/constants/configuration'
import { formElements } from 'components/helper/constants/common'
import CardConfigurationForm from './CardConfigurationForm'
import CatAndCapGrid from './CatAndCapGrid'

const ActionGrid = styled(Grid)(({ theme }) => ({
  '&&': {
    button: {
      marginRight: theme.spacing(2)
    }
  }
}))
const CatAndCapTitleGrid = styled(Grid)(({ theme }) => ({
  '&&': {
    marginTop: theme.spacing(4)
  }
}))

const DropdownGrid = styled(Grid)(() => ({
  '&&': {
    '.MuiAutocomplete-root': {
      width: '50%'
    }
  }
}))

const validationSchema = isCapTypeApprovalAndReview => validationMapper([{
  id: cardConfigurationFields.approvalRequiredServices,
  isResponseRequired: true,
  answerType: formElements.multiSelectDropdown,
  dependency: {
    elements: [],
    callBack: () => isCapTypeApprovalAndReview
  }
}, {
  id: cardConfigurationFields.capType,
  isResponseRequired: true,
  answerType: formElements.dropdownMenu,
}])

const TCNCard = () => {
  const dispatch = useDispatch()
  const {
    cardConfigurationForm,
    reimbursementCatAndCapHeaders,
    reimbursementCountries,
    reimbursementConfiguration,
    updatedFields,
    categoryAndCap,
  } = useSelector(store => store.cardConfiguration)

  const fetchAllData = async () => {
    await dispatch(fetchReimbursementConfiguration())
    await dispatch(fetchEligibleParticipants())
    await dispatch(fetchReimbursementCardTypes())
  }
  useEffect(() => {
    fetchAllData()
    return () => {
      dispatch(resetCardConfiguration())
    }
  }, [])

  // eslint-disable-next-line max-params
  const onChange = (field, fieldType, selectedValues, reason, options, setFieldValue) => {
    let newSelectedValue
    let isValueChanged = false
    const existingValue = reimbursementConfiguration.results[field]

    if (reason === 'selectOption' || reason === 'removeOption') {
      newSelectedValue = selectedValues.find(option => option.id === 'select-all')
        ? options
        : selectedValues
    } else if (reason === 'clear') {
      newSelectedValue = []
    }

    const arrayOfSelectedIds = newSelectedValue.map(value => value.id)
    // because participating countries is coming array of IDs and not array of object, we are doing this check
    const areIdsEqual =
        existingValue &&
        existingValue.length === arrayOfSelectedIds.length &&
        existingValue.every(value =>
          typeof value === 'object'
            ? arrayOfSelectedIds.includes(value.id)
            : arrayOfSelectedIds.includes(value)
        )

    isValueChanged = !areIdsEqual

    setFieldValue(field, newSelectedValue)
    dispatch(setCardConfigurationForm({ ...cardConfigurationForm, [field]: newSelectedValue }))
    // Dynamically fetch category and cap grid values

    dispatch(fetchReimbursementCatAndCap(newSelectedValue))

    dispatch(
      onFieldChange({
        fieldName: field,
        fieldType,
        isChanged: isValueChanged
      })
    )
  }

  const isFormChanged = updatedFields.some(field => !!field.isChanged)

  return (
    <PageHeader
      title=<Typography variant="h4" gutterBottom>
        TCN Card
      </Typography>
      content={
        reimbursementCountries.isLoading ||
        reimbursementCountries.results.length === 0 ||
        reimbursementCatAndCapHeaders.isLoading ||
        !reimbursementConfiguration.results ||
        categoryAndCap.isLoading
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          )
          : (
            <Formik
              initialValues={cardConfigurationForm}
              enableReinitialize={true}
              validationSchema={validationSchema(cardConfigurationForm.capType?.id === capTypesIds.reviewAndApprove)}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(onSubmitCardConfigurations(values))
                setSubmitting(false)
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2}>
                    <CardConfigurationForm values={values} setFieldValue={setFieldValue} />
                    <CatAndCapTitleGrid item xs={12} sm={12} md={12} lg={12}>
                      <Typography gutterBottom variant="subtitle1">
                      Payment and Reimbursement Categories and Limits
                      </Typography>
                    </CatAndCapTitleGrid>
                    <DropdownGrid item xs={12} sm={12} md={6} lg={6}>
                      <AutoCompleteWithLabel
                        multiple={true}
                        hasSelectAllOption={true}
                        size="small"
                        id={cardConfigurationFields.participatingCountries}
                        name={cardConfigurationFields.participatingCountries}
                        label="Participating Countries"
                        options={reimbursementCountries.results}
                        isLoading={reimbursementCountries.isLoading}
                        onChange={(event, selectedValues, reason) =>
                          onChange(
                            cardConfigurationFields.participatingCountries,
                            formElements.multiSelectDropdown,
                            selectedValues,
                            reason,
                            reimbursementCountries.results,
                            setFieldValue
                          )
                        }
                      />
                    </DropdownGrid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CatAndCapGrid values={values} setFieldValue={setFieldValue} />
                    </Grid>
                    <ActionGrid item xs={12} sm={12} md={12} lg={12}>
                      <Button type="submit" color="primary" disabled={!isFormChanged}>
                      Save
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => {
                          dispatch(setUpdatedFields([]))
                          dispatch(fetchReimbursementConfiguration())
                        }}
                        disabled={!isFormChanged}
                      >
                      Cancel
                      </Button>
                    </ActionGrid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )
      }
      expandContent={true}
    />
  )
}

export default TCNCard
