import { createAction, createReducer } from '@reduxjs/toolkit'
import { getMediaReferralsConfigDetails, postMediaReferralsConfigDetails } from 'services/configuration/mediaReferralsConfiguration'
import { showError } from '../application'
import { copyObject } from 'components/helper/utility'
import { setLoading } from '../userInteractions'

export const setMediaReferralsConfiguration = createAction('mediaReferralsConfiguration/setMediaReferralsConfiguration')
export const setEditMediaReferralConfig = createAction('mediaReferralConfiguration/setEditMediaReferralConfig')
export const resetMediaReferralsConfiguration = createAction('mediaReferralsConfiguration/resetMediaReferralsConfiguration')

export const fetchMediaReferralsConfiguration = () => async dispatch => {
  try {
    dispatch(setMediaReferralsConfiguration({ isLoading: true }))
    const { data } = await getMediaReferralsConfigDetails()
    dispatch(setMediaReferralsConfiguration({
      isLoading: false,
      results: {
        participatingCountries: data
      }
    }))
  } catch (e) {
    dispatch(setMediaReferralsConfiguration(initialState.mediaReferralsConfiguration))
    dispatch(showError('There was issue while trying to fetch Media Referrals Configurations', e))
  }
}

export const submitMediaReferralsConfiguration = values => async dispatch => {
  dispatch(setLoading(true))
  try {
    const payload = { countryIDs: values.participatingCountries.map(country => country.id) }
    await postMediaReferralsConfigDetails(payload)
    await dispatch(fetchMediaReferralsConfiguration())
    dispatch(setEditMediaReferralConfig(false))
  } catch (e) {
    dispatch(showError('There was issue while trying to fetch Media Referrals Configurations', e))
  }
  dispatch(setLoading(false))
}

const initialState = {
  mediaReferralsConfiguration: {
    results: { participatingCountries: [] },
    isLoading: false
  },
  editMediaReferralConfig: false

}

export default createReducer(initialState, builder => {
  builder
    .addCase(setMediaReferralsConfiguration, (state, { payload }) => {
      state.mediaReferralsConfiguration = { ...state.mediaReferralsConfiguration, ...payload }
    })
    .addCase(setEditMediaReferralConfig, (state, action) => {
      state.editMediaReferralConfig = action.payload
    })
    .addCase(resetMediaReferralsConfiguration, state => {
      copyObject(state, initialState)
    })
})
