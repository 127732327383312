import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { palette } from 'styles/baseTheme'

import { DataGrid } from 'components/common'

import RenderHTML from 'components/common/dataDisplay/RenderHTML'
import { Chip } from '@mui/material'

const Container = styled.div(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(3)};
`
)

const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}

const SitesListWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    '.MuiChip-root': {
      width: 'fit-content',
      margin: theme.spacing(0.5)
    }
  }
}))

const headers = [
  {
    id: 'question',
    label: 'Question',
    bodyProperties: {
      width: '30%',
      textAlign: 'left',
      cellType: 'node'
    },
    headProperties: { ...headerProperties, textAlign: 'left' }
  },
  {
    id: 'answerType',
    label: 'Answer Type',
    bodyProperties: {
      width: '10%',
      cellType: 'html'
    },
    headProperties: { ...headerProperties }
  },
  {
    id: 'options',
    label: 'Answer Options',
    bodyProperties: {
      width: '10%',
      cellType: 'html'
    },
    headProperties: { ...headerProperties }
  },
  {
    id: 'isResponseRequired',
    label: 'Required',
    bodyProperties: {
      width: '10%'
    },
    headProperties: { ...headerProperties }
  },
  {
    id: 'associatedSites',
    label: 'Associated Sites',
    bodyProperties: {
      cellType: 'node',
      width: '40%',
      textAlign: 'left'
    },
    headProperties: { ...headerProperties, textAlign: 'left' }
  }
]

const ViewQuestions = ({ questions }) => (
  <Container>
    <DataGrid
      headers={headers}
      tableData={questions.map(question => ({
        question: <RenderHTML htmlContent={question.question} />,
        answerType: question.answerType,
        options: question.options?.join(', '),
        isResponseRequired: question.isResponseRequired ? 'Yes' : 'No',
        associatedSites: (
          <SitesListWrapper>
            {question.associatedSites.map((site, index) => (
              <Chip variant="outlined" size="small" key={index} label={site} />
            ))}
          </SitesListWrapper>
        )
      }))}
      setRequestedSort={() => {}}
      order={'DESC'}
      orderBy={'createdDate'}
      border="allSides"
      radius="false"
    />
  </Container>
)

ViewQuestions.propTypes = {
  questions: PropTypes.array
}

ViewQuestions.defaultProps = {
  questions: []
}

export default ViewQuestions
