import { createAction, createReducer } from '@reduxjs/toolkit'
import { showError, showSuccess } from '../application'
import {
  acceptTermsAndConditions,
  getCardDataToken,
  getReplaceOrReissueReasonsLookup,
  getTransactionHistory,
  postCancelAndReplace,
  postCardHistoryAudit
} from 'services/patientRecord/manageAccount'
import {
  checkLocalCurrency,
  fetchCardBackDetails,
  fetchCardBalance,
  fetchCardFrontDetails,
  fetchCardStatus,
  fetchCardTrackingNumber,
  getFundsHistory,
  getPQUserAgreements
} from 'services/patientRecord/reimbursement'
import { setLoading } from '../userInteractions'
import { copyObject, formatCurrency, sortList, displayDate } from 'components/helper/utility'
import {
  cardStatus,
  getCardStatus,
  reimbursementAccordions
} from 'components/helper/constants/reimbursement'
import {
  fetchPatientSupportPersonsForReimbursement,
  getAccounts,
  setIsLocalCurrency
} from './reimbursement'
import { helperAttachmentModuleNames } from 'components/helper/constants/common'
import { getHelperAttachments } from 'services/common'
import { getClientIp, getPQCardData } from 'services/patientRecord/payQuicker'
import fileDownload from 'js-file-download'
import { downloadHelperAttachment } from '../common'

export const setCardDetailsManageAccount = createAction('manageAccount/setCardDetailsManageAccount')
export const setTransHistory = createAction('manageAccount/setTransHistory')
export const setSelectedAccountManageAccount = createAction(
  'manageAccount/setSelectedAccountManageAccount'
)
export const setSelectedPersonDetailsManageAccount = createAction(
  'manageAccount/setSelectedPersonDetailsManageAccount'
)
export const setRequestedSortForTransHistory = createAction(
  'manageAccount/setRequestedSortForTransHistory'
)
export const resetManageAccount = createAction('manageAccount/resetManageAccount')
export const setTermsAndConditionsAttachments = createAction(
  'manageAccount/setTermsAndConditionsAttachments'
)
export const setReplaceOrReissueReasonsLookup = createAction(
  'manageAccount/setReplaceOrReissueReasonsLookup'
)
export const setCardTranslationDocuments = createAction('manageAccount/setCardTranslationDocuments')

export const fetchAccountHoldersCardDetails = reimbursementCardId => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    dispatch(setCardDetailsManageAccount({ results: initialValueCardDetailsManageAccount, isLoading: true }))
    const { instanceConfig } = getState().instanceConfiguration
    const { selectedAccountManageAccount } = getState().manageAccount
    let payload = {
      ...initialValueCardDetailsManageAccount,
      reimbursementCardId
    }

    let frontResponse,
      backResponse,
      statusResponse,
      trackingResponse,
      availableBalance,
      transactionHistoryResponse

    if (instanceConfig.results.CardVendor) {
      if (
        instanceConfig.results.CardVendor.toLowerCase() ===
        process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase()
      ) {
        try {
          const origin = process.env.REACT_APP_APPLICATION_ORIGIN
          const { ip } = await getClientIp()
          if (ip) {
            const { data } = await getCardDataToken(reimbursementCardId, 'TEXT', '', origin, ip)
            const { accessToken, token, userToken, cardToken } = data
            const cardDataResponse = await getPQCardData(
              accessToken,
              token,
              userToken,
              cardToken,
              origin,
              'TEXT'
            )
            frontResponse = {
              san: cardDataResponse.cardNumber,
              cvv: cardDataResponse.cvvNumber,
            }
          }
        } catch (e) {
          dispatch(showError('There was an error while trying to get card details', e))
        }
      } else {
        try {
          const cardDetailsRes = await fetchCardFrontDetails(reimbursementCardId)
          frontResponse = {
            ...cardDetailsRes
          }
        } catch (e) {
          dispatch(showError('There was error while trying to fetch card details.', e))
        }
        try {
          backResponse = await fetchCardBackDetails(reimbursementCardId)
        } catch (e) {
          dispatch(showError('There was error while trying to fetch card details.', e))
        }

        try {
          trackingResponse = await fetchCardTrackingNumber(reimbursementCardId)
        } catch (e) {
          dispatch(showError('There was error while trying to fetch card details.', e))
        }
      }

      try {
        statusResponse = await fetchCardStatus(reimbursementCardId)
        statusResponse = {
          ...statusResponse,
          cardStatus: getCardStatus(statusResponse.cardStatus)
        }
      } catch (e) {
        dispatch(showError('There was error while trying to fetch card details.', e))
      }

      try {
        if (statusResponse.cardStatus !== cardStatus.suspend) {
          availableBalance = await fetchCardBalance(reimbursementCardId)
        }
      } catch (e) {
        dispatch(showError('There was error while trying to fetch card details.', e))
      }
      try {
        transactionHistoryResponse = await getFundsHistory(reimbursementCardId)
      } catch (e) {
        dispatch(showError('There was error while trying to fetch card details.', e))
      }
      let totalReimbursement = 0
      try {
        if (transactionHistoryResponse) {
          const isLocalCurrency = await checkLocalCurrency(reimbursementCardId)
          dispatch(setIsLocalCurrency(isLocalCurrency))
          totalReimbursement = transactionHistoryResponse.reduce(
            (sum, reimbursement) =>
              sum + (isLocalCurrency ? reimbursement.Total : reimbursement.TotalInCardCurrency),
            0
          )
        }
      } catch (e) {
        dispatch(showError('There was error while trying to fetch card details.', e))
      }

      ;[frontResponse, backResponse, statusResponse, trackingResponse].forEach(response => {
        payload = {
          ...payload,
          ...response,
          lastFourDigits: selectedAccountManageAccount?.lastFourDigits,
          availableBalance: formatCurrency(availableBalance),
          totalReimbursement: formatCurrency(totalReimbursement)
        }
      })
    }

    dispatch(setCardDetailsManageAccount({ results: payload, isLoading: false }))
  } catch (e) {
    dispatch(setCardDetailsManageAccount({ isLoading: false }))
    dispatch(showError('There was some issue fetching card details. Please try again.', e))
  }
  dispatch(setLoading(false))
}

export const fetchTransactionHistory =
  (reimbursementCardId, month, year) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const res = await getTransactionHistory(reimbursementCardId, month, year)
      await postCardHistoryAudit(reimbursementCardId)
      const finalRes = res.transactions.map(record => ({
        ...record,
        date: displayDate(record.date),
        amount: `${formatCurrency(record.amount)} ${record.currency}`,
        description:
          record.description.indexOf('(Txn') > -1
            ? record.description.substring(0, record.description.indexOf('(Txn')) +
              record.description.substring(record.description.length)
            : record.description
      }))
      const { transHistory } = getState().manageAccount
      dispatch(
        setTransHistory({
          results: sortList(finalRes, transHistory.order, transHistory.orderBy),
          totalCount: finalRes.totalCount
        })
      )
    } catch (err) {
      if (err.response && err.response.status === 404) {
        dispatch(showError('Something went wrong!'))
      } else {
        dispatch(
          showError(
            'There was some issue while trying to fetch Transaction history. Please try again later.'
          )
        )
      }
    }
    dispatch(setLoading(false))
  }

export const handleTransactionHistorySort = orderByValue => (dispatch, getState) => {
  dispatch(setRequestedSortForTransHistory(orderByValue))
  const { transHistory } = getState().manageAccount

  dispatch(
    setTransHistory({
      results: sortList(transHistory.results, transHistory.order, transHistory.orderBy),
      totalCount: transHistory.totalCount
    })
  )
}

export const fetchTermsAndConditionsAttachments = reimbursementCardId => async (dispatch, getState) => {
  try {
    const { termsAndConditionsAttachments } = getState().manageAccount
    const { helperAttachmentModules } = getState().common
    const { instanceConfig } = getState().instanceConfiguration
    dispatch(setTermsAndConditionsAttachments({ isLoading: true }))

    if (instanceConfig.results.CardVendor) {
      if (
        instanceConfig.results.CardVendor.toLowerCase() ===
        process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase()
      ) {
        const { data } = await getPQUserAgreements(reimbursementCardId)
        const results = data.payload.map(attachment => ({
          read: false,
          name: attachment.type,
          url: attachment.url
        }))
        dispatch(setTermsAndConditionsAttachments({ results }))
      } else {
        if (helperAttachmentModules.results.length) {
          // if Card Terms Helper Attachments are empty, fetch
          if (termsAndConditionsAttachments.results.length === 0) {
            // Get Card Terms helper module by filtering by name
            const cardTermsModule = helperAttachmentModules.results.filter(
              helperAttachmentModule =>
                helperAttachmentModule.displayText ===
                helperAttachmentModuleNames.cardTermsAndConditions
            )

            // if Module is present, fetch helper attachments with attachmentModuleId
            if (cardTermsModule.length) {
              const results = await getHelperAttachments(cardTermsModule[0].id)
              dispatch(setTermsAndConditionsAttachments({ results }))
            }
          }
        }
      }
    }
  } catch (e) {
    dispatch(setTermsAndConditionsAttachments({ results: [] }))
    dispatch(showError('There was issue fetching Card Terms and Conditions.'))
  }

  dispatch(setTermsAndConditionsAttachments({ isLoading: false }))
}

export const onDownloadAgreement = attachment => async (dispatch, getState) => {
  try {
    const { instanceConfig } = getState().instanceConfiguration
    const { termsAndConditionsAttachments } = getState().manageAccount

    if (instanceConfig.results.CardVendor) {
      if (
        instanceConfig.results.CardVendor.toLowerCase() ===
        process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase()
      ) {
        const response = await fetch(attachment.url)
        // find the element's index by name and update the read flag to true
        const results = termsAndConditionsAttachments.results.map(item =>
          item.name === attachment.name ? { ...item, read: true } : item
        )

        dispatch(setTermsAndConditionsAttachments({ results }))

        const blob = await response.blob()
        fileDownload(blob, `${attachment.name}.pdf`)
      } else {
        dispatch(downloadHelperAttachment(attachment))
      }
    }
  } catch (e) {
    dispatch(
      showError('There was some error while trying to fetch document. Please try again later.')
    )
  }
}

export const fetchCardTranslationDocuments = () => async (dispatch, getState) => {
  try {
    const { cardTranslationDocuments } = getState().manageAccount
    const { helperAttachmentModules } = getState().common
    dispatch(setCardTranslationDocuments({ isLoading: true }))
    // if Card translation documents are empty, fetch
    if (cardTranslationDocuments.results.length === 0) {
      // Get Card translation documents by filtering by name
      const cardReferenceDocumentsModule = helperAttachmentModules.results.filter(
        helperAttachmentModule =>
          helperAttachmentModule.displayText === helperAttachmentModuleNames.cardReferenceDocuments
      )

      // if Module is present, fetch helper attachments with attachmentModuleId
      if (cardReferenceDocumentsModule.length) {
        const results = await getHelperAttachments(cardReferenceDocumentsModule[0].id)
        dispatch(setCardTranslationDocuments({ results }))
      }
    }
  } catch (e) {
    dispatch(showError('There was issue fetching Card Translations documents.'))
  }

  dispatch(setCardTranslationDocuments({ isLoading: false }))
}

export const onAcceptingTermsAndConditions = reimbursementCardId => async (dispatch, getState) => {
  try {
    dispatch(
      setLoading({
        showLoading: true,
        loadingCriteria: {
          module: 'manageAccount',
          methodName: 'onAcceptingTermsAndConditions',
          loadingText:
            'A TCN Card account is being created. This may take up to one minute. Thank you for your patience. '
        }
      })
    )
    await acceptTermsAndConditions(reimbursementCardId)
    const { patientInformationForReimbursement } = getState().reimbursement
    await dispatch(getAccounts(patientInformationForReimbursement.userId))
    dispatch(showSuccess('Success! You have accepted the Terms & Conditions and Privacy Policy.'))
  } catch (e) {
    dispatch(showError('Error! while trying to submit terms and conditions agreement.', e))
  }
  dispatch(
    setLoading({
      showLoading: false,
      loadingCriteria: {
        module: 'manageAccount',
        methodName: 'onAcceptingTermsAndConditions',
      }
    })
  )
}

export const cancelAndReplaceTCNCard =
  (reimbursementCardId, replaceOrReissueReasonId, onSuccess) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      await postCancelAndReplace(reimbursementCardId, replaceOrReissueReasonId)
      const { patientInformationForReimbursement } = getState().reimbursement
      const { patientInformation } = getState().patientMainWrapper
      if (patientInformationForReimbursement.userId) {
        await dispatch(
          fetchPatientSupportPersonsForReimbursement(patientInformationForReimbursement.userId)
        )
        dispatch(getAccounts(patientInformation.userId, reimbursementAccordions.manageAccount))
      }

      onSuccess()
      dispatch(showSuccess('Replace / Reissue complete!'))
    } catch (e) {
      dispatch(showError('Error! Replace or Reissue of Card failed.', e))
    }
    dispatch(setLoading(false))
  }

export const fetchReplaceOrReissueReasonsLookup = () => async (dispatch, getState) => {
  try {
    const { replaceOrReissueReasonsLookup } = getState().manageAccount
    if (replaceOrReissueReasonsLookup.length === 0) {
      const { data } = await getReplaceOrReissueReasonsLookup()
      dispatch(setReplaceOrReissueReasonsLookup(data))
    }
  } catch (e) {
    dispatch(showError('Error! When fetching Replace or Reissue Reasons', e))
  }
}

export const initialValueCardDetailsManageAccount = {
  reimbursementCardId: '',
  expiry: '',
  cardStatus: '',
  cardCurrency: '',
  cardCurrencySymbol: '',
  trackingNumber: '',
  availableBalance: 0,
  totalReimbursement: 0,
  cvv: '',
  cardholderEmail: '',
  cardType: '',
  name: '',
  cardImageLink: '',
  san: '',
  status: 0,
  description: ''
}

export const initialState = {
  cardDetailsManageAccount: { results: initialValueCardDetailsManageAccount, isLoading: false },
  transHistory: { isLoading: false, results: [], totalCount: 0, order: 'DESC', orderBy: 'date' },
  selectedAccountManageAccount: null,
  selectedPersonDetailsManageAccount: {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: { id: '', displayText: '' },
    dateOfBirth: '',
    emailAddress: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    country: { id: '', displayText: '' },
    state: { id: '', displayText: '' },
    preferredLanguage: null,
    patientCommunicationPreferences: [],
    isReimbursementAcknowledged: false,
    isRestrictedCountry: false
  },
  termsAndConditionsAttachments: { isLoading: false, results: [] },
  replaceOrReissueReasonsLookup: [],
  cardTranslationDocuments: { isLoading: false, results: [] }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setCardDetailsManageAccount, (state, action) => {
      state.cardDetailsManageAccount = { ...state.cardDetailsManageAccount, ...action.payload }
    })
    .addCase(setTransHistory, (state, action) => {
      state.transHistory = { ...state.transHistory, ...action.payload }
    })
    .addCase(setRequestedSortForTransHistory, (state, action) => {
      const isDesc =
        state.transHistory.orderBy === action.payload && state.transHistory.order === 'DESC'
      state.transHistory.order = isDesc ? 'ASC' : 'DESC'
      state.transHistory.orderBy = action.payload
    })
    .addCase(setSelectedAccountManageAccount, (state, action) => {
      state.selectedAccountManageAccount = action.payload
    })
    .addCase(setSelectedPersonDetailsManageAccount, (state, action) => {
      state.selectedPersonDetailsManageAccount = action.payload
    })
    .addCase(setTermsAndConditionsAttachments, (state, action) => {
      state.termsAndConditionsAttachments = {
        ...state.termsAndConditionsAttachments,
        ...action.payload
      }
    })
    .addCase(setReplaceOrReissueReasonsLookup, (state, action) => {
      state.replaceOrReissueReasonsLookup = action.payload
    })
    .addCase(setCardTranslationDocuments, (state, action) => {
      state.cardTranslationDocuments = { ...state.cardTranslationDocuments, ...action.payload }
    })
    .addCase(resetManageAccount, state => {
      copyObject(state, initialState)
    })
})
