import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { removeTime, copyObject } from 'components/helper/utility'

import { getTCNUsers } from 'services/visit'
import { addRemoteSupportTeleHealthRequest, getTelehealthSupportDetails } from 'services/rsgArrive'
import { getAllSupportPersons, getPatientInformation } from 'services/patientRecord/patientProfile'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import { setOpenSubmitSuccess, setStartNewRequestForVisitFlow } from './requestDetails'
import { fetchListOfPatients } from '../patientExperienceManager'

const setVisitParticipants = createAction('telehealthVisit/setVisitParticipants')
export const setTeleHealthSupportDetailsForm = createAction(
  'telehealthVisit/setTeleHealthSupportDetailsForm'
)
export const resetTeleHealthSupport = createAction('telehealthVisit/resetTeleHealthSupport')

export const setupTeleHealthRequestDetailsForm = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    dispatch(setVisitParticipants({ isLoading: true }))
    const { patientInformation } = getState().patientMainWrapper

    let allParticipants = []

    const patientInfoResponse = await getPatientInformation(patientInformation.userId)

    if (patientInfoResponse.data) {
      allParticipants.push({
        ...patientInfoResponse.data,
        id: patientInfoResponse.data.userId,
        role: 'Patient',
        emailAddress: patientInfoResponse.data?.emailAddress || '',
        phoneNumber: patientInfoResponse.data?.phoneNumber || ''
      })
    }

    const supportPersonsResponse = await getAllSupportPersons(patientInformation.userId)

    // Backend return null if no support persons
    if (supportPersonsResponse.data?.length) {
      allParticipants = allParticipants.concat(
        supportPersonsResponse.data.map(person => ({
          ...person,
          id: person.userSupportPersonId,
          role: person.userSupportPersonType?.displayText,
          emailAddress: person.emailAddress,
          phoneNumber: person.phoneNumber || ''
        }))
      )
    }

    // If siteClientId is empty dispatch call which gets it
    const applicationStore = getState().application
    let siteClientId
    if (applicationStore.siteClientId) {
      siteClientId = applicationStore.siteClientId
    } else {
      // from argument is for filters, and second is that this fetch patients call is happening from teleHealth module
      await dispatch(fetchListOfPatients(true))
      siteClientId = getState().application.siteClientId
    }
    const tcnUsers = await getTCNUsers(siteClientId)

    if (tcnUsers.length) {
      allParticipants = allParticipants.concat(
        tcnUsers.map(person => ({
          ...person,
          id: person.id,
          role: person.role,
          emailAddress: person.email,
          phoneNumber: person.phoneNumber || ''
        }))
      )
    }

    allParticipants.push({ fullName: 'Concierge Nurse / Health Professional', id: '0', email: '0' })

    // set initial values for phone and email address fields inside each participant's table
    let participantFormValues = {}
    allParticipants.forEach(participant => {
      participantFormValues = {
        ...participantFormValues,
        [`${participant.id}-selectedTelehealthParticipants-phoneNumber`]: participant.phoneNumber,
        [`${participant.id}-selectedTelehealthParticipants-emailAddress`]: participant.emailAddress
      }
    })

    const { telehealthSupportDetailsForm } = getState().telehealthVisit

    dispatch(
      setTeleHealthSupportDetailsForm({ ...telehealthSupportDetailsForm, ...participantFormValues })
    )

    dispatch(setVisitParticipants({ results: allParticipants, isLoading: false }))
  } catch (e) {
    dispatch(showError('Error while setting up TeleHealth request form', e))
  }
  dispatch(setLoading(false))
}

export const fetchTelehealthSupportDetails = requestId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    await dispatch(setupTeleHealthRequestDetailsForm())
    const res = await getTelehealthSupportDetails(requestId)
    const { telehealthSupportDetailsForm, visitParticipants } = getState().telehealthVisit
    let formattedTelehealthSupportDetails = {
      ...telehealthSupportDetailsForm,
      visitNames: res.visitName,
      visitDate: res.visitDate,
      visitTime: res.visitTIme,
      aprroxDuration: res.durationInMinutes,
      reachDateTime: res.bestTimeToReach || '',
      reachPhoneNumber: res.bestPhoneNumber || ''
    }

    // res.participants contains originalEmail, email and phoneNumber
    // so use originalEmail to set the value
    // create name property for the input fields(email and phone) in this format: id-selectedTelehealthParticipants-emailAddress
    const selectedTelehealthParticipants = []
    res.participants.forEach(selectedParticipant => {
      // the value for this field will be pulled from the response using visitParticipant's emailAddress
      const participantByEmail = visitParticipants.results.find(
        participant => selectedParticipant.originalEmail === participant.emailAddress
      )
      formattedTelehealthSupportDetails = {
        ...formattedTelehealthSupportDetails,
        [`${participantByEmail.id}-selectedTelehealthParticipants-emailAddress`]:
          selectedParticipant.email,
        [`${participantByEmail.id}-selectedTelehealthParticipants-phoneNumber`]:
          selectedParticipant.phone
      }
      selectedTelehealthParticipants.push(participantByEmail)
    })

    if (res.isHealthProfessionalParticipating) {
      selectedTelehealthParticipants.push({
        fullName: 'Concierge Nurse / Health Professional',
        id: '0',
        email: '0'
      })
    }

    formattedTelehealthSupportDetails = {
      ...formattedTelehealthSupportDetails,
      selectedTelehealthParticipants
    }

    dispatch(setTeleHealthSupportDetailsForm(formattedTelehealthSupportDetails))
  } catch (e) {
    dispatch(showError('Error while fetching Telehealth visit details', e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const submitTelehealthSupportRequest =
  (data, parentRequestId) => async (dispatch, getState) => {
    try {
      const { patientInformation } = getState().patientMainWrapper
      dispatch(setLoading(true))

      // Get selected participants in an array format
      const selectedTelehealthParticipants = []

      data.selectedTelehealthParticipants.forEach(participant => {
        if (participant.id !== '0') {
          // find the fields(email and phone number) of the corresponding participant from the request form
          selectedTelehealthParticipants.push({
            originalEmail: participant.emailAddress,
            email: data[`${participant.id}-selectedTelehealthParticipants-emailAddress`],
            phone: data[`${participant.id}-selectedTelehealthParticipants-phoneNumber`],
            fullName: participant.fullName,
            role: participant.role
          })
        }
      })

      const isHealthProfessionalParticipating = data.selectedTelehealthParticipants.some(
        participant => participant.id === '0'
      )

      const requestData = {
        patientId: patientInformation.userId,
        visitNameId: data.visitNames?.id,
        visitDate: removeTime(data.visitDate),
        visitTime: data.visitTime,
        durationInMinutes: data.aprroxDuration,
        selectedTelehealthParticipants,
        bestTimeToReach: data.reachDateTime,
        bestPhoneNumber: data.reachPhoneNumber,
        isHealthProfessionalParticipating,
        ...(!!parentRequestId && {
          parentRequestId
        })
      }

      await addRemoteSupportTeleHealthRequest(requestData)
      await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
      dispatch(setOpenSubmitSuccess(true))
      // Save data for StartNewRequestForVisitFlow,
      // in case user selects Yes in SubmitSuccess Confirmation box
      // This will be cleared if user selects No.
      dispatch(
        setStartNewRequestForVisitFlow({
          visitDate: removeTime(data.visitDate),
          visitNames: data.visitNames
        })
      )
      dispatch(showSuccess('Request submitted successfully!'))
    } catch (e) {
      dispatch(showError('Something went wrong!'))
    } finally {
      dispatch(setLoading(false))
    }
  }

const initialState = {
  visitParticipants: { isLoading: true, results: [] },
  telehealthSupportDetailsForm: {
    visitNames: null,
    visitDate: '',
    visitTime: '',
    aprroxDuration: '',
    selectedTelehealthParticipants: [],
    reachDateTime: '',
    reachPhoneNumber: ''
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setVisitParticipants, (state, action) => {
      state.visitParticipants = { ...state.visitParticipants, ...action.payload }
    })
    .addCase(setTeleHealthSupportDetailsForm, (state, action) => {
      state.telehealthSupportDetailsForm = action.payload
    })
    .addCase(resetTeleHealthSupport, state => {
      copyObject(state, initialState)
    })
})
