import { useContext } from 'react'
import permissionContext from './permissionsContext'
import PropTypes from 'prop-types'
import useRestriction from './useRestriction'

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restriction = ({ feature, ability, children, features, checkFor }) => {
  // We "connect" to the provider thanks to the PermissionContext
  const { isAllowedTo } = useContext(permissionContext)

  // If the user has that permission, render the children
  if (isAllowedTo({ ability, feature })) {
    return <>{children}</>
  }

  // If multiple permissions has to be checked to render the children
  // both features & checkFor props needs to be passed
  if (features && features.length) {
    let permission = false
    if (checkFor === 'all') permission = features.every(i => useRestriction('read', i))
    else permission = features.some(i => useRestriction('read', i))
    if (permission) return <>{children}</>
  }

  // Otherwise, do not render anything
  return null
}

Restriction.propTypes = {
  feature: PropTypes.string,
  ability: PropTypes.oneOf(['read', 'edit']),
  children: PropTypes.node || PropTypes.string,
  features: PropTypes.array,
  checkFor: PropTypes.oneOf(['all', 'any']),
}

Restriction.defaultProps = {
  ability: 'read',
  features: [],
  checkFor: 'any',
}

export default Restriction
