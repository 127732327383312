import React from 'react'
import { Grid, InputAdornment } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { Button, TextBox } from 'components/common'
import { Search } from '@mui/icons-material'
import { fetchListOfInstances } from 'store/ducks/auth'

// import * as Yup from 'yup'

const Wrapper = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing(2, 2, 2, 2)};
`,
)

const ActionWrapper = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-self: center;
    max-width: 200px;
  }
`
const SearchWrapper = styled(Grid)`
  align-self: center;
`

const StudyListFilters = () => {
  const dispatch = useDispatch()

  // todo: add some kind of validation here, so that user should get a message for filling out form before clicking submit
  // const validations = Yup.object().shape({
  //   sites: Yup.string(),
  //   countries: Yup.string(),
  //   sources: Yup.string(),
  //   participationProgresses: Yup.string(),
  //   AorB: Yup.bool().when(['sites', 'countries', 'sources', 'participationProgresses'], {
  //     is: (sites, countries, sources, participationProgresses) =>
  //       (!sites && !countries && !sources && !participationProgresses) ||
  //       (!!sites && !!countries && !!sources && !!participationProgresses),
  //     then: Yup.bool().required('some error msg'),
  //     otherwise: Yup.bool(),
  //   }),
  // })

  return (
    <Wrapper>
      <Formik
        initialValues={{ search: '' }}
        enableReinitialize={true}
        // validationSchema={validations}
        // onSubmit={(values, { setSubmitting }) => {
        //   dispatch(fetchListOfPatients(values))
        //   setSubmitting(false)
        // }}
      >
        {({ values, resetForm, setFieldValue }) => (
          <Form>
            <Grid container spacing={1}>
              <SearchWrapper item xs={12} sm={6} md={6} lg={4}>
                <TextBox
                  variant="outlined"
                  id="patient-list-search"
                  name="search"
                  label="Search by Project Name or Sponsor / CRO Project ID"
                  size="medium"
                  value={values.search}
                  onChange={e => {
                    setFieldValue('search', e.target.value)
                    dispatch(fetchListOfInstances({ ...values, search: e.target.value }))
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  rounded="true"
                />
              </SearchWrapper>
              <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
                <Button fullWidth={false} color="inherit" onClick={() => {
                  resetForm()
                  setFieldValue('search', '')
                  dispatch(fetchListOfInstances())
                }}>
                  Clear
                </Button>
              </ActionWrapper>
            </Grid>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default StudyListFilters
