import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { submitSiteAddress } from 'store/ducks/siteManagement/siteManagement'
import { Grid } from '@mui/material'
import StatesDropdown from 'components/common/form/StatesDropdown'
import QuickView from 'components/common/QuickView'
import VerifiedDetails from './VerifiedDetails'
import { TextBoxOuterLabel, AutoCompleteWithLabel, Button, Checkbox } from 'components/common'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import { addressTypes } from 'components/helper/constants/patientExperienceManager'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`
const VerifiedGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const validationSchema = ({
  isNoStatesForCountry,
  addressToEdit,
  showIsVerifiedCheckbox = false
}) =>
  validationMapper([
    { id: 'addressType', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'locationName', isResponseRequired: true, answerType: formElements.openText },
    { id: 'address1', isResponseRequired: true, answerType: formElements.openText },
    { id: 'address2', isResponseRequired: false, answerType: formElements.openText },
    { id: 'city', isResponseRequired: true, answerType: formElements.openText },
    {
      id: 'state',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
      dependency: {
        elements: [],
        callBack: () => !isNoStatesForCountry
      }
    },
    { id: 'country', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'zipCode', isResponseRequired: true, answerType: formElements.openText },
    { id: 'phone', isResponseRequired: true, answerType: formElements.openText },
    {
      id: 'attentionOfContact',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu,
      dependency: {
        elements: ['addressType'],
        callBack: addressType =>
          // when individual user option is selected
          (addressToEdit?.id && addressToEdit.addressType?.id === addressTypes.shipping) ||
          addressType?.id === addressTypes.shipping
      }
    },
    {
      id: 'isAddressVerified',
      isResponseRequired: showIsVerifiedCheckbox,
      answerType: formElements.checkbox
    }
  ])

const AddressForm = ({ addressToEdit, onSubmitForm }) => {
  const dispatch = useDispatch()
  const formikRef = useRef(null)
  const { countriesLookup } = useSelector(store => store.common)
  const { addressLookup } = useSelector(store => store.siteManagement)
  const { tcnUsersLookup } = useSelector(store => store.application)
  const [isReadOnly, setIsReadOnly] = useState(!!addressToEdit?.addressId)
  const [showVerifiedDetails, setShowVerifiedDetails] = useState(false)
  const [isNoStatesForCountry, setIsNoStatesForCountry] = useState(false)
  const { loggedInAsSite } = useSelector(store => store.auth)

  const cancelForm = addressId => {
    onSubmitForm ? onSubmitForm(addressId) : setIsReadOnly(true)
  }

  const noStatesFoundForSelectedCountry = noStatesForCountry => {
    setIsNoStatesForCountry(noStatesForCountry)
    formikRef.current.validateForm()
  }

  const isNotBusinessAddress =
    addressToEdit.addressId && addressToEdit.addressType.id !== addressTypes.business

  const showIsVerifiedCheckbox =
    isNotBusinessAddress &&
    loggedInAsSite &&
    addressToEdit.addressId &&
    addressToEdit?.isAddressVerified !== true

  return (
    <>
      <Formik
        initialValues={addressToEdit}
        innerRef={formikRef}
        enableReinitialize={true}
        validationSchema={validationSchema({
          isNoStatesForCountry,
          addressToEdit,
          showIsVerifiedCheckbox
        })}
        onSubmit={values => dispatch(submitSiteAddress(values, cancelForm))}
      >
        {({ values, handleReset, dirty }) => (
          <Form>
            <Grid container spacing={2}>
              {!addressToEdit.addressId && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AutoCompleteWithLabel
                    id="addressType"
                    label="Address Type"
                    name="addressType"
                    options={addressLookup}
                    size="medium"
                    disabled={isReadOnly}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextBoxOuterLabel
                  size="medium"
                  id="locationName"
                  label="Location Name"
                  name="locationName"
                  type="text"
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextBoxOuterLabel
                  size="medium"
                  id="address1"
                  label="Address Line 1"
                  name="address1"
                  type="text"
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextBoxOuterLabel
                  size="medium"
                  id="address2"
                  label="Address Line 2"
                  name="address2"
                  type="text"
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextBoxOuterLabel
                  size="medium"
                  id="city"
                  label="City / Town"
                  name="city"
                  type="text"
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <AutoCompleteWithLabel
                  id="country"
                  label="Country"
                  name="country"
                  options={countriesLookup.results}
                  isLoading={countriesLookup.isLoading}
                  size="medium"
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextBoxOuterLabel
                  size="medium"
                  id="zipCode"
                  label="Zip / Postal Code"
                  name="zipCode"
                  type="text"
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <StatesDropdown
                  id="state"
                  label="State / Province"
                  name="state"
                  size="medium"
                  selectedCountry={values.country?.id}
                  noStatesFoundForSelectedCountry={noStatesFoundForSelectedCountry}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextBoxOuterLabel
                  size="medium"
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  type="text"
                  disabled={isReadOnly}
                />
              </Grid>
              {values.addressType?.id === addressTypes.shipping && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <AutoCompleteWithLabel
                    id="attention-of"
                    label="Attention Of"
                    name="attentionOfContact"
                    options={tcnUsersLookup.results}
                    isLoading={tcnUsersLookup.isLoading}
                    matchWith="alternateIdentifier"
                    disabled={isReadOnly}
                    size="medium"
                  />
                </Grid>
              )}
              {showIsVerifiedCheckbox && (
                // Show Verified checkbox only for Site AND
                // And only for Edit form and not on New form
                <VerifiedGrid item xs={12} sm={12} md={6} lg={6}>
                  <Checkbox
                    name="isAddressVerified"
                    id="isAddressVerified"
                    label="Verified"
                    size="small"
                    disabled={isReadOnly}
                  />
                </VerifiedGrid>
              )}
              {isNotBusinessAddress &&
                (addressToEdit?.isAddressVerified === true
                  ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        size="large"
                        variant="text"
                        fullWidth={false}
                        color="primary"
                        onClick={() => {
                          setShowVerifiedDetails(true)
                        }}
                      >
                      Verified
                      </Button>
                    </Grid>
                  )
                  : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        size="large"
                        variant="text"
                        fullWidth={false}
                        color="primary"
                        disabled={true}
                      >
                      Pending Verification
                      </Button>
                    </Grid>
                  ))}

              {isReadOnly
                ? (
                  isNotBusinessAddress && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        color="primary"
                        aria-label="Edit"
                        onClick={() => setIsReadOnly(false)}
                        size="large"
                      >
                      Edit
                      </Button>
                    </Grid>
                  )
                )
                : (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ActionWrapper>
                      <Button
                        disabled={!dirty}
                        size="large"
                        fullWidth={false}
                        color="primary"
                        type="submit"
                      >
                      Save
                      </Button>
                    &ensp;&ensp;
                      <Button
                        size="large"
                        color="inherit"
                        fullWidth={false}
                        onClick={() => {
                          cancelForm()
                          handleReset()
                        }}
                      >
                      Cancel
                      </Button>
                    </ActionWrapper>
                  </Grid>
                )}
            </Grid>
          </Form>
        )}
      </Formik>
      <QuickView
        title="Address Verification"
        onClose={() => {
          setShowVerifiedDetails(false)
        }}
        dialogContent={<VerifiedDetails addressDetails={addressToEdit} />}
        isDialogOpen={showVerifiedDetails}
        maxWidth="md"
      />
    </>
  )
}

AddressForm.propTypes = {
  /**
   * This will act as both initial state for the add address form and also existing address to edit
   */
  addressToEdit: PropTypes.object.isRequired,
  /**
   * A function that trigger on successfully submitting this form
   */
  onSubmitForm: PropTypes.func
}

export default AddressForm
