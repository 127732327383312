import { backendAxios, fetchWithRetries, getConfig } from '../backend'

export const getCampaignDetails = () =>
  fetchWithRetries(
    'GET',
    `/configuration/campaigndetails/?cache=${Date.now()}`,
    getConfig()
  )

export const saveCampaignDetails = updatedCampaignDetails =>
  backendAxios
    .post('/configuration/campaigndetails', updatedCampaignDetails, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))
