import { createAction, createReducer } from '@reduxjs/toolkit'
import { convertDraftToHTML, copyObject, formInitialValues, removeDuplicateItemsFromArray } from 'components/helper/utility'
import { EditorState } from 'draft-js'
import { getSitesByCountries } from 'services/common'
import {
  getAddSitePreferenceLoadData,
  getSitePreferenceConfiguration,
  getSitePreferenceQuestionData,
  getUnansweredSitePreferences,
  saveSitePreferenceQuestion
} from 'services/settings'
import { showError, showSuccess, showWarning } from '../application'
import { setSitePrefVerificationFlow } from '../patientExperienceManager'
import { setLoading } from '../userInteractions'

export const setAddQuestionFlow = createAction('sitePreferencesConfiguration/setAddQuestionFlow')
export const resetSitePreferences = createAction('sitePreferencesConfiguration/resetSitePreferences')
export const setSitePrefConfiguration = createAction('sitePreferencesConfiguration/setSitePrefConfiguration')
export const setUnansweredSitePref = createAction('sitePreferencesConfiguration/setUnansweredSitePref')
export const setSitePrefQuestions = createAction('sitePreferencesConfiguration/setSitePrefQuestions')
export const setCategory = createAction('sitePreferencesConfiguration/setCategory')
export const setAnswerType = createAction('sitePreferencesConfiguration/setAnswerType')
export const setCountries = createAction('sitePreferencesConfiguration/setCountries')
export const setAddQuestion = createAction('confirmation/setAddQuestion')
export const setSitesByCountries = createAction('sitePreferencesConfiguration/setSitesByCountries')

/* --------Travel preferences confirmation--------- */
export const fetchUnansweredSitePref = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const response = await getUnansweredSitePreferences()

    if (response && response.length > 0) {
      const initialValues = {}

      const categories = response.map(eachCategory => {
        eachCategory.questions.forEach(question => {
          initialValues[`${question.id}`] = formInitialValues(question)
        })
        return eachCategory
      })

      const finalResponse = {
        initialValues,
        categories
      }
      dispatch(setUnansweredSitePref(finalResponse))
      dispatch(setSitePrefVerificationFlow(true))
    }
  } catch (e) {
    dispatch(showWarning('There was some error, try refreshing the application.', e))
  }
  dispatch(setLoading(false))
}

export const fetchSitePreferenceQuestionsData = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const data = await getSitePreferenceQuestionData()
    const result = data
    result.sitePreferenceQuestion = { message: data.sitePreferenceQuestion }
    result.required = data.isResponseRequired
    dispatch(setSitePrefQuestions(result))
  } catch {
    dispatch(showError('There was some issue while trying to fetch site preference questions.'))
  }
  dispatch(setLoading(false))
}

export const fetchSitePrefConfiguration = () => async dispatch => {
  try {
    dispatch(setLoading(true))
    const result = await getSitePreferenceConfiguration()
    if (result && result.length > 0) {
      dispatch(setSitePrefConfiguration(result))
    }
  } catch {
    dispatch(showError('There was issue while trying to fetch configurations.'))
  }
  dispatch(setLoading(false))
}

export const fetchSitePrefLoadData = () => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setCategory({ isLoading: true }))
  dispatch(setAnswerType({ isLoading: true }))
  dispatch(setCountries({ isLoading: true }))
  try {
    const result = await getAddSitePreferenceLoadData()
    dispatch(setCategory({ results: result.categories, isLoading: false }))
    dispatch(setAnswerType({ results: result.answerTypes, isLoading: false }))
    dispatch(setCountries({ results: removeDuplicateItemsFromArray(result.countries, 'displayText'), isLoading: false }))
  } catch {
    dispatch(showError('There was error while trying to fetch load data.'))
  }
  dispatch(setLoading(false))
}

export const fetchSitesByCountries = countriesLookup => async dispatch => {
  dispatch(setSitesByCountries({ isLoading: true }))
  await getSitesByCountries(countriesLookup.map(country => country.id).toString())
    .then(res => {
      dispatch(setSitesByCountries({ results: res, isLoading: false }))
    })
    .catch(() => {
      dispatch(setSitesByCountries({ isLoading: false }))
      dispatch(showError('There was some issue while trying to fetch sites. Trying refreshing your page.'))
    })
}

export const onSubmitNewQuestion = (values, noOfOptions) => async dispatch => {
  dispatch(setLoading(true))
  try {
    let payload = {
      categoryId: values.category?.id,
      question: values.question,
      answerTypeId: values.answerType.id,
      isResponseRequired: values.answerIsRequired,
      associatedCountryIds: values.countries?.map(country => country.id),
      associatedSiteIds: values.siteAssociations?.map(site => site.id),
    }

    if (values.answerType.hasOptions) {
      payload = { ...payload, options: noOfOptions.map(option => values[option]) }
    }

    await saveSitePreferenceQuestion(payload)
    dispatch(showSuccess('Question added successfully!'))
    dispatch(setAddQuestionFlow(false))
    dispatch(setSitesByCountries({ results: [] }))
    dispatch(setAddQuestion(initialState.addQuestion))
    dispatch(fetchSitePrefConfiguration())
  } catch {
    dispatch(showError('There is some issue while trying to create a new question. Please try again.'))
  }
  dispatch(setLoading(false))
}

const initialState = {
  addQuestionFlow: false,
  addQuestion: {
    category: null,
    answerType: null,
    countries: [],
    siteAssociations: [],
    answerIsRequired: false,
    question: convertDraftToHTML(EditorState.createEmpty()),
  },
  unansweredSitePref: {
    initialValues: {},
    categories: []
  },
  sitePrefQuestions: {},
  sitePrefConfiguration: [],
  category: { results: [], isLoading: false },
  answerType: { results: [], isLoading: false },
  countries: { results: [], isLoading: false },
  sitesByCountries: { results: [], isLoading: false }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setAddQuestionFlow, (state, action) => {
      state.addQuestionFlow = action.payload
    })
    .addCase(setUnansweredSitePref, (state, action) => {
      state.unansweredSitePref = action.payload
    })
    .addCase(setSitePrefConfiguration, (state, action) => {
      state.sitePrefConfiguration = action.payload
    })
    .addCase(setSitePrefQuestions, (state, action) => {
      state.sitePrefQuestions = action.payload
    })
    .addCase(setCategory, (state, action) => {
      state.category = { ...state.category, ...action.payload }
    })
    .addCase(setAnswerType, (state, action) => {
      state.answerType = { ...state.answerType, ...action.payload }
    })
    .addCase(setCountries, (state, action) => {
      state.countries = { ...state.countries, ...action.payload }
    })
    .addCase(setAddQuestion, (state, action) => {
      state.addQuestion = action.payload
    })
    .addCase(setSitesByCountries, (state, action) => {
      state.sitesByCountries = { ...state.sitesByCountries, ...action.payload }
    })
    .addCase(resetSitePreferences, state => {
      copyObject(state, initialState)
    })
})
