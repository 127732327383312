import { createReducer, createAction } from '@reduxjs/toolkit'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { copyObject } from 'components/helper/utility'
import {
  getAdminDocuments,
  getPatientStudyToolsDocuments,
  getStudyLinks,
  getStudySiteStaffDocuments,
  getUserGuides
} from 'services/resourceCenter'
import { showError } from './application'
import { setLoading } from './userInteractions'

export const setStudyLinks = createAction('resourceCenter/setStudyLinks')
export const setAdministrativeDocuments = createAction('resourceCenter/setAdministrativeDocuments')
export const setUserGuides = createAction('resourceCenter/setUserGuides')
export const setForStudySiteStaffDocuments = createAction(
  'resourceCenter/setForStudySiteStaffDocuments'
)
export const setForPatientStudyToolsDocuments = createAction(
  'resourceCenter/setForPatientStudyToolsDocuments'
)
export const resetResourceCenter = createAction('resourceCenter/resetResourceCenter')
export const setPageNumber = createAction('resourceCenter/setPageNumber')
export const setSelectedDocument = createAction('resourceCenter/setSelectedDocument')

export const fetchAdministrativeDocuments = fetchInitialDocument => async dispatch => {
  dispatch(setLoading(true))
  await getAdminDocuments()
    .then(({ data }) => {
      const finalRes = data.map(document => ({
        // We use the copy of the object, so that the final object that we have will contain both fetchId and id
        ...document,
        fetchId: document.id,
        name: document.displayText
      }))

      dispatch(setAdministrativeDocuments(finalRes))
      if (fetchInitialDocument) {
        dispatch(
          setSelectedDocument({
            ...finalRes[0],
            category: resourceCenterCategory.administrativeDocuments,
            documentType: null,
            isViewed: true
          })
        )
      }
    })
    .catch(() => {
      dispatch(
        showError(
          'There was error while trying to fetch administrative documents. Please try again.'
        )
      )
    })
  dispatch(setLoading(false))
}

export const fetchStudyLinks = () => async dispatch => {
  dispatch(setLoading(true))
  await getStudyLinks()
    .then(({ data }) => {
      dispatch(setStudyLinks(data))
    })
    .catch(() => {
      dispatch(showError('There was an error while trying to fetch study links. Please try again.'))
    })
  dispatch(setLoading(false))
}

export const fetchUserGuides = () => async dispatch => {
  dispatch(setLoading(true))
  await getUserGuides()
    .then(({ data }) => {
      const finalRes = data.map(document => ({
        // We use the copy of the object, so that the final object that we have will contain both fetchId and id
        ...document,
        fetchId: document.id,
        name: document.displayText
      }))
      dispatch(setUserGuides(finalRes))
    })
    .catch(() => {
      dispatch(showError('There was an error while trying to fetch user guides. Please try again.'))
    })
  dispatch(setLoading(false))
}

export const fetchStudySiteStaffDocuments = siteClientId => async dispatch => {
  dispatch(setLoading(true))

  await getStudySiteStaffDocuments({ siteClientId })
    .then(({ data }) => {
      if (data?.length) {
        const finalRes = data.map((file, index) => ({
          // We use the copy of the object, so that the final object that we have will contain both fetchId and id
          ...file,
          fetchId: file.id,
          name: file.displayText ? file.displayText : `File ${index}`
        }))
        dispatch(setForStudySiteStaffDocuments(finalRes))
      }
    })
    .catch(() => {
      dispatch(
        showError(
          'There was a n error while trying to fetch study site staff documents. Please try again.'
        )
      )
    })
  dispatch(setLoading(false))
}

export const fetchPatientStudyToolsDocuments =
  (siteClientId, fetchInitialDocument) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { isPatient } = getState().auth
      let finalRes
      if (siteClientId) {
        finalRes = await getPatientStudyToolsDocuments({ siteClientId, fetchInitialDocument })
      } else {
        finalRes = await getPatientStudyToolsDocuments()
      }
      const { data } = finalRes

      finalRes = data?.map((document, index) => ({
        // We use the copy of the object, so that the final object that we have will contain both fetchId and id
        ...document,
        fetchId: document.id,
        name: document.displayText ? document.displayText : `File ${index}`
      }))
      if (finalRes) {
        dispatch(setForPatientStudyToolsDocuments(finalRes))
        if (fetchInitialDocument) {
          dispatch(
            setSelectedDocument({
              ...finalRes[0],
              category: resourceCenterCategory.forPatientsStudyToolsDocuments,
              documentType: isPatient ? 'studyTools' : null,
              isViewed: true
            })
          )
        }
      }
      dispatch(setLoading(false))
    } catch {
      dispatch(setLoading(false))
      dispatch(
        showError('There was an error while trying to fetch Patient Documents. Please try again.')
      )
    }
  }

const initialState = {
  studyLinks: [],
  administrativeDocuments: [],
  userGuides: [],
  forStudySiteStaffDocuments: [],
  forPatientsStudyToolsDocuments: [],
  selectedDocument: {},
  pageNumber: 1
}
export default createReducer(initialState, builder => {
  builder
    .addCase(setStudyLinks, (state, action) => {
      state.studyLinks = action.payload
    })
    .addCase(setAdministrativeDocuments, (state, action) => {
      state.administrativeDocuments = action.payload
    })
    .addCase(setUserGuides, (state, action) => {
      state.userGuides = action.payload
    })
    .addCase(setForStudySiteStaffDocuments, (state, action) => {
      state.forStudySiteStaffDocuments = action.payload
    })
    .addCase(setForPatientStudyToolsDocuments, (state, action) => {
      state.forPatientsStudyToolsDocuments = action.payload
    })
    .addCase(setSelectedDocument, (state, action) => {
      state.selectedDocument = action.payload
    })
    .addCase(setPageNumber, (state, action) => {
      state.pageNumber = action.payload
    })
    .addCase(resetResourceCenter, state => {
      copyObject(state, initialState)
    })
})
