export const sitePerformanceReportHeaders = {
  SiteCountry: 'Site Country',
  SponsorSiteID: 'Site ID',
  SiteStatus: 'Site Status',
  PatientsatSite: 'Total Patients at Site',
  PatientsAddedToTCN: 'Patients Added to TCN',
  PatientsWithoutSubj: 'Patients Added to TCN without Subject ID Association',
  ProgramParticipation: 'Arrive Program Participation (Y/N)',
  OrientationDate: 'Orientation Date',
  PatientsWithARequest: 'Number of Patients with at least one Request',
  PercofPatientsUsingArrive: 'Percentage of Patients using TCN Arrive',
  InPersonRequests: 'In-Person Visit Travel Requests',
  AdditionalRequests: 'Additional Requests',
  AdvanceRequests: 'Advanced Requests',
  RemoteRequests: 'Remote Requests',
  TotalRequests: 'Total Requests',
  InterpreterRequests: 'Number of Interpreter Support Requests',
  CompletedRequests: 'Number of Completed Requests',
  CancelledRequests: 'Number of Cancelled Requests',
  AvgTimeBtwReqandVisit: 'Average Time between Request Date and Visit Date (days)',
  AvgTimeBtwItineraryAvailandAppr:
    'Average Time between Itinerary Available Date and Approval Date (days)',
  PatientsReqTicketedTravel: 'Number of Participant Accounts Requiring Ticketed Travel',
  LastMinuteRequests: 'Number of Last Minute Requests (≤ 3 days)',
  ApprovalsafterVisitDate: 'Number of Approvals After Visit Date',
  ApprovalsByConcierge: 'Number of Approvals by Concierge'
}

export const summaryAndDetailsReportCommonHeaders = {
  SiteCountry: 'Site Country',
  SponsorSiteID: 'Site ID',
  TCNCreateDate: 'TCN # Created Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  CountryofResidence: 'Country of Residence',
  ParticipationStatus: 'Participation Progress / Status',
  RequestNumber: 'Request Number',
  RequestStatus: 'Request Status',
  RequestType: 'Request Type',
  SelectedServices: 'Selected Services',
  RequestDate: 'Request Date'
}

export const summaryAndDetailsSheets = {
  requestSummary: {
    VisitTitle: 'Visit Name',
    VisitDate: 'Visit Date',
    ItineraryVersion: 'Itinerary / Service Details Document Version',
    CurrentAssignment: 'Current Assignment',
    ReqNotApprovedDate: 'Request Not Approved Date',
    ReqNotApprovedBy: 'Request Not Approved By',
    ReqApprovedDate: 'Request Approved Date',
    ReqApprovedBy: 'Request Approved By',
    ReqCompletedDate: 'Request "Marked as Completed" Date',
    ReqCompletedBy: 'Request "Marked as Completed" By',
    ReqCancelledDate: 'Request Cancelled Date',
    ReqCancelledBy: 'Request Cancelled By',
    StatusWhenCancelled: 'Status When Cancelled',
    NewReqNumber: 'New Request Made Based Off of Cancelled Request'
  },

  inpRequestDetails: {
    VisitTitle: 'Visit Name',
    ItineraryVersion: 'Itinerary / Service Details Document Version',
    ItineraryDownloadedByPatientOn: 'Itinerary / Service Details Document Downloaded by Patient',
    VisitStartDate: 'Visit Start Date',
    VisitEndDate: 'Visit End Date',
    VisitArrivalTime: 'Arrival Time for This Visit',
    VisitDepartureTime: 'Approximate Departure Time from This Visit',
    NumberofTravelers: 'Number of Travelers',
    TicketedTravelNotes: 'Ticket Travel Notes',
    TicketedTravelOtherPreferences: 'Ticketed Travel: Other Relevant Considerations',
    CheckInDate: 'Recommended Check-In Date',
    CheckOutDate: 'Recommended Check-Out Date',
    IsADARoomNeeded: 'ADA Room Needed (Y/N)',
    HotelAccommodationOtherPreferences: 'Hotel Accommodations:  Other Relevant Considerations',
    GroundTransportNotes: 'Ground Transport Notes',
    MobilityAssistance: 'Mobility Assistance',
    GroundTransportOtherPreferences: 'Ground Transport:  Other Relevant Considerations',
    VisitArrivalLocation: 'Visit Arrival pick-up location',
    VisitDropoffLocation: 'Visit Arrival drop-off location',
    VisitDeparturePickupLocation: 'Visit Departure pick-up location',
    VisitDepartureDropOffLocation: 'Visit Departure drop-off location',
    BtwVisitArrivalandDeparture: 'Between Visit Arrival and Visit Departure Notes'
  },

  addRequestDetails: {
    VisitTitle: 'Visit Name',
    VisitStartDate: 'Visit Date',
    ItineraryVersion: 'Itinerary / Service Details Document Version',
    ItineraryDownloadedByPatientOn: 'Itinerary / Service Details Document Downloaded by Patient',
    InterpreterSupportNeeded: 'Interpreter Support Needed',
    InterpreterSupportNotes: 'Interpreter Support Notes',
    PatientPreferredLanguage: 'Interpreter Support: Language of Patient / Caregiver',
    StaffPreferredLanguage: 'Interpreter Support: Language of Staff Member',
    SpecialtyStayNotes: 'Longer-term Stay Support Notes',
    ProfessionalCompanionNotes: 'Professional Companion'
  },

  advRequestDetails: {
    VisitTitle: 'Visit Name',
    VisitStartDate: 'Visit Date',
    ItineraryVersion: 'Itinerary / Service Details Document Version',
    ItineraryDownloadedByPatientOn: 'Itinerary / Service Details Document Downloaded by Patient',
    LegalSupportNotes: 'Legal Support for International Travel Needs Notes',
    MedicalSpecialityNotes: 'Medical / Specialty Transportation Notes',
    MedicalRecordNotes: 'Medical Record / Official Document Request and Translation Notes',
    NutritionSupportNotes: 'Nutrition Support / Meal Delivery Notes'
  },

  remoteRequestDetails: {
    VisitTitle: 'Visit Name',
    VisitStartDate: 'Visit Date',
    ItineraryVersion: 'Itinerary / Service Details Document Version',
    ItineraryDownloadedByPatientOn: 'Itinerary / Service Details Document Downloaded by Patient',
    DeliveryPickupLocation: 'Delivery Services pick-up location',
    PickupDate: 'Delivery Services pick-up date',
    PickupTime: 'Delivery Services pick-up time',
    PickupContactName: 'Pick-up Contact Name',
    PickupPhone: 'Pick-up Contact Phone Number',
    PickupItems: 'What is being picked up?',
    DropoffLocation: 'Delivery Services drop-off location',
    DropoffDate: 'Delivery Services drop-off date',
    DropoffTime: 'Delivery Services drop-offtime',
    DropoffContactName: 'Drop-off Contact Name',
    DropoffPhone: 'Drop-off Contact Phone Number',
    DropoffInstructions: 'Drop-off Instructions',
    HomehealthApptLocation: 'Home Health Appt Location',
    HomehealthVisitTime: 'Home Health Visit Time',
    HomehealthOtherConsiderations: 'Home Health: Other Relevant Considerations',
    TelehealthVisitTime: 'Telehealth Visit Time',
    TelehealthDuration: 'Telehealth Visit Approx. Duration (in minutes)',
    //  Telehealth Visit Attendees (Name)
    // Telehealth Visit Attendees (Role)
    OtherServices: 'Other Services'
  }
}

export const summaryAndDetailsSheetNames = {
  requestSummary: "Requests' Summary",
  inpRequestDetails: 'INP Request Details',
  addRequestDetails: 'ADD Request Details',
  advRequestDetails: 'ADV Request Details',
  remoteRequestDetails: 'REM Request Details'
}

export const reimbursementReportByVisitHeadersOne = {
  SiteCountry: 'Site Country',
  SponsorSiteID: 'Site ID',
  SiteStatus: 'Site Status',
  TCNCreateDate: 'TCN # Created Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  ParticipationStatus: 'Participation Progress / Status',
  SupplementalProgramParticipant: 'Supplemental Program Participant',
  SAN: 'SAN #',
  ExpirationDate: 'Card Expiration Date',
  CardCurrency: 'Card Currency',
  ActualReimbursementDate: 'Actual Reimbursement Date',
  VisitDate: 'Visit Date',
  VisitTitle: 'Visit Name'
}

export const reimbursementReportByVisitHeadersTwo = {
  ExchangeRate: 'Exchange Rate at time of Reimbursement',
  ReimbursementNote: 'Payment / Reimbursement Note',
  NoteAddedBy: 'Funds / Note Added By',
  SubmissionStatus: 'Submission Status',
  ReviewNote: 'Payment / Reimbursement Reviewed By'
}

export const tcnCardPatientUsageHeaders = {
  SiteCountry: 'Site Country',
  SponsorSiteID: 'Site ID',
  SiteStatus: 'Site Status',
  TCNCreateDate: 'TCN # Created Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  ParticipationStatus: 'Participation Progress / Status',
  CountryofResidence: 'Country of Residence',
  EmailAddress: 'Email Address',
  CardAssignedDate: 'Current Card Assigned',
  CardAssignedTo: 'Card Assigned To',
  SAN: 'Current SAN #',
  ExpirationDate: 'Card Expiration Date',
  Currency: 'Card Currency',
  TermsAcceptedDate: 'Patient accepts T&Cs and PP',
  RequestPlasticCardDate: 'Request Plastic Card',
  ActivatePlasticCardDate: 'Activate Plastic Card',
  // CardsLostStolen: 'Number of Cards Reported Lost / Stolen',
  NoofReplacements: 'Number of Replacements / Reissues',
  PINRetrievals: 'Number of PIN Inquiries',
  ViewHistoryInquiries: 'Number of View History Inquiries',
  NoofReimbursements: 'Number of Reimbursements',
  TotalReimbursed: 'Total Amount Reimbursed',
  LastLoginDate: 'Last Login'
}

export const siteDataPointsHeaders = {
  SiteId: 'Site ID',
  SiteStatus: 'Site Status',
  'EC Name': 'EC Name',
  'EC Type': 'EC Type',
  PILastName: 'PI Last Name',
  PlannedSiteActivationDate: 'Planned Activation Date',
  ActivationDate: 'Initial Activation Date',
  EnrollmentPausedDate: 'Enrollment Paused Date #1',
  ReactivationDate: 'Reactivated Date #1',
  ClosureDate: 'Closure Date',
  DroppedDate: 'Dropped Date',
  PrimaryStudyCoordinator: 'Primary Study Coordinator',
  PSCLastLoginDate: 'Primary Study Coordinator Last Login Date',
  AdditionalStudyCoordinator: 'Study Coordinator',
  ASCLastLoginDate: 'Study Coordinator Last Login Date'
}

export const secondaryPrescreeningHeaders = {
  SiteCountry: 'Site Country',
  SiteId: 'Site ID',
  PreviousSiteId: 'Previous Site ID',
  TCNCreatedDate: 'TCN # Created Date',
  AssignedToSite: 'Assigned to Site Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  Source: 'Referral Source'
}

export const additionalPrescreeningHeaders = {
  SiteCountry: 'Site Country',
  SiteId: 'Site ID',
  PreviousSiteId: 'Previous Site ID',
  TCNCreatedDate: 'TCN # Created Date',
  AssignedToSite: 'Assigned to Site Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  Source: 'Referral Source'
}

export const siteNotesHeaders = {
  SiteId: 'Site ID',
  SiteStatus: 'Site Status',
  PILastName: 'PI Last Name',
  Notetype: 'Note Type',
  Notes: 'Note',
  CreatedBy: 'Note Added By',
  CreatedDate: 'Note Added Date'
}

export const sitePreferencesHeadersOne = {
  SiteCountry: 'Site Country',
  SiteId: 'Site ID',
  SiteStatus: 'Site Status',
  PILastName: 'PI Last Name'
}

export const sitePreferencesHeadersTwo = {
  QuestionAnsweredBy: 'Question Answered By',
  MostRecentQuestionAnsweredDate: 'Most Recent Question Answered Date'
}

export const siteStatusAndAddressesHeaders = {
  SiteId: 'Site ID',
  SiteStatus: 'Site Status',
  PILastName: 'PI Last Name',
  PlannedSiteActivationDate: 'Planned Activation Date',
  ActivationDate: 'Initial Activation Date',
  EnrollmentPausedDate: 'Enrollment Paused Date #1',
  ReactivationDate: 'Reactivated Date #1',
  ClosureDate: 'Closure Date',
  DroppedDate: 'Dropped Date',
  IMAttendanceDate: 'IM Attendance Date',
  'EC Name': 'EC Name',
  'EC Type': 'EC Type',
  SiteName: 'Site Name',
  AddressType: 'Address Type',
  VerifiedBy: 'Address Verified By',
  VerifiedOn: 'Address Verified Date',
  AlternateLocationName: 'Location Name',
  Address1: 'Address 1',
  Address2: 'Address 2',
  City: 'City / Town',
  State: 'State / Province',
  ZipPostalCode: 'Zip / Postal Code',
  Country: 'Country',
  Phone: 'Phone'
}

export const siteManagementPerformanceHeaders = {
  Country: 'Site Country',
  SiteId: 'Site ID',
  PILastName: 'PI Last Name',
  SiteStatus: 'Site Status',
  PlannedSiteActivationDate: 'Planned Activation Date',
  ActivationDate: 'Initial Activation Date',
  EnrollmentPausedDate: 'Enrollment Paused Date',
  ReactivationDate: 'Reactivated Date',
  ClosureDate: 'Closure Date',
  MonthsActive: 'Site Active Months',
  ShippingAddressforParcelsVerifiedOn: 'Shipping Address for Parcels Verified Date',
  PrimaryLocationforPatientCareVerifiedOn: 'Primary Location for Patient Care Verified Date',
  TotalActivePatients: 'Total "Active" Patients',
  TotalScreened: 'Total Screened',
  TotalScreenFailed: 'Total Screen Failed',
  TotalRandomizedEnrolled: 'Total Randomized / Enrolled',
  TotalWithdrawn: 'Total Withdrawn',
  TotalCompleted: 'Total Completed',
  ScreeningRate: 'Screening Rate',
  ScreenFailRate: 'Screen Fail Percentage',
  RandomizationEnrollmentRate: 'Randomization / Enrollment Rate',
  EligibleforReferrals: 'Eligible for Referrals',
  TotalReferral: 'Total Referrals',
  ViewedReferral: 'Viewed Referrals',
  AppointmentScheduledReferrals: 'Appointment Scheduled Referrals',
  PercentNonDispositionedReferrals: '% Non Dispositioned Referrals',
  NotParticipatingReferrals: 'Not Participating Referrals',
  ConsentedReferrals: 'Consented Referrals',
  PercentDispositionedReferrals: '% Dispositioned Referrals',
  TotalTCNCardParticipants: 'Total TCN Card Participants',
  TCNCardParticipantsRate: '% TCN Card Participants',
  NumberofVisitsReimbursed: 'Number of Visits Reimbursed',
  TCNCardParticipantsWithdrawn: 'TCN Card Participants Withdrawn',
  TotalTCNArriveParticipants: 'Total TCN Arrive Participants',
  TCNArriveParticipantsRate: '% TCN Arrive Participants',
  NumberofRequests: 'Number of Requests',
  NumberofRequestsCompleted: 'Number of Requests Completed',
  TCNArrivePatientsWithdrawn: 'TCN Arrive Patients Withdrawn',
  TotalSiteStaffLogins: 'Total Site Staff Logins',
  SiteStaffLastLoginDate: 'Site Staff Last Login Date'
}

export const mediaOverviewHeaders = {
  'Media Plan Name': 'Media Plan Name',
  'Media Plan Status': 'Media Plan Status',
  ActualMediaStartDate: 'Actual Media Start Date',
  ActualMediaEndDate: 'Actual Media End Date',
  'Total Supported Sites (All Time)': 'Total Supported Sites (All Time)',
  'List of Supported Sites': 'List of Supported Sites (All Time)',
  'Total Actual Supported Sites': 'Total Actual Supported Sites (Current)',
  'List of Actual Supported Sites': 'List of Actual Supported Sites (Current)',
  'Total Sites with Referrals': 'Total Sites with Referrals',
  'List of Sites with Referrals': 'List of Sites with Referrals',
  'Total Sites with No Referrals': 'Total Sites with No Referrals',
  'List of Sites with No Referrals': 'List of Sites with No Referrals',
  'Total Referrals': 'Total Referrals',
  'Call Center In Process Referrals': 'Call Center In Process Referrals',
  'Call Center Final Disposition Referrals': 'Call Center Final Disposition Referrals',
  'Site In Process Referrals': 'Site In Process Referrals',
  'Site Final Disposition Referrals': 'Site Final Disposition Referrals'
}

export const mediaPlannedVsActualHeaders = {
  PlanName: 'Media Plan Name',
  'Number of Participating Sites': 'Number of Participating Sites',
  PlannedStartDate: 'Planned Start Date',
  PlannedEndDate: 'Planned End Date',
  PlannedFlightTimeframeDays: 'Planned Flight Timeframe (days)',
  NetPlacementBudget: 'Planned Net Placement Budget',
  BBKFees: 'BBK Fees',
  VendorFees: 'Vendor Fees',
  PlannedInquiries: 'Planned Inquiries',
  PlannedReferrals: 'Planned Referrals',
  PlannedInquirytoReferral: 'Planned Inquiry to Referral',
  PlannedCostPerReferral: 'Planned Cost per Referral',
  ActualMediaStartDate: 'Actual Media Start Date',
  DataLockDateforActuals: 'Data Lock Date for Actuals',
  DaysLive: 'Days Live',
  NetPlacementSpendToDate: 'Net Placement Spend to Date',
  BBKFeesIncurredToDate: 'BBK Fees Incurred to Date',
  VendorFeesIncurredToDate: 'Vendor Fees Incurred to Date',
  ActualInquiriesToDate: 'Actual Inquiries to Date',
  ActualReferralsToDate: 'Actual Referrals to Date',
  ActualInquiryToReferral: 'Actual Inquiry to Referral',
  ActualCostPerReferral: 'Actual Cost Per Referral to Date'
}

export const patientProfileReportHeaders = {
  SiteCountry: 'Site Country',
  SiteId: 'Site ID',
  SiteStatus: 'Site Status',
  PILastName: 'PI Last Name',
  TCNCreatedDate: 'TCN # Created Date',
  AssignedToSite: 'Assigned to Site Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  Source: 'Source',
  ParticipationProgress: 'Participation Progress',
  PPChangeDate: 'Participation Progress Change Date',
  FirstLoginDate: 'First Login Date',
  LastLoginDate: 'Last Login Date',
  UserName: 'Email Address',
  FirstName: 'First Name',
  LastName: 'Last Name',
  DateOfBirth: 'Date of Birth',
  Phone: 'Phone Number',
  PreferredCommunication: 'Preferred Communication',
  Address1: 'Address Line 1',
  Address2: 'Address Line 2',
  City: 'City / Town',
  State: 'State / Province',
  Country: 'Country',
  ZipPostalCode: 'ZIP / Postal Code',
  PreferredLanguage: 'Preferred Language',
  IsCommunicatoinInEnglish: 'Understand English?',
  Type: 'Companion Role',
  SP_EmailAddress: 'Email Address',
  SP_FN: 'First Name',
  SP_LN: 'Last Name',
  SP_DOB: 'Date of Birth',
  SP_Phone: 'Phone Number',
  SP_PreferredCommunication: 'Preferred Communication',
  SP_Addr1: 'Address Line 1',
  SP_Addr2: 'Address Line 2',
  SP_City: 'City / Town',
  SP_State: 'State / Province',
  SP_Country: 'Country',
  SP_Zip: 'ZIP / Postal Code',
  SP_PrefLang: 'Preferred Language'
}

export const patientUsageActivityHeaders = {
  SiteCountry: 'Site Country',
  SiteId: 'Site ID',
  SiteStatus: 'Site Status',
  PILastName: 'PI Last Name',
  TCNCreatedDate: 'TCN # Created Date',
  AssignedToSite: 'Assigned to Site Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  Source: 'Source',
  ParticipationProgress: 'Participation Progress',
  PPChangeDate: 'Participation Progress Last Updated',
  'TCN Engage Access': 'TCN Engage Access',
  ActivationEmailSentDate: 'Activation / Reset Password Email Sent',
  FirstLoginDate: 'First Login',
  TimeBetween: 'Days Between Activation / Reset Password Email and First Login',
  LastLoginDate: 'Last Login',
  TotalNoOfLogin: 'Total Logins',
  TCNCardSignUpDate: 'TCN Card Sign Up Date',
  'Payments Received': 'TCN Card Payments / Reimbursements Received',
  'Arrive First Request Entered': 'TCN Arrive First Request Entered',
  CountOfInProcessResquest: 'TCN Arrive Requests -In Process',
  CountOfCompletedResquest: 'TCN Arrive Requests -Completed',
  NoOfItinerariesDownloaded: 'TCN Arrive Itineraries Downloaded',
  UserStudyMessageSent: 'Concierge Messages Sent to Patient',
  UserStudyMessageRead: 'Concierge Messages Read by Patient',
  NoOfStudyVisits: 'Study Visits in Associated Visit Schedule',
  NoOfStudyVisitsCompleted: 'Study Visits Marked as Complete ',
  VisitResourcesDownloaded: 'Visit Resources Downloaded',
  StduyToolsDownloaded: 'Study Tools Downloaded',
  StduyToolsViewed: 'Study Tools Viewed',
}

export const prescreeningReportHeaderOne = {
  'Inquiry ID': 'Inquiry ID',
  'Country ID': 'Country ID',
  'Inquiry Start Date': 'Inquiry Start Date',
  'Prescreening Method': 'Prescreening Method',
  'Ad Source': 'Ad Source',
  'Tactic Source': 'Tactic Source',
  'Tactic Source ID': 'Tactic Source ID',
  'Device Type': 'Device Type',
  'Prescreener Result': 'Prescreener Result',
  'Contact Details Provided': 'Contact Details Provided',
  Abandoned: 'Abandoned',
}

export const prescreeningReportHeaderTwo = {
  'TCN # Created Date': 'TCN # Created Date',
  'TCN #': 'TCN #',
  'Referral Source': 'Referral Source',
  'First Viewed by Site': 'First Viewed by Site',
  'Last Viewed by Site': 'Last Viewed by Site',
  'Participation Progress / Status': 'Participation Progress / Status',
  'Status Last Updated': 'Status Last Updated',
  'Days in Current Status': 'Days in Current Status',
  'Preferred Site Details': 'Preferred Site Details',
  'No Closest Sites': 'No Closest Sites',
  'Assigned Site ID': 'Assigned Site ID',
  'Site City': 'Site City',
  'Site State': 'Site State',
  'Site Country': 'Site Country',
  'Site Zip / Postal Code': 'Site Zip / Postal Code',
  'Inquiry Zip / Postal Code': 'Inquiry Zip / Postal Code',
  'Inquiry Distance to Site (miles between Zip / Postal Codes)': 'Inquiry Distance to Site (miles between Zip / Postal Codes)'
}

export const prescreeningReportNotesHeaders = {
  Notes: 'Notes'
}

export const participationProgressReportHeaderOne = {
  SiteCountry: 'Site Country',
  SiteId: 'Site ID',
  PreviousSiteId: 'Previous Site ID'
}

export const participationProgressReportHeaderTwo = {
  TCNCreatedDate: 'TCN # Created Date',
  AssignedToSite: 'Assigned to Site Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  ReferralSource: 'Source',
  ParticipationProgress: 'Current Participation Progress',
  DaysInCurrentStatus: 'Days in Current Status',
  StatusUpdateDate: 'Status Updated Date',
  FirstViewedDate: 'First Viewed by Site',
  LastViewedDate: 'Last Viewed by Site'
}

export const participationProgressReportHeaderThree = {
  NoteText: 'Last Note Added',
  CreatedOn: 'Last Note Added Date',
  CreatedBy: 'Last Note Added By',
  Source: 'Ad Source',
  SourceDetail: 'Ad Source Detail',
  ReferralZipCode: 'Referral ZIP / Postal Code',
  StudyLocationDistance: 'Distance to Site (miles between Zip / Postal Codes)'
}

export const participationProgressNotesHeader = {
  NoteText: 'Notes',
  CreatedBy: 'Note Added By (user)',
  CreatedByUserPolicy: 'Note Added By (policy)',
  CreatedOn: 'Note Added Date'
}

export const participantStatusHistoryHeader = {
  SiteStatus: 'Site Status',
  TCNCreatedDate: 'TCN # Created Date',
  AssignedToSite: 'Assigned to Site Date',
  SubjectIDAssociationDate: 'Subject ID Association Date',
  TcnId: 'TCN #',
  SubjectNumber: 'Subject ID',
  ReferralSource: 'Source',
  ParticipationStatus: 'Participation Progress / Status',
  AppointmentDate: 'Appointment Date',
  DaysInCurrentStatus: 'Days in Current Status',
  StatusDate: 'Status Updated Date'
}
