import styled from '@emotion/styled'
import { ListItem, ListItemText } from '@mui/material'
import { Button } from 'components/common'
import PropTypes from 'prop-types'

const StyledListItemText = styled(ListItemText)(() => ({
  '&&': {
    button: {
      paddingLeft: '0px'
    }
  }
}))

const ExportLink = ({ label, exportTrigger, disabled }) => (
  <ListItem disablePadding>
    <StyledListItemText
      inset
      primary={
        <Button disabled={disabled} onClick={exportTrigger} variant="text">
          {label}
        </Button>
      }
    />
  </ListItem>
)

ExportLink.propTypes = {
  label: PropTypes.string,
  exportTrigger: PropTypes.func,
  disabled: PropTypes.bool
}

ExportLink.defaultProps = {
  disabled: false
}

export default ExportLink
