import { backendAxios, getConfig } from 'services/backend'

export const getPatientInformationForReimbursement = id =>
  backendAxios
    .get('/reimbursement/getpatientinformation', getConfig({ userId: id }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getAllSupportPersonsForReimbursement = patientId =>
  backendAxios
    .get('/reimbursement/getallsupportpersons', getConfig({ userId: patientId }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const fetchAccounts = userId =>
  backendAxios
    .get(`/reimbursement/${userId}/cards/?cache=${Date.now()}`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const confirmPatientReimbursementInfo = payload =>
  backendAxios
    .post('/reimbursement', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const confirmSupportPersonReimbursementInfo = payload =>
  backendAxios
    .post('/reimbursement', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const fetchCardFrontDetails = reimbursementCardId =>
  backendAxios
    .get(`/reimbursement/card/image/${reimbursementCardId}`, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const fetchCardBackDetails = reimbursementCardId =>
  backendAxios
    .get(`/reimbursement/card/data/${reimbursementCardId}`, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const fetchCardBalance = reimbursementCardId =>
  backendAxios
    .get(`/reimbursement/card/balance/${reimbursementCardId}`, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const fetchCardTrackingNumber = reimbursementCardId =>
  backendAxios
    .get('/reimbursement/card/shipmenttrackingno', getConfig({ reimbursementCardId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const fetchCardStatus = reimbursementCardId =>
  backendAxios
    .get(`/reimbursement/card/status/${reimbursementCardId}`, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const fetchServiceCap = reimbursementCardId =>
  backendAxios
    .get(`/reimbursement/card/service/cap/${reimbursementCardId}`, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const fetchServiceCapForRestrictedCountry = patientId =>
  backendAxios
    .get('/reimbursement/restrictedcountry/service/cap', getConfig({ userId: patientId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const checkLocalCurrency = reimbursementCardId =>
  backendAxios
    .get('/reimbursement/islocalcurrency', getConfig({ reimbursementCardId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const convertCurrency = payload =>
  backendAxios
    .post('/reimbursement/convert/currency', payload, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const submitReimbursements = payload =>
  backendAxios
    .post('/reimbursement/reimburse', payload, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const submitReimbursementsForRestrictedCountry = payload =>
  backendAxios
    .post('/reimbursement/restrictedcountry/reimburse', payload, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getFundsHistory = reimbursementCardId =>
  backendAxios
    .get(`/reimbursement/history/${reimbursementCardId}`, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getFundsHistoryForRestrictedCountry = patientId =>
  backendAxios
    .get('/reimbursement/restrictedcountry/history', getConfig({ userId: patientId }))
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const getFundsReceipts = (entityTypeId, entityId) =>
  backendAxios
    .get(`/attachment/${entityTypeId}/${entityId}`, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const reviewFunds = fundsDetails =>
  backendAxios
    .post('/reimbursement/review', fundsDetails, getConfig())
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))

export const updateReimbursement = payload =>
  backendAxios
    .patch('/reimbursement/attachment', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getBankAccounts = (reimbursementCardId, disableCache = false) =>
  backendAxios
    .get(
      disableCache
        ? `/reimbursement/beneficiaries/?cache=${Date.now()}`
        : '/reimbursement/beneficiaries',
      getConfig({ reimbursementCardId })
    )
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const submitBankAccount = payload =>
  backendAxios
    .post('/reimbursement/beneficiary/create', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const updateBankAccount = payload =>
  backendAxios
    .post('/reimbursement/beneficiary/update', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const deleteBankAccount = payload =>
  backendAxios
    .post('/reimbursement/beneficiary/disable', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getPQUserAgreements = reimbursementCardId =>
  backendAxios.get('/reimbursement/card/useragreements', getConfig({ reimbursementCardId }))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
