import React from 'react'
import styled from '@emotion/styled'
import Card from './Card'
import PropTypes from 'prop-types'

const MainWrapper = styled.div(({ theme, width }) => ({
  '&&': {
    display: 'flex',
    justifyContent: 'spaceBetween',
    width: width || '100%',
    minHeight: '200px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}))

const FirstPart = styled.div(({ theme, width, background, subColor, padding, gradient }) => ({
  '&&': {
    padding: `${theme.spacing(padding)}`,
    width: `${width}`,
    backgroundColor: `${!gradient ? theme.palette[background][subColor] : 'unset'}`,
    backgroundImage: `${
      gradient === true
        ? `linear-gradient(to right, ${theme.palette[background].main}, ${theme.palette[background].light})`
        : 'unset'
    }`,
    color: `${theme.palette[background].contrastText}`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

const SecondPart = styled.div(({ theme, width, background, gradient, subColor, padding, height }) => ({
  '&&': {
    padding: `${theme.spacing(padding)}`,
    width: `${width}`,
    height: `${height}`,
    backgroundImage: `${
      gradient === true
        ? `linear-gradient(to right, ${theme.palette[background].main}, ${theme.palette[background].light})`
        : 'unset'
    }`,
    backgroundColor: `${!gradient ? theme.palette[background][subColor] : 'unset'}`,
    color: `${theme.palette[background].contrastText}`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

const PartitionCard = ({
  firstContent,
  firstContentPadding,
  firstContentWidth,
  firstContentBackground,
  firstContentBackgroundSubColor,
  secondContent,
  secondContentPadding,
  secondContentWidth,
  secondContentBackground,
  secondContentBackgroundSubColor,
  secondContentHeight,
  cardProps,
  width,
  isSecondContentBackgroundGradient,
  isFirstContentBackgroundGradient
}) => (
  <Card {...cardProps} style={{ width: '770px' }}>
    <MainWrapper width={width}>
      <FirstPart
        width={firstContentWidth}
        background={firstContentBackground}
        subColor={firstContentBackgroundSubColor}
        padding={firstContentPadding}
        gradient={isFirstContentBackgroundGradient}
        data-testid="first-content-container"
      >
        {firstContent}
      </FirstPart>
      <SecondPart
        width={secondContentWidth}
        background={secondContentBackground}
        subColor={secondContentBackgroundSubColor}
        padding={secondContentPadding}
        height={secondContentHeight}
        gradient={isSecondContentBackgroundGradient}
        data-testid="second-content-container"
      >
        {secondContent}
      </SecondPart>
    </MainWrapper>
  </Card>
)

PartitionCard.propTypes = {
  firstContent: PropTypes.node,
  firstContentPadding: PropTypes.number,
  firstContentWidth: PropTypes.string,
  firstContentBackground: PropTypes.oneOf(['gradientBlue', 'secondary', 'primary', 'customBlue']),
  firstContentBackgroundSubColor: PropTypes.string,
  secondContent: PropTypes.node,
  secondContentPadding: PropTypes.number,
  secondContentWidth: PropTypes.string,
  secondContentBackground: PropTypes.oneOf(['gradientBlue', 'secondary']),
  isSecondContentBackgroundGradient: PropTypes.bool,
  isFirstContentBackgroundGradient: PropTypes.bool,
  secondContentBackgroundSubColor: PropTypes.string,
  secondContentHeight: PropTypes.string,
  cardProps: PropTypes.object,
  width: PropTypes.string,
}

PartitionCard.defaultProps = {
  firstContentBackground: 'secondary',
  firstContentBackgroundSubColor: 'main',
  firstContentWidth: '40%',
  firstContentPadding: 4,
  secondContentBackground: 'secondary',
  secondContentBackgroundSubColor: 'main',
  secondContentWidth: '65%',
  secondContentPadding: 4,
  secondContentHeight: 'unset',
  isSecondContentBackgroundGradient: false,
  isFirstContentBackgroundGradient: false
}

export default PartitionCard
