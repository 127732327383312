/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import {
  fetchDeliveryServicesDetails,
  resetDeliveryServices,
  setupDeliveryServicesForm,
  submitDeliveryServicesRequest
} from 'store/ducks/visitServices/deliveryServices'

import {
  TextBoxOuterLabel,
  DatePickerWithLabel,
  AutoCompleteWithLabel,
  Button,
  Bar,
  CustomRadioGroup,
  AddressField
} from 'components/common'
import { addressType, addressTypeLabel } from 'components/helper/constants/visitServices'
import PatientLocationForm from '../../PatientLocationForm'
import { setPatientAddresses } from 'store/ducks/visitServices/inPersonRequest'
import AddressForm from 'components/private/siteManagement/siteAddresses/AddressForm'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import { Error } from '@mui/icons-material'
import {
  fetchVisitNames,
  fetchVisitScheduleGroup,
  setVisitNames
} from 'store/ducks/visitServices/requestDetails'

const LabelForVisitNames = styled.div(
  ({ theme }) => `
      display: flex;  
      svg{
        top: -1px;
        width: 1.2rem;
        height: 1.2rem;
        right: -17px;
      }
      p {
        margin-right: ${theme.spacing(1 / 2)};
      }`
)

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const StyledLabel = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
`

const validationSchema = validationMapper([
  { id: 'visitNames', isResponseRequired: true, answerType: formElements.dropdownMenu },
  {
    id: 'visitDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Visit start date must be in the future'
    },
    error: 'Visit start date is required and must be a valid date'
  },

  {
    id: 'pickupAddressType',
    isResponseRequired: true,
    answerType: formElements.radioButtons
  },
  { id: 'contactName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'contactPhone', isResponseRequired: true, answerType: formElements.number },
  { id: 'deliveryItems', isResponseRequired: true, answerType: formElements.openText },
  {
    id: 'pickupDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Pick-Up date must be in the future'
    }
  },
  { id: 'pickupTime', isResponseRequired: true, answerType: formElements.openText },

  {
    id: 'dropOffAddressType',
    isResponseRequired: true,
    answerType: formElements.radioButtons
  },
  { id: 'dropOffContactName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'dropOffContactPhone', isResponseRequired: true, answerType: formElements.number },
  { id: 'dropOffInstructions', isResponseRequired: true, answerType: formElements.openText },
  {
    id: 'dropOffDate',
    isResponseRequired: true,
    answerType: formElements.date,
    condition: {
      min: new Date(),
      minError: 'Pick-Up date must be in the future'
    }
  },
  { id: 'dropOffTime', isResponseRequired: true, answerType: formElements.openText }
])

const DeliveryServices = () => {
  const { requestTypeId, requestId, copyRequestId } = useParams()
  const dispatch = useDispatch()

  const { visitDetails, visitNames, visitScheduleGroup, startNewRequestForVisitFlow } = useSelector(
    store => store.requestDetails
  )
  const { deliveryServicesDetailsForm } = useSelector(store => store.deliveryServices)
  const { siteAddresses, siteAddress } = useSelector(store => store.siteManagement)
  const { patientAddresses } = useSelector(store => store.inPersonRequest)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)

  const setupForCopyRequest = async () => {
    await dispatch(setupDeliveryServicesForm())
    await dispatch(fetchDeliveryServicesDetails(copyRequestId))
  }

  const setupForm = async () => {
    if (patientInformation.userId) {
      await dispatch(fetchVisitScheduleGroup(patientInformation.userId))
      if (!requestTypeId && !requestId && !copyRequestId) {
        dispatch(setupDeliveryServicesForm())
      }
      if (requestTypeId && requestId) {
        dispatch(fetchDeliveryServicesDetails(requestId))
      }
      // Copy Existing request View
      if (copyRequestId) {
        setupForCopyRequest()
      }
    }
  }

  useEffect(() => {
    setupForm()
    return () => {
      dispatch(resetDeliveryServices())
      dispatch(setVisitNames({ isLoading: false, results: [] }))
    }
  }, [patientInformation.userId])

  const changeAddressSelection = (
    selectedAddress,
    setFieldValue,
    selectedAddressType,
    fieldName
  ) => {
    let newSelectedAddress
    if (typeof selectedAddress === 'object') {
      // this means user added new address, so automatically select that address
      newSelectedAddress = selectedAddress
    } else {
      // else find the address by addressId from the list of addresses and then make the selection
      // if the selected address type is patient residence than, use patient addresses list for selection
      newSelectedAddress =
        selectedAddressType === addressType.patientResidence
          ? patientAddresses.find(item => item.addressId === parseInt(selectedAddress))
          : siteAddresses.find(item => item.addressId === parseInt(selectedAddress))
    }
    setFieldValue(fieldName, newSelectedAddress)
  }

  const onPickUpAddressTypeChange = (value, setFieldValue) => {
    const finalAddress =
      value === addressType.patientResidence
        ? patientAddresses[patientAddresses.length - 1]
        : siteAddresses[siteAddresses.length - 1]
    setFieldValue('pickupAddress', finalAddress)
    setFieldValue('pickupAddressType', value)
  }

  const onDropOffAddressTypeChange = (value, setFieldValue) => {
    const finalAddress =
      value === addressType.patientResidence
        ? patientAddresses[patientAddresses.length - 1]
        : siteAddresses[siteAddresses.length - 1]
    setFieldValue('dropOffAddress', finalAddress)
    setFieldValue('dropOffAddressType', value)
  }

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {visitScheduleGroup.isLoading || !deliveryServicesDetailsForm || visitDetails.isLoading
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          )
          : (
            <Formik
              initialValues={{
                ...deliveryServicesDetailsForm,
                ...(startNewRequestForVisitFlow &&
                !readOnly && {
                  ...startNewRequestForVisitFlow
                })
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={values => {
                dispatch(submitDeliveryServicesRequest(values, copyRequestId))
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2}>
                    {visitScheduleGroup.results.length > 1 && (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <AutoCompleteWithLabel
                          id="visitScheduleGroup"
                          label="Select a Visit Schedule"
                          name="visitScheduleGroup"
                          onChange={(event, selectedValue, reason) => {
                            if (reason === 'selectOption') {
                              dispatch(fetchVisitNames(patientInformation.userId, selectedValue.id))
                            } else if (reason === 'clear') {
                              dispatch(
                                setVisitNames({
                                  isLoading: false,
                                  results: []
                                })
                              )
                            }
                          }}
                          options={visitScheduleGroup.results}
                          isLoading={visitScheduleGroup.isLoading}
                          size="small"
                          disabled={readOnly}
                          multiple={false}
                          hasSelectAllOption={false}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <AutoCompleteWithLabel
                        id="visitNames"
                        label={
                          <LabelForVisitNames>
                            <Typography variant="body2">Study Visit</Typography>
                            <Tooltip
                              color="primary"
                              size="small"
                              title={
                                <Typography variant="body1">
                                If your request will apply to more than one study visit, please
                                select the first applicable study visit.
                                </Typography>
                              }
                            >
                              <Error size="small" />
                            </Tooltip>
                          </LabelForVisitNames>
                        }
                        name="visitNames"
                        options={visitNames.results}
                        isLoading={visitNames.isLoading}
                        size="small"
                        disabled={readOnly}
                        multiple={false}
                        hasSelectAllOption={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <DatePickerWithLabel
                        id="visitDate"
                        label="Visit Date"
                        name="visitDate"
                        size="small"
                        minDate={new Date()}
                        fullWidth={false}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <CustomRadioGroup
                            id="pickupAddressType"
                            name="pickupAddressType"
                            label="Pick-up location"
                            row
                            options={[
                              {
                                value: addressType.siteLocation,
                                label: addressTypeLabel.siteLocation
                              },
                              {
                                value: addressType.patientResidence,
                                label: addressTypeLabel.patientResidence
                              }
                            ]}
                            disabled={readOnly}
                            formik="true"
                            onChange={e => {
                              onPickUpAddressTypeChange(e.target.value, setFieldValue)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {values.pickupAddress && values.pickupAddressType && (
                            <>
                              {values.pickupAddressType === addressType.patientResidence
                                ? (
                                  <AddressField
                                    id="pickupAddress"
                                    label="Selected Pick-up Address"
                                    name="pickupAddress"
                                    changeAddressLabel="Select Alternate Address"
                                    addressesList={patientAddresses}
                                    addressesListHeader="Location must be where the patient resides at the time of pick-up / delivery. If the home address does not
          meet this criteria, please add a new residence address (e.g., hotel)"
                                    addAddressLabel="Add Location"
                                    addAddressForm={props => (
                                      <PatientLocationForm
                                        onNewAddressAdded={newAddress =>
                                          dispatch(
                                            setPatientAddresses([...patientAddresses, newAddress])
                                          )
                                        }
                                        {...props}
                                      />
                                    )}
                                    onChange={selectedAddress =>
                                      changeAddressSelection(
                                        selectedAddress,
                                        setFieldValue,
                                        values.pickupAddressType,
                                        'pickupAddress'
                                      )
                                    }
                                    disabled={readOnly}
                                  />
                                )
                                : (
                                  <AddressField
                                    id="pickupAddress"
                                    label="Selected Pick-up Address"
                                    name="pickupAddress"
                                    changeAddressLabel="Select Alternate Address"
                                    addressesList={siteAddresses}
                                    addAddressLabel="Add Site Location"
                                    addAddressForm={props => (
                                      <AddressForm addressToEdit={siteAddress} {...props} />
                                    )}
                                    onChange={selectedAddress =>
                                      changeAddressSelection(
                                        selectedAddress,
                                        setFieldValue,
                                        values.pickupAddressType,
                                        'pickupAddress'
                                      )
                                    }
                                    disabled={readOnly}
                                  />
                                )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextBoxOuterLabel
                                size="small"
                                id="contactName"
                                label="Contact Name"
                                name="contactName"
                                type="text"
                                disabled={readOnly}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextBoxOuterLabel
                                size="small"
                                id="contactPhone"
                                label="Contact Phone Number"
                                name="contactPhone"
                                type="text"
                                disabled={readOnly}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <DatePickerWithLabel
                                id="pickupDate"
                                label="Pick-up date"
                                name="pickupDate"
                                size="small"
                                minDate={new Date()}
                                fullWidth={false}
                                disabled={readOnly}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextBoxOuterLabel
                                size="small"
                                id="pickupTime"
                                label="Best pick-up time"
                                name="pickupTime"
                                type="time"
                                captionText={
                                  '(Please allow for an hour window before or after the selected time for pick-ups.)'
                                }
                                disabled={readOnly}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextBoxOuterLabel
                            size="small"
                            id="deliveryItems"
                            label="What is being picked-up?"
                            name="deliveryItems"
                            type="text"
                            captionText="(e.g., medication, lab results, device)"
                            disabled={readOnly}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>

                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <CustomRadioGroup
                            id="dropOffAddressType"
                            name="dropOffAddressType"
                            label="Drop-off location"
                            formik="true"
                            row
                            options={[
                              {
                                value: addressType.siteLocation,
                                label: addressTypeLabel.siteLocation
                              },
                              {
                                value: addressType.patientResidence,
                                label: addressTypeLabel.patientResidence
                              }
                            ]}
                            disabled={readOnly}
                            onChange={e => {
                              onDropOffAddressTypeChange(e.target.value, setFieldValue)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {values.dropOffAddress && values.dropOffAddressType && (
                            <>
                              {values.dropOffAddressType === addressType.patientResidence
                                ? (
                                  <AddressField
                                    id="dropOffAddress"
                                    label="Selected Drop-off Address"
                                    name="dropOffAddress"
                                    changeAddressLabel="Select Alternate Address"
                                    addressesList={patientAddresses}
                                    addressesListHeader="Location must be where the patient resides at the time of pick-up / delivery. If the home address does not
          meet this criteria, please add a new residence address (e.g., hotel)"
                                    addAddressLabel="Add Location"
                                    addAddressForm={props => (
                                      <PatientLocationForm
                                        onNewAddressAdded={newAddress =>
                                          dispatch(
                                            setPatientAddresses([...patientAddresses, newAddress])
                                          )
                                        }
                                        {...props}
                                      />
                                    )}
                                    onChange={selectedAddress =>
                                      changeAddressSelection(
                                        selectedAddress,
                                        setFieldValue,
                                        values.dropOffAddressType,
                                        'dropOffAddress'
                                      )
                                    }
                                    disabled={readOnly}
                                  />
                                )
                                : (
                                  <AddressField
                                    id="dropOffAddress"
                                    label="Selected Drop-off Address"
                                    name="dropOffAddress"
                                    changeAddressLabel="Select Alternate Address"
                                    addressesList={siteAddresses}
                                    addAddressLabel="Add Site Location"
                                    addAddressForm={props => (
                                      <AddressForm addressToEdit={siteAddress} {...props} />
                                    )}
                                    onChange={selectedAddress =>
                                      changeAddressSelection(
                                        selectedAddress,
                                        setFieldValue,
                                        values.dropOffAddressType,
                                        'dropOffAddress'
                                      )
                                    }
                                    disabled={readOnly}
                                  />
                                )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextBoxOuterLabel
                                size="small"
                                id="dropOffContactName"
                                label="Contact Name"
                                name="dropOffContactName"
                                type="text"
                                disabled={readOnly}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextBoxOuterLabel
                                size="small"
                                id="dropOffContactPhone"
                                label="Contact Phone Number"
                                name="dropOffContactPhone"
                                type="text"
                                disabled={readOnly}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <DatePickerWithLabel
                                id="dropOffDate"
                                label="Drop-off date"
                                name="dropOffDate"
                                size="small"
                                minDate={new Date()}
                                fullWidth={false}
                                disabled={readOnly}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextBoxOuterLabel
                                size="small"
                                id="dropOffTime"
                                label="Best drop-off time"
                                name="dropOffTime"
                                type="time"
                                captionText={
                                  '(Please allow for an hour window before or after the selected time for pick-ups.)'
                                }
                                disabled={readOnly}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextBoxOuterLabel
                            size="small"
                            id="dropOffInstructions"
                            label="Instructions at drop-off location and any other relevant information"
                            name="dropOffInstructions"
                            type="text"
                            disabled={readOnly}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="body2">In Case We Need To Speak with You</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachDateTime"
                        label="What is the best time of the day to reach you?"
                        name="reachDateTime"
                        type="time"
                        size="small"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachPhoneNumber"
                        label={
                          <StyledLabel variant="body2">
                          Best phone number to reach you <i>(include country code)</i>
                          </StyledLabel>
                        }
                        name="reachPhoneNumber"
                        size="small"
                        type="number"
                        disabled={readOnly}
                      />
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ActionWrapper>
                          <Button size="large" fullWidth={false} color="primary" type="submit">
                          Submit Request
                          </Button>
                        </ActionWrapper>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
      </Grid>
    </Grid>
  )
}

export default DeliveryServices
