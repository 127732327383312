import React from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { TextBoxOuterLabel } from 'components/common'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const SmallTextTypography = styled(Typography)`
  font-weight: normal;
  font-style: italic;
`
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TicketedTravel = () => {
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextBoxOuterLabel
          id="ticketedTravelSpecialNeeds"
          data-testid="ticketedTravelSpecialNeeds"
          label={
            <LabelWrapper>
              <Typography variant="body2">
                Concierge will be arranging the most appropriate ticketed travel for this request.
                If your travelers have any special needs, please supply this information in detail
                below:
              </Typography>{' '}
              <SmallTextTypography variant="caption" gutterBottom>
                (e.g., will not travel by plane, special dietary needs with specifics, seat location
                related to health needs (e.g., aisle, near bathroom) and any other helpful
                particulars).
              </SmallTextTypography>
            </LabelWrapper>
          }
          name="ticketedTravelSpecialNeeds"
          multiline={true}
          rows={3}
          placeholder="Travelers Special Needs Details"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextBoxOuterLabel
          id="ticketedTravelOtherPreferences"
          data-testid="ticketedTravelOtherPreferences"
          label="Other Relevant Considerations"
          name="ticketedTravelOtherPreferences"
          multiline={true}
          rows={3}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  )
}

export default TicketedTravel
