import Login from 'components/public/Login'
import ForgotPassword from 'components/public/ForgotPassword'
import ResetPassword from 'components/public/ResetPassword'
import SignUp from 'components/public/SignUp'
import ProtectionLayer from 'layouts/ProtectionLayer'
import authRoutes from './authRoutes'
import SuccessScreen from 'components/public/SuccessScreen'

const publicRoutes = [
  { to: 'login', component: Login },
  { to: 'sign-up', component: SignUp },
  { to: 'forgot-password', component: ForgotPassword },
  { to: 'reset-success/:flow', component: SuccessScreen },
  { to: 'passwordsetup/:resetToken', component: ResetPassword },
  { to: 'auth', component: ProtectionLayer, children: authRoutes },
]

export default publicRoutes
