import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'
import { PartitionCard, AutoCompleteWithLabel } from 'components/common'
import IndividualDetails from './IndividualDetails'
import CardDetails from './CardDetails'
import {
  fetchAccountHoldersCardDetails,
  setSelectedPersonDetailsManageAccount,
  setSelectedAccountManageAccount,
} from 'store/ducks/patientRecord/manageAccount'
import { fetchPatientSupportPersonsForReimbursement, getAccounts, setSelectedAccountAddFunds } from 'store/ducks/patientRecord/reimbursement'
import { reimbursementAccordions } from 'components/helper/constants/reimbursement'

const WrapperGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(2)}`,
}))
const SelectAccountWrapper = styled(Grid)(({ hidden }) => ({
  display: hidden ? 'none' : 'block',
}))

const ManageAccount = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(store => store.userInteractions)
  const { selectedAccountManageAccount, selectedPersonDetailsManageAccount } = useSelector(
    store => store.manageAccount,
  )
  const { accounts, patientInformationForReimbursement, patientSupportsForReimbursement } = useSelector(
    store => store.reimbursement,
  )
  const { patientInformation } = useSelector(store => store.patientMainWrapper)

  const getPatientSupportInfo = async () => {
    if (patientInformationForReimbursement.userId) {
      await dispatch(fetchPatientSupportPersonsForReimbursement(patientInformationForReimbursement.userId))
      dispatch(getAccounts(patientInformation.userId, reimbursementAccordions.manageAccount))
    }
  }

  useEffect(() => {
    dispatch(setSelectedAccountAddFunds(null))
    getPatientSupportInfo()
  }, [patientInformationForReimbursement.userId])

  useEffect(() => () => {
    dispatch(setSelectedAccountAddFunds(null))
    dispatch(setSelectedAccountManageAccount(null))
  }, [])

  const onAccountChange = async (selectedValue, reason) => {
    if (reason !== 'clear') {
      await dispatch(fetchAccountHoldersCardDetails(selectedValue.id))

      // If there is a value in supportPersonType, then it is support person
      if (selectedValue.supportPersonType) {
        // Find the Support Person by email
        const cardHolderDetailsTempList = patientSupportsForReimbursement.results.filter(
          supportPerson => supportPerson.emailAddress === selectedValue.email,
        )
        if (cardHolderDetailsTempList.length > 0) {
          dispatch(setSelectedPersonDetailsManageAccount(cardHolderDetailsTempList[0]))
        }
        // else it is a patient
      } else {
        dispatch(setSelectedPersonDetailsManageAccount(patientInformationForReimbursement))
      }
    }
    dispatch(setSelectedAccountManageAccount(selectedValue))
  }

  return (
    <WrapperGrid>
      <Grid container spacing={2}>
        {!patientInformationForReimbursement.isRestrictedCountry && !!accounts.results.length && (
          <SelectAccountWrapper item xs={12} sm={12} md={4} lg={4} hidden={accounts.results.length === 1}>
            <AutoCompleteWithLabel
              multiple={false}
              hasSelectAllOption={false}
              size="small"
              id="selectAccount"
              label="Select Account"
              name="selectAccount"
              options={accounts.results}
              isLoading={accounts.isLoading || !!isLoading.length}
              formik="false"
              selectedValue={selectedAccountManageAccount}
              onChange={(event, selectedValue, reason) => onAccountChange(selectedValue, reason)}
              placeholder="Please Select"
            />
          </SelectAccountWrapper>
        )}
        <Grid item xs={12} sm={12} md={8} lg={8}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {selectedAccountManageAccount && (
            <PartitionCard
              cardProps={{
                cardContentStyles: { padding: '0px', margin: '0px' },
                cardStyles: {
                  marginTop: '30px',
                  boxShadow:
                    '3px 3px 6px 3px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                },
              }}
              firstContent={
                selectedPersonDetailsManageAccount && (
                  <IndividualDetails information={selectedPersonDetailsManageAccount} />
                )
              }
              firstContentWidth="40%"
              secondContent={<CardDetails />}
              isSecondContentBackgroundGradient={true}
              secondContentWidth="60%"
              secondContentBackground="gradientBlue"
            />
          )}
        </Grid>
      </Grid>
    </WrapperGrid>
  )
}

export default ManageAccount
