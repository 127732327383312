import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

import { setAmountsExceedNote } from 'store/ducks/patientRecord/reimbursement'

import { Alert, PropertyValue, TextBoxOuterLabel } from 'components/common'
import { formatCurrency } from 'components/helper/utility'
import { capAlertMessages, capTypesIds } from 'components/helper/constants/configuration'
import { serviceIds } from 'components/helper/constants/reimbursement'

const CategoryWrapper = styled.div(({ theme }) => ({
  marginBottom: `${theme.spacing(1)}`,
  display: 'flex'
}))

const ExceededTypography = styled(Typography)(({ theme }) => ({
  margin: `${theme.spacing(2, 0)}`
}))

const LimitText = styled(Typography)(({ theme }) => ({
  marginLeft: `${theme.spacing(1)}`
}))

const LimitText2 = styled(Typography)(({ theme }) => ({
  marginLeft: `${theme.spacing(1)}`,
  fontStyle: 'italic'
}))

const ExceededAmount = ({ exceededType }) => {
  const dispatch = useDispatch()
  const { amountsExceededList, serviceCaps, serviceIdDisplayTextMapping, capTypeRestriction } =
    useSelector(store => store.reimbursement)

  return (
    <>
      {(exceededType === 'both' || exceededType === 'exceededCap') && (
        <>
          {[capTypesIds.softCaps, capTypesIds.reviewAndApprove].includes(capTypeRestriction)
            ? (
              <Alert gutterBottom={true} severity="info" message={capAlertMessages[capTypeRestriction]} formik="false" />
            )
            : (
              <ExceededTypography variant="body2" gutterBottom>
              You entered a reimbursement that exceeds one or more category guidelines.
              </ExceededTypography>
            )}
          {Object.keys(amountsExceededList).map(
            (cat, index) =>
              cat !== serviceIds.other &&
              ![serviceIds.totalOrTotalInLocalCurrency, serviceIds.totalInCardCurrency].includes(
                cat
              ) && (
                <CategoryWrapper key={index}>
                  <PropertyValue
                    value={`${serviceIdDisplayTextMapping[cat].currencySymbol}${formatCurrency(
                      amountsExceededList[cat]
                    )}`}
                    label={`${serviceIdDisplayTextMapping[cat].displayText}:`}
                    align="row"
                    labelProps={{ variant: 'body2' }}
                    valueProps={{ variant: 'body2' }}
                  />
                  <LimitText variant="body1">|</LimitText>
                  <LimitText2 variant="body1" color="error">{`Limit: ${
                    serviceIdDisplayTextMapping[cat].currencySymbol
                  }${formatCurrency(serviceCaps[cat])}`}</LimitText2>
                </CategoryWrapper>
              )
          )}
        </>
      )}
      <br />
      {(exceededType === 'both' || exceededType === 'other') && (
        <>
          <ExceededTypography variant="body2" gutterBottom>
            You entered a reimbursement in the &quot;other&quot; category.
          </ExceededTypography>
          <CategoryWrapper>
            <PropertyValue
              value={`${serviceIdDisplayTextMapping[serviceIds.other].currencySymbol}${formatCurrency(
                amountsExceededList[serviceIds.other]
              )}`}
              label="Other:"
              align="row"
              labelProps={{ variant: 'body2' }}
              valueProps={{ variant: 'body2' }}
            />
            <LimitText variant="body1">|</LimitText>
            <LimitText2 variant="body1" color="error">{`Limit: ${
              serviceIdDisplayTextMapping[serviceIds.other].currencySymbol
            }${formatCurrency(serviceCaps[serviceIds.other])}`}</LimitText2>
          </CategoryWrapper>
        </>
      )}
      <br />
      <Typography variant="body2" gutterBottom>
        Please provide additional information on the nature of this reimbursement
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextBoxOuterLabel
            size="medium"
            id="note"
            label=""
            name="note"
            type="text"
            rows="5"
            multiline
            formik="false"
            onChange={e => dispatch(setAmountsExceedNote(e.target.value))}
          />
        </Grid>
      </Grid>
    </>
  )
}

ExceededAmount.propTypes = {
  exceededType: PropTypes.oneOf(['both', 'other', 'exceededCap']).isRequired
}

export default ExceededAmount
