import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import styled from '@emotion/styled'

import QuickView from 'components/common/QuickView'
import { Button } from 'components/common/form'
import { DataGrid } from 'components/common'
import AddInsightForm from './AddInsightForm'
import DeleteInsightConfirmation from './DeleteInsightConfirmation'
import {
  fetchConciergeInsights,
  setDeleteInsightConfirmation,
  resetDeleteInsightConfirmation,
  setShowAddInsightForm
} from 'store/ducks/patientRecord/patientProfile'
import { useRestriction, Restriction } from 'permissions'

const EmptyFooter = styled.div`
  padding: 0px;
`
const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
`

const InsightsList = () => {
  const dispatch = useDispatch()

  // For Patient View, use the patientID from patientDashboard
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { conciergeInsights, deleteInsightConfirmation, showAddInsightForm } = useSelector(
    store => store.patientProfile
  )
  let formattedConciergeInsights = []

  if (useRestriction('read', 'profileNoteDelete') && conciergeInsights.length > 0) {
    formattedConciergeInsights = conciergeInsights.map(insight => ({
      ...insight,
      delete: (
        <>
          <IconButton
            aria-label="actions"
            aria-haspopup="true"
            onClick={_event =>
              dispatch(
                setDeleteInsightConfirmation({
                  showConfirmation: true,
                  deleteInsightId: insight.noteId
                })
              )
            }
            color="primary"
          >
            {/* <Badge color="error" variant="dot"> */}
            <Delete color="action" />
            {/* </Badge> */}
          </IconButton>
        </>
      )
    }))
  }

  useEffect(() => {
    dispatch(fetchConciergeInsights(patientInformation.userId))
  }, [])

  return (
    <>
      <Restriction ability="read" feature="profileNoteAdd">
        <div style={{ width: '100%', overflow: 'hidden', padding: '20px 0px 10px 10px' }}>
          <div style={{ width: '80%', float: 'left' }}></div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="medium"
              fullWidth={false}
              color="primary"
              onClick={() => dispatch(setShowAddInsightForm(true))}
            >
                Add Note
            </Button>
          </div>
        </div>
      </Restriction>
      <TableWrapper>
        <DataGrid
          headers={[
            { id: 'note', label: 'Notes' },
            { id: 'createdBy', label: 'Created By' },
            { id: 'createdDate', label: 'Date' },
            { id: 'delete', label: 'Delete' }
          ]}
          tableData={formattedConciergeInsights}
          setRequestedSort={() => {}}
          order={'DESC'}
          orderBy={'createdDate'}
          alternateRowColored={true}
        />
      </TableWrapper>
      <QuickView
        title="Add Note"
        onClose={() => dispatch(setShowAddInsightForm(false))}
        dialogContent={<AddInsightForm />}
        isDialogOpen={showAddInsightForm}
        maxWidth="md"
        dialogActions={<EmptyFooter />}
      />
      <QuickView
        title="Confirm"
        onClose={() => dispatch(resetDeleteInsightConfirmation())}
        dialogContent={<DeleteInsightConfirmation />}
        isDialogOpen={deleteInsightConfirmation.showConfirmation}
        maxWidth="xs"
        dialogActions={null}
      />
    </>
  )
}

export default InsightsList
