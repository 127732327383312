import React from 'react'
import { PropTypes } from 'prop-types'
import styled from '@emotion/styled'
import { AttachFile, Flag, FlagOutlined } from '@mui/icons-material'
import { MailNavTab } from 'components/common'
import { IconButton, Typography } from '@mui/material'
import { Restriction, useRestriction } from 'permissions'
import { useDispatch, useSelector } from 'react-redux'
import { flagStudyMessage } from 'store/ducks/communicationCenter/studyMessages'
import { communicationTabIDs } from 'components/helper/constants/mailbox'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'

const AttachedLogo = styled(AttachFile)(
  () => `
    transform: rotate(45deg);
    `
)
const ScheduledMessageText = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  font-style: italic;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledFlag = styled(Flag)(
  ({ theme }) => `
  color: ${theme.palette.error.main}
  `
)

const StudyMessagesTab = ({
  id,
  parentUserStudyMessageId,
  senderPolicy,
  distributionDate,
  subject,
  isViewed,
  isStudyMessage,
  senderFullName,
  hasAttachment,
  selected,
  distributionTime,
  createdDate,
  createdTime,
  category,
  onTabChange,
  isFlagged
}) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const canReadDistributionDate = useRestriction('read', 'studyMessageShowDistributionDate')
  return (
    <MailNavTab
      selected={selected}
      viewed={`${isStudyMessage ? true : isViewed}`}
      action={
        <ActionsWrapper>
          {selectedCommunicationTabId !== communicationTabIDs.conciergeMsgsForPatients && (
            <Restriction ability="read" feature="conciergeMsgsFilterSearchButton">
              <IconButton
                aria-label="close-announcement-details"
                onClick={() =>
                  dispatch(
                    flagStudyMessage(
                      { isStudyMessage, id, parentUserStudyMessageId, isFlagged },
                      patientInformation.userId,
                      selectedCommunicationTabId
                    )
                  )
                }
                size="large"
                color="primary"
              >
                {isFlagged
                  ? (
                    <StyledFlag />
                  )
                  : (
                    <FlagOutlined color={`${selected ? 'secondary' : 'primary'}`} />
                  )}
              </IconButton>
            </Restriction>
          )}
          {hasAttachment && <AttachedLogo />}
        </ActionsWrapper>
      }
      header={senderPolicy ? `${senderFullName}, ${senderPolicy}` : senderFullName}
      title={subject}
      subtitle={category}
      footer={
        <>
          {canReadDistributionDate && isStudyMessage
            ? (
              <span>{`${distributionDate} | ${distributionTime}`}</span>
            )
            : (
              <span>{`${createdDate} | ${createdTime}`}</span>
            )}

          {isStudyMessage && distributionDate !== createdDate && (
            <ScheduledMessageText variant="caption" color={`${selected ? 'secondary' : 'primary'}`}>
              Scheduled Message
            </ScheduledMessageText>
          )}
        </>
      }
      onTabChange={onTabChange}
    />
  )
}

StudyMessagesTab.propTypes = {
  distributionDate: PropTypes.string,
  senderFullName: PropTypes.string,
  subject: PropTypes.string,
  hasAttachment: PropTypes.bool,
  selected: PropTypes.bool,
  distributionTime: PropTypes.string,
  createdDate: PropTypes.string,
  createdTime: PropTypes.string,
  isViewed: PropTypes.bool,
  category: PropTypes.string,
  senderPolicy: PropTypes.string,
  onTabChange: PropTypes.func,
  isStudyMessage: PropTypes.bool,
  isDistributed: PropTypes.bool,
  isFlagged: PropTypes.bool,
  id: PropTypes.any,
  parentUserStudyMessageId: PropTypes.any
}

export default StudyMessagesTab
