import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  getEligibleParticipants,
  getReimbursementCapTypes,
  getReimbursementCatAndCapHeaders,
  getReimbursementConfiguration,
  getReimbursementCountries,
  getReimbursementServices,
  submitCardConfigurations,
  submitCatAndCap
} from 'services/configuration/cardConfiguration'
import { showError } from '../application'
import {
  copyObject,
  extractArrayOfIdsForEachKey,
  getIndividualProps,
  removeDuplicateItemsFromArray,
  setUniqueIdentifier
} from 'components/helper/utility'
import { setLoading } from '../userInteractions'
import { cardConfigurationFields } from 'components/helper/constants/configuration'
import { formElements } from 'components/helper/constants/common'

export const setReimbursementCatAndCapHeaders = createAction(
  'cardConfiguration/setReimbursementCatAndCapHeaders'
)
export const setEligibleParticipants = createAction('cardConfiguration/setEligibleParticipants')
export const setReimbursementCountries = createAction('cardConfiguration/setReimbursementCountries')
export const setReimbursementCapTypes = createAction('cardConfiguration/setReimbursementCapTypes')
export const setReimbursementServices = createAction('cardConfiguration/setReimbursementServices')
export const setCategoryAndCap = createAction('cardConfiguration/setCategoryAndCap')
export const setCardConfigurationForm = createAction('cardConfiguration/setCardConfigurationForm')
export const setReimbursementConfiguration = createAction(
  'cardConfiguration/setReimbursementConfiguration'
)
export const setUpdatedFields = createAction('cardConfiguration/setUpdatedFields')
export const resetCardConfiguration = createAction('cardConfiguration/resetCardConfiguration')

export const fetchReimbursementConfiguration = (configurationModule = true) => async (dispatch, getState) => {
  try {
    dispatch(setReimbursementConfiguration({ isLoading: true }))
    await dispatch(fetchReimbursementCountries())
    const { reimbursementCountries, cardConfigurationForm } = getState().cardConfiguration
    const res = await getReimbursementConfiguration()
    const selectedCountries = reimbursementCountries.results.filter(countryObj =>
      res.participatingCountries.includes(countryObj.id)
    )

    dispatch(
      setCardConfigurationForm({
        ...cardConfigurationForm,
        ...res,
        participatingCountries: selectedCountries,
        approvalRequiredServices: res.approvalRequiredServices || [],
        isStandardShipping: !!res.isStandardShipping,
        isVirtual: !!res.isVirtual
      })
    )

    // when user is making this call from Configuration module
    configurationModule && dispatch(fetchReimbursementCatAndCap(selectedCountries))
    dispatch(
      setReimbursementConfiguration({
        isLoading: false,
        results: { ...res, participatingCountries: res.participatingCountries || [] }
      })
    )
  } catch (e) {
    dispatch(setReimbursementConfiguration({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch.', e))
  }
}

export const fetchReimbursementCatAndCap = participatingCountries => async (dispatch, getState) => {
  try {
    if (participatingCountries.length) {
      const { cardConfigurationForm, reimbursementCatAndCapHeaders } = getState().cardConfiguration
      dispatch(setCategoryAndCap({ isLoading: true }))
      reimbursementCatAndCapHeaders.results.length === 0 &&
        dispatch(setReimbursementCatAndCapHeaders({ isLoading: true }))

      const countryIds = participatingCountries.map(country => country.id).join(',')
      const res = await getReimbursementCatAndCapHeaders(countryIds)

      if (res.length && res[0].services.length) {
        const headers = []
        const groupedData = {}
        const formInitialValues = {}

        res.forEach((obj, index) => {
          const { country, siteId, currency, services, countryId, uniqueSiteProtocolId } = obj

          const subTableData = {
            countryId,
            uniqueSiteProtocolId,
            siteId,
            localCurrency: currency
          }

          // Iterate over the services array in the given order
          services.forEach(serviceObj => {
            const { displayText, maxAmount, id } = serviceObj

            // subTable data contains the row data of each country
            subTableData[displayText] = { isService: true, id, maxAmount }

            // Loop only thorough the first index of the response and set table headers
            if (index === 0) {
              headers.push({
                id: displayText,
                label: displayText
              })
            }

            // set the initial values for the input fields
            formInitialValues[
              setUniqueIdentifier({ countryId, siteId, uniqueSiteProtocolId, id, displayText })
            ] = maxAmount || ''
          })

          if (groupedData[country]) {
            groupedData[country].subTableData.push(subTableData)
          } else {
            groupedData[country] = {
              subTableLabel: country,
              subTableData: [subTableData]
            }
          }
        })

        dispatch(
          setCardConfigurationForm({
            ...cardConfigurationForm,
            participatingCountries,
            ...formInitialValues
          })
        )

        dispatch(setReimbursementCatAndCapHeaders({ results: headers, isLoading: false }))
        dispatch(setCategoryAndCap({ isLoading: false, results: Object.values(groupedData) }))
      } else {
        dispatch(setCategoryAndCap({ results: [], isLoading: false }))
        dispatch(setReimbursementCatAndCapHeaders({ isLoading: false }))
      }
    } else {
      dispatch(setCategoryAndCap({ results: [], isLoading: false }))
      dispatch(setReimbursementCatAndCapHeaders({ isLoading: false }))
    }
  } catch (e) {
    dispatch(setCategoryAndCap({ isLoading: false }))

    dispatch(setReimbursementCatAndCapHeaders({ isLoading: false }))
    dispatch(
      showError('There was some error while trying to fetch reimbursement category limits.', e)
    )
  }
}

export const fetchReimbursementCountries = () => async dispatch => {
  try {
    dispatch(setReimbursementCountries({ isLoading: true }))
    const res = await getReimbursementCountries()
    dispatch(
      setReimbursementCountries({
        isLoading: false,
        results: removeDuplicateItemsFromArray(res, 'displayText')
      })
    )
  } catch {
    dispatch(setReimbursementCountries({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch countries options.'))
  }
}

export const fetchEligibleParticipants = () => async dispatch => {
  try {
    dispatch(setEligibleParticipants({ isLoading: true }))
    const res = await getEligibleParticipants()
    dispatch(setEligibleParticipants({ isLoading: false, results: res }))
  } catch {
    dispatch(setEligibleParticipants({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch eligible participants options.'))
  }
}

export const fetchReimbursementCardTypes = () => async dispatch => {
  try {
    dispatch(setReimbursementCapTypes({ isLoading: true }))
    const res = await getReimbursementCapTypes()
    dispatch(setReimbursementCapTypes({ isLoading: false, results: res }))
  } catch {
    dispatch(setReimbursementCapTypes({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch eligible participants options.'))
  }
}

export const fetchReimbursementServices = () => async (dispatch, getState) => {
  try {
    const { reimbursementServices } = getState().cardConfiguration
    if (reimbursementServices.results.length === 0) {
      dispatch(setReimbursementServices({ isLoading: true }))
      const res = await getReimbursementServices()
      dispatch(setReimbursementServices({ isLoading: false, results: res }))
    }
  } catch {
    dispatch(setReimbursementServices({ isLoading: false }))
    dispatch(showError('There was some error while trying to fetch eligible participants options.'))
  }
}

export const onFieldChange = fieldInfo => (dispatch, getState) => {
  const { updatedFields } = getState().cardConfiguration
  const fieldName = fieldInfo.fieldName
  let finalUpdatedFields = [...updatedFields]
  const existingIndex = updatedFields.findIndex(field => field.fieldName === fieldName)

  if (existingIndex !== -1) {
    finalUpdatedFields[existingIndex] = fieldInfo
  } else {
    finalUpdatedFields = [...updatedFields, fieldInfo]
  }
  if (
    fieldInfo.fieldName === cardConfigurationFields.participatingCountries &&
    finalUpdatedFields.some(field => field.fieldName.includes('|'))
  ) {
    finalUpdatedFields = finalUpdatedFields.filter(field => !field.fieldName.includes('|'))
  }

  dispatch(setUpdatedFields(finalUpdatedFields))
}

export const onSubmitCardConfigurations = values => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))

    const { updatedFields } = getState().cardConfiguration

    let changedValues = {}

    updatedFields.forEach(fieldInfo => {
      // Only send fields that are updated
      if (fieldInfo.isChanged) {
        const fieldValue = values[fieldInfo.fieldName]

        if (fieldInfo.fieldType === formElements.dropdownMenu) {
          changedValues[fieldInfo.fieldName] = fieldValue ? fieldValue.id : 0
        } else if (fieldInfo.fieldType === formElements.multiSelectDropdown) {
          if (fieldValue.length) {
            changedValues = {
              ...changedValues,
              ...extractArrayOfIdsForEachKey({ [fieldInfo.fieldName]: fieldValue })
            }
          } else {
            changedValues = {
              ...changedValues,
              [fieldInfo.fieldName]: fieldValue
            }
          }
        } else {
          changedValues[fieldInfo.fieldName] = fieldValue
        }
      }
    })

    const catAndCapPayload = []
    const cardConfigPayload = {}

    Object.keys(changedValues).forEach(objKey => {
      // if the object key includes pipe then it is a service
      if (objKey.includes('|')) {
        const { countryId, siteId, uniqueSiteProtocolId, id } = getIndividualProps(objKey)
        catAndCapPayload.push({
          countryId,
          siteId,
          uniqueSiteProtocolId,
          service: { id, maxAmount: changedValues[objKey] }
        })
      } else {
        // it is one of the card configuration form field
        cardConfigPayload[objKey] = changedValues[objKey]
      }
    })

    if (Object.values(cardConfigPayload).length) {
      await submitCardConfigurations(cardConfigPayload)
    }

    if (catAndCapPayload.length) {
      await submitCatAndCap(catAndCapPayload)
    }

    dispatch(setUpdatedFields([]))
    await dispatch(fetchReimbursementConfiguration())
  } catch (e) {
    dispatch(showError('There was some error while trying to submit card configuration.', e))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  cardConfigurationForm: {
    participatingCountries: [],
    isVirtual: false,
    eligibleParticipants: [],
    isStandardShipping: false,
    capType: 0,
    approvalRequiredServices: []
  },
  reimbursementConfiguration: { isLoading: true, results: '' },
  reimbursementCatAndCapHeaders: { isLoading: false, results: [] },
  reimbursementCountries: { isLoading: false, results: [] },
  eligibleParticipants: { isLoading: false, results: [] },
  reimbursementCapTypes: { isLoading: false, results: [] },
  reimbursementServices: { isLoading: false, results: [] },
  categoryAndCap: { isLoading: false, results: [] },
  updatedFields: []
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setReimbursementConfiguration, (state, action) => {
      state.reimbursementConfiguration = { ...state.reimbursementConfiguration, ...action.payload }
    })
    .addCase(setReimbursementCatAndCapHeaders, (state, action) => {
      state.reimbursementCatAndCapHeaders = {
        ...state.reimbursementCatAndCapHeaders,
        ...action.payload
      }
    })
    .addCase(setReimbursementCountries, (state, action) => {
      state.reimbursementCountries = { ...state.reimbursementCountries, ...action.payload }
    })
    .addCase(setEligibleParticipants, (state, action) => {
      state.eligibleParticipants = { ...state.eligibleParticipants, ...action.payload }
    })
    .addCase(setReimbursementCapTypes, (state, action) => {
      state.reimbursementCapTypes = { ...state.reimbursementCapTypes, ...action.payload }
    })
    .addCase(setReimbursementServices, (state, action) => {
      state.reimbursementServices = { ...state.reimbursementServices, ...action.payload }
    })
    .addCase(setCategoryAndCap, (state, action) => {
      state.categoryAndCap = { ...state.categoryAndCap, ...action.payload }
    })
    .addCase(setCardConfigurationForm, (state, action) => {
      state.cardConfigurationForm = action.payload
    })
    .addCase(setUpdatedFields, (state, action) => {
      state.updatedFields = action.payload
    })
    .addCase(resetCardConfiguration, state => {
      copyObject(state, initialState)
    })
})
