import React from 'react'
import PropTypes from 'prop-types'
import { displayDate } from 'components/helper/utility'
import { PropertyValue } from 'components/common'

const IndividualDetails = ({ information }) => (
  <>
    <PropertyValue
      label="First Name:"
      value={information.firstName}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="Last Name:"
      value={information.lastName}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="Email Address:"
      value={information.emailAddress}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    {/* <PropertyValue
      label="Phone Number:"
      value={information.phoneNumber}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    /> */}
    {/* <PropertyValue
      label="Gender:"
      value={information.gender.displayText}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    /> */}
    <PropertyValue
      label="Date of Birth:"
      value={displayDate(information.dateOfBirth)}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="Country:"
      value={information.country.displayText}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="Address 1:"
      value={information.address1}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="Address 2:"
      value={information.address2}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="City / Town:"
      value={information.city}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="State / Province:"
      value={information.state.displayText}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
    <PropertyValue
      label="Zip / Postal Code:"
      value={information.zipCode}
      valueProps={{ variant: 'body2' }}
      fullWidth
      align="row"
      gutterBottom={1}
      labelProps={{ variant: 'body1' }}
    />
  </>
)

IndividualDetails.propTypes = {
  information: PropTypes.object,
}

export default IndividualDetails
