import styled from '@emotion/styled'
import { Check, ChevronLeft, Download } from '@mui/icons-material'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { AccordionGroup, Button } from 'components/common'
import {
  termsAndConditionsAttachmentLabel,
  termsAndConditionsAttachmentText
} from 'components/helper/constants/reimbursement'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import {
  fetchTermsAndConditionsAttachments,
  onDownloadAgreement,
} from 'store/ducks/patientRecord/manageAccount'
import { palette } from 'styles/baseTheme'

const ActionWrapper = styled(Grid)(({ theme }) => ({
  '&&': {
    display: 'flex',
    justifyContent: 'flex-end',
    // margin: theme.spacing(2, 0),
    button: {
      marginLeft: theme.spacing(2)
    }
  }
}))

const StyledNavLink = styled(NavLink)(() => ({
  '&&': {
    textDecoration: 'unset',
    color: 'unset'
  }
}))

const TermsDocumentLabelWrapper = styled(Typography)`
  button {
    padding: 0px;
  }
`
const TermsAndConditions = () => {
  const dispatch = useDispatch()
  const { selectedInstance } = useSelector(store => store.auth)
  const { termsAndConditionsAttachments } = useSelector(store => store.manageAccount)
  const { reimbursementCardId } = useParams()
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)

  useEffect(() => {
    dispatch(fetchTermsAndConditionsAttachments(reimbursementCardId))
  }, [reimbursementCardId])

  return (
    <AccordionGroup
      controlled
      contentBackground="none"
      defaultOpenPanel={0}
      contentStyles={{ backgroundColor: palette.gray.light, padding: '32px' }}
      accordions={[
        {
          id: 'bankTransfer',
          label: (
            <Typography variant="subtitle1" component="div">
              Card Issuer&apos;s Terms & Conditions
            </Typography>
          ),
          component: (
            <Grid container spacing={2}>
              <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
                <StyledNavLink to={`/instance/${selectedInstance.instanceId}/reimbursement`}>
                  <Button color="inherit" startIcon={<ChevronLeft />}>
                    Back
                  </Button>
                </StyledNavLink>
              </ActionWrapper>
              <>
                {termsAndConditionsAttachments.isLoading
                  ? (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box>
                        <Skeleton height={32} />
                        <Skeleton height={32} />
                        <Skeleton height={32} />
                      </Box>
                    </Grid>
                  )
                  : (
                    <>
                      {termsAndConditionsAttachments.results.length
                        ? (
                          instanceConfig.results.CardVendor &&
                      instanceConfig.results.CardVendor.toLowerCase() ===
                        process.env.REACT_APP_INTERCASH_VENDOR.toLowerCase()
                            ? (
                              termsAndConditionsAttachments.results.map((attachment, index) => (
                                <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                                  <TermsDocumentLabelWrapper variant="body2">
                                    {termsAndConditionsAttachmentText[attachment.name]}{' '}
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={() => dispatch(onDownloadAgreement(attachment))}
                                    >
                                      {termsAndConditionsAttachmentLabel(attachment.name)}
                                    </Button>
                                  </TermsDocumentLabelWrapper>
                                </Grid>
                              ))
                            )
                            : (
                              termsAndConditionsAttachments.results.map((attachment, index) => (
                                <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                                  <TermsDocumentLabelWrapper variant="body2">
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={() => dispatch(onDownloadAgreement(attachment))}
                                      endIcon={attachment.read ? <Check fontSize='medium'/> : <Download />}
                                    >
                                      {termsAndConditionsAttachmentLabel(attachment.name)}
                                    </Button>
                                  </TermsDocumentLabelWrapper>
                                </Grid>
                              ))
                            )
                        )
                        : (
                          <Typography variant="subtitle1" color="error">
                        No documents found.
                          </Typography>
                        )}
                    </>
                  )}
              </>
            </Grid>
          )
        }
      ]}
      gradient={true}
      color="gradientBlue"
      transitionProps={{ unmountOnExit: true }}
    />
  )
}

export default TermsAndConditions
