import React from 'react'
import { PageHeader } from 'components/common'
import { Paper, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { TopNavigationBar } from 'components/common/navigation'
import { Outlet } from 'react-router-dom'
import { patientMailBoxTabs } from 'routes/navigationRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { communicationCenterModuleNames } from 'components/helper/constants/badging'
import { fetchBadgesForPatientSection } from 'store/ducks/patientRecord/patientMainWrapper'
import { useRestriction } from 'permissions'

const Wrapper = styled.div`
  width: 160px;
  display: flex;
`

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 5px;
  justify-content: space-between;
  align-items: center;
`

const CommunicationCenterLabel = ({ icon, label, count }) => {
  const Icon = icon
  return (
    <Wrapper>
      <Icon />
      <LabelWrapper>
        <Typography variant="caption">{label}</Typography>
        {count && <Typography variant="caption">{count}</Typography>}
      </LabelWrapper>
    </Wrapper>
  )
}

CommunicationCenterLabel.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  count: PropTypes.string
}

const PatientMailBox = () => {
  const dispatch = useDispatch()
  const { badgesForPatientSection, patientInformation } = useSelector(store => store.patientMainWrapper)
  const { isPatient } = useSelector(store => store.auth)

  const finalTabs = patientMailBoxTabs.map(tab => useRestriction('read', tab.to)
    ? {
      ...tab,
      badgeCount: badgesForPatientSection.mailBox?.list.filter(
        badge => badge.name === communicationCenterModuleNames()[tab.to]
      )[0]?.count
    }
    : '')

  return (
    <>
      <PageHeader
        expandContent={true}
        content={
          <TopNavigationBar
            buttons={finalTabs}
            changeTab={() => {
              if (isPatient) {
                dispatch(fetchBadgesForPatientSection())
              } else {
                dispatch(fetchBadgesForPatientSection(patientInformation.userId))
              }
            }}
          />
        }
      />
      <Paper elevation={0}>
        <Outlet />
      </Paper>
    </>
  )
}

export default PatientMailBox
