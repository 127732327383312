import React from 'react'
import PropTypes from 'prop-types'
import { Card as MuiCard, CardContent, CardHeader, CardActions, Divider, Fade } from '@mui/material'
import styled from '@emotion/styled'
import ForwardRefWrapper from '../ForwardRefWrapper'

const StyledCardHeader = styled(CardHeader)(
  ({ color, theme, styles }) => ({
    '&&': {
      flexWrap: 'wrap',
      padding: `${theme.spacing(3, 5)}`,
      backgroundColor: color && `${theme.palette[color].main}`,
      color: color && `${theme.palette[color].contrastText}`,
      ...styles
    }
  })
)

const StyledCard = styled(MuiCard)(({ gradient, background, type, radius, styles, theme }) => ({
  '&&': {
    width: '100%',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: radius === 'true' && theme.shape.containerRadius,
    backgroundImage:
      gradient &&
      `linear-gradient(to left, ${theme.palette[gradient].main}, ${theme.palette[gradient].dark})`,
    opacity: gradient && '0.9 !important',
    backgroundColor: !gradient && theme.palette[background][type],
    color: gradient ? theme.palette[gradient].contrastText : theme.palette[background].contrastText,
    ...styles,
    '.MuiCardHeader-action': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }
}))

const StyledDivider = styled(Divider)`
  && {
    margin: 0 16px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    height: 2px;
  }
`

const StyledCardContent = styled(CardContent)(({ theme, styles, align, wrap }) => ({
  '&&': {
    display: 'flex',
    flexWrap: wrap,
    justifyContent: 'space-between',
    alignItems: align,
    ...styles,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  }
}))

const Card = ({
  title,
  titleTypographyProps,
  children,
  cardStyles,
  cardBottom,
  headerColor,
  alignContent,
  flexWrap,
  headerAction,
  gradientBackground,
  background,
  backgroundType,
  radius,
  cardContentStyles,
  disableTypographyForTitle,
  cardHeaderStyles
}) => (
  <Fade in timeout={{ enter: 800, exit: 800 }}>
    <ForwardRefWrapper>
      <StyledCard
        gradient={gradientBackground}
        background={background}
        type={backgroundType}
        radius={`${radius}`}
        styles={cardStyles}
      >
        {title && (
          <>
            <StyledCardHeader
              title={title}
              color={headerColor}
              titleTypographyProps={{ ...titleTypographyProps }}
              action={headerAction}
              disableTypography={disableTypographyForTitle}
              styles={cardHeaderStyles}
            />
            {!headerColor && <StyledDivider />}
          </>
        )}
        {children && <StyledCardContent align={alignContent} wrap={flexWrap} styles={cardContentStyles}>
          {children}
        </StyledCardContent>}
        {cardBottom && <CardActions>{cardBottom}</CardActions>}
      </StyledCard>
    </ForwardRefWrapper>
  </Fade>
)

Card.propTypes = {
  /**
   * Title of the card
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * @default false
   * when true, disabled the typography around the title
   */
  disableTypographyForTitle: PropTypes.bool,
  /**
   * Content of the card
   */
  children: PropTypes.node.isRequired,
  /**
   * Card footer
   */
  cardBottom: PropTypes.node,
  /**
   * Color of the header
   */
  headerColor: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * Will have gradient style background
   */
  gradientBackground: PropTypes.oneOf(['primary', 'secondary', 'info', 'gradientBlue', 'rose']),
  /**
   * Background of the card
   */
  background: PropTypes.oneOf(['primary', 'secondary', 'info', 'gradientBlue', 'rose', 'gray']),
  /**
   * Background type
   */
  backgroundType: PropTypes.oneOf(['main', 'dark', 'light']),
  /**
   * @default true
   * When true will have radius to the card
   */
  radius: PropTypes.bool,
  /**
   * Title will have action items on the right
   */
  headerAction: PropTypes.node,
  /**
   * styles for header section
   */
  cardHeaderStyles: PropTypes.object,
  /**
   * pass properties to title text
   */
  titleTypographyProps: PropTypes.object,
  /**
   * align content
   */
  alignContent: PropTypes.oneOf(['start', 'end', 'center']),
  /**
   * decide to wrap or no-wrap the content on responsive screen
   */
  flexWrap: PropTypes.oneOf(['wrap', 'noWrap']),
  /**
   * pass styles to content wrapper
   */
  cardContentStyles: PropTypes.object,
  /**
   * pass styles to card wrapper
   */
  cardStyles: PropTypes.object
}

Card.defaultProps = {
  titleTypographyProps: { variant: 'body2' },
  alignContent: 'start',
  flexWrap: 'wrap',
  background: 'secondary',
  backgroundType: 'main',
  radius: true,
  disableTypographyForTitle: false
}

export default Card
