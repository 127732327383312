import { backendAxios, getConfig } from './backend'

export const getCrossInstancePermission = () =>
  backendAxios
    .get('/crossinstancereport/crossinstancepermission', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getRequestSummary = () =>
  backendAxios
    .get('/crossinstancereport/tcnarrivereqsummaryreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getConciergeScrum = () =>
  backendAxios
    .get('/crossinstancereport/conciergescrumreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
