import { Typography } from '@mui/material'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const changeColor = keyframes`
  0%, 100% {
    color: #29CB7E;
  }
  50% {
    color: #757779;
  }
`

const LoaderWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`

const Preloader = styled.div`
  text-align: center;
  max-width: 900px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Loader = styled.div`
  border: 4px solid #29cb7e;
  border-radius: 50%;
  border-top: 4px solid #757779;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 2s linear infinite;
  margin-bottom: 20px;
`

const LoadingText = styled(Typography)(({ visibility }) => ({
  '&&': {
    color: '#757779',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    animation: `${changeColor} 2s linear infinite`,
    transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out' /* Add transition effects */,
    opacity: visibility ? 1 : 0 /* Toggle opacity based on visibility */,
    transform: `translateY(${visibility ? '0' : '-20px'})` /* Move in from top */
  }
}))

const LoadingIndicator = ({ loadingCriteria }) => (
  <LoaderWrapper>
    <Preloader>
      <Loader />
      {loadingCriteria?.length
        ? loadingCriteria.map((loadingText, index) => (
          <LoadingText key={index} visibility={true}>
            {loadingText.loadingText}
          </LoadingText>
        ))
        : 'Loading. . . '}
    </Preloader>
  </LoaderWrapper>
)

LoadingIndicator.propTypes = {
  loadingCriteria: PropTypes.arrayOf(
    PropTypes.shape({
      module: PropTypes.string,
      methodName: PropTypes.string,
      loadingText: PropTypes.string
    })
  )
}

export default LoadingIndicator
