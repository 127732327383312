import React from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { AutoCompleteWithLabel, Button, Password } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  createAnswerToQuestion,
  fetchSecurityQuestionsLookup,
  performLogout
} from 'store/ducks/auth'
import { formElements } from 'components/helper/constants/common'
import { validationMapper } from 'components/helper/validationEngine'

const StyledButton = styled(Button)`
  width: 35%;
  margin-top: 15px;
`

const StyledTitle = styled(Typography)`
  margin-bottom: 30px;
`

const ActionGrid = styled(Grid)`
  display: flex;
  justify-content: space-around;
`

const validations = validationMapper([
  { id: 'securityQuestion', isResponseRequired: true, answerType: formElements.dropdownMenu },
  { id: 'answer', isResponseRequired: true, answerType: formElements.openText },
  {
    id: 'confirmAnswer',
    isResponseRequired: true,
    answerType: formElements.openText,
    dependency: {
      matchWith: true,
      ref: 'answer',
      message: 'Answers must match.'
    }
  }
])

const SetSecurityQuestion = () => {
  const dispatch = useDispatch()
  const { securityQuestionsLookup } = useSelector(store => store.auth)

  return (
    <>
      <StyledTitle gutterBottom variant="h2" color="primary">
        Security Question Setup
      </StyledTitle>

      <Formik
        initialValues={{
          securityQuestion: null,
          answer: '',
          confirmAnswer: ''
        }}
        validationSchema={validations}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(createAnswerToQuestion(values))
          setSubmitting(false)
        }}
      >
        <Form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AutoCompleteWithLabel
                id="security-questions"
                label="Select A Security Question"
                name="securityQuestion"
                options={securityQuestionsLookup.results}
                multiple={false}
                hasSelectAllOption={false}
                size="small"
                isLoading={securityQuestionsLookup.isLoading}
                fetchOptions={() => dispatch(fetchSecurityQuestionsLookup())}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Password
                size="small"
                required
                id="answer"
                label="Answer"
                name="answer"
                outerLabel={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Password
                size="small"
                required
                id="confirm-answer"
                label="Confirm Answer"
                name="confirmAnswer"
                outerLabel={true}
              />
            </Grid>
            <ActionGrid item xs={12} sm={12} md={12} lg={12}>
              <StyledButton color="primary" type="submit" size="large" fullWidth={true}>
                Submit
              </StyledButton>{' '}
              <StyledButton
                color="inherit"
                size="large"
                fullWidth={true}
                onClick={() => dispatch(performLogout(false, false))}
              >
                Cancel
              </StyledButton>
            </ActionGrid>
          </Grid>
        </Form>
      </Formik>
    </>
  )
}

export default SetSecurityQuestion
