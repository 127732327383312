import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line prefer-arrow-callback
const ForwardRefWrapper = React.forwardRef(function ForwardRefWrapper (props, ref) {
  const child = React.Children.only(props.children)
  const forwardedChild = React.cloneElement(child, { ref })
  return forwardedChild
})

ForwardRefWrapper.propTypes = {
  children: PropTypes.node,
}

export default ForwardRefWrapper
