import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

import { displayDate } from 'components/helper/utility'
import {
  fetchAccountInformation,
  getAccounts,
  setSelectedAccountAddFunds,
  setSelectedVisitDate,
  setSelectedVisitName,
  setupReimbursementForCountryRestricted
} from 'store/ducks/patientRecord/reimbursement'

import { AutoCompleteWithLabel, DatePickerWithLabel, PropertyValue, Card } from 'components/common'
import AddFundsGrid from './AddFundsGrid'
import FundsHistory from './FundsHistory'
import { cardStatus, reimbursementAccordions } from 'components/helper/constants/reimbursement'
import { Restriction } from 'permissions'
import {
  capTypesIds,
  fundsManagementGuidelinesForSite,
  fundsManagementLabels
} from 'components/helper/constants/configuration'
import { Circle } from '@mui/icons-material'
import {
  fetchVisitNames,
  fetchVisitScheduleGroup,
  setVisitNames
} from 'store/ducks/visitServices/requestDetails'

const WrapperGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(2)}`
}))

const CardDetailsWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  '&&': {
    minWidth: '20px',
    alignSelf: 'flex-start',
    marginTop: '9px',
    svg: {
      fontSize: '10px'
    }
  }
}))
const SelectAccountWrapper = styled(Grid)`
  align-self: flex-end;
`

const SelectInfoText = styled(Typography)`
  padding: 0px;
`

const AddFunds = () => {
  const dispatch = useDispatch()
  const {
    patientInformationForReimbursement,
    accounts,
    selectedAccountAddFunds,
    cardDetails,
    selectedVisitName,
    selectedVisitDate
  } = useSelector(store => store.reimbursement)
  const { reimbursementConfiguration, reimbursementServices } = useSelector(
    store => store.cardConfiguration
  )
  const { visitNames, visitScheduleGroup } = useSelector(store => store.requestDetails)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)

  const addFundsCalls = async () => {
    if (patientInformationForReimbursement.userId) {
      if (patientInformationForReimbursement.isRestrictedCountry) {
        await dispatch(setupReimbursementForCountryRestricted())
      } else {
        await dispatch(
          getAccounts(patientInformationForReimbursement.userId, reimbursementAccordions.addFunds)
        )
      }
    }
    if (patientInformation.userId) {
      await dispatch(fetchVisitScheduleGroup(patientInformation.userId))
    }
  }

  useEffect(() => {
    addFundsCalls()
    return () => {
      dispatch(setSelectedAccountAddFunds(null))
      dispatch(
        setVisitNames({
          isLoading: false,
          results: []
        })
      )
    }
  }, [patientInformationForReimbursement.userId])

  useEffect(
    () => () => {
      dispatch(setSelectedAccountAddFunds(null))
    },
    []
  )

  const onAccountChange = async (selectedValue, reason) => {
    if (reason !== 'clear') {
      dispatch(fetchAccountInformation(selectedValue.id))
    }
    dispatch(setSelectedAccountAddFunds(selectedValue))
  }

  const isSelectedAccountForAddingFunds = !!selectedAccountAddFunds

  const unmatchedServices =
    reimbursementConfiguration.results &&
    reimbursementConfiguration.results.capType.id === capTypesIds.reviewAndApprove &&
    reimbursementServices.results.length &&
    reimbursementServices.results.filter(
      service =>
        !reimbursementConfiguration.results.approvalRequiredServices.some(
          approvalRequiredService => service.id === approvalRequiredService.id
        )
    )

  const displayTextList =
    unmatchedServices && unmatchedServices.map(service => service.displayText).join(', ')

  return (
    <>
      <WrapperGrid container spacing={2}>
        <SelectAccountWrapper item xs={12} sm={12} md={7.5} lg={7.5}>
          <Grid container spacing={2}>
            {!patientInformationForReimbursement.isRestrictedCountry && (
              <Grid item xs={12} sm={12} md={6.5} lg={6.5}>
                <AutoCompleteWithLabel
                  multiple={false}
                  hasSelectAllOption={false}
                  size="small"
                  id="selectAccount"
                  label="Select Account"
                  name="selectAccount"
                  options={accounts.results}
                  isLoading={accounts.isLoading}
                  formik="false"
                  selectedValue={selectedAccountAddFunds}
                  onChange={(event, selectedValue, reason) =>
                    onAccountChange(selectedValue, reason)
                  }
                  placeholder="Please Select"
                />
              </Grid>
            )}
            {/**
                Show "selection of visit date and name" only when (country is restricted) OR (Card is not suspended
                AND card/account is selected in above dropdown)
                 */}
            {(patientInformationForReimbursement.isRestrictedCountry ||
              (cardDetails.cardStatus !== cardStatus.suspend &&
                isSelectedAccountForAddingFunds)) && (
              <>
                <Grid item xs={12} sm={12} md={8} lg={8} />
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <SelectInfoText variant="body2">
                    Select the visit and date associated to this reimbursement.
                  </SelectInfoText>
                </Grid>
                {visitScheduleGroup.results.length > 1 && (
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <AutoCompleteWithLabel
                      id="visitScheduleGroup"
                      label="Select a Visit Schedule"
                      name="visitScheduleGroup"
                      onChange={(event, selectedValue, reason) => {
                        if (reason === 'selectOption') {
                          dispatch(fetchVisitNames(patientInformation.userId, selectedValue.id))
                        } else if (reason === 'clear') {
                          dispatch(
                            setVisitNames({
                              isLoading: false,
                              results: []
                            })
                          )
                        }
                      }}
                      options={visitScheduleGroup.results}
                      isLoading={visitScheduleGroup.isLoading}
                      size="small"
                      multiple={false}
                      hasSelectAllOption={false}
                      formik="false"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  {/* Visit Names are fetched in PatientMainWrapper */}
                  <AutoCompleteWithLabel
                    multiple={false}
                    hasSelectAllOption={false}
                    size="small"
                    id="visitName"
                    label="Visit"
                    name="visitName"
                    options={visitNames.results}
                    isLoading={visitNames.isLoading}
                    formik="false"
                    onChange={(event, selectedValue) => {
                      dispatch(setSelectedVisitName(selectedValue))
                    }}
                    selectedValue={selectedVisitName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={4}>
                  <DatePickerWithLabel
                    formik="false"
                    id="visitDate"
                    label="Visit Date"
                    name="visitDate"
                    size="small"
                    maxDate={new Date()}
                    onChange={value => {
                      dispatch(setSelectedVisitDate(displayDate(value)))
                    }}
                    value={selectedVisitDate}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </SelectAccountWrapper>
        {!patientInformationForReimbursement.isRestrictedCountry &&
          isSelectedAccountForAddingFunds && (
          <>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5} />
            <CardDetailsWrapper item xs={12} sm={12} md={4} lg={4}>
              <Card cardStyles={{ boxShadow: '1px 1px 10px 5px #0909091a', padding: '24px' }}>
                {/*
                <PropertyValue
                  label="ID Number (back of the card):"
                  fullWidth
                  value={cardDetails.san}
                  align="row"
                  labelProps={{ variant: 'body1' }}
                  valueProps={{ variant: 'body2' }}
                />
                <PropertyValue
                  label="Card Expiration:"
                  fullWidth
                  value={cardDetails.expiry}
                  align="row"
                  labelProps={{ variant: 'body1' }}
                  valueProps={{ variant: 'body2' }}
                /> */}
                <PropertyValue
                  label="Card Status:"
                  fullWidth
                  value={cardDetails.cardStatus}
                  align="row"
                  labelProps={{ variant: 'body1' }}
                  valueProps={{ variant: 'body2' }}
                  gutterBottom={3}
                />
                <Typography variant="subtitle1" gutterBottom>
                    Funds Management
                </Typography>
                {reimbursementConfiguration.results && (
                  <Typography variant="body1">
                    <b>
                      {fundsManagementLabels[reimbursementConfiguration.results.capType.id]}:{' '}
                    </b>
                    {
                      fundsManagementGuidelinesForSite[
                        reimbursementConfiguration.results?.capType.id
                      ]
                    }
                  </Typography>
                )}
                {reimbursementConfiguration.results &&
                    reimbursementConfiguration.results.capType.id ===
                      capTypesIds.reviewAndApprove && (
                  <List disablePadding>
                    <ListItem disablePadding>
                      <StyledListItemIcon>
                        <Circle size="small" />
                      </StyledListItemIcon>
                      <ListItemText>{displayTextList}</ListItemText>
                    </ListItem>
                  </List>
                )}
              </Card>
            </CardDetailsWrapper>
          </>
        )}
      </WrapperGrid>
      {((isSelectedAccountForAddingFunds && cardDetails.cardStatus !== cardStatus.suspend) ||
        patientInformationForReimbursement.isRestrictedCountry) && <AddFundsGrid />}
      {(isSelectedAccountForAddingFunds ||
        patientInformationForReimbursement.isRestrictedCountry) && (
        <Restriction ability="read" feature="addFundsFundsHistorygrid">
          <FundsHistory />
        </Restriction>
      )}
    </>
  )
}

export default AddFunds
