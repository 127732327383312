import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Paper, Typography } from '@mui/material'
import { replyToMessage, setIsResponding } from 'store/ducks/communicationCenter/studyMessages'
import { showWarning } from 'store/ducks/application'
import { convertHtmlToDraft, convertDraftToHTML, getSelectedId } from 'components/helper/utility'
import { RichTextEditor, Button, AttachFile } from 'components/common'
import { useLocation, useParams } from 'react-router-dom'

const StyledForm = styled(Form)(({ theme }) => ({
  '&&': {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}))

const StyledPaper = styled(Paper)(
  ({ theme }) => `
      padding: ${theme.spacing(1)};
  `
)

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(2)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      flex-wrap: wrap;
      display: flex;
  `
)

const AttachWrapper = styled(Grid)(
  ({ theme }) => `
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacing(2)};
`
)

const validationSchema = Yup.object({
  body: Yup.object().test('is required', function (value) {
    if (convertDraftToHTML(value) === '<p></p>\n') {
      return this.createError({ message: 'Body cannot be empty.' })
    }
    return true
  })
})

const StudyMessageResponse = () => {
  const dispatch = useDispatch()
  const { composeMessage } = useSelector(store => store.studyMessages)
  const { isPatient } = useSelector(store => store.auth)
  const { patientId } = useParams()
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const updatedComposeMessage = {
    ...composeMessage,
    body: convertHtmlToDraft(composeMessage.body)
  }

  return (
    <Formik
      initialValues={updatedComposeMessage}
      enableReinitialize={false}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          replyToMessage(
            {
              attachments: values.attachments,
              body: convertDraftToHTML(values.body)
            },
            patientId,
            selectedCommunicationTabId
          )
        )
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue }) => (
        <StyledForm noValidate>
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledPaper elevation={1}>
                  <RichTextEditor id="body" name="body" />
                </StyledPaper>
              </Grid>
              {isPatient && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" gutterBottom>
                  IMPORTANT: Never send questions or comments regarding your health status and /
                    or the way you are feeling through TCN. You must contact your healthcare
                    provider and / or your study staff directly regarding such matters.
                  </Typography>
                </Grid>
              )}

              <ActionWrapper item xs={12} sm={12} md={6} lg={6}>
                <Button color="primary" type="submit" size="medium" fullWidth={false}>
                  Send
                </Button>
                <Button
                  size="medium"
                  color="inherit"
                  onClick={() => dispatch(setIsResponding(false))}
                >
                  Cancel
                </Button>
              </ActionWrapper>
              <AttachWrapper item xs={4} sm={2} md={6} lg={6}>
                <AttachFile
                  label="Attachment:"
                  name="attachments"
                  onDelete={deletedFile => {
                    setFieldValue('attachmentsToBeDeleted', [
                      ...values.attachmentsToBeDeleted,
                      deletedFile.id
                    ])
                  }}
                  onFileUploadFailure={msg => {
                    dispatch(showWarning(msg))
                  }}
                />
              </AttachWrapper>
            </Grid>
          </>
        </StyledForm>
      )}
    </Formik>
  )
}

export default StudyMessageResponse
