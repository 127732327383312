export const loginFailureReasons = givenReason => {
  const reasons = {
    PasswordExpired: 'Your Password is expired.',
    Invalid_Credentials: 'You have entered invalid credentials.',
    InvalidUser:
          '<span><p>Sponsors / CROs, log in here:</p> <a href="https://www.tcnconnect.com" target="_blank">TCN Connect</a></span>',
    UserDeactivated: 'Your account has been deactivated. Please contact the Concierge for assistance.'
  }

  return reasons[givenReason] || 'Authentication failed, please check your credentials or try again later.'
}

export const resetSuccessMessages = {
  'security-qna': 'A message with the instructions for resetting your security question and answer has been sent to your email.',
  password: 'A message with the instructions for resetting your password has been sent to the email address you provided.'
}
