import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Typography, Grid } from '@mui/material'

import { saveStudyLink } from 'store/ducks/configuration/resourceCenterConfiguration'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import { Button, PageHeader } from 'components/common'
import { TextBoxOuterLabel } from 'components/common/form'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const validationSchema = validationMapper([
  { id: 'studyLink', isResponseRequired: true, answerType: formElements.openText },
  { id: 'displayName', isResponseRequired: true, answerType: formElements.openText },
])

const ResourceCenter = () => {
  const dispatch = useDispatch()
  const { studyLinkInitialValues } = useSelector(store => store.resourceCenterConfiguration)

  return (
    <PageHeader
      title={<Typography variant="h4">Resource Center</Typography>}
      expandContent
      content={
        <Formik
          initialValues={{ ...studyLinkInitialValues }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            dispatch(saveStudyLink(values))
            resetForm()
          }}
        >
          {({ dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextBoxOuterLabel size="medium" id="studyLink" label="Study Link URL" name="studyLink" type="text" />
                </Grid>
                <Grid item xs={0} sm={0} md={6} lg={6} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextBoxOuterLabel size="medium" id="displayName" label="Display Name" name="displayName" type="text" />
                </Grid>
                <Grid item xs={0} sm={0} md={6} lg={6} />

                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ActionWrapper>
                    <Button size="large" fullWidth={false} color="primary" type="submit" disabled={!dirty}>
                      Save
                    </Button>
                  </ActionWrapper>
                </Grid>
                <Grid item xs={0} sm={0} md={6} lg={8} />
              </Grid>
            </Form>
          )}
        </Formik>
      }
      bar={false}
    />
  )
}

export default ResourceCenter
