/* eslint-disable max-lines-per-function */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'

import {
  TextBoxOuterLabel,
  AutoCompleteWithLabel,
  Button,
  DatePickerWithLabel
} from 'components/common'
import {
  fetchSiteStatusesLookup,
  fetchSiteTypesLookup,
  fetchCountriesLookup,
  fetchAllAddressTypes
} from 'store/ducks/common'
import { setAddEditSiteFlow, submitSiteDetails } from 'store/ducks/siteManagement/siteManagement'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import StatesDropdown from 'components/common/form/StatesDropdown'
import { useParams } from 'react-router-dom'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`
const validationSchema = isEditing =>
  validationMapper([
    { id: 'siteId', isResponseRequired: true, answerType: formElements.openText },
    { id: 'siteName', isResponseRequired: true, answerType: formElements.openText },
    { id: 'piLastName', isResponseRequired: false, answerType: formElements.openText },
    { id: 'siteStatus', isResponseRequired: true, answerType: formElements.dropdownMenu },
    { id: 'siteType', isResponseRequired: false, answerType: formElements.dropdownMenu },
    { id: 'screeningTarget', isResponseRequired: false, answerType: formElements.number }, // Number
    { id: 'randomizationTarget', isResponseRequired: false, answerType: formElements.number }, // Number
    // { id: 'plannedSiteActivationDate', isResponseRequired: true, answerType: formElements.date },
    {
      id: 'activationDate',
      isResponseRequired: true,
      answerType: formElements.date,
      dependency: {
        elements: ['siteStatus'],
        callBack: siteStatus =>
          /**
           * required when Statue is Active
           */
          siteStatus?.id === 3
      }
    },
    { id: 'enrollmentPausedDate', isResponseRequired: false, answerType: formElements.openText },
    {
      id: 'reactivationDate',
      isResponseRequired: true,
      answerType: formElements.date,
      dependency: {
        elements: ['siteStatus'],
        callBack: siteStatus =>
          /**
           * required when Statue is Reactivated
           */
          isEditing && siteStatus?.id === 12
      }
    },
    { id: 'droppedDate', isResponseRequired: false, answerType: formElements.date },
    { id: 'closureDate', isResponseRequired: false, answerType: formElements.date },
    { id: 'imAttendanceDate', isResponseRequired: false, answerType: formElements.date }
  ])

const AddEditSiteDetails = () => {
  const dispatch = useDispatch()
  const { siteId } = useParams()
  const { siteTypesLookup, siteStatusesLookup, countriesLookup, allAddressTypes } = useSelector(
    store => store.common
  )
  const { siteDetails } = useSelector(store => store.siteManagement)

  return (
    <Formik
      initialValues={siteDetails}
      enableReinitialize={true}
      validationSchema={validationSchema(!!siteDetails.siteId)}
      onSubmit={values => {
        dispatch(submitSiteDetails({ ...values, encrypttedSiteId: siteId }))
      }}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            {/* Row1 */}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextBoxOuterLabel
                size="small"
                required
                id="siteName"
                label="Site Name"
                name="siteName"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <TextBoxOuterLabel
                size="small"
                required
                id="siteId"
                label="Site ID"
                name="siteId"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            {/* Row2 */}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextBoxOuterLabel
                size="small"
                id="piLastName"
                label="PI Last Name"
                name="piLastName"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <AutoCompleteWithLabel
                id="siteStatus"
                required
                label="Site Status"
                name="siteStatus"
                options={siteStatusesLookup.results}
                isLoading={siteStatusesLookup.isLoading}
                fetchOptions={() => dispatch(fetchSiteStatusesLookup())}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={0.5} lg={0.5} xl={0.5}></Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
              <AutoCompleteWithLabel
                id="siteType"
                label="Site Type"
                name="siteType"
                options={siteTypesLookup.results}
                isLoading={siteTypesLookup.isLoading}
                fetchOptions={() => dispatch(fetchSiteTypesLookup())}
                size="small"
              />
            </Grid>
            {/* Row3 */}
            {siteDetails.siteId && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextBoxOuterLabel
                    size="small"
                    id="irbecName"
                    label="IRB / EC Name"
                    name="irbecName"
                    type="text"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextBoxOuterLabel
                    size="small"
                    id="irbecType"
                    label="IRB / EC Type"
                    name="irbecType"
                    type="text"
                    disabled
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
            {/* Row5 */}

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextBoxOuterLabel
                size="small"
                id="screeningTarget"
                label="Screening Target"
                name="screeningTarget"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextBoxOuterLabel
                size="small"
                id="randomizationTarget"
                label="Randomization Target"
                name="randomizationTarget"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <DatePickerWithLabel
                id="plannedSiteActivationDate"
                label="Planned Activation Date "
                name="plannedSiteActivationDate"
                size="small"
              />
            </Grid>
            {/* Row6 */}

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <DatePickerWithLabel
                id="activationDate"
                label="Initial Activation Date"
                name="activationDate"
                size="small"
                required={values.siteStatus?.id === 3}
              />
            </Grid>
            {siteDetails.siteId && (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DatePickerWithLabel
                    id="enrollmentPausedDate"
                    label="Enrollment Paused Date"
                    name="enrollmentPausedDate"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DatePickerWithLabel
                    id="reactivationDate"
                    label="Reactivation Date"
                    name="reactivationDate"
                    size="small"
                    required={values.siteStatus?.id === 12}
                  />
                </Grid>

                {/* Row7 */}

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DatePickerWithLabel
                    id="droppedDate"
                    label="Dropped Date"
                    name="droppedDate"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DatePickerWithLabel
                    id="closureDate"
                    label="Closure Date"
                    name="closureDate"
                    size="small"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <DatePickerWithLabel
                id="imAttendanceDate"
                label="IM Attendance Date"
                name="imAttendanceDate"
                size="small"
              />
            </Grid>
            {!siteDetails.siteId && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <AutoCompleteWithLabel
                    id="addressType"
                    required
                    label="Address Type"
                    name="addressType"
                    options={allAddressTypes.results}
                    isLoading={allAddressTypes.isLoading}
                    fetchOptions={() => dispatch(fetchAllAddressTypes())}
                    size="medium"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextBoxOuterLabel
                    size="medium"
                    id="locationName"
                    label="Location Name"
                    name="locationName"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextBoxOuterLabel
                    size="medium"
                    required
                    id="address1"
                    label="Address Line 1"
                    name="address1"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextBoxOuterLabel
                    size="medium"
                    id="address2"
                    label="Address Line 2"
                    name="address2"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextBoxOuterLabel
                    size="medium"
                    required
                    id="city"
                    label="City / Town"
                    name="city"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AutoCompleteWithLabel
                    id="country"
                    required
                    label="Country"
                    name="country"
                    options={countriesLookup.results}
                    isLoading={countriesLookup.isLoading}
                    fetchOptions={() => dispatch(fetchCountriesLookup())}
                    size="medium"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <StatesDropdown
                    id="state"
                    required
                    label="State / Province"
                    name="state"
                    size="medium"
                    selectedCountry={values.country?.id}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextBoxOuterLabel
                    size="medium"
                    required
                    id="zipCode"
                    label="Zip / Postal Code"
                    name="zipCode"
                    type="text"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextBoxOuterLabel
                    size="medium"
                    required
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    type="text"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ActionWrapper>
                <Button size="large" fullWidth={false} color="primary" type="submit">
                  Save
                </Button>
                &ensp;&ensp;
                <Button
                  size="large"
                  color="inherit"
                  fullWidth={false}
                  onClick={() => dispatch(setAddEditSiteFlow(false))}
                >
                  Cancel
                </Button>
              </ActionWrapper>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default AddEditSiteDetails
