import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'
import { updateMyInformation, setIsReadOnly, fetchMyInformation, setEmailUpdateModal } from 'store/ducks/myAccount'
import { TextBoxOuterLabel, AutoCompleteWithLabel, Card, PageHeader, Button } from 'components/common'
import ChangePassword from './ChangePassword/ChangePassword'
import ChangePasswordSuccess from './ChangePassword/ChangePasswordSuccess'
import QuickView from 'components/common/QuickView'
import UpdateEmail from './UpdateEmail'

const IconButtonWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    button: {
      marginRight: `${theme.spacing(2)}`
    }
  }
}))

const validationSchema = validationMapper([
  { id: 'firstName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'lastName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'emailAddress', isResponseRequired: true, answerType: formElements.emailOpenText },
  { id: 'businessPhone', isResponseRequired: false, answerType: formElements.openText },
  { id: 'mobilePhone', isResponseRequired: false, answerType: formElements.openText },
  { id: 'role', isResponseRequired: false, answerType: formElements.dropdownMenu }
])

const UpdateInformation = () => {
  const dispatch = useDispatch()
  const { isReadOnly, rolesLookup, myInformation, emailUpdateModal } = useSelector(store => store.myAccount)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [showChangePasswordSuccess, setShowChangePasswordSuccess] = useState(false)

  const closeSuccessModalAndLogout = () => {
    setShowChangePasswordSuccess(false)
  }

  useEffect(() => {
    dispatch(fetchMyInformation())
  }, [])

  const submitHandler = values => {
    dispatch(updateMyInformation(values))
  }

  return (
    <>
      <Formik
        initialValues={myInformation}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        enableReinitialize={true}
      >
        {({ handleReset }) => (
          <Form>
            <Card
              radius={false}
              cardStyles={{ padding: '46px' }}
              cardContentStyles={{ padding: '8px' }}
              cardBottom={
                !isReadOnly && (
                  <>
                    <Button size="large" fullWidth={false} color="primary" type="submit">
                          Save
                    </Button>
                        &ensp;&ensp;
                    <Button
                      size="large"
                      color="inherit"
                      fullWidth={false}
                      onClick={() => {
                        handleReset()
                        dispatch(setIsReadOnly(true))
                      }}
                    >
                          Cancel
                    </Button>
                  </>
                )
              }
            >
              <PageHeader
                bar={false}
                expandContent={true}
                actionContent={
                  <IconButtonWrapper>
                    {isReadOnly && (
                      <Button
                        color="secondary"
                        aria-label="Edit"
                        onClick={() => dispatch(setIsReadOnly(false))}
                        startIcon={<Edit />}
                        size="small"
                      >
                            Edit
                      </Button>
                    )}
                    <Button
                      color="secondary"
                      label="Change Password"
                      size="small"
                      onClick={() => setShowChangePassword(true)}
                      fullWidth={false}
                    >
                          Change Password
                    </Button>
                  </IconButtonWrapper>
                }
                content={
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
                      <TextBoxOuterLabel
                        size="small"
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        type="text"
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
                      <TextBoxOuterLabel
                        size="small"
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        type="text"
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
                      <AutoCompleteWithLabel
                        id="role"
                        label="Role"
                        name="role"
                        options={rolesLookup}
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5}></Grid>
                    <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
                      <TextBoxOuterLabel
                        size="small"
                        id="businessPhone"
                        label="Business Phone"
                        name="businessPhone"
                        type="text"
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
                      <TextBoxOuterLabel
                        size="small"
                        id="mobilePhone"
                        label="Mobile Phone"
                        name="mobilePhone"
                        type="text"
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
                      <TextBoxOuterLabel
                        size="small"
                        id="emailAddress"
                        label="Email Address"
                        name="emailAddress"
                        type="text"
                        disabled={true}
                      />
                      <Button variant='text' onClick={() => dispatch(setEmailUpdateModal(true))}>Username / Email Address Change Request</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}></Grid>
                  </Grid>
                }
              />
            </Card>
          </Form>
        )}
      </Formik>
      <QuickView
        title={'Change Password'}
        onClose={() => setShowChangePassword(false)}
        dialogContent={
          <ChangePassword
            setShowChangePassword={setShowChangePassword}
            setShowChangePasswordSuccess={setShowChangePasswordSuccess}
          />
        }
        isDialogOpen={showChangePassword}
        isSmallDialog={true}
      />
      <QuickView
        title={'Username / Email Address Change Request'}
        onClose={() => dispatch(setEmailUpdateModal(false))}
        dialogContent={
          <UpdateEmail currentEmail={myInformation.emailAddress}/>
        }
        isDialogOpen={emailUpdateModal}
        isSmallDialog={true}
      />
      <QuickView
        title={'Password changed successfully!'}
        onClose={closeSuccessModalAndLogout}
        dialogContent={
          <ChangePasswordSuccess setShowChangePasswordSuccess={setShowChangePasswordSuccess} />
        }
        isDialogOpen={showChangePasswordSuccess}
        isSmallDialog={true}
      />
    </>
  )
}

export default UpdateInformation
