import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { copyObject, removeTime } from 'components/helper/utility'

import { getAuxillarySupportTypes } from 'services/common'
import { addAuxillarySupportRequest, getAuxillarySupportDetails } from 'services/rsgArrive'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import { setOpenSubmitSuccess, setStartNewRequestForVisitFlow } from './requestDetails'
import { auxillarySupportTypeIds } from 'components/helper/constants/visitServices'
import { fetchLanguagesLookup } from '../common'

const setAuxillarySupportTypes = createAction('auxillaryRequest/setAuxillarySupportTypes')
export const setAuxillarySupportRequestForm = createAction(
  'auxillaryRequest/setAuxillarySupportRequestForm'
)
export const resetAuxillarySupport = createAction('auxillaryRequest/resetAuxillarySupport')

export const fetchAuxillarySupportTypes = () => async (dispatch, getState) => {
  try {
    const { auxillarySupportTypes } = getState().auxillarySupportRequest
    await dispatch(fetchLanguagesLookup())
    if (auxillarySupportTypes.results.length === 0) {
      dispatch(setAuxillarySupportTypes({ isLoading: true }))
      const auxillarySupportTypes = await getAuxillarySupportTypes()
      dispatch(setAuxillarySupportTypes({ results: auxillarySupportTypes }))
    }
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  }
  dispatch(setAuxillarySupportTypes({ isLoading: false }))
}

export const fetchAuxillarySupportDetails = requestId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    await dispatch(fetchAuxillarySupportTypes())
    const res = await getAuxillarySupportDetails(requestId)
    const { auxillarySupportRequestForm } = getState().auxillarySupportRequest
    let formattedAuxillarySupportDetails = { ...auxillarySupportRequestForm }
    formattedAuxillarySupportDetails = {
      ...formattedAuxillarySupportDetails,
      visitNames: res.visitName,
      visitDate: res.visitDate,
      reachDateTime: res.bestTimeToReach || '',
      reachPhoneNumber: res.bestPhoneNumber || ''
    }

    const supportServices = []
    res.selectedServices.forEach(service => {
      // selected support services
      supportServices.push({
        id: `${service.supportTypeId}`,
        displayText: service.supportTypeName
      })

      // other form elements inside each checkbox's detailed view
      if (service.supportTypeId === parseInt(auxillarySupportTypeIds.interpreterSupport)) {
        formattedAuxillarySupportDetails = {
          ...formattedAuxillarySupportDetails,
          interpreterWhenNeeded: service.isAvailableByPhone
            ? 'interpreteravailablebyphone'
            : 'interpreteravailableinperson',
          details: service.note,
          patientCaregiverPreferredLanguage: service.patientPreferredLanguage,
          patientCaregiverSecondaryLanguage: service.staffPreferredLanguage
        }
      } else if (service.supportTypeId === parseInt(auxillarySupportTypeIds.specialAssistance)) {
        formattedAuxillarySupportDetails = {
          ...formattedAuxillarySupportDetails,
          accommodationsDetails: service.note
        }
      } else if (
        service.supportTypeId === parseInt(auxillarySupportTypeIds.professionalCompanion)
      ) {
        formattedAuxillarySupportDetails = {
          ...formattedAuxillarySupportDetails,
          companionDetails: service.note
        }
      }
    })
    formattedAuxillarySupportDetails = {
      ...formattedAuxillarySupportDetails,
      supportServices
    }
    dispatch(setAuxillarySupportRequestForm(formattedAuxillarySupportDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  }
  dispatch(setLoading(false))
}

export const submitAuxillarySupportRequest =
  (data, parentRequestId) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { patientInformation } = getState().patientMainWrapper
      const selectedSupportServices = data.supportServices.map(service => service.id)
      const auxillarySupportTypes = []
      if (selectedSupportServices.includes(auxillarySupportTypeIds.interpreterSupport)) {
        auxillarySupportTypes.push({
          auxillarySupportType: parseInt(auxillarySupportTypeIds.interpreterSupport),
          note: data.details,
          isAvailableByPhone: data.interpreterWhenNeeded === 'interpreteravailablebyphone',
          isAvailableInPerson: data.interpreterWhenNeeded === 'interpreteravailableinperson',
          patientPreferredLanguageId: data.patientCaregiverPreferredLanguage.id,
          staffPreferredLanguageId: data.patientCaregiverSecondaryLanguage.id
        })
      }
      if (selectedSupportServices.includes(auxillarySupportTypeIds.specialAssistance)) {
        auxillarySupportTypes.push({
          auxillarySupportType: parseInt(auxillarySupportTypeIds.specialAssistance),
          note: data.accommodationsDetails,
          isAvailableByPhone: null,
          isAvailableInPerson: null,
          patientPreferredLanguageId: null,
          staffPreferredLanguageId: null
        })
      }
      if (selectedSupportServices.includes(auxillarySupportTypeIds.professionalCompanion)) {
        auxillarySupportTypes.push({
          auxillarySupportType: parseInt(auxillarySupportTypeIds.professionalCompanion),
          note: data.companionDetails,
          isAvailableByPhone: null,
          isAvailableInPerson: null,
          patientPreferredLanguageId: null,
          staffPreferredLanguageId: null
        })
      }

      const requestData = {
        patientId: patientInformation.userId,
        visitNameId: data.visitNames?.id,
        visitDate: removeTime(data.visitDate),
        auxillarySupportTypes,
        bestTimeToReach: data.reachDateTime,
        bestPhoneNumber: data.reachPhoneNumber,
        ...(!!parentRequestId && {
          parentRequestId
        })
      }
      await addAuxillarySupportRequest(requestData)
      await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
      dispatch(setOpenSubmitSuccess(true))
      // Save data for StartNewRequestForVisitFlow,
      // in case user selects Yes in SubmitSuccess Confirmation box
      // This will be cleared if user selects No.
      dispatch(
        setStartNewRequestForVisitFlow({
          visitDate: removeTime(data.visitDate),
          visitNames: data.visitNames
        })
      )
      dispatch(showSuccess('Request submitted successfully!'))
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    } finally {
      dispatch(setLoading(false))
    }
  }

const initialState = {
  auxillarySupportTypes: { isLoading: false, results: [] },
  auxillarySupportRequestForm: {
    visitNames: null,
    visitDate: '',
    supportServices: [],
    reachDateTime: '',
    reachPhoneNumber: '',
    interpreterWhenNeeded: '',
    details: '',
    patientCaregiverPreferredLanguage: null,
    patientCaregiverSecondaryLanguage: null,
    accommodationsDetails: '',
    companionDetails: ''
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setAuxillarySupportTypes, (state, action) => {
      state.auxillarySupportTypes = { ...state.auxillarySupportTypes, ...action.payload }
    })
    .addCase(setAuxillarySupportRequestForm, (state, action) => {
      state.auxillarySupportRequestForm = action.payload
    })
    .addCase(resetAuxillarySupport, state => {
      copyObject(state, initialState)
    })
})
