import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { AutoCompleteWithLabel, TextBoxOuterLabel } from 'components/common'
import { useParams } from 'react-router-dom'

const AutoCompleteWrapper = styled(Grid)(() => ({
  '&&': {
    '.MuiFormLabel-root': {
      position: 'unset'
    }
  }
}))

const SmallTextTypography = styled(Typography)`
  line-height: 1.5;
  font-weight: normal;
  font-style: italic;
`
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const GroundTransportation = () => {
  const { mobilityTypes } = useSelector(store => store.inPersonRequest)
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextBoxOuterLabel
          id="groundTransportationException"
          data-testid="groundTransportationException"
          label={
            <Typography variant="body2" gutterBottom>
              We will be providing ground transportation for all legs of the trips. If there are any
              exceptions, please specify below. If there are no exceptions, please write
              &quot;none&quot;.
            </Typography>
          }
          name="groundTransportationException"
          multiline={true}
          rows={3}
          placeholder="Exception Details"
          disabled={readOnly}
        />
      </Grid>
      <AutoCompleteWrapper item xs={12} sm={12} md={12} lg={12} xl={12}>
        <AutoCompleteWithLabel
          multiple={true}
          id="mobilityAssistance"
          label={
            <LabelWrapper>
              <Typography variant="body2" gutterBottom>
                Mobility Assistance For Non-Independent Travelers
              </Typography>
              <Typography variant="body1" gutterBottom>
                Our services, vehicles and specially trained drivers can provide travelers with
                additional mobility assistance. Please select as many as apply below:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Mobility Assistance
              </Typography>
            </LabelWrapper>
          }
          name="mobilityAssistance"
          options={mobilityTypes.results}
          disabled={readOnly}
          resetOnUnMount={false}
        />
      </AutoCompleteWrapper>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextBoxOuterLabel
          id="groundTransportOtherPreferences"
          data-testid="groundTransportOtherPreferences"
          label={
            <LabelWrapper>
              <SmallTextTypography variant="caption" gutterBottom>
                If ambulance or any other medical ground transportation beyond a WAV vehicle is
                needed, please enter details below.
              </SmallTextTypography>
              <Typography variant="body2" gutterBottom>
                Other Relevant Considerations
              </Typography>
            </LabelWrapper>
          }
          name="groundTransportOtherPreferences"
          multiline={true}
          rows={3}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  )
}

export default GroundTransportation
