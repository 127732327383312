import fileDownload from 'js-file-download'
import { backendAxios, getConfig, getConfigForDownload } from './backend'

export const getStudyLinks = () =>
  backendAxios
    .get('/resourcecenter/studylinks', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getAdminDocuments = () =>
  backendAxios
    .get('/resourcecenter/admindocuments', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
export const getUserGuides = () =>
  backendAxios
    .get('/resourcecenter/userguides', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
export const getStudySiteStaffDocuments = siteClientId =>
  backendAxios
    .get('/resourcecenter/studysitestaffdocuments', getConfig(siteClientId))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
export const getPatientStudyToolsDocuments = siteClientId =>
  backendAxios
    .get('/resourcecenter/patientstudytoolsdocuments', getConfig(siteClientId))
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getUserGuidesDocsByID = attachment =>
  backendAxios
    .get(`/resourcecenter/userguidesdocument/${attachment.id}`, getConfigForDownload())
    .then(response => Promise.resolve(response))
    .catch(err => Promise.reject(err.message))

export const downloadUserGuidesDocsByID = attachment =>
  backendAxios
    .get(`/resourcecenter/userguidesdocument/${attachment.id}`, getConfigForDownload())
    .then(response => {
      fileDownload(response.data, `${attachment.name}.pdf`)
      return Promise.resolve('message')
    })
    .catch(err => Promise.reject(err.message))
