import React from 'react'
import styled from '@emotion/styled'
import { TextField } from '@mui/material'
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useField } from 'formik'
import PropTypes from 'prop-types'

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  '&&': {
    '.MuiInputLabel-root': {
      transform: 'unset',
      display: 'flex',
      fontWeight: '600',
    },
    '.MuiOutlinedInput-root': {
      marginTop: theme.spacing(2.4),
      minHeight: '45px',
      backgroundColor: theme.palette.white.dark,
      paddingTop: '4px',
      fieldset: {
        top: '-5px',
        border: `2px solid ${error ? theme.palette.error.main : theme.palette.background.disabled}`,
      },
    },
    '.Mui-disabled': {
      fieldset: {
        backgroundColor: `${theme.palette.background.disabled}`,
        border: 'unset',
      },
      input: {
        'z-index': `${theme.zIndex.minorUiElement}`,
      },
      '.MuiAutocomplete-endAdornment': {
        svg: {
          color: `${theme.palette.gray.dark}`,
        },
      },
      '.MuiButtonBase-root': {
        'z-index': `${theme.zIndex.minorUiElement}`,
      },
    },
  },
}))

const DatePicker = props => {
  const {
    name,
    label,
    maxDate,
    minDate,
    required,
    id,
    size,
    formik,
    onChange,
    onPostChange,
    onAccept,
    onBlur,
    error,
    helperText,
    value,
    autoFocus,
    color,
    disabled,
    fullWidth,
    inputProps,
    margin,
    variant,
    views,
    openTo,
  } = props

  let field, meta, setTouched, setValue

  const onFieldChange = newValue => {
    !isNaN(newValue) && setValue(newValue)
    // Call onPostChange if provided
    if (onPostChange) {
      onPostChange()
    }
  }

  if (formik === 'true') {
    [field, meta, { setValue, setTouched }] = useField(props)
    field = {
      ...field,
      value: field.value,
      onChange: onFieldChange,
      onBlur: setTouched,
    }
  } else {
    field = {
      onChange,
      onBlur,
      value,
    }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        id={id}
        name={name}
        label={label}
        autoFocus={autoFocus}
        color={color}
        disabled={disabled}
        fullWidth={fullWidth}
        inputProps={inputProps}
        margin={margin}
        variant={variant}
        views={views}
        openTo={openTo}
        onAccept={value => {
          onAccept && onAccept(value)
          field.onChange(value)
        }}
        allowSameDateSelection={true}
        value={field.value}
        onChange={value => {
          field.onChange(value)
        }}
        onBlur={value => {
          field.onBlur(value)
        }}
        renderInput={params => {
          const { InputLabelProps, InputProps } = params
          return (
            <StyledTextField
              {...params}
              InputLabelProps={{ ...InputLabelProps, shrink: true }}
              InputProps={{ ...InputProps, notched: false }}
              required={required}
              size={size}
              fullWidth
              error={isError}
              helperText={errorText}
              onBlur={field.onBlur}
              label={label}
            />
          )
        }}
        maxDate={maxDate}
        minDate={minDate}
      />
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  /**
   * Dates after the provided date will be masked/disabled.
   */
  maxDate: PropTypes.object,
  /**
   * Dates before the provided date will be masked/disabled.
   */
  minDate: PropTypes.object,
  /**
   * By default focuses on to this component.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),

  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),

  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * If `true`, the input will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.string,

  /**
   * Props applied to the Input element.
   * It will be a [`FilledInput`](/api/filled-input/),
   * [`OutlinedInput`](/api/outlined-input/) or [`Input`](/api/input/)
   * component depending on the `variant` prop value.
   */
  inputProps: PropTypes.object,
  /**
   * The label content.
   */
  label: PropTypes.node,
  /**
   * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
   * @default 'none'
   */
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * Optional prop. If provided, call post onChange
   */
  onPostChange: PropTypes.func,
  /**
   * Callback fired when the value is accepted.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onAccept: PropTypes.func,
  /**
   * onBlur function
   */
  onBlur: PropTypes.func,
  /**
   * If `true`, the label is displayed as required and the `input` element is required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * The size of the component.
   * @default small
   */
  size: PropTypes.oneOf(['medium', 'small']),

  /**
   * The value of the `input` element,
   * required for a controlled component.
   */
  value: PropTypes.any,
  /**
   * The variant to use.
   * @default 'outlined'
   */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  /**
   * Pass an array of what datepicker view is required
   * ex: ['month'], ['year', 'month', 'day']
   */
  views: PropTypes.array,
  /**
   * First view to show. Must be a valid option from views list
   */
  openTo: PropTypes.oneOf(['year', 'month', 'day']),
}

DatePicker.defaultProps = {
  variant: 'outlined',
  color: 'primary',
  size: 'small',
  formik: 'true',
  fullWidth: true,
  autoFocus: false,
  disabled: false,
  error: false,
  margin: 'none',
  multiline: false,
  required: false,
  views: ['year', 'month', 'day'],
  openTo: 'day',
}

export default DatePicker
