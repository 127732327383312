import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DataGrid, DatePickerWithLabel } from 'components/common'
import { palette } from 'styles/baseTheme'
import { Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTransactionHistory, handleTransactionHistorySort } from 'store/ducks/patientRecord/manageAccount'
import { AttachMoney, CalendarMonthRounded, Description } from '@mui/icons-material'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const bodyProperties = { textAlign: 'start', paddingLeft: '16px' }
const headProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.gray.main}`,
  textAlign: 'start',
  padding: '8px'
}
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 4px;
    margin-left: 4px;
  }
`

const transactionHistoryHeader = [
  {
    id: 'description',
    label: (
      <HeaderWrapper>
        <Description />
        <Typography variant="body2">Description</Typography>
      </HeaderWrapper>
    ),
    headProperties: { ...headProperties, width: '110px', cellType: 'node' },
    bodyProperties: { ...bodyProperties }
  },
  {
    id: 'date',
    label: (
      <HeaderWrapper>
        <CalendarMonthRounded />
        <Typography variant="body2">Date</Typography>
      </HeaderWrapper>
    ),
    headProperties: { ...headProperties, width: '110px', cellType: 'node' },
    bodyProperties: { ...bodyProperties },
    isSortable: true,
  },
  {
    id: 'amount',
    label: (
      <HeaderWrapper>
        <AttachMoney />
        <Typography variant="body2">Amount</Typography>
      </HeaderWrapper>
    ),
    headProperties: { ...headProperties, width: '110px', cellType: 'node' },
    bodyProperties: { ...bodyProperties },
    isSortable: true,
  }
]

const TransactionHistory = ({ reimbursementCardId }) => {
  const dispatch = useDispatch()
  const { transHistory } = useSelector(store => store.manageAccount)
  const [monthYear, setMonthYear] = useState(new Date())

  useEffect(() => {
    dispatch(
      fetchTransactionHistory(
        reimbursementCardId,
        monthYear?.getUTCMonth() + 1,
        monthYear?.getUTCFullYear()
      )
    )
  }, [monthYear])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="body2">
          Your transaction history is not shared with your study staff.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Formik
          initialValues={{ transactionsByMonth: monthYear }}
          enableReinitialize={false}
          validationSchema={validationMapper([
            {
              id: 'transactionsByMonth',
              isResponseRequired: true,
              answerType: formElements.date,
              condition: {
                max: new Date(),
                maxError: 'Year-month value must be in the past'
              },
              error: 'Year-month is required and must be a valid value'
            }
          ])}
          onSubmit={(values, { setSubmitting }) => {
            setMonthYear(values.transactionsByMonth)
            setSubmitting(false)
          }}
        >
          {() => (
            <Form noValidate>
              <DatePickerWithLabel
                formik="true"
                id="transactionsByMonth"
                label="Transactions By Month"
                name="transactionsByMonth"
                size="small"
                maxDate={new Date()}
                views={['year', 'month']}
                openTo="month"
                onAccept={value => {
                  setMonthYear(value)
                }}
              />
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DataGrid
          headers={transactionHistoryHeader}
          tableData={transHistory.results}
          setRequestedSort={orderByValue => dispatch(handleTransactionHistorySort(orderByValue))}
          order={transHistory.order}
          orderBy={transHistory.orderBy}

          border="allSides"
          radius={'false'}
          alternateRowColored={true}
        />
      </Grid>
    </Grid>
  )
}

TransactionHistory.propTypes = {
  reimbursementCardId: PropTypes.string
}

export default TransactionHistory
