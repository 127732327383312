import { backendAxios, getConfig } from './backend'

export const getAssignmentsPerPolicy = async () => {
  try {
    const response = await backendAxios.get('/smartAlert/assignmentsperpolicy', getConfig())
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const getModuleAssignmentsPerPolicy = async (moduleId, policies, actionRoles) => {
  try {
    const response = await backendAxios.get(
      '/smartAlert/moduleassignmentsperpolicy',
      getConfig({ moduleId, policies, actionRoles })
    )
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const getModulesForTriggers = async () => {
  try {
    const response = await backendAxios.get('/common/modulesfortriggers', getConfig())
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const getPolicies = async () => {
  try {
    const response = await backendAxios.get('/common/policies', getConfig())
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const getBBKActionRoles = async () => {
  try {
    const response = await backendAxios.get('/common/bbkactionroles', getConfig())
    const result = response.data.filter(option => option.displayText !== 'Concierge')
    return result
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const getExistingEmailTriggers = async (moduleId, policies, actionRoles) => {
  try {
    const response = await backendAxios.get(
      '/smartAlert/existingemailtriggers',
      getConfig({ moduleId, policies, actionRoles })
    )
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const saveAssignmentsPerPolicy = async newAssignmentsData => {
  const response = await backendAxios.post(
    '/smartAlert/assignmentsperpolicy',
    newAssignmentsData,
    getConfig()
  )
  return response.data
}

export const saveExistingEmailTriggers = async emailTriggersData => {
  const response = await backendAxios.post(
    '/smartAlert/existingemailtriggers',
    emailTriggersData,
    getConfig()
  )
  return response.data
}
