export const requestType = {
  inPersonVisit: 'In-person Visit',
  deliveryServices: 'Delivery Services',
  homeHealthVisit: 'Home Health Visit',
  telehealthVisit: 'Telehealth Visit',
  advancedSupport: 'Advanced Support',
  auxillarySupport: 'Auxillary Support',
  otherServices: 'Other Remote Support',
  remoteRequest: 'Remote Request'
}

export const requestTypeFromId = {
  1: requestType.inPersonVisit,
  2: requestType.deliveryServices,
  3: requestType.homeHealthVisit,
  4: requestType.telehealthVisit,
  5: requestType.advancedSupport,
  6: requestType.auxillarySupport,
  7: requestType.otherServices
}

/**
 * Order
 * inPersonVisit
 * auxillarySupport
 * advancedSupport
 * deliveryServices
 * homeHealthVisit
 * telehealthVisit
 * otherServices
 */
export const requestTypeOrder = [1, 6, 5, 2, 3, 4, 7]

export const remoteRequestTypes = [
  requestType.deliveryServices,
  requestType.homeHealthVisit,
  requestType.telehealthVisit,
  requestType.otherServices
]

export const requestTypeIds = {
  inPersonVisit: 1,
  deliveryServices: 2,
  homeHealthVisit: 3,
  telehealthVisit: 4,
  advancedSupport: 5,
  auxillarySupport: 6,
  otherServices: 7
}

export const remoteRequestIds = [2, 3, 4, 7]

export const pages = {
  dashboard: 'dashboard',
  makeARequest: 'makeARequest',
  requestDetails: 'requestDetails',
  selectRequestType: 'selectRequestType'
}

export const requestLabel = {
  inPersonVisit: 'In-Person Visit Travel',
  auxillarySupport: 'Additional Support',
  advancedSupport: 'Advanced Support',
  remoteRequest: 'Remote Support'
}

export const requestStatusId = {
  InProcess: 1,
  InProcessApproved: 2,
  InProcessNotApproved: 5,
  Completed: 3,
  Cancelled: 4
}

export const requestStatusLabels = {
  InProcess: 'In Process',
  InProcessApproved: 'In Process Approved',
  Completed: 'Completed',
  Cancelled: 'Cancelled'
}

export const assignment = {
  site: 'Site',
  agent: 'Agent',
  concierge: 'Concierge'
}

export const addressType = {
  siteLocation: 'siteLocation',
  patientResidence: 'patientResidence'
}

export const addressTypeLabel = {
  siteLocation: 'Site Location',
  patientResidence: 'Patient Residence'
}

export const multiActionModalTypes = {
  uploadFile: 'uploadFile',
  writeMessage: 'writeMessage',
  sendToSite: 'sendToSite'
}

export const modalActionModalTitles = {
  uploadFile: 'Upload Service / Itinerary Details',
  writeMessage: 'Write Message',
  sendToSite: 'Send Itinerary / Service Details to Site'
}

export const servicesIdForVisit = {
  ticketedTravel: '0',
  hotelAccommodations: '1',
  groundTransportation: '2'
}

export const inPersonServices = [
  {
    id: servicesIdForVisit.ticketedTravel,
    displayText: 'Ticketed Travel',
  },
  {
    id: servicesIdForVisit.hotelAccommodations,
    displayText: 'Hotel / Accommodations',
  },
  {
    id: servicesIdForVisit.groundTransportation,
    displayText: 'Ground Transportation',
  }
]

export const auxillarySupportTypeIds = {
  interpreterSupport: '1',
  specialAssistance: '2',
  professionalCompanion: '3'
}

export const selectPatientAddressCopy =
  'Location must be where the patient resides at the time of pick-up / delivery. If the home address does not meet this criteria, please add a new address (e.g. hotel).'

export const subRequestTypes = {
  'Additional Support': [
    'Intepreter Support',
    'Longer-term Stay Support',
    'Professional Companion'
  ],
  'Advanced Support': [
    'Legal Support for International Travel Needs',
    'Medical Record / Official Document Request and Translation',
    'Medical / Specialty Transportation',
    'Nutrition Support / Meal Delivery'
  ]
}
