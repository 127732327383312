import { backendAxios, getConfig } from '../backend'

export const getArriveRequestTypes = () =>
  backendAxios
    .get('/common/rsgarriverequesttype', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getArriveRequestCountries = () =>
  backendAxios
    .get('/configuration/rsgarriverequestcountries', getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const saveArriveRequestCountries = updatedArriveRequestCountries =>
  backendAxios
    .post('/configuration/rsgarriverequestcountries', updatedArriveRequestCountries, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))
