export const requestSummaryHeaders = {
  DisplayName: 'Project Name',
  ProtocolCode: 'Sponsor / CRO Project ID',
  SiteCountry: 'Site Country',
  SiteID: 'Site ID',
  TCNCreatedDate: 'TCN # Created Date',
  TcnId: 'TCN #',
  SubjectID: 'Subject ID',
  CountryofResidence: 'Country of Residence',
  ParticipationProgressStatus: 'Participation Progress / Status',
  RequestNumber: 'Request Number',
  RequestStatus: 'Request Status',
  RequestType: 'Request Type',
  SelectedServices: 'Selected Services',
  RequestDate: 'Request Date',
  VisitName: 'Visit Name',
  VisitDate: 'Visit Date',
  ItineraryVersion: 'Itinerary / Service Details Document Version',
  CurrentAssignment: 'Current Assignment',
  ReqNotApprovedDate: 'Request Not Approved Date',
  ReqNotApprovedBy: 'Request Not Approved By',
  ReqApprovedDate: 'Request Approved Date',
  ReqApprovedBy: 'Request Approved By',
  ReqCompletedDate: 'Request "Marked as Completed" Date',
  ReqCompletedBy: 'Request "Marked as Completed" By',
  ReqCancelledDate: 'Request Cancelled Date',
  ReqCancelledBy: 'Request Cancelled By',
  StatusWhenCancelled: 'Status When Cancelled',
  NewReqNumber: 'New Request Made Based Off of Cancelled Request'
}

export const conciergeScrumHeaders = {
  DisplayName: 'Project Name',
  ProtocolCode: 'Sponsor / CRO Project ID',
  SiteCountry: 'Site Country',
  SiteID: 'Site ID',
  TcnId: 'TCN #',
  CountryofResidence: 'Country of Residence',
  RequestNumber: 'Request Number',
  RequestStatus: 'Request Status',
  SelectedServices: 'Selected Services',
  RequestDate: 'Request Date',
  VisitDate: 'Visit Date',
  CurrentAssignment: 'Current Assignment'
}
