import React, { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Button } from 'components/common'
import Logout from 'components/private/Logout'

const ActionWrapper = styled.div(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(3)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      display: flex;
      align-items: start;
      justify-content: flex-start;
  `
)
const StyledButton = styled(Button)(
  ({ theme }) => `
    margin: ${theme.spacing(1)};
`
)

const ChangePasswordSuccess = () => {
  const [logoutUser, setLogoutUser] = useState(false)

  return logoutUser
    ? (
      <Logout />
    )
    : (
      <React.Fragment>
        <div>Password was changed successfully!</div>
        <div>You will now be now be logged out. Please relogin with your new password</div>
        <ActionWrapper item xs={12} sm={12} md={12} lg={12}>
          <StyledButton
            size="large"
            fullWidth={false}
            color="secondary"
            onClick={() => setLogoutUser(true)}
          >
          Okay
          </StyledButton>
        </ActionWrapper>
      </React.Fragment>
    )
}

ChangePasswordSuccess.propTypes = {
  setShowChangePasswordSuccess: PropTypes.func
}

export default ChangePasswordSuccess
