import React from 'react'
import styled from '@emotion/styled'
import { ListItemIcon, Grid, ListItem, ListItemText, List } from '@mui/material'
import { Clear, Done } from '@mui/icons-material'
import PropTypes from 'prop-types'

const ListIcon = styled(ListItemIcon)`
  && {
    min-width: 24px;
    color: #b3b3b3;
  }
`

const ConstraintGrid = styled(Grid)`
  && {
    padding: 0px !important;
  }
`

const ConstraintItem = styled(ListItem)`
  && {
    padding-top: 2px;
    padding-bottom: 0;
  }
`
const CheckIcon = styled(Done)`
  color: ${props => props.theme.green};
`

const PasswordConstraint = ({ disabled, rule }) => (
  <ConstraintGrid item xs={12} sm={6} md={6} lg={6}>
    <ConstraintItem disabled={disabled} disableGutters>
      <ListIcon>{disabled ? <Clear fontSize="small" /> : <CheckIcon fontSize="small" />}</ListIcon>
      <ListItemText primary={rule} />
    </ConstraintItem>
  </ConstraintGrid>
)

PasswordConstraint.propTypes = {
  disabled: PropTypes.bool,
  rule: PropTypes.string,
}

const PasswordConstraintsCheck = ({ enteringNewPassword }) => (
  <List dense disablePadding>
    <Grid container>
      <PasswordConstraint disabled={!enteringNewPassword.match(/.{8,}$/gm)} rule="Minimum length 8" />
      <PasswordConstraint
        disabled={!enteringNewPassword.match(/(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/gm)}
        rule="Special character"
      />
      <PasswordConstraint disabled={!enteringNewPassword.match(/([a-z])/gm)} rule="Lower case [a-z]" />
      <PasswordConstraint disabled={!enteringNewPassword.match(/([A-Z])/gm)} rule="Upper case [A-Z]" />
      <PasswordConstraint disabled={!enteringNewPassword.match(/([0-9])/gm)} rule="Numeric [0-9]" />
    </Grid>
  </List>
)

PasswordConstraintsCheck.propTypes = {
  enteringNewPassword: PropTypes.string,
}

export default PasswordConstraintsCheck
