import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { showWarning } from 'store/ducks/application'
import {
  fetchArriveRequestsAssignments,
  multiActionFormInitialState,
  saveRequestMessage,
  setMessageSaved,
  setMultiActionForm
} from 'store/ducks/visitServices/requestDetails'
import { downloadAttachment } from 'store/ducks/communicationCenter/announcements'
import { Button, TextBoxOuterLabel, AutoCompleteWithLabel, AttachFile } from 'components/common'
import { assignment, multiActionModalTypes } from 'components/helper/constants/visitServices'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const AlignCenterGrid = styled(Grid)`
  text-align: center;
`
const AlignLeftGrid = styled(Grid)`
  text-align: left;
`

const validationSchema = isUploadFileModal =>
  validationMapper([
    { id: 'note', isResponseRequired: true, answerType: formElements.openText },
    {
      id: 'assignment',
      isResponseRequired: true,
      answerType: formElements.dropdownMenu
    },
    {
      id: 'attachments',
      isResponseRequired: isUploadFileModal,
      answerType: formElements.attachment
    }
  ])

const WriteMessage = ({ closeWriteMessageModal, modalType }) => {
  const dispatch = useDispatch()
  const { requestId } = useParams()
  const { arriveRequestsAssignments, messageSaved, multiActionForm } = useSelector(
    store => store.requestDetails
  )

  useEffect(() => {
    dispatch(fetchArriveRequestsAssignments(modalType))
    return () => {
      dispatch(setMultiActionForm(multiActionFormInitialState))
    }
  }, [])

  useEffect(() => {
    if (messageSaved) {
      closeWriteMessageModal()
      dispatch(setMessageSaved(false))
    }
  }, [messageSaved])

  let assignmentDropdownIsDisabled = false

  if (
    (arriveRequestsAssignments.results.length === 1 &&
      arriveRequestsAssignments.results[0].displayText === assignment.concierge) ||
    modalType === multiActionModalTypes.sendToSite
  ) {
    // If there is only 1 Assignee - Concierge, default to it and disable dropdown.
    // OR
    // For Send to Site, Assignment should be disabled and defaulted to Site
    assignmentDropdownIsDisabled = true
  }

  return (
    <>
      <Formik
        initialValues={multiActionForm}
        enableReinitialize={true}
        validationSchema={validationSchema(modalType === multiActionModalTypes.uploadFile)}
        onSubmit={values => {
          dispatch(saveRequestMessage(requestId, modalType, values))
        }}
      >
        {() => (
          <Form>
            <AlignCenterGrid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteWithLabel
                  id="assignment"
                  required
                  label="Assignment"
                  name="assignment"
                  options={arriveRequestsAssignments.results}
                  isLoading={arriveRequestsAssignments.isLoading}
                  size="small"
                  formik="true"
                  disabled={assignmentDropdownIsDisabled}
                />
              </Grid>
              {modalType !== multiActionModalTypes.sendToSite && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AttachFile
                    label="Attach File:"
                    name="attachments"
                    downloadAttachment={file => dispatch(downloadAttachment(file))}
                    onDelete={() => {}}
                    multiple={false}
                    onFileUploadFailure={msg => {
                      dispatch(showWarning(msg))
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  formik="true"
                  size="small"
                  id="note"
                  label=""
                  placeholder="Notes"
                  name="note"
                  type="text"
                  rows="7"
                  multiline
                />
              </Grid>
              <AlignLeftGrid item xs={12} sm={12} md={12} lg={12}>
                <Button size="large" fullWidth={false} color="primary" type="submit">
                  Save
                </Button>
              </AlignLeftGrid>
            </AlignCenterGrid>
          </Form>
        )}
      </Formik>
    </>
  )
}

WriteMessage.propTypes = {
  closeWriteMessageModal: PropTypes.func,
  modalType: PropTypes.string
}

export default WriteMessage
