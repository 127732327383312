import { createAction, createReducer } from '@reduxjs/toolkit'

export const handleUserMessageBar = createAction('userMessageBar/handleUserMessageBar')

export const openUserMessageBar =
  ({
    verticalPosition,
    horizontalPosition,
    message,
    messageIsANode = false,
    messageType,
    autoHideDuration = 10000
  }) =>
    async dispatch => {
      dispatch(
        handleUserMessageBar({
          verticalPosition,
          horizontalPosition,
          message,
          messageIsANode,
          autoHideDuration,
          messageType,
          isOpen: true
        })
      )
    }

export const closeUserMessageBar = () => (dispatch, getState) => {
  const { messageType } = getState().userMessageBar
  dispatch(handleUserMessageBar({ isOpen: false, messageType }))
}

const initialState = {
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  message: '',
  autoHideDuration: 6000,
  isOpen: false,
  messageType: 'success'
}

export default createReducer(initialState, builder => {
  builder.addCase(handleUserMessageBar, (state, action) => {
    const {
      isOpen,
      verticalPosition,
      horizontalPosition,
      message,
      messageIsANode,
      autoHideDuration,
      messageType
    } = action.payload
    state.isOpen = isOpen
    state.verticalPosition = verticalPosition
    state.horizontalPosition = horizontalPosition
    state.message = message
    state.autoHideDuration = autoHideDuration
    state.messageType = messageType
    state.messageIsANode = messageIsANode
  })
})
