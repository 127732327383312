import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { copyObject, removeTime } from 'components/helper/utility'

import { getOtherServiceTypes } from 'services/common'
import { addOtherServiceRequest, getOtherServicesDetails } from 'services/rsgArrive'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import { setOpenSubmitSuccess, setStartNewRequestForVisitFlow } from './requestDetails'

const setOtherServiceTypes = createAction('otherServiceRequest/setOtherServiceTypes')
export const setOtherServiceDetailsForm = createAction(
  'otherServiceRequest/setOtherServiceDetailsForm'
)
export const resetOtherService = createAction('otherServiceRequest/resetOtherService')

export const fetchOtherServiceTypes = () => async (dispatch, getState) => {
  const { otherServiceTypes } = getState().otherServiceRequest
  if (otherServiceTypes.results.length === 0) {
    try {
      dispatch(setOtherServiceTypes({ isLoading: true }))
      const servicesList = await getOtherServiceTypes()
      const formattedServiceList = servicesList.map(item => ({
        id: item.id.toString(),
        displayText: item.displayText
      }))
      dispatch(setOtherServiceTypes({ results: formattedServiceList, isLoading: false }))
    } catch (e) {
      dispatch(showError('Error while trying to fetch Other services types', e))
      dispatch(setOtherServiceTypes({ isLoading: false }))
    }
  }
}

export const fetchOtherServiceDetails = requestId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    await dispatch(fetchOtherServiceTypes())
    const res = await getOtherServicesDetails(requestId)
    const { otherServiceDetailsForm } = getState().otherServiceRequest
    const formattedOtherServiceDetails = {
      ...otherServiceDetailsForm,
      visitDate: res.visitDate,
      reachDateTime: res.bestTimeToReach || '',
      reachPhoneNumber: res.bestPhoneNumber || '',
      selectedServices: res.selectedServices.map(svc => ({
        id: svc.serviceTypeId.toString(),
        displayText: svc.serviceTypeName
      }))
    }
    dispatch(setOtherServiceDetailsForm(formattedOtherServiceDetails))
  } catch (e) {
    dispatch(showError('Error while fetching Other Remote Service request details!', e))
  } finally {
    dispatch(setLoading(false))
  }
}

export const submitOtherServiceRequest = (data, parentRequestId) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { patientInformation } = getState().patientMainWrapper
    const otherServiceTypes = data.selectedServices.map(svc => ({
      otherServiceType: parseInt(svc.id)
    }))
    const requestData = {
      patientId: patientInformation.userId,
      visitDate: removeTime(data.visitDate),
      otherServiceTypes,
      bestTimeToReach: data.reachDateTime,
      bestPhoneNumber: data.reachPhoneNumber,
      ...(!!parentRequestId && {
        parentRequestId
      })
    }
    await addOtherServiceRequest(requestData)
    await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
    dispatch(setOpenSubmitSuccess(true))
    // Save data for StartNewRequestForVisitFlow,
    // in case user selects Yes in SubmitSuccess Confirmation box
    // This will be cleared if user selects No.
    dispatch(
      setStartNewRequestForVisitFlow({
        visitDate: removeTime(data.visitDate)
      })
    )
    dispatch(showSuccess('Request submitted successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  otherServiceTypes: { isLoading: false, results: [] },
  otherServiceDetailsForm: {
    visitDate: '',
    selectedServices: [],
    reachDateTime: '',
    reachPhoneNumber: ''
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setOtherServiceTypes, (state, action) => {
      state.otherServiceTypes = { ...state.otherServiceTypes, ...action.payload }
    })
    .addCase(setOtherServiceDetailsForm, (state, action) => {
      state.otherServiceDetailsForm = action.payload
    })
    .addCase(resetOtherService, state => {
      copyObject(state, initialState)
    })
})
