import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchStudySiteStaffDocuments,
  setPageNumber,
  setSelectedDocument
} from 'store/ducks/resourceCenter'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { downloadResourceDocsByID } from 'services/common'
import { showError } from 'store/ducks/application'
import { List } from 'components/common'
import { setLoading } from 'store/ducks/userInteractions'

const ForStudySiteStaffDocuments = ({ siteClientId }) => {
  const dispatch = useDispatch()
  const { forStudySiteStaffDocuments, selectedDocument } = useSelector(
    store => store.resourceCenter
  )

  useEffect(() => {
    dispatch(fetchStudySiteStaffDocuments(siteClientId))
    return () => {
      dispatch(setSelectedDocument({}))
    }
  }, [siteClientId])

  return (
    <List
      list={forStudySiteStaffDocuments}
      selectedItem={selectedDocument}
      onAdornmentClicked={async file => {
        dispatch(setLoading(true))
        await downloadResourceDocsByID({ ...file, isViewed: false, documentType: null })
          .then(() => {
            dispatch(setLoading(false))
          })
          .catch(() => {
            dispatch(setLoading(false))
            dispatch(
              showError(
                'There was some error while trying to download the document. Please try again.'
              )
            )
          })
      }}
      onItemClicked={file => {
        dispatch(setPageNumber(1))
        dispatch(
          setSelectedDocument({
            ...file,
            category: resourceCenterCategory.forStudySiteStaffDocuments,
            isViewed: true,
            documentType: null
          })
        )
      }}
    />
  )
}

ForStudySiteStaffDocuments.propTypes = {
  siteClientId: PropTypes.string
}

export default ForStudySiteStaffDocuments
