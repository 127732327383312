import React, { useEffect, useState } from 'react'
import { Button, PageHeader, TopNavigationBar } from 'components/common'
import { ArrowBackIos, ArrowForwardIos, WhatsApp } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchBadgesForPatientSection,
  fetchPatientInformation,
  resetPatientMainWrapper,
  fetchVisitGuidelineGroup
} from 'store/ducks/patientRecord/patientMainWrapper'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useParams, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  fetchParticipationProgressDetails,
  resetParticipationProgress,
  setSubjectIdUpdateModal
} from 'store/ducks/patientRecord/participationProgress'
import { useRestriction, Restriction } from 'permissions'
import QuickView from 'components/common/QuickView'
import SubjectIdUpdateModal from './participationProgress/SubjectIdUpdateModal'
import { resetPatientProfile } from 'store/ducks/patientRecord/patientProfile'
import { patientTabs } from 'routes/navigationRoutes'
import { patientSectionModuleNames } from 'components/helper/constants/badging'
import { displayDate, getSelectedId } from 'components/helper/utility'
import { patientTabIDs } from 'components/helper/constants/common'
import { resetCardConfiguration } from 'store/ducks/configuration/cardConfiguration'
import { resetRequestDetails } from 'store/ducks/visitServices/requestDetails'
import {
  fetchCampaignDetails,
  resetProtocolCampaignConfiguration
} from 'store/ducks/configuration/protocolCampaignConfiguration'
import PatientHelp from './PatientHelp'
import ConciergeHelp from './ConciergeHelp'
import { fetchListOfPatients } from 'store/ducks/patientExperienceManager'

const TitleWrapper = styled.div`
  display: flex;
  h4:first-of-type {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`
const TitleActionWrapper = styled.div`
  && {
    button {
      margin-left: ${({ theme }) => theme.spacing(1)};
    }
  }
`

const EmptyFooter = styled.div`
  padding: 25px;
`

const PatientMainWrapper = () => {
  const dispatch = useDispatch()
  const { instanceID, patientId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { patientInformation, badgesForPatientSection } = useSelector(
    store => store.patientMainWrapper
  )
  const { isPatient } = useSelector(store => store.auth)
  const { participationProgressDetails, subjectIdUpdateModal } = useSelector(
    store => store.participationProgress
  )
  const { patientsList } = useSelector(store => store.patientExperienceManager)
  const [conciergeHelpPopup, setConciergeHelpPopup] = useState(false)

  const currentTab = getSelectedId(pathname)

  const [openPatientHelp, setOpenPatientHelp] = useState(false)

  const selectedPatientIndex = patientsList.results.findIndex(
    patient =>
      patient.tcnId === participationProgressDetails.systemId &&
      displayDate(patient.recordCreatedDate) === participationProgressDetails.systemIdCreatedDate
  )

  const permissionToBeChecked = [
    {
      to: patientTabIDs.participationProgress
    },
    {
      to: patientTabIDs.patientMailbox
    },
    {
      to: patientTabIDs.reimbursement,
      condition: !!patientInformation.isTCNCardAvailable
    },
    {
      to: patientTabIDs.patientProfile
    },
    {
      to: patientTabIDs.studyTools
    },
    {
      to: patientTabIDs.visitSupportServices
    }
  ]

  const childTabPermissionToBeChecked = [
    {
      to: patientTabIDs.patientMailbox
    }
  ]

  const tabs = patientTabs(isPatient, patientInformation.isRestrictedCountry)
    .filter(tab => {
      const allowedTab = permissionToBeChecked.find(allowedTab => allowedTab.to === tab.to)
      const canReadToAddress = useRestriction('read', tab.to)
      return !allowedTab || (canReadToAddress && (!allowedTab.condition || allowedTab.condition))
    })
    .map(tab => ({
      ...tab,
      badgeCount: badgesForPatientSection[patientSectionModuleNames[tab.to]]?.count
    }))

  // Check permissions of the child tab
  const filteredChildTab = tabs.map(tab => {
    const allowedTab = childTabPermissionToBeChecked.find(allowedTab => allowedTab.to === tab.to)
    if (allowedTab && tab.children) {
      const childTabs = tab.children.filter(childTab => useRestriction('read', childTab.to))
      return { ...tab, children: childTabs }
    }
    return tab
  })

  const updatePatientParticipationProgressPopup = useRestriction(
    'read',
    'updatePatientParticipationProgressPopup'
  )

  const fetchRestOfTheCallsInSequence = async () => {
    // fetch all other information only when patientId exists,
    if (isPatient) {
      await dispatch(fetchBadgesForPatientSection())
    } else {
      await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
    }
    if (patientInformation.userId) {
      // this will also get the information required for subjectID pop-up,
      // this dispatch is removed from the participation progress module
      await dispatch(fetchParticipationProgressDetails(patientInformation.userId))
      // Need CampaignName when user selected
      await dispatch(fetchCampaignDetails())
      await dispatch(fetchVisitGuidelineGroup(patientInformation.userId))
    }
  }

  useEffect(() => {
    // check for patient log-in
    if (isPatient) {
      // Patient Flow for fetching information
      dispatch(fetchPatientInformation())
    } else {
      // PatientID is set, and used in the entire patient module
      dispatch(fetchPatientInformation(patientId))
    }
  }, [patientId])

  useEffect(() => {
    if (patientInformation.userId) {
      fetchRestOfTheCallsInSequence()
    }
  }, [patientInformation.userId])

  useEffect(() => {
    dispatch(fetchListOfPatients())
    // clears up patient's module store,
    // so that when user comes back by clicking another patient, gets updated information
    return () => {
      dispatch(resetPatientMainWrapper())
      dispatch(resetPatientProfile())
      dispatch(resetParticipationProgress())
      dispatch(resetCardConfiguration())
      dispatch(resetRequestDetails())
      dispatch(resetProtocolCampaignConfiguration())
    }
  }, [])

  return (
    <>
      <PageHeader
        title={
          <TitleWrapper>
            <Typography variant="h4">{patientInformation.fullName}</Typography>
            <Typography variant="h5">
              | Preferred Language: {patientInformation.preferredLanguage?.displayText}{' '}
              {participationProgressDetails?.subject?.id &&
                `| Subject ID: ${participationProgressDetails.subject?.displayText}`}{' '}
              | TCN #: {participationProgressDetails.systemId}
            </Typography>
          </TitleWrapper>
        }
        actionContent={
          <TitleActionWrapper>
            {/* <Restriction ability="read" feature="dashboardExport">
                <Button
                  startIcon={<Download variant="outlined" fontSize="medium" />}
                  size="small"
                  color="inherit"
                  variant="outlined"
                >
                  Export Messages
                </Button>
            </Restriction> */}
            {!isPatient && (
              <Button
                disabled={selectedPatientIndex === 0}
                startIcon={<ArrowBackIos />}
                onClick={() => {
                  navigate(
                    `/instance/${instanceID}/patient-dashboard/${
                      patientsList.results[selectedPatientIndex - 1].userId
                    }/${currentTab}`
                  )
                }}
              >
                Previous
              </Button>
            )}
            {!isPatient && (
              <Button
                disabled={selectedPatientIndex === patientsList.results.length - 1}
                endIcon={<ArrowForwardIos />}
                onClick={() => {
                  navigate(
                    `/instance/${instanceID}/patient-dashboard/${
                      patientsList.results[selectedPatientIndex + 1].userId
                    }/${currentTab}`
                  )
                }}
              >
                Next
              </Button>
            )}
            {isPatient
              ? (
                <Restriction ability="read" feature="dashboardHelpCenterbutton">
                  <Button
                    size="small"
                    onClick={() => setOpenPatientHelp(true)}
                    variant="outlined"
                    startIcon={<WhatsApp variant="outlined" fontSize="medium" />}
                    color="inherit"
                  >
                  Help
                  </Button>
                </Restriction>
              )
              : (
                <Restriction ability="read" feature="dashboardConciergeHelpbutton">
                  <Button
                    startIcon={<WhatsApp variant="outlined" fontSize="medium" />}
                    color="inherit"
                    size="small"
                    onClick={() => setConciergeHelpPopup(true)}
                    variant="outlined"
                  >
                  Concierge Help
                  </Button>
                </Restriction>
              )}
          </TitleActionWrapper>
        }
        expandContent={true}
        content={
          <TopNavigationBar
            buttons={filteredChildTab}
            bar={true}
            changeTab={() => {
              if (isPatient) {
                dispatch(fetchBadgesForPatientSection())
              } else {
                dispatch(fetchBadgesForPatientSection(patientInformation.userId))
              }
            }}
          />
        }
        bar={false}
      />

      <Outlet />
      {/* !Todo :If there is no IVR integration, i.e., Subject ID is open text (as per the Configuration module),
      this pop-up displays every time a user clicks into the patient record */}
      <QuickView
        title="Associate Patient's Subject ID"
        onClose={() => dispatch(setSubjectIdUpdateModal(false))}
        dialogContent={<SubjectIdUpdateModal />}
        isDialogOpen={
          updatePatientParticipationProgressPopup &&
          subjectIdUpdateModal &&
          currentTab === patientTabIDs.dashboard
        }
        maxWidth="md"
      />
      <QuickView
        title="Help"
        onClose={() => setOpenPatientHelp(false)}
        dialogContent={<PatientHelp />}
        isDialogOpen={openPatientHelp}
        maxWidth="xs"
      />
      <QuickView
        title="Concierge Help"
        onClose={() => setConciergeHelpPopup(false)}
        dialogContent={<ConciergeHelp />}
        isDialogOpen={conciergeHelpPopup}
        maxWidth="md"
        dialogActions={<EmptyFooter />}
      />
    </>
  )
}
export default PatientMainWrapper
