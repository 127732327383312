import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@mui/material'

const StyledTypography = styled(Typography)(
  ({ theme }) => `
    padding-bottom: ${theme.spacing(2)};
`
)

const SignUpSuccess = ({ isAbleToRequestPlasticCard }) => (
  <Grid container spacing={2} style={{ paddingLeft: '20px' }}>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <StyledTypography variant="body2" gutterBottom>
        You may begin to add funds to the card assigned to this participant.
      </StyledTypography>
      {isAbleToRequestPlasticCard
        ? (
          <Typography variant="body1" gutterBottom>
          An email has been automatically sent to this participant explaining how to immediately
          access funds using the virtual card assigned to them. The participant also has the option
          to request a plastic card once they log in to TCN Engage.
          </Typography>
        )
        : (
          <Typography variant="body1" gutterBottom>
          An email has been automatically sent to this participant explaining how to immediately
          access funds using the virtual card assigned to them. A plastic card will arrive via
          standard mail within 14-21 business days with an explanation on how to activate the plastic card.
          </Typography>
        )}
    </Grid>
  </Grid>
)

SignUpSuccess.propTypes = {
  isAbleToRequestPlasticCard: PropTypes.bool
}

export default SignUpSuccess
