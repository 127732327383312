import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, IconButton, Badge, Grid } from '@mui/material'
import { Add, Error, PersonPinRounded, FilterAlt } from '@mui/icons-material'
import styled from '@emotion/styled'
import { palette, combinedBaseTheme } from 'styles/baseTheme'

import {
  setSiteTablePageNumber,
  setRowsPerPage,
  fetchSites,
  handleRequestSort,
  startAddSiteSuccess,
  resetAddEditSiteFlow,
  setAddEditSiteFlow
} from 'store/ducks/siteManagement/siteManagement'

import { displayDate } from 'components/helper/utility'
import { PageHeader, DataGrid, Pagination, Button } from 'components/common'
import QuickView from 'components/common/QuickView'
import AddEditSiteDetails from './AddEditSiteDetails'
import SiteGridFilters from './SiteGridFilters'

const sitesTableHeaders = [
  { id: 'manage', label: ' ' },
  { id: 'siteId', label: 'Site ID' },
  { id: 'piLastName', label: 'PI Last Name' },
  { id: 'state', label: 'State / Province' },
  { id: 'country', label: 'Country' },
  { id: 'siteStatus', label: 'Site Status' },
  { id: 'plannedSiteActivationDate', label: 'Planned Activation Date' },
  { id: 'activationDate', label: 'Initial Activation Date' },
  { id: 'monthsActive', label: 'Months Active' },
  { id: 'totalScreened', label: 'Total Screened' },
  { id: 'totalRandomized', label: 'Total Randomized' }
]

const EmptyFooter = styled.div`
  padding: 25px;
`
const ActionCellWrapper = styled.div`
  width: 60px;
  margin-left: 30px;
`
const SitePinIcon = styled(PersonPinRounded)`
  width: 15px;
`
const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 8px;
  padding: 1px 3px;
`
)

// eslint-disable-next-line max-lines-per-function
const SiteDashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [expandFilters, setExpandedFilters] = useState(false)
  const { sites, rowsPerPage, order, orderBy, addEditSiteFlow, siteTablePageNumber } = useSelector(
    store => store.siteManagement
  )

  const { instanceID } = useParams()

  useEffect(() => {
    dispatch(fetchSites())
    // Cleanup Redux values for this component/feature
    return () => {
      dispatch(resetAddEditSiteFlow())
    }
  }, [])

  /* -----DataGrid related actions------ */
  const updatedHeaders = sitesTableHeaders.map(header => {
    const headerProperties = {
      backgroundColor: palette.secondary.main,
      color: palette.primary.main,
      borderBottom: `1px solid ${palette.gray.main}`,
      borderTop: `1px solid ${palette.gray.main}`,
      borderLeft: '0px',
      borderRight: '0px',
      cellType: 'node',
      padding: '24px 8px',
      textAlign: 'center'
    }
    const bodyProperties = {
      backgroundColor: palette.secondary.main,
      borderLeft: '0px',
      borderBottom: `1px solid ${palette.gray.main}`,
      borderRight: `1px solid ${palette.gray.main}`
    }

    if (header.id === 'manage') {
      return {
        ...header,
        headProperties: {
          ...headerProperties,
          borderRight: `4px solid ${palette.gray.light}`,
          borderLeft: `1px solid ${palette.gray.main}`,
          borderTopLeftRadius: `${combinedBaseTheme.shape.mediumRadius}`
        },
        bodyProperties: {
          ...bodyProperties,
          cellType: 'node',
          borderRight: `4px solid ${palette.gray.main}`,
          margin: '0px !important'
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else if (header.id === 'totalRandomized') {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties,
          borderRight: `1px solid ${palette.gray.main}`,
          borderTopRightRadius: `${combinedBaseTheme.shape.mediumRadius}`
        },
        bodyProperties: {
          ...bodyProperties,
          borderRight: '0px'
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    } else {
      return {
        ...header,
        isSortable: true,
        headProperties: {
          ...headerProperties
        },
        bodyProperties: {
          ...bodyProperties
        },
        label: <Typography variant="body2">{header.label}</Typography>
      }
    }
  })

  let updatedSiteListData = []

  const onTableRowSelection = (event, encrypttedSiteId) => {
    navigate(`/instance/${instanceID}/site-details/${encrypttedSiteId}`)
  }

  updatedSiteListData = sites.results?.map(item => ({
    ...item,
    plannedSiteActivationDate: displayDate(item.plannedSiteActivationDate),
    activationDate: displayDate(item.activationDate),
    manage: (
      <ActionCellWrapper>
        <IconButtonWrapper
          aria-label="actions"
          aria-haspopup="true"
          onClick={event => onTableRowSelection(event, item.encrypttedSiteId)}
          color="primary"
        >
          {item.alerts
            ? (
              <Badge
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                badgeContent={<Error color="error" fontSize="small" />}
              >
                <SitePinIcon color="primary" fontSize="small" />{' '}
              </Badge>
            )
            : (
              <>
                <SitePinIcon color="primary" fontSize="small" />{' '}
              </>
            )}
        </IconButtonWrapper>
      </ActionCellWrapper>
    )
  }))

  const handlePageNumber = newPageNumber => {
    dispatch(setSiteTablePageNumber(newPageNumber))
  }

  const handleRowsPerPage = rowsPerPage => {
    dispatch(setRowsPerPage(rowsPerPage))
    dispatch(setSiteTablePageNumber(0))
  }

  const onAddButtonClicked = () => {
    dispatch(setAddEditSiteFlow(true))
  }

  const closeAddSiteFlow = refreshSitesList => {
    dispatch(setAddEditSiteFlow(false))

    if (refreshSitesList) {
      dispatch(startAddSiteSuccess(true))
    }
  }

  const handleExpandedFilters = () => {
    setExpandedFilters(prevState => !prevState)
  }

  return (
    <>
      <PageHeader
        title={<Typography variant="h4">Site Management</Typography>}
        actionContent={
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleExpandedFilters}
                size="small"
                startIcon={<FilterAlt />}
              >
                <Typography variant="body1">Filter / Search</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" onClick={onAddButtonClicked} startIcon={<Add />}>
                <Typography variant="body1">Add a Site</Typography>
              </Button>
            </Grid>
          </Grid>
        }
        content={<SiteGridFilters />}
        expandContent={expandFilters}
      />
      <DataGrid
        headers={updatedHeaders}
        tableData={updatedSiteListData.slice(
          siteTablePageNumber * rowsPerPage,
          siteTablePageNumber * rowsPerPage + rowsPerPage
        )}
        showExportToExcel={false}
        setRequestedSort={orderByValue => dispatch(handleRequestSort(orderByValue))}
        order={order}
        orderBy={orderBy}
        border="none"
        radius="false"
      />
      <Pagination
        canChangeRowsPerPage={[20, 50, 100]}
        totalCount={sites.totalCount}
        rowsPerPage={rowsPerPage}
        pageNumber={siteTablePageNumber}
        setPageNumber={handlePageNumber}
        setRowsPerPage={handleRowsPerPage}
      />
      <QuickView
        title="Add a Site"
        onClose={() => closeAddSiteFlow(false, null)}
        dialogContent={
          <AddEditSiteDetails
            siteDetails={null}
            closeForm={() => closeAddSiteFlow(false, null)}
            refreshSiteDetails={() => {}}
          />
        }
        isDialogOpen={addEditSiteFlow}
        maxWidth="md"
        dialogActions={<EmptyFooter />}
        closeConfirmation={true}
      />
    </>
  )
}

export default SiteDashboard
