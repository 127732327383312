import { backendAxios, fetchWithRetries, getConfig } from './backend'

// Settings - Address
// /{ins}/visit/visitschedulegroup ---groupeID---> /{ins}/visit/getvisitnames
export const getVisitNames = (userId, visitScheduleGroupId) =>
  backendAxios
    .get('/visit/getvisitnames', getConfig({ userId, visitScheduleGroupId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getVisitScheduleGroup = userId =>
  backendAxios
    .get('/visit/visitschedulegroup', getConfig({ userId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getResources = siteClientId =>
  backendAxios
    .get('/visit/visitresources', getConfig({ siteClientId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getVisitScheduleDetails = (userId, visitGuidelineGroupId) =>
  fetchWithRetries('GET', '/visit/visitscheduledetails', getConfig({ userId, visitGuidelineGroupId }))

// /{ins}/visit/visitguidelinegroup --groupeID---> /{ins}/visit/visitscheduledetails

export const getVisitGuidelineGroup = userId =>
  backendAxios
    .get('/visit/visitguidelinegroup', getConfig({ userId }))
    .then(response => Promise.resolve(response.data))
    .catch(err => Promise.reject(err.message))

export const updateVisitComplete = requestData =>
  backendAxios
    .post('/visit/uservisithistory', requestData, getConfig())
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))

export const getTCNUsers = siteclientid =>
  backendAxios
    .get('/user/tcnusers', getConfig({ siteclientid }))
    .then(response => response.data)
    .catch(err => Promise.reject(err))
