import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'

import { fetchSitePrefConfiguration, setAddQuestionFlow } from 'store/ducks/configuration/sitePreferencesConfiguration'

import { Button, PageHeader, AccordionGroup } from 'components/common'
import QuickView from 'components/common/QuickView'
import AddQuestion from './AddQuestion'
import ViewQuestions from './ViewQuestions'

const Container = styled.div(
  ({ theme }) => `
  padding-top: ${theme.spacing(3)};
`)

const SitePreferencesConfiguration = () => {
  const dispatch = useDispatch()
  const { sitePrefConfiguration, addQuestionFlow } = useSelector(store => store.sitePreferencesConfiguration)

  useEffect(() => {
    dispatch(fetchSitePrefConfiguration())
  }, [])

  return (
    <PageHeader
      title={<Typography variant="h4">Site Preferences</Typography>}
      expandContent
      actionContent={
        <Button
          size="large"
          fullWidth={false}
          color="primary"
          type="submit"
          onClick={() => dispatch(setAddQuestionFlow(true))}
        >
          Add a Question
        </Button>
      }
      content={
        <Container>

          <AccordionGroup
            accordions={
              sitePrefConfiguration.map(categoryQuestions => ({
                label: categoryQuestions.category,
                component: <ViewQuestions
                  key={categoryQuestions.category}
                  questions={categoryQuestions.questions}
                />
              }))}
            defaultOpenPanel={-1}
          />

          <QuickView
            title="Add a Question"
            onClose={() => dispatch(setAddQuestionFlow(false))}
            dialogContent={<AddQuestion />}
            isDialogOpen={addQuestionFlow}
            maxWidth="md"
            dialogActions={<></>}
          />
        </Container>
      }
    />

  )
}

export default SitePreferencesConfiguration
