import { backendAxios, getConfig } from './backend'

export const getSitePerformance = () =>
  backendAxios
    .get('/report/arrive/siteperformancereport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSummaryAndSiteDetailsReport = () =>
  backendAxios
    .get('/report/arrive/requestsummary', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getReimbursementReportBySite = () =>
  backendAxios
    .get('/report/card/reimbursementsbyvisit', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getTCNCardPatientUsageReport = () =>
  backendAxios
    .get('/report/card/cardpatientusage', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSiteDataPointsReport = () =>
  backendAxios
    .get('/report/sitemanagement/sitedatapointsreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSiteNotesReport = () =>
  backendAxios
    .get('/report/sitemanagement/sitenotesreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSitePreferencesReport = () =>
  backendAxios
    .get('/report/sitemanagement/sitepreferencesreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSiteStatusAndAddresses = () =>
  backendAxios
    .get('/report/sitemanagement/sitestatusandaddressesreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSiteManagementSitePerformance = () =>
  backendAxios
    .get('/report/sitemanagement/siteperformancereport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getMediaPlanOverview = () =>
  backendAxios
    .get('/report/mediaplan/mediaoverview', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getMediaPlannedVsActual = () =>
  backendAxios
    .get('/report/mediaplan/mediaplannedvsactual', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getPatientProfileReport = () =>
  backendAxios
    .get('/report/patientmanagement/patientprofilereport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getPatientUsageActivityReport = () =>
  backendAxios
    .get('/report/patientmanagement/patientusageactivityreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getSecondaryPrescreeningReport = () =>
  backendAxios
    .get('/report/prescreening/secondaryprescreeningreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getAdditionalPrescreeningReport = () =>
  backendAxios
    .get('/report/prescreening/additionalprescreeningreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getPrescreeningReport = () =>
  backendAxios
    .get('/report/prescreening/prescreenerreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getPrescreeningWithNotesReport = () =>
  backendAxios
    .get('/report/prescreening/prescreenerwithnotesreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getParticipationProgressReport = () =>
  backendAxios
    .get('/report/referral/participationprogressreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getParticipationProgressNotesReport = () =>
  backendAxios
    .get('/report/referral/participationprogressnotesreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getParticipantStatusHistoryReport = () =>
  backendAxios
    .get('/report/referral/participantstatushistoryreport', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
