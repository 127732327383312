import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import Button from 'components/common/form/Button'

import TextBoxOuterLabel from 'components/common/form/TextBoxOuterLabel'
import { savePrescreenQuestion, setShowAddQuestionFormFor } from 'store/ducks/patientRecord/participationProgress'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const StyledDiv = styled.div`
  width: '100%';
`

const validationSchema = Yup.object({
  question: Yup.string().required('Question is required')
})

const AddQuestionForm = ({ questionType }) => {
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{ question: '' }}
      validationSchema={validationSchema}
      onSubmit={values => {
        dispatch(savePrescreenQuestion(values, questionType))
      }}
    >
      {() => (
        <StyledDiv>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  size="medium"
                  id="question"
                  label=""
                  name="question"
                  placeholder="Question"
                  type="text"
                  rows="5"
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1">This question will be added to all current and future patient records associated to this patient&apos;s site.</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                  &ensp;&ensp;
                  <Button size="large" color="inherit" fullWidth={false} onClick={() => dispatch(setShowAddQuestionFormFor(''))}>
                    Cancel
                  </Button>
                </ActionWrapper>
              </Grid>
            </Grid>
          </Form>
        </StyledDiv>
      )}
    </Formik>
  )
}

AddQuestionForm.propTypes = {
  questionType: PropTypes.string,
}

export default AddQuestionForm
