import { createTheme } from '@mui/material/styles'

export const palette = {
  primary: {
    main: '#234F72',
    light: '#547ba1',
    dark: '#002746',
    contrastText: '#ffffffff',
  },
  secondary: {
    main: '#ffffffff',
    light: '#ffffff',
    dark: '#cccccc',
    contrastText: '#000000',
  },
  white: {
    main: '#FFFFFF',
    light: '#fbfafa91',
    dark: '#fafbfdff',
    contrastText: '#666666',
    shade: '#f5fdfe',
  },
  gray: {
    main: '#CCCCCC',
    light: '#F4F4F4',
    dark: '#333333',
    contrastText: '#666666',
  },
  rose: {
    main: '#DD548B',
    light: '#ff8ab9',
    dark: '#934169',
    contrastText: '#666666',
  },
  midnight: {
    main: '#666666',
    light: '#e5e5e5',
    dark: '#757779',
    contrastText: '#ffffffff',
  },
  text: {
    main: '#333333',
    light: '#ffffffff',
    dark: '#333333',
    disabled: '#333333',
    icon: 'rgba(255, 255, 255, 0.5)',
    component: '#ffffffff',
  },
  error: {
    main: '#FA0303',
    light: '#ff676aff',
    dark: '#a80018ff',
    contrastText: '#ffffffff',
  },
  success: {
    main: '#1e824cff',
    light: '#55b278ff',
    dark: '#005423ff',
    contrastText: '#ffffffff',
  },
  warning: {
    main: '#f1bd19ff',
    light: '#ffef56ff',
    dark: '#ba8d00ff',
    contrastText: '#000000ff',
  },
  info: {
    main: '#2d77a3ff',
    light: '#64a6d4ff',
    dark: '#004b74ff',
    contrastText: '#ffffffff',
  },
  background: {
    default: '#f4f4f4',
    paper: '#ffffff',
    disabled: '#E2E2E3',
  },
  gradientBlue: {
    main: '#00339F',
    light: '#31B4EF',
    dark: '#00106f',
    contrastText: '#ffffffff',
  },
  customBlue: {
    main: '#1073bc',
    contrastText: '#ffffffff',
  },
  gradientPink: {
    main: '#95426A',
    light: '#ED5992',
    contrastText: '#ffffffff',
  },
  gradientGreen: {
    main: '#48B87A',
    light: '#BEDB98',
    contrastText: '#ffffffff',
  },
  gradientPurple: {
    main: '#390C9F',
    light: '#AF5EEB',
    contrastText: '#ffffffff',
  },
  gradientOrange: {
    main: '#B94600',
    light: '#EBBC7A',
    contrastText: '#ffffffff',
  },
  other: {
    divider: '#d1d1d1ff',
    outlineBorder: '#d1d1d1ff',
    activeTextFieldOutline: '#334048ff',
    standardInputLine: '#8f8f8fff',
    illustration: '#cb7ba9ff',
    grayDarker: '#757779',
    grayMedium: '#DDDDDD',
    grayMediumTwo: '#757779',
    grayLabel: '#00000099',
    lightBlue: '#abc5ff',
  },
}

export const typography = {
  fontFamily: '"Roboto"',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontWeightExtraBold: 900,
  htmlFontSize: 16,
  fontSize: 14,
  h1: {
    fontFamily: '"Roboto"',
    fontSize: '2rem',
    textDecoration: 'none',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.1142857142857143,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
  },
  h2: {
    fontFamily: '"Roboto"',
    fontSize: '1.5rem',
    textDecoration: 'none',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.2222222222222223,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
  },
  h3: {
    fontSize: '1.4rem',
    textDecoration: 'none',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.2222222222222223,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    fontFamily: '"Roboto"',
  },
  h4: {
    fontSize: '1.2rem',
    textDecoration: 'none',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    fontFamily: '"Roboto"',
  },
  h5: {
    fontFamily: '"Roboto"',
    fontSize: '1.2rem',
    textDecoration: 'none',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
  },
  h6: {
    fontFamily: '"Roboto"',
    fontSize: '1.2rem',
    textDecoration: 'none',
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
  },
  button: {
    fontFamily: '"Roboto"',
    fontSize: '0.8rem',
    textDecoration: 'none',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: '16.5741px',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'uppercase',
  },
  subtitle1: {
    fontSize: '1.2rem',
    textDecoration: 'none',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.3333333333333333,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    fontFamily: '"Roboto"',
  },
  subtitle2: {
    fontSize: '1.2rem',
    textDecoration: 'none',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.57,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    fontFamily: '"Roboto"',
  },
  body1: {
    fontSize: '0.85rem',
    textDecoration: 'none',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    fontFamily: '"Roboto"',
  },
  body2: {
    fontSize: '0.85rem',
    textDecoration: 'none',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    fontFamily: '"Roboto"',
  },
  caption: {
    display: 'inline-block',
    fontSize: '0.7rem',
    textDecoration: 'none',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'none',
    fontFamily: '"Roboto"',
  },
  overline: {
    fontFamily: '"Roboto"',
    fontSize: '0.6rem',
    textDecoration: 'none',
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'uppercase',
  },
  minor: {
    fontFamily: '"Roboto"',
    fontSize: '0.5rem',
    textDecoration: 'none',
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: 'uppercase',
  },
}

export const combinedBaseTheme = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: 'px',
  },
  direction: 'ltr',
  palette: {
    mode: 'light',
    divider: '#132F4C',
    ...palette,
  },
  shape: {
    borderRadius: 2,
    minRadius: '5px',
    mediumRadius: '15px',
    containerRadius: '30px',
    circle: '25rem',
  },
  unstable_strictMode: true,
  typography: {
    ...typography,
  },

  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    minorUiElement: 10,
  },
  spacing: 8,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: `${palette.primary.main} ${palette.gray.main}`,

          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px 6px green',
            border: `solid 4px ${palette.gray.light}`,
          },
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: `${palette.gray.light}`,
            width: 14,
            height: '14px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: `${palette.primary.main}`,
            minHeight: 14,
            boxShadow: `inset 0 0 3px 3px ${palette.primary.main}`,
            border: `solid 4px ${palette.gray.light}`,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: `${palette.primary.dark}`,
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: `${palette.primary.dark}`,
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: `${palette.primary.dark}`,
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: `${palette.midnight.light}`,
          },
          '.MuiPickersPopper-root': {
            '.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root': {
              color: `${palette.gray.main}`,
            },
          },
          '.MuiMonthPicker-root': {
            'button[disabled]': {
              color: `${palette.gray.main}`,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          textTransform: 'none',
        },
        contained: {
          textTransform: 'none',
        },
        text: {
          textTransform: 'none',
          textDecoration: 'underline',
        },
      },
      variants: [
        {
          props: { variant: 'dotted' },
          style: {
            border: '1px dashed red',
            color: 'gray',
          },
        },
        {
          props: { grayed: 'true' },
          style: {
            backgroundColor: `${palette.gray.main} !important`,
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#333333',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#333333',
        },
      },
    },
    'Mui-disabled': {
      styleOverrides: {
        standard: {
          color: '#333333',
        },
        outlined: {
          color: '#333333',
        },
        animated: {
          color: '#333333',
        },
      },
    },
  },
}

export const maskingScrollBars = {
  maskImage: 'linear-gradient(to top, transparent, white), linear-gradient(to left, transparent 6px, black 6px)',
  maskSize: '100% 20000px',
  maskPosition: 'left bottom',
  WebkitMaskImage: 'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 6px, black 6px)',
  WebkitMaskSize: '100% 20000px',
  WebkitMaskPosition: 'left bottom',
  transition: 'mask-position 0.3s, -webkit-mask-position 0.3s',
  ':hover': {
    WebkitMaskPosition: 'left top',
  },
}

export const muiTheme = createTheme(combinedBaseTheme)
