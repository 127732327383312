import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Typography, Grid, Box, Skeleton } from '@mui/material'

import { fetchCampaignDetails, updateCampaignDetails } from 'store/ducks/configuration/protocolCampaignConfiguration'
import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

import { Button, PageHeader } from 'components/common'
import { TextBoxOuterLabel } from 'components/common/form'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const validationSchema = validationMapper([
  { id: 'campaignName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'protocolNumber', isResponseRequired: true, answerType: formElements.openText },
  { id: 'instanceId', isResponseRequired: true, answerType: formElements.openText },
])

const ProtocolCampaign = () => {
  const dispatch = useDispatch()
  const { protocolCampaignDetails } = useSelector(store => store.protocolCampaignConfiguration)

  useEffect(() => {
    dispatch(fetchCampaignDetails())
  }, [])

  return (
    <PageHeader
      title={<Typography variant="h4">Project Overview</Typography>}
      expandContent
      content={
        protocolCampaignDetails.isLoading
          ? <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <Skeleton height={64} />
                <Skeleton height={64} />
                <Skeleton height={64} />
              </Box>
            </Grid>
          </Grid>
          : <Formik
            initialValues={{ ...protocolCampaignDetails.results }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={values => {
              dispatch(updateCampaignDetails(values))
            }}
          >
            {({ dirty }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextBoxOuterLabel size="medium" id="campaignName" label="Project Name" name="campaignName" type="text" />
                  </Grid>
                  <Grid item xs={0} sm={0} md={6} lg={8} />
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextBoxOuterLabel size="medium" id="protocolNumber" label="Sponsor / CRO Project ID" name="protocolNumber" type="text" />
                  </Grid>
                  <Grid item xs={0} sm={0} md={6} lg={8} />
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextBoxOuterLabel size="medium" id="instanceId" label="Instance ID" name="instanceId" type="text" />
                  </Grid>
                  <Grid item xs={0} sm={0} md={6} lg={8} />
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <ActionWrapper>
                      <Button size="large" fullWidth={false} color="primary" type="submit" disabled={!dirty}>
                          Save
                      </Button>
                    </ActionWrapper>
                  </Grid>
                  <Grid item xs={0} sm={0} md={6} lg={8} />
                </Grid>
              </Form>
            )}
          </Formik>
      }
      bar={false}
    />
  )
}

export default ProtocolCampaign
