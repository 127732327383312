import ValidateSecurityAnswer from 'components/public/ValidateSecurityAnswer'
import SetupSecurityQuestion from 'components/public/SetupSecurityQuestion'
import ResetSecurityQnA from 'components/public/ResetSecurityQnA'
import SuccessScreen from 'components/public/SuccessScreen'

const authRoutes = [
  { to: 'setup-sec-ques', component: SetupSecurityQuestion },
  { to: 'validate-sec-ques', component: ValidateSecurityAnswer },
  { to: 'reset-sec-ques', component: ResetSecurityQnA },
  { to: 'reset-success/:flow', component: SuccessScreen },
]

export default authRoutes
