import { createReducer, createAction } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { copyObject } from 'components/helper/utility'
import { getPolicies } from 'services/smartAlerts'
import {
  getExistingPermissions,
  getExistingPermissionsByPolicy,
  getModulesForPermissions,
  saveUpdatedPermissions
} from 'services/permission'

export const setModules = createAction('permissionsConfiguration/setModules')
export const setPolicies = createAction('permissionsConfiguration/setPolicies')
export const setSelectedModules = createAction('permissionsConfiguration/setSelectedModules')
export const setSelectedPolicies = createAction('permissionsConfiguration/setSelectedPolicies')
export const setExistingPermissions = createAction('permissionsConfiguration/setExistingPermissions')
export const setExistingPermissionsPolicy = createAction('permissionsConfiguration/setExistingPermissionsPolicy')
export const setInitialPermissionValues = createAction('permissionsConfiguration/setInitialPermissionValues')
export const setInitialValuesUpdateCounter = createAction('permissionsConfiguration/setInitialValuesUpdateCounter')
export const setUpdatedPermissions = createAction('permissionsConfiguration/setUpdatedPermissions')
export const setGridHeaders = createAction('permissionsConfiguration/setGridHeaders')
export const setGridRows = createAction('permissionsConfiguration/setGridRows')

export const resetPermissionsConfiguration = createAction(
  'permissionsConfiguration/resetPermissionsConfiguration'
)

const indent = (value, indentNumber) => {
  let indentString = ''
  const tab = '&emsp;'

  for (let i = 0; i < indentNumber; i++) {
    indentString = indentString.concat(tab)
  }
  return `${indentString} - ${value}`
}

export const generateGrid = () => async (dispatch, getState) => {
  const {
    selectedModules,
    selectedPolicies,
    existingPermissions,
    existingPermissionsPolicy,
    initialValuesUpdateCounter
  } = getState().permissionsConfiguration

  const allHeaders = {}
  const allRows = {}
  const initialValues = {}
  if (selectedModules.length > 0 && existingPermissions.length > 0) {
    selectedModules.forEach(selectedModule => {
      const rows = []
      let moduleHeader = []
      let permissionHeaders = []

      const existingPermissionsForModule = existingPermissions.filter(permission => permission.luPermissionCategoryId === selectedModule.id)
      existingPermissionsForModule.forEach(permission => {
        rows[`${selectedModule.id}-${permission.luPermissionId}`] = {}
        rows[`${selectedModule.id}-${permission.luPermissionId}`][`module-${selectedModule.id.toString()}`] = `html:${indent(permission.permissionName, permission.rootLevel)}`
      })

      /** Set Headers of Grid */
      // Selected Module Name Header. Under this trigger names are displayed

      moduleHeader = [{
        id: `module-${selectedModule.id.toString()}`,
        label: selectedModule.displayText,
        key: `module-${selectedModule.id.toString()}`,
        type: 'module'
      }]

      if (existingPermissionsPolicy.length > 0) {
        const existingPermissionsPolicyForModule = existingPermissionsPolicy.filter(permission => permission.luPermissionCategoryId === selectedModule.id)

        permissionHeaders = selectedPolicies.map(policy => ({
          id: policy.id.toString(),
          label: policy.displayText.toString(),
          key: policy.id.toString(),
          // type: 'module'
        }))

        existingPermissionsPolicyForModule.forEach(permission => {
          const checkBoxName = `${permission.luPermissionId}-${permission.policyId}`
          const readOnly = permission.cannotEditPermission ? '1' : '0'
          rows[`${selectedModule.id}-${permission.luPermissionId}`][permission.policyId.toString()] = `checkbox:${checkBoxName}:${readOnly}`
          initialValues[checkBoxName] = !!permission.hasAccess
        })
      }
      allHeaders[selectedModule.id] = [...moduleHeader, ...permissionHeaders]
      allRows[selectedModule.id] = Object.keys(rows).map(key => rows[key])
    })

    dispatch(setInitialPermissionValues(initialValues))
    dispatch(setInitialValuesUpdateCounter(initialValuesUpdateCounter + 1))
    /**
      [
        {
            "id": "module-8",
            "label": "TCN Arrive",
            "key": "module-8",
            "type": "module"
        },
        {
            "id": "1",
            "label": "Admin",
            "key": "1"
        },
        {
            "id": "2",
            "label": "BBK",
            "key": "2"
        }
      ]
    */
    dispatch(setGridHeaders(allHeaders))
    /** {
     * 8: [
            {
                "1": "checkbox:65-1:0", (Permissions for a Policy column)
                "2": "checkbox:65-2:0", (Permissions for a Policy column)
                "module-8": "html: - TCN Arrive - Visible" (Permission Name column)
            },
            {
                "1": "checkbox:122-1:0",
                "2": "checkbox:122-2:0",
                "module-8": "html:&emsp; - Arrive Guidelines button - Visible"
            },
            {
                "1": "checkbox:66-1:0",
                "2": "checkbox:66-2:0",
                "module-8": "html:&emsp; - Make a Request button - Visible"
            },
          ]
        }
      */
    dispatch(setGridRows(allRows))
  }
}

export const fetchModules = () => async dispatch => {
  try {
    dispatch(setModules({ isLoading: true }))
    const modules = await getModulesForPermissions()
    dispatch(setModules({ isLoading: false, results: modules }))
  } catch (e) {
    dispatch(setModules({ isLoading: false, results: [] }))
    dispatch(showError('Error while fetching Modules for Permissions!'))
  }
}

export const fetchPolicies = () => async dispatch => {
  try {
    dispatch(setPolicies({ isLoading: true }))
    const policies = await getPolicies()
    dispatch(setPolicies({ isLoading: false, results: policies }))
  } catch (e) {
    dispatch(setPolicies({ isLoading: false, results: [] }))
    dispatch(showError('Error while fetching Policies!'))
  }
}

export const fetchExistingPermissions = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { selectedModules } = getState().permissionsConfiguration
  const moduleIds = selectedModules.map(smodule => smodule.id).join(',')
  try {
    const existingPermissions = await getExistingPermissions(moduleIds)
    await dispatch(setExistingPermissions(existingPermissions))

    dispatch(generateGrid())
  } catch (e) {
    dispatch(showError('Error while fetching existing Permissions!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchExistingPermissionsPolicy = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { selectedModules, selectedPolicies } = getState().permissionsConfiguration
  const moduleIds = selectedModules.map(smodule => smodule.id).join(',')
  const policies = selectedPolicies.map(policy => policy.id).join(',')
  try {
    const existingPermissionsPolicy = await getExistingPermissionsByPolicy(moduleIds, policies)
    await dispatch(setExistingPermissionsPolicy(existingPermissionsPolicy))

    await dispatch(generateGrid())
  } catch (e) {
    dispatch(showError('Error while fetching existing Permissions for selected Policies!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updatePermissions = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { updatedPermissions } = getState().permissionsConfiguration
  try {
    /**
     * updatedPermissions
     * {
     *  59: { 5: true },
     *  61: { 5: true },
     * luPermissionId: { policyId: hasAccess }
     * }
     */
    /** Convert to below Payload format */
    /**
     * {
     *  "updatedPermissions": [
     *     {
     *       "policyId": 0,
     *       "luPermissionId": 0,
     *       "hasAccess": true,
     *       "cannotEditPermission": true
     *     }
     *   ]
     * }
     */

    const newUpdatedPermissions = Object.keys(updatedPermissions)
      .map(luPermissionId =>
        Object.keys(updatedPermissions[luPermissionId]).map(policyId => ({
          policyId: parseInt(policyId),
          luPermissionId: parseInt(luPermissionId),
          hasAccess: updatedPermissions[luPermissionId][policyId]
        }))
      )
      .flat()

    await saveUpdatedPermissions({ updatedPermissions: newUpdatedPermissions })
    await setUpdatedPermissions({})
    dispatch(showSuccess('Updated Permissions successfully!'))
  } catch (e) {
    dispatch(showError('Failed to update Permissions!', e))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  modules: { isLoading: false, results: [] },
  policies: { isLoading: false, results: [] },
  selectedModules: [],
  selectedPolicies: [],
  existingPermissions: [],
  existingPermissionsPolicy: [],
  gridHeaders: {},
  gridRows: {},
  initialPermissionValues: {},
  initialValuesUpdateCounter: 0,
  updatedPermissions: {},
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setSelectedModules, (state, action) => {
      state.selectedModules = action.payload
    })
    .addCase(setSelectedPolicies, (state, action) => {
      state.selectedPolicies = action.payload
    })
    .addCase(setModules, (state, action) => {
      state.modules = { ...state.modules, ...action.payload }
    })
    .addCase(setPolicies, (state, action) => {
      state.policies = { ...state.policies, ...action.payload }
    })
    .addCase(setExistingPermissions, (state, action) => {
      state.existingPermissions = action.payload
    })
    .addCase(setExistingPermissionsPolicy, (state, action) => {
      state.existingPermissionsPolicy = action.payload
    })
    .addCase(setGridHeaders, (state, action) => {
      state.gridHeaders = action.payload
    })
    .addCase(setGridRows, (state, action) => {
      state.gridRows = action.payload
    })
    .addCase(setInitialPermissionValues, (state, action) => {
      state.initialPermissionValues = action.payload
    })
    .addCase(setInitialValuesUpdateCounter, (state, action) => {
      state.initialValuesUpdateCounter = action.payload
    })
    .addCase(setUpdatedPermissions, (state, action) => {
      state.updatedPermissions = action.payload
    })
    .addCase(resetPermissionsConfiguration, state => {
      copyObject(state, initialState)
    })
})
