import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Button, CustomRadioGroup, PropertyValue } from 'components/common'
import QuickView from 'components/common/QuickView'
import RequestPlasticCardModal from './RequestPlasticCardModal'
import TransactionHistory from './TransactionHistory'
import ActivatePlasticCard from './ActivatePlasticCard'
import { getCardPin, getCardPinToken, suspendCard } from 'services/patientRecord/manageAccount'
import { cardStatus, cardType } from 'components/helper/constants/reimbursement'
import { useDispatch, useSelector } from 'react-redux'
import CardView from './CardView'
import {
  AddCardTwoTone,
  CreditCard,
  HistoryOutlined,
  LockOutlined,
  PowerSettingsNewOutlined,
  ReportProblemOutlined
} from '@mui/icons-material'
import { showError } from 'store/ducks/application'
import {
  cancelAndReplaceTCNCard,
  fetchReplaceOrReissueReasonsLookup,
  setCardDetailsManageAccount
} from 'store/ducks/patientRecord/manageAccount'
import { Restriction } from 'permissions'
import { getClientIp, getPQCardPIN } from 'services/patientRecord/payQuicker'

const ActionWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  marginTop: `${theme.spacing(4)}`,
  button: {
    minWidth: '160px',
    margin: `${theme.spacing(1.9, 1.2)}`
  }
}))

const CancelReplaceActionWrapper = styled.div(({ theme }) => ({
  button: {
    marginRight: `${theme.spacing(3)}`
  }
}))

const CardDetails = () => {
  const [viewVirtualCard, setViewVirtualCard] = useState(false)
  const dispatch = useDispatch()
  const {
    cardDetailsManageAccount,
    selectedAccountManageAccount,
    selectedPersonDetailsManageAccount,
    replaceOrReissueReasonsLookup
  } = useSelector(store => store.manageAccount)
  const { patientInformationForReimbursement } = useSelector(store => store.reimbursement)
  const { instanceConfig } = useSelector(store => store.instanceConfiguration)
  const [viewTransactionHistory, setViewTransactionHistory] = useState(false)
  const [requestPlasticCardModal, setRequestPlasticCardModal] = useState(false)
  const [activatePlasticCard, setActivatePlasticCard] = useState(false)
  const [retrievePinModal, setRetrievePinModal] = useState(false)
  const [secretPin, setSecretPin] = useState('')
  const [reportLostStolen, setReportLostStolen] = useState(false)
  const [lostStolenReportedSuccess, setLostStolenReportedSuccess] = useState(false)
  const [cancelAndReplaceConfirmation, setCancelAndReplaceConfirmation] = useState(false)
  const [replaceOrReissueReasonId, setReplaceOrReissueReasonId] = useState('')

  const { results } = cardDetailsManageAccount

  useEffect(() => {
    dispatch(fetchReplaceOrReissueReasonsLookup())
  }, [])

  const isPayQuicker = !!((instanceConfig.results.CardVendor &&
  instanceConfig.results.CardVendor.toLowerCase() === process.env.REACT_APP_PAYQUICKER_VENDOR.toLowerCase()))

  const onRetrievePin = async () => {
    try {
      let pin
      if (
        isPayQuicker &&
        results.reimbursementCardId
      ) {
        const origin = process.env.REACT_APP_APPLICATION_ORIGIN
        const { ip } = await getClientIp()
        if (ip) {
          const { data } = await getCardPinToken(results.reimbursementCardId, origin, ip)
          const { accessToken, token, userToken, cardToken } = data
          const cardDataResponse = await getPQCardPIN(
            accessToken,
            token,
            userToken,
            cardToken,
            origin
          )
          pin = cardDataResponse.cardPin
        }
      } else {
        const pinDetails = await getCardPin(selectedAccountManageAccount.id)
        pin = pinDetails.pin
      }

      setSecretPin(pin)
      setRetrievePinModal(true)
    } catch {
      dispatch(showError('Error while trying to get card pin'))
    }
  }

  const onLostStolenReport = async () => {
    try {
      const requestData = {
        reimbursementCardId: selectedAccountManageAccount.id
      }
      await suspendCard(requestData)
      dispatch(
        setCardDetailsManageAccount({ results: { ...results, cardStatus: cardStatus.suspend } })
      )
      setLostStolenReportedSuccess(true)
    } catch {
      dispatch(showError('Error when trying to fetch account holder details'))
    }
  }

  // cardDetailsManageAccount is coming from inter-cash API,
  // whereas selectedAccountManageAccount is coming from our in-house API

  const isRequestPlasticCardDisabled =
    [cardType.convertToPlasticRequested, cardType.plastic].includes(
      results.cardType
    ) || selectedAccountManageAccount?.isPlastic

  // if the card status is inactive and plastic card is NOT activated
  // (IsPlastic = 1 && IsPlasticCardActivated =0 && cardstatus != 'Suspend')
  const isActivatePlasticCardEnabled =
    selectedAccountManageAccount?.isPlastic &&
    !selectedAccountManageAccount?.isPlasticCardActivated &&
    ![cardStatus.suspend].includes(results.cardStatus)

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h2" gutterBottom>
            Card Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {isPayQuicker
            ? <PropertyValue
              value={results.lastFourDigits}
              label="Last Four Digits:"
              align="row"
              fullWidth
              valueProps={{ variant: 'body2' }}
              labelProps={{ variant: 'body1' }}
            />
            : <PropertyValue
              value={results.san}
              label="ID Number (back of card):"
              align="row"
              fullWidth
              valueProps={{ variant: 'body2' }}
              labelProps={{ variant: 'body1' }}
            />}

        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <PropertyValue
            value={results.cardStatus}
            label="Card Status:"
            align="row"
            fullWidth
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
          />
        </Grid>
        <Restriction ability="read" feature="manageAccountCardExpiration">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <PropertyValue
              value={results.expiry}
              label="Card Expiration:"
              align="row"
              fullWidth
              valueProps={{ variant: 'body2' }}
              labelProps={{ variant: 'body1' }}
            />
          </Grid>
        </Restriction>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <PropertyValue
            value={results.cardCurrency}
            label="Card Currency:"
            align="row"
            fullWidth
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <PropertyValue
            value={results.trackingNumber}
            label="Tracking Number:"
            align="row"
            fullWidth
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <PropertyValue
            value={
              results.cardStatus === cardStatus.suspend
                ? (
                  <i>Not Available due to card Suspension</i>
                )
                : (
                  `${results.cardCurrencySymbol} ${results.availableBalance} ${results.cardCurrency}`
                )
            }
            label="Available Balance:"
            align="row"
            fullWidth
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} />
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <PropertyValue
            value={`${results.cardCurrencySymbol} ${results.totalReimbursement} ${results.cardCurrency}`}
            label="Total Reimbursed:"
            align="row"
            fullWidth
            valueProps={{ variant: 'body2' }}
            labelProps={{ variant: 'body1' }}
          />
        </Grid>
      </Grid>

      <ActionWrapper>
        {results.cardStatus !== cardStatus.suspend && (
          <>
            <Restriction ability="read" feature="manageAccountViewVirtualCardButton">

              <Button color="secondary" onClick={() => setViewVirtualCard(true)}>
                  View Virtual Card
              </Button>

            </Restriction>
            <Restriction ability="read" feature="manageAccountRequestPlasticCardButton">
              {
                patientInformationForReimbursement.isAbleToRequestPlasticCard
                  ? (
                    <Button
                      color="secondary"
                      onClick={() => setRequestPlasticCardModal(true)}
                      disabled={isRequestPlasticCardDisabled}
                      grayDisabled={isRequestPlasticCardDisabled}
                    >
                    Request Plastic Card
                    </Button>
                  )
                  : ''}

            </Restriction>
            <Restriction ability="read" feature="manageAccountActivatePlasticCardButton">

              <Button
                color="secondary"
                onClick={() => setActivatePlasticCard(true)}
                grayDisabled={!isActivatePlasticCardEnabled}
                disabled={!isActivatePlasticCardEnabled}
              >
                  Activate Plastic Card
              </Button>

            </Restriction>
            <Restriction ability="read" feature="manageAccountGetPINButton">

              <Button color="secondary" onClick={() => onRetrievePin()}>
                  Get PIN
              </Button>

            </Restriction>
            <Restriction ability="read" feature="manageAccountReportLostStolenButton">
              {/* <Restriction ability="read" feature="manageAccountCancelAndReplaceButton"> */}

              <Button color="secondary" onClick={() => setReportLostStolen(true)}>
                  Report Lost / Stolen
              </Button>

            </Restriction>
            <Restriction ability="read" feature="manageAccountViewHistoryButton">

              <Button color="secondary" onClick={() => setViewTransactionHistory(true)}>
                  View History
              </Button>

            </Restriction>
          </>
        )}
        <Restriction ability="read" feature="manageAccountCancelAndReplaceButton">
          <Button color="secondary" onClick={() => setCancelAndReplaceConfirmation(true)}>
              Replace / Reissue
          </Button>
        </Restriction>
      </ActionWrapper>

      <QuickView
        title="View Virtual Card"
        titleIcon={<CreditCard />}
        onClose={() => setViewVirtualCard(false)}
        dialogContentStyles={{ padding: '0px', height: '480px' }}
        dialogContent={<CardView cardDetails={results} />}
        isDialogOpen={viewVirtualCard}
        maxWidth="sm"
      />
      <QuickView
        title={'Request Plastic Card'}
        titleIcon={<AddCardTwoTone />}
        iconAltText="Request Plastic Card"
        onClose={() => setRequestPlasticCardModal(false)}
        dialogContent={
          <RequestPlasticCardModal
            setRequestPlasticCardModal={setRequestPlasticCardModal}
            cardHolderDetails={selectedPersonDetailsManageAccount}
            reimbursementCardId={selectedAccountManageAccount?.id}
          />
        }
        isDialogOpen={requestPlasticCardModal}
        maxWidth="md"
      />
      <QuickView
        title="View History"
        titleIcon={<HistoryOutlined />}
        onClose={() => setViewTransactionHistory(false)}
        dialogContent={
          <TransactionHistory reimbursementCardId={results.reimbursementCardId} />
        }
        isDialogOpen={viewTransactionHistory}
        maxWidth="md"
      />
      <QuickView
        title="Activate Plastic Card"
        titleIcon={<PowerSettingsNewOutlined />}
        iconAltText="Activate Plastic Card"
        onClose={() => setActivatePlasticCard(false)}
        dialogContent={<ActivatePlasticCard setActivatePlasticCard={setActivatePlasticCard} />}
        isDialogOpen={activatePlasticCard}
        maxWidth="sm"
      />
      <QuickView
        title="Get PIN"
        titleIcon={<LockOutlined />}
        iconAltText="Get PIN"
        onClose={() => setRetrievePinModal(false)}
        dialogContent={
          <PropertyValue
            label="PIN:"
            value={secretPin}
            labelProps={{ variant: 'subtitle2' }}
            valueProps={{ variant: 'subtitle1' }}
            align="row"
          />
        }
        isDialogOpen={retrievePinModal}
        maxWidth="sm"
      />
      <QuickView
        title="Report Lost or Stolen Card"
        titleIcon={<ReportProblemOutlined />}
        iconAltText="Report Lost or Stolen Card"
        onClose={() => setReportLostStolen(false)}
        dialogContent={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body2" gutterBottom>
                Are you sure you want to report this card as lost or stolen?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="body1">
                Doing so will disable the card and block any future attempted transactions
              </Typography>
            </Grid>
          </Grid>
        }
        isDialogOpen={reportLostStolen}
        dialogActions={
          <Button color="primary" onClick={() => onLostStolenReport()}>
            Report as Lost / Stolen
          </Button>
        }
        alignActions="flex-start"
        maxWidth="sm"
      />
      <QuickView
        title="Report Lost or Stolen Card"
        onClose={() => {
          setReportLostStolen(false)
          setLostStolenReportedSuccess(false)
        }}
        dialogContent={
          <Typography variant="body2" gutterBottom>
            The Concierge has been notified and will reach out to confirm next steps.
          </Typography>
        }
        isDialogOpen={lostStolenReportedSuccess}
        maxWidth="sm"
      />
      <QuickView
        title="Replace / Reissue"
        onClose={() => {
          setCancelAndReplaceConfirmation(false)
        }}
        dialogContent={
          // <Typography variant="body2" gutterBottom>
          //   Are you sure you want to cancel the current card and replace it with a new card? Please
          //   note, regardless of the current card type (virtual or virtual and plastic), the new card
          //   assigned will be a virtual card.
          // </Typography>
          <CustomRadioGroup
            formik="false"
            label="Replace / Reissue reason:"
            name="replaceOrReissueReason"
            id="replaceOrReissueReason"
            value={replaceOrReissueReasonId}
            options={replaceOrReissueReasonsLookup.map(reason => ({
              value: reason.id,
              label: reason.displayText
            }))}
            fullWidth={true}
            row={false}
            orientation="column"
            color="primary"
            onChange={e => setReplaceOrReissueReasonId(parseInt(e.target.value))}
            align="center"
            labelPlacement="end"
            gutterBottomForEachOption={false}
          />
        }
        isDialogOpen={cancelAndReplaceConfirmation}
        maxWidth="sm"
        dialogActions={
          <CancelReplaceActionWrapper>
            <Button
              color="primary"
              onClick={() =>
                dispatch(
                  cancelAndReplaceTCNCard(
                    results.reimbursementCardId,
                    replaceOrReissueReasonId,
                    () => {
                      setCancelAndReplaceConfirmation(false)
                      setReplaceOrReissueReasonId('')
                    }
                  )
                )
              }
              disabled={!replaceOrReissueReasonId}
            >
              Replace / Reissue
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                setCancelAndReplaceConfirmation(false)
                setReplaceOrReissueReasonId('')
              }}
            >
              Cancel
            </Button>
          </CancelReplaceActionWrapper>
        }
      />
    </>
  )
}

export default CardDetails
