import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'

const AddressMainWrapper = styled.div(({ theme, fullwidth }) => ({
  '&&': {
    backgroundColor: `${theme.palette.midnight.light}`,
    padding: `${theme.spacing(3, 2)}`,
    minHeight: '150px',
    width: fullwidth ? '100%' : '50%',
    [theme.breakpoints.down('md')]: {
      width: fullwidth ? '100%' : '70%',
      padding: `${theme.spacing(1, 1)}`
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: `${theme.spacing(1, 1)}`
    },
  }
}))

const AddressCard = ({ title, address, fullWidth }) => (
  <AddressMainWrapper fullwidth={fullWidth}>
    <Typography variant="body2">{title}</Typography>
    {address.locationName ? <Typography variant="body1">{address.locationName}</Typography> : ''}
    <Typography variant="body1">{address.address2 ? `${address.address1}, ${address.address2}` : address.address1}</Typography>
    {address.address3 && <Typography variant="body1" gutterBottom>{address.address3}</Typography>}
    <Typography variant="body1">{`${address.city}, ${address.state?.displayText}`}</Typography>
    <Typography variant="body1">{`${address.country?.displayText}, ${address.zipCode}`}</Typography>
    {address.phone && <Typography variant="body1">{`Phone #: ${address.phone}`}</Typography>}
  </AddressMainWrapper>
)

AddressCard.defaultProps = {
  fullWidth: false,
}

AddressCard.propTypes = {
  /**
   * Title of address block
   */
  title: PropTypes.string,
  /**
   * Address object
   */
  address: PropTypes.shape({
    addressId: PropTypes.number,
    siteName: PropTypes.string,
    locationName: PropTypes.string,
    addressType: PropTypes.shape({ id: PropTypes.number, displayText: PropTypes.string }),
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.shape({ id: PropTypes.number, displayText: PropTypes.string }),
    country: PropTypes.shape({ id: PropTypes.number, displayText: PropTypes.string }),
    zipCode: PropTypes.string,
    phone: PropTypes.string,
    confirmedBy: PropTypes.string,
    confirmedOn: PropTypes.string,
    lastModifiedBy: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    addedBy: PropTypes.string,
    addedOn: PropTypes.string,
  }),
  /**
   * @default false
   * When true, the wrapper of the address block will stretched to the space available
   */
  fullWidth: PropTypes.bool,
}

export default AddressCard
