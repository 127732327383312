import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import { Box, Grid, Skeleton } from '@mui/material'
import { Button, FormMapper } from 'components/common'
import { validationMapper } from 'components/helper/validationEngine'
import { setSitePrefVerificationFlow, onSitePreferencesConfirmation } from 'store/ducks/patientExperienceManager'

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(4)};
    display: flex;
    justify-content: space-between;
`,
)

const ActionButton = styled(Button)(
  ({ theme }) => `
    margin-right: ${theme.spacing(2)};
`,
)

const SiteTravelPreferences = () => {
  const dispatch = useDispatch()
  const { unansweredSitePref } = useSelector(store => store.sitePreferencesConfiguration)

  const { isLoading } = useSelector(store => store.userInteractions)
  const [changedFields, setChangedFields] = useState([])

  const getValidationsForEachCategory = categories => {
    let listOfQuestions = []
    categories.forEach(eachCategory => {
      listOfQuestions = [...listOfQuestions, ...eachCategory.questions]
    })
    return validationMapper(listOfQuestions)
  }

  const markAsChanged = id => {
    setChangedFields(prevValues => [...new Set([...prevValues, id])])
  }

  return isLoading.length
    ? (
      <Box>
        <Skeleton />
      </Box>
    )
    : (
      <>
        <Formik
          initialValues={unansweredSitePref.initialValues}
          validationSchema={getValidationsForEachCategory(unansweredSitePref.categories)}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            const changedValues = {}

            changedFields.forEach(fieldName => {
              const fieldValue = values[fieldName.toString()]
              // Field should be updated and should not be empty
              if (changedFields.includes(parseInt(fieldName)) && fieldValue) {
                changedValues[fieldName.toString()] = fieldValue
              }
            })

            // Only save changed and non-empty values
            dispatch(onSitePreferencesConfirmation(changedValues))
            setSubmitting(false)
          }}
        >
          {() => (
            <Form>
              <Grid container spacing={0}>
                {unansweredSitePref.categories.map((preferences, categoryIndex) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} key={categoryIndex}>
                    <FormMapper questions={preferences.questions.map(question => ({ ...question, markAsChanged }))} />
                  </Grid>
                ))}
                <ActionWrapper item xs={4} sm={4} md={3} lg={3}>
                  <ActionButton type="submit" size="large" color="primary">
                Save
                  </ActionButton>
                  <Button size="large" color="inherit" onClick={() => dispatch(setSitePrefVerificationFlow(false))}>
                Cancel
                  </Button>
                </ActionWrapper>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    )
}
export default SiteTravelPreferences
