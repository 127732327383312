import { patientTabIDs } from './common'
import { communicationTabIDs } from './mailbox'

export const communicationCenterModuleNames = loggedInAsSite => ({
  [communicationTabIDs.conciergeMsgsWithPatients]: 'New message from Patient',
  [communicationTabIDs.conciergeMsgsWithSites]: loggedInAsSite
    ? 'New Concierge Message'
    : 'New message from Site',
  [communicationTabIDs.conciergeMsgsForPatients]: 'New Concierge Message',
  [communicationTabIDs.announcementTab]: 'New Announcement',
  [communicationTabIDs.announcementsForPatients]: 'New Announcement'
})

export const patientSectionModuleNames = {
  [patientTabIDs.patientMailbox]: 'mailBox',
  [patientTabIDs.visitSupportServices]: 'Arrive',
  [patientTabIDs.participationProgress]: 'Participation Progress'
}
