import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Dialog, DialogContent, DialogActions, IconButton, Paper, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import Draggable from 'react-draggable'
import CloseConfirmation from './CloseConfirmation'

const PaperWrapper = styled(Paper)`
  border-radius: 12px;
`
const PaperComponent = props => (
  <Draggable handle="#form-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <PaperWrapper {...props} />
  </Draggable>
)

const DialogHeader = styled.div`
  margin: 0px;
  padding: ${({ theme }) => theme.spacing(4, 5, 4, 5)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  cursor: move;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.secondary.dark};
  display: flex;
  align-items: center;
  h2 {
    padding-left: 10px;
    padding-top: 3px;
  }
`

const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.spacing(3.5)};
    right: ${({ theme }) => theme.spacing(3.5)};
  }
`

const CloseConfirmationButton = styled(IconButton)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.spacing(1.5)};
    right: ${({ theme }) => theme.spacing(1.5)};
  }
`

const CustomDialogActions = styled(DialogActions)(
  ({ theme, align }) => `
    && {
      justify-content: ${align};
      padding-left: 0px;
      padding-top: ${theme.spacing(3)}
    }
    `,
)

const StyledDialogContent = styled(DialogContent)(({ theme, styles }) => ({
  '&&': {
    minHeight: '120px',
    padding: `${theme.spacing(4, 6, 4, 6)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1, 2, 1, 2)}`,
    },
    backgroundColor: 'white',
    ...styles,
  },
}))

const QuickView = ({
  title,
  titleIcon,
  contentText,
  contentTextProps,
  dialogContent,
  dialogContentStyles,
  onClose,
  isDialogOpen,
  dialogActions,
  maxWidth,
  alignActions,
  closeConfirmation,
  disableBackdropClick
}) => {
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false)

  const closeQuickView = () => {
    if (closeConfirmation) {
      setCancelConfirmationOpen(true)
    } else {
      onClose()
    }
  }

  const onCloseModal = reason => {
    if (disableBackdropClick) {
      if (reason !== 'backdropClick') {
        closeQuickView()
      }
    } else {
      closeQuickView()
    }
  }

  const onCloseCancelConfirmation = () => {
    setCancelConfirmationOpen(false)
  }

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={(e, reason) => onCloseModal(reason)}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
        fullWidth={true}
        scroll="paper"
        PaperComponent={PaperComponent}
      >
        <DialogHeader id="form-dialog-title">
          {titleIcon && titleIcon}
          <Typography variant="h2">{title}</Typography>
          <CloseButton
            aria-label="close"
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => closeQuickView()}
          >
            <Cancel aria-label="close quick view modal" />
          </CloseButton>
        </DialogHeader>
        <StyledDialogContent styles={dialogContentStyles}>
          {contentText && <Typography {...contentTextProps}>{contentText}</Typography>}
          {dialogContent}
          {dialogActions && <CustomDialogActions align={alignActions}>{dialogActions}</CustomDialogActions>}
        </StyledDialogContent>
      </Dialog>
      <Dialog
        open={cancelConfirmationOpen}
        onClose={onCloseCancelConfirmation}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        PaperComponent={PaperComponent}
      >
        <DialogHeader id="form-dialog-title">
          <Typography variant="h2">Confirm</Typography>
          <CloseConfirmationButton
            aria-label="close confirmation"
            variant="contained"
            size="medium"
            color="primary"
            onClick={onCloseCancelConfirmation}
          >
            <Cancel />
          </CloseConfirmationButton>
        </DialogHeader>
        <StyledDialogContent>
          <CloseConfirmation onConfirmation={onClose} closeConfirmation={onCloseCancelConfirmation} />
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

QuickView.propTypes = {
  title: PropTypes.any,
  /**
   * Title of the Modal
   */
  titleIcon: PropTypes.node,
  /**
   * Can pass just a string to display as typography in the content
   */
  contentText: PropTypes.string,
  /**
   * Content/component passed to show as content of the modal
   */
  dialogContent: PropTypes.node,
  /**
   * Function triggered on trying to close the modal
   */
  onClose: PropTypes.func,
  /**
   * Boolean value to toggle showing/hiding of the modal
   */
  isDialogOpen: PropTypes.bool,
  /**
   * Size of the modal
   */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  /**
   * Component passed to act as dialog's action
   */
  dialogActions: PropTypes.node,
  /**
   * Set the actions in required position
   */
  alignActions: PropTypes.oneOf(['center', 'flex-end', 'flex-start']),
  /**
   * @default false
   * When true, there will be a close confirmation for closing the main modal
   */
  closeConfirmation: PropTypes.bool,
  /**
   * Styled applied to content styles
   */
  dialogContentStyles: PropTypes.object,
  /**
   * Props applied to content typography
   */
  contentTextProps: PropTypes.object,
  /**
   * @default false
   * When true, disables closing of modal on clicking outside of modal
   */
  disableBackdropClick: PropTypes.bool
}

QuickView.defaultProps = {
  maxWidth: 'sm',
  alignActions: 'center',
  closeConfirmation: false,
  contentTextProps: { variant: 'subtitle1' },
  disableBackdropClick: false
}

export default QuickView
