import React, { useEffect, useState } from 'react'
import { ArrowDropDown, ArrowDropUp, Edit, Refresh } from '@mui/icons-material'
import styled from '@emotion/styled'
import AnnouncementTab from './AnnouncementTab'
import AnnouncementContent from './AnnouncementContent'
import QuickView from 'components/common/QuickView'
import ComposeAnnouncement from './ComposeAnnouncement'
import {
  getAnnouncementById,
  getAnnouncements,
  resetAnnouncement,
  setComposeAnnouncementModal,
  setPreviewAnnouncement,
  setSelectedAnnouncement,
  setViewAnnouncementDetails,
} from 'store/ducks/communicationCenter/announcements'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Collapse, Paper } from '@mui/material'
import AnnouncementFilters from './AnnouncementFilters'
import { MailNavigation } from 'components/common/navigation'
import { Button } from 'components/common'
import AnnouncementDetailedView from './AnnouncementDetailedView'
import { Restriction } from 'permissions'
import { useLocation } from 'react-router-dom'
import { getSelectedId } from 'components/helper/utility'

const EditIcon = styled(Edit)(
  ({ theme }) => `
      border: 2px solid ${theme.palette.white.main};
      border-radius: 5px;
      margin-right: ${theme.spacing(1 / 2)}
`,
)

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`
const StyledIconButton = styled(IconButton)`
  padding: 6px;
`
const FilterWrapper = styled.div(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
`,
)

const WriteAnnouncementAndRefreshButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Announcements = () => {
  const {
    composeAnnouncementModal,
    announcementsList,
    filters,
    previewAnnouncement,
    composeAnnouncement,
    viewAnnouncementDetails,
    announcement,
    selectedAnnouncement,
  } = useSelector(store => store.announcements)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAnnouncements(true, false, patientInformation.userId, selectedCommunicationTabId))
  }, [selectedCommunicationTabId])

  const onCreateNewAnnouncement = () => {
    dispatch(resetAnnouncement())
    dispatch(setViewAnnouncementDetails(false))
    dispatch(setComposeAnnouncementModal(true))
  }

  const isFiltered = Object.values(filters).filter(t => t.filter(Boolean).length > 0).length > 0

  const onTabChange = selectedItem => {
    dispatch(setViewAnnouncementDetails(false))
    dispatch(getAnnouncementById(selectedItem, selectedCommunicationTabId))
    dispatch(setSelectedAnnouncement(selectedItem))
  }

  return (
    <>
      <MailNavigation
        tabLabelComp={AnnouncementTab}
        tabContentComp={viewAnnouncementDetails ? AnnouncementDetailedView : AnnouncementContent}
        tabChangeActions={selectedItem => {
          // do not make api call if user clicks again on the same item
          if (announcement.announcementId !== selectedItem.announcementId) {
            onTabChange(selectedItem)
          }
        }}
        tabProps={announcementsList.results}
        backdropMode={viewAnnouncementDetails ? 'true' : 'false'}
        selectedItem={selectedAnnouncement?.announcementId ? selectedAnnouncement : { ...announcementsList.results[0] }}
        selectedIdentifierKey="announcementId"
        isLoading={announcementsList.isLoading}
        action={
          <ActionWrapper>
            <WriteAnnouncementAndRefreshButton>
              <Restriction ability="read" feature={`${selectedCommunicationTabId}WriteAnnouncement`}>
                <Button size="medium" startIcon={<EditIcon />} onClick={() => onCreateNewAnnouncement()}>
                    Write New Announcement
                </Button>
              </Restriction>

              <StyledIconButton
                aria-label="refresh"
                color="primary"
                onClick={() => dispatch(getAnnouncements(true, false, patientInformation.userId, selectedCommunicationTabId))}
                size="large"
              >
                <Refresh aria-label="refresh-list" />
              </StyledIconButton>
            </WriteAnnouncementAndRefreshButton>
            <Restriction ability="read" feature={`${selectedCommunicationTabId}FilterSearchButton`}>
              <FilterWrapper>
                <Button
                  size="small"
                  color={`${isFiltered ? 'primary' : 'inherit'}`}
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                  endIcon={isFilterOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                >
                    Filter/Search
                </Button>
              </FilterWrapper>
            </Restriction>
            <Collapse in={isFilterOpen} unmountOnExit>
              <Paper elevation={0}>
                <AnnouncementFilters handleClose={() => setIsFilterOpen(false)} />
              </Paper>
            </Collapse>
          </ActionWrapper>
        }
      />

      <QuickView
        title={`${
          previewAnnouncement
            ? 'Preview Announcement'
            : composeAnnouncement.announcementId
              ? 'View / Edit Announcement'
              : 'Write New Announcement'
        }`}
        onClose={() => {
          dispatch(setComposeAnnouncementModal(false))
          dispatch(setPreviewAnnouncement(false))
        }}
        dialogContent={<ComposeAnnouncement />}
        isDialogOpen={composeAnnouncementModal}
        maxWidth="lg"
        closeConfirmation={true}
      />
    </>
  )
}

export default Announcements
