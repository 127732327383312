import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import PropertyValue from 'components/common/dataDisplay/PropertyValue'
import { displayDate } from 'components/helper/utility'

const PaddedBottomGrid = styled(Grid)`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`

const VerifiedDetails = ({ addressDetails }) => (
  <Grid container spacing={2}>
    <PaddedBottomGrid item xs={12} sm={12} md={6} lg={6}>
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Added By:"
        value={addressDetails?.createdBy}
      />
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Added On:"
        value={displayDate(addressDetails?.createdOn)}
      />
    </PaddedBottomGrid>
    <PaddedBottomGrid item xs={12} sm={12} md={6} lg={6}></PaddedBottomGrid>

    <Grid item xs={12} sm={12} md={6} lg={6}>
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Modified By:"
        value={addressDetails?.modifiedBy}
      />
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Modified On:"
        value={displayDate(addressDetails?.modifiedOn)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Verified By:"
        value={addressDetails?.verifiedBy}
      />
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Verified On:"
        value={displayDate(addressDetails?.verifiedOn)}
      />
    </Grid>
  </Grid>
)

VerifiedDetails.propTypes = {
  addressDetails: PropTypes.object
}

export default VerifiedDetails
