import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { copyObject, removeTime, sortArrayByProperty } from 'components/helper/utility'

import {
  getTravelers,
  addInPersonVisitRequest,
  getInPersonVisitDetails,
  getInPersonServiceDetails,
  getInPersonTravelerDetails
} from 'services/rsgArrive'
import { getPatientAddress } from 'services/patientRecord/patientProfile'
import { getMobilityTypes } from 'services/common'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import { fetchSiteAddresses } from '../siteManagement/siteManagement'
import { inPersonServices, servicesIdForVisit } from 'components/helper/constants/visitServices'
import { setOpenSubmitSuccess, setStartNewRequestForVisitFlow } from './requestDetails'

export const setTravelers = createAction('inPersonVisit/setTravelers')
export const setMobilityTypes = createAction('inPersonVisit/setMobilityTypes')
export const setPatientAddresses = createAction('inPersonVisit/setPatientAddresses')
export const setInPersonRequestForm = createAction('inPersonVisit/setInPersonRequestForm')
export const resetInPersonVisit = createAction('inPersonVisit/resetInPersonVisit')

export const setupInPersonRequestForm = patientInformation => async (dispatch, getState) => {
  try {
    await dispatch(fetchSiteAddresses(patientInformation.siteClientId))
    await dispatch(fetchPatientAddresses(patientInformation.userId))
    await dispatch(fetchInPersonRequestTravelers(patientInformation.userId))
    await dispatch(fetchMobilityTypes())
    const { inPersonRequestForm, patientAddresses } = getState().inPersonRequest
    const { siteAddresses } = getState().siteManagement
    dispatch(
      setInPersonRequestForm({
        ...inPersonRequestForm,
        pickUpPatientLocationVisitStart: patientAddresses[patientAddresses.length - 1],
        dropOffSiteLocationVisitStart: siteAddresses[siteAddresses.length - 1],
        pickUpSiteLocationVisitEnd: siteAddresses[siteAddresses.length - 1],
        dropOffPatientLocationVisitEnd: patientAddresses[patientAddresses.length - 1]
      })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
}

export const fetchMobilityTypes = () => async (dispatch, getState) => {
  try {
    const { mobilityTypes } = getState().inPersonRequest
    dispatch(setMobilityTypes({ isLoading: true }))
    if (mobilityTypes.results.length === 0) {
      const mobilityTypesResponse = await getMobilityTypes()
      if (mobilityTypesResponse && Array.isArray(mobilityTypesResponse)) {
        const sortedMobilityTypes = sortArrayByProperty(mobilityTypesResponse, 'id')
        dispatch(setMobilityTypes({ results: sortedMobilityTypes, isLoading: false }))
      }
    }
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  }
  dispatch(setMobilityTypes({ isLoading: false }))
}

export const fetchInPersonRequestTravelers = patientId => async dispatch => {
  try {
    dispatch(setTravelers({ isLoading: true }))
    const travelers = await getTravelers(patientId)
    const transformedTravelers = travelers.map(traveler => ({
      ...traveler,
      uniqueId:
        traveler.userSupportPersonId === null ? traveler.userId : traveler.userSupportPersonId
    }))
    dispatch(setTravelers({ results: transformedTravelers }))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setTravelers({ isLoading: false }))
}

export const fetchInPersonRequestDetails =
  (requestId, patientInformation) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      await dispatch(fetchSiteAddresses(patientInformation.siteClientId))
      await dispatch(fetchPatientAddresses(patientInformation.userId))
      await dispatch(fetchInPersonRequestTravelers(patientInformation.userId))
      await dispatch(fetchMobilityTypes())
      const { inPersonRequestForm } = getState().inPersonRequest
      const serviceDetailsResponse = await getInPersonServiceDetails(requestId)
      const visitDetailsResponse = await getInPersonVisitDetails(requestId)
      const travelerDetailsResponse = await getInPersonTravelerDetails(
        patientInformation.userId,
        requestId
      )

      let formattedFormData = { ...inPersonRequestForm }

      formattedFormData = {
        ...formattedFormData,
        visitNames: visitDetailsResponse.visitName,
        visitStartDate: visitDetailsResponse.visitStartDate,
        visitEndDate: visitDetailsResponse.visitEndDate,
        arrivalTime: visitDetailsResponse.visitArrivalTime,
        departureTime: visitDetailsResponse.visitDepartureTime,
        reachDateTime: visitDetailsResponse.bestTimeToReach || '',
        reachPhoneNumber: visitDetailsResponse.bestPhoneNumber || '',
        otherConsiderations: visitDetailsResponse.otherVisitInformation,
        pickUpPatientLocationVisitStart: {
          addressId: 1,
          address1: visitDetailsResponse.visitArrivalPickupAddress1,
          address2: visitDetailsResponse.visitArrivalPickupAddress2,
          country: { displayText: visitDetailsResponse.visitArrivalPickupCountry },
          state: { displayText: visitDetailsResponse.visitArrivalPickupState },
          city: visitDetailsResponse.visitArrivalPickupCity,
          zipCode: visitDetailsResponse.visitArrivalPickupZip,
          phone: visitDetailsResponse.visitArrivalPickupPhone
        },
        dropOffSiteLocationVisitStart: {
          addressId: 1,
          address1: visitDetailsResponse.visitArrivalDropoffAddress1,
          address2: visitDetailsResponse.visitArrivalDropoffAddress2,
          country: { displayText: visitDetailsResponse.visitArrivalDropoffCountry },
          state: { displayText: visitDetailsResponse.visitArrivalDropoffState },
          city: visitDetailsResponse.visitArrivalDropoffCity,
          zipCode: visitDetailsResponse.visitArrivalDropoffZip,
          phone: visitDetailsResponse.visitArrivalDropoffPhone
        },
        pickUpSiteLocationVisitEnd: {
          addressId: 1,
          address1: visitDetailsResponse.visitDeparturePickupAddress1,
          address2: visitDetailsResponse.visitDeparturePickupAddress2,
          country: { displayText: visitDetailsResponse.visitDeparturePickupCountry },
          state: { displayText: visitDetailsResponse.visitDeparturePickupState },
          city: visitDetailsResponse.visitDeparturePickupCity,
          zipCode: visitDetailsResponse.visitDeparturePickupZip,
          phone: visitDetailsResponse.visitArrivalDropoffPhone
        },
        dropOffPatientLocationVisitEnd: {
          addressId: 1,
          address1: visitDetailsResponse.visitDepartureDropoffAddress1,
          address2: visitDetailsResponse.visitDepartureDropoffAddress2,
          country: { displayText: visitDetailsResponse.visitDepartureDropoffCountry },
          state: { displayText: visitDetailsResponse.visitDepartureDropoffState },
          city: visitDetailsResponse.visitDepartureDropoffCity,
          zipCode: visitDetailsResponse.visitDepartureDropoffZip,
          phone: visitDetailsResponse.visitDepartureDropoffPhone
        },
        selectTraveler: travelerDetailsResponse || []
      }

      if (serviceDetailsResponse.isTicketedTravel) {
        const { id, displayText } = inPersonServices.find(
          service => service.id === servicesIdForVisit.ticketedTravel
        )
        formattedFormData = {
          ...formattedFormData,
          selectedNeeds: [...formattedFormData.selectedNeeds, { id, displayText }],
          ticketedTravelSpecialNeeds: serviceDetailsResponse.ticketedTravelSpecialNeeds
            ? serviceDetailsResponse.ticketedTravelSpecialNeeds
            : '',
          ticketedTravelOtherPreferences: serviceDetailsResponse.ticketedTravelOtherPreferences
            ? serviceDetailsResponse.ticketedTravelOtherPreferences
            : ''
        }
      }
      if (serviceDetailsResponse.isHotelAccommodation) {
        const { id, displayText } = inPersonServices.find(
          service => service.id === servicesIdForVisit.hotelAccommodations
        )
        formattedFormData = {
          ...formattedFormData,
          selectedNeeds: [...formattedFormData.selectedNeeds, { id, displayText }],
          adaRoomRequired: serviceDetailsResponse.isADARoomNeeded ? '1' : '0',
          hotelAccommodationOtherPreferences:
            serviceDetailsResponse.hotelAccommodationOtherPreferences
              ? serviceDetailsResponse.hotelAccommodationOtherPreferences
              : '',
          checkInDate: serviceDetailsResponse.checkInDate ? serviceDetailsResponse.checkInDate : '',
          checkOutDate: serviceDetailsResponse.checkOutDate
            ? serviceDetailsResponse.checkOutDate
            : ''
        }
      }
      if (serviceDetailsResponse.isGroundTransport) {
        const { id, displayText } = inPersonServices.find(
          service => service.id === servicesIdForVisit.groundTransportation
        )
        formattedFormData = {
          ...formattedFormData,
          selectedNeeds: [...formattedFormData.selectedNeeds, { id, displayText }],
          groundTransportationException: serviceDetailsResponse.groundTravelLegExceptions
            ? serviceDetailsResponse.groundTravelLegExceptions
            : '',
          groundTransportOtherPreferences: serviceDetailsResponse.groundTransportOtherPreferences
            ? serviceDetailsResponse.groundTransportOtherPreferences
            : '',
          mobilityAssistance: serviceDetailsResponse.mobilityAssistance
            ? JSON.parse(serviceDetailsResponse.mobilityAssistance)
            : []
        }
      }

      dispatch(setInPersonRequestForm(formattedFormData))
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    }
    dispatch(setLoading(false))
  }

export const fetchPatientAddresses = patientId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const result = await getPatientAddress(patientId)
    dispatch(setPatientAddresses([{ ...result.address, addressId: 1 }]))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  } finally {
    dispatch(setLoading(false))
  }
}

const formatAddress = address => {
  if (!address) {
    return {}
  }
  return {
    id: address.addressId,
    luAddressTypeId: 0,
    contactName: address.attentionOfContactUserName,
    address1: address.address1,
    address2: address.address2,
    address3: '',
    city: address.city,
    luStateId: address.state?.id,
    zipPostalCode: address.zipCode,
    luCountryId: address.country?.id,
    phone: address.phone,
    fax: '',
    emailAddress: address.email,
    isVisible: true
  }
}

export const submitInPersonVisitRequest = (data, parentRequestId) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { patientInformation } = getState().patientMainWrapper

    // Filter patient support users and patients (person.userSupportPersonId === null)
    const selectedTravelers = data.selectTraveler.reduce((result, person) => {
      result.push({
        id: person.userSupportPersonId ? person.userSupportPersonId : person.userId,
        isPatient: !person.userSupportPersonId,
        name: person.fullName
      })

      return result
    }, [])

    const visitArrivalPickupAddress = formatAddress(data.pickUpPatientLocationVisitStart)
    const visitArrivalDropOffAddress = formatAddress(data.dropOffSiteLocationVisitStart)
    const visitDeparturePickupAddress = formatAddress(data.pickUpSiteLocationVisitEnd)
    const visitDepartureDropOffAddress = formatAddress(data.dropOffPatientLocationVisitEnd)

    const selectedNeedIds = data.selectedNeeds.map(need => need.id)

    const requestData = {
      patientId: patientInformation.userId,
      visitNameId: data.visitNames.id,
      visitDate: removeTime(data.visitStartDate),
      visitEndDate: removeTime(data.visitEndDate),
      visitArrivalTime: data.arrivalTime,
      visitDepartureTime: data.departureTime,
      visitArrivalPickupAddress,
      visitArrivalDropOffAddress,
      visitDeparturePickupAddress,
      visitDepartureDropOffAddress,
      otherVisitInformation: data.otherConsiderations,
      isTicketedTravel: selectedNeedIds.includes(servicesIdForVisit.ticketedTravel),
      isHotelAccommodation: selectedNeedIds.includes(servicesIdForVisit.hotelAccommodations),
      isGroundTransport: selectedNeedIds.includes(servicesIdForVisit.groundTransportation),
      ticketedTravelSpecialNeeds: data.ticketedTravelSpecialNeeds,
      ticketedTravelOtherPreferences: data.ticketedTravelOtherPreferences,
      preferredCheckinDate: data.checkInDate,
      preferredCheckOutDate: data.checkOutDate,
      isADARoomRequired: parseInt(data.adaRoomRequired),
      hotelAccommodationOtherPreferences: data.hotelAccommodationOtherPreferences,
      legsNotNeeded: data.groundTransportationException,
      groundTransportOtherPreferences: data.groundTransportOtherPreferences,
      mobilityAssistance: JSON.stringify(data.mobilityAssistance),
      selectedTravelers,
      bestTimeToReach: data.reachDateTime,
      bestPhoneNumber: data.reachPhoneNumber,
      ...(!!parentRequestId && {
        parentRequestId
      })
    }

    await addInPersonVisitRequest(requestData)
    await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
    dispatch(setOpenSubmitSuccess(true))
    // Save data for StartNewRequestForVisitFlow,
    // in case user selects Yes in SubmitSuccess Confirmation box
    // This will be cleared if user selects No.
    dispatch(
      setStartNewRequestForVisitFlow({
        // Only in InPersonRequest visitDate is visitStartDate
        visitDate: removeTime(data.visitStartDate),
        visitEndDate: removeTime(data.visitEndDate),
        visitNames: data.visitNames
      })
    )
    dispatch(showSuccess('Request submitted successfully!'))
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  } finally {
    dispatch(setLoading(false))
  }
}

const initialState = {
  travelers: { results: [], isLoading: false },
  mobilityTypes: { results: [], isLoading: false },
  patientAddresses: [],
  inPersonRequestForm: {
    visitNames: null,
    visitStartDate: '',
    visitEndDate: '',
    arrivalTime: '',
    departureTime: '',
    selectTraveler: [],
    selectedNeeds: [],
    ticketedTravelSpecialNeeds: '',
    ticketedTravelOtherPreferences: '',
    checkInDate: '',
    checkOutDate: '',
    adaRoomRequired: '',
    hotelAccommodationOtherPreferences: '',
    groundTransportationException: '',
    mobilityAssistance: [],
    groundTransportOtherPreferences: '',
    pickUpPatientLocationVisitStart: {},
    dropOffSiteLocationVisitStart: {},
    pickUpSiteLocationVisitEnd: {},
    dropOffPatientLocationVisitEnd: {},
    otherConsiderations: '',
    reachDateTime: '',
    reachPhoneNumber: ''
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setTravelers, (state, action) => {
      state.travelers = { ...state.travelers, ...action.payload }
    })
    .addCase(setMobilityTypes, (state, action) => {
      state.mobilityTypes = { ...state.mobilityTypes, ...action.payload }
    })
    .addCase(setPatientAddresses, (state, action) => {
      state.patientAddresses = action.payload
    })
    .addCase(setInPersonRequestForm, (state, action) => {
      state.inPersonRequestForm = action.payload
    })
    .addCase(resetInPersonVisit, state => {
      copyObject(state, initialState)
    })
})
