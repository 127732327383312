import React from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, TextBoxOuterLabel } from 'components/common'
import { performLogout } from 'store/ducks/auth'
import { useDispatch } from 'react-redux'

const StyledButton = styled(Button)`
  width: 35%;
`

const StyledTitle = styled(Typography)`
  margin-bottom: 30px;
`

const validations = Yup.object().shape({
  answer: Yup.string().required('Required'),
})

const GridContainer = styled(Grid)`
  text-align: center;
`
const ActionGrid = styled(Grid)`
  display: flex;
  justify-content: space-around;
`

const ResetSecurityQnA = () => {
  const dispatch = useDispatch()
  return (
    <>
      <StyledTitle gutterBottom variant="h2" color="primary">
        Security Verification
      </StyledTitle>

      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={validations}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
        }}
      >
        <Form noValidate>
          <GridContainer container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="subtitle1">A verification code is sent to your registered email ID.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextBoxOuterLabel size="small" id="verification-code" label="Verification Code" name="code" />
            </Grid>
            <ActionGrid item xs={12} sm={12} md={12} lg={12}>
              <StyledButton color="primary" type="submit" size="large" fullWidth={true}>
                Submit
              </StyledButton>
              <StyledButton color="inherit" size="large" fullWidth={true} onClick={() => dispatch(performLogout(false, false))}>
                Cancel
              </StyledButton>
            </ActionGrid>
          </GridContainer>
        </Form>
      </Formik>
    </>
  )
}

export default ResetSecurityQnA
