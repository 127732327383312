import React from 'react'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'

const RenderHTML = ({ htmlContent }) =>
  // ! todo: to allow all CSS
  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlContent) }} />

RenderHTML.propTypes = {
  htmlContent: PropTypes.string,
}

export default RenderHTML
