import React from 'react'
import { FormMapper } from 'components/common'
import { useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'

const SitePrefQuestionsAndAnswers = () => {
  const { siteQuestionAndAnswers } = useSelector(store => store.userActivity)
  return (
    <Formik
      initialValues={siteQuestionAndAnswers.initialValues}
      enableReinitialize={true}
    >
      {() => (
        <Form noValidate>
          <Grid container spacing={0}>
            {siteQuestionAndAnswers.categories.map((preferences, categoryIndex) => (
              <Grid item xs={12} sm={12} md={12} lg={12} key={categoryIndex}>
                <FormMapper questions={preferences.questions} />
              </Grid>
            ))}
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default SitePrefQuestionsAndAnswers
