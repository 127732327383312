import { backendAxios, getConfig } from './backend'
import { addEditTravelerDropdownValues } from 'mockData/rsgArrive/addEditForm/getAddEditDropdownValues'
import { trips } from 'mockData/rsgArrive/trips'
import { getCreateRequestDropdownValues } from 'mockData/rsgArrive/makeARequest/getCreateRequestDropdownValues'
import { tripFullDetails } from 'mockData/rsgArrive/tripFullDetails'

// Settings - Address
export const getTravelers = userId =>
  backendAxios
    .get('/rsgarrive/getalltravellers', getConfig({ userId }))
    .then(response => response.data)

export const getAddEditTravelerDropdownValues = () => Promise.resolve(addEditTravelerDropdownValues)

export const getTripsForUser = () => Promise.resolve(trips)

export const saveUserSupportPersonInformation = userSupportPersonInfo =>
  backendAxios
    .post('/rsgarrive/saveusersupportpersoninformation', userSupportPersonInfo, getConfig())
    .then(response => response.data)

export const updatePatientInformation = patientInfo =>
  backendAxios
    .post('/rsgarrive/updatepatientinformation', patientInfo, getConfig())
    .then(response => response.data)

export const getNewRequestDropdownValues = () => Promise.resolve(getCreateRequestDropdownValues)

export const getTripDetails = requestNumber => {
  const trip = tripFullDetails.find(trip => trip.requestNumber === requestNumber)
  return Promise.resolve(trip)
}

export const addInPersonVisitRequest = requestData =>
  backendAxios
    .post('/rsgarrive/addinpersonvisitrequest', requestData, getConfig())
    .then(response => response.data)

export const addRemoteSupportDeliveryServiceRequest = requestData =>
  backendAxios
    .post('/rsgarrive/addremotesupportdeliveryservicerequest', requestData, getConfig())
    .then(response => response.data)

export const addRemoteSupportHomeHealthRequest = requestData =>
  backendAxios
    .post('/rsgarrive/addremotesupporthomehealthrequest', requestData, getConfig())
    .then(response => response.data)

export const addRemoteSupportTeleHealthRequest = requestData =>
  backendAxios
    .post('/rsgarrive/addremotesupporttelehealthrequest', requestData, getConfig())
    .then(response => response.data)

export const addAdvancedSupportRequest = requestData =>
  backendAxios
    .post('/rsgarrive/addadvancedsupportrequest', requestData, getConfig())
    .then(response => response.data)

export const addAuxillarySupportRequest = requestData =>
  backendAxios
    .post('/rsgarrive/auxillarysupportrequest', requestData, getConfig())
    .then(response => response.data)

export const addOtherServiceRequest = requestData =>
  backendAxios
    .post('/rsgarrive/otherservicerequest', requestData, getConfig())
    .then(response => response.data)

export const getAllRequests = userId =>
  backendAxios
    .get(`/rsgarrive/requests?cache=${Date.now()}`, getConfig({ userId }))
    .then(response => response.data)

export const getPatientRequests = userId =>
  backendAxios
    .get('/rsgarrive/patientrequests', getConfig({ userId }))
    .then(response => response.data)

export const getInPersonVisitDetails = requestId =>
  backendAxios
    .get('/rsgarrive/inpersonvisitdetails', getConfig({ requestId }))
    .then(response => response.data)

export const getAuxillarySupportDetails = requestId =>
  backendAxios
    .get('/rsgarrive/auxillarysupportdetails', getConfig({ requestId }))
    .then(response => response.data)

export const getAdvancedSupportDetails = requestId =>
  backendAxios
    .get('/rsgarrive/advancedsupportdetails', getConfig({ requestId }))
    .then(response => response.data)

export const getTelehealthSupportDetails = requestId =>
  backendAxios
    .get('/rsgarrive/telehealthsupportdetails', getConfig({ requestId }))
    .then(response => response.data)

export const getDeliveryServicesDetails = requestId =>
  backendAxios
    .get('/rsgarrive/deliveryservicesdetails', getConfig({ requestId }))
    .then(response => response.data)

export const getHomehealthVisitDetails = requestId =>
  backendAxios
    .get('/rsgarrive/homehealthvisitdetails', getConfig({ requestId }))
    .then(response => response.data)

export const getOtherServicesDetails = requestId =>
  backendAxios
    .get('/rsgarrive/otherservicesdetails', getConfig({ requestId }))
    .then(response => response.data)

export const getInPersonServiceDetails = requestId =>
  backendAxios
    .get('/rsgarrive/inpersonservicedetails', getConfig({ requestId }))
    .then(response => response.data)

export const getInPersonTravelerDetails = (userId, requestId) =>
  backendAxios
    .get('/rsgarrive/inpersontravelerdetails', getConfig({ userId, requestId }))
    .then(response => response.data)

export const getRequestAssignmentAndMessageHistory = requestId =>
  backendAxios
    .get('/rsgarrive/requestassignmentandmessagehistory', getConfig({ requestId }))
    .then(response => response.data)

export const getRequestCurrentItinerary = requestId =>
  backendAxios
    .get('/rsgarrive/requestcurrentitinerary', getConfig({ requestId }))
    .then(response => response.data)

export const addRequestStatus = requestData =>
  backendAxios
    .post('/rsgarrive/requeststatus', requestData, getConfig())
    .then(response => response.data)

export const addRequestMessage = messageData =>
  backendAxios
    .post('/rsgarrive/requestmessage', messageData, getConfig())
    .then(response => response.data)

export const getAvailableRequestTypes = userId =>
  backendAxios
    .get('/rsgarrive/requesttypes', getConfig({ userId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err.message))
