import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { AccordionGroup } from 'components/common'
import IndividualPrescreener from './IndividualPrescreener'
import ConciergePrescreener from './ConciergePrescreener'
import SitePrescreener from './SitePrescreener'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllParticipationInfo,
  setShowAddQuestionFormFor
} from 'store/ducks/patientRecord/participationProgress'
import QuickView from 'components/common/QuickView'
import AddQuestionForm from './AddQuestionForm'
import { Restriction } from 'permissions'

const Container = styled.div(({ theme }) => ({
  padding: theme.spacing(2, 0, 3, 0)
}))

const PrescreeningQuestionsAnswers = () => {
  const dispatch = useDispatch()
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { showAddQuestionFormFor } = useSelector(store => store.participationProgress)

  useEffect(() => {
    if (patientInformation.userId) {
      dispatch(fetchAllParticipationInfo(patientInformation.userId))
    }
  }, [patientInformation.userId])
  return (
    <>
      <Container>
        <Typography variant="h2">Prescreening</Typography>
      </Container>
      <Grid container spacing={2}>
        <Restriction ability='read' feature="patientSurveyAnswers">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <AccordionGroup
              accordions={[
                {
                  label: 'Patient Survey Answers',
                  component: <IndividualPrescreener />
                }
              ]}
              color="primary"
              contentBackground="gray"
              transitionProps={{ unmountOnExit: true }}
              contentStyles={{ padding: '24px' }}
            />
          </Grid>
        </Restriction>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Restriction ability="read" feature="secondaryPrescreening">
            <AccordionGroup
              accordions={[
                {
                  label: 'Secondary Prescreening Q&A',
                  component: <ConciergePrescreener />
                }
              ]}
              color="primary"
              contentBackground="gray"
              transitionProps={{ unmountOnExit: true }}
              contentStyles={{ padding: '24px' }}
            />
          </Restriction>
          <Restriction ability="read" feature="additionalPrescreening">
            <AccordionGroup
              accordions={[
                {
                  label: 'Additional Prescreening Q&A',
                  component: <SitePrescreener />
                }
              ]}
              color="primary"
              contentBackground="gray"
              transitionProps={{ unmountOnExit: true }}
              contentStyles={{ padding: '24px' }}
            />
          </Restriction>
        </Grid>
      </Grid>
      <QuickView
        title="Add Prescreening Questions"
        onClose={() => dispatch(setShowAddQuestionFormFor(''))}
        dialogContent={<AddQuestionForm questionType={showAddQuestionFormFor} />}
        isDialogOpen={!!showAddQuestionFormFor}
        maxWidth="sm"
        dialogActions={<div />}
        closeConfirmation={false}
      />
    </>
  )
}

export default PrescreeningQuestionsAnswers
