import PropTypes from 'prop-types'
import permissionContext from './permissionsContext'

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider = ({ permissions, children }) => {
  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter
  const isAllowedTo = ({ ability, feature }) => {
    const restrictedPermissions = permissions.filter(
      permissionObj => permissionObj.feature === feature
    )
    if (restrictedPermissions.length) {
      const indexByAbility = restrictedPermissions.findIndex(
        permissionObj => permissionObj.ability === ability
      )
      if (indexByAbility > -1) {
        return restrictedPermissions[indexByAbility].permission === true
      }
    }
    return false
  }

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return <permissionContext.Provider value={{ isAllowedTo }}>{children}</permissionContext.Provider>
}

PermissionProvider.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      feature: PropTypes.string,
      ability: PropTypes.oneOf(['read', 'edit']),
      permission: PropTypes.bool
    })
  ).isRequired,
  children: PropTypes.node
}

export default PermissionProvider
