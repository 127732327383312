import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const getEndPointsArgs = () => {
  const accessToken = localStorage.getItem('accessToken')
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken || ''}`,
    'Content-Type': 'application/json',
  }
}

// Set the AUTH token for any request
// instance.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('token')
//   config.headers.Authorization = token ? `Bearer ${token}` : ''
//   config.headers.Accept = 'application/json'
//   config.headers['Content-Type'] = 'application/json'
//   return config
// })

export default instance
