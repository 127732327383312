import styled from '@emotion/styled'
import {
  AdsClick,
  CreditCard,
  LocationOn,
  // Mail,
  Medication,
  PersonPinRounded,
  SensorOccupied
} from '@mui/icons-material'
import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material'
import { PageHeader } from 'components/common'
import ExportLink from './ExportLink'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMediaPlanOverview,
  fetchMediaPlannedVsActual,
  fetchPatientProfileReports,
  fetchPatientUsageActivityReports,
  fetchPrescreeningReports,
  fetchReimbursementReportsByVisit,
  fetchSiteInsights,
  fetchSiteManagementPerformance,
  fetchSitePerformance,
  fetchSiteStatusAndAddresses,
  fetchSummaryAndSiteDetailsReport,
  fetchTCNCardPatientUsageReport,
  fetchParticipationProgressReports
} from 'store/ducks/reports'
import { Restriction } from 'permissions'

const MainWrapper = styled(Paper)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(5),
    height: '87vh'
  }
}))

const GridContainer = styled(Grid)(({ theme }) => ({
  '&&': {
    margin: theme.spacing(5, 5, 5, 0)
  }
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  '&&': {
    svg: {
      marginBottom: theme.spacing(0.5)
    }
  }
}))

const Reports = () => {
  const dispatch = useDispatch()
  const { policyPermissions } = useSelector(store => store.application)

  return (
    <MainWrapper>
      <PageHeader
        title={<Typography variant="h4">Reports</Typography>}
        expandContent
        content={
          <GridContainer container spacing={2}>
            <Restriction features={['reportReimbursementsByVisit', 'reportCardPatientUsage']} checkFor="any">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List aria-label="tcn card">
                  <ListItem disablePadding>
                    <StyledListItemIcon>
                      <CreditCard color="primary" fontSize="large" />
                    </StyledListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle1">TCN Card</Typography>} />
                  </ListItem>
                  <Restriction feature='reportReimbursementsByVisit'>
                    <ExportLink
                      label="Reimbursements by Visit"
                      exportTrigger={() => dispatch(fetchReimbursementReportsByVisit())}
                    />
                  </Restriction>
                  {/* <ExportLink label="Impact by Site" disabled /> */}
                  <Restriction feature='reportCardPatientUsage'>
                    <ExportLink
                      label="Card Usage"
                      exportTrigger={() => dispatch(fetchTCNCardPatientUsageReport())}
                    />
                  </Restriction>
                </List>
              </Grid>
            </Restriction>
            <Restriction features={['reportTCNArriveImpact', 'reportRequestSummary']} checkFor="any">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ListItem disablePadding>
                  <StyledListItemIcon>
                    <LocationOn color="primary" fontSize="large" />
                  </StyledListItemIcon>
                  <ListItemText primary={<Typography variant="subtitle1">TCN Arrive</Typography>} />
                </ListItem>
                {/* <ExportLink label="Highlights / Overview" disabled /> */}
                <Restriction feature='reportTCNArriveImpact'>
                  <ExportLink
                    label="Impact Report"
                    exportTrigger={() => dispatch(fetchSitePerformance())}
                  />
                </Restriction>
                <Restriction feature='reportRequestSummary'>
                  <ExportLink
                    label="Request Summary and Details"
                    exportTrigger={() => dispatch(fetchSummaryAndSiteDetailsReport())}
                  />
                </Restriction>
              </Grid>
            </Restriction>
            <Restriction features={['reportPatientProfile', 'reportPatientUsageActivity']} checkFor="any">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List aria-label="patient management">
                  <ListItem disablePadding>
                    <StyledListItemIcon>
                      <PersonPinRounded color="primary" fontSize="large" />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={<Typography variant="subtitle1">Patient Management</Typography>}
                    />
                  </ListItem>
                  <Restriction feature='reportPatientProfile'>
                    <ExportLink
                      label="Patient Profile"
                      exportTrigger={() => dispatch(fetchPatientProfileReports())}
                    />
                  </Restriction>
                  <Restriction feature='reportPatientUsageActivity'>
                    <ExportLink
                      label="Patient Usage & Activity"
                      exportTrigger={() => dispatch(fetchPatientUsageActivityReports())}
                    />
                  </Restriction>
                </List>
              </Grid>
            </Restriction>
            <Restriction features={['reportSiteInsightsDataPoints', 'reportSiteInsightsNotes', 'reportSiteInsightsPreferences', 'reportSiteStatusAndAddresses', 'reportSitePerformance']} checkFor="any">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List aria-label="site management">
                  <ListItem disablePadding>
                    <StyledListItemIcon>
                      <Medication color="primary" fontSize="large" />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={<Typography variant="subtitle1">Site Management</Typography>}
                    />
                  </ListItem>
                  <Restriction features={['reportSiteInsightsDataPoints', 'reportSiteInsightsNotes', 'reportSiteInsightsPreferences']} checkFor="any">
                    <ExportLink
                      label="Site Insights"
                      exportTrigger={() => dispatch(fetchSiteInsights(policyPermissions))}
                    />
                  </Restriction>
                  <Restriction feature='reportSiteStatusAndAddresses'>
                    <ExportLink
                      label="Site Status and Addresses"
                      exportTrigger={() => dispatch(fetchSiteStatusAndAddresses())}
                    />
                  </Restriction>
                  <Restriction feature='reportSitePerformance'>
                    <ExportLink
                      label="Site Performance"
                      exportTrigger={() => dispatch(fetchSiteManagementPerformance())}
                    />
                  </Restriction>
                </List>
              </Grid>
            </Restriction>
            <Restriction features={['reportPrescreener', 'reportPrescreenerWithNotes', 'reportSecondaryPrescreening', 'reportAdditionalPrescreening', 'reportParticipationProgress', 'reportParticipationProgressNotes', 'reportParticipantStatusHistory']} checkFor="any">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List aria-label="referral management">
                  <ListItem disablePadding>
                    <StyledListItemIcon>
                      <SensorOccupied color="primary" fontSize="large" />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={<Typography variant="subtitle1">Referral Management</Typography>}
                    />
                  </ListItem>
                  <Restriction features={['reportPrescreener', 'reportPrescreenerWithNotes', 'reportSecondaryPrescreening', 'reportAdditionalPrescreening']} checkFor="any">
                    <ExportLink
                      label="Prescreening"
                      exportTrigger={() => dispatch(fetchPrescreeningReports(policyPermissions))}
                    />
                  </Restriction>
                  <Restriction features={['reportParticipationProgress', 'reportParticipationProgressNotes', 'reportParticipantStatusHistory']} checkFor="any">
                    <ExportLink
                      label="Participation Progress"
                      exportTrigger={() => dispatch(fetchParticipationProgressReports(policyPermissions))}
                    />
                  </Restriction>
                </List>
              </Grid>
            </Restriction>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <List aria-label="announcement">
                <ListItem disablePadding>
                  <StyledListItemIcon>
                    <Mail color="primary" fontSize="large" />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={<Typography variant="subtitle1">Announcements</Typography>}
                  />
                </ListItem>
              </List>
            </Grid> */}
            <Restriction features={['reportMediaOverview', 'reportMediaPlannedvsActual']} checkFor="any">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List aria-label="media plan">
                  <ListItem disablePadding>
                    <StyledListItemIcon>
                      <AdsClick color="primary" fontSize="large" />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={<Typography variant="subtitle1">Media</Typography>}
                    />
                  </ListItem>
                  <Restriction feature='reportMediaOverview'>
                    <ExportLink
                      label="Media Overview"
                      exportTrigger={() => dispatch(fetchMediaPlanOverview())}
                    />
                  </Restriction>
                  <Restriction feature='reportMediaPlannedvsActual'>
                    <ExportLink
                      label="Planned vs Actual"
                      exportTrigger={() => dispatch(fetchMediaPlannedVsActual())}
                    />
                  </Restriction>
                </List>
              </Grid>
            </Restriction>
          </GridContainer>
        }
        bar={true}
      />
    </MainWrapper>
  )
}

export default Reports
