import React from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { convertTo12HrTime, displayDate } from 'components/helper/utility'
import { Button } from 'components/common'
import { patientTabIDs } from 'components/helper/constants/common'
import PropertyValue from 'components/common/dataDisplay/PropertyValue'
import { useSelector } from 'react-redux'
import {
  requestTypeIds,
  requestStatusLabels,
  addressTypeLabel
} from 'components/helper/constants/visitServices'

const CustomButton = styled(Button)`
  padding-left: 0px;
`

const LinkedRequests = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0),
    a: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      ':hover': {
        textDecoration: 'none'
      }
    },
    p: {
      marginRight: theme.spacing(1),
      fontStyle: 'italic'
    }
  }
}))

const RequestFields = ({ setShowDetailView }) => {
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { instanceId, patientId, requestTypeId } = useParams()
  const { results } = visitDetails
  let content = null

  let serviceLabel = ''
  let serviceValues = null

  if (visitDetails.results) {
    switch (+requestTypeId) {
      case requestTypeIds.auxillarySupport:
        serviceLabel = 'Selected Support Services'
        serviceValues = results.selectedServices?.map(svc => svc.supportTypeName)
        break
      case requestTypeIds.advancedSupport:
        serviceLabel = 'Selected Support Services'
        serviceValues = results.selectedServices?.map(svc => svc.supportTypeName)
        break
      case requestTypeIds.otherServices:
        serviceLabel = 'Selected Services'
        serviceValues = results.selectedServices?.map(svc => svc.serviceTypeName)
        break
      default:
        break
    }
  }

  const commonFields = (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Request Type:"
        value={results?.requestType}
      />
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Request Number:"
        value={results?.requestNumber}
      />
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Status:"
        value={results?.requestStatus}
      />
      <PropertyValue
        labelProps={{ variant: 'body2' }}
        align="row"
        label="Assignment:"
        value={results?.assignment}
      />
      {results?.linkedRequestId && (
        <LinkedRequests>
          <Typography variant="body2">{`Linked ${
            results?.requestStatus === requestStatusLabels.Cancelled ? 'New' : 'Cancelled'
          } Request:`}</Typography>
          <Link
            to={`/instance/${instanceId}/patient-dashboard/${patientId}/${patientTabIDs.visitSupportServices}/${results.linkedRequestId}/${requestTypeId}`}
          >
            {results?.linkedRequestNo}
          </Link>
        </LinkedRequests>
      )}
      <CustomButton variant="text" onClick={() => setShowDetailView(true)}>
        View More
      </CustomButton>
    </Grid>
  )

  if (+requestTypeId === requestTypeIds.inPersonVisit) {
    content = (
      <>
        {commonFields}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Study Visit:"
            value={results?.visitName?.displayText}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Visit Start Date:"
            value={displayDate(results?.visitStartDate)}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Arrival Time:"
            value={convertTo12HrTime(results?.visitArrivalTime)}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Visit End Date:"
            value={displayDate(results?.visitEndDate)}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Departure Time:"
            value={convertTo12HrTime(results?.visitDepartureTime)}
          />
        </Grid>
      </>
    )
  } else if (+requestTypeId === requestTypeIds.deliveryServices) {
    content = (
      <>
        {commonFields}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Study Visit:"
            value={results?.visitName?.displayText}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Pick-up Date:"
            value={displayDate(results?.pickupDate)}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Pick-up Location:"
            value={addressTypeLabel[results?.pickupAddressType]}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Drop-off Date:"
            value={displayDate(results?.dropoffDate)}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Drop-off Location:"
            value={addressTypeLabel[results?.dropoffAddressType]}
          />
        </Grid>
      </>
    )
  } else if (
    +requestTypeId === requestTypeIds.homeHealthVisit ||
    +requestTypeId === requestTypeIds.telehealthVisit
  ) {
    content = (
      <>
        {commonFields}

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Study Visit:"
            value={results?.visitName?.displayText}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Visit Date:"
            value={displayDate(results?.visitDate)}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Visit Time:"
            value={convertTo12HrTime(results?.visitTIme)}
          />
        </Grid>
      </>
    )
  } else if (+requestTypeId === requestTypeIds.otherServices) {
    content = (
      <>
        {commonFields}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Visit Date:"
            value={displayDate(results?.visitDate)}
          />
          {serviceLabel && (
            <PropertyValue
              labelProps={{ variant: 'body2' }}
              align="row"
              label={serviceLabel}
              value={serviceValues}
            />
          )}
        </Grid>
      </>
    )
  } else if (+requestTypeId === requestTypeIds.auxillarySupport) {
    content = (
      <>
        {commonFields}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Study Visit:"
            value={results?.visitName?.displayText}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            side={false}
            label="Visit Date:"
            value={displayDate(results?.visitDate)}
          />
          <br />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            label={serviceLabel}
            value={serviceValues}
          />
        </Grid>
      </>
    )
  } else if (+requestTypeId === requestTypeIds.advancedSupport) {
    content = (
      <>
        {commonFields}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            label="Study Visit:"
            value={results?.visitName?.displayText}
          />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            align="row"
            side={false}
            label="Visit Date:"
            value={displayDate(results?.visitDate)}
          />
          <br />
          <PropertyValue
            labelProps={{ variant: 'body2' }}
            label={serviceLabel}
            value={serviceValues}
          />
        </Grid>
      </>
    )
  }

  return visitDetails.isLoading
    ? (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box>
            <Skeleton height={32} />
            <Skeleton height={32} />
            <Skeleton height={32} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box>
            <Skeleton height={32} />
            <Skeleton height={32} />
            <Skeleton height={32} />
          </Box>
        </Grid>
      </Grid>
    )
    : (
      content
    )
}

RequestFields.propTypes = {
  setShowDetailView: PropTypes.func
}

export default RequestFields
