import React, { useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'

import {
  fetchCountriesLookup,
} from 'store/ducks/common'

import { PageHeader } from 'components/common'
import { questionType } from 'components/helper/constants/participationProgress'

import IVRIntegrationForm from './IVRIntegrationForm'
import PrescreenerQuestions from './PrescreenerQuestions'

const ParticipationProgress = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCountriesLookup())
  }, [])

  return (

    <PageHeader
      title={<Typography variant="h4">Participation Progress</Typography>}
      expandContent
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <IVRIntegrationForm />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PrescreenerQuestions questionType={questionType.concierge} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PrescreenerQuestions questionType={questionType.site} />
          </Grid>
        </Grid>
      }

    />
  )
}

export default ParticipationProgress
