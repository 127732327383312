import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Done } from '@mui/icons-material'
import { Button, PropertyValue, SpecificCard } from 'components/common'
import QuickView from 'components/common/QuickView'
import CardMapIcon from 'assets/images/reimbursement/Card-map-Icon.png'
import { displayDate } from 'components/helper/utility'
import { useDispatch, useSelector } from 'react-redux'
import { onSubmitPlasticCardRequest, setRequestPlasticCardSuccessModal } from 'store/ducks/patientRecord/reimbursement'

const Wrapper = styled.div``

const CardWrapper = styled.div(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(0)};
`,
)
const StyledImage = styled.img(
  ({ theme }) => `
  && {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${theme.spacing(1)}
  }
`,
)
const StyledGrid = styled(Grid)(
  ({ theme }) => `    
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(2)};
`,
)

const RequestButton = styled(Button)(({ theme }) => ({
  marginTop: `${theme.spacing(3)}`,
}))

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: `${theme.spacing(4)}`,
}))

const RequestPlasticCardModal = ({ cardHolderDetails, setRequestPlasticCardModal, reimbursementCardId }) => {
  const dispatch = useDispatch()
  const { requestPlasticCardSuccessModal } = useSelector(store => store.reimbursement)

  return (
    <Wrapper>
      <Title variant="body2" gutterBottom>
        The plastic card must be mailed to the cardholder&apos;s primary residence. If the information below requires a
        change, please contact the Concierge.
      </Title>

      <CardWrapper>
        <SpecificCard
          titleContent={
            <div>
              <StyledImage alt="After authentication" src={CardMapIcon} width="50%" />
            </div>
          }
          mainContent={
            <StyledGrid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PropertyValue
                  label="First Name:"
                  value={cardHolderDetails.firstName}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                <PropertyValue
                  label="Last Name:"
                  value={cardHolderDetails.lastName}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                <PropertyValue
                  label="Email Address:"
                  value={cardHolderDetails.emailAddress}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                {/* <PropertyValue
                  label="Phone Number:"
                  value={cardHolderDetails.phoneNumber}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                /> */}
                {/* <PropertyValue
                  label="Gender:"
                  value={cardHolderDetails.gender.displayText}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                /> */}
                <PropertyValue
                  label="Date of Birth:"
                  value={displayDate(cardHolderDetails.dateOfBirth)}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <PropertyValue
                  label="Country:"
                  value={cardHolderDetails.country.displayText}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                <PropertyValue
                  label="Address 1:"
                  value={cardHolderDetails.address1}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                <PropertyValue
                  label="Address 2:"
                  value={cardHolderDetails.address2}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                <PropertyValue
                  label="City / Town:"
                  value={cardHolderDetails.city}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                <PropertyValue
                  label="State / Province:"
                  value={cardHolderDetails.state.displayText}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
                <PropertyValue
                  label="Zip / Postal Code:"
                  value={cardHolderDetails.zipCode}
                  valueProps={{ variant: 'body2' }}
                  fullWidth
                  align="row"
                  gutterBottom={1}
                  labelProps={{ variant: 'body1' }}
                />
              </Grid>
            </StyledGrid>
          }
          flexDirection="column"
          subContent={undefined}
          showArrow={false}
          isTitleGradient={true}
        />
      </CardWrapper>

      <RequestButton
        // when reimbursementCardId is null, that means user is trying to request plastic card from SignUp accordion, soon after the isAcknowledged submit
        // In this accordion Get Cards call is not made in advance
        onClick={() => dispatch(onSubmitPlasticCardRequest(cardHolderDetails, reimbursementCardId))}
        color="primary"
        endIcon={<Done />}
      >
        Submit
      </RequestButton>
      <QuickView
        title="Success!"
        onClose={() => {
          dispatch(setRequestPlasticCardSuccessModal(false))
          setRequestPlasticCardModal(false)
        }}
        dialogContent={
          <>
            <Typography variant="body2" gutterBottom>
              A plastic card has been successfully requested.
            </Typography>
            <Typography variant="body1">
              The card will arrive via standard mail within 14-21 business days with an explanation on how to activate the
              plastic card.
            </Typography>
          </>
        }
        isDialogOpen={requestPlasticCardSuccessModal}
        maxWidth="sm"
      />
    </Wrapper>
  )
}

RequestPlasticCardModal.propTypes = {
  cardHolderDetails: PropTypes.object,
  setRequestPlasticCardModal: PropTypes.func,
  reimbursementCardId: PropTypes.string,
}

export default RequestPlasticCardModal
