import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import './react-draft-wysiwyg.css'
import styled from '@emotion/styled'
import { Grid, InputLabel, FormHelperText, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useField } from 'formik'

const Wrapper = styled.div`
  .rich-editor-wrapper {
    border: 1px solid ${({ theme }) => theme.palette.midnight.dark};
    min-height: 130px;
    padding: 0 ${({ theme }) => theme.spacing(1.5)};
  }
`

const RichTextEditor = props => {
  const { onChange, id, label, name } = props
  const [{ value }, meta, { setValue, setTouched }] = useField(props)

  const onEditorStateChange = richTextValue => {
    setValue(richTextValue)
  }
  const isError = !!(meta.touched && meta.error)

  return (
    <Wrapper>
      <>
        <InputLabel htmlFor={id}><Typography gutterBottom variant='body2'>{label}</Typography></InputLabel>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Editor
            editorState={value}
            wrapperClassName="rich-main-wrapper"
            editorClassName="rich-editor-wrapper"
            onEditorStateChange={onChange || onEditorStateChange}
            name={name}
            id={id}
            onBlur={setTouched}
          />
        </Grid>
      </>
      {isError && <FormHelperText error={isError}>{meta.touched && meta.error}</FormHelperText>}
    </Wrapper>
  )
}

RichTextEditor.propTypes = {
  /**
   * name to the component, used in formik forms
   */
  name: PropTypes.string,
  /**
   * ID of the component
   */
  id: PropTypes.string,
  /**
   * label of the element
   */
  label: PropTypes.string,
  /**
   * onChange when provided, formik setValue is not used
   */
  onChange: PropTypes.func
}

export default RichTextEditor
