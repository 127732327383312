import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

import {
  fetchAvailableRequestTypes,
  setStartNewRequestForVisitFlow
} from 'store/ducks/visitServices/requestDetails'

import ArrivePatientView from './patientView/ArrivePatientView'

const VisitSupportMainWrapper = () => {
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const dispatch = useDispatch()
  const { isPatient } = useSelector(store => store.auth)

  useEffect(
    () => () => {
      /** Reset setStartNewRequestForVisitFlow if,
      user navigates away from VisitServices  */
      dispatch(setStartNewRequestForVisitFlow(null))
    },
    []
  )

  useEffect(() => {
    if (patientInformation.userId) {
      dispatch(fetchAvailableRequestTypes(patientInformation.userId))
    }
  }, [patientInformation.userId])

  return <>{isPatient ? <ArrivePatientView /> : <Outlet />}</>
}

export default VisitSupportMainWrapper
