import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Document, Page as ReactPdfPage, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
import { Typography, IconButton } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const StyledPage = styled(ReactPdfPage)`
  position: unset !important;
  width: 100%;
  canvas {
    width: inherit !important;
    height: auto !important;
  }
  .react-pdf__Page__annotations {
    display: none;
  }
  .react-pdf__Page__textContent {
    width: 100% !important;
    height: 100% !important;
    top: -49em !important;
    left: -34em !important;
  }
`

// eslint-disable-next-line react/display-name
const Page = React.forwardRef(({ pageNumber }, ref) => (
  <div ref={ref}>
    <StyledPage pageNumber={pageNumber} />
  </div>
))

Page.propTypes = {
  pageNumber: PropTypes.number
}

const PdfWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .react-pdf__Document {
      overflow-y: auto;
      max-height: 65vh;
    }
  }
`
const Pagination = styled.div`
  display: flex;
  align-items: center;
`

const PdfViewer = ({ fetchDocument, fetchingPayload, setPageNumber, pageNumber }) => {
  const [total, setTotal] = useState(0)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const callToFetchDocument = async () => {
    try {
      setLoading(true)
      if (fetchingPayload.fetchId) {
        const { data } = await fetchDocument(fetchingPayload)
        setFile(data)
      }
    } catch (e) {}
    setLoading(false)
  }

  useEffect(() => {
    callToFetchDocument()
  }, [fetchingPayload])

  useEffect(
    () => () => {
      setPageNumber(1)
    },
    []
  )

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(1)
    setTotal(numPages)
  }

  const fileToDisplay = fetchingPayload.resourceURL ? { url: fetchingPayload.resourceURL } : file

  return loading
    ? (
      <Typography variant="subtitle1">Please wait while a document is fetching. . .</Typography>
    )
    : (
      <PdfWrapper>
        <Document file={fileToDisplay} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <Pagination>
          <IconButton
            aria-label="close-announcement-details"
            onClick={() => setPageNumber(pageNumber - 1)}
            size="large"
            color="primary"
            disabled={pageNumber === 1}
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="body2">
            {pageNumber} / {total}
          </Typography>
          <IconButton
            aria-label="close-announcement-details"
            onClick={() => setPageNumber(pageNumber + 1)}
            size="large"
            color="primary"
            disabled={pageNumber === total}
          >
            <ChevronRight />
          </IconButton>
        </Pagination>
      </PdfWrapper>
    )
}

PdfViewer.propTypes = {
  /**
   * service call to fetch documents from the server
   */
  fetchDocument: PropTypes.func,
  /**
   * payload needed for fetching data
   */
  fetchingPayload: PropTypes.object,
  /**
   * pageNumber of the document
   */
  pageNumber: PropTypes.number,
  /**
   * Change the page number
   */
  setPageNumber: PropTypes.func
}

PdfViewer.defaultProps = {
  pageNumber: 1
}

export default PdfViewer
