import { backendAxios, fetchWithRetries, getConfig, getConfigForFileUpload } from 'services/backend'

export const getRecipientDetailsLookupBySendTo = sendToID =>
  backendAxios
    .get(`/common/receipentgroup/${sendToID}`, getConfig())
    .then(res => res)
    .catch(err => Promise.reject(err))

export const createAnnouncements = payload =>
  backendAxios
    .post('/announcement', payload, getConfigForFileUpload())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
export const updateAnnouncement = payload =>
  backendAxios
    .put('/announcement', payload, getConfigForFileUpload())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const fetchAnnouncements = filters =>
  fetchWithRetries('POST', '/announcement/list', getConfig(), { ...filters })

export const fetchDistributionList = announcementId =>
  backendAxios
    .get(`/announcement/${announcementId}/userAnnouncement`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const fetchAnnouncementById = announcementId =>
  backendAxios
    .get(`/announcement/${announcementId}`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const fetchUserAnnouncementById = userAnnouncementId =>
  backendAxios
    .get(`/announcement/userannouncement/${userAnnouncementId}`, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const updateAnnouncementProperty = (announcementId, property, newValue) => {
  const payload = {
    announcementId,
    document: [
      {
        op: 'replace',
        path: property,
        value: newValue
      }
    ]
  }

  return backendAxios
    .patch('/announcement', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

export const updateUserAnnouncementProperty = (userAnnouncementId, property, newValue) => {
  const payload = {
    userAnnouncementId,
    document: [
      {
        op: 'replace',
        path: property,
        value: newValue
      }
    ]
  }

  return backendAxios
    .patch('/announcement/userannouncement', payload, getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}
