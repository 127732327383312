import { backendAxios, getConfig } from './backend'

export const getModulesForPermissions = async () => {
  try {
    const response = await backendAxios.get('/common/permissioncategories', getConfig())
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const getExistingPermissions = async moduleIds => {
  try {
    const response = await backendAxios.get(
      '/permission/existingpermissions',
      getConfig({ moduleIds })
    )
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const getExistingPermissionsByPolicy = async (moduleIds, policies) => {
  try {
    const response = await backendAxios.get('/permission/existingpermissionsbypolicy', getConfig({ moduleIds, policies }))
    return response.data
  } catch (err) {
    return await Promise.reject(err.message)
  }
}

export const saveUpdatedPermissions = async updatedPermissions => {
  const response = await backendAxios.post(
    '/permission/existingpermissionsbypolicy',
    updatedPermissions,
    getConfig()
  )
  return response.data
}
