import React from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError () {
    return {
      errorOccurred: true,
    }
  }

  state = {
    errorOccurred: false,
  }

  render () {
    const { children, fallback } = this.props
    const { errorOccurred } = this.state

    if (errorOccurred) {
      const FallBackComponent = fallback
      return FallBackComponent
    }

    return children
  }

  // This lifecyle method is called when error occurs in children
  componentDidCatch = (error, info) => {
    // eslint-disable-next-line no-console
    console.log(error, info)
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node,
}

export default ErrorBoundary
