import React, { useEffect } from 'react'
import {
  RichTextEditor,
  AttachFile,
  AutoCompleteWithLabel,
  CustomRadioGroup,
  TextBoxOuterLabel,
  DatePickerWithLabel,
  Button
} from 'components/common'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Paper, Typography, Tooltip } from '@mui/material'
import {
  convertDraftToHTML,
  convertHtmlToDraft,
  displayDate,
  getSelectedId
} from 'components/helper/utility'
import parse from 'html-react-parser'
import styled from '@emotion/styled'
import {
  publishAnnouncement,
  setComposeAnnouncement,
  setPreviewAnnouncement,
  downloadAttachment,
  fetchSitesByCountries,
  fetchRecipientDetailsLookupBySendTo
} from 'store/ducks/communicationCenter/announcements'
import {
  announcementSendCategoryIDs,
  recipientsDetailsCodes,
  sendToOptionsFor
} from 'components/helper/constants/mailbox'
import {
  fetchCountriesLookup,
  fetchMessagesSendCategory,
  fetchProductsLookup,
  // fetchCohortsLookup,
  fetchVendorsLookup
} from 'store/ducks/common'
import { showWarning } from 'store/ducks/application'
import { validationMapper } from 'components/helper/validationEngine'
import { useLocation } from 'react-router-dom'
import { Info, Error } from '@mui/icons-material'
import { formElements } from 'components/helper/constants/common'

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
      button {
        margin-top: ${theme.spacing(2)};
        margin-right: ${theme.spacing(2)};
        height: fit-content;
      }
      flex-wrap: wrap;
      display: flex;
      align-items: end;
      justify-content: flex-end;
  `
)

const DateAndBannerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div:first-of-type {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
`

const PreviewWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledForm = styled(Form)`
  width: 100%;
`

const SendToWrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.other.grayMedium};
    border-radius: ${theme.shape.minRadius};
    padding: ${theme.spacing(1, 2)}; 
    legend{
      font-weight: 900;
      color: ${theme.palette.gray.contrastText};
    }
  `
)

const StyledPaper = styled(Paper)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
`
)

const GridContainer = styled(Grid)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
    background-color: ${theme.palette.other.grayMedium};
    border-radius: ${theme.shape.minRadius};
    padding: ${theme.spacing(2)}; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .MuiFormLabel-root {
      font-weight: 900;
      color: ${theme.palette.text.dark};
    }
`
)
const AutoCompleteWrapper = styled(Grid)(
  ({ theme }) => `
 &&{
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiOutlinedInput-root{
    background-color: ${theme.palette.gray.light};
  }
  }
 }
`
)
const StyledRecipientDetails = styled(Grid)(
  ({ theme }) => `
 &&{
  .MuiFormControl-root {
    width: 50%;
  }
  .MuiOutlinedInput-root{
    background-color: ${theme.palette.gray.light};
  }
  }
 }
`
)

const LabelForCountries = styled.div(
  ({ theme }) => `
      display: flex;  
      svg{
        top: -1px;
        width: 1.2rem;
        height: 1.2rem;
        right: -17px;
      }
      p {
        margin-right: ${theme.spacing(1 / 2)};
      }`
)

const PreviewButtonWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    svg: {
      marginTop: theme.spacing(2)
    }
  }
}))

const validationSchema = validationMapper([
  { id: 'sendTo', isResponseRequired: true, answerType: formElements.radioButtons },
  { id: 'subject', isResponseRequired: true, answerType: formElements.openText },
  { id: 'body', isResponseRequired: true, answerType: formElements.richTextEditor },
  {
    id: 'recipientDetails',
    isResponseRequired: true,
    answerType: formElements.dropdownMenu,
    dependency: {
      elements: ['sendTo'],
      callBack: sendTo =>
        /**
         * when one of the options(patient, site or vendor) is selected for sendTo radio.
         */
        sendTo === '1' || sendTo === '2' || sendTo === '3'
    }
  },
  {
    id: 'countries',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
    dependency: {
      elements: ['recipientDetails'],
      callBack: recipientDetails =>
        /**
         * when user selects "patient by country" or "patient by program" or "site by country" or "Sites by program" from Recipient details dropdown
         */
        recipientDetails?.id === recipientsDetailsCodes.patientsByCountry ||
        recipientDetails?.id === recipientsDetailsCodes.sitesByCountry ||
        recipientDetails?.id === recipientsDetailsCodes.patientsByProgram ||
        recipientDetails?.id === recipientsDetailsCodes.sitesByProgram
    }
  },
  {
    id: 'filterByCountry',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
    dependency: {
      elements: ['recipientDetails'],
      callBack: recipientDetails =>
        /**
         * when user selects "patient by site" from Recipient details dropdown
         */
        recipientDetails?.id === recipientsDetailsCodes.patientsBySite
    }
  },
  {
    id: 'siteProtocols',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
    dependency: {
      elements: ['recipientDetails'],
      callBack: recipientDetails =>
        /**
         * when user selects "patient by site" from Recipient details dropdown
         */
        recipientDetails?.id === recipientsDetailsCodes.patientsBySite
    }
  },
  {
    id: 'products',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
    dependency: {
      elements: ['recipientDetails'],
      callBack: recipientDetails =>
        /**
         * when user selects "patient by program" or "Sites by Program" from Recipient details dropdown
         */
        recipientDetails?.id === recipientsDetailsCodes.patientsByProgram ||
        recipientDetails?.id === recipientsDetailsCodes.sitesByProgram
    }
  },
  {
    id: 'vendors',
    isResponseRequired: true,
    answerType: formElements.multiSelectDropdown,
    dependency: {
      elements: ['recipientDetails'],
      callBack: recipientDetails =>
        /**
         * when user selects "specific vendors" from Recipient details dropdown
         */
        recipientDetails?.id === recipientsDetailsCodes.specificVendors
    }
  },
  {
    id: 'distributionDate',
    isResponseRequired: true,
    answerType: formElements.date,
    // condition: {
    //   min: new Date(),
    //   minError: 'Date value must not be in the past'
    // },
    // dependency: {
    //   elements: ['recipientDetails'],
    //   callBack: recipientDetails =>
    //   /**
    //      * when user selects "specific vendors" from Recipient details dropdown
    //      */
    //     recipientDetails?.id === recipientsDetailsCodes.specificVendors

    // },
  }
])

const ComposeAnnouncement = () => {
  const dispatch = useDispatch()
  const { composeAnnouncement, previewAnnouncement, recipientDetailsLookup, sitesLookupByCountry } =
    useSelector(store => store.announcements)
  const { pathname } = useLocation()
  const selectedCommunicationTabId = getSelectedId(pathname)
  const {
    countriesLookup,
    messagesSendCategory,
    productsLookup,
    // cohortsLookup,
    vendorsLookup
  } = useSelector(store => store.common)

  useEffect(() => {
    dispatch(fetchMessagesSendCategory(sendToOptionsFor[selectedCommunicationTabId]))
  }, [])

  const onPreviewClicked = values => {
    dispatch(
      setComposeAnnouncement({
        ...values,
        body: convertDraftToHTML(values.body),
        distributionDate: displayDate(values.distributionDate)
      })
    )
    dispatch(setPreviewAnnouncement(true))
  }

  const updatedComposeAnnouncement = {
    ...composeAnnouncement,
    body: convertHtmlToDraft(composeAnnouncement.body)
  }

  const onSendToChange = (sendToValue, setFormikFieldValue) => {
    setFormikFieldValue('sendTo', sendToValue)
    // clear any selected value from recipientDetails dropdown
    setFormikFieldValue('recipientDetails', null)
    if (sendToValue !== announcementSendCategoryIDs.allActiveUsers) {
      // onchange of SendTo radio, the Recipient Details Dropdown won't re-render, therefore
      // this lookup call is done here instead of passing it as fetchOptions props to the dropdown
      dispatch(fetchRecipientDetailsLookupBySendTo(sendToValue))
    }
  }

  const onFilterByCountriesChange = (selectedValues, reason, setFieldValue) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      // Dynamic dropdown options for Sites based on country selection
      if (selectedValues.find(option => option.id === 'select-all')) {
        setFieldValue('filterByCountry', countriesLookup.results)
        dispatch(fetchSitesByCountries(countriesLookup.results))
      } else {
        setFieldValue('filterByCountry', selectedValues)
        if (selectedValues.length > 0) {
          dispatch(fetchSitesByCountries(selectedValues))
        }
        if (reason === 'removeOption') {
          // Empty the selected values if any
          setFieldValue('siteProtocols', [])
        }
      }
    } else if (reason === 'clear') {
      setFieldValue('siteProtocols', [])
      setFieldValue('filterByCountry', [])
    }
  }

  return previewAnnouncement
    ? (
      <PreviewWrapper container spacing={4}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          {parse(convertDraftToHTML(updatedComposeAnnouncement.body))}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Button
            size="medium"
            color="primary"
            onClick={() => dispatch(setPreviewAnnouncement(false))}
          >
          Back to Write New Announcement
          </Button>
        </Grid>
      </PreviewWrapper>
    )
    : (
      <Formik
        initialValues={updatedComposeAnnouncement}
        enableReinitialize={false}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            publishAnnouncement(
              {
                ...values,
                body: convertDraftToHTML(values.body)
              },
              selectedCommunicationTabId
            )
          )
          setSubmitting(false)
        }}
      >
        {({ values, setFieldValue }) => (
          <StyledForm noValidate>
            <>
              <GridContainer container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <SendToWrapper>
                    <CustomRadioGroup
                      label="Send To:"
                      name="sendTo"
                      id="sendTo"
                      options={messagesSendCategory.results.map(item => ({
                        label: item.displayText,
                        value: item.id
                      }))}
                      onChange={e => {
                        onSendToChange(parseInt(e.target.value), setFieldValue)
                      }}
                      fullWidth={true}
                      row={true}
                      orientation="column"
                      color="primary"
                      formik="false"
                      size="small"
                      value={values.sendTo}
                    />
                  </SendToWrapper>
                </Grid>
              </GridContainer>
              {values.sendTo !== announcementSendCategoryIDs.allActiveUsers && (
                <GridContainer container spacing={2}>
                  <StyledRecipientDetails item xs={12} sm={12} md={12} lg={12}>
                    <AutoCompleteWithLabel
                      multiple={false}
                      hasSelectAllOption={false}
                      size="small"
                      id="recipientDetails"
                      name="recipientDetails"
                      label="Recipient Details:"
                      options={recipientDetailsLookup.results}
                      isLoading={recipientDetailsLookup.isLoading}
                      required={values.sendTo !== announcementSendCategoryIDs.allActiveUsers}
                    />
                  </StyledRecipientDetails>

                  {(values.recipientDetails?.id === recipientsDetailsCodes.patientsByCountry ||
                  values.recipientDetails?.id === recipientsDetailsCodes.sitesByCountry) && (
                  // when user selects "patient by country" or "site by country" from Recipient details dropdown
                    <AutoCompleteWrapper item xs={12} sm={6} md={6} lg={6}>
                      <AutoCompleteWithLabel
                        multiple={true}
                        hasSelectAllOption={true}
                        size="small"
                        id="selectCountries"
                        name="countries"
                        label="Select Country / Countries:"
                        options={countriesLookup.results}
                        isLoading={countriesLookup.isLoading}
                        fetchOptions={() => dispatch(fetchCountriesLookup())}
                      />
                    </AutoCompleteWrapper>
                  )}
                  {(values.recipientDetails?.id === recipientsDetailsCodes.patientsByProgram ||
                  values.recipientDetails?.id === recipientsDetailsCodes.sitesByProgram) && (
                  // when user selects "patient by product" or "site by product" from Recipient details dropdown
                    <>
                      <AutoCompleteWrapper item xs={12} sm={12} md={6} lg={6}>
                        <AutoCompleteWithLabel
                          multiple={true}
                          hasSelectAllOption={true}
                          size="small"
                          id="selectProduct"
                          name="products"
                          label="Select Program:"
                          options={productsLookup.results}
                          isLoading={productsLookup.isLoading}
                          fetchOptions={() => dispatch(fetchProductsLookup())}
                        />
                      </AutoCompleteWrapper>
                      <AutoCompleteWrapper item xs={12} sm={12} md={6} lg={6}>
                        <AutoCompleteWithLabel
                          multiple={true}
                          hasSelectAllOption={true}
                          size="small"
                          id="selectCountries"
                          name="countries"
                          label={
                            <LabelForCountries>
                              <Typography variant="body2">Select Country / Countries:</Typography>
                              <Tooltip
                                color="primary"
                                size="small"
                                title={
                                  <Typography variant="body1">
                                    {`Optional: Send to ${
                                      values.recipientDetails.id ===
                                    recipientsDetailsCodes.patientsByProgram
                                        ? 'patients'
                                        : 'sites'
                                    } by program in the following country / countries`}
                                  </Typography>
                                }
                              >
                                <Error size="small" />
                              </Tooltip>
                            </LabelForCountries>
                          }
                          options={countriesLookup.results}
                          isLoading={countriesLookup.isLoading}
                          fetchOptions={() => dispatch(fetchCountriesLookup())}
                        />
                      </AutoCompleteWrapper>
                    </>
                  )}
                  {values.recipientDetails?.id === recipientsDetailsCodes.patientsBySite && (
                  // when user selects "patient by site"  from Recipient details dropdown
                    <>
                      <AutoCompleteWrapper item xs={12} sm={12} md={6} lg={6}>
                        <AutoCompleteWithLabel
                          multiple={true}
                          hasSelectAllOption={false}
                          size="small"
                          id="selectCountries"
                          name="filterByCountry"
                          label="Filter By Country:"
                          options={countriesLookup.results}
                          isLoading={countriesLookup.isLoading}
                          onChange={(event, selectedValues, reason) => {
                            onFilterByCountriesChange(selectedValues, reason, setFieldValue)
                          }}
                          fetchOptions={() => dispatch(fetchCountriesLookup())}
                        />
                      </AutoCompleteWrapper>
                      <AutoCompleteWrapper item xs={12} sm={12} md={6} lg={6}>
                        <AutoCompleteWithLabel
                          multiple={true}
                          hasSelectAllOption={true}
                          size="small"
                          id="selectSite"
                          name="siteProtocols"
                          label="Select Sites:"
                          options={sitesLookupByCountry.results}
                          isLoading={sitesLookupByCountry.isLoading}
                        />
                      </AutoCompleteWrapper>
                    </>
                  )}
                  {/* {values.recipientDetails?.id === 4 && (
                      // when user selects "patient by cohort / Arm"  from Recipient details dropdown
                      <AutoCompleteWrapper item xs={12} sm={12} md={6} lg={6}>
                        <AutoCompleteWithLabel
                          multiple={true}
                          hasSelectAllOption={true}
                          size="small"
                          id="selectCohort"
                          name="cohorts"
                          label="Select Cohort(s)"
                          options={cohortsLookup.results}
                          isLoading={cohortsLookup.isLoading}
                          fetchOptions={() => dispatch(fetchCohortsLookup())}
                        />
                      </AutoCompleteWrapper>
                    )} */}
                  {values.recipientDetails?.id === recipientsDetailsCodes.specificVendors && (
                  // when user selects "Specific vendors"  from Recipient details dropdown
                    <AutoCompleteWrapper item xs={12} sm={12} md={6} lg={6}>
                      <AutoCompleteWithLabel
                        multiple={true}
                        hasSelectAllOption={true}
                        size="small"
                        id="selectVendor"
                        name="vendors"
                        label="Select Vendor(s)"
                        options={vendorsLookup.results}
                        isLoading={vendorsLookup.isLoading}
                        fetchOptions={() => dispatch(fetchVendorsLookup())}
                      />
                    </AutoCompleteWrapper>
                  )}
                </GridContainer>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextBoxOuterLabel
                    required
                    size="medium"
                    id="subject"
                    label="Subject:"
                    name="subject"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <StyledPaper elevation={1}>
                    <RichTextEditor name="body" id="body" />
                  </StyledPaper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AttachFile
                    label="Attach File:"
                    name="attachments"
                    downloadAttachment={file => dispatch(downloadAttachment(file))}
                    onDelete={deletedFile => {
                      setFieldValue('attachmentsToBeDeleted', [
                        ...values.attachmentsToBeDeleted,
                        deletedFile.id
                      ])
                    }}
                    onFileUploadFailure={msg => dispatch(showWarning(msg))}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <DateAndBannerWrapper>
                    <DatePickerWithLabel
                      id="distribution-date"
                      label="Distribution Date"
                      name="distributionDate"
                      required={true}
                      minDate={new Date()}
                      size="small"
                    />
                    {/* <Checkbox
                      name="displayAsBanner"
                      id="displayAsBanner"
                      label="Display as pop-up"
                      size="small"
                    /> */}
                  </DateAndBannerWrapper>
                </Grid>

                <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
                  <Button color="primary" type="submit" size="medium" fullWidth={false}>
                    {composeAnnouncement.announcementId
                      ? 'Save'
                      : `${
                        displayDate(values.distributionDate) === displayDate(new Date())
                          ? 'Send Now'
                          : 'Send Later'
                      }`}
                  </Button>
                  <PreviewButtonWrapper>
                    <Button
                      disabled={
                        !!values.attachments.length || convertDraftToHTML(values.body) === '<p></p>\n'
                      }
                      size="medium"
                      color="inherit"
                      onClick={() => onPreviewClicked(values)}
                    >
                    Preview
                    </Button>
                    {(values.attachments.length > 0 ||
                    convertDraftToHTML(values.body) === '<p></p>\n') && (
                      <Tooltip
                        title={
                          <Typography variant="body1">
                          Preview option is disabled, because there is no content to preview or
                          there are one/more attachments selected.
                          </Typography>
                        }
                      >
                        <Info />
                      </Tooltip>
                    )}
                  </PreviewButtonWrapper>
                </ActionWrapper>
              </Grid>
            </>
          </StyledForm>
        )}
      </Formik>
    )
}

export default ComposeAnnouncement
