import React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { CardContent, Typography } from '@mui/material'
import { Button } from './form'

const CardWrapper = styled(Card)`
  width: 337px;
  margin-bottom: 15px;
  margin-right: 5px;
  &:hover {
    box-shadow: 4px 4px 4px 2px #09090957;
    background-color: unset;
  }
`
const StyledButton = styled(Button)(({ theme }) => ({
  '&&': {
    height: '158px',
    width: '337px',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'unset',
    padding: '0px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const CardContentWrapper = styled(CardContent)`
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CardLogo = styled.img`
  width: 80%;
  color: ${({ theme }) => theme.palette.midnight.dark};
`

const CardBottomTitle = styled(CardActions)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 25%;
  display: flex;
  justify-content: center;
  width: 100%;
`

const MediaCard = props => (
  <CardWrapper>
    <StyledButton
      color="secondary"
      onClick={() => {
        props.onCardClicked(props.cardID)
      }}
    >
      <CardContentWrapper>
        <CardLogo src={props.imageURL} alt={props.studyTitle} />
      </CardContentWrapper>
      <CardBottomTitle>
        <Typography variant="body2">{props.studyTitle}</Typography>
      </CardBottomTitle>
    </StyledButton>
  </CardWrapper>
)

MediaCard.propTypes = {
  imageURL: PropTypes.string,
  studyTitle: PropTypes.string,
  cardID: PropTypes.string,
  onCardClicked: PropTypes.func,
}

export default MediaCard
