import { createAction, createReducer } from '@reduxjs/toolkit'

import {
  copyObject,
  sortList,
  formInitialValues
} from 'components/helper/utility'

import { setLoading } from '../userInteractions'
import { showError } from '../application'
import {
  getSiteUserActivity,
} from 'services/siteManagement'
import {
  getSitePrefQuestionAndResponse
} from 'services/settings'

export const setShowQuestionAndAnswerModal = createAction(
  'siteManagement/setShowQuestionAndAnswerModal'
)
export const setRequestedSort = createAction('userActivity/setRequestedSort')
export const setSiteTablePageNumber = createAction('userActivity/setSiteTablePageNumber')
export const setRowsPerPage = createAction('userActivity/setRowsPerPage')
export const setSiteQuestionAndAnswers = createAction('userActivity/setSiteQuestionAndAnswers')
const setSiteUserActivity = createAction('userActivity/setSiteUserActivity')

export const resetUserActivity = createAction('userActivity/resetUserActivity')

export const handleRequestSort = orderByValue => (dispatch, getState) => {
  const { siteUserActivity, order, orderBy } = getState().userActivity
  dispatch(setRequestedSort(orderByValue))

  dispatch(
    setSiteUserActivity(sortList(siteUserActivity, order, orderBy))
  )
}

export const fetchSiteUserActivity = siteId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const siteUserActivity = await getSiteUserActivity(siteId)
    dispatch(setSiteUserActivity(siteUserActivity))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchSitePrefQuestionAndAnswers = userId => async dispatch => {
  dispatch(setLoading(true))
  try {
    const response = await getSitePrefQuestionAndResponse(userId)
    if (response && response.length > 0) {
      const initialValues = {}

      /** Loop through the categories and create an initial values object */
      const categories = response.map(eachCategory => {
        const finalQuestions = []
        eachCategory.questions.forEach(question => {
          /** As this is a view-only screen for site users, adding disabled: true property */
          finalQuestions.push({ ...question, disabled: true })
          initialValues[`${question.id}`] = formInitialValues(question)
        })
        return { ...eachCategory, questions: finalQuestions }
      })

      const finalResponse = {
        initialValues,
        categories
      }
      dispatch(setSiteQuestionAndAnswers(finalResponse))
      dispatch(setShowQuestionAndAnswerModal(true))
    } else {
      dispatch(
        showError(
          'There was some error while trying to fetch site preference question and answers.'
        )
      )
    }
  } catch (e) {
    dispatch(
      showError('There was some error while trying to fetch site preference question and answers.', e)
    )
  }
  dispatch(setLoading(false))
}

const initialState = {
  siteUserActivity: undefined,
  showQuestionAndAnswerModal: false,
  orderBy: 'createdDate',
  order: 'DESC',
  siteQuestionAndAnswers: {
    initialValues: {},
    categories: []
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setSiteUserActivity, (state, action) => {
      state.siteUserActivity = action.payload
    })
    .addCase(setShowQuestionAndAnswerModal, (state, action) => {
      state.showQuestionAndAnswerModal = action.payload
    })
    .addCase(setRequestedSort, (state, action) => {
      const isDesc = state.orderBy === action.payload && state.order === 'DESC'
      state.order = isDesc ? 'ASC' : 'DESC'
      state.orderBy = action.payload
    })
    .addCase(setSiteQuestionAndAnswers, (state, action) => {
      state.siteQuestionAndAnswers = action.payload
    })
    .addCase(resetUserActivity, state => {
      copyObject(state, initialState)
    })
})
