import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { palette } from 'styles/baseTheme'

import {
  fetchSitePrescreenerQuestionAnswers,
  fetchConciergePrescreenerQuestionAnswers
} from 'store/ducks/configuration/participationProgressConfiguration'
import { questionType as questionTypeConstant } from 'components/helper/constants/participationProgress'

import { Card, DataGrid } from 'components/common'
import AddConfigQuestionForm from './AddConfigQuestionForm'
import { Chip } from '@mui/material'

const ContentWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`
const DatagridWrapper = styled.div`
  width: 98%;
  padding-top: 5px;
  padding-bottom: 10px;
`

const SitesListWrapper = styled.div(({ theme }) => ({
  '&&': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    '.MuiChip-root': {
      width: 'fit-content',
      margin: theme.spacing(0.5)
    }
  }
}))

const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}

const headers = [
  {
    id: 'question',
    label: 'Question',
    bodyProperties: {
      width: '30%',
      textAlign: 'left'
    },
    headProperties: { ...headerProperties, textAlign: 'left' }
  },
  {
    id: 'associatedSites',
    label: 'Associated Sites',
    bodyProperties: {
      cellType: 'node',
      width: '40%',
      textAlign: 'left'
    },
    headProperties: { ...headerProperties, textAlign: 'left' }
  }
]

const PrescreenerQuestions = ({ questionType }) => {
  const dispatch = useDispatch()
  let questions = []
  const { conciergePrescreeningQuestions, sitePrescreenerQuestions } = useSelector(
    store => store.participationProgressConfiguration
  )

  useEffect(() => {
    if (questionType === questionTypeConstant.concierge) {
      dispatch(fetchConciergePrescreenerQuestionAnswers())
    } else if (questionType === questionTypeConstant.site) {
      dispatch(fetchSitePrescreenerQuestionAnswers())
    }
  }, [])

  if (questionType === questionTypeConstant.concierge) {
    questions = conciergePrescreeningQuestions
  } else if (questionType === questionTypeConstant.site) {
    questions = sitePrescreenerQuestions
  }

  return (
    <Card
      title={`${
        questionType === 'concierge'
          ? 'Secondary Prescreener Questions'
          : 'Additional Prescreener Questions'
      } `}
      cardHeaderStyles={{
        '.MuiCardHeader-title': {
          fontSize: '16px'
        },
        padding: '14px 14px 7px 14px',
        marginTop: '14px'
      }}
    >
      <>
        <ContentWrapper>
          <AddConfigQuestionForm questionType={questionType} />
        </ContentWrapper>
        <DatagridWrapper>
          <DataGrid
            headers={headers}
            tableData={questions.results.map(question => ({
              question: question.question,
              associatedSites: (
                <SitesListWrapper>
                  {question.associatedSites.map((site, index) => (
                    <Chip variant="outlined" size="small" key={index} label={site} />
                  ))}
                </SitesListWrapper>
              )
            }))}
            setRequestedSort={() => {}}
            order={'DESC'}
            orderBy={'createdDate'}
            border="allSides"
            radius="false"
          />
        </DatagridWrapper>
      </>
    </Card>
  )
}

PrescreenerQuestions.propTypes = {
  questionType: PropTypes.oneOf([questionTypeConstant.site, questionTypeConstant.concierge])
}

export default PrescreenerQuestions
