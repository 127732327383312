import { communicationTabIDs } from 'components/helper/constants/mailbox'
import { configurationTabIDs } from 'components/helper/constants/configuration'
import { patientTabIDs } from 'components/helper/constants/common'
import PropTypes from 'prop-types'
import {
  Drafts,
  PrivacyTip,
  Person,
  DateRangeRounded,
  HomeRounded,
  PersonRounded,
  MailRounded,
  FeedRounded,
  CreditCardRounded,
  LocationOnRounded
} from '@mui/icons-material'
import Announcements from 'components/private/communicationCenter/announcements/Announcements'
import StudyMessages from 'components/private/communicationCenter/studyMessages/StudyMessages'
import styled from '@emotion/styled'
import PatientDashboard from 'components/private/patientSection/dashboard/PatientDashboard'
import PatientProfile from 'components/private/patientSection/patientProfile/PatientProfile'
import ParticipationProgress from 'components/private/patientSection/participationProgress/ParticipationProgress'
import PatientMailBox from 'components/private/patientSection/patientMailbox/PatientMailbox'
import Reimbursement from 'components/private/patientSection/reimbursement/Reimbursement'
import VisitSupportMainWrapper from 'components/private/patientSection/visitSupportServices/VisitSupportMainWrapper'
import PatientStudyTools from 'components/private/patientSection/PatientStudyTools'
import { pages } from 'components/helper/constants/visitServices'
import VisitServicesDashboard from 'components/private/patientSection/visitSupportServices/VisitServicesDashboard'
import SelectRequestType from 'components/private/patientSection/visitSupportServices/SelectRequestType'
import MakeARequest from 'components/private/patientSection/visitSupportServices/makeARequest/MakeARequest'
import RequestDetails from 'components/private/patientSection/visitSupportServices/requestDetails/RequestDetails'
import UpdateInformation from 'components/private/myAccount/myInformation/UpdateInformation'
// import BankTransfer from 'components/private/patientSection/reimbursement/bankTransfer/BankTransfer'
import ReimbursementWrapper from 'components/private/patientSection/reimbursement/ReimbursementWrapper'
import ReimbursementPatientView from 'components/private/patientSection/reimbursement/ReimbursementPatientView'

import ProtocolCampaignConfiguration from 'components/private/configuration/ProtocolCampaignConfiguration'
import MediaReferralsConfiguration from 'components/private/configuration/MediaReferralsConfiguration'
import ParticipationProgressConfiguration from 'components/private/configuration/participationProgressConfiguration/ParticipationProgressConfiguration'
import SitePreferencesConfiguration from 'components/private/configuration/sitePreferences/SitePreferencesConfiguration'
import ResourceCenterConfiguration from 'components/private/configuration/ResourceCenterConfiguration'
import TCNArriveConfiguration from 'components/private/configuration/tcnArriveConfiguration/TCNArriveConfiguration'
import TCNCardConfiguration from 'components/private/configuration/cardConfiguration/TCNCardConfiguration'
import VisitDetailsConfiguration from 'components/private/configuration/visitDetailsConfiguration/VisitDetailsConfiguration'
import PermissionsConfiguration from 'components/private/configuration/PermissionsConfiguration'
import TermsAndConditions from 'components/private/patientSection/reimbursement/patientView/TermsAndConditions'

export const myAccountTabs = [
  {
    label: 'MY INFORMATION',
    icon: <Person />,
    to: 'myInformation',
    component: UpdateInformation
  }
  // Site Addresses moved to Site Management, Quick Query moved under Configuration/Site Preferences
]

export const communicationCenterTabs = loggedInAsSite => [
  {
    label: 'GENERAL ANNOUNCEMENTS',
    icon: <PrivacyTip />,
    to: communicationTabIDs.announcementTab,
    component: Announcements
  },
  {
    label: `${loggedInAsSite ? 'CONCIERGE MESSAGES' : 'CONCIERGE MESSAGES WITH SITES'}`,
    icon: <Drafts />,
    to: communicationTabIDs.conciergeMsgsWithSites,
    component: StudyMessages
  },
  {
    label: 'CONCIERGE MESSAGES WITH PATIENTS',
    icon: <Drafts />,
    to: communicationTabIDs.conciergeMsgsWithPatients,
    component: StudyMessages
  }
]

export const configurationTabs = [
  {
    label: 'PROJECT OVERVIEW',
    icon: <></>,
    to: configurationTabIDs.protocolCampaign,
    component: ProtocolCampaignConfiguration
  },
  {
    label: 'SITE PREFERENCES',
    icon: <></>,
    to: configurationTabIDs.sitePreferences,
    component: SitePreferencesConfiguration
  },
  {
    label: 'RESOURCE CENTER',
    icon: <></>,
    to: configurationTabIDs.resourceCenter,
    component: ResourceCenterConfiguration
  },
  {
    label: 'PARTICIPATION PROGRESS',
    icon: <></>,
    to: configurationTabIDs.participationProgress,
    component: ParticipationProgressConfiguration
  },
  {
    label: 'MEDIA / REFERRALS',
    icon: <></>,
    to: configurationTabIDs.mediaReferrals,
    component: MediaReferralsConfiguration
  },
  {
    label: 'TCN CARD',
    icon: <></>,
    to: configurationTabIDs.tcnCard,
    component: TCNCardConfiguration
  },
  {
    label: 'TCN ARRIVE',
    icon: <></>,
    to: configurationTabIDs.tcnArrive,
    component: TCNArriveConfiguration
  },
  {
    label: 'VISIT DETAILS',
    icon: <></>,
    to: configurationTabIDs.visitDetails,
    component: VisitDetailsConfiguration
  },
  {
    label: 'PERMISSIONS',
    icon: <></>,
    to: configurationTabIDs.permissions,
    component: PermissionsConfiguration
  }
]

export const patientMailBoxTabs = [
  {
    label: 'CONCIERGE MESSAGES',
    icon: <Drafts />,
    to: communicationTabIDs.conciergeMsgsForPatients,
    component: StudyMessages
  },
  {
    label: 'GENERAL ANNOUNCEMENTS',
    icon: <PrivacyTip />,
    to: communicationTabIDs.announcementsForPatients,
    component: Announcements
  }
]

const CustomIcon = ({ icon }) => {
  const StyledIcon = styled(icon)`
    font-size: 35px !important;
  `
  return <StyledIcon color="customBlue" />
}

CustomIcon.propTypes = {
  icon: PropTypes.object
}

export const tcnArriveTabs = [
  { to: 'arriveDashboard', component: VisitServicesDashboard },
  { to: ':requestId/:requestTypeId', component: RequestDetails },
  {
    to: pages.makeARequest,
    component: SelectRequestType
  },
  { to: 'makeARequest/:requestType', component: MakeARequest },
  { to: 'makeARequest/:requestType/:copyRequestId', component: MakeARequest }
]

export const patientTabs = (isPatient, isRestrictedCountry) => {
  const patientGivenTabs = [
    {
      label: isPatient ? 'HOME' : 'DASHBOARD',
      icon: <CustomIcon icon={HomeRounded} />,
      to: patientTabIDs.dashboard,
      component: PatientDashboard
    },
    {
      label: isPatient ? 'PROFILE' : 'PATIENT PROFILE',
      icon: <CustomIcon icon={PersonRounded} />,
      to: patientTabIDs.patientProfile,
      component: PatientProfile
    },
    {
      label: 'PARTICIPATION PROGRESS',
      icon: <CustomIcon icon={DateRangeRounded} />,
      to: patientTabIDs.participationProgress,
      component: ParticipationProgress
    },
    {
      label: 'MAILBOX',
      icon: <CustomIcon icon={MailRounded} />,
      children: patientMailBoxTabs,
      to: patientTabIDs.patientMailbox,
      component: PatientMailBox
    },
    {
      label: 'STUDY TOOLS',
      icon: <CustomIcon icon={FeedRounded} />,
      to: patientTabIDs.studyTools,
      component: PatientStudyTools
    },
    {
      label: 'TCN CARD',
      icon: <CustomIcon icon={CreditCardRounded} />,
      to: patientTabIDs.reimbursement,
      component: ReimbursementWrapper,
      children: [
        {
          label: 'Reimbursement',
          to: '',
          component: Reimbursement
        }
      ]
    },
    {
      label: 'TCN ARRIVE',
      icon: <CustomIcon icon={LocationOnRounded} />,
      children: tcnArriveTabs,
      to: patientTabIDs.visitSupportServices,
      component: VisitSupportMainWrapper
    }
  ]

  const finalPatientTabs = patientGivenTabs.filter(tab => {
    // // when isTCNCardAvailable is true only then show TCN card tab
    // if (tab.to === patientTabIDs.reimbursement && isTCNCardAvailable) {
    //   return true
    // }
    /**
     * if the logged in is patient only then have the transferFunds and termsconditions path available
     */
    if (isPatient) {
      if (tab.label === 'TCN CARD') {
        tab.children = [
          {
            label: 'Reimbursement',
            to: '',
            component: ReimbursementPatientView
          },
          // {
          //   label: 'TRANSFER FUNDS',
          //   to: ':reimbursementCardId/transferFunds',
          //   component: BankTransfer
          // },
          {
            label: 'Terms & Conditions',
            to: ':reimbursementCardId/termsconditions',
            component: TermsAndConditions
          }
        ]
      }
      /**
       * No participation progress tab for patient
       * AND
       * No Reimbursement tab when restricted country for patient
       */
      return (
        tab.to !== patientTabIDs.participationProgress &&
        !(isRestrictedCountry && tab.to === patientTabIDs.reimbursement)
      )
    }

    return true
  })

  return finalPatientTabs
}
