import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  copyObject,
  displayDate,
  extractArrayOfIdsForEachKey,
  formatCurrency,
  addCommas
} from 'components/helper/utility'
import { mapValuesToObjects } from 'components/helper/utilities'
import {
  getMediaPlanDetails,
  getMediaPlans,
  postMediaPlan,
  postMediaPlanUpdate
} from 'services/mediaPlans'
import { showError } from './application'
import { setLoading } from './userInteractions'
import {
  fetchMediaPlanSitesLookup,
  fetchMediaPlanStatusesLookup,
  fetchTacticsLookup
} from 'store/ducks/common'

export const setMediaPlansData = createAction('mediaPlans/setMediaPlansData')
export const setAddMediaPlan = createAction('mediaPlans/setAddMediaPlan')
export const setMediaPlanDetails = createAction('mediaPlan/setMediaPlanDetails')
export const resetMediaPlans = createAction('mediaPlans/resetMediaPlans')

export const fetchMediaPlansData = () => async dispatch => {
  try {
    dispatch(setMediaPlansData({ isLoading: true }))
    const { data } = await getMediaPlans()
    const formattedData = data.map(d => ({
      ...d,
      plannedStartDate: displayDate(d.plannedStartDate),
      plannedEndDate: displayDate(d.plannedEndDate),
      actualMediaStartDate: displayDate(d.actualMediaStartDate),
      actualMediaEndDate: displayDate(d.actualMediaEndDate),
      totalBudget: d.totalBudget ? `$${addCommas(formatCurrency(d.totalBudget))}` : '',
      totalSpendtoDate: d.totalSpendtoDate
        ? `$${addCommas(formatCurrency(d.totalSpendtoDate))}`
        : ''
    }))
    dispatch(
      setMediaPlansData({
        isLoading: false,
        results: formattedData
      })
    )
  } catch (e) {
    dispatch(setMediaPlansData(initialState.mediaPlansData))
    dispatch(showError('There was issue while trying to fetch Media Plans', e))
  }
}

export const onAddMediaPlan = values => async dispatch => {
  try {
    dispatch(setLoading(true))

    const multiSelectDropDowns = {
      sites: { array: values.sites, propertyToExtract: 'alternateIdentifier' },
      tactics: values.tactics
    }
    const payload = {
      planName: values.planName,
      ...extractArrayOfIdsForEachKey(multiSelectDropDowns),
      netPlacementBudget: parseFloat(values.netPlacementBudget),
      bbkFees: parseFloat(values.bbkFees),
      vendorFees: parseFloat(values.vendorFees),
      plannedInquiries: parseFloat(values.plannedInquiries),
      plannedReferrals: parseFloat(values.plannedReferrals),
      plannedStartDate: values.plannedStartDate,
      plannedEndDate: values.plannedEndDate
    }
    await postMediaPlan(payload)
    dispatch(setAddMediaPlan(false))
    dispatch(setLoading(false))
    dispatch(fetchMediaPlansData())
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(setMediaPlansData(initialState.mediaReferralsConfiguration))
    dispatch(showError('There was issue while trying to create a new Media plan.', e))
  }
}

export const fetchMediaPlanDetails = mediaPlanId => async (dispatch, getState) => {
  try {
    dispatch(setMediaPlanDetails({ isLoading: true }))
    await dispatch(fetchMediaPlanSitesLookup())
    await dispatch(fetchTacticsLookup())
    await dispatch(fetchMediaPlanStatusesLookup())
    const { mediaPlanStatusesLookup, mediaPlanSitesLookup, tacticsLookup } = getState().common
    const { data } = await getMediaPlanDetails(mediaPlanId)
    const formattedData = {
      mediaPlanId,
      planName: data.planName,
      planStatus: mapValuesToObjects(mediaPlanStatusesLookup.results, data.luMediaPlanStatusId),
      sites: data.sites
        ? mapValuesToObjects(
          mediaPlanSitesLookup.results,
          JSON.parse(data.sites),
          'alternateIdentifier'
        )
        : '',
      tactics: data.tactics
        ? mapValuesToObjects(tacticsLookup.results, JSON.parse(data.tactics))
        : '',
      plannedStartDate: data.plannedStartDate === null ? '' : data.plannedStartDate,
      plannedEndDate: data.plannedEndDate === null ? '' : data.plannedEndDate,
      netPlacementBudget:
        data.netPlacementBudget === null ? '' : formatCurrency(data.netPlacementBudget),
      bbkFees: data.bbkFees === null ? '' : formatCurrency(data.bbkFees),
      vendorFees: data.vendorFees === null ? '' : formatCurrency(data.vendorFees),
      plannedInquiries: data.plannedInquiries === null ? '' : data.plannedInquiries,
      plannedReferrals: data.plannedReferrals === null ? '' : data.plannedReferrals,
      actualInquiriesToDate: data.actualInquiriesToDate === null ? '' : data.actualInquiriesToDate,
      actualMediaStartDate: data.actualMediaStartDate === null ? '' : data.actualMediaStartDate,
      actualMediaEndDate: data.actualMediaEndDate === null ? '' : data.actualMediaEndDate,
      dataLockDateForActuals:
        data.dataLockDateforActuals === null ? '' : data.dataLockDateforActuals,
      netPlacementSpendToDate:
        data.netPlacementSpendToDate === null ? '' : formatCurrency(data.netPlacementSpendToDate),
      totalBudget: data.totalBudget === null ? '' : formatCurrency(data.totalBudget),
      plannedFlightTimeframeDays:
        data.plannedFlightTimeframeDays === null ? '' : data.plannedFlightTimeframeDays,
      daysLive: data.daysLive === null ? '' : data.daysLive,
      bbkFeesIncurredToDate:
        data.bbkFeesIncurredToDate === null ? '' : formatCurrency(data.bbkFeesIncurredToDate),
      vendorFeesIncurredToDate:
        data.vendorFeesIncurredToDate === null ? '' : formatCurrency(data.vendorFeesIncurredToDate),
      totalSpendtoDate: data.totalSpendtoDate === null ? '' : formatCurrency(data.totalSpendtoDate),
      plannedInquirytoReferral:
        data.plannedInquirytoReferral === null ? '' : `${data.plannedInquirytoReferral}%`,
      plannedCostPerReferral:
        data.plannedCostPerReferral === null ? '' : formatCurrency(data.plannedCostPerReferral),
      actualReferralsToDate: data.actualReferralsToDate === null ? '' : data.actualReferralsToDate,
      actualInquiryToReferral:
        data.actualInquiryToReferral === null ? '' : `${data.actualInquiryToReferral}%`,
      actualCostPerReferral:
        data.actualCostPerReferral === null ? '' : formatCurrency(data.actualCostPerReferral)
    }
    dispatch(setMediaPlanDetails({ isLoading: false, results: formattedData }))
  } catch (e) {
    dispatch(setMediaPlanDetails({ isLoading: false }))
    dispatch(showError('There was some issue while trying to fetch Media Plan details', e))
  }
}

export const onUpdateMediaPlan = values => async dispatch => {
  try {
    dispatch(setLoading(true))
    const multiSelectDropDowns = {
      sites: { array: values.sites, propertyToExtract: 'alternateIdentifier' },
      tactics: values.tactics
    }
    const payload = {
      mediPlanId: values.mediaPlanId,
      // planName ?
      luMediaPlanStatusId: values.planStatus.id,
      ...extractArrayOfIdsForEachKey(multiSelectDropDowns),
      actualMediaEndDate: values.actualMediaEndDate,

      plannedStartDate: values.plannedStartDate,
      plannedEndDate: values.plannedEndDate,
      //  plannedFlightTimeframeDays ?
      // totalBudget ?
      netPlacementBudget: parseFloat(values.netPlacementBudget),
      bbkFees: parseFloat(values.bbkFees),
      vendorFees: parseFloat(values.vendorFees),

      actualMediaStartDate: values.actualMediaStartDate,
      dataLockDateforActuals: values.dataLockDateForActuals,
      // daysLive ?
      // totalSpendtoDate ?
      netPlacementSpendtoDate: values.netPlacementSpendToDate,
      // bbkFeesIncurredToDate ?
      // vendorFeesIncurredToDate ?

      plannedInquiries: parseFloat(values.plannedInquiries),
      plannedReferrals: parseFloat(values.plannedReferrals),
      // plannedInquirytoReferral ?
      // plannedCostPerReferral ?

      actualInquiriestoDate: parseFloat(values.actualInquiriesToDate),
      actualReferralsToDate: parseFloat(values.actualReferralsToDate),
      // actualInquiryToReferral ?
      // actualCostPerReferral ?
    }
    await postMediaPlanUpdate(payload)
    await dispatch(fetchMediaPlanDetails(values.mediaPlanId))
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(showError('There was some error while trying to update media plan', e))
  }
}

const initialState = {
  mediaPlansData: {
    results: [],
    isLoading: false
  },
  addMediaPlan: false,
  mediaPlan: {
    planName: '',
    sites: [],
    tactics: [],
    plannedStartDate: '',
    plannedEndDate: '',
    plannedInquiries: '',
    plannedReferrals: '',
    totalBudget: '',
    netPlacementBudget: '',
    bbkFees: '',
    vendorFees: ''
  },
  mediaPlanDetails: {
    isLoading: false,
    results: {
      mediaPlanId: '',
      planName: '',
      planStatus: '',
      sites: [],
      tactics: [],
      actualMediaEndDate: '',
      plannedStartDate: '',
      plannedEndDate: '',
      plannedFlightTimeframeDays: '',
      totalBudget: '',
      netPlacementBudget: '',
      bbkFees: '',
      vendorFees: '',
      actualMediaStartDate: '',
      dataLockDateForActuals: '',
      daysLive: '',
      totalSpendtoDate: '',
      netPlacementSpendToDate: '',
      bbkFeesIncurredToDate: '',
      vendorFeesIncurredToDate: '',
      plannedInquiries: '',
      plannedReferrals: '',
      plannedInquirytoReferral: '',
      plannedCostPerReferral: '',
      actualInquiriesToDate: '',
      actualReferralsToDate: '',
      actualInquiryToReferral: '',
      actualCostPerReferral: ''
    }
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setMediaPlansData, (state, { payload }) => {
      state.mediaPlansData = { ...state.mediaPlansData, ...payload }
    })
    .addCase(setAddMediaPlan, (state, { payload }) => {
      state.addMediaPlan = payload
    })
    .addCase(setMediaPlanDetails, (state, { payload }) => {
      state.mediaPlanDetails = { ...state.mediaPlanDetails, ...payload }
    })
    .addCase(resetMediaPlans, state => {
      copyObject(state, initialState)
    })
})
