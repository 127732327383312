import { useSelector } from 'react-redux'
import AccordionGroup from 'components/common/dataDisplay/AccordionGroup'
import AccountDetails from './patientView/AccountDetails'
import { palette } from 'styles/baseTheme'
import { Typography } from '@mui/material'

const ReimbursementPatientView = () => {
  const { accounts } = useSelector(store => store.reimbursement)
  const { isLoading } = useSelector(store => store.userInteractions)

  return accounts.results.length || isLoading.length
    ? (
      <AccordionGroup
        accordions={accounts.results.map(account => ({
        // Need to update Titles for all supportPersonType
          label: `${account.name}`,
          component: <AccountDetails account={account} key={account.id} />
        }))}
        gradient={true}
        color="gradientBlue"
        transitionProps={{ unmountOnExit: true }}
        defaultOpenPanel={0}
        contentStyles={{ backgroundColor: palette.gray.light }}
        wrapperStyles={{ width: '100%' }}
        controlled={true}
      />
    )
    : (
      <Typography variant="subtitle1" align="center">
      Please ask your study staff about the TCN Card Payment & Reimbursement Program.
      </Typography>
    )
}

export default ReimbursementPatientView
