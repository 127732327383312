import { Typography } from '@mui/material'
import styled from '@emotion/styled'

const ErrorDiv = styled.div`
  color: red;
`

const FallBackComponent = () => (
  <Typography variant="subtitle1">
    <ErrorDiv>Something went wrong. Please refresh the application and try again!</ErrorDiv>
  </Typography>
)

export default FallBackComponent
