import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { performLogout } from 'store/ducks/auth'

const ProtectionLayer = () => {
  const { isAuthenticated } = useSelector(store => store.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const accessToken = localStorage.getItem('accessToken')

  useEffect(() => {
    if (!accessToken) {
      dispatch(performLogout(() => navigate('/login')))
    }
  }, [])

  return isAuthenticated || accessToken ? <Outlet /> : <Navigate to="/login" replace />
}

export default ProtectionLayer
