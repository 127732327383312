import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'

import { Grid, Typography } from '@mui/material'

import { Button, CustomRadioGroup } from 'components/common'
import DisplayAddress from 'components/common/dataDisplay/DisplayAddress'
import RenderHTML from 'components/common/dataDisplay/RenderHTML'

import { validationMapper } from 'components/helper/validationEngine'
import { formElements } from 'components/helper/constants/common'

const validationSchema = () =>
  validationMapper([
    { id: 'isAddressCorrect', isResponseRequired: true, answerType: formElements.radioButtons }
  ])

const AddressVerificationPopup = () => {
  const addresses = [
    {
      addressid: 1,
      siteName: 'BBK Test - Test Site',
      state: { id: 22, displayText: 'Massachusetts' },
      country: { id: 784, displayText: 'United States' },
      addressType: { id: 2, displayText: 'Business Address' },
      addressId: 67,
      locationName: 'BBK Test - Test Site',

      address1: '117 Kendrick St',
      address2: null,
      address3: null,
      city: 'Needham',
      zipCode: '02494',
      phone: '617-630-5555',
      attentionOfContactUserName: null
    }
  ]

  const onSubmitHandler = _values => {
    // Yet to implement
  }

  return (
    <>
      <Formik
        initialValues={{ isAddressCorrect: '' }}
        enableReinitialize={true}
        validationSchema={validationSchema()}
        onSubmit={onSubmitHandler}
      >
        {() => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2">
                  A site address was recently updated. Please verify this address is correct.
                </Typography>
              </Grid>
              {addresses.map(address => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={address.addressid}>
                  <DisplayAddress
                    item={address}
                    containerStyle={{ backgroundColor: '#CCC', padding: '20px' }}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomRadioGroup
                  formik="true"
                  label={<RenderHTML htmlContent="<b>Is the address above correct?</b>" />}
                  name="isAddressCorrect"
                  id="isAddressCorrect"
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' }
                  ]}
                  fullWidth={false}
                  row={true}
                  orientation="row"
                  color="primary"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button size="medium" fullWidth={false} color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

AddressVerificationPopup.propTypes = {
  closeModal: PropTypes.func
}

export default AddressVerificationPopup
