import React from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, TextBox } from 'components/common'
import { useDispatch } from 'react-redux'
import { setPasswordUpdateSuccess } from 'store/ducks/auth'
import PasswordConstraintsCheck from 'components/common/utils/PasswordConstraintsCheck'

const StyledButton = styled(Button)`
  width: 35%;
  margin-top: 15px;
`

const StyledTitle = styled(Typography)`
  margin-bottom: 20px;
`

const validations = Yup.object().shape({
  newPassword: Yup.string()
    .matches(/([a-z])/, 'Password should have minimum of one lower case letter.')
    .matches(/([A-Z])/, 'Password should have minimum of one uppercase case letter.')
    .matches(/(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/, 'Password should have minimum of one special character.')
    .matches(/([0-9])/, 'Password should have minimum of one numeric.')
    .min(8, 'Password is too short - should be 8 characters minimum.')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
})

const SignUp = () => {
  const dispatch = useDispatch()

  return (
    <>
      <StyledTitle gutterBottom variant="h2" color="primary">
        Create New Password
      </StyledTitle>

      <Formik
        initialValues={{
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={validations}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(setPasswordUpdateSuccess(true))
          setSubmitting(false)
        }}
      >
        {({ values }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBox
                  size="small"
                  required
                  id="password-input"
                  label="Enter new password"
                  name="newPassword"
                  type="password"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <PasswordConstraintsCheck enteringNewPassword={values.newPassword} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBox
                  size="small"
                  required
                  id="confirm-password-input"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledButton color="primary" type="submit" size="large" fullWidth={true}>
                    Submit
                </StyledButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SignUp
