import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Fade,
  CircularProgress
} from '@mui/material'
import styled from '@emotion/styled'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { AccordionGroup, Button } from 'components/common'
import RenderHTML from '../dataDisplay/RenderHTML'
import { Fragment } from 'react'

const SortingLabel = styled(Button)(
  () => `
&& {
    text-decoration: unset;
    padding: 0px;
    .MuiButton-endIcon {
      margin-left: 4px;
      margin-top: 2px;
      svg {
        font-size: 22px;
      }
    }
  }
`
)
const TableHeadingCell = styled(TableCell)(({ theme, properties }) => ({
  '&&': {
    lineHeight: '1.2rem',
    padding: `${theme.spacing(1, 1)}`,
    backgroundColor: `${theme.palette.secondary.dark}`,
    color: `${theme.palette.secondary.contrastText}`,
    textAlign: 'center',
    borderRight: `1px solid ${theme.palette.gray.main}`,
    borderLeft: `1px solid ${theme.palette.gray.main}`,
    ...properties
  }
}))
const HeaderTableCell = styled(TableCell)(({ theme, properties }) => ({
  '&&': {
    lineHeight: '1.2rem',
    padding: `${theme.spacing(2, 2)}`,
    backgroundColor: `${theme.palette.secondary.dark}`,
    color: `${theme.palette.secondary.contrastText}`,
    textAlign: 'center',
    borderRight: `1px solid ${theme.palette.gray.main}`,
    borderLeft: `1px solid ${theme.palette.gray.main}`,
    button: {
      ':hover': {
        backgroundColor: 'unset'
      }
    },
    ...properties
  }
}))
const BodyTableCell = styled(TableCell)(({ theme, properties }) => ({
  '&&': {
    maxWidth: '200px',
    overflowWrap: 'breakWord',
    padding: `${theme.spacing(1, 1)}`,
    textAlign: 'center',
    borderRight: `1px solid ${theme.palette.gray.main}`,
    borderLeft: `1px solid ${theme.palette.gray.main}`,
    ...properties
  }
}))

const StyledTableRow = styled(TableRow)(
  ({ theme, border }) => `
  ${
  border === 'columnRight'
    ? ` 
       .MuiTableCell-root:not(:last-child) {
         border-right: 1px solid ${theme.palette.gray.dark};
       }
       .MuiTableCell-root {
         border-bottom: unset;
       }
       `
    : ''
},
   ${
  border === 'rowBottom'
    ? ` .MuiTableCell-root {border-bottom: 1px solid ${theme.palette.gray.dark};}`
    : ''
},
   ${
  border === 'allSides'
    ? ` 
       .MuiTableCell-root:not(:last-child) {
         border-right: 1px solid ${theme.palette.gray.dark};
       }
       .MuiTableCell-root {
         border-bottom: 1px solid ${theme.palette.gray.dark};
       }
     `
    : ''
},
   .MuiTableCell-root {
     font-weight: normal
   }
  `
)

const StyledTable = styled(Table)(({ alternate, theme, styles }) => ({
  borderCollapse: 'inherit',
  '.MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: alternate === 'true' && theme.palette.gray.light
  },
  '.MuiTableRow-root:nth-of-type(even)': {
    backgroundColor: theme.palette.white.main
  },
  ...styles
}))

const EmptyState = styled('div')({
  textAlign: 'center',
  padding: '20px',
  backgroundColor: 'white;'
})

const TableWrapper = styled.div(({ theme, radius }) => ({
  height: '100%',
  width: '100%',
  borderRadius: `${radius === 'true' ? theme.shape.containerRadius : 'none'}`
}))

const Wrapper = styled.div(() => ({
  overflowX: 'auto',
  maxHeight: '65vh',
  width: '100%'
}))
const LoadingTableCell = styled(TableCell)(({ theme }) => ({
  '&&': {
    lineHeight: '20vh',
    padding: `${theme.spacing(1, 1)}`,
    color: `${theme.palette.secondary.contrastText}`,
    textAlign: 'center'
  }
}))

const SubTableCell = styled(TableCell)(() => ({
  '&&': {
    padding: '0px'
  }
}))

const TableBodyContent = ({ border, headers, data }) => (
  <StyledTableRow border={border} tabIndex={-1} data-testid={'row'}>
    {headers.map((headerItem, index) => (
      <BodyTableCell align="center" key={index} properties={headerItem.bodyProperties}>
        {headerItem.bodyProperties?.cellType === 'node'
          ? (
            data[headerItem.id]
          )
          : headerItem.bodyProperties?.cellType === 'html'
            ? (
              <RenderHTML htmlContent={data[headerItem.id]} />
            )
            : (
              <Typography variant="body1">{data[headerItem.id]}</Typography>
            )}
      </BodyTableCell>
    ))}
  </StyledTableRow>
)

TableBodyContent.propTypes = {
  /**
   * Decide borders for the data grid.
   * @default none
   */
  border: PropTypes.oneOf(['allSides', 'rowBottom', 'columnRight', 'none']),
  /**
   * Header elements
   */
  headers: PropTypes.array,
  /**
   * Table data
   */
  data: PropTypes.object
}

const DataGrid = ({
  headers,
  tableData,
  setRequestedSort,
  order,
  orderBy,
  size,
  noRowsFoundMessage,
  border,
  alternateRowColored,
  radius,
  gridTitle,
  gridTitleProperties,
  gridFooter,
  gridFooterProperties,
  styles,
  isLoading
}) => {
  const currentPageRowCount = tableData.length
  return (
    <Wrapper>
      <TableWrapper radius={radius}>
        <StyledTable
          stickyHeader={currentPageRowCount !== 0 && true}
          padding="normal"
          size={size}
          alternate={`${alternateRowColored}`}
          styles={styles}
        >
          <TableHead>
            {gridTitle && (
              <StyledTableRow border={border}>
                <TableHeadingCell
                  align="center"
                  colSpan={headers.length}
                  properties={gridTitleProperties}
                >
                  {gridTitle}
                </TableHeadingCell>
              </StyledTableRow>
            )}
            <StyledTableRow border={border}>
              {headers.map(headCell => {
                const sortedColumn = orderBy === headCell.id
                return headCell.label
                  ? (
                    <HeaderTableCell
                      key={headCell.id}
                      align="center"
                      properties={headCell.headProperties}
                    >
                      {headCell.isSortable
                        ? (
                          <SortingLabel
                            color="inherit"
                            variant="text"
                            endIcon={
                              sortedColumn && order === 'DESC'
                                ? (
                                  <KeyboardArrowDown fontSize="large" />
                                )
                                : (
                                  <KeyboardArrowUp fontSize="large" />
                                )
                            }
                            onClick={() => setRequestedSort(headCell.id)}
                          >
                            {headCell.headProperties?.cellType === 'node'
                              ? (
                                headCell.label
                              )
                              : (
                                <Typography variant="body2">{headCell.label}</Typography>
                              )}
                          </SortingLabel>
                        )
                        : headCell.headProperties?.cellType === 'node'
                          ? (
                            headCell.label
                          )
                          : (
                            <Typography variant="body2">{headCell.label}</Typography>
                          )}
                    </HeaderTableCell>
                  )
                  : (
                    ''
                  )
              })}
            </StyledTableRow>
          </TableHead>
          <Fade in timeout={{ enter: 800, exit: 800 }}>
            <TableBody>
              {isLoading
                ? (
                  <TableRow>
                    <LoadingTableCell align="center" colSpan={headers.length}>
                      <CircularProgress />
                    </LoadingTableCell>
                  </TableRow>
                )
                : (
                  tableData.map((data, index) =>
                    data.subTableLabel && data.subTableData
                      ? (
                        <TableRow key={index}>
                          <SubTableCell colSpan={headers.length}>
                            <AccordionGroup
                              color="gray"
                              labelComponent={true}
                              noContentPadding={true}
                              minBottomGutter
                              summaryStyles={{
                                minHeight: '40px',
                                '.MuiAccordionSummary-content': {
                                  display: 'flex',
                                  justifyContent: 'start',
                                  alignItems: 'center',
                                  margin: '5px'
                                }
                              }}
                              accordions={[
                                {
                                  label: (
                                    <div>
                                      <Typography variant="body2">{data.subTableLabel}</Typography>
                                    </div>
                                  ),
                                  component: (
                                    <StyledTable
                                      stickyHeader={currentPageRowCount !== 0 && true}
                                      padding="normal"
                                      size={size}
                                      alternate={`${alternateRowColored}`}
                                      styles={styles}
                                    >
                                      <TableBody>
                                        {data.subTableData.map((subTableRow, subTableRowIndex) => (
                                          <Fragment key={subTableRowIndex}>
                                            <TableBodyContent
                                              headers={headers}
                                              data={subTableRow}
                                              border={border}
                                            />
                                          </Fragment>
                                        ))}
                                      </TableBody>
                                    </StyledTable>
                                  )
                                }
                              ]}
                            />
                          </SubTableCell>
                        </TableRow>
                      )
                      : (
                        <Fragment key={index}>
                          <TableBodyContent headers={headers} data={data} border={border} />
                        </Fragment>
                      )
                  )
                )}
              {!isLoading && gridFooter && (
                <StyledTableRow border={border}>
                  <TableHeadingCell
                    align="center"
                    colSpan={headers.length}
                    properties={gridFooterProperties}
                  >
                    {gridFooter}
                  </TableHeadingCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Fade>
        </StyledTable>
        {!isLoading && currentPageRowCount === 0 && (
          <EmptyState className="noResultsFoundWrapper">{noRowsFoundMessage}</EmptyState>
        )}
      </TableWrapper>
    </Wrapper>
  )
}

DataGrid.propTypes = {
  /**
   * Header elements
   */
  headers: PropTypes.array,
  /**
   * Table data
   */
  tableData: PropTypes.array,

  /**
   * Sorting functionality
   */
  setRequestedSort: PropTypes.func,

  /**
   * sorting order
   */
  order: PropTypes.string,
  /**
   * Ascending or descending
   */
  orderBy: PropTypes.string,

  /**
   * Table empty message
   */
  noRowsFoundMessage: PropTypes.string,
  /**
   * Decide borders for the data grid.
   * @default none
   */
  border: PropTypes.oneOf(['allSides', 'rowBottom', 'columnRight', 'none']),
  /**
   * When true, alternate rows will have gray background
   * @default false
   */
  alternateRowColored: PropTypes.bool,
  /**
   * When 'medium', the size of each cell will inherit from table
   * @default 'medium'
   */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * When 'true', the table will contain a radius
   * @default `false`
   */
  radius: PropTypes.oneOf(['true', 'false']),
  /**
   * Title to be shown at the top of the Table in a Header Row.
   */
  gridTitle: PropTypes.node,
  /**
   * Styles for Grid Title
   */
  gridTitleProperties: PropTypes.object,
  /**
   * Styles applied to grid Footer
   */
  gridFooterProperties: PropTypes.object,
  /**
   * Content of Grid footer
   */
  gridFooter: PropTypes.node,
  /**
   * styles applied to grid
   */
  styles: PropTypes.object,
  /**
   * @default false
   * when true, shows loading spinner in the body section of the table
   */
  isLoading: PropTypes.bool
}

DataGrid.defaultProps = {
  border: 'none',
  alternateRowColored: false,
  size: 'medium',
  noRowsFoundMessage: 'No results found, please try updating the filters.',
  radius: 'false',
  gridTitle: undefined,
  isLoading: false
}

export default DataGrid
