import * as Yup from 'yup'
import { convertDraftToHTML } from './utility'
import { format, parse } from 'date-fns'

export const validationSetter = componentDetails => {
  const radio = () => {
    if (componentDetails.dependency?.callBack) {
      return Yup.string().when(componentDetails.dependency.elements, {
        is: componentDetails.dependency.callBack,
        then: Yup.string().required('Required')
      })
    } else {
      return Yup.string().required('Required')
    }
  }
  const dropdown = () => {
    if (componentDetails.dependency) {
      if (componentDetails.dependency.type === 'test') {
        return Yup.object()
          .nullable({ isNullable: true })
          .test(
            componentDetails.dependency.message,
            componentDetails.dependency.message,
            componentDetails.dependency.condition
          )
          .required('Required')
      } else if (componentDetails.dependency.callBack) {
        return Yup.object()
          .nullable({ isNullable: true })
          .when(componentDetails.dependency.elements, {
            is: componentDetails.dependency.callBack,
            then: Yup.object().required('Required').nullable()
          })
      }
    } else {
      return Yup.object().nullable({ isNullable: true }).required('Required')
    }
  }
  const multiSelectDropdown = () => {
    if (componentDetails.dependency) {
      return Yup.array()
        .when(componentDetails.dependency.elements, {
          is: componentDetails.dependency.callBack,
          then: Yup.array().min(1, 'Required').nullable({ isNullable: true })
        })
        .nullable({ isNullable: true })
    } else {
      return Yup.array().min(1, 'Required').nullable({ isNullable: true })
    }
  }

  const checkbox = () => Yup.boolean().isTrue('Required')

  const checkboxGroup = () =>
    Yup.array()
      .of(Yup.object())
      .min(1, componentDetails.errorMessage || 'Required')
      .nullable({ isNullable: true })

  const openText = () => {
    if (componentDetails.dependency) {
      if (componentDetails.dependency.matchWith) {
        return Yup.string()
          .oneOf(
            [Yup.ref(componentDetails.dependency.ref), null],
            componentDetails.dependency.message
          )
          .required('Required')
      } else if (componentDetails.dependency.callBack) {
        return Yup.string().when(componentDetails.dependency.elements, {
          is: componentDetails.dependency.callBack,
          then: Yup.string().required(componentDetails.dependency.error || 'Required')
        })
      }
    } else {
      return Yup.string().required('Required').nullable()
    }
  }
  const numberText = () => {
    if (componentDetails.dependency) {
      if (componentDetails.dependency.matchWith) {
        return Yup.number()
          .oneOf(
            [Yup.ref(componentDetails.dependency.ref), null],
            componentDetails.dependency.message
          )
          .required('Required')
      } else if (componentDetails.dependency.callBack) {
        return Yup.number().when(componentDetails.dependency.elements, {
          is: componentDetails.dependency.callBack,
          then: Yup.number().required('Required')
        })
      }
    } else if (componentDetails.condition) {
      const { callBack, errorMessage } = componentDetails.condition
      return Yup.number().test('conditionTest', errorMessage, callBack).required(errorMessage)
    } else {
      return Yup.number()
        .required(componentDetails.errorMessage || 'Required')
        .nullable()
    }
  }

  // Ensure no leading/trailing whitespace after trimming
  // Validate email format
  // Ensure the field is not empty
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/
  const emailOpenText = () =>
    Yup.string()
      .trim()
      .trim('Please enter a valid email without extra spaces.')
      .strict(true)
      .email('Please enter a valid email.')
      .matches(emailRegex, 'Please enter a valid email. Example: email@test.com')
      .required('Required')

  const richTextEditor = () =>
    Yup.object().test('is required', function (value) {
      if (convertDraftToHTML(value) === '<p></p>\n') {
        return this.createError({ message: 'Body cannot be empty.' })
      }
      return true
    })

  const attachment = () =>
    Yup.array().min(1, 'Attachment is required').nullable({ isNullable: true })

  const date = () => {
    let validation = Yup.date().nullable()

    if (componentDetails.dependency?.callBack) {
      validation = validation.when(componentDetails.dependency.elements, {
        is: componentDetails.dependency.callBack,
        then: schema => {
          let modifiedSchema = schema

          if (componentDetails.condition) {
            const { condition, error } = componentDetails
            const customError = error || 'Required'

            if (condition.min) {
              const { min, minError } = condition
              modifiedSchema = modifiedSchema
                .test('future', minError, value => {
                  if (value) {
                    const date = parse(format(value, 'MM/dd/yyyy'), 'MM/dd/yyyy', new Date())
                    return date >= min || format(value, 'MM/dd/yyyy') === format(min, 'MM/dd/yyyy')
                  }
                  return false
                })
                .typeError(customError)
                .required(customError)
            }

            if (condition.max) {
              const { max, maxError } = condition
              modifiedSchema = modifiedSchema.max(max, maxError).required(customError)
            }
          } else {
            modifiedSchema = modifiedSchema
              .typeError(componentDetails.error || 'Required')
              .required(componentDetails.error || 'Required')
          }

          return modifiedSchema
        },

        otherwise: validation
      })
    } else if (componentDetails.condition) {
      const { condition, error } = componentDetails
      const customError = error || 'Required'

      if (condition.min) {
        const { min, minError } = condition
        validation = validation
          .test('future', minError, value => {
            if (value) {
              const date = parse(format(value, 'MM/dd/yyyy'), 'MM/dd/yyyy', new Date())
              return date >= min || format(value, 'MM/dd/yyyy') === format(min, 'MM/dd/yyyy')
            }
            return false
          })
          .required(customError)
      }

      if (condition.max) {
        const { max, maxError } = condition
        validation = validation.max(max, maxError).required(customError)
      }
    } else {
      validation = validation
        .typeError(componentDetails.error || 'Required')
        .required(componentDetails.error || 'Required')
    }

    return validation
  }

  const componentsList = {
    'Dropdown Menu': dropdown,
    'Multi Select Dropdown': multiSelectDropdown,
    'Radio Buttons': radio,
    'Check Box': checkbox,
    'Check Boxes': checkboxGroup,
    'Open Text': openText,
    'Number Text': numberText,
    'Email Open Text': emailOpenText,
    'Rich Text Editor': richTextEditor,
    Attachment: attachment,
    Date: date
  }
  return (
    componentDetails.isResponseRequired &&
    componentsList[componentDetails.answerType] &&
    componentsList[componentDetails.answerType]()
  )
}

export const validationMapper = questions => {
  const validationSchema = {}
  questions.forEach(question => {
    validationSchema[`${question.id}`] = validationSetter(question)
  })
  return Yup.object().shape(validationSchema)
}

export const validationSchemaObject = questions => {
  const validationSchema = {}
  questions.forEach(question => {
    validationSchema[`${question.id}`] = validationSetter(question)
  })
  return validationSchema
}
