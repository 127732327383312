import React from 'react'
import { useSelector } from 'react-redux'
import { AccordionGroup } from 'components/common'
import styled from '@emotion/styled'
import AddFunds from './addFunds/AddFunds'
import SignUp from './signup/SignUp'
import ManageAccount from './manageAccount/ManageAccount'
import { useRestriction } from 'permissions'

const ContentWrapper = styled.div(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};
    width: 100%;
`
)

const Reimbursement = () => {
  const { patientInformationForReimbursement } = useSelector(store => store.reimbursement)

  const accordionContent = [
    {
      id: 'signUpSection',
      label: 'Sign Up',
      component: <SignUp />
    },
    {
      id: 'addFundsSection',
      label: 'Add Funds',
      component: <AddFunds />
    }
  ]

  if (!patientInformationForReimbursement.isRestrictedCountry) {
    accordionContent.push({
      id: 'manageAccountSection',
      label: 'Manage Account',
      component: <ManageAccount />
    })
  }

  const finalAccordionContent = accordionContent.filter(
    accordion => useRestriction('read', accordion.id) && accordion
  )

  return (
    <ContentWrapper>
      <AccordionGroup
        controlled
        accordions={finalAccordionContent}
        gradient={true}
        color="gradientBlue"
        transitionProps={{ unmountOnExit: true }}
        defaultOpenPanel={-1}
      />
    </ContentWrapper>
  )
}

export default Reimbursement
