import { Grid, Paper, Tooltip, Typography } from '@mui/material'
import { AutoCompleteWithLabel, Button, DatePickerWithLabel, TextBoxOuterLabel } from 'components/common'
import { formElements } from 'components/helper/constants/common'
import { validationMapper } from 'components/helper/validationEngine'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEnrollmentCodesLookup, fetchParticipationStatusLookup } from 'store/ducks/application'
import { saveParticipationProgressInfo } from 'store/ducks/patientRecord/participationProgress'
import styled from '@emotion/styled'
import { Error } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { Restriction } from 'permissions'

const SubTitle = styled(Typography)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(4)}; 
`,
)
const SubjectIDLabel = styled.div(
  ({ theme }) => `
        display: flex;  
        svg{
            font-size: 20px;
        }
        p {
          margin-right: ${theme.spacing(1 / 2)};
        }`,
)

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
        button {
          margin-top: ${theme.spacing(1)};
          margin-right: ${theme.spacing(2)};
          height: fit-content;
        }
        display: flex;
        align-items: start;
        justify-content: flex-start;
    `,
)

const validationSchema = isSubjectIDOpenText => validationMapper([
  { id: 'systemIdCreatedDate', isResponseRequired: true, answerType: formElements.openText },
  { id: 'preconsentStatus', isResponseRequired: true, answerType: formElements.dropdownMenu },
  {
    id: 'subject',
    isResponseRequired: true,
    answerType: isSubjectIDOpenText ? formElements.openText : formElements.dropdownMenu,
  },
])

const SubjectIdUpdateModal = () => {
  const dispatch = useDispatch()
  const { participationProgressDetails } = useSelector(store => store.participationProgress)
  const { enrollmentCodesLookup, participationStatusLookup } = useSelector(store => store.application)
  const urlParams = useParams()

  // Subject ID should be Open Text when participationProgressDetails.isIVRIntegration is FALSE
  const subjectIDIsOpenText = !participationProgressDetails.isIVRIntegration
  useEffect(() => {
    dispatch(fetchParticipationStatusLookup())
  }, [])

  useEffect(() => {
    // fetch lookup for subject ID only when user selects a site
    if (!subjectIDIsOpenText && participationProgressDetails.site?.alternateIdentifier) {
      dispatch(fetchEnrollmentCodesLookup(participationProgressDetails.site.alternateIdentifier))
    }
  }, [participationProgressDetails.site?.id])
  return (
    <Paper elevation={0}>
      <SubTitle variant="body1" gutterBottom>
        To make it easier for the Concierge to service you and your patients, please select the patient’s Subject ID.
        <br />
        <br />
        <i>Not seeing your patients Subject ID? Close the pop-up and try again later</i>
      </SubTitle>
      <Formik
        initialValues={
          subjectIDIsOpenText
            ? { ...participationProgressDetails, subject: participationProgressDetails.subject?.displayText || '' }
            : participationProgressDetails
        }
        enableReinitialize={false}
        validationSchema={validationSchema(subjectIDIsOpenText)}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(saveParticipationProgressInfo(values, urlParams.patientId, false))
          setSubmitting(false)
        }}
      >
        {() => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Restriction ability="read" feature="participationProgressSubjectId">
                <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
                  {subjectIDIsOpenText
                    ? (
                      <TextBoxOuterLabel
                        size="small"
                        id="subject-id"
                        name="subject"
                        label={
                          <SubjectIDLabel>
                            <Typography variant="body1">Subject ID:</Typography>
                            <Tooltip
                              color="error"
                              size="small"
                              title={
                                <Typography variant="body1">
                                  Not seeing your patient&apos;s Subject ID? Try again later.
                                </Typography>
                              }
                            >
                              <Error />
                            </Tooltip>
                          </SubjectIDLabel>
                        }
                        fullWidth={true}
                      />
                    )
                    : (
                      <AutoCompleteWithLabel
                        multiple={false}
                        hasSelectAllOption={false}
                        size="small"
                        id="subject-id"
                        name="subject"
                        label={
                          <SubjectIDLabel>
                            <Typography variant="body2">Subject ID:</Typography>
                            <Tooltip
                              color="error"
                              size="small"
                              title={
                                <Typography variant="body1">
                                  Not seeing your patient&apos;s Subject ID? Try again later.
                                </Typography>
                              }
                            >
                              <Error />
                            </Tooltip>
                          </SubjectIDLabel>
                        }
                        options={enrollmentCodesLookup.results}
                        isLoading={enrollmentCodesLookup.isLoading}
                      />
                    )}
                </Grid>
              </Restriction>
              <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
                <DatePickerWithLabel
                  id="system-ID-create-date"
                  label="Record Created Date:"
                  name="systemIdCreatedDate"
                  minDate={new Date()}
                  size="small"
                  disabled={true}
                />
              </Grid>
              <Restriction ability="read" feature="participationProgressPreconsentStatusDD">
                <Grid item xs={12} sm={12} md={4.5} lg={4.5}>
                  <AutoCompleteWithLabel
                    id="pre-consent-through-consent"
                    label="Participation Progress:"
                    name="preconsentStatus"
                    multiple={false}
                    hasSelectAllOption={false}
                    size="small"
                    disabled={true}
                    options={participationStatusLookup.results}
                    isLoading={participationStatusLookup.isLoading}
                  />
                </Grid>
              </Restriction>

              <ActionWrapper item xs={12} sm={12} md={4} lg={4}>
                <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                </Button>
              </ActionWrapper>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default SubjectIdUpdateModal
