import { backendAxios, getConfig } from './backend'

export const policyPermissions = () =>
  backendAxios
    .get('/permission/policypermission', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))

export const getTcnUsers = siteClientId =>
  backendAxios
    .get('/user/tcnusers', getConfig(siteClientId && { siteClientId }))
    .then(response => response.data)
    .catch(err => Promise.reject(err))

export const getUserListFilterLoadData = () =>
  backendAxios
    .get('/user/getuserslistfilterloaddata', getConfig())
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
