import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { AutoCompleteWithLabel, Button, PropertyValue, TextBoxOuterLabel } from 'components/common'
import { formElements } from 'components/helper/constants/common'
import { validationMapper } from 'components/helper/validationEngine'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchSitesByCountries,
  onUpdateVisitDetailsAssociatedSites
} from 'store/ducks/configuration/visitDetailsConfiguration'
import { fetchCountriesLookup } from 'store/ducks/common'
import styled from '@emotion/styled'

const ActionGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`

const validationSchema = validationMapper([
  { id: 'displayName', isResponseRequired: true, answerType: formElements.openText },
  { id: 'filterByCountry', isResponseRequired: true, answerType: formElements.multiSelectDropdown },
  { id: 'siteAssociation', isResponseRequired: true, answerType: formElements.multiSelectDropdown }
])

const EditAssociatedSites = ({ editAssociatedSites, onSuccess }) => {
  const dispatch = useDispatch()
  const { countriesLookup } = useSelector(store => store.common)
  const { sitesLookupByCountry } = useSelector(store => store.visitDetailsConfiguration)

  const onFilterByCountriesChange = (selectedValues, reason, setFieldValue) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      // Dynamic dropdown options for Sites based on country selection
      if (selectedValues.find(option => option.id === 'select-all')) {
        setFieldValue('filterByCountry', countriesLookup.results)
        dispatch(fetchSitesByCountries(countriesLookup.results))
      } else {
        setFieldValue('filterByCountry', selectedValues)
        if (selectedValues.length > 0) {
          dispatch(fetchSitesByCountries(selectedValues))
        }
        if (reason === 'removeOption') {
          // Empty the selected values if any
          setFieldValue('siteAssociation', [])
        }
      }
    } else if (reason === 'clear') {
      setFieldValue('siteAssociation', [])
      setFieldValue('filterByCountry', [])
    }
  }
  return (
    <Formik
      initialValues={editAssociatedSites}
      enableReinitialize={false}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          onUpdateVisitDetailsAssociatedSites(
            {
              ...values,
              uploadType: editAssociatedSites.uploadType,
              groupId: editAssociatedSites.groupId
            },
            onSuccess
          )
        )
        setSubmitting(false)
      }}
    >
      {({ setFieldValue, dirty }) => (
        <Form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PropertyValue
                label="File Name: "
                labelProps={{ variant: 'body2' }}
                value={editAssociatedSites.fileName}
                valueProps={{ variant: 'body1' }}
                fullWidth={true}
                align="row"
                gutterBottom={3}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextBoxOuterLabel
                id="displayName"
                label="Display Name"
                name="displayName"
                fullWidth={true}
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="small"
                id="filterByCountry"
                name="filterByCountry"
                label="Select Country / Countries"
                options={countriesLookup.results}
                isLoading={countriesLookup.isLoading}
                fetchOptions={() => dispatch(fetchCountriesLookup())}
                onChange={(event, selectedValues, reason) => {
                  onFilterByCountriesChange(selectedValues, reason, setFieldValue)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AutoCompleteWithLabel
                multiple={true}
                hasSelectAllOption={true}
                size="small"
                id="siteAssociation"
                name="siteAssociation"
                label="Site Association"
                options={sitesLookupByCountry.results}
                isLoading={sitesLookupByCountry.isLoading}
                matchWith="displayText"
              />
            </Grid>
            <ActionGrid item xs={12} sm={12} md={12} lg={12}>
              <Button
                disabled={!dirty}
                color="primary"
                type="submit"
                size="medium"
                fullWidth={false}
              >
                Save
              </Button>
            </ActionGrid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

EditAssociatedSites.propTypes = {
  editAssociatedSites: PropTypes.object,
  onSuccess: PropTypes.func
}

export default EditAssociatedSites
