import React from 'react'
import { Formik, Form } from 'formik'
import { Grid } from '@mui/material'
import { Button, TextBoxOuterLabel } from 'components/common'
import PropTypes from 'prop-types'
import { validationMapper } from 'components/helper/validationEngine'
import { updateEmail } from 'store/ducks/myAccount'
import { useDispatch } from 'react-redux'
import { formElements } from 'components/helper/constants/common'

const validationSchema = validationMapper([
  { id: 'newEmail', isResponseRequired: true, answerType: formElements.emailOpenText },
  {
    id: 'confirmEmail',
    isResponseRequired: true,
    answerType: formElements.openText,
    dependency: {
      matchWith: true,
      ref: 'newEmail',
      message: 'Emails must match.'
    }
  },
  { id: 'reasonForChange', isResponseRequired: false, answerType: formElements.openText },
])

const UpdateEmail = ({ currentEmail }) => {
  const dispatch = useDispatch()
  return <Formik
    initialValues={{ currentEmail, newEmail: '', confirmEmail: '', reasonForChange: '' }}
    validationSchema={validationSchema}
    onSubmit={values => {
      dispatch(updateEmail(values))
    }}
    enableReinitialize={true}>
    {() =>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextBoxOuterLabel
              size="small"
              id="currentEmail"
              label="Current Email"
              name="currentEmail"
              type="text"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextBoxOuterLabel
              size="small"
              id="newEmail"
              label="New Email Address"
              name="newEmail"
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextBoxOuterLabel
              size="small"
              id="confirmEmail"
              label="Confirm New Email Address"
              name="confirmEmail"
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextBoxOuterLabel
              size="small"
              id="reasonForChange"
              label="Reason For Change"
              name="reasonForChange"
              type="text"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button type="submit" onClick={() => {}}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Form>
    }
  </Formik>
}

UpdateEmail.propTypes = {
  currentEmail: PropTypes.string
}

export default UpdateEmail
