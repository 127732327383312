import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { AutoCompleteWithLabel, CustomRadioGroup, TextBoxOuterLabel } from 'components/common'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'

const AutoCompleteWrapper = styled(Grid)(() => ({
  '&&': {
    '.MuiFormLabel-root': {
      position: 'unset'
    }
  }
}))

const InterpreterSupport = () => {
  const { languagesLookup } = useSelector(store => store.common)
  const { visitDetails } = useSelector(store => store.requestDetails)
  const { requestId } = useParams()

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item sb={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <CustomRadioGroup
          id="interpreterWhenNeeded"
          data-testid="interpreterWhenNeeded"
          label="How is the interpreter needed to assist this patient with this visit?"
          name="interpreterWhenNeeded"
          options={[
            { label: 'Interpreter available by phone', value: 'interpreteravailablebyphone' },
            { label: 'Interpreter available in person', value: 'interpreteravailableinperson' }
          ]}
          size="medium"
          disabled={readOnly}
        />
      </Grid>
      <Grid item sb={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextBoxOuterLabel
          id="details"
          data-testid="details"
          label={
            <Typography variant="caption">
              <i>
                If an interpreter is required for more than a study visit, please provide details
                below:
              </i>
            </Typography>
          }
          name="details"
          multiline={true}
          rows={3}
          disabled={readOnly}
        />
      </Grid>
      <Grid item sb={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <AutoCompleteWithLabel
          id="patientCaregiverPreferredLanguage"
          data-testid="patientCaregiverPreferredLanguage"
          disableTypographyForLabel={true}
          label={
            <>
              <Typography variant="body2" gutterBottom>
                What languages do you need the interpreter to speak?
              </Typography>
              <Typography variant="body2">Language of Patient / Caregiver</Typography>
            </>
          }
          name="patientCaregiverPreferredLanguage"
          options={languagesLookup.results}
          disabled={readOnly}
          resetOnUnMount={false}
          multiple={false}
          hasSelectAllOption={false}
          size="small"
        />
      </Grid>
      <AutoCompleteWrapper item sb={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <AutoCompleteWithLabel
          id="patientCaregiverSecondaryLanguage"
          data-testid="patientCaregiverSecondaryLanguage"
          label="Language of Staff Member"
          name="patientCaregiverSecondaryLanguage"
          options={languagesLookup.results}
          disabled={readOnly}
          resetOnUnMount={false}
        />
      </AutoCompleteWrapper>
    </Grid>
  )
}

export default InterpreterSupport
