import { FormGroup, FormLabel, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Children, cloneElement } from 'react'

const StyledFormLabel = styled(FormLabel)`
  display: flex;
  width: 100%;
`

const StyledFormControlLabel = styled(FormControlLabel)(({ theme, row }) => ({
  '&&': {
    alignItems: 'flex-start',
    margin: theme.spacing(2),
    width: row === 'true' ? 'fit-content' : '-webkit-fill-available',
    minWidth: row === 'true' ? 'fit-content' : '-moz-available',
    '.MuiTypography-root': {
      padding: '9px',
      marginTop: '1px'
    }
  }
}))

const StyledFormGroup = styled(FormGroup)(() => ({
  '&&': {
    justifyContent: 'flex-start'
  }
}))

const StyledCheckbox = styled(Checkbox)`
  align-self: flex-start;
`

const CheckboxGroup = props => {
  const {
    id,
    label,
    disableTypographyForLabel,
    formik,
    onChange,
    onPostChange,
    name,
    checkedIcon,
    color,
    disabled,
    disableRipple,
    icon,
    indeterminate,
    indeterminateIcon,
    required,
    size,
    value,
    onBlur,
    helperText,
    error,
    disableTypographyForOptionsLabel,
    fullWidth,
    options,
    optionsAlignInRow,
    matchWith
  } = props

  let field, meta, setValue

  const onFieldValueChange = e => {
    const checkboxValue = [...field.value] || []
    // find the selected option from the options list
    // as sometimes the matchWith might be passed from the parent component, so it is good to save the entire object of the option including id and displayText
    // and sometimes the displayText and control in the options list can be a React component, therefore it cannot be stored in the selected option
    const matcher = matchWith || 'id'

    const selectedOption = {
      // e.target.id - event target property will always be 'id'
      ...options.find(option => option[matcher] === e.target.id),
      displayText: e.target.name,
      control: ''
    }

    if (e.target.checked) {
      checkboxValue.push(selectedOption)
    } else {
      const updatedValue = checkboxValue.filter(item => item[matcher] !== selectedOption[matcher])
      checkboxValue.splice(0, checkboxValue.length, ...updatedValue)
    }
    setValue(checkboxValue)

    // Call onPostChange if provided
    if (onPostChange) {
      onPostChange()
    }
  }

  if (formik === 'true') {
    ;[field, meta, { setValue }] = useField(props)
    field = { ...field, onChange: onFieldValueChange }
  } else {
    field = {
      onChange,
      onBlur,
      value
    }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <FormControl
      error={isError}
      disabled={disabled}
      fullWidth={fullWidth}
      id={id}
      component="fieldset"
      variant="standard"
      name={name}
      required={required}
    >
      {label && (
        <StyledFormLabel required={required} component="legend">
          {disableTypographyForLabel ? label : <Typography variant="body2">{label}</Typography>}
        </StyledFormLabel>
      )}
      <StyledFormGroup row={optionsAlignInRow}>
        {options?.map((option, index) => {
          const isChecked = matchWith
            ? !!field.value?.find(i => i[matchWith] === option[matchWith])
            : !!field.value?.find(i => i.id === option.id)
          const formControlLabelProps = {
            id: matchWith ? option[matchWith] : option.id,
            label: option.displayText,
            disableTypography: disableTypographyForOptionsLabel,
            labelPlacement: 'end',
            checked: isChecked,
            control: (
              <StyledCheckbox
                checkedIcon={checkedIcon}
                indeterminate={indeterminate}
                indeterminateIcon={indeterminateIcon}
                checked={isChecked} // checks if this option exists in the value array
                onChange={field.onChange}
                name={option.name || option.displayText}
                id={matchWith ? option[matchWith] : `${option.id}`}
                color={color}
                disabled={disabled}
                disableRipple={disableRipple}
                icon={icon}
                size={size}
              />
            )
          }
          if (option.control) {
            const child = Children.only(option.control)
            return cloneElement(child, { key: index, ...formControlLabelProps })
          } else {
            return (
              <StyledFormControlLabel
                row={`${optionsAlignInRow}`}
                componentsProps={{ typography: { variant: 'body2' } }}
                key={index}
                {...formControlLabelProps}
              />
            )
          }
        })}
      </StyledFormGroup>
      <FormHelperText component="div" error={isError}>
        {errorText}
      </FormHelperText>
    </FormControl>
  )
}

CheckboxGroup.defaultProps = {
  formik: 'true',
  color: 'primary',
  size: 'medium',
  indeterminateIcon: <IndeterminateCheckBoxIcon />,
  checkedIcon: <CheckBoxIcon />,
  icon: <CheckBoxOutlineBlankIcon />,
  error: false,
  fullWidth: false,
  required: false,
  disableTypographyForOptionsLabel: false,
  optionsAlignInRow: false,
  disableTypographyForLabel: false
}

CheckboxGroup.propTypes = {
  /**
   * The label content. Note, this is of type node that means you must pass this as React element ex: Typography
   * The reason this is defined as node is that the label can be a combination of text and some icon.
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Use this when you want to pass your custom label.
   * when true, whatever is passed for label props, will be shown as is. Meaning, without a Typography/p-tag. Also, there will not be any indication, that this field is required.
   * Whereas in case of `false` and if this component is required, now when user tries to submit the form without selecting this checkbox, the label will turn to red.
   * @default false
   */
  disableTypographyForLabel: PropTypes.bool,
  /**
   * Use this when you want to pass your custom label for each option.
   * when true, whatever is passed for label props, will be shown as is. Meaning, without a Typography/p-tag. Also, there will not be any indication, that this field is required.
   * Whereas in case of `false` and if this component is required, now when user tries to submit the form without selecting this checkbox, the label will turn to red.
   * @default false
   */
  disableTypographyForOptionsLabel: PropTypes.bool,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * If `true`, the component will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * Implement onBlur using this props.
   */
  onBlur: PropTypes.func,

  /**
   * The icon to display when the component is checked.
   * @default <CheckBoxIcon />
   */
  checkedIcon: PropTypes.node,

  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning',
    'default'
  ]),

  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the ripple effect is disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * The icon to display when the component is unchecked.
   * @default <CheckBoxOutlineBlankIcon />
   */
  icon: PropTypes.node,
  /**
   * The id of the `input` element.
   */
  id: PropTypes.string,
  /**
   * If `true`, the component appears indeterminate.
   * This does not set the native input element to indeterminate due
   * to inconsistent behavior across browsers.
   * However, we set a `data-indeterminate` attribute on the `input`.
   * @default false
   */
  indeterminate: PropTypes.bool,
  /**
   * The icon to display when the component is indeterminate.
   * @default <IndeterminateCheckBoxIcon />
   */
  indeterminateIcon: PropTypes.node,
  /**
   * onChange is an optional props, can be used to perform other tasks beside just value change of this particular element
   * Callback fired when the state is changed.
   * @param {React.ChangeEvent<HTMLInputElement>} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange: PropTypes.func,
  /**
   * Optional prop. If provided, call post onChange
   */
  onPostChange: PropTypes.func,
  /**
   * If `true`, the `component` is required.
   */
  required: PropTypes.bool,
  /**
   * The size of the component.
   * `small` is equivalent to the dense checkbox styling.
   * @default 'medium'
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * The value of the component. The DOM API casts this to a string.
   * The browser uses "on" as the default value.
   */
  value: PropTypes.array,
  /**
   * Options must be in array of objects format with each field as id and display text.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    })
  ).isRequired,
  /**
   * @default false
   * When true options are aligned in row else column.
   */
  optionsAlignInRow: PropTypes.bool,
  /**
   * when passed will use it to show checked values
   */
  matchWith: PropTypes.string
}
export default CheckboxGroup
