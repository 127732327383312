import React from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import WarningIcon from '@mui/icons-material/Warning'
import styled from '@emotion/styled'
import RenderHTML from './dataDisplay/RenderHTML'

const colorMapper = type => {
  const value = {
    success: '#43a047',
    error: '#d32f2f',
    info: '#1976d2',
    warning: '#ffa000',
    default: 'black'
  }
  return value[type]
}
const contrastColorMapper = type => {
  const value = {
    success: '#f9f9f9',
    error: '#f9f9f9',
    info: '#f9f9f9',
    warning: '#000000',
    default: '#f9f9f9'
  }
  return value[type]
}

const Message = styled.span`
  display: flex;
  align-items: center;
`

const UserMessageBarContent = styled(SnackbarContent)`
  background-color: ${props => colorMapper(props.messagetype)} !important;
  color: ${props => contrastColorMapper(props.messagetype)} !important;
  border-radius: 15px;
  .MuiSnackbarContent-message {
    max-width: 87%;

    @media (max-width: 448px) {
      max-width: 80%;
    }
  }
`

const SuccessIcon = styled(CheckCircleIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const CustomErrorIcon = styled(ErrorIcon)`
  opacity: 0.9;
  margin-right: 10px;
`

const CustomInfoIcon = styled(InfoIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const CustomWarningIcon = styled(WarningIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const DefaultIcon = styled(CheckCircleIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const UserMessageBar = ({
  verticalPosition,
  horizontalPosition,
  message,
  messageIsANode,
  autoHideDuration,
  isOpen,
  onClose,
  messageType,
  ...other
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: verticalPosition,
      horizontal: horizontalPosition
    }}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    open={isOpen}
  >
    <UserMessageBarContent
      aria-describedby="client-snackbar"
      messagetype={messageType}
      message={
        <Message id="client-snackbar">
          {messageType === 'success'
            ? (
              <SuccessIcon />
            )
            : messageType === 'error'
              ? (
                <CustomErrorIcon />
              )
              : messageType === 'info'
                ? (
                  <CustomInfoIcon />
                )
                : messageType === 'warning'
                  ? (
                    <CustomWarningIcon />
                  )
                  : (
                    <DefaultIcon />
                  )}
          {messageIsANode ? <RenderHTML htmlContent={message} /> : message}
        </Message>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ]}
      {...other}
    />
  </Snackbar>
)

UserMessageBar.propTypes = {
  /**
   * content of the component
   */
  message: PropTypes.node,
  /**
   * function that will trigger on close
   */
  onClose: PropTypes.func,
  /**
   * Message type can be any thing - success, error, warning and info
   */
  messageType: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  /**
   * vertical position of the component
   */
  verticalPosition: PropTypes.string,
  /**
   * Horizontal position of the component
   */
  horizontalPosition: PropTypes.string,
  /**
   * duration of the component to be shown
   */
  autoHideDuration: PropTypes.number,
  /**
   * when true shows the Message component
   */
  isOpen: PropTypes.bool,
  /**
   * @default false
   * when true, wraps the message in renderHtml component
   */
  messageIsANode: PropTypes.bool
}

UserMessageBar.defaultProps = {
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  message: '',
  autoHideDuration: 5000,
  messageType: 'success',
  messageIsANode: false
}

export default UserMessageBar
