import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import StatesDropdown from 'components/common/form/StatesDropdown'
import { Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  onAddressConfirmation,
  setIsAddressVerificationRequired,
  startAddressConfirmation
} from 'store/ducks/patientExperienceManager'
import {
  AddressCard,
  AutoCompleteWithLabel,
  Button,
  CustomRadioGroup,
  TextBoxOuterLabel
} from 'components/common'
import { addressTitles, addressTypes } from 'components/helper/constants/patientExperienceManager'

const FormWrapper = styled(Form)(
  ({ theme }) => `
    margin: ${theme.spacing(0, 1)};
`
)

const AddressGrid = styled(Grid)(({ theme }) => ({
  '&&': ({
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  })
}))

const ActionWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(4)};
    display: flex;
    justify-content: space-between;
    button: {
      margin-right: ${theme.spacing(2)};
    }
`
)

const requiredAddress = Yup.object().shape({
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  address3: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.object().nullable({ isNullable: true }).required('Required'),
  country: Yup.object().nullable({ isNullable: true }).required('Required'),
  zipCode: Yup.string().required('Required'),
  phone: Yup.string().required('Required')
})

const validations = showShippingAddressBlock =>
  Yup.object({
    isVisitAddress: Yup.string(),
    isPackageAddress: Yup.string(),
    attentionOf: showShippingAddressBlock
      ? Yup.object().nullable({ isNullable: true }).required('Required')
      : Yup.object().nullable({ isNullable: true }),
    updatedVisitAddress: Yup.object().nullable({ isNullable: true }).when('isVisitAddress', {
      is: 'no',
      then: requiredAddress
    }),
    updatedPackageAddress: Yup.object().nullable({ isNullable: true }).when('isPackageAddress', {
      is: 'no',
      then: requiredAddress
    })
  })

const SiteAddressConfirmation = () => {
  const dispatch = useDispatch()
  const {
    updatedVisitAddress,
    updatedPackageAddress,
    isVisitAddress,
    isPackageAddress,
    attentionOf
  } = useSelector(store => store.patientExperienceManager)
  const { countriesLookup } = useSelector(store => store.common)
  const { tcnUsersLookup } = useSelector(store => store.application)

  useEffect(() => {
    if (!attentionOf) {
      /* ---Using this Flag, to check if the user is coming back to this step-1 (site address confirmation)
      from step-2 (site travel preferences)---- */
      dispatch(startAddressConfirmation())
    }
  }, [dispatch])

  const showShippingAddressBlock =
    updatedPackageAddress.address1 &&
    ((updatedPackageAddress.addressType.id === addressTypes.shipping &&
      !updatedPackageAddress.isAddressVerified) ||
      updatedPackageAddress.addressType.id === addressTypes.business)

  const isBusinessAddress = (address, addressType) =>
    address.address1 && addressType.id === addressTypes.business

  const visitAddress = isBusinessAddress(updatedVisitAddress, updatedVisitAddress.addressType)
    ? {
      title: addressTitles[addressTypes.business],
      address: updatedVisitAddress
    }
    : updatedVisitAddress.address1
      ? {
        title: addressTitles[addressTypes.patientCare],
        address: updatedVisitAddress
      }
      : null

  return (
    <Formik
      initialValues={{
        isVisitAddress,
        isPackageAddress,
        attentionOf,
        updatedVisitAddress,
        updatedPackageAddress
      }}
      enableReinitialize={true}
      validationSchema={validations(showShippingAddressBlock)}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(onAddressConfirmation(values))
        setSubmitting(false)
      }}
    >
      {({ values }) => (
        <FormWrapper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="subtitle1">
                Please confirm your site&#39;s main address and if this same address can be used for
                other means related to our programs and services.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {visitAddress && (
              <AddressGrid item xs={12} sm={12} md={12} lg={12}>
                <AddressCard title={visitAddress.title} address={visitAddress.address} />
              </AddressGrid>
            )}

            {updatedVisitAddress.address1 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomRadioGroup
                  label="Is this the best address and phone number for patients to use for their visits?"
                  name="isVisitAddress"
                  id="visit-address"
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' }
                  ]}
                  fullWidth={false}
                  row={true}
                  orientation="row"
                  color="primary"
                />
              </Grid>
            )}

            {values.isVisitAddress === 'no' && (
              <>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    id="visit-address1"
                    label="Address Line 1"
                    name="updatedVisitAddress.address1"
                    fullWidth={true}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7}></Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="visit-address2"
                    label="Address Line 2"
                    name="updatedVisitAddress.address2"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="visit-address3"
                    label="Address Line 3"
                    name="updatedVisitAddress.address3"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}></Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <AutoCompleteWithLabel
                    multiple={false}
                    size="small"
                    hasSelectAllOption={false}
                    id="visit-country"
                    label="Country"
                    name="updatedVisitAddress.country"
                    options={countriesLookup.results}
                    isLoading={countriesLookup.isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <StatesDropdown
                    multiple={false}
                    size="small"
                    hasSelectAllOption={false}
                    id="visit-state"
                    label="County / State"
                    name="updatedVisitAddress.state"
                    selectedCountry={values.updatedVisitAddress.country?.id}
                    outerLabel={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="visit-city"
                    label="City"
                    name="updatedVisitAddress.city"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="visit-zip"
                    label="Zip / Postal Code"
                    name="updatedVisitAddress.zipCode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="visit-phone"
                    label="Phone Number"
                    name="updatedVisitAddress.phone"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7}></Grid>
              </>
            )}

            {updatedPackageAddress.address1 &&
              !isBusinessAddress(updatedVisitAddress, updatedVisitAddress.addressType) &&
              isBusinessAddress(updatedPackageAddress, updatedPackageAddress.addressType) && (
              <AddressGrid item xs={12} sm={12} md={12} lg={12}>
                <AddressCard
                  title={addressTitles[addressTypes.business]}
                  address={updatedPackageAddress}
                />
              </AddressGrid>
            )}

            {updatedPackageAddress.address1 &&
              !isBusinessAddress(updatedPackageAddress, updatedPackageAddress.addressType) && (
              <AddressGrid item xs={12} sm={12} md={12} lg={12}>
                <AddressCard
                  title={addressTitles[updatedPackageAddress.addressType.id]}
                  address={updatedPackageAddress}
                />
              </AddressGrid>
            )}

            {updatedPackageAddress.address1 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomRadioGroup
                  label="Is this the best address and phone number for receipt of shipped packages?"
                  name="isPackageAddress"
                  id="package-address"
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' }
                  ]}
                  fullWidth={false}
                  row={true}
                  orientation="row"
                  color="primary"
                />
              </Grid>
            )}

            {values.isPackageAddress === 'no' && (
              <>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="package-address1"
                    label="Address Line 1"
                    name="updatedPackageAddress.address1"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7}></Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="package-address2"
                    label="Address Line 2"
                    name="updatedPackageAddress.address2"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="package-address3"
                    label="Address Line 3"
                    name="updatedPackageAddress.address3"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}></Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <AutoCompleteWithLabel
                    multiple={false}
                    size="small"
                    hasSelectAllOption={false}
                    id="package-country"
                    label="Country"
                    name="updatedPackageAddress.country"
                    options={countriesLookup.results}
                    isLoading={countriesLookup.isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <StatesDropdown
                    multiple={false}
                    size="small"
                    hasSelectAllOption={false}
                    id="package-state"
                    label="County / State"
                    name="updatedPackageAddress.state"
                    selectedCountry={values.updatedPackageAddress.country?.id}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="package-city"
                    label="City"
                    name="updatedPackageAddress.city"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="package-zip"
                    label="Zip / Postal Code"
                    name="updatedPackageAddress.zipCode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextBoxOuterLabel
                    fullWidth={true}
                    size="small"
                    id="package-phone"
                    label="Phone Number"
                    name="updatedPackageAddress.phone"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7}></Grid>
              </>
            )}

            {updatedPackageAddress.address1 && (
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <AutoCompleteWithLabel
                  multiple={false}
                  size="small"
                  hasSelectAllOption={false}
                  id="attentionOf"
                  label="Attention of:"
                  name="attentionOf"
                  options={tcnUsersLookup.results}
                  isLoading={tcnUsersLookup.isLoading}
                  matchWith="alternateIdentifier"
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2}>
            <ActionWrapper item xs={4} sm={4} md={3} lg={3}>
              <Button type="submit" size="large" color="primary">
                Save
              </Button>
              <Button
                size="large"
                color="inherit"
                onClick={() => dispatch(setIsAddressVerificationRequired(false))}
              >
                Cancel
              </Button>
            </ActionWrapper>
          </Grid>
        </FormWrapper>
      )}
    </Formik>
  )
}

export default SiteAddressConfirmation
