import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useField, useFormikContext } from 'formik'
import { AutoCompleteWithLabel, AutoComplete } from 'components/common'
import { showError } from 'store/ducks/application'
import { getStateDropDownValues } from 'services/common'
import { removeDuplicateItemsFromArray } from 'components/helper/utility'

const StatesDropdown = props => {
  const { selectedCountry, countryChange, outerLabel, ...rest } = props
  const [statesLookup, setStatesLookup] = useState([])
  const dispatch = useDispatch()
  const [loadingStates, setLoadingStates] = useState(false)
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  const fetchStatesLookup = async () => {
    if (selectedCountry) {
      setLoadingStates(true)
      try {
        const { data } = await getStateDropDownValues(selectedCountry)
        // check if the options list has the already selected value,
        // if not reset the state dropdown to ''
        const optionExists = field.value && data.map(res => res.id).includes(field.value.id)

        if (optionExists) {
          setFieldValue(field.name, field.value)
        } else {
          setFieldValue(field.name, null)
        }
        const finalRes = removeDuplicateItemsFromArray(data, 'displayText')
        if (props.noStatesFoundForSelectedCountry) {
          props.noStatesFoundForSelectedCountry(data.length === 0)
        }
        setStatesLookup(finalRes)
      } catch {
        dispatch(
          showError(
            'There was issue while fetching states dropdown options. Please refresh your page or try after some time.',
          ),
        )
      }
      setLoadingStates(false)
    }
  }

  useEffect(() => {
    // Fetch new state options based on country selection
    !rest.disabled && fetchStatesLookup()
  }, [selectedCountry, rest.disabled])

  return outerLabel
    ? (
      <AutoCompleteWithLabel options={statesLookup} isLoading={loadingStates} {...rest} />
    )
    : (
      <AutoComplete options={statesLookup} {...rest} isLoading={loadingStates} />
    )
}

StatesDropdown.propTypes = {
  selectedCountry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  countryChange: PropTypes.number,
  options: PropTypes.array,
  outerLabel: PropTypes.bool,
  /**
   * Function from parent component
   * To let parent component know that No States were found for selected country
   */
  noStatesFoundForSelectedCountry: PropTypes.func,
}
StatesDropdown.defaultProps = {
  outerLabel: true,
  noStatesFoundForSelectedCountry: undefined,
}
export default StatesDropdown
