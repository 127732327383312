import { createAction, createReducer } from '@reduxjs/toolkit'
import { copyObject, createModuleObject, displayDate, displayTime } from 'components/helper/utility'
import { setLoading } from '../userInteractions'
import { getPatientInformation, setPatientViewed } from 'services/patientRecord/patientProfile'
import { setSiteClientId, showError } from '../application'
import { getPatientSectionBadges } from 'services/badging'
import {
  getVisitScheduleDetails,
  updateVisitComplete,
  getResources,
  getVisitGuidelineGroup
} from 'services/visit'

export const setPatientInformation = createAction('patientMainWrapper/setPatientInformation')
export const setUserAccountUpdatedModal = createAction(
  'patientMainWrapper/setUserAccountUpdatedModal'
)
export const resetPatientMainWrapper = createAction('patientMainWrapper/resetPatientMainWrapper')
export const setBadgesForPatientSection = createAction(
  'patientMainWrapper/setBadgesForPatientSection'
)
// Moved from Visit
const setResources = createAction('patientMainWrapper/setResource')
export const setVisitScheduleDetails = createAction('patientMainWrapper/setVisitScheduleDetails')
export const setVisitGuidelineGroup = createAction('patientMainWrapper/setVisitGuidelineGroup')

export const fetchPatientInformation = id => async dispatch => {
  dispatch(setLoading(true))
  try {
    const { data } = await getPatientInformation(id)
    const {
      zipCode,
      middleName,
      gender,
      dateOfBirth,
      emailAddress,
      phoneNumber,
      preferredLanguage,
      patientCommunicationPreferences,
      address1,
      address2,
      address3,
      city,
      country,
      state,
      siteClientId,
      isAcknowledged,
      firstViewedDate,
      lastViewedDate
    } = data
    const formattedResponse = {
      ...data,
      middleName: middleName || '',
      gender: gender && gender,
      dateOfBirth,
      emailAddress: emailAddress || '',
      phoneNumber: phoneNumber || '',
      preferredLanguage: preferredLanguage || null,
      patientCommunicationPreferences: patientCommunicationPreferences || [],
      address1: address1 || '',
      address2: address2 || '',
      address3: address3 || '',
      city: city || '',
      country: country && country,
      state: state && state,
      zipCode: zipCode || '',
      isAcknowledged: !!isAcknowledged,
      lastViewedDate: `${displayDate(lastViewedDate)} ${displayTime(lastViewedDate)}`,
      firstViewedDate: `${displayDate(firstViewedDate)} ${displayTime(firstViewedDate)}`
    }
    dispatch(setPatientInformation(formattedResponse))
    dispatch(setSiteClientId(siteClientId))
    /**
     * Patient Flow for fetching information
     * Since for patient, userId is not in Url
     * Following calls are made here, as userId is now available
     */
  } catch (err) {
    if (err.response && err.response.status === 404) {
      dispatch(
        showError('Record not found. Please click on back button to go back to the application.')
      )
    } else {
      dispatch(
        showError(
          "There was some issue while trying to fetch Patient's contact details. please try again later."
        )
      )
    }
  }
  dispatch(setLoading(false))
}

export const fetchBadgesForPatientSection = patientId => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { isPatient } = getState().auth
    const { data } = await getPatientSectionBadges(patientId)
    const finalBadges = {}
    data.forEach(badge => {
      if (isPatient && ['Announcement', 'Study Message'].includes(badge.module)) {
        finalBadges.mailBox = createModuleObject(finalBadges.mailBox, badge)
      } else {
        finalBadges[badge.module] = createModuleObject(finalBadges[badge.module], badge)
      }
    })
    dispatch(setBadgesForPatientSection(finalBadges))
  } catch (e) {
    dispatch(
      showError('There was some error while trying to fetch badges. Please refresh you page.')
    )
  }
  dispatch(setLoading(false))
}

export const fetchResources = () => async (dispatch, getState) => {
  const { siteClientId } = getState().application
  try {
    dispatch(setLoading(true))
    const resources = await getResources(siteClientId)
    dispatch(setResources(resources))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const fetchVisitGuidelineGroup = userId => async (dispatch, getState) => {
  const { patientInformation } = getState().patientMainWrapper
  dispatch(setVisitGuidelineGroup({ isLoading: true }))
  try {
    dispatch(setLoading(true))
    const visitGuidelineGroupRes = await getVisitGuidelineGroup(userId)
    dispatch(setVisitGuidelineGroup({ results: visitGuidelineGroupRes }))
    if (visitGuidelineGroupRes && visitGuidelineGroupRes.length === 1) {
      await dispatch(
        fetchVisitScheduleDetails(patientInformation.userId, visitGuidelineGroupRes[0].id)
      )
    }
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
    dispatch(setVisitGuidelineGroup({ isLoading: false }))
  }
}

export const fetchVisitScheduleDetails = (patientId, visitGuidelineGroupId) => async dispatch => {
  dispatch(setVisitScheduleDetails({ isLoading: true }))
  try {
    dispatch(setLoading(true))
    const { data } = await getVisitScheduleDetails(patientId, visitGuidelineGroupId)

    /**
     * Format the Schedule details to group by Phases
     * So it is easy to render on UI
     * {
     *    "Study Drug Visit": [
     *        { "userId" : "", "visitScheduleId": ""...},
     *        { "userId" : "", "visitScheduleId": ""...},
     *    ],
     *    "Follow-up Visits": [
     *        { "userId" : "", "visitScheduleId": ""...},
     *        { "userId" : "", "visitScheduleId": ""...},
     *        { "userId" : "", "visitScheduleId": ""...},
     *    ],
     * }
     */
    const phases = [...new Set(data.map(schedule => schedule.visitPhase))]
    const formattedScheduleDetails = {}
    phases.forEach(phase => {
      const phaseSchedules = data.filter(schedule => schedule.visitPhase === phase)
      formattedScheduleDetails[phase] = phaseSchedules
    })

    dispatch(setVisitScheduleDetails({ results: formattedScheduleDetails, isLoading: false }))
  } catch (e) {
    dispatch(setVisitScheduleDetails({ isLoading: false }))
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const setVisitComplete = (visitSchedule, isVisitComplete) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const { visitScheduleDetails } = getState().patientMainWrapper

  try {
    const visitCompleteData = {
      userId: visitSchedule.userId,
      visitScheduleId: visitSchedule.visitScheduleId,
      isVisitComplete
    }

    await updateVisitComplete(visitCompleteData)
    const updatedVisitScheduleDetails = JSON.parse(JSON.stringify(visitScheduleDetails.results))

    // Update local state with isVisitComplete, as API was successful
    // Sometime fetchingDetails does not return latest update.
    updatedVisitScheduleDetails[visitSchedule.visitPhase].forEach(schedule => {
      if (schedule.visitScheduleId === visitSchedule.visitScheduleId) {
        schedule.isVisitComplete = isVisitComplete
      }
    })

    dispatch(setVisitScheduleDetails({ results: updatedVisitScheduleDetails }))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  } finally {
    dispatch(setLoading(false))
  }
}

export const updatePatientViewed = userId => async dispatch => {
  dispatch(setLoading(true))
  try {
    await setPatientViewed(userId)
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  }
  dispatch(setLoading(false))
}

const initialState = {
  patientInformation: {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: { id: '', displayText: '' },
    dateOfBirth: '',
    emailAddress: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    country: { id: '', displayText: '' },
    state: { id: '', displayText: '' },
    preferredLanguage: null,
    patientCommunicationPreferences: [],
    isAcknowledged: false,
    isRestrictedCountry: false
  },
  userAccountUpdatedModal: false,
  badgesForPatientSection: '',
  // Dashboard Visit Details
  // visitNames: [],
  resources: [],
  visitScheduleDetails: { results: {}, isLoading: false },
  visitGuidelineGroup: { results: [], isLoading: false }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setPatientInformation, (state, action) => {
      state.patientInformation = action.payload
    })
    .addCase(setUserAccountUpdatedModal, (state, action) => {
      state.userAccountUpdatedModal = action.payload
    })
    .addCase(setBadgesForPatientSection, (state, action) => {
      state.badgesForPatientSection = action.payload
    })
    .addCase(setResources, (state, action) => {
      state.resources = action.payload
    })
    .addCase(setVisitScheduleDetails, (state, action) => {
      state.visitScheduleDetails = { ...state.visitScheduleDetails, ...action.payload }
    })
    .addCase(setVisitGuidelineGroup, (state, action) => {
      state.visitGuidelineGroup = { ...state.visitGuidelineGroup, ...action.payload }
    })
    .addCase(resetPatientMainWrapper, state => {
      copyObject(state, initialState)
    })
})
