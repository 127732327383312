import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading } from '../userInteractions'
import { showError, showSuccess } from '../application'
import { copyObject, removeTime } from 'components/helper/utility'

import { addRemoteSupportHomeHealthRequest, getHomehealthVisitDetails } from 'services/rsgArrive'
import { fetchBadgesForPatientSection } from '../patientRecord/patientMainWrapper'
import { setOpenSubmitSuccess, setStartNewRequestForVisitFlow } from './requestDetails'
import { fetchPatientAddresses } from './inPersonRequest'
import { fetchCountriesLookup } from '../common'

export const setHomeHealthVisitDetailsForm = createAction(
  'homeHealthVisit/setHomeHealthVisitDetailsForm'
)
export const resetHomeHealthVisit = createAction('homeHealthVisit/resetHomeHealthVisit')

export const setupHomeHealthVisitDetailsForm = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true))
    const { patientInformation } = getState().patientMainWrapper
    await dispatch(fetchCountriesLookup())
    await dispatch(fetchPatientAddresses(patientInformation.userId))
    const { homeHealthVisitDetailsForm } = getState().homeHealthVisit
    const { patientAddresses } = getState().inPersonRequest
    dispatch(
      setHomeHealthVisitDetailsForm({
        ...homeHealthVisitDetailsForm,
        location: patientAddresses[patientAddresses.length - 1]
      })
    )
  } catch (e) {
    dispatch(showError('Something went wrong!', e))
  }
  dispatch(setLoading(false))
}

export const fetchHomeHealthVisitDetails = requestId => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const res = await getHomehealthVisitDetails(requestId)
    const { homeHealthVisitDetailsForm } = getState().homeHealthVisit

    let formattedHomeHealthVisitDetails = { ...homeHealthVisitDetailsForm }
    formattedHomeHealthVisitDetails = {
      ...formattedHomeHealthVisitDetails,
      visitNames: res.visitName,
      location: { ...res.location, addressId: res.location.addressId ? res.location.addressId : 0 },
      visitDate: res.visitDate,
      visitTime: res.visitTIme,
      otherRelevantConsiderations: res.otherConsiderations,
      reachDateTime: res.bestTimeToReach || '',
      reachPhoneNumber: res.bestPhoneNumber || ''
    }

    dispatch(setHomeHealthVisitDetailsForm(formattedHomeHealthVisitDetails))
  } catch (e) {
    dispatch(showError('Something went wrong!'))
  }
  dispatch(setLoading(false))
}

export const submitHomeHealthVisitRequest =
  (data, parentRequestId) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { patientInformation } = getState().patientMainWrapper
      const requestData = {
        patientId: patientInformation.userId,
        visitNameId: data.visitNames?.id,
        visitDate: removeTime(data.visitDate),
        visitTime: data.visitTime,
        patientAddress: data.location,
        otherRelevantConsiderations: data.otherRelevantConsiderations,
        bestTimeToReach: data.reachDateTime,
        bestPhoneNumber: data.reachPhoneNumber,
        ...(!!parentRequestId && {
          parentRequestId
        })
      }

      await addRemoteSupportHomeHealthRequest(requestData)
      await dispatch(fetchBadgesForPatientSection(patientInformation.userId))
      dispatch(setOpenSubmitSuccess(true))
      // Save data for StartNewRequestForVisitFlow,
      // in case user selects Yes in SubmitSuccess Confirmation box
      // This will be cleared if user selects No.
      dispatch(
        setStartNewRequestForVisitFlow({
          visitDate: removeTime(data.visitDate),
          visitNames: data.visitNames
        })
      )
      dispatch(showSuccess('Request submitted successfully!'))
    } catch (e) {
      dispatch(showError('Something went wrong!', e))
    } finally {
      dispatch(setLoading(false))
    }
  }

const initialState = {
  homeHealthVisitDetailsForm: {
    visitNames: null,
    location: '',
    visitDate: '',
    visitTime: '',
    otherRelevantConsiderations: '',
    reachDateTime: '',
    reachPhoneNumber: ''
  }
}

export default createReducer(initialState, builder => {
  builder
    .addCase(setHomeHealthVisitDetailsForm, (state, action) => {
      state.homeHealthVisitDetailsForm = action.payload
    })
    .addCase(resetHomeHealthVisit, state => {
      copyObject(state, initialState)
    })
})
