import React from 'react'
import PropTypes from 'prop-types'

import VisitScheduleDetail from './VisitScheduleDetail'

const VisitPhaseDetails = ({ phaseDetails }) => (
  <>
    {phaseDetails.map(visitSchedule => (
      <VisitScheduleDetail visitSchedule={visitSchedule} key={visitSchedule.visitScheduleId} />
    ))}
  </>
)

VisitPhaseDetails.propTypes = {
  phaseDetails: PropTypes.arrayOf(PropTypes.object),
}

VisitPhaseDetails.defaultProps = {
  phaseDetails: [],
}

export default VisitPhaseDetails
