import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { PartitionCard } from 'components/common'
import { Typography } from '@mui/material'
import { Restriction } from 'permissions'

const Hello = styled.div(({ theme }) => ({
  height: '400px',
  position: 'relative',

  [theme.breakpoints.between('md', 'lg')]: {
    height: '320px',
  },
  [theme.breakpoints.down('md')]: {
    height: '240px',
  },
}))

const ShowInCenter = styled.div(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
}))

const CardWrapper = styled.div(({ theme }) => ({
  width: '100%',
  height: '',
  [theme.breakpoints.between('md', 'lg')]: {
    height: '320px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    height: '560px',
  },
}))

const VideoWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  height: '400px',
  alignItems: 'center',
  [theme.breakpoints.between('md', 'lg')]: {
    height: '320px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    height: '320px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '240px',
  },
}))

const HelloBanner = () => {
  const { protocolCampaignDetails } = useSelector(store => store.protocolCampaignConfiguration)
  return (
    <CardWrapper>
      <PartitionCard
        cardProps={{
          cardContentStyles: { padding: '0px', margin: '0px' },
          cardStyles: { marginTop: '0px' },
        }}
        firstContent={
          <Hello>
            <ShowInCenter>
              <Typography variant="h1">
                {protocolCampaignDetails.results.campaignName}
              </Typography>
            </ShowInCenter>
          </Hello>
        }

        firstContentPadding={0}
        firstContentWidth="45%"
        secondContent={
          <VideoWrapper>
            <Restriction ability="read" feature='dashboardVideo'>
              <video
                controls
                autoPlay={false}
                loop={false}
                preload="none"
                poster={`${process.env.PUBLIC_URL}video/TCN-Engage-Welcome-Thumbnail.png`}
                width="100%"
              >
                <source src={`${process.env.PUBLIC_URL}video/TCN-Engage-Weclome2.mov`} type="video/webm" />
              video.
              </video>
            </Restriction>

          </VideoWrapper>
        }
        secondContentWidth="55%"
        secondContentPadding={0}
      />
    </CardWrapper>
  )
}

HelloBanner.propTypes = {}

export default HelloBanner
