import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useField } from 'formik'
import {
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  FormLabel,
  FormControl,
  Typography
} from '@mui/material'

const LabelOptionWrapper = styled.div`
  ${({ orientation, theme }) =>
    orientation === 'row'
      ? `
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .MuiFormLabel-root{
          margin-right: ${theme.spacing(2)}
      }
      `
      : ''}
`

const StyledRadioGroup = styled(RadioGroup)(({ theme, gutterbottom }) => ({
  '&&': {
    '.MuiFormControlLabel-root': {
      marginBottom: gutterbottom === 'true' ? theme.spacing(2) : theme.spacing(0)
    }
  }
}))

const StyledRadio = styled(Radio)`
  align-self: flex-start;
`

const CustomRadioGroup = props => {
  const {
    id,
    options,
    label,
    formik,
    onChange,
    onPostChange,
    name,
    onBlur,
    value,
    error,
    helperText,
    fullWidth,
    required,
    row,
    orientation,
    labelPlacement,
    color,
    size,
    disabled,
    captionText,
    fetchOptions,
    disableTypographyForLabel,
    disableTypographyForOptionsLabel,
    gutterBottomForEachOption
  } = props

  let field, meta, setValue

  useEffect(() => {
    if (fetchOptions) {
      fetchOptions()
    }
  }, [])

  const onFieldValueChange = e => {
    setValue(e.target.value)

    // Call onPostChange if provided
    if (onPostChange) {
      onPostChange()
    }
  }

  if (formik === 'true') {
    ;[field, meta, { setValue }] = useField(props)
    field = { ...field, onChange: onChange || onFieldValueChange }
  } else {
    field = {
      onChange,
      onBlur,
      value
    }
  }

  const isError = error || (meta?.touched && Boolean(meta.error))
  const errorText = helperText || (meta?.touched && meta.error)

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      error={isError}
      disabled={disabled}
      id={id}
      component="fieldset"
      variant="standard"
      name={name}
    >
      <LabelOptionWrapper orientation={orientation}>
        {label && (
          <FormLabel required={required} component="legend">
            {disableTypographyForLabel ? label : <Typography variant="body2">{label}</Typography>}
          </FormLabel>
        )}

        <StyledRadioGroup
          id={id}
          name={name}
          aria-label={`radio for ${label}`}
          {...field}
          row={row}
          gutterbottom={`${gutterBottomForEachOption}`}
        >
          {options &&
            options.map((radioItem, index) => {
              const isChecked =
                field && typeof field.value === 'boolean'
                  ? field.value === radioItem.value
                  : !!(field.value && field.value === radioItem.value)
              return (
                <FormControlLabel
                  key={index}
                  labelPlacement={labelPlacement}
                  disableTypography={disableTypographyForOptionsLabel}
                  value={radioItem.value}
                  checked={isChecked}
                  control={<StyledRadio size={size} color={color} />}
                  label={radioItem.label}
                  disabled={disabled}
                />
              )
            })}
        </StyledRadioGroup>
      </LabelOptionWrapper>
      <FormHelperText component="div" error={isError}>
        {errorText}
      </FormHelperText>
      {captionText && <Typography variant="caption">{captionText}</Typography>}
    </FormControl>
  )
}

CustomRadioGroup.propTypes = {
  id: PropTypes.string,
  /**
   * Items required to list as radio options
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Label of the Radio group
   */
  label: PropTypes.node.isRequired,
  /**
   * If `true`, the input field will be a formik field. Note: Make sure in the parent component, this component is wrapped inside form and formik.
   * For non-formik forms, handle - onChange, onBlur, validationError props on your own
   * @default true
   */
  formik: PropTypes.oneOf(['true', 'false']),
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * If `true`, the component will take up the full width of its container.
   * @default true
   */
  fullWidth: PropTypes.bool,
  /**
   * If `true`, the component is displayed in an error state.
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   * @default false
   */
  error: PropTypes.bool,

  /**
   * Use this only for non-formik forms, because when wrapped inside formik, it has its own meta.touched and meta.error.
   */
  helperText: PropTypes.node,
  /**
   * Implement onBlur using this props.
   */
  onBlur: PropTypes.func,
  /**
   * onChange is an optional props, can be used to perform other tasks beside just value change of this particular element
   * Callback fired when a radio button is selected.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The event source of the callback.
   * @param {string} value The value of the selected radio button.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * Optional prop. If provided, call post onChange
   */
  onPostChange: PropTypes.func,
  /**
   * Value of the component
   */
  value: PropTypes.any,
  /**
   * If `true`, the component is required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * row when true, will align the options in row
   * @default false
   */
  row: PropTypes.bool,
  /**
   * orientation when 'column' will align the main label and options in column(the same line)
   * @default 'column'
   */
  orientation: PropTypes.oneOf(['column', 'row']),
  /**
   * labelPlacement, will align the label of each option based on following selection
   * @default 'end'
   */
  labelPlacement: PropTypes.oneOf(['top', 'start', 'bottom', 'end']),
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning'
  ]),
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default 'medium'
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Control disabled?
   * @default 'false'
   */
  disabled: PropTypes.bool,
  /**
   * Description text to display at the bottom in small size.
   */
  captionText: PropTypes.string,
  /**
   * Pass a service call that returns array of options.
   */
  fetchOptions: PropTypes.func,
  /**
   * Use this when you want to pass your custom label.
   * when true, whatever is passed for label props, will be shown as is. Meaning, without a Typography/p-tag. Also, there will not be any indication, that this field is required.
   * Whereas in case of `false` and if this component is required, now when user tries to submit the form without selecting this checkbox, the label will turn to red.
   * @default false
   */
  disableTypographyForLabel: PropTypes.bool,
  /**
   * Use this when you want to pass your custom label for each option.
   * when true, whatever is passed for label props, will be shown as is. Meaning, without a Typography/p-tag. Also, there will not be any indication, that this field is required.
   * Whereas in case of `false` and if this component is required, now when user tries to submit the form without selecting this checkbox, the label will turn to red.
   * @default false
   */
  disableTypographyForOptionsLabel: PropTypes.bool,
  /**
   * @default false
   * when true, there will be margin bottom for each option
   */
  gutterBottomForEachOption: PropTypes.bool
}

CustomRadioGroup.defaultProps = {
  formik: 'true',
  orientation: 'column',
  fullWidth: true,
  error: false,
  required: false,
  row: false,
  labelPlacement: 'end',
  color: 'primary',
  size: 'small',
  disabled: false,
  disableTypographyForLabel: false,
  disableTypographyForOptionsLabel: false,
  gutterBottomForEachOption: false
}

export default CustomRadioGroup
