import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserGuides, setPageNumber, setSelectedDocument } from 'store/ducks/resourceCenter'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { showError } from 'store/ducks/application'
import { List } from 'components/common'
import { downloadUserGuidesDocsByID } from 'services/resourceCenter'

const UserGuides = () => {
  const dispatch = useDispatch()
  const { userGuides, selectedDocument } = useSelector(store => store.resourceCenter)

  useEffect(() => {
    dispatch(fetchUserGuides())
  }, [])

  return (
    <List
      list={userGuides}
      selectedItem={selectedDocument}
      onAdornmentClicked={file => {
        downloadUserGuidesDocsByID(file)
          .then(() => {})
          .catch(() => {
            dispatch(showError('There was some error while trying to download the document. Please try again.'))
          })
      }}
      onItemClicked={file => {
        dispatch(setPageNumber(1))
        dispatch(setSelectedDocument({ ...file, category: resourceCenterCategory.userGuides }))
      }}
    />
  )
}

export default UserGuides
