import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Card, CardHeader, CardContent } from '@mui/material'

import { fetchResourceIcon } from 'components/helper/constants/visitSchedule'

import { fetchResources } from 'store/ducks/patientRecord/patientMainWrapper'
import { setLoading } from 'store/ducks/userInteractions'
import { downloadResourceDocsByID } from 'services/common'
import { showError } from 'store/ducks/application'

const CustomCard = styled(Card)`
  && {
    maxwidth: 200;
    box-shadow: none;
    .MuiCardHeader-root {
      padding: ${({ theme }) => theme.spacing(2)};
      background-color: ${({ theme }) => theme.palette.primary.main};
      min-height: 64px;
    }
    .MuiCardContent-root {
      padding: ${({ theme }) => theme.spacing(4)};
    }
    .MuiCardHeader-title {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      font-weight: bold;
      flex: 1;
      display: flex;
      justify-content: flex-start;
      transform: translateX(10px);
      align-self: center;
    }
  }
`

const StyledLI = styled.li(({ theme, type }) => ({
  backgroundImage: `url(${fetchResourceIcon(type)})`,
  backgroundRepeat: 'no-repeat',
  listStyle: 'none',
  fontSize: '1rem',
  lineHeight: '40px',
  paddingLeft: '60px',
  margin: `${theme.spacing(2)} 0`
}))
const StyledUL = styled.ul(() => ({
  margin: '0px',
  paddingInlineStart: '0',
  cursor: 'pointer'
}))

const StickyDiv = styled.div(({ theme }) => ({
  position: 'sticky',
  top: 60,
  [theme.breakpoints.down('md')]: {
    top: 200
  }
}))

const Information = () => {
  const dispatch = useDispatch()
  const { resources } = useSelector(store => store.patientMainWrapper)
  const { siteClientId } = useSelector(store => store.application)
  const { isPatient } = useSelector(store => store.auth)

  useEffect(() => {
    // siteClientId is required to fetch Resources
    if (siteClientId) {
      dispatch(fetchResources())
    }
  }, [siteClientId])

  return (
    <StickyDiv>
      <CustomCard>
        <CardHeader title="Information" />
        <CardContent>
          <StyledUL>
            {resources.map(resource => (
              <StyledLI
                key={resource.documentId}
                type={resource.documentType}
                onClick={async () => {
                  dispatch(setLoading(true))
                  await downloadResourceDocsByID({
                    id: resource.documentId,
                    name: resource.documentName,
                    isViewed: false,
                    documentType: isPatient ? 'visitResource' : null,
                  })
                    .then(() => {
                      dispatch(setLoading(false))
                    })
                    .catch(() => {
                      dispatch(setLoading(false))
                      dispatch(
                        showError(
                          'There was some error while trying to download the document. Please try again.'
                        )
                      )
                    })
                }}
              >
                {resource.displayText}
              </StyledLI>
            ))}
          </StyledUL>
        </CardContent>
      </CustomCard>
    </StickyDiv>
  )
}

Information.propTypes = {}

export default Information
