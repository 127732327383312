import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPatientStudyToolsDocuments,
  setPageNumber,
  setSelectedDocument
} from 'store/ducks/resourceCenter'
import { resourceCenterCategory } from 'components/helper/constants/resourceCenter'
import { List } from 'components/common'
import { downloadResourceDocsByID } from 'services/common'
import { showError } from 'store/ducks/application'
import { setLoading } from 'store/ducks/userInteractions'

const ForPatientsStudyTools = ({ siteClientId, fetchInitialDocument }) => {
  const dispatch = useDispatch()
  const { isPatient } = useSelector(store => store.auth)
  const { forPatientsStudyToolsDocuments, selectedDocument } = useSelector(
    store => store.resourceCenter
  )

  useEffect(() => {
    if (isPatient) {
      dispatch(fetchPatientStudyToolsDocuments('', true))
    } else {
      siteClientId && dispatch(fetchPatientStudyToolsDocuments(siteClientId, fetchInitialDocument))
    }
    return () => {
      dispatch(setSelectedDocument({}))
    }
  }, [siteClientId])

  return (
    <List
      list={forPatientsStudyToolsDocuments}
      selectedItem={selectedDocument}
      onAdornmentClicked={async file => {
        dispatch(setLoading(true))
        await downloadResourceDocsByID({ ...file, isViewed: false, documentType: isPatient ? 'studyTools' : null, })
          .then(() => {
            dispatch(setLoading(false))
          })
          .catch(() => {
            dispatch(setLoading(false))
            dispatch(
              showError(
                'There was some error while trying to download the document. Please try again.'
              )
            )
          })
      }}
      onItemClicked={file => {
        dispatch(setPageNumber(1))
        dispatch(
          setSelectedDocument({
            ...file,
            category: resourceCenterCategory.forPatientsStudyToolsDocuments,
            documentType: isPatient ? 'studyTools' : null,
            isViewed: true
          })
        )
      }}
    />
  )
}

ForPatientsStudyTools.propTypes = {
  /**
   * When true will fetch the first document on load
   */
  fetchInitialDocument: PropTypes.bool,
  /**
   * Documents of a selected Site
   */
  siteClientId: PropTypes.string
}

ForPatientsStudyTools.defaultProps = {
  fetchInitialDocument: false
}

export default ForPatientsStudyTools
