import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { requestTypeIds } from 'components/helper/constants/visitServices'
import { Button, Card } from 'components/common'
import AdvancedSupportForm from '../makeARequest/advancedSupportForm/AdvancedSupportForm'
import AuxillarySupportForm from '../makeARequest/auxillarySupportForm/AuxillarySupportForm'
import InPersonRequestForm from '../makeARequest/inPersonRequestForm/InPersonRequestForm'
import TelehealthVisit from '../makeARequest/remoteRequestForm/telehealthSupport/TelehealthVisit'
import DeliveryServices from '../makeARequest/remoteRequestForm/DeliveryServices'
import OtherServices from '../makeARequest/remoteRequestForm/OtherServices'
import HomeHealthVisit from '../makeARequest/remoteRequestForm/HomeHealthVisit'

const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledTitle = styled(Typography)`
  width: 70%;
  text-align: end;
`
const ActionGrid = styled(Grid)(({ theme }) => ({
  '&&': {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2)
  }
}))

const RequestReadOnlyView = ({ setShowDetailView }) => {
  const { requestTypeId } = useParams()
  const { visitDetails } = useSelector(store => store.requestDetails)

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card
          radius={false}
          headerColor="secondary"
          title={
            <SubHeaderWrapper>
              <StyledTitle variant="h5" gutterBottom>
                <b>Service Number:</b> {visitDetails.results.requestNumber} | <b>Service Type:</b>{' '}
                {visitDetails.results.requestType}
              </StyledTitle>

              {/* <Button
                      size="medium"
                      onClick={() => setOpenConiergeHelp(true)}
                      color="inherit"
                      startIcon={<ChatBubble fontSize="small" />}
                    >
                      Concierge
                    </Button>
                    &emsp; */}
              <Button size="medium" onClick={() => setShowDetailView(false)} color="inherit">
                Close
              </Button>
            </SubHeaderWrapper>
          }
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {+requestTypeId === requestTypeIds.inPersonVisit && <InPersonRequestForm />}
              {+requestTypeId === requestTypeIds.auxillarySupport && <AuxillarySupportForm />}
              {+requestTypeId === requestTypeIds.advancedSupport && <AdvancedSupportForm />}
              {+requestTypeId === requestTypeIds.telehealthVisit && <TelehealthVisit />}
              {+requestTypeId === requestTypeIds.deliveryServices && <DeliveryServices />}
              {+requestTypeId === requestTypeIds.homeHealthVisit && <HomeHealthVisit />}
              {+requestTypeId === requestTypeIds.otherServices && (
                <OtherServices
                  openSubmitSuccessHandler={() => {}}
                  selectedRequest={visitDetails.results}
                />
              )}
            </Grid>
            <ActionGrid item xs={12} sm={12} md={12} lg={12}>
              <Button size="large" onClick={() => setShowDetailView(false)} color="inherit">
                Close
              </Button>
            </ActionGrid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

RequestReadOnlyView.propTypes = {
  setShowDetailView: PropTypes.func,
  selectedRequest: PropTypes.object
}

export default RequestReadOnlyView
