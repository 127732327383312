import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { Button, DataGrid } from 'components/common'
import { palette } from 'styles/baseTheme'
import {
  handleRequestSort,
  fetchSitePrefQuestionAndAnswers,
  fetchSiteUserActivity,
  setShowQuestionAndAnswerModal
} from 'store/ducks/siteManagement/userActivity'
import { displayDate } from 'components/helper/utility'
import QuickView from 'components/common/QuickView'
import SitePrefQuestionsAndAnswers from './SitePrefQuestionsAndAnswers'
import { useRestriction } from 'permissions'

const TableWrapper = styled.div`
  border-bottom: 1px solid gray;
`
const headerProperties = {
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText
}

const UserActivity = () => {
  const { siteId } = useParams()
  const dispatch = useDispatch()
  const { order, orderBy, siteUserActivity, showQuestionAndAnswerModal } = useSelector(store => store.userActivity)
  let formattedSiteUserActivity = []
  if (siteUserActivity && siteUserActivity.length > 0) {
    formattedSiteUserActivity = siteUserActivity.map(activity => ({
      ...activity,
      lastLoginDate: displayDate(activity.lastLoginDate),
      sitePreferencesColumn: (
        <Button variant="text" onClick={() => dispatch(fetchSitePrefQuestionAndAnswers(activity.userId))}>
          Q&amp;A
        </Button>
      )
    }))
  }

  useEffect(() => {
    dispatch(fetchSiteUserActivity(siteId))
  }, [siteId])

  const headers = [
    { id: 'firstName', label: 'First Name', isSortable: true, headProperties: { ...headerProperties } },
    { id: 'lastName', label: 'Last Name', isSortable: true, headProperties: { ...headerProperties } },
    {
      id: 'role',
      label: 'Role',
      bodyProperties: {
        width: '20%'
      },
      isSortable: true,
      headProperties: { ...headerProperties }
    },
    { id: 'phone', label: 'Phone', isSortable: true, headProperties: { ...headerProperties } },
    {
      id: 'email',
      label: 'Email',
      isSortable: true,
      bodyProperties: {
        width: '20%'
      },
      headProperties: { ...headerProperties }
    },
    // { id: 'userStatus', label: 'Status' },
    {
      id: 'userStatusDisplay',
      label: 'Status',
      isSortable: true,
      bodyProperties: {
        width: '10%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'lastLoginDate',
      label: 'Last Login',
      isSortable: true,
      bodyProperties: {
        width: '15%'
      },
      headProperties: { ...headerProperties }
    },
    {
      id: 'sitePreferencesColumn',
      label: 'Site Preferences',
      bodyProperties: {
        width: '10%'
      },
      headProperties: { ...headerProperties }
    }
  ]

  // check permission of each column
  const updatedHeaders = headers.filter(
    item => useRestriction('read', item.id) && item
  )

  return (
    <>
      <TableWrapper>
        <DataGrid
          headers={updatedHeaders}
          tableData={formattedSiteUserActivity}
          alternateRowColored={true}
          setRequestedSort={orderByValue => dispatch(handleRequestSort(orderByValue))}
          order={order}
          orderBy={orderBy}
        />
      </TableWrapper>
      <QuickView
        title="Site Preferences Q&amp;A"
        onClose={() => dispatch(setShowQuestionAndAnswerModal(false))}
        dialogContent={<SitePrefQuestionsAndAnswers />}
        isDialogOpen={showQuestionAndAnswerModal}
        maxWidth="md"
      />
    </>
  )
}

export default UserActivity
