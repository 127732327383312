import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import { saveSiteNote, setShowAddNote } from 'store/ducks/siteManagement/siteManagement'
import { fetchSiteNoteTypesLookup } from 'store/ducks/common'

import Button from 'components/common/form/Button'
import { TextBoxOuterLabel, AutoCompleteWithLabel } from 'components/common/form'

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 0px;
`

const StyledDiv = styled.div`
  width: '100%';
`

const validationSchema = Yup.object({
  noteTypeId: Yup.object().nullable({ isNullable: true }).required('Note Type is required'),
  note: Yup.string().required('Note is required')
})

const AddNoteForm = ({ siteId, siteProtocolId }) => {
  const dispatch = useDispatch()
  const { siteNoteTypesLookup } = useSelector(store => store.common)

  const submitHandler = data => {
    dispatch(saveSiteNote(data, siteId, siteProtocolId))
  }

  return (
    <Formik
      initialValues={{ note: '', noteTypeId: null }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {() => (
        <StyledDiv>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AutoCompleteWithLabel
                  id="noteTypeId"
                  required
                  label="Note Type"
                  name="noteTypeId"
                  options={siteNoteTypesLookup.results}
                  isLoading={siteNoteTypesLookup.isLoading}
                  fetchOptions={() => dispatch(fetchSiteNoteTypesLookup())}
                  size="small"
                />
              </Grid>
              <Grid item xs={0} sm={0} md={6} lg={6}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextBoxOuterLabel
                  size="medium"
                  id="note"
                  label=""
                  name="note"
                  type="text"
                  rows="5"
                  placeholder="Site Note"
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ActionWrapper>
                  <Button size="large" fullWidth={false} color="primary" type="submit">
                    Save
                  </Button>
                  &ensp;&ensp;
                  <Button size="large" color="inherit" fullWidth={false} onClick={() => dispatch(setShowAddNote(false))}>
                    Cancel
                  </Button>
                </ActionWrapper>
              </Grid>
            </Grid>
          </Form>
        </StyledDiv>
      )}
    </Formik>
  )
}

AddNoteForm.propTypes = {
  siteId: PropTypes.string,
  siteProtocolId: PropTypes.string
}

export default AddNoteForm
