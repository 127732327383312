import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

import { Button } from 'components/common/form'
import { Grid, Typography } from '@mui/material'
import { DataGrid } from 'components/common'

import AddNoteForm from './AddNoteForm'
import QuickView from 'components/common/QuickView'
import { Restriction } from 'permissions'

const EmptyFooter = styled.div`
  padding: 25px;
`
const Container = styled.div(({ theme }) => ({
  padding: theme.spacing(4, 0, 3, 0),
  width: '100%',
  overflow: 'hidden',
  // padding: '20px 0px 20px 0px'
}))
const HeadingContainer = styled.div(() => ({
  width: '80%',
  float: 'left',
}))
const ButtonContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}))

const ParticipationProgressNotes = () => {
  const [showAddNote, setShowAddNote] = useState(false)
  const { participationProgressNotes } = useSelector(store => store.participationProgress)
  const addNoteHandler = () => {
    setShowAddNote(true)
  }

  const closeAddNoteForm = () => {
    setShowAddNote(false)
  }

  return (
    <>
      <Container>
        <HeadingContainer>
          <Typography variant="h2">Notes</Typography>
        </HeadingContainer>
        <ButtonContainer>
          <Restriction ability="read" feature="participationProgressAddNoteButton">
            <Button label="Add" size="medium" fullWidth={false} color="primary" onClick={addNoteHandler}>
              Add Note
            </Button>
          </Restriction>
        </ButtonContainer>
      </Container>
      {/* <Container>
        <Typography variant="h2">Notes</Typography>
      </Container> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ParticipationProgressNotesGrid notes={participationProgressNotes} />
        </Grid>
      </Grid>
      <QuickView
        title="Add Note"
        onClose={closeAddNoteForm}
        dialogContent={<AddNoteForm closeAddNoteForm={closeAddNoteForm} />}
        isDialogOpen={showAddNote}
        maxWidth="sm"
        dialogActions={<EmptyFooter />}
        closeConfirmation={false}
      />
    </>
  )
}

const ParticipationProgressNotesGrid = ({ notes }) => (
  <div>
    <DataGrid
      headers={[
        {
          id: 'note',
          label: 'Notes',
          bodyProperties: {
            textAlign: 'left',
            width: '55%',
            cellType: 'html',
          },
        },
        {
          id: 'createdBy',
          label: 'Created By (Name)',
          bodyProperties: {
            width: '15%',
          },
        },
        {
          id: 'createdByPolicy',
          label: 'Created By (Policy)',
          bodyProperties: {
            width: '15%',
          },
        },
        {
          id: 'createdDate',
          label: 'Date',
          bodyProperties: {
            width: '15%',
          },
        },
      ]}
      tableData={notes}
      setRequestedSort={() => {}}
      order={'DESC'}
    />
  </div>
)

ParticipationProgressNotesGrid.propTypes = {
  notes: PropTypes.array,
}

export default ParticipationProgressNotes
