/* eslint-disable multiline-ternary */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from '@emotion/styled'
import {
  fetchTelehealthSupportDetails,
  resetTeleHealthSupport,
  setupTeleHealthRequestDetailsForm,
  submitTelehealthSupportRequest
} from 'store/ducks/visitServices/telehealthVisit'

import {
  TextBoxOuterLabel,
  DatePickerWithLabel,
  AutoCompleteWithLabel,
  Button,
  Bar,
  CheckboxGroup
} from 'components/common'
import { formElements } from 'components/helper/constants/common'
import { validationMapper } from 'components/helper/validationEngine'
import ParticipantForm from './ParticipantForm'
import { Error } from '@mui/icons-material'
import {
  fetchVisitNames,
  fetchVisitScheduleGroup,
  setVisitNames
} from 'store/ducks/visitServices/requestDetails'

const LabelForVisitNames = styled.div(
  ({ theme }) => `
      display: flex;  
      svg{
        top: -1px;
        width: 1.2rem;
        height: 1.2rem;
        right: -17px;
      }
      p {
        margin-right: ${theme.spacing(1 / 2)};
      }`
)

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`

const ParticipantWrapper = styled.div`
  width: 500px;
`

const ConciergeNurseParticipant = styled(Typography)(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
}))

const StyledLabel = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
`

const validationSchema = visitParticipants => {
  const finalValidationArray = [
    { id: 'visitNames', isResponseRequired: true, answerType: formElements.dropdownMenu },
    {
      id: 'visitDate',
      isResponseRequired: true,
      answerType: formElements.date,
      condition: {
        min: new Date(),
        minError: 'Visit start date must be in the future'
      },
      error: 'Visit start date is required and must be a valid date'
    },
    { id: 'visitTime', isResponseRequired: true, answerType: formElements.openText },
    { id: 'aprroxDuration', isResponseRequired: true, answerType: formElements.number },
    {
      id: 'selectedTelehealthParticipants',
      isResponseRequired: true,
      answerType: formElements.checkboxGroup,
      errorMessage: 'Atleast 1 Participant needs to be selected'
    }
  ]
  visitParticipants.forEach(person => {
    if (person.id !== '0') {
      finalValidationArray.push({
        id: `${person.id}-selectedTelehealthParticipants-emailAddress`,
        isResponseRequired: true,
        answerType: formElements.openText,
        dependency: {
          elements: ['selectedTelehealthParticipants'],
          callBack: selectedTelehealthParticipants =>
            selectedTelehealthParticipants?.length &&
            selectedTelehealthParticipants
              .map(participant => participant.id)
              .includes(`${person.id}`)
        }
      })
    }
  })
  return validationMapper(finalValidationArray)
}

const TelehealthVisit = () => {
  const { requestId, copyRequestId, requestTypeId } = useParams()
  const dispatch = useDispatch()
  const { visitNames, visitDetails, visitScheduleGroup, startNewRequestForVisitFlow } = useSelector(
    store => store.requestDetails
  )
  const { patientInformation } = useSelector(store => store.patientMainWrapper)
  const { telehealthSupportDetailsForm, visitParticipants } = useSelector(
    store => store.telehealthVisit
  )

  const setupTelehealthForm = async () => {
    if (patientInformation.userId) {
      await dispatch(fetchVisitScheduleGroup(patientInformation.userId))
      if (!requestTypeId && !requestId && !copyRequestId) {
        dispatch(setupTeleHealthRequestDetailsForm())
      }
      if (requestTypeId && requestId) {
        dispatch(fetchTelehealthSupportDetails(requestId))
      }
      // Copy Existing request View
      if (copyRequestId) {
        dispatch(fetchTelehealthSupportDetails(copyRequestId))
      }
    }
  }

  useEffect(() => {
    setupTelehealthForm()
    return () => {
      dispatch(resetTeleHealthSupport())
      dispatch(
        setVisitNames({
          isLoading: false,
          results: []
        })
      )
    }
  }, [patientInformation.userId])

  const readOnly = !!visitDetails.results && !!requestId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {visitScheduleGroup.isLoading || visitDetails.isLoading ||
        visitParticipants.isLoading ||
        visitParticipants.results.length === 0 ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box>
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                  <Skeleton height={64} />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Formik
              initialValues={{
                ...telehealthSupportDetailsForm,
                ...(startNewRequestForVisitFlow &&
                !readOnly && {
                  ...startNewRequestForVisitFlow
                })
              }}
              enableReinitialize={true}
              validationSchema={validationSchema(visitParticipants.results)}
              onSubmit={values => {
                dispatch(submitTelehealthSupportRequest(values, copyRequestId))
              }}
            >
              {() => (
                <Form>
                  <Grid container spacing={2}>
                    {visitScheduleGroup.results.length > 1 && (
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <AutoCompleteWithLabel
                          id="visitScheduleGroup"
                          label="Select a Visit Schedule"
                          name="visitScheduleGroup"
                          onChange={(event, selectedValue, reason) => {
                            if (reason === 'selectOption') {
                              dispatch(fetchVisitNames(patientInformation.userId, selectedValue.id))
                            } else if (reason === 'clear') {
                              dispatch(
                                setVisitNames({
                                  isLoading: false,
                                  results: []
                                })
                              )
                            }
                          }}
                          options={visitScheduleGroup.results}
                          isLoading={visitScheduleGroup.isLoading}
                          size="small"
                          disabled={readOnly}
                          multiple={false}
                          hasSelectAllOption={false}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <AutoCompleteWithLabel
                        id="visitNames"
                        label={
                          <LabelForVisitNames>
                            <Typography variant="body2">Study Visit</Typography>
                            <Tooltip
                              color="primary"
                              size="small"
                              title={
                                <Typography variant="body1">
                                If your request will apply to more than one study visit, please
                                select the first applicable study visit.
                                </Typography>
                              }
                            >
                              <Error size="small" />
                            </Tooltip>
                          </LabelForVisitNames>
                        }
                        name="visitNames"
                        options={visitNames.results}
                        isLoading={visitNames.isLoading}
                        size="small"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <DatePickerWithLabel
                        id="visitDate"
                        label="Visit Date"
                        name="visitDate"
                        size="small"
                        minDate={new Date()}
                        fullWidth={false}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextBoxOuterLabel
                        size="small"
                        id="visitTime"
                        label="Visit Time"
                        name="visitTime"
                        type="time"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextBoxOuterLabel
                        size="small"
                        id="aprroxDuration"
                        label={
                          <Typography variant="body2">
                          Approximate Duration <i>(in mins)</i>
                          </Typography>
                        }
                        name="aprroxDuration"
                        type="number"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CheckboxGroup
                        id="selectedTelehealthParticipants"
                        name="selectedTelehealthParticipants"
                        fullWidth={true}
                        optionsAlignInRow={true}
                        disableTypographyForOptionsLabel={true}
                        label="Select all individuals who are attending the virtual visit. If for this call the contact information requires updating, please provide"
                        options={visitParticipants.results.map(person =>
                          person.id === '0'
                            ? {
                              ...person,
                              emailAddress: person.emailAddress, // this is required for matching with selected option
                              name: person.fullName, // Name is required in case of options being a react node and not a string
                              displayText: (
                                <ParticipantWrapper>
                                  <ConciergeNurseParticipant variant="body2">
                                    {person.fullName}
                                  </ConciergeNurseParticipant>
                                </ParticipantWrapper>
                              )
                            }
                            : {
                              ...person,
                              emailAddress: person.emailAddress, // this is required for matching with selected option
                              name: person.fullName, // Name is required in case of options being a react node and not a string
                              displayText: <ParticipantForm person={person} />
                            }
                        )}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Bar fullWidth={true} color="midnight" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="body2">In Case We Need To Speak with You</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachDateTime"
                        label="What is the best time of the day to reach you?"
                        name="reachDateTime"
                        type="time"
                        size="small"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextBoxOuterLabel
                        id="reachPhoneNumber"
                        label={
                          <StyledLabel variant="body2">
                          Best phone number to reach you <i>(include country code)</i>
                          </StyledLabel>
                        }
                        name="reachPhoneNumber"
                        size="small"
                        type="number"
                        disabled={readOnly}
                      />
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ActionWrapper>
                          <Button size="large" fullWidth={false} color="primary" type="submit">
                          Submit Request
                          </Button>
                          {/* TBD: Need to confirm with Dena if cancel is needed */}
                          {/* &ensp;&ensp;
                    <Button
                      size="large"
                      color="inherit"
                      fullWidth={false}
                      onClick={() => {
                        // setIsReadOnly(true)
                        resetForm()
                      }}
                    >
                      Cancel
                    </Button> */}
                        </ActionWrapper>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
      </Grid>
    </Grid>
  )
}

TelehealthVisit.propTypes = {}

export default TelehealthVisit
